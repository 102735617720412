











import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class BaseCheckbox extends Vue {
  @Prop(String) text!: string
  @Prop(Boolean) initialState!: boolean
  @Prop(Boolean) disabled!: boolean

  isChecked = this.initialState

  get icon() {
    return this.isChecked ? 'check_box' : 'check_box_outline_blank'
  }

  onToggle() {
    if (!this.disabled) {
      this.isChecked = !this.isChecked
      this.$emit('toggle', this.isChecked)
    }
  }
}
