export const downloadFormats = [
  { id: 'SRT', title: '.SRT Format' },
  { id: 'TEXT', title: '.TXT Format' },
  { id: 'DOCX', title: '.DOCX Format' },
]

export enum templateTypes {
  BASIC = 'BASIC',
  BOXED = 'BOXED',
  WRAP = 'WRAP',
  SHADOW = 'SHADOW',
  OUTLINED = 'OUTLINED',
  FILLED = 'FILLED',
  NEON = 'NEON',
}

export const templateStyles = {
  initialStyle: {
    width: 300,
    selectable: false,
    originX: 'center',
    originY: 'center',
    fontFamily: 'Arial',
    textAlign: 'center',
    fontWeight: 'normal',
    fontSize: 20,
    cursorWidth: 1,
    cursorDuration: 1,
    paintFirst: 'stroke',
    typeThing: 'none',
    objectCaching: true,
    strokeUniform: true,
    inGroup: false,
    cursorDelay: 250,
    id: 'subtitle',
    strokeDashArray: false,
    absolutePositioned: true,
  },
  [templateTypes.BASIC]: {
    fill: '#fff',
    shadow: {
      color: '#000',
      offsetX: 0,
      offsetY: 0,
      blur: 0,
      opacity: 0,
    },
  },
  [templateTypes.BOXED]: {
    fill: '#fff',
    backgroundColor: 'rgba(0,0,0,0.7)',
  },
  [templateTypes.WRAP]: {
    fill: '#fff',
    textBackgroundColor: 'rgba(0,0,0,0.5)',
    lineHeight: 1.3,
  },
  [templateTypes.SHADOW]: {
    fill: '#fff',
    shadow: 'rgba(0,0,0,0.6) 5px 15px 10px',
  },
  [templateTypes.OUTLINED]: {
    fill: 'rgba(0, 0, 0, 0)',
    stroke: '#fff',
    strokeWidth: 1,
  },
  [templateTypes.FILLED]: {
    fill: '#000000',
    stroke: '#00FFF0',
    strokeWidth: 3,
  },
  [templateTypes.NEON]: {
    fill: '#FF0099',
    stroke: '#fff',
    strokeWidth: 2,
    shadow: {
      color: 'rgb(255, 0, 153) ',
      offsetX: 0,
      offsetY: 10,
      blur: 10,
      opacity: 1,
    },
  },
}

export const sliderOPtions: any = {
  eventType: 'auto',
  width: 'auto',
  height: 6,
  dotSize: 12,
  dotHeight: null,
  dotWidth: null,
  min: 0,
  interval: 1,
  show: true,
  speed: 0.5,
  disabled: false,
  piecewise: false,
  useKeyboard: true,
  enableCross: true,
  piecewiseLabel: false,
  tooltip: 'none',
  tooltipDir: 'top',
  reverse: false,
  clickable: true,
  realTime: true,
  lazy: false,
  formatter: null,
  bgStyle: null,
  sliderStyle: null,
  processStyle: {
    background: '#DE1A23',
  },
  piecewiseActiveStyle: null,
  tooltipStyle: null,
  labelStyle: null,
  labelActiveStyle: null,
}
