import { Ref, ref } from '@vue/composition-api'
import { audioQueryKeys } from './audio'
import { queryDefaults } from '@/utils/query.config'
import AdoriService from '@/services/adori'
import store from '@/store'
import { useQuery, useMutation, useQueryClient } from 'vue-query'
import { billingQueryKeys } from './billing'

// Query Keys

export enum transcriptQuery {
  TRANSCRIPT = 'TRANSCRIPT',
}

// Queries
const getTranscript = (networkId: string, trackUid: string) => {
  return AdoriService.fetchAudioTranscript(networkId, trackUid)
}
const getLanguages = () => {
  return AdoriService.fetchLanguages()
}
const getVoiceLanguages = (provider: 'google' | 'aws' | 'elevenlabs') => {
  return AdoriService.fetchVoiceLanguages(provider)
}
const getVoiceDetails = (provider: 'google' | 'aws' | 'elevenlabs', params: any) => {
  return AdoriService.fetchVoiceDetails(params, provider)
}

const generateTranscript = (networkId: string, payload: { trackUid: string; language: string }) => {
  return AdoriService.generateAudioTranscript(networkId, payload.trackUid, payload.language)
}

// Hooks
export const useGetTranscript = (trackUid: string, { enabled }: any, interval: any = false, onSuccess?: any) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useQuery([transcriptQuery.TRANSCRIPT, networkId, trackUid], () => getTranscript(networkId, trackUid), {
    ...queryDefaults,
    enabled,
    refetchInterval: interval,
    refetchIntervalInBackground: false,
    onSuccess: onSuccess,
  })
}
export const useGetLanguages = () => {
  return useQuery(['LANGUAGES'], () => getLanguages(), {
    cacheTime: Infinity,
    staleTime: Infinity,
    onSuccess(data) {
      store.commit('setLanguages', data)
    },
  })
}
export const useGetVoiceLanguages = (provider: Ref<'aws' | 'elevenlabs'>) => {
  return useQuery(['VOICE-LANGUAGES', provider], () => getVoiceLanguages(provider.value), {
    ...queryDefaults,
    cacheTime: Infinity,
    staleTime: Infinity,
  })
}
export const useGetVoiceDetails = (provider: Ref<'aws' | 'elevenlabs'>, params: any) => {
  return useQuery(['VOICE-LANGUAGES', provider, params], () => getVoiceDetails(provider.value, params), {
    ...queryDefaults,
    cacheTime: Infinity,
    staleTime: Infinity,
  })
}

// mutations
export const useGenerateTranscript = () => {
  const queryClient = useQueryClient()
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  let tempTrackUid = ''
  return useMutation(
    (payload: { trackUid: string; language: string }) => {
      tempTrackUid = payload.trackUid
      return generateTranscript(networkId, payload)
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([audioQueryKeys.AUDIO_TRACK, networkId, tempTrackUid])
        queryClient.invalidateQueries(billingQueryKeys.BILLING_INVOICES)
      },
    }
  )
}
