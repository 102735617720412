







































































































































































































































































































































import { Vue, Component, Watch, Mixins } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import TheToggleButton from '@/components/Common/TheToggleButton.vue'
import moment from 'moment'
import vSelect from 'vue-select'
import { validationMixin } from 'vuelidate'
import AIPanel from '@/components/AI/Panes/AIPanel.vue'

// constants
import { privacyList, categoryList } from '@/components/Publish/publish'
import { formatSelected, formatToday, timezoneList } from '@/components/Youtube/time'
import get from 'lodash.get'
import AdoriService from '@/services/adori'
import { required } from 'vuelidate/lib/validators'
import { CURRENT_ENV, ENVS } from '@/constants/env'
// constants
import { formatOpenAiKeywords } from '@/utils/misc'

const validations = {
  title: {
    required,
  },
}

@Component({
  // @ts-ignore
  validations,
  components: {
    TheToggleButton,
    vSelect,
    AIPanel,
  },
})
export default class ModalYoutubePublish extends Mixins(validationMixin) {
  @Getter categories!: any
  @Getter playlist!: any
  @Getter ytModalId!: any
  @Getter selectedEpisodeSettings!: any
  @Getter networkId!: string
  @Getter networkType!: string
  @Getter getRssEpisodeByGuid!: any
  @Getter uploadListStatus!: any
  @Getter signinStatus!: any
  @Getter youtubeProfile!: any
  @Getter createdYoutubeFeed!: any

  @Action closeModal!: any
  @Action ytModalSetting!: any
  @Action getIntegrationsSummary!: any
  @Action youtubePlaylist!: any
  @Action pushNotification!: any
  @Action loadUser!: any
  @Action setSession!: any
  @Action setShowPane!: any

  // open AI
  @Action openAi!: any
  @Getter openAiResults!: any

  $v!: any

  selfDeclareMadeForKids = null
  today: string = moment().utc().format('YYYY-MM-DD')
  youtubeConf: any = {}
  playlistId: number = 0
  currentYoutubeLogin = 'Network Login'
  categoryId: number = 1
  privacy: string = ''
  toggleValue: boolean = false
  includeVisuals: boolean = true
  includeChapters: boolean = true
  title: string = ''
  description: string = ''
  scheduleDate: string = ''
  scheduleTime: string = ''
  publish: string = ''
  dateSelected: boolean = false
  timesSelected: boolean = false
  timezone: string = ''
  uploadingImage = false
  file!: any
  imageId = ''
  posterImageId = ''
  width!: number
  height!: number
  startTimeSec!: number
  endTimeSec!: number
  localPublish!: string
  plainDescription: string = ''
  scheduledFor!: string
  isScheduled!: boolean
  chapterMarkers: string = ''
  customDescription: boolean = false
  guid: string = ''
  options: any = []
  keywords: any = []
  episodeKeywords: any = []
  currentInput: string = ''
  youtubeAuthHandle: any = null
  includeShortsValue: boolean = false
  selectedContext = 'CATCHY_TITLE'

  toggleAIPanel(ctx: any) {
    this.selectedContext = ctx
    this.setShowPane(true)
  }

  get aiData() {
    return {
      title: this.title,
      description: this.description,
    }
  }

  async selectItem(val: any) {
    if (val.context === 'AI_TITLE') this.title = val.title.replace(/["]/g, '')
    if (val.context === 'AI_DESCRIPTION') {
      this.plainDescription = val.description
      this.description = val.description
      if (this.includeVisuals && this.includeChapters) {
        this.description += this.chapterMarkers
        this.plainDescription += this.chapterMarkers
      }
    }
    if (val.context === 'AI_THUMBNAIL') {
      if (val.imageUrl) {
        this.uploadingImage = true
        this.setShowPane(false)
        const img: any = await AdoriService.uploadImage(this.networkId, {
          url: val.imageUrl,
        })
        this.file = img
        this.imageId = img.id
        this.posterImageId = img.id
        Vue.set(img, 'thumbnailSrc', img.url)
        this.uploadingImage = false
      }
    }
    if (val.context === 'AI_KEYWORDS') {
      let key = formatOpenAiKeywords(val.keywords)
      const words = key.split(',')
      words.length && (this.keywords = words)
    }
    // this.setShowPane(false)
  }

  saveKeyword() {
    this.currentInput !== '' && this.keywords.indexOf(this.currentInput) === -1 && this.keywords.push(this.currentInput)
    this.currentInput = ''
  }
  deleteKeyword(index: any) {
    this.keywords.splice(index, 1)
  }
  backspaceDelete({ which }: any) {
    which == 8 && this.currentInput === '' && this.keywords.splice(this.keywords.length - 1)
  }

  triggerYoutubeLogin() {
    sessionStorage.setItem('youtubeAuth', 'YES')
    this.$store.commit('setTriggerLogin')
  }
  triggerShowYoutubeLogin() {
    sessionStorage.setItem('showLogin', 'YES')
    this.$store.commit('setTriggerLogin')
  }

  get loginTooltip() {
    return this.currentYoutubeLogin === 'Network Login'
      ? 'Connect Youtube Channel to your Network, By default all the episode will be published to connected channel'
      : 'Connect Youtube channel to this standalone podcast, Then all the episode of this podcast will be published to connected channel '
  }

  get ytFeedUid() {
    return get(this.createdYoutubeFeed, 'uid', '') || sessionStorage.getItem('feedUid')
  }

  get getPlaylist() {
    return this.signinStatus[this.ytFeedUid]?.youtube
      ? this.playlist[this.ytFeedUid] || []
      : this.playlist[this.networkId] || []
  }

  get isNotALlowedLogin() {
    return !['YOUTUBE_METERED', 'YOUTUBE', 'YOUTUBE_PRO'].includes(this.networkType) && CURRENT_ENV !== ENVS.dev
  }

  get youtubeNetworkSignInStatus() {
    return this.signinStatus[this.networkId]?.youtube || false
  }
  get youtubeShowSignInStatus() {
    return (this.ytFeedUid && this.signinStatus[this.ytFeedUid]?.youtube) || false
  }

  get youtubeSignInStatus() {
    return this.ytFeedUid && this.signinStatus[this.ytFeedUid]?.youtube
      ? this.signinStatus[this.ytFeedUid]?.youtube
      : this.signinStatus[this.networkId]?.youtube
      ? this.signinStatus[this.networkId]?.youtube
      : false
  }

  get isSaveInValid() {
    return this.$v.title.$invalid
  }

  get isAudioUploading() {
    return this.uploadListStatus[this.guid]
  }

  get initialCoverImg() {
    return this.selectedEpisodeSettings[this.ytModalId].thumbnailSrc
  }

  get thumbnailSrc() {
    if (this.imageId) {
      return this.file.url
    } else {
      return this.initialCoverImg
    }
  }

  get timeError() {
    if (this.scheduleDate && this.scheduleTime) {
      let scheduleDateTime = this.scheduleDate + this.scheduleTime
      let todayDate = formatToday(scheduleDateTime, this.timezone)
      let selectedDate = formatSelected(scheduleDateTime)
      if (selectedDate < todayDate) {
        return true
      }
    }
    return false
  }

  get titleCharacterRemaining() {
    return 99 - this.title.length
  }

  get descriptionCharacterRemaining() {
    return 4999 - this.plainDescription.length
  }

  get isTitleOverMax() {
    return this.titleCharacterRemaining < 0
  }
  get isDescriptionOverMax() {
    return this.descriptionCharacterRemaining < 0
  }

  get privacyListData() {
    return privacyList
  }

  get categoryListData() {
    return categoryList
  }

  get timezoneListData() {
    let today = new Date().toString()
    let rawOffset = today.split(' ')[5]
    let offset = rawOffset.substring(0, 6) + ':' + rawOffset.substring(6)
    let ltz = {
      title: `(${offset}) Local Time`,
      value: `(${offset}) Local Time`,
    }
    let tz = [ltz, ...timezoneList]
    return tz
  }
  get hasErrors() {
    return this.timeError && !this.isdisabled ? true : false
  }
  get isdisabled() {
    return this.toggleValue ? false : true
  }
  get isDateTimeSelected() {
    return this.dateSelected && this.timesSelected
  }

  get hasTags() {
    return this.selectedEpisodeSettings[this.ytModalId].hasTags
  }

  get initialVisuals() {
    return this.selectedEpisodeSettings[this.ytModalId].includeVisuals
  }

  get initialChapters() {
    return this.selectedEpisodeSettings[this.ytModalId].includeChapters
  }

  get shortsCriteria() {
    return this.selectedEpisodeSettings[this.ytModalId].shortsCriteria
  }
  get isYTShorts() {
    return this.selectedEpisodeSettings[this.ytModalId].isYTShorts
  }

  userSummaryInsert(value: string) {
    this.customDescription = true
    this.description = value
  }

  summary() {
    if (this.customDescription && this.includeVisuals && this.includeChapters) {
      this.plainDescription = this.description + this.chapterMarkers
    } else if (this.customDescription) {
      if (this.description.includes(this.chapterMarkers)) {
        this.description = this.description.replace(this.chapterMarkers, '')
      }
      this.plainDescription = this.description
    } else if (this.includeVisuals && this.includeChapters) {
      this.plainDescription =
        this.selectedEpisodeSettings[this.ytModalId].description.replace(/<[^>]*>?/gm, '') + this.chapterMarkers
    } else {
      this.plainDescription = this.selectedEpisodeSettings[this.ytModalId].description.replace(/<[^>]*>?/gm, '')
    }

    if (this.episodeKeywords.length > 0) {
      this.plainDescription =
        this.plainDescription +
        '\n\n' +
        'HashTags:' +
        '\n' +
        this.episodeKeywords.map((item: string) => '#' + item.replace(/\s+/g, '')).join(' ')
    }

    if (this.includeShortsValue) {
      this.plainDescription += `\n #Shorts`
    }
    return this.plainDescription
  }

  selectDate(value: string) {
    if (value && this.scheduleTime) {
      this.dateSelected = true
    }
  }

  selectTime(value: string) {
    if (value && this.scheduleDate) {
      this.timesSelected = true
    }
  }

  imageClicked() {
    if (this.networkId) {
      this.$store.dispatch('showFileUploader', {
        accept: 'image/*',
        onChange: this.loadImage,
      })
    } else {
      this.$store.commit('setTriggerLogin')
    }
  }

  async loadImage() {
    const file = this.$store.getters.selectedFile
    if (this.bytesToMegaBytes(file.size) > 2) {
      this.$store.dispatch('pushNotification', {
        text: 'Please upload image lesser than 2MB',
        type: 'WARNING',
      })
      return
    }
    if (file) {
      this.uploadingImage = true
      const img: any = await AdoriService.uploadImage(this.networkId, file)
      this.file = img
      this.imageId = img.id
      this.posterImageId = img.id
      Vue.set(img, 'thumbnailSrc', img.url)
      this.uploadingImage = false
    }
  }

  bytesToMegaBytes(bytes: any) {
    return bytes / (1024 * 1024)
  }

  toIsoString(date: any) {
    // eslint-disable-next-line one-var
    let tzo = -date.getTimezoneOffset(),
      dif = tzo >= 0 ? '+' : '-',
      pad = function (num: number) {
        const norm = Math.floor(Math.abs(num))
        return (norm < 10 ? '0' : '') + norm
      }

    return (
      date.getFullYear() +
      '-' +
      pad(date.getMonth() + 1) +
      '-' +
      pad(date.getDate()) +
      'T' +
      pad(date.getHours()) +
      ':' +
      pad(date.getMinutes()) +
      ':' +
      pad(date.getSeconds()) +
      dif +
      pad(tzo / 60) +
      ':' +
      pad(tzo % 60)
    )
  }

  calculateDateTime(scheduleDate: string, scheduleTime: string) {
    const offset = new Date().getTimezoneOffset() / 60
    let scheduleDat: number | string = scheduleDate
    scheduleDat = this.toIsoString(scheduleDate)
    let scheduleTi: number | string = scheduleTime
    scheduleTi = this.toIsoString(scheduleTime)
    let selectedOffset = this.timezone.substring(4, 10)
    const dateTime = `${scheduleDat.split('T')[0]}T${scheduleTi.split('T')[1].substring(0, 8)}${selectedOffset}`
    return dateTime
  }

  handleSave() {
    if (this.isSaveInValid) return

    const payload = this.ytModalSetting({
      guid: this.ytModalId,
      title: this.title,
      description: this.description,
      thumbnailSrc: this.thumbnailSrc,
      includeVisuals: this.includeVisuals,
      includeChapters: this.includeChapters,
      keywords: this.keywords,
      privacy: this.privacy,
      category: this.categoryId,
      publish: this.publish,
      scheduledFor: this.scheduledFor,
      isScheduled: this.isScheduled,
      scheduleDate: this.scheduleDate,
      scheduleTime: this.scheduleTime,
      timezone: this.timezone,
      customDescription: this.customDescription,
      posterImageId: this.posterImageId,
      playlistId: this.playlistId,
      isYTShorts: this.includeShortsValue,
      isMadeForKids: this.selfDeclareMadeForKids,
    })
    this.closeModal()
  }

  async created() {
    if (this.youtubeSignInStatus && this.getPlaylist.length === 0) {
      const payload: any = { networkId: this.networkId }
      this.signinStatus[this.ytFeedUid]?.youtube && (payload['showId'] = this.ytFeedUid)
      await this.youtubePlaylist(payload)
    }
  }

  mounted() {
    this.youtubeShowSignInStatus && (this.currentYoutubeLogin = 'Show Login')
    const episode = this.selectedEpisodeSettings[this.ytModalId]
    this.posterImageId = episode.posterImageId
    this.guid = episode.guid
    this.title = episode.title
    this.episodeKeywords = episode.episodeKeywords
    this.plainDescription = episode.description.replace(/<[^>]*>?/gm, '')
    this.description = episode.description
    this.keywords = episode.episodeKeywords.length ? episode.episodeKeywords : episode.keywords
    this.privacy = episode.privacy
    this.localPublish = episode.publish
    this.categoryId = episode.category
    this.width = episode.width
    this.height = episode.height
    this.startTimeSec = episode.startTimeSec
    this.endTimeSec = episode.endTimeSec
    this.scheduledFor = episode.scheduledFor
    this.toggleValue = episode.isScheduled
    this.scheduleDate = episode.scheduleDate
    this.scheduleTime = episode.scheduleTime
    this.includeShortsValue = episode.isYTShorts
    episode.playlistId && (this.playlistId = episode.playlistId)
    const isTimezone = episode.timezone
    if (isTimezone) {
      this.timezone = isTimezone
    } else {
      this.timezone = this.timezoneListData[0].title
    }
    this.chapterMarkers = episode.chapterMarkers
    this.customDescription = episode.customDescription
    this.includeVisuals = this.initialVisuals
    this.includeChapters = this.initialChapters
    this.selfDeclareMadeForKids = episode.isMadeForKids
    this.summary()
  }
  @Watch('dateSelected')
  @Watch('timesSelected')
  @Watch('isdisabled')
  @Watch('timezone')
  onDateTimeSelection() {
    if (!this.isdisabled) {
      let shortDate = new Date(this.scheduleDate).toLocaleDateString()
      this.publish = 'On ' + shortDate
      const dateTime = this.calculateDateTime(this.scheduleDate, this.scheduleTime)
      this.scheduledFor = dateTime
      this.isScheduled = true
    } else {
      this.publish = 'Immediately'
      this.scheduledFor = ''
      this.isScheduled = false
    }
  }
}
