var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "br-pill pill bg-adori-light-gray ba b--adori-gray relative flex items-center pa1 pointer",
      class: _vm.isLeft ? "justify-start" : "justify-end",
      on: {
        click: function ($event) {
          !_vm.disabled && _vm.$emit("on-change")
        },
      },
    },
    [
      _vm.isLeft
        ? _c("div", {
            class: _vm.disabled
              ? "br-100 bg-adori-gray  circle"
              : "br-100 bg-adori-red  circle",
          })
        : _c("div", {
            class: _vm.disabled
              ? "br-100 bg-adori-gray  circle"
              : "br-100 bg-adori-red  circle",
          }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }