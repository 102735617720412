








import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class BaseIconCircleSmall extends Vue {
  @Prop(String) icon!: string
  @Prop(Function) onClick!: () => void

  onButtonClick() {
    if (this.onClick) this.onClick()
  }
}
