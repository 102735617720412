var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.edit
      ? _c("input", {
          ref: "editTitle",
          staticClass: "bg-adori-very-light-gray bw0 br2 w-100 pv1 f6 white",
          attrs: { type: "text", placeholder: _vm.placeHolder },
          domProps: { value: _vm.valueLocal },
          on: {
            blur: function ($event) {
              _vm.valueLocal = $event.target.value
              _vm.edit = false
              _vm.$emit("change-title", _vm.valueLocal)
            },
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              _vm.valueLocal = $event.target.value
              _vm.edit = false
              _vm.$emit("change-title", _vm.valueLocal)
            },
          },
        })
      : _c(
          "div",
          {
            staticClass: "flex mv1",
            on: {
              click: function ($event) {
                _vm.edit = true
              },
            },
          },
          [
            _c("div", { staticClass: "edit truncate" }, [
              _vm._v(_vm._s(_vm.valueLocal ? _vm.valueLocal : _vm.placeHolder)),
            ]),
            _c("img", {
              staticClass: "ml2 pointer",
              attrs: { src: require("@/assets/audiogram/title-edit.svg") },
            }),
          ]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }