var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "relative" }, [
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.selectedLang,
            expression: "selectedLang",
          },
        ],
        staticClass:
          "input-reset light-gray bn br2 pa3 pr5 shadow-2 db arrow w-100 mb2 w2 scrollbar bg-adori-light-gray z-2",
        attrs: { disabled: _vm.languageDisabled },
        on: {
          change: [
            function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.selectedLang = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
            _vm.handleLangChange,
          ],
        },
      },
      _vm._l(_vm.languages || [], function (lang) {
        return _c(
          "option",
          {
            key: lang.code,
            staticClass: "scrollbar",
            staticStyle: { width: "30px" },
            domProps: { value: lang.code },
          },
          [_vm._v("\n      " + _vm._s(lang.name) + "\n    ")]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }