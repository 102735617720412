

















import { Component, Mixins, Prop } from 'vue-property-decorator'

import { mixin as clickaway } from 'vue-clickaway'

@Component
export default class BaseRateLimitPopup extends Mixins(clickaway) {
  @Prop() secondsLeft!: any
}
