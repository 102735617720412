var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticClass: "vue-toggles",
      style: _vm.bgStyle,
      attrs: {
        role: "switch",
        tabindex: "0",
        "aria-checked": _vm.value ? "true" : "false",
        "aria-readonly": _vm.disabled ? "true" : "false",
      },
      on: {
        click: function ($event) {
          !_vm.disabled ? _vm.$emit("click", _vm.value) : null
        },
        keyup: [
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            $event.preventDefault()
            !_vm.disabled ? _vm.$emit("click", _vm.value) : null
          },
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "space", 32, $event.key, [" ", "Spacebar"])
            ) {
              return null
            }
            $event.preventDefault()
            !_vm.disabled ? _vm.$emit("click", _vm.value) : null
          },
        ],
      },
    },
    [
      _c(
        "span",
        {
          staticClass: "dot",
          style: _vm.dotStyle,
          attrs: { "aria-hidden": "true" },
        },
        [
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.checkedText && _vm.value,
                  expression: "checkedText && value",
                },
              ],
              staticClass: "text",
              style: _vm.textStyle,
            },
            [_vm._v("\n      " + _vm._s(_vm.checkedText) + "\n    ")]
          ),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.uncheckedText && !_vm.value,
                  expression: "uncheckedText && !value",
                },
              ],
              staticClass: "text",
              style: _vm.textStyle,
            },
            [_vm._v("\n      " + _vm._s(_vm.uncheckedText) + "\n    ")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }