var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "light-gray fw5 f3" }, [
          _vm._v("Embed audio track"),
        ]),
      ]),
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "flex flex-column" }, [
          _c("div", { staticClass: "flex justify-center items-center mb3" }, [
            _c(
              "div",
              { staticClass: "pv1 ba br4 b--transparent bg-black-20" },
              [
                _c(
                  "label",
                  {
                    staticClass: "pv1 ph2 silver link pointer",
                    class:
                      _vm.orientation === "PORTRAIT"
                        ? "br4 bg-adori-red light-gray"
                        : "",
                  },
                  [
                    _c("input", {
                      staticClass: "input-reset",
                      attrs: { type: "radio" },
                      domProps: { checked: _vm.orientation === "PORTRAIT" },
                      on: {
                        click: function ($event) {
                          _vm.orientation = "PORTRAIT"
                        },
                      },
                    }),
                    _vm._v("Portrait"),
                  ]
                ),
                _c(
                  "label",
                  {
                    staticClass: "pv1 ph2 silver link pointer",
                    class:
                      _vm.orientation === "LANDSCAPE"
                        ? "br4 bg-adori-red light-gray"
                        : "",
                  },
                  [
                    _c("input", {
                      staticClass: "input-reset",
                      attrs: { type: "radio" },
                      domProps: { checked: _vm.orientation === "LANDSCAPE" },
                      on: {
                        click: function ($event) {
                          _vm.orientation = "LANDSCAPE"
                        },
                      },
                    }),
                    _vm._v("Landscape"),
                  ]
                ),
                _c(
                  "label",
                  {
                    staticClass: "pv1 ph2 silver link pointer",
                    class:
                      _vm.orientation === "CLASSIC_LANDSCAPE"
                        ? "br4 bg-adori-red light-gray"
                        : "",
                  },
                  [
                    _c("input", {
                      staticClass: "input-reset",
                      attrs: { type: "radio" },
                      domProps: {
                        checked: _vm.orientation === "CLASSIC_LANDSCAPE",
                      },
                      on: {
                        click: function ($event) {
                          _vm.orientation = "CLASSIC_LANDSCAPE"
                        },
                      },
                    }),
                    _vm._v("Classic Landscape"),
                  ]
                ),
              ]
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "flex justify-between",
              class:
                _vm.orientation === "PORTRAIT" ? "flex-row" : "flex-column",
            },
            [
              _vm.orientation === "PORTRAIT"
                ? _c(
                    "div",
                    { staticClass: "relative flex justify-center mr4" },
                    [
                      _c("img", {
                        attrs: {
                          height: "500",
                          src: require("@/assets/phone-portrait.svg"),
                        },
                      }),
                      _c("div", {
                        staticClass: "absolute portrait iphonex-portrait",
                        domProps: { innerHTML: _vm._s(_vm.iframePreviewEmbed) },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm.orientation === "LANDSCAPE"
                ? _c("div", {
                    staticClass: "relative landscape",
                    domProps: { innerHTML: _vm._s(_vm.iframePreviewEmbed) },
                  })
                : _vm._e(),
              _vm.orientation === "CLASSIC_LANDSCAPE"
                ? _c("div", {
                    staticClass: "relative classic-landscape",
                    domProps: { innerHTML: _vm._s(_vm.iframePreviewEmbed) },
                  })
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "pv1 light-gray flex flex-column mt4",
                  class: _vm.orientation === "PORTRAIT" ? "w6" : "",
                },
                [
                  _c("div", { staticClass: "f5 fw5 mb1 light-gray" }, [
                    _vm._v("Embed code"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "flex items-center mb3" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.iframePublicEmbed,
                            expression: "iframePublicEmbed",
                          },
                        ],
                        staticClass:
                          "db bg-adori-very-light-gray bw0 br2 ph2 pv2 f6 white flex-auto br--left",
                        domProps: { value: _vm.iframePublicEmbed },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.iframePublicEmbed = $event.target.value
                          },
                        },
                      }),
                      _c("BaseButtonRed", {
                        staticClass: "br--right",
                        attrs: {
                          icon: "assignment",
                          onClick: _vm.copyToClipboard,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "f6 silver lh-copy" }, [
                    _vm.orientation === "PORTRAIT"
                      ? _c("div", [
                          _vm._v(
                            "Portrait player is responsive across all sizes by default."
                          ),
                        ])
                      : _vm._e(),
                    _vm.orientation === "LANDSCAPE"
                      ? _c("div", [
                          _vm._v(
                            "\n              Landscape player has fixed height of 400px and responsive across all widths.\n            "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]
              ),
            ]
          ),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }