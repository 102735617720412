






























import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class BaseAdoriTagImage extends Vue {
  @Prop(String) src!: string
  @Prop(String) filter!: string
  @Prop(Boolean) bigger!: boolean
  @Prop(Boolean) enableCroppa!: boolean

  async getImageBlobData() {
    if (this.enableCroppa) {
      // @ts-ignore
      const blob = await this.$refs.croppaInstance.promisedBlob()
      return blob
    }
    return null
  }

  async getImageDataUrl() {
    if (this.enableCroppa) {
      // @ts-ignore
      const dataUrl = await this.$refs.croppaInstance.generateDataUrl()
      return dataUrl
    }
    return null
  }
}
