




































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { AudioTags } from '@/components/Monetize/monetize'
import { Action, Getter } from 'vuex-class'
import AdoriService from '@/services/adori'
import { uploadStatus } from '@/components/Publish/publish'
import Invalidate from '@/mixins/Invalidate'
import { audioQueryKeys } from '@/hooks/audio'

@Component
export default class ModalAudioAd extends Mixins(Invalidate) {
  @Prop(Boolean) modalCreate!: boolean

  @Getter networkId!: any
  @Getter audioPlayUrl!: string
  @Getter audioUploadStatus!: boolean

  @Action createAudioTrack!: any
  @Action clearAudioUploader!: any
  @Action getAudioAds!: any
  @Action pushNotification!: any

  audioType = 'Promo'
  isUploadingTag = false
  adName = ''
  btnStatus = true
  btnTxt = 'Uploading...'
  validationStatus = false

  @Watch('audioUploadStatus')
  setLoader() {
    if (this.audioUploadStatus === true) {
      this.btnStatus = false
      this.btnTxt = 'Create Ad'
    } else {
      this.btnStatus = true
      this.btnTxt = 'Uploading...'
    }
  }

  get audioTags() {
    return AudioTags
  }

  validation() {
    if (!this.adName) {
      this.validationStatus = true
      return true
    }
    this.validationStatus = false
    return false
  }

  async createAd() {
    if (this.validation() === true) return
    this.isUploadingTag = true
    const payload = {
      name: this.adName,
      type: this.audioType,
      category: 'AD',
    }

    try {
      await AdoriService.updateUpload(this.networkId, this.$store.getters.audioUploadId)
      const poll = setInterval(async () => {
        const res: any = await AdoriService.getUploadStatus(this.networkId, this.$store.getters.audioUploadId)

        if (res.processingStatus === uploadStatus.FINISHED) {
          clearInterval(poll)
          await this.createAudioTrack(payload)

          this.isUploadingTag = false
          if (this.modalCreate) {
            this.$emit('create')
          } else {
            this.queryClient.invalidateQueries(audioQueryKeys.AD)
            this.$store.dispatch('closeModal')
          }
        }
        if (res.processingStatus === uploadStatus.FAILED) {
          clearInterval(poll)
          this.$store.dispatch('pushNotification', {
            text: 'Upload Failed',
            type: 'ERROR',
          })
          this.isUploadingTag = false
        }
      }, 5000)
    } catch (e) {
      this.$store.dispatch('pushNotification', {
        text: 'Upload Failed',
        type: 'ERROR',
      })
    }
  }

  handleUploadAudio() {
    this.clearAudioUploader()
    this.$store.dispatch('uploadNewAd')
  }

  beforeDestroy() {
    this.clearAudioUploader()
  }
}
