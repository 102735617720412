import {
  useGetAudiogramTemplates,
  useGetAudiogramAssets,
  useGetAudiogramWaveforms,
  useGetAudiogramFonts,
} from '@/hooks/audiogram'
import { QueryClient, useQueryClient } from 'vue-query'
import { Component, Vue } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

import get from 'lodash.get'
import { fabric } from 'fabric'
import WebFontLoader from 'webfontloader'
import { orientationStatus } from '@/components/Publish/publish'

import AdoriService from '@/services/adori'
import AdoriServicev6 from '@/services/adori_v6'
import { imageQueryKeys } from '@/hooks/image'
import { videoQueryKeys } from '@/hooks/videos'
import { ref } from '@vue/composition-api'
import { downloadNumber } from '@/Interfaces/StoreInterFaces/accountInterfaces/SignupInterface'

@Component({
  setup() {
    const queryClient = useQueryClient()
    const orientation = ref('LANDSCAPE')
    const {
      data: audiogramTemplates,
      fetchNextPage: nextTemplatesPage,
      isFetchingNextPage: isNextTemplatesFetching,
      isLoading: isTemplatesLoading,
      hasNextPage: hasTemplatesNextPage,
    } = useGetAudiogramTemplates(orientation)

    useGetAudiogramAssets()
    useGetAudiogramWaveforms()
    useGetAudiogramFonts()
    return {
      queryClient,
      orientation,
      audiogramTemplates,
      nextTemplatesPage,
      isNextTemplatesFetching,
      isTemplatesLoading,
      hasTemplatesNextPage,
    }
  },
})
export default class Audiogram extends Vue {
  @Getter networkId!: string
  @Getter audiogramTemplateById!: any
  @Getter networkType!: any

  canvasState: string[] = []
  currentStateIndex: number = -1
  undoStatus: boolean = false
  redoStatus: boolean = false
  undoFinishedStatus: number = 1
  redoFinishedStatus: number = 1
  isUndoDisabled: boolean = false
  isRedoDisabled: boolean = true

  saveText = 'Save Changes'
  audiogramLoader = false
  loadingCanvas = false
  uploadingImage = false
  textSize: number = 30
  width: number = 1920
  height: number = 1080
  canvasContext!: any
  title: string = ''
  subtitle: string = ''
  artboardCtx!: any
  showTextToolbar: boolean = false
  showWaveColor: boolean = false
  showElementColor: boolean = false
  activeTab: string = 'templates'
  imageTab: string = 'image_upload'
  videoTab: string = 'video_upload'
  autoScaleVideo: boolean = true
  videoId: string = ''
  activeHover: boolean = false
  font: string = 'Inter'
  bgDragColor: boolean = false
  activeColor: string = 'color1'
  SCALE_FACTOR: number = 3
  OrientationList = [
    { id: 'LANDSCAPE', value: 'Landscape' },
    { id: 'SQUARE', value: 'Square' },
    { id: 'PORTRAIT', value: 'Portrait' },
  ]
  orientation!: string
  waveformName = '' // one of "WAVEFORM_LINE" "WAVEFORM_CENTER_LINE" "FREQUENCY_BAR" "FREQUENCY_LINE"
  displayTextColorPicker: boolean = false
  displayWaveformColorPicker: boolean = false
  displayElementColorPicker: boolean = false
  textSketchColors = {
    hex: '#FFFFFF',
  }
  waveformSketchColors = {
    hex: '#FFFFFF',
  }
  elementSketchColors = {
    hex: '#FFFFFF',
  }
  textColorValue = '#FFFFFF'
  waveformColorValue = '#FFFFFF'
  elementColorValue = '#FFFFFF'

  imageTypes = ['jpg', 'jpeg', 'png', 'svg', 'svg+xml']

  autoPublishTitle = false
  autoTitleLineLength = 20
  autoTitleLineHeight = 20
  isAutoTitle = false

  // text-align
  alignLeftActive: boolean = false
  alignCenterActive: boolean = false
  alignRightActive: boolean = false
  alignJustifyActive: boolean = false

  // text-format
  formatBold: boolean = false
  formatItalic: boolean = false
  formatStrikethrough: boolean = false
  formatUnderline: boolean = false

  //gradients
  color1 = new fabric.Gradient({
    type: 'linear',
    gradientUnits: 'pixels', // or 'percentage'
    coords: { x1: 0, y1: this.height / 2, x2: this.width, y2: 0 },
    colorStops: [
      { offset: 0, color: '#21D4FD' },
      { offset: 1, color: '#B721FF' },
    ],
  })

  color2 = new fabric.Gradient({
    type: 'linear',
    gradientUnits: 'pixels', // or 'percentage'
    coords: {
      x1: this.width / 2,
      y1: this.height / 2,
      x2: this.width / 2,
      y2: -this.height / 2,
    },
    colorStops: [
      { offset: 0, color: '#feada6' },
      { offset: 1, color: '#f5efef' },
    ],
  })
  color3 = new fabric.Gradient({
    type: 'linear',
    gradientUnits: 'pixels', // or 'percentage'
    coords: {
      x1: this.width / 2,
      y1: this.height / 2,
      x2: this.width / 2,
      y2: -this.height / 2,
    },
    colorStops: [
      { offset: 0, color: '#50cc7f' },
      { offset: 1, color: '#f5d100' },
    ],
  })

  color4 = new fabric.Gradient({
    type: 'linear',
    gradientUnits: 'pixels', // or 'percentage'
    coords: {
      x1: 0,
      y1: -this.height / 2,
      x2: this.width,
      y2: this.height / 2 + 100,
    },
    colorStops: [
      { offset: 0, color: '#0093E9' },
      { offset: 1, color: '#80D0C7' },
    ],
  })

  color5 = new fabric.Gradient({
    type: 'linear',
    gradientUnits: 'pixels', // or 'percentage'
    coords: { x1: 0, y1: this.height / 2, x2: this.width, y2: 0 },
    colorStops: [
      { offset: 0, color: '#4158D0' },
      { offset: 0.46, color: '#C850C0' },
      { offset: 1, color: '#FFCC70' },
    ],
  })

  color6 = new fabric.Gradient({
    type: 'linear',
    gradientUnits: 'pixels', // or 'percentage'
    coords: { x1: 0, y1: this.height / 2, x2: this.width, y2: 0 },
    colorStops: [
      { offset: 0, color: '#8EC5FC' },
      { offset: 1, color: '#E0C3FC' },
    ],
  })

  color7 = new fabric.Gradient({
    type: 'linear',
    gradientUnits: 'pixels', // or 'percentage'
    coords: { x1: 0, y1: this.height / 2, x2: this.width, y2: 0 },
    colorStops: [
      { offset: 0, color: '#FBAB7E' },
      { offset: 1, color: '#F7CE68' },
    ],
  })

  color8 = new fabric.Gradient({
    type: 'linear',
    gradientUnits: 'pixels', // or 'percentage'
    coords: { x1: 0, y1: -this.height / 2, x2: this.width, y2: this.height },
    colorStops: [
      { offset: 0, color: '#bd4f6c' },
      { offset: 0.74, color: '#d7816a' },
    ],
  })
  color9 = new fabric.Gradient({
    type: 'linear',
    gradientUnits: 'pixels', // or 'percentage'
    coords: { x1: 0, y1: -this.height / 2, x2: this.width, y2: this.height },
    colorStops: [
      { offset: 0, color: '#e899dc' },
      { offset: 0.74, color: '#d387ab' },
    ],
  })
  color10 = new fabric.Gradient({
    type: 'linear',
    gradientUnits: 'pixels', // or 'percentage'
    coords: { x1: 0, y1: -this.height / 2, x2: this.width, y2: this.height },
    colorStops: [
      { offset: 0, color: '#9e8fb2' },
      { offset: 0.74, color: '#a7acd9' },
    ],
  })
  color11 = new fabric.Gradient({
    type: 'linear',
    gradientUnits: 'pixels', // or 'percentage'
    coords: { x1: 0, y1: -this.height / 2, x2: this.width, y2: this.height },
    colorStops: [
      { offset: 0, color: '#4dccc6' },
      { offset: 0.74, color: '#96e4df' },
    ],
  })
  color12 = new fabric.Gradient({
    type: 'linear',
    gradientUnits: 'pixels', // or 'percentage'
    coords: { x1: 0, y1: -this.height / 2, x2: this.width, y2: this.height },
    colorStops: [
      { offset: 0, color: '#fde74c' },
      { offset: 0.74, color: '#32ff7a' },
      { offset: 0.1, color: '#2fcbe0' },
    ],
  })
  color13 = new fabric.Gradient({
    type: 'linear',
    gradientUnits: 'pixels', // or 'percentage'
    coords: { x1: 0, y1: -this.height / 2, x2: this.width, y2: this.height },
    colorStops: [
      { offset: 0, color: '#6e72fc' },
      { offset: 0.74, color: '#ad1deb' },
    ],
  })
  color14 = new fabric.Gradient({
    type: 'linear',
    gradientUnits: 'pixels', // or 'percentage'
    coords: { x1: 0, y1: -this.height / 2, x2: this.width, y2: this.height },
    colorStops: [
      { offset: 0, color: '#f5df2e' },
      { offset: 0.74, color: '#f07654' },
    ],
  })
  color15 = new fabric.Gradient({
    type: 'linear',
    gradientUnits: 'pixels', // or 'percentage'
    coords: { x1: 0, y1: -this.height / 2, x2: this.width, y2: this.height },
    colorStops: [
      { offset: 0, color: '#fdf86d' },
      { offset: 0.74, color: '#bddcf1' },
    ],
  })
  color16 = new fabric.Gradient({
    type: 'linear',
    gradientUnits: 'pixels', // or 'percentage'
    coords: { x1: 0, y1: -this.height / 2, x2: this.width, y2: this.height },
    colorStops: [
      { offset: 0, color: '#f5f4f2' },
      { offset: 0.74, color: '#ff4081' },
    ],
  })
  color17 = new fabric.Gradient({
    type: 'linear',
    gradientUnits: 'pixels', // or 'percentage'
    coords: { x1: 0, y1: -this.height / 2, x2: this.width, y2: this.height },
    colorStops: [
      { offset: 0, color: '#1dd1a1' },
      { offset: 0.74, color: '#d3d3d3' },
    ],
  })
  color18 = new fabric.Gradient({
    type: 'linear',
    gradientUnits: 'pixels', // or 'percentage'
    coords: { x1: 0, y1: -this.height / 2, x2: this.width, y2: this.height },
    colorStops: [
      { offset: 0, color: '#FFE53B' },
      { offset: 0.74, color: '#FF2525' },
    ],
  })

  color19 = new fabric.Gradient({
    type: 'linear',
    gradientUnits: 'pixels', // or 'percentage'
    coords: { x1: 0, y1: this.height / 2, x2: this.width, y2: 0 },
    colorStops: [
      { offset: 0, color: '#FBDA61' },
      { offset: 1, color: '#FF5ACD' },
    ],
  })

  color20 = new fabric.Gradient({
    type: 'linear',
    gradientUnits: 'pixels', // or 'percentage'
    coords: {
      x1: this.width / 2,
      y1: -this.height / 2,
      x2: this.width / 2,
      y2: this.height / 2,
    },
    colorStops: [
      { offset: 0, color: '#A9C9FF' },
      { offset: 1, color: '#FFBBEC' },
    ],
  })

  color21 = new fabric.Gradient({
    type: 'linear',
    gradientUnits: 'pixels', // or 'percentage'
    coords: {
      x1: this.width / 2,
      y1: -this.height / 2,
      x2: this.width / 2,
      y2: this.height / 2,
    },
    colorStops: [
      { offset: 0, color: '#7028e4' },
      { offset: 1, color: '#e5b2ca' },
    ],
  })
  color22 = new fabric.Gradient({
    type: 'linear',
    gradientUnits: 'pixels', // or 'percentage'
    coords: { x1: 0, y1: -this.height / 2, x2: this.width, y2: this.height },
    colorStops: [
      { offset: 0, color: '#63d471' },
      { offset: 0.74, color: '#233329' },
    ],
  })
  color23 = new fabric.Gradient({
    type: 'linear',
    gradientUnits: 'pixels', // or 'percentage'
    coords: { x1: 0, y1: -this.height / 2, x2: this.width, y2: this.height },
    colorStops: [
      { offset: 0, color: '#e84393' },
      { offset: 0.74, color: '#000000' },
    ],
  })
  color24 = new fabric.Gradient({
    type: 'linear',
    gradientUnits: 'pixels', // or 'percentage'
    coords: { x1: 0, y1: -this.height / 2, x2: this.width, y2: this.height },
    colorStops: [
      { offset: 0, color: '#ffa69e' },
      { offset: 0.74, color: '#5d4954' },
    ],
  })
  color25 = new fabric.Gradient({
    type: 'linear',
    gradientUnits: 'pixels', // or 'percentage'
    coords: { x1: 0, y1: -this.height / 2, x2: this.width, y2: this.height },
    colorStops: [
      { offset: 0, color: '#000000' },
      { offset: 0.74, color: '#7f8c8d' },
    ],
  })

  color26 = '#000'
  color27 = '#FFF'

  sourceComponent = 'AUDIOGRAM'

  queryClient!: QueryClient
  audiogramTemplates!: any
  nextTemplatesPage!: any
  isNextTemplatesFetching!: boolean
  isTemplatesLoading!: boolean
  hasTemplatesNextPage!: boolean

  setSourceComponent(source: string) {
    this.sourceComponent = source
  }

  get adminTemplates() {
    return this.audiogramTemplates
  }

  setActiveTab(tab: string) {
    this.activeTab = tab
  }

  async initCanvas(transparent: boolean) {
    const ref = this.$refs.can
    this.canvasContext = new fabric.Canvas(ref, {
      preserveObjectStacking: true,
      stateful: true,
      uniScaleKey: '',
      selection: false,
    })

    this.canvasContext.setWidth(this.width)
    this.canvasContext.setHeight(this.height)
    const fill = transparent ? 'transparent' : this.color1

    this.artboardCtx = new fabric.Rect({
      left: 0,
      top: 0,
      width: this.width,
      height: this.height,
      absolutePositioned: true,
      fill: fill,
      hasControls: true,
      typeThing: 'none',
      transparentCorners: false,
      borderColor: '#0E98FC',
      cornerColor: '#0E98FC',
      cursorWidth: 1,
      selectable: false,
      cursorDuration: 1,
      cursorDelay: 250,
      id: 'artboard',
      scaleX: this.orientation === 'PORTRAIT' ? 3.8 / 3 : 1,
      scaleY: this.orientation === 'PORTRAIT' ? 3.8 / 3 : 1,
    })

    // Clip canvas to the artboard
    // this.canvasContext.clipPath = this.artboardCtx
    this.canvasContext.add(this.artboardCtx)
    this.canvasContext.renderAll()

    this.downScaleCanvas(this.orientation)

    // Customize controls
    fabric.Object.prototype.set({
      transparentCorners: false,
      borderColor: '#51B9F9',
      cornerColor: '#FFF',
      borderScaleFactor: 2.5,
      cornerStyle: 'circle',
      cornerStrokeColor: '#0E98FC',
      borderOpacityWhenMoving: 1,
    })

    this.canvasContext.selectionColor = 'rgba(46, 115, 252, 0.11)'
    this.canvasContext.selectionBorderColor = 'rgba(98, 155, 255, 0.81)'
    this.canvasContext.selectionLineWidth = 1.5

    let img = new Image()
    let img2 = new Image()
    let img3 = new Image()
    let img4 = new Image()

    const images = [img, img2, img3, img4]

    function imageIsLoaded(image: any) {
      return new Promise<void>((resolve) => {
        image.onload = () => resolve()
        image.onerror = () => resolve()
      })
    }

    Promise.all(images.map(imageIsLoaded)).then(() => {
      img.src = '@/assests/audiogram/canvas-controls/middlecontrol.svg'
      img2.src = '@/assests/audiogram/canvas-controls/middlecontrolhoz.svg'
      img3.src = '@/assests/audiogram/canvas-controls/edgecontrol.svg'
      img4.src = '@/assests/audiogram/canvas-controls/rotateicon.svg'

      function renderIcon(ctx: any, left: any, top: any, styleOverride: any, fabricObject: any) {
        const wsize = 20
        const hsize = 25
        ctx.save()
        ctx.translate(left, top)
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle))
        ctx.drawImage(img, -wsize / 2, -hsize / 2, wsize, hsize)
        ctx.restore()
      }
      function renderIconHoz(ctx: any, left: any, top: any, styleOverride: any, fabricObject: any) {
        const wsize = 25
        const hsize = 20
        ctx.save()
        ctx.translate(left, top)
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle))
        ctx.drawImage(img2, -wsize / 2, -hsize / 2, wsize, hsize)
        ctx.restore()
      }
      function renderIconEdge(ctx: any, left: any, top: any, styleOverride: any, fabricObject: any) {
        const wsize = 25
        const hsize = 25
        ctx.save()
        ctx.translate(left, top)
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle))
        ctx.drawImage(img3, -wsize / 2, -hsize / 2, wsize, hsize)
        ctx.restore()
      }

      function renderIconRotate(ctx: any, left: any, top: any, styleOverride: any, fabricObject: any) {
        const wsize = 40
        const hsize = 40
        ctx.save()
        ctx.translate(left, top)
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle))
        ctx.drawImage(img4, -wsize / 2, -hsize / 2, wsize, hsize)
        ctx.restore()
      }
      function resetControls() {
        fabric.Object.prototype.controls.ml = new fabric.Control({
          x: -0.5,
          y: 0,
          offsetX: -1,
          cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
          actionHandler: fabric.controlsUtils.scalingXOrSkewingY,
          getActionName: fabric.controlsUtils.scaleOrSkewActionName,
          render: renderIcon,
        })

        fabric.Object.prototype.controls.mr = new fabric.Control({
          x: 0.5,
          y: 0,
          offsetX: 1,
          cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
          actionHandler: fabric.controlsUtils.scalingXOrSkewingY,
          getActionName: fabric.controlsUtils.scaleOrSkewActionName,
          render: renderIcon,
        })

        fabric.Object.prototype.controls.mb = new fabric.Control({
          x: 0,
          y: 0.5,
          offsetY: 1,
          cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
          actionHandler: fabric.controlsUtils.scalingYOrSkewingX,
          getActionName: fabric.controlsUtils.scaleOrSkewActionName,
          render: renderIconHoz,
        })

        fabric.Object.prototype.controls.mt = new fabric.Control({
          x: 0,
          y: -0.5,
          offsetY: -1,
          cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
          actionHandler: fabric.controlsUtils.scalingYOrSkewingX,
          getActionName: fabric.controlsUtils.scaleOrSkewActionName,
          render: renderIconHoz,
        })

        fabric.Object.prototype.controls.tl = new fabric.Control({
          x: -0.5,
          y: -0.5,
          cursorStyleHandler: fabric.controlsUtils.scaleCursorStyleHandler,
          actionHandler: fabric.controlsUtils.scalingEqually,
          render: renderIconEdge,
        })

        fabric.Object.prototype.controls.tr = new fabric.Control({
          x: 0.5,
          y: -0.5,
          cursorStyleHandler: fabric.controlsUtils.scaleCursorStyleHandler,
          actionHandler: fabric.controlsUtils.scalingEqually,
          render: renderIconEdge,
        })

        fabric.Object.prototype.controls.bl = new fabric.Control({
          x: -0.5,
          y: 0.5,
          cursorStyleHandler: fabric.controlsUtils.scaleCursorStyleHandler,
          actionHandler: fabric.controlsUtils.scalingEqually,
          render: renderIconEdge,
        })

        fabric.Object.prototype.controls.br = new fabric.Control({
          x: 0.5,
          y: 0.5,
          cursorStyleHandler: fabric.controlsUtils.scaleCursorStyleHandler,
          actionHandler: fabric.controlsUtils.scalingEqually,
          render: renderIconEdge,
        })

        fabric.Object.prototype.controls.mtr = new fabric.Control({
          x: 0,
          y: 0.5,
          cursorStyleHandler: fabric.controlsUtils.rotationStyleHandler,
          actionHandler: fabric.controlsUtils.rotationWithSnapping,
          offsetY: 30,
          withConnecton: false,
          actionName: 'rotate',
          render: renderIconRotate,
        })
      }
      resetControls()
      fabric.Textbox.prototype.controls = {}
      let textBoxControls = fabric.Textbox.prototype.controls

      textBoxControls.mtr = fabric.Object.prototype.controls.mtr
      textBoxControls.tr = fabric.Object.prototype.controls.tr
      textBoxControls.br = fabric.Object.prototype.controls.br
      textBoxControls.tl = fabric.Object.prototype.controls.tl
      textBoxControls.bl = fabric.Object.prototype.controls.bl
      textBoxControls.mt = fabric.Object.prototype.controls.mt
      textBoxControls.mb = fabric.Object.prototype.controls.mb

      textBoxControls.ml = new fabric.Control({
        x: -0.5,
        y: 0,
        offsetX: -1,
        cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
        actionHandler: fabric.controlsUtils.changeWidth,
        actionName: 'resizing',
        render: renderIcon,
      })

      textBoxControls.mr = new fabric.Control({
        x: 0.5,
        y: 0,
        offsetX: 1,
        cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
        actionHandler: fabric.controlsUtils.changeWidth,
        actionName: 'resizing',
        render: renderIcon,
      })
    })

    // End customize

    // A selection has been made in the canvas
    this.canvasContext.on('selection:created', (opt: any) => {
      this.checkText()
    })

    // A selection has been updated in the canvas
    this.canvasContext.on('selection:updated', (e: any) => {
      this.checkText()
    })

    // A selection has been cleared in the canvas
    this.canvasContext.on('selection:cleared', (e: any) => {
      this.showTextToolbar = false
      this.showWaveColor = false
      this.showElementColor = false
    })

    // object has been added in the canvas
    this.canvasContext.on('object:added', (e: any) => {
      this.updateCanvasState()
    })

    // object has been modified in the canvas
    this.canvasContext.on('object:modified', (e: any) => {
      this.updateCanvasState()
      //always top layer
      this.canvasContext.getObjects().forEach((obj: any) => {
        //check if autopublish's episode title is present in canvas
        if (obj.id && obj.id === 'autoTitle') {
          this.canvasContext.bringToFront(obj)
          this.canvasContext.renderAll()
        }
      })
    })

    //initialize background for undo
    this.updateCanvasState()
  }

  initCanvasSize(orientation: string) {
    this.orientation = orientation
    if (orientation === orientationStatus.SQUARE) {
      this.width = 1080
      this.height = 1080
    } else if (orientation === orientationStatus.LANDSCAPE) {
      this.width = 1920
      this.height = 1080
    } else {
      this.width = 1080
      this.height = 1920
    }
  }

  downScaleCanvas(size: string, isResize = false) {
    this.SCALE_FACTOR = size === 'PORTRAIT' ? 3.8 : 3
    this.canvasContext.setHeight(this.height * (1 / this.SCALE_FACTOR))
    this.canvasContext.setWidth(this.width * (1 / this.SCALE_FACTOR))
    if (!isResize) {
      this.canvasContext.getObjects().forEach((obj: any) => {
        let tempScaleX = obj.scaleX * (1 / this.SCALE_FACTOR)
        let tempScaleY = obj.scaleY * (1 / this.SCALE_FACTOR)
        let tempLeft = obj.left * (1 / this.SCALE_FACTOR)
        let tempTop = obj.top * (1 / this.SCALE_FACTOR)

        obj.scaleX = tempScaleX
        obj.scaleY = tempScaleY
        obj.left = tempLeft
        obj.top = tempTop

        obj.setCoords()
      })
    }
    this.canvasContext.renderAll()
  }

  upScaleCanvas() {
    this.SCALE_FACTOR = this.orientation === 'PORTRAIT' ? 3.8 : 3
    this.canvasContext.setHeight(this.canvasContext.getHeight() * this.SCALE_FACTOR)
    this.canvasContext.setWidth(this.canvasContext.getWidth() * this.SCALE_FACTOR)

    this.canvasContext.getObjects().forEach((obj: any) => {
      let tempScaleX = obj.scaleX * this.SCALE_FACTOR
      let tempScaleY = obj.scaleY * this.SCALE_FACTOR
      let tempLeft = obj.left * this.SCALE_FACTOR
      let tempTop = obj.top * this.SCALE_FACTOR

      obj.scaleX = tempScaleX
      obj.scaleY = tempScaleY
      obj.left = tempLeft
      obj.top = tempTop

      obj.setCoords()
    })
  }

  changeTextSize(e: any) {
    // this.canvasContext.getActiveObject().enterEditing()
    this.canvasContext.getActiveObject().selectAll()
    let scaleX = this.canvasContext.getActiveObject().scaleX
    this.canvasContext.getActiveObject().set({ fontSize: e.target.value / scaleX })
    // this.canvasContext.getActiveObject().exitEditing()
    this.canvasContext.renderAll()
  }

  // Change text format
  changeTextFormat(id: string) {
    this.canvasContext.getActiveObject().enterEditing()
    this.canvasContext.getActiveObject().selectAll()

    if (id == 'bold') {
      if (this.formatBold == true) {
        this.formatBold = false
        this.canvasContext.getActiveObject().setSelectionStyles({ fontWeight: 'normal' })
      } else {
        this.formatBold = true
        this.canvasContext.getActiveObject().setSelectionStyles({ fontWeight: 'bold' })
      }
    } else if (id == 'italic') {
      if (this.formatItalic == true) {
        this.formatItalic = false
        this.canvasContext.getActiveObject().setSelectionStyles({ fontStyle: 'normal' })
      } else {
        this.formatItalic = true
        this.canvasContext.getActiveObject().setSelectionStyles({ fontStyle: 'italic' })
      }
    } else if (id == 'underline') {
      if (this.formatUnderline == true) {
        this.formatUnderline = false
        this.canvasContext.getActiveObject().setSelectionStyles({ underline: false })
      } else {
        this.formatUnderline = true
        this.canvasContext.getActiveObject().setSelectionStyles({ underline: true })
      }
    } else {
      if (this.formatStrikethrough == true) {
        this.formatStrikethrough = false
        this.canvasContext.getActiveObject().setSelectionStyles({ linethrough: false })
      } else {
        this.formatStrikethrough = true
        this.canvasContext.getActiveObject().setSelectionStyles({ linethrough: true })
      }
    }

    this.canvasContext.getActiveObject().exitEditing()
    this.canvasContext.renderAll()
  }

  // Change text alignment
  alignText(id: string) {
    let textalign
    if (id == 'left') {
      this.alignLeftActive = true
      this.alignCenterActive = false
      this.alignRightActive = false
      this.alignJustifyActive = false
      textalign = 'left'
    }
    if (id == 'center') {
      this.alignCenterActive = true
      this.alignLeftActive = false
      this.alignRightActive = false
      this.alignJustifyActive = false
      textalign = 'center'
    }
    if (id == 'right') {
      this.alignRightActive = true
      this.alignLeftActive = false
      this.alignCenterActive = false
      this.alignJustifyActive = false
      textalign = 'right'
    }
    if (id == 'justify') {
      this.alignJustifyActive = true
      this.alignLeftActive = false
      this.alignCenterActive = false
      this.alignRightActive = false
      textalign = 'justify'
    }
    this.canvasContext.getActiveObject().set({ textAlign: textalign })
    this.canvasContext.renderAll()
  }

  checkText() {
    if (this.canvasContext.getActiveObject().type == 'textbox') {
      let object = this.canvasContext.getActiveObject()
      this.font = object.get('fontFamily')
      this.textSize = object.get('fontSize')
      this.textColorValue = object.get('fill')
      if (object.get('textAlign') == 'left') {
        this.alignLeftActive = true
        this.alignCenterActive = false
        this.alignRightActive = false
        this.alignJustifyActive = false
      } else if (object.get('textAlign') == 'center') {
        this.alignCenterActive = true
        this.alignLeftActive = false
        this.alignRightActive = false
        this.alignJustifyActive = false
      } else if (object.get('textAlign') == 'right') {
        this.alignRightActive = true
        this.alignCenterActive = false
        this.alignLeftActive = false
        this.alignJustifyActive = false
      } else {
        this.alignJustifyActive = true
        this.alignCenterActive = false
        this.alignRightActive = false
        this.alignLeftActive = false
      }

      if (object.get('fontWeight') == 'bold' || object.get('fontWeight') == 700) {
        this.formatBold = true
      } else {
        this.formatBold = false
      }

      if (get(object, 'styles.0.0.fontStyle') == 'italic') {
        this.formatItalic = true
      } else {
        this.formatItalic = false
      }
      if (get(object, 'styles.0.0.underline') == true) {
        this.formatUnderline = true
      } else {
        this.formatUnderline = false
      }
      if (get(object, 'styles.0.0.linethrough') == true) {
        this.formatStrikethrough = true
      } else {
        this.formatStrikethrough = false
      }

      this.showTextToolbar = true

      if (this.canvasContext.getActiveObject().id == 'autoTitle') {
        this.isAutoTitle = true
      } else {
        this.isAutoTitle = false
      }
    } else if (this.canvasContext.getActiveObject().id == 'waveform') {
      this.showWaveColor = true
      this.showTextToolbar = false
      this.showElementColor = false
      this.isAutoTitle = false
    } else if (this.canvasContext.getActiveObject().id == 'element') {
      this.showElementColor = true
      this.showWaveColor = false
      this.showTextToolbar = false
      this.isAutoTitle = false
    } else {
      this.showElementColor = false
      this.showWaveColor = false
      this.showTextToolbar = false
      this.isAutoTitle = false
    }
  }

  // Switch artboard background
  switchBackgroundFill(attr: any) {
    if (attr.id) {
      let colorId = attr.id.value
      this.activeColor = colorId
      this.artboardCtx.set('opacity', 1)
      // @ts-ignore
      this.artboardCtx.set('fill', this[colorId])
    }

    this.canvasContext.renderAll()
  }

  // Delete object
  deleteObject() {
    let obj = this.canvasContext.getActiveObject()
    if (obj.id && obj.id === 'autoTitle') {
      this.autoPublishTitle = false
    }

    this.canvasContext.remove(this.canvasContext.getActiveObject())
    this.canvasContext.renderAll()
  }

  moveObjectToFront() {
    this.canvasContext.bringForward(this.canvasContext.getActiveObject())
    this.canvasContext.renderAll()
  }

  moveObjectToBack() {
    this.canvasContext.sendBackwards(this.canvasContext.getActiveObject())
    this.canvasContext.renderAll()
  }

  loadImageFromUrl(src: string, tagData?: any) {
    const isSuggestedTag = tagData?.suggestedTag
    fabric.Image.fromURL(
      src,
      (img: any) => {
        let oImg = img.set({
          left: isSuggestedTag ? 0 : this.canvasContext.get('width') / 4,
          top: isSuggestedTag ? 0 : this.canvasContext.get('height') / 4,
        })

        this.canvasContext.add(oImg)
        oImg.scaleToWidth(this.canvasContext.get('width') / (isSuggestedTag ? 1 : 2))
        this.canvasContext.setActiveObject(oImg)
        this.canvasContext.bringToFront(oImg)
        if (!tagData?.tagId && tagData?.data.caption) {
          this.newTextbox(tagData.data.caption, 'caption')
        }
        this.canvasContext.renderAll()
        //   this.centerObject(oImg, this.canvasContext)
      },
      { crossOrigin: 'Anonymous' }
    )
  }

  toggleAutoScaleVideo() {
    this.autoScaleVideo = !this.autoScaleVideo
  }

  loadVideo(video: any) {
    this.getVideoElement(video.originalUrl)
    this.videoId = video.id
    this.sourceComponent === 'TAG' && this.$store.commit('setTagVideoId', video.id)
  }

  getVideoElement(url: any, videoInEditMode?: any) {
    let videoE = document.createElement('video')
    videoE.muted = true
    videoE.autoplay = true
    videoE.loop = true
    videoE.crossOrigin = 'anonymous'
    let source = document.createElement('source')
    source.src = url
    source.type = 'video/mp4'
    videoE.appendChild(source)
    videoE.addEventListener('loadedmetadata', () => {
      videoE.width = videoE.videoWidth
      videoE.height = videoE.videoHeight
      this.loadVideoFromUrl(videoE, videoInEditMode)
    })
  }

  loadVideoFromUrl(videoE: any, videoInEditMode: any) {
    let that = this
    let aCoords: any, fab_video: any, videoObj: any

    //only one video per tag is allowed
    this.canvasContext.getObjects().forEach((obj: any) => {
      if (obj.id && obj.id === 'video') {
        videoObj = obj.aCoords
        this.canvasContext.remove(obj)
        if (videoInEditMode) {
          aCoords = {
            tl: new fabric.Point(videoObj.tl.x, videoObj.tl.y),
            tr: new fabric.Point(videoObj.tr.x, videoObj.tr.y),
            br: new fabric.Point(videoObj.br.x, videoObj.br.y),
            bl: new fabric.Point(videoObj.bl.x, videoObj.bl.y),
          }
        }
      }
    })
    if (videoInEditMode) {
      fab_video = new fabric.Image(videoE, {
        id: 'video',
        left: aCoords.tl.lerp(aCoords.br).x,
        top: aCoords.tl.lerp(aCoords.br).y,
        scaleX: aCoords.tl.distanceFrom(aCoords.tr) / videoE.width,
        scaleY: aCoords.tl.distanceFrom(aCoords.bl) / videoE.height,
        angle: fabric.util.radiansToDegrees(Math.atan2(aCoords.tr.y - aCoords.tl.y, aCoords.tr.x - aCoords.tl.x)),
        originX: 'center',
        originY: 'center',
      })
      //hide rotation
      fab_video.setControlsVisibility({ mtr: false })
      this.canvasContext.add(fab_video)
    } else {
      fab_video = new fabric.Image(videoE, {
        id: 'video',
        left: this.autoScaleVideo ? 0 : this.canvasContext.get('width') / 4,
        top: this.autoScaleVideo ? 0 : this.canvasContext.get('height') / 4,
      })

      //hide rotation
      fab_video.setControlsVisibility({ mtr: false })
      this.canvasContext.add(fab_video)
      if (this.autoScaleVideo) {
        fab_video.scaleToWidth(this.canvasContext.get('width'))
        fab_video.scaleToHeight(this.canvasContext.get('height'))
      } else {
        fab_video.scaleToWidth(this.canvasContext.get('width') / 2)
        fab_video.scaleToHeight(this.canvasContext.get('height') / 2)
      }
    }
    fab_video.getElement().play()
    let request: any

    fabric.util.requestAnimFrame(function render() {
      that.canvasContext.renderAll()
      fabric.util.requestAnimFrame(render)
    })
  }

  // Change font
  changeFont(family: string) {
    WebFontLoader.load({
      google: {
        families: [family],
      },
      //font loading sequence events->loading, active,inactive,
      active: () => {
        this.canvasContext.getActiveObject().set('fontFamily', family)
        this.updateCanvasState()
        this.canvasContext.renderAll()
      },
    })
  }

  resizeCanvas(size: string) {
    this.initCanvasSize(size)
    this.downScaleCanvas(size, true)
    this.artboardCtx.set('width', this.width)
    this.artboardCtx.set('height', this.height)
    this.canvasContext.renderAll()
    this.initCenteringGuidelines(this.canvasContext)
    this.initAligningGuidelines(this.canvasContext)
  }

  videoUploader() {
    this.$store.dispatch('showFileUploader', {
      accept: 'video/mp4',
      onChange: this.loadMp4,
    })
  }

  loadMp4() {
    let download = get(downloadNumber, `[${this.networkType}]`, downloadNumber.YOUTUBE_METERED)
    if (this.bytesToMegaBytes(this.$store.getters.selectedFile.size) > download) {
      this.$store.dispatch('pushNotification', {
        text: 'Please enter file lesser than ' + download + 'MB',
        type: 'WARNING',
      })
      return
    }
    const file = this.$store.getters.selectedFile
    if (file) {
      this.uploadVideo(file)
    }
  }

  bytesToMegaBytes(bytes: any) {
    return bytes / (1024 * 1024)
  }

  async uploadVideo(file: any) {
    this.uploadingImage = true
    try {
      const video = await AdoriServicev6.createVideo(this.networkId, file)
      this.queryClient.invalidateQueries(videoQueryKeys.LIB_VIDEOS)
      this.uploadingImage = false
    } catch (error) {
      console.log(error)
      this.uploadingImage = false
    }
  }

  imageUploader() {
    this.$store.dispatch('showFileUploader', {
      accept: 'image/*',
      onChange: this.loadImageFile,
    })
  }

  async loadImageFile() {
    const file = this.$store.getters.selectedFile
    if (file) {
      this.uploadImage(file)
    }
  }

  async dropImageFile(e: any) {
    this.bgDragColor = false
    const file = e.dataTransfer.files[0]
    const type = get(file, 'type', '')
    if (!type.includes('image')) {
      this.$store.dispatch('pushNotification', {
        text: 'Please choose valid image',
        type: 'ERROR',
      })
      return
    }
    if (file) {
      this.uploadImage(file)
    }
  }

  async dropVideoFile(e: any) {
    this.bgDragColor = false
    const file = e.dataTransfer.files[0]
    const type = get(file, 'type', '')
    if (!type.includes('video/mp4')) {
      this.$store.dispatch('pushNotification', {
        text: 'Please choose valid video',
        type: 'ERROR',
      })
      return
    }
    if (this.bytesToMegaBytes(file.size) > 60) {
      this.$store.dispatch('pushNotification', {
        text: 'Please upload video lesser than 60MB',
        type: 'WARNING',
      })
      return
    }
    if (file) {
      this.uploadVideo(file)
    }
  }

  async uploadImage(file: any) {
    this.uploadingImage = true
    try {
      const img: any = await AdoriService.uploadImage(this.networkId, file)
      if (img) {
        let asset = { fileUrl: img.url, mimeType: img.mimeType }
        this.loadAsset(asset, 'elements')
      }
      this.queryClient.invalidateQueries(imageQueryKeys.LIB_IMAGES)
      this.uploadingImage = false
    } catch (error) {
      console.log(error)
      this.uploadingImage = false
    }
  }

  downloadImage() {
    this.upScaleCanvas()
    // @ts-ignore
    this.$refs['imageDownloadRef'].href = this.canvasContext.toDataURL({
      format: 'jpeg',
      quality: 1.0,
      // multiplier:0.25 -> 25% scaled
    })
    this.downScaleCanvas(this.orientation)
    const imageDownloadLink = this.$refs.imageDownloadRef as HTMLElement
    imageDownloadLink.click()
  }

  clearCanvas() {
    this.canvasContext.clear()
    // Clip canvas to the artboard
    this.canvasContext.clipPath = this.artboardCtx
    this.artboardCtx.set('fill', this.color1)
    this.canvasContext.add(this.artboardCtx)
    this.canvasContext.renderAll()
  }

  initCenteringGuidelines(canvas: any) {
    let canvasWidth = canvas.getWidth(),
      canvasHeight = canvas.getHeight(),
      canvasWidthCenter = canvasWidth / 2,
      canvasHeightCenter = canvasHeight / 2,
      canvasWidthCenterMap: any = {},
      canvasHeightCenterMap: any = {},
      centerLineMargin = 4,
      centerLineColor = 'rgba(255,0,241,0.5)',
      centerLineWidth = 1,
      ctx = canvas.getSelectionContext(),
      viewportTransform: any

    for (let i = canvasWidthCenter - centerLineMargin, len = canvasWidthCenter + centerLineMargin; i <= len; i++) {
      canvasWidthCenterMap[Math.round(i)] = true
    }
    for (let i = canvasHeightCenter - centerLineMargin, len = canvasHeightCenter + centerLineMargin; i <= len; i++) {
      canvasHeightCenterMap[Math.round(i)] = true
    }

    function showVerticalCenterLine() {
      showCenterLine(canvasWidthCenter + 0.5, 0, canvasWidthCenter + 0.5, canvasHeight)
    }

    function showHorizontalCenterLine() {
      showCenterLine(0, canvasHeightCenter + 0.5, canvasWidth, canvasHeightCenter + 0.5)
    }

    function showCenterLine(x1: any, y1: any, x2: any, y2: any) {
      ctx.save()
      ctx.strokeStyle = centerLineColor
      ctx.lineWidth = centerLineWidth
      ctx.beginPath()
      ctx.moveTo(x1 * viewportTransform[0], y1 * viewportTransform[3])
      ctx.lineTo(x2 * viewportTransform[0], y2 * viewportTransform[3])
      ctx.stroke()
      ctx.restore()
    }

    let afterRenderActions = [],
      isInVerticalCenter: any,
      isInHorizontalCenter: any

    canvas.on('mouse:down', function () {
      viewportTransform = canvas.viewportTransform
    })

    canvas.on('object:moving', function (e: any) {
      let object = e.target,
        objectCenter = object.getCenterPoint(),
        transform = canvas._currentTransform

      if (!transform) return
      ;(isInVerticalCenter = Math.round(objectCenter.x) in canvasWidthCenterMap),
        (isInHorizontalCenter = Math.round(objectCenter.y) in canvasHeightCenterMap)

      if (isInHorizontalCenter || isInVerticalCenter) {
        object.setPositionByOrigin(
          new fabric.Point(
            isInVerticalCenter ? canvasWidthCenter : objectCenter.x,
            isInHorizontalCenter ? canvasHeightCenter : objectCenter.y
          ),
          'center',
          'center'
        )
      }
    })

    canvas.on('before:render', function () {
      const ct = canvas.contextTop
      if (ct) canvas.clearContext(ct)
    })

    canvas.on('after:render', function () {
      if (isInVerticalCenter) {
        showVerticalCenterLine()
      }
      if (isInHorizontalCenter) {
        showHorizontalCenterLine()
      }
    })

    canvas.on('mouse:up', function () {
      // clear these values, to stop drawing guidelines once mouse is up
      isInVerticalCenter = isInHorizontalCenter = null
      canvas.renderAll()
    })
  }

  initAligningGuidelines(canvas: any) {
    let ctx = canvas.getSelectionContext(),
      aligningLineOffset = 5,
      aligningLineMargin = 4,
      aligningLineWidth = 1,
      aligningLineColor = 'rgb(0,255,0)',
      viewportTransform: any,
      zoom = 1

    function drawVerticalLine(coords: any) {
      drawLine(
        coords.x + 0.5,
        coords.y1 > coords.y2 ? coords.y2 : coords.y1,
        coords.x + 0.5,
        coords.y2 > coords.y1 ? coords.y2 : coords.y1
      )
    }

    function drawHorizontalLine(coords: any) {
      drawLine(
        coords.x1 > coords.x2 ? coords.x2 : coords.x1,
        coords.y + 0.5,
        coords.x2 > coords.x1 ? coords.x2 : coords.x1,
        coords.y + 0.5
      )
    }

    function drawLine(x1: any, y1: any, x2: any, y2: any) {
      ctx.save()
      ctx.lineWidth = aligningLineWidth
      ctx.strokeStyle = aligningLineColor
      ctx.beginPath()
      ctx.moveTo((x1 + viewportTransform[4]) * zoom, (y1 + viewportTransform[5]) * zoom)
      ctx.lineTo((x2 + viewportTransform[4]) * zoom, (y2 + viewportTransform[5]) * zoom)
      ctx.stroke()
      ctx.restore()
    }

    function isInRange(value1: any, value2: any) {
      value1 = Math.round(value1)
      value2 = Math.round(value2)
      for (let i = value1 - aligningLineMargin, len = value1 + aligningLineMargin; i <= len; i++) {
        if (i === value2) {
          return true
        }
      }
      return false
    }

    let verticalLines: any[] = [],
      horizontalLines: any[] = []

    canvas.on('mouse:down', function () {
      viewportTransform = canvas.viewportTransform
      zoom = canvas.getZoom()
    })

    canvas.on('object:moving', function (e: any) {
      let activeObject = e.target,
        canvasObjects = canvas.getObjects(),
        activeObjectCenter = activeObject.getCenterPoint(),
        activeObjectLeft = activeObjectCenter.x,
        activeObjectTop = activeObjectCenter.y,
        activeObjectBoundingRect = activeObject.getBoundingRect(),
        activeObjectHeight = activeObjectBoundingRect.height / viewportTransform[3],
        activeObjectWidth = activeObjectBoundingRect.width / viewportTransform[0],
        horizontalInTheRange = false,
        verticalInTheRange = false,
        transform = canvas._currentTransform

      if (!transform) return

      for (let i = canvasObjects.length; i--; ) {
        if (canvasObjects[i] === activeObject) continue

        let objectCenter = canvasObjects[i].getCenterPoint(),
          objectLeft = objectCenter.x,
          objectTop = objectCenter.y,
          objectBoundingRect = canvasObjects[i].getBoundingRect(),
          objectHeight = objectBoundingRect.height / viewportTransform[3],
          objectWidth = objectBoundingRect.width / viewportTransform[0]

        // snap by the horizontal center line
        if (isInRange(objectLeft, activeObjectLeft)) {
          verticalInTheRange = true
          verticalLines.push({
            x: objectLeft,
            y1:
              objectTop < activeObjectTop
                ? objectTop - objectHeight / 2 - aligningLineOffset
                : objectTop + objectHeight / 2 + aligningLineOffset,
            y2:
              activeObjectTop > objectTop
                ? activeObjectTop + activeObjectHeight / 2 + aligningLineOffset
                : activeObjectTop - activeObjectHeight / 2 - aligningLineOffset,
          })
          activeObject.setPositionByOrigin(new fabric.Point(objectLeft, activeObjectTop), 'center', 'center')
        }

        // snap by the left edge
        if (isInRange(objectLeft - objectWidth / 2, activeObjectLeft - activeObjectWidth / 2)) {
          verticalInTheRange = true
          verticalLines.push({
            x: objectLeft - objectWidth / 2,
            y1:
              objectTop < activeObjectTop
                ? objectTop - objectHeight / 2 - aligningLineOffset
                : objectTop + objectHeight / 2 + aligningLineOffset,
            y2:
              activeObjectTop > objectTop
                ? activeObjectTop + activeObjectHeight / 2 + aligningLineOffset
                : activeObjectTop - activeObjectHeight / 2 - aligningLineOffset,
          })
          activeObject.setPositionByOrigin(
            new fabric.Point(objectLeft - objectWidth / 2 + activeObjectWidth / 2, activeObjectTop),
            'center',
            'center'
          )
        }

        // snap by the right edge
        if (isInRange(objectLeft + objectWidth / 2, activeObjectLeft + activeObjectWidth / 2)) {
          verticalInTheRange = true
          verticalLines.push({
            x: objectLeft + objectWidth / 2,
            y1:
              objectTop < activeObjectTop
                ? objectTop - objectHeight / 2 - aligningLineOffset
                : objectTop + objectHeight / 2 + aligningLineOffset,
            y2:
              activeObjectTop > objectTop
                ? activeObjectTop + activeObjectHeight / 2 + aligningLineOffset
                : activeObjectTop - activeObjectHeight / 2 - aligningLineOffset,
          })
          activeObject.setPositionByOrigin(
            new fabric.Point(objectLeft + objectWidth / 2 - activeObjectWidth / 2, activeObjectTop),
            'center',
            'center'
          )
        }

        // snap by the vertical center line
        if (isInRange(objectTop, activeObjectTop)) {
          horizontalInTheRange = true
          horizontalLines.push({
            y: objectTop,
            x1:
              objectLeft < activeObjectLeft
                ? objectLeft - objectWidth / 2 - aligningLineOffset
                : objectLeft + objectWidth / 2 + aligningLineOffset,
            x2:
              activeObjectLeft > objectLeft
                ? activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset
                : activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset,
          })
          activeObject.setPositionByOrigin(new fabric.Point(activeObjectLeft, objectTop), 'center', 'center')
        }

        // snap by the top edge
        if (isInRange(objectTop - objectHeight / 2, activeObjectTop - activeObjectHeight / 2)) {
          horizontalInTheRange = true
          horizontalLines.push({
            y: objectTop - objectHeight / 2,
            x1:
              objectLeft < activeObjectLeft
                ? objectLeft - objectWidth / 2 - aligningLineOffset
                : objectLeft + objectWidth / 2 + aligningLineOffset,
            x2:
              activeObjectLeft > objectLeft
                ? activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset
                : activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset,
          })
          activeObject.setPositionByOrigin(
            new fabric.Point(activeObjectLeft, objectTop - objectHeight / 2 + activeObjectHeight / 2),
            'center',
            'center'
          )
        }

        // snap by the bottom edge
        if (isInRange(objectTop + objectHeight / 2, activeObjectTop + activeObjectHeight / 2)) {
          horizontalInTheRange = true
          horizontalLines.push({
            y: objectTop + objectHeight / 2,
            x1:
              objectLeft < activeObjectLeft
                ? objectLeft - objectWidth / 2 - aligningLineOffset
                : objectLeft + objectWidth / 2 + aligningLineOffset,
            x2:
              activeObjectLeft > objectLeft
                ? activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset
                : activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset,
          })
          activeObject.setPositionByOrigin(
            new fabric.Point(activeObjectLeft, objectTop + objectHeight / 2 - activeObjectHeight / 2),
            'center',
            'center'
          )
        }
      }

      if (!horizontalInTheRange) {
        horizontalLines.length = 0
      }

      if (!verticalInTheRange) {
        verticalLines.length = 0
      }
    })

    canvas.on('before:render', function () {
      const ct = canvas.contextTop
      if (ct) canvas.clearContext(ct)
    })

    canvas.on('after:render', function () {
      for (let i = verticalLines.length; i--; ) {
        drawVerticalLine(verticalLines[i])
      }
      for (let i = horizontalLines.length; i--; ) {
        drawHorizontalLine(horizontalLines[i])
      }

      verticalLines.length = horizontalLines.length = 0
    })

    canvas.on('mouse:up', function () {
      verticalLines.length = horizontalLines.length = 0
      canvas.renderAll()
    })
  }

  async onChangeTemplate(templateId: any) {
    this.autoPublishTitle = false
    this.loadingCanvas = true
    const template = this.audiogramTemplates.filter((template: any) => template.id === templateId)[0]
    this.editAudiogram(template.canvasData)
    this.loadingCanvas = false
  }

  editAudiogram(jsonData: any) {
    jsonData.objects.forEach((object: any) => {
      if (object.clipPath) {
        fabric.util.enlivenObjects([object.clipPath], function (arg1: any) {
          object.clipPath = arg1[0]
        })
      }
    })
    this.canvasContext.loadFromJSON(jsonData, () => {
      let fonts = this.canvasContext
        .getObjects()
        .filter((o: any) => o.get('type') === 'textbox')
        .map((o: any) => o.fontFamily)

      if (fonts.length) {
        WebFontLoader.load({
          google: {
            // @ts-ignore
            families: [...fonts],
          },
          active: () => {
            this.canvasContext.getObjects().forEach((o: any) => {
              if (o.get('type') === 'textbox') {
                o.set('dirty', true)
              }
            })

            this.canvasContext.renderAll()
          },
        })
      }

      this.downScaleCanvas(this.orientation)

      if (this.sourceComponent === 'TAG' && this.$store.state.modal.tagEdit.data.videoCoords) {
        this.getVideoElement(this.$store.state.modal.tagEdit.data.video.originalUrl, true)
      }

      this.canvasContext.getObjects().forEach((obj: any) => {
        if (obj.type && obj.type === 'rect') {
          obj.selectable = false
          this.artboardCtx = obj
          //   this.canvasContext.clipPath = this.artboardCtx
        }
      })

      this.canvasContext.getObjects().forEach((obj: any) => {
        if (obj.id && obj.id === 'autoTitle') {
          this.autoPublishTitle = true
          obj.set({
            editable: false,
          })
          obj.setControlsVisibility({
            mtr: false,
            tl: false,
            tr: false,
            bl: false,
            br: false,
            ml: false,
            mr: false,
            mt: false,
            mb: false,
          })
        }
      })

      this.canvasContext.renderAll()
      this.canvasState = []
      this.currentStateIndex = -1
      this.undoStatus = false
      this.redoStatus = false
      this.undoFinishedStatus = 1
      this.redoFinishedStatus = 1
      this.isUndoDisabled = false
      this.isRedoDisabled = true
      this.updateCanvasState()
    })
  }

  updateCanvasState() {
    if (this.undoStatus == false && this.redoStatus == false) {
      this.upScaleCanvas()
      let jsonData = this.canvasContext.toDatalessJSON(['id'])
      this.downScaleCanvas(this.orientation)
      let canvasAsJson = JSON.stringify(jsonData)
      if (this.currentStateIndex < this.canvasState.length - 1) {
        let indexToBeInserted = this.currentStateIndex + 1
        this.canvasState[indexToBeInserted] = canvasAsJson
        let numberOfElementsToRetain = indexToBeInserted + 1
        this.canvasState = this.canvasState.splice(0, numberOfElementsToRetain)
      } else {
        this.canvasState.push(canvasAsJson)
      }
      this.currentStateIndex = this.canvasState.length - 1
      if (this.currentStateIndex == this.canvasState.length - 1 && this.currentStateIndex != -1) {
        this.isRedoDisabled = true
      }
    }
  }

  loadAsset(asset: any, from: string) {
    let imgSrc, type

    if (from === 'elements') {
      imgSrc = asset.fileUrl
      type = asset.mimeType.split('/')[1]
    }
    if (from === 'uploads') {
      imgSrc = asset.url
      type = asset.url.split(/[#?]/)[0].split('.').pop().trim()
      if (!this.imageTypes.includes(type)) {
        type = 'jpeg'
      }
    }
    if (type === 'svg+xml' || type === 'svg') {
      this.loadSVGAsset(imgSrc)
    }
    if (type === 'png' || type === 'jpeg' || type === 'jpg') {
      this.loadImageFromUrl(imgSrc)
    }
  }

  loadSVGAsset(imgSrc: string) {
    fabric.loadSVGFromURL(
      imgSrc,
      (objects: any, d: any) => {
        let iconGroup = fabric.util.groupSVGElements(objects, d)
        //for more than one path
        iconGroup.set({
          left: this.canvasContext.get('width') / 4,
          top: this.canvasContext.get('height') / 4,
          id: 'element',
          sourcePath: imgSrc,
        })
        iconGroup.scaleToWidth(this.canvasContext.get('width') / 2)
        this.canvasContext.add(iconGroup)
        this.canvasContext.setActiveObject(iconGroup)
        this.canvasContext.bringToFront(iconGroup)
        this.canvasContext.renderAll()
      },
      function () {},
      {
        crossOrigin: 'anonymous',
      }
    )
  }

  loadWaveforms(waveform: any) {
    this.canvasContext.getObjects().forEach((obj: any) => {
      if (obj.id && obj.id === 'waveform') {
        this.canvasContext.remove(obj)
      }
    })
    this.waveformName = waveform.enum
    let imageUrl = waveform.imageUrl
    fabric.loadSVGFromURL(
      imageUrl,
      (objects: any, d: any) => {
        let iconGroup = fabric.util.groupSVGElements(objects, d)
        //for more than one path
        iconGroup.set({
          left: this.canvasContext.get('width') / 2 - 180,
          top: this.canvasContext.get('height') - 79,
          id: 'waveform',
          sourcePath: imageUrl,
        })

        iconGroup.setControlsVisibility({ mtr: false })
        this.canvasContext.add(iconGroup)
        this.canvasContext.centerObject(iconGroup)
        this.canvasContext.setActiveObject(iconGroup)
        this.canvasContext.bringToFront(iconGroup)
        this.canvasContext.renderAll()
      },
      function () {},
      {
        crossOrigin: 'anonymous',
      }
    )
  }

  checkDelete(e: any) {
    if (e.keyCode == 46 || e.key == 'Delete' || e.code == 'Delete' || e.key == 'Backspace') {
      if (this.canvasContext.getActiveObject()) {
        if (this.canvasContext.getActiveObject().isEditing) {
          return
        }
        this.deleteObject()
      }
    }
  }

  toggleTextColorPicker() {
    this.displayTextColorPicker = !this.displayTextColorPicker
  }

  toggleWaveformColorPicker() {
    this.displayWaveformColorPicker = !this.displayWaveformColorPicker
  }

  toggleElementColorPicker() {
    this.displayElementColorPicker = !this.displayElementColorPicker
  }

  closeTextColorPicker() {
    this.displayTextColorPicker = false
  }

  closeWaveformColorPicker() {
    this.displayWaveformColorPicker = false
  }

  closeElementColorPicker() {
    this.displayElementColorPicker = false
  }

  updateTextColorFromPicker(color: any) {
    this.textSketchColors = color
    if (color.rgba.a == 1) {
      this.textColorValue = color.hex
    } else {
      this.textColorValue =
        'rgba(' + color.rgba.r + ', ' + color.rgba.g + ', ' + color.rgba.b + ', ' + color.rgba.a + ')'
    }
    this.onTextColorChange(this.textColorValue)
  }

  updateWaveformColorFromPicker(color: any) {
    this.waveformSketchColors = color
    if (color.rgba.a == 1) {
      this.waveformColorValue = color.hex
    } else {
      this.waveformColorValue =
        'rgba(' + color.rgba.r + ', ' + color.rgba.g + ', ' + color.rgba.b + ', ' + color.rgba.a + ')'
    }
    this.onWaveformColorChange(this.waveformColorValue)
  }

  updateElementColorFromPicker(color: any) {
    this.elementSketchColors = color
    if (color.rgba.a == 1) {
      this.elementColorValue = color.hex
    } else {
      this.elementColorValue =
        'rgba(' + color.rgba.r + ', ' + color.rgba.g + ', ' + color.rgba.b + ', ' + color.rgba.a + ')'
    }
    this.onElementColorChange(this.elementColorValue)
  }

  onTextColorChange(color: any) {
    this.canvasContext.getActiveObject().set('fill', color)
    this.canvasContext.renderAll()
  }

  onBgColorChange(color: any) {
    if (color) {
      this.artboardCtx.set('opacity', 1)
      this.artboardCtx.set('fill', color)
    } else {
      this.activeColor = 'color29'
      this.artboardCtx.set('opacity', 0)
    }
    this.canvasContext.renderAll()
  }

  onWaveformColorChange(color: any) {
    this.canvasContext.getActiveObject().set('fill', color)
    // if ( this.canvasContext.getActiveObject().type == 'group') {
    //   iconGroup.item(0).fill = 'color
    //   iconGroup.addWithUpdate()
    this.canvasContext.renderAll()
  }

  onElementColorChange(color: any) {
    this.canvasContext.getActiveObject().set('fill', color)
    this.canvasContext.renderAll()
  }

  // Create a textbox
  newTextbox(text: string, type: string) {
    let fontWeight, fontSize
    if (type === 'header') {
      fontWeight = 'bold'
      fontSize = 48
    }
    if (type === 'subheader') {
      fontWeight = 'normal'
      fontSize = 36
    }
    if (type === 'paragraph' || type === 'caption') {
      fontWeight = 'normal'
      fontSize = 20
    }

    let newtext = new fabric.Textbox(text, {
      left: this.canvasContext.getWidth() / 2,
      top: this.canvasContext.getHeight() / 2,
      originX: 'center',
      originY: 'center',
      fontFamily: 'Inter',
      fill: '#fff',
      textAlign: 'center',
      fontWeight: fontWeight,
      fontSize: fontSize,
      cursorWidth: 1,
      stroke: '#000',
      strokeWidth: 0,
      cursorDuration: 1,
      paintFirst: 'stroke',
      typeThing: 'none',
      objectCaching: true,
      strokeUniform: true,
      inGroup: false,
      cursorDelay: 250,
      strokeDashArray: false,
      absolutePositioned: true,
      shadow: {
        color: '#000',
        offsetX: 0,
        offsetY: 0,
        blur: 0,
        opacity: 0,
      },
    })

    newtext.setControlsVisibility({
      mt: false,
      mb: false,
    })

    if (type === 'caption') {
      newtext.set({
        backgroundColor: 'rgba(0,0,0,.7)',
        width: this.canvasContext.getWidth(),
      })
    }

    this.canvasContext.add(newtext)
    this.canvasContext.setActiveObject(newtext)
    this.canvasContext.bringToFront(newtext)
    newtext.enterEditing()
    newtext.selectAll()
    this.canvasContext.renderAll()
  }

  insertTitlePlaceholder() {
    let newtext = new fabric.Textbox('Episode title placeholder', {
      id: 'autoTitle',
      editable: false,
      left: this.canvasContext.getWidth() / 3,
      top: this.canvasContext.getHeight() / 3,
      fontFamily: 'Inter',
      fill: '#ffffff',
      fontWeight: 'normal',
      fontSize: 24,
      textAlign: 'left',
      width: 300,
    })
    //hide controls and rotation
    newtext.setControlsVisibility({
      mtr: false,
      tl: false,
      tr: false,
      bl: false,
      br: false,
      ml: false,
      mr: false,
      mt: false,
      mb: false,
    })
    this.canvasContext.add(newtext)
    this.canvasContext.setActiveObject(newtext)
    this.canvasContext.bringToFront(newtext)
    this.canvasContext.renderAll()
  }

  setImageTab(tab: string) {
    this.imageTab = tab
  }

  setVideoTab(tab: string) {
    this.videoTab = tab
  }

  undo() {
    if (this.undoFinishedStatus) {
      if (this.currentStateIndex == -1 || this.currentStateIndex == 0) {
        this.undoStatus = false
      } else {
        if (this.canvasState.length >= 1) {
          this.undoFinishedStatus = 0
          if (this.currentStateIndex != 0) {
            this.undoStatus = true

            let jsonData = JSON.parse(this.canvasState[this.currentStateIndex - 1])
            jsonData.objects.forEach((object: any) => {
              if (object.clipPath) {
                fabric.util.enlivenObjects([object.clipPath], function (arg1: any) {
                  object.clipPath = arg1[0]
                })
              }
            })
            this.canvasContext.loadFromJSON(jsonData, () => {
              let fonts = this.canvasContext
                .getObjects()
                .filter((o: any) => o.get('type') === 'textbox')
                .map((o: any) => o.fontFamily)

              if (fonts.length) {
                WebFontLoader.load({
                  google: {
                    // @ts-ignore
                    families: [...fonts],
                  },
                  active: () => {
                    this.canvasContext.renderAll()
                  },
                })
              }

              this.downScaleCanvas(this.orientation)

              this.canvasContext.getObjects().forEach((obj: any) => {
                if (obj.type && obj.type === 'rect') {
                  obj.selectable = false
                  this.artboardCtx = obj
                }
              })

              this.undoStatus = false
              this.currentStateIndex -= 1

              this.isUndoDisabled = false
              if (this.currentStateIndex !== this.canvasState.length - 1) {
                this.isRedoDisabled = false
              }
              this.undoFinishedStatus = 1
              this.canvasContext.renderAll()
            })
          } else if (this.currentStateIndex == 0) {
            this.canvasContext.clear()
            this.undoFinishedStatus = 1

            this.isUndoDisabled = true

            this.isRedoDisabled = false
            this.currentStateIndex -= 1
          }
        }
      }
    }
  }

  redo() {
    if (this.redoFinishedStatus) {
      if (this.currentStateIndex == this.canvasState.length - 1 && this.currentStateIndex != -1) {
        this.isRedoDisabled = true
      } else {
        if (this.canvasState.length > this.currentStateIndex && this.canvasState.length != 0) {
          this.redoFinishedStatus = 0
          this.redoStatus = true

          let jsonData = JSON.parse(this.canvasState[this.currentStateIndex + 1])
          jsonData.objects.forEach((object: any) => {
            if (object.clipPath) {
              fabric.util.enlivenObjects([object.clipPath], function (arg1: any) {
                object.clipPath = arg1[0]
              })
            }
          })
          this.canvasContext.loadFromJSON(jsonData, () => {
            let fonts = this.canvasContext
              .getObjects()
              .filter((o: any) => o.get('type') === 'textbox')
              .map((o: any) => o.fontFamily)

            if (fonts.length) {
              WebFontLoader.load({
                google: {
                  // @ts-ignore
                  families: [...fonts],
                },
                active: () => {
                  this.canvasContext.renderAll()
                },
              })
            }

            this.downScaleCanvas(this.orientation)

            this.canvasContext.getObjects().forEach((obj: any) => {
              if (obj.type && obj.type === 'rect') {
                obj.selectable = false
                this.artboardCtx = obj
              }
            })

            this.redoStatus = false
            this.currentStateIndex += 1
            if (this.currentStateIndex != -1) {
              this.isUndoDisabled = false
            }
            this.redoFinishedStatus = 1
            if (this.currentStateIndex == this.canvasState.length - 1 && this.currentStateIndex != -1) {
              this.isRedoDisabled = true
            }
            this.canvasContext.renderAll()
          })
        }
      }
    }
  }

  buttonAction(from: string) {
    if (from === 'undo' && !this.isUndoDisabled) {
      this.undo()
    }
    if (from === 'redo' && !this.isRedoDisabled) {
      this.redo()
    }
  }
}
