export const ENVS = {
  dev: 'DEV',
  beta: 'BETA',
  prod: 'PROD',
}

export const CURRENT_ENV =
  window.location.origin.endsWith('.dev.adorilabs.com') || window.location.origin.startsWith('http://localhost')
    ? ENVS.dev
    : window.location.origin.endsWith('.beta.adorilabs.com') ||
      window.location.origin === 'https://studio-canary.adorilabs.com'
    ? ENVS.beta
    : ENVS.prod

const matches = window.location.origin.match(/([\d]+)(.*)?.dev.adorilabs.com$/)

export const SUPPORT_VIDEO_MAIL =
  CURRENT_ENV === ENVS.prod
    ? 'support+video@adorilabs.com'
    : CURRENT_ENV === ENVS.beta
    ? 'support+beta+youtube@adorilabs.com'
    : 'support+dev+youtube@adorilabs.com'
export const SUPPORT_STUDIO_MAIL =
  CURRENT_ENV === ENVS.prod
    ? 'support+studio@adorilabs.com'
    : CURRENT_ENV === ENVS.beta
    ? 'support+beta+studio@adorilabs.com'
    : 'support+dev+studio@adorilabs.com'

export const PR_NUMBER = matches ? matches[1] : null
