export const topicList = {
  Technology: [
    'Reviews of the latest smartphones, laptops, or other gadgets',
    'Tutorials on how to use popular software or apps',
    'Unboxings and first impressions of new tech products',
    'Explainer videos on emerging technologies like artificial intelligence or blockchain',
    "Gaming-related content like walkthroughs, reviews, or Let's Plays",
  ],
  Education: [
    'Instructional videos on academic topics like math, science, or history',
    'Language learning lessons or vocabulary builders',
    'Tips and strategies for studying or test-taking',
    'College and career advice for students and graduates',
    'Virtual tours of educational institutions like universities or museums',
  ],
  Finance: [
    'Personal finance advice on topics like budgeting, saving, or investing',
    'Interviews with financial experts or entrepreneurs',
    'Explainers on complex financial concepts like stocks or cryptocurrency',
    'Tips for managing debt or improving credit scores',
    'Q&A sessions with financial advisors or accountants',
  ],
  Travel: [
    'Extreme sports or outdoor activities like skydiving, rock climbing, or snowboarding',
    'Destination reviews and recommendations for different travel styles or budgets',
    'Cultural experiences like food tours, music festivals, or religious ceremonies',
    'Road trip or backpacking adventures',
    'Sustainable or eco-friendly travel content',
  ],
  'Personal Development': [
    'Self-improvement tips and resources on topics like mindfulness, confidence, or productivity',
    'Goal-setting and motivation strategies',
    'Reviews of personal development books or courses',
    'Q&A sessions with life coaches or motivational speakers',
    'Inspirational stories of personal growth and transformation.',
  ],
  Relationships: ['Relationship advice and tips', 'Relationship advice for couples', 'Relationship advice for singles'],
  'Fashion & Beauty': [
    'Makeup tutorials and product reviews',
    'Styling tips for different body types or occasions',
    'Fashion hauls and lookbook videos',
    'Haircare and skincare routines',
    'Sustainable and ethical fashion or beauty content',
  ],
  Sports: [
    'Highlights and analysis of recent games or matches',
    'Training and workout routines for different sports or fitness levels',
    'Profiles of athletes or sports teams',
    'Fan reactions and commentary on trending sports topics',
    'Interviews with coaches or players',
  ],
  'Health and Wellness': [
    'Yoga or meditation tutorials',
    'Recipes and meal prep ideas for different diets or health goals',
    'Mental health tips and resources',
    'Workouts or fitness challenges for different levels or interests',
    'Reviews of health and wellness products or services',
  ],
  'Politics and Social Issues': [
    'News commentary or analysis on current events or political issues',
    'Documentaries or investigative reports on social issues like climate change, inequality, or human rights',
    'Interviews with politicians or activists',
    'Debates or roundtable discussions on hot-button topics',
    'Historical perspectives on political or social movements',
  ],
  'Parenting and Family': [
    'Advice for new parents on topics like breastfeeding, sleep training, or diapering',
    'Activities and crafts for kids of different ages',
    'Home organization tips for families',
    'Reviews of family-friendly products or services',
    'Q&A sessions with parenting experts or pediatricians',
  ],
  Entrepreneurship: [
    'Tips for starting and growing a business',
    'Case studies of successful startups or entrepreneurs',
    'Interviews with business leaders or investors',
    'Explainers on business concepts like marketing, branding, or finance',
    'Q&A sessions with business coaches or consultants',
  ],
  'History and Culture': [
    'Documentaries or educational videos on historical events or figures',
    'Virtual tours of museums or landmarks',
    'Travel guides for historical or cultural destinations',
    'Interviews with historians or cultural experts',
    'Explainers on cultural concepts like religion, mythology, or folklore',
  ],
  Food: ['Cooking and recipe videos', 'Food challenges and taste tests', 'Cooking challenges and food experiments'],
  'Animals & Pets': [
    'Animal videos and pet tricks',
    'Animal videos and pet care tips',
    'Animal videos and pet adoptions',
  ],
  'Life Hacks': [
    'Life hacks for students',
    'Life hacks for the workplace',
    'Life hacks for saving money',
    'Life hacks for saving time',
    'Life hacks for parents',
  ],
  'DIY and Crafts': [
    'DIY home decor ideas',
    'DIY fashion and style tips',
    'DIY beauty and skincare products',
    'DIY gift ideas and tutorials',
    'DIY project ideas for kids',
  ],
}

export const voiceToneList = [
  { title: 'Default', value: 'default' },
  { title: 'Authoritative', value: 'authoritative' },
  { title: 'Clinical', value: 'clinical' },
  { title: 'Cold', value: 'cold' },
  { title: 'Confident', value: 'confident' },
  { title: 'Cynical', value: 'cynical' },
  { title: 'Dramatic', value: 'dramatic' },
  { title: 'Educational', value: 'educational' },
  { title: 'Emotional', value: 'emotional' },
  { title: 'Empathetic', value: 'empathetic' },
  { title: 'Formal', value: 'formal' },
  { title: 'Friendly', value: 'friendly' },
  { title: 'Funny', value: 'funny' },
  { title: 'Informal', value: 'informal' },
  { title: 'Inspirational', value: 'inspirational' },
  { title: 'Ironic', value: 'ironic' },
  { title: 'Motivational', value: 'motivational' },
  { title: 'Optimistic', value: 'optimistic' },
  { title: 'Pessimistic', value: 'pessimistic' },
  { title: 'Professional', value: 'professional' },
  { title: 'Playful', value: 'playful' },
  { title: 'Romantic', value: 'romantic' },
  { title: 'Sarcastic', value: 'sarcastic' },
  { title: 'Sad', value: 'sad' },
  { title: 'Scary', value: 'scary' },
  { title: 'Serious', value: 'serious' },
  { title: 'Sympathetic', value: 'sympathetic' },
  { title: 'Witty', value: 'witty' },
]

export const writingStyleList = [
  { title: 'Default', value: 'default' },
  { title: 'Academic', value: 'academic' },
  { title: 'Analytical', value: 'analytical' },
  { title: 'Argumentative', value: 'argumentative' },
  { title: 'Conversational', value: 'conversational' },
  { title: 'Creative', value: 'creative' },
  { title: 'Critical', value: 'critical' },
  { title: 'Descriptive', value: 'descriptive' },
  { title: 'Epigrammatic', value: 'epigrammatic' },
  { title: 'Epistolary', value: 'epistolary' },
  { title: 'Expository', value: 'expository' },
  { title: 'Epigrammatic', value: 'epigrammatic' },
  { title: 'Informative', value: 'informative' },
  { title: 'Instructive', value: 'instructive' },
  { title: 'Journalistic', value: 'journalistic' },
  { title: 'Metaphoric', value: 'metaphoric' },
  { title: 'Narrative', value: 'narrative' },
]

export const languageList = [
  'English',
  'Spanish',
  'French',
  'German',
  'Mandarin Chinese',
  'Japanese',
  'Korean',
  'Italian',
  'Portuguese',
  'Dutch',
  'Russian',
  'Arabic',
  'Swedish',
  'Danish',
  'Norwegian',
  'Finnish',
  'Hebrew',
  'Greek',
  'Turkish',
  'Polish',
  'Hungarian',
  'Thai',
  'Indonesian',
  'Vietnamese',
  'Tagalog',
  'Hindi',
  'Bengali',
  'Urdu',
  'Punjabi',
  'Tamil',
  'Telugu',
  'Marathi',
  'Gujarati',
  'Kannada',
  'Malayalam',
]

export const titleList = [
  { title: 'Make existing title catchy using AI', value: 'existing' },
  { title: 'Use description to generate catchy title', value: 'description' },
]

export const descriptionList = [
  { title: 'Search engine optimised description using AI', value: 'seo' },
  { title: 'Tags and hashtag generator using AI', value: 'hashtag' },
]
export const thumbNailList = [
  { title: 'Generate Image from title using AI', value: 'title' },
  { title: 'Generate Image from description using AI', value: 'description' },
]
export const keywordDataList = [
  { title: 'Generate keywords from  title using AI', value: 'title' },
  { title: 'Generate keywords from description using AI', value: 'description' },
]

export const blogList = [
  {
    title: 'Techcrunch',
    value: 'https://techcrunch.com',
  },
  {
    title: 'Wordpress',
    value: 'https://wordpress.com/blog/',
  },
  {
    title: 'Medium',
    value: 'https://medium.com/explore-topics',
  },
  {
    title: 'Google Blog',
    value: 'https://blog.google/',
  },
  {
    title: 'CNN',
    value: 'https://edition.cnn.com/',
  },
  {
    title: 'Mac World',
    value: 'https://www.macworld.com/',
  },
  {
    title: 'The Verge',
    value: 'https://www.theverge.com',
  },
  {
    title: 'Wired',
    value: 'https://www.wired.com',
  },
  {
    title: 'IT World Canada',
    value: 'https://www.itworldcanada.com',
  },
  {
    title: 'The Next Web',
    value: 'https://thenextweb.com',
  },
  {
    title: 'BBC',
    value: 'https://www.bbc.com',
  },
  {
    title: 'Substack',
    value: 'https://bottomfeeder.substack.com',
  },
  {
    title: 'Telegraph India',
    value: 'https://www.telegraphindia.com',
  },
  {
    title: 'Wp Beginner',
    value: 'https://www.wpbeginner.com',
  },
  {
    title: 'Indie Hackers',
    value: 'https://www.indiehackers.com',
  },
  {
    title: 'The Guardian',
    value: 'https://www.theguardian.com',
  },
  {
    title: 'Quanta Magazine',
    value: 'https://www.quantamagazine.org/',
  },
  {
    title: 'Bedtime Short Stories',
    value: 'https://www.bedtimeshortstories.com',
  },
  {
    title: 'Assembly Ai',
    value: 'https://www.assemblyai.com/blog',
  },
  {
    title: 'CNBC',
    value: 'https://www.cnbc.com',
  },
  {
    title: 'Economist',
    value: 'https://www.economist.com',
  },
  {
    title: 'Arch Daily',
    value: 'https://www.archdaily.com',
  },
  {
    title: 'Scientific American',
    value: 'https://www.scientificamerican.com',
  },
  {
    title: 'Yahoo Finance',
    value: 'https://finance.yahoo.com',
  },
  {
    title: 'NBC News',
    value: 'https://www.nbcnews.com',
  },
  {
    title: 'The Wall Street Journal',
    value: 'https://www.wsj.com',
  },
  {
    title: 'Washington Post',
    value: 'https://www.washingtonpost.com',
  },
  {
    title: 'Apple Insider',
    value: 'https://appleinsider.com',
  },
  {
    title: 'Computer World',
    value: 'https://www.computerworld.com',
  },
]
