var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex justify-start" }, [
    _c("div", { staticClass: "br2" }, [
      _c(
        "audio",
        {
          ref: "audio",
          staticClass: "v-mid ml4",
          attrs: { preload: "auto" },
          on: {
            timeupdate: function ($event) {
              return _vm.updateTime()
            },
          },
        },
        [_c("source", { attrs: { src: _vm.audioSrc } })]
      ),
      _c(
        "div",
        { staticClass: "bg-adori-medium-gray ph2 pt2 pb1 dib" },
        [
          _c("BaseButtonPlayer", {
            staticClass: "mr2 pointer dim",
            attrs: {
              icon: "replay_10",
              onClick: function () {
                return _vm.seek(false)
              },
            },
          }),
          _c("BaseButtonPlayer", {
            staticClass: "mr2 pointer dim",
            attrs: {
              icon: _vm.playButtonIcon,
              text: _vm.formattedCurrentTime + " / " + _vm.formattedTotalTime,
              onClick: _vm.togglePlay,
            },
          }),
          _c("BaseButtonPlayer", {
            staticClass: "pointer dim",
            attrs: {
              icon: "forward_10",
              onClick: function () {
                return _vm.seek(true)
              },
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "bg-adori-medium-gray ph2 pt0 pb1" }, [
        _c("input", {
          staticClass: "input-reset timeline-range",
          attrs: { type: "range", min: _vm.startTimeSec, max: _vm.endTimeSec },
          domProps: { value: _vm.currentTime },
          on: { input: _vm.onInputChange },
        }),
      ]),
      _c("div", { staticClass: "triangle center" }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }