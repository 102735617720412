




import { Vue, Prop, Component } from 'vue-property-decorator'

@Component
export default class ButtonAudioAdorify extends Vue {
  @Prop(String) audioUid!: string

  buttonDisabled: boolean = false

  languageDisabled: boolean = false

  get buttonIcon() {
    if (!this.buttonDisabled) {
      return 'auto_awesome'
    }
    return ''
  }

  get buttonText() {
    return 'Auto Enhance'
  }

  async buttonAction() {
    this.$router.push('/audio-editor?uid=' + this.audioUid)
  }
}
