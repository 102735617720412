import { queryDefaults } from '@/utils/query.config'
import AdoriServiceV6 from '@/services/adori_v6'
import store from '@/store'
import { Ref } from '@vue/composition-api'
import { useInfiniteQuery, useQuery } from 'vue-query'
import get from 'lodash.get'

// Query Keys

export enum audiogramQueryKeys {
  GET_AUDIOGRAMS = 'GET_AUDIOGRAMS',
  TRACK_AUDIOGRAMS = 'TRACK_AUDIOGRAMS',
  TEMPLATE = 'AUDIOGRAM_TEMPLATES',
  ASSET = 'AUDIOGRAM_ASSET',
  WAVEFORMS = 'AUDIOGRAM_WAVEFORM',
  FONTS = 'AUDIOGRAM_FONTS',
}

// Queries
const getAudiograms = (networkId: string, { orientation, offset = 0 }: { orientation: string; offset: number }) => {
  const params = {
    orientation,
    limit: 50,
    offset,
  }
  return AdoriServiceV6.get_all_audiograms(networkId, {
    params,
  })
}
const getTrackAudiograms = (networkId: string, trackUid: string) => {
  return AdoriServiceV6.fetchAudioAudiograms(networkId, trackUid)
}
const getAudiogramTemplates = (
  networkId: string,
  { orientation, offset = 0 }: { orientation: string; offset: number }
) => {
  const params = {
    orientation,
    limit: 10,
    offset,
  }
  return AdoriServiceV6.get_audiogram_templates(networkId, params)
}
const getAudiogramAssets = (networkId: string) => {
  // Todo: refactor this when asset design changes
  return AdoriServiceV6.get_all_elements_assets(networkId, {
    limit: 100,
    offset: 0,
  })
}
const getAllWaveforms = (networkId: string) => {
  return AdoriServiceV6.get_all_waveforms(networkId)
}
const getWebFonts = () => {
  return AdoriServiceV6.fetchWebFonts()
}

// Hooks
export const useGetAudiograms = (orientation: Ref<string>) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useInfiniteQuery(
    [audiogramQueryKeys.GET_AUDIOGRAMS, networkId, orientation],
    ({ pageParam = 0 }) => getAudiograms(networkId, { orientation: orientation.value, offset: pageParam }),
    {
      ...queryDefaults,
      getNextPageParam: (lastPage: any, pages: any) => {
        const audiograms = {
          data: lastPage.data,
          count: lastPage.count,
          offset: lastPage.offset,
        }
        store.commit('setAllAudiograms', audiograms)
        const totalPages = get(pages, '[0].count', 0) > 50 ? Math.ceil(get(pages, '[0].count', 0) / 50) : 1
        if (pages.length === totalPages) return false
        else return pages.length * 50
      },
      select: (data: any) => {
        const audiogramArrays = data.pages.flatMap((obj: any) => obj.data)
        return audiogramArrays
      },
    }
  )
}
export const useGetTrackAudiograms = (trackUid: any, { enabled }: any) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useQuery(
    [audiogramQueryKeys.TRACK_AUDIOGRAMS, networkId, trackUid],
    () => getTrackAudiograms(networkId, typeof trackUid === 'object' ? trackUid.value : trackUid),
    {
      ...queryDefaults,
      onSuccess(data: any) {
        store.commit('setAudioAudiogram', {
          audioUid: typeof trackUid === 'object' ? trackUid.value : trackUid,
          audiogramPositions: data,
        })
      },
      enabled,
    }
  )
}
export const useGetAudiogramTemplates = (orientation: Ref<string>) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useInfiniteQuery(
    [audiogramQueryKeys.TEMPLATE, networkId, orientation],
    ({ pageParam = 0 }) => getAudiogramTemplates(networkId, { orientation: orientation.value, offset: pageParam }),
    {
      ...queryDefaults,
      getNextPageParam: (lastPage: any, pages: any) => {
        const totalPages = get(pages, '[0].count', 0) > 10 ? Math.ceil(get(pages, '[0].count', 0) / 10) : 1
        if (pages.length === totalPages) return false
        else return pages.length * 10
      },
      select: (data: any) => {
        const audiogramArrays = data.pages.flatMap((obj: any) => obj.data)
        return audiogramArrays
      },
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  )
}
export const useGetAudiogramAssets = () => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useQuery([audiogramQueryKeys.ASSET], () => getAudiogramAssets(networkId), {
    ...queryDefaults,
    cacheTime: Infinity,
    staleTime: Infinity,
    onSuccess(data) {
      store.commit('setAllElementAssets', data.data)
    },
  })
}
export const useGetAudiogramWaveforms = () => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useQuery([audiogramQueryKeys.WAVEFORMS], () => getAllWaveforms(networkId), {
    ...queryDefaults,
    cacheTime: Infinity,
    staleTime: Infinity,
    onSuccess(data) {
      store.commit('setAllAudiogramWaveforms', data)
    },
  })
}
export const useGetAudiogramFonts = () => {
  return useQuery([audiogramQueryKeys.FONTS], () => getWebFonts(), {
    ...queryDefaults,
    cacheTime: Infinity,
    staleTime: Infinity,
    onSuccess(data) {
      store.commit('setWebFonts', data.data)
    },
  })
}
// mutations
