import { useGetLibImages, useGetPublicImages } from '@/hooks/image'
import { useGetStabilityResults } from '@/hooks/openAi'

import { reactive, ref, computed } from '@vue/composition-api'
import { Component, Vue } from 'vue-property-decorator'
import get from 'lodash.get'
import { QueryClient, useQueryClient } from 'vue-query'
import { imageQueryKeys } from '@/hooks/image'

@Component({
  setup(props) {
    const queryClient = useQueryClient()
    const orientation = computed(() => (get(props, 'galleryParent', false) === 'PODCAST_SHOW' ? 'squarish' : 'NONE'))

    const selectedImageTab = ref('MY_LIBRARY')
    const imageSearchText = ref('')
    const imageSearchParams = reactive({
      query: imageSearchText,
      limit: 10,
      offset: 0,
      searchLibrary: selectedImageTab,
      orientation,
    })

    const isImageSearchActive = computed(
      () => !['MY_LIBRARY', 'DALLE', 'AI'].includes(selectedImageTab.value) && !!imageSearchText.value
    )
    const { data: publicImageData, isFetching: isPublicImageLoading } = useGetPublicImages(imageSearchParams, {
      enabled: isImageSearchActive,
    })

    const stabilityParams = reactive({
      prompt: '',
      id: null,
      searchLibrary: selectedImageTab,
    })
    // const interval: any = ref(false)
    const isStabilityActive = computed(() => !!stabilityParams.prompt && selectedImageTab.value == 'AI')

    const { data: stabilityResults, isFetching: isStabilityLoading } = useGetStabilityResults(
      stabilityParams,
      {
        enabled: isStabilityActive,
      }
      //   interval,
      //   (data: any) => {
      //     if (data.status === 'processing' || data.status === null) {
      //       stabilityParams.id = data.id
      //       interval.value = true
      //     } else {
      //       stabilityParams.id = null
      //       interval.value = false
      //     }
      //   }
    )

    const libImageParams = reactive({
      limit: 10,
      offset: 0,
    })
    const { data: libImageData, isFetching: isLibImageLoading } = useGetLibImages(libImageParams, {
      enabled: true,
    })

    return {
      queryClient,
      selectedImageTab,
      imageSearchText,
      imageSearchParams,
      publicImageData,
      isPublicImageLoading,
      libImageParams,
      libImageData,
      isLibImageLoading,
      stabilityParams,
      stabilityResults,
      isStabilityLoading,
    }
  },
})
export default class ImageLibraries extends Vue {
  selectedImageTab!: string
  imageSearchText!: string
  imageSearchParams!: any
  publicImageData!: any
  isPublicImageLoading!: boolean
  libImageParams!: any
  libImageData!: any
  isLibImageLoading!: boolean
  queryClient!: QueryClient
  stabilityParams!: any
  isStabilityLoading!: boolean
  stabilityResults!: any

  get selectedRangeStart() {
    return this.selectedImageTab === 'MY_LIBRARY' ? this.libImageParams.offset + 1 : this.imageSearchParams.offset + 1
  }

  get selectedRangeEnd() {
    return this.selectedImageTab === 'MY_LIBRARY'
      ? this.libImageParams.offset + this.imageList.length
      : this.imageSearchParams.offset + this.imageList.length
  }

  get imageList() {
    return this.selectedImageTab === 'MY_LIBRARY'
      ? get(this.libImageData, 'data', []).map((img: any) => ({
          url: img.url,
          previewUrl: img.thumbnailURL,
          source: 'adori',
          id: img.id,
        }))
      : this.selectedImageTab === 'DALLE'
      ? get(this, 'dalleResults', []).map((img: any) => ({
          url: img.url,
          previewUrl: img.url,
          source: 'dalle',
          id: crypto.randomUUID(),
        }))
      : this.selectedImageTab === 'AI'
      ? get(this.stabilityResults, 'output', []).map((img: any) => ({
          url: img,
          previewUrl: img,
          source: 'stability',
          id: this.stabilityResults?.id || crypto.randomUUID(),
        }))
      : get(this.publicImageData, 'data', []).map((img: any) => ({
          url: img.urls.full,
          previewUrl: img.urls.thumb,
          source: 'unsplash',
          id: img.id,
          user: img.user || null,
          downloadUrl: img.urls.download,
        }))
  }

  get totalImageCount() {
    return this.selectedImageTab === 'MY_LIBRARY'
      ? get(this.libImageData, 'count', 0)
      : get(this.publicImageData, 'count', 0)
  }

  handleNavigation(nav: string) {
    if (this.selectedImageTab === 'MY_LIBRARY') {
      nav === 'next' && this.libImageParams.offset <= this.totalImageCount && (this.libImageParams.offset += 10)
      nav === 'prev' && this.libImageParams.offset >= 10 && (this.libImageParams.offset -= 10)
    } else {
      nav === 'next' && this.imageSearchParams.offset <= this.totalImageCount && (this.imageSearchParams.offset += 10)
      nav === 'prev' && this.imageSearchParams.offset >= 10 && (this.imageSearchParams.offset -= 10)
    }
  }

  resetOffset() {
    this.imageSearchParams.offset = 0
  }

  resetImageGallery() {
    this.queryClient.invalidateQueries(imageQueryKeys.LIB_IMAGES)
  }
}
