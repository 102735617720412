import Vue from 'vue'
import { StoreOptions, GetterTree, MutationTree, ActionTree } from 'vuex'
import AdoriService from '@/services/adori'
import AdoriServiceV6 from '@/services/adori_v6'

const state: any = {
  transcriptsByAudioUid: {},
  languages: null,
  openAiResults: null,
  isAutomationLoading: false,
}

const initialState: any = {
  ...JSON.parse(JSON.stringify(state)),
}

const getters: GetterTree<any, any> = {
  getTranscript: (state) => (audioUid: string) => state.transcriptsByAudioUid[audioUid],
  languages: (state) => state.languages,
  openAiResults: (state) => state.openAiResults,
  isAutomationLoading: (state) => state.isAutomationLoading,
}

const mutations: MutationTree<any> = {
  resetState(state) {
    Object.keys(state).forEach((key: any) => {
      Vue.set(state, key, initialState[key])
    })
  },

  addTranscript(state, { audioUid, transcript }) {
    Vue.set(state.transcriptsByAudioUid, audioUid, transcript)
  },

  setLanguages(state, languages) {
    Vue.set(state, 'languages', languages)
  },

  setOpenAiResult(state, results) {
    Vue.set(state, 'openAiResults', results)
  },
  setIsAutomationLoading(state, value) {
    Vue.set(state, 'isAutomationLoading', value)
  },
}

const actions: ActionTree<any, any> = {
  resetTranscriptsState(context) {
    context.commit('resetState')
  },

  async fetchTranscript(context, audioUid: string) {
    const networkId = context.getters.networkId
    const transcript = await AdoriService.fetchAudioTranscript(networkId, audioUid)
    context.commit('addTranscript', { audioUid, transcript })

    // If the transcript status has changed, fetch the audio details again.
    const audio = context.getters.audio(audioUid)
    if (audio && audio.transcriptStatus !== transcript.status) {
      await context.dispatch('getAudio', audioUid)
    } else if (!audio) {
      await context.dispatch('getAudio', audioUid)
    }
  },

  async generateTranscript(context, payload) {
    const networkId = context.getters.networkId
    await AdoriService.generateAudioTranscript(networkId, payload.audioUid, payload.language)
  },

  async getLanguages(context) {
    const languages = await AdoriService.fetchLanguages()
    context.commit('setLanguages', languages)
  },

  async openAi(context, payload) {
    const networkId = context.getters.networkId
    const results = await AdoriServiceV6.openAi(networkId, payload)
    context.commit('setOpenAiResult', results)
  },
}

const transcripts: StoreOptions<any> = {
  state,
  getters,
  mutations,
  actions,
}

export default transcripts

// Public helpers

export function createTranscriptPoll(store: any, audioUid: string, interval: number): number {
  return window.setInterval(() => {
    store.dispatch('fetchTranscript', audioUid)
  }, interval)
}

export function destroyTranscriptPoll(handler: number) {
  window.clearInterval(handler)
}
