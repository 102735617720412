var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "bg-black br2",
      class: {
        "landscape-size": _vm.filter === "LANDSCAPE",
        "square-size": _vm.filter === "SQUARE",
        "portrait-size": _vm.filter === "PORTRAIT",
        bigger: _vm.bigger,
        tag: !_vm.filter,
      },
    },
    [
      _c(
        "div",
        { staticClass: "relative h-100" },
        [
          _vm.enableCroppa
            ? _c("croppa", {
                ref: "croppaInstance",
                attrs: {
                  width: _vm.bigger ? 225 : 150,
                  height: _vm.bigger ? 400 : 226,
                  "initial-image": _vm.src,
                  "show-remove-button": false,
                  "zoom-speed": 20,
                  "disable-drag-and-drop": true,
                },
              })
            : _c(
                "div",
                { staticClass: "absolute w-100 h-100 flex items-center" },
                [
                  _c("img", {
                    staticClass: "tag-bg mw-none w-100 no-drag",
                    attrs: { src: _vm.src },
                  }),
                ]
              ),
          _vm._t("default"),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }