var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    [
      _c("template", { slot: "header" }, [
        _c("h3", { staticClass: "f4 gray fw5 di" }, [
          _vm._v(_vm._s(_vm.title)),
        ]),
      ]),
      _c("template", { slot: "body" }, [
        _c("p", { staticClass: "f5 gray measure" }, [
          _vm._v(_vm._s(_vm.description)),
        ]),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("BaseButtonRed", {
            staticClass: "fw5",
            attrs: {
              text: "Ok",
              onClick: function () {
                return _vm.$store.dispatch("closeModal")
              },
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }