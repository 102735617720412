































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Action } from 'vuex-class'

@Component({})
export default class AudiogramImagesList extends Vue {
  @Prop({ default: 1 })
  selectedRangeStart!: number
  @Prop({ default: 10 })
  selectedRangeEnd!: number
  @Prop({ default: 0 })
  totalImages!: number
  @Prop({ default: [] })
  imageSources!: any[]
  @Prop() selectedTab!: string
  @Prop() cols!: number
  @Prop({ default: '' })
  previewImageSrc!: string

  @Action downloadUnsplash!: Function

  //chunking of images in masonry grid
  get masonryColumns() {
    let columns = []
    let cols = this.cols || 2 //change it for n-columns
    let mid = Math.ceil(this.imageSources.length / cols)
    for (let col = 0; col < cols; col++) {
      columns.push(this.imageSources.slice(col * mid, col * mid + mid))
    }
    console.log(columns)

    return columns
  }

  handleNavigation(nav: string) {
    this.$emit('handleNavigation', nav)
  }

  loadAsset(img: any) {
    this.selectedTab === 'IMAGE_SEARCH' ? this.$parent.$emit('load-image', img) : this.$emit('load-image', img)
  }
  //   async downloadAsset(img: any) {
  //     const imgUrl = await this.downloadUnsplash({ url: img.downloadUrl })
  //     const response = await fetch(imgUrl)
  //     const blob = await response.blob()
  //     // @ts-ignore
  //     this.$refs['imageDownloadRef'].href = window.URL.createObjectURL(blob)
  //     const imageDownloadLink = this.$refs.imageDownloadRef as HTMLElement
  //     imageDownloadLink.click()
  //   }
}
