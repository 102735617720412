





















































































































































































































import { Vue, Component, Watch, Mixins } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import TheToggleButton from '@/components/Common/TheToggleButton.vue'
import moment from 'moment'
import vSelect from 'vue-select'
import get from 'lodash.get'

// constants
import { privacyList, categoryList } from '@/components/Publish/publish'
import { formatSelected, formatToday } from '@/components/Youtube/time'

import AdoriService from '@/services/adori'

import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

const validations = {
  title: {
    required,
  },
}

@Component({
  // @ts-ignore
  validations,
  components: {
    TheToggleButton,
    vSelect,
  },
})
export default class ModalAutoPublish extends Mixins(validationMixin) {
  @Getter categories!: any
  @Getter playlist!: any
  @Getter selectedPodcastSetting!: any
  @Getter networkId!: string
  @Getter uploadListStatus!: any
  @Getter signinStatus!: any
  @Getter youtubeProfile!: any
  @Getter createdYoutubeFeed!: any

  @Action closeModal!: any
  @Action ytPodcastModalSetting!: any
  @Action youtubePlaylist!: any
  @Action pushNotification!: any
  @Action loadUser!: any
  @Action setSession!: any

  $v!: any

  selfDeclareMadeForKids = null
  today: string = moment().utc().format('YYYY-MM-DD')
  youtubeConf: any = {}
  playlistId: number = 0
  categoryId: number = 1
  privacy: string = ''
  toggleValue: boolean = false
  includeVisuals: boolean = true
  includeChapters: boolean = true
  title: string = ''
  description: string = ''
  scheduleDate: string = ''
  scheduleTime: string = ''
  publish: string = ''
  dateSelected: boolean = false
  timesSelected: boolean = false
  timezone: string = ''
  uploadingImage = false
  file!: any
  imageId = ''
  posterImageId = ''
  width!: number
  height!: number
  startTimeSec!: number
  endTimeSec!: number
  localPublish!: string
  customTitle: any = ''
  newDescription: any = ''
  plainDescription: string = ''
  scheduledFor!: string
  isScheduled!: boolean
  chapterMarkers: string = ''
  customDescription: boolean = false
  guid: string = ''
  options: any = []
  keywords: any = []
  episodeKeywords: any = []
  currentInput: string = ''
  youtubeLoader = false
  youtubeAuthHandle: any = null
  includeShortsValue: boolean = false
  youtubeAuthWindow: any = null

  titleTags = [
    { value: 'Podcast Title,', tag: ' <PODCAST_TITLE> ', name: 'PODCAST_TITLE' },
    { value: 'Episode Title,', tag: ' <EPISODE_TITLE> ', name: 'EPISODE_TITLE' },
    { value: 'Episode Number', tag: ' <EPISODE_NUMBER> ', name: 'EPISODE_NUMBER' },
  ]
  descriptionTags = [
    { value: 'Podcast Title,', tag: ' <PODCAST_TITLE> ', name: 'PODCAST_TITLE' },
    { value: 'Episode Title,', tag: ' <EPISODE_TITLE> ', name: 'EPISODE_TITLE' },
    { value: 'Episode Number,', tag: ' <EPISODE_NUMBER> ', name: 'EPISODE_NUMBER' },
    { value: 'Podcast Description,', tag: ' <PODCAST_DESCRIPTION> ', name: 'PODCAST_DESCRIPTION' },
    { value: 'Episode Description,', tag: ' <EPISODE_DESCRIPTION> ', name: 'EPISODE_DESCRIPTION' },
    { value: 'Powered by Adori', tag: 'Powered by Adori. https://adorilabs.com, #AdoriLabs' },
  ]

  findTagTitle(tagName: string) {
    const result = this.titleTags.filter((obj: any) => {
      return obj.name === tagName
    })
    return get(result, '[0].tag', '')
  }

  findTagDesc(tagName: string) {
    const result = this.descriptionTags.filter((obj: any) => {
      return obj.name === tagName
    })
    return get(result, '[0].tag', '')
  }

  saveKeyword() {
    this.currentInput !== '' && this.keywords.indexOf(this.currentInput) === -1 && this.keywords.push(this.currentInput)
    this.currentInput = ''
  }
  deleteKeyword(index: any) {
    this.keywords.splice(index, 1)
  }
  backspaceDelete({ which }: any) {
    which == 8 && this.currentInput === '' && this.keywords.splice(this.keywords.length - 1)
  }

  triggerYoutubeLogin() {
    this.youtubeAuthWindow = window.open('', 'youtubeAuthHandle', 'width=500,height=715')
    sessionStorage.setItem('youtubeAuth', 'YES')
    this.$store.commit('setTriggerLogin')
  }

  get isSaveInValid() {
    return this.$v.title.$invalid
  }

  get isAudioUploading() {
    return this.uploadListStatus[this.guid]
  }

  get initialCoverImg() {
    return this.selectedPodcastSetting.thumbnailSrc
  }

  get thumbnailSrc() {
    if (this.imageId) {
      return this.file.url
    } else {
      return this.initialCoverImg
    }
  }

  get timeError() {
    if (this.scheduleDate && this.scheduleTime) {
      let scheduleDateTime = this.scheduleDate + this.scheduleTime
      let todayDate = formatToday(scheduleDateTime, this.timezone)
      let selectedDate = formatSelected(scheduleDateTime)
      if (selectedDate < todayDate) {
        return true
      }
    }
    return false
  }

  get titleCharacterRemaining() {
    return 99 - this.title.length
  }

  get descriptionCharacterRemaining() {
    return 4999 - this.plainDescription.length
  }

  get isTitleOverMax() {
    return this.titleCharacterRemaining < 0
  }
  get isDescriptionOverMax() {
    return this.descriptionCharacterRemaining < 0
  }

  get ytFeedUid() {
    return get(this.createdYoutubeFeed, 'uid', '') || sessionStorage.getItem('feedUid')
  }

  get getPlaylist() {
    return this.signinStatus[this.ytFeedUid]?.youtube
      ? this.playlist[this.ytFeedUid] || []
      : this.playlist[this.networkId] || []
  }

  get youtubeSignInStatus() {
    return this.ytFeedUid && this.signinStatus[this.ytFeedUid]?.youtube
      ? this.signinStatus[this.ytFeedUid]?.youtube
      : this.signinStatus[this.networkId]?.youtube
      ? this.signinStatus[this.networkId]?.youtube
      : false
  }

  get privacyListData() {
    return privacyList
  }

  get categoryListData() {
    return categoryList
  }

  get hasErrors() {
    return this.timeError && !this.isdisabled ? true : false
  }
  get isdisabled() {
    return this.toggleValue ? false : true
  }
  get isDateTimeSelected() {
    return this.dateSelected && this.timesSelected
  }

  get hasTags() {
    return this.selectedPodcastSetting.hasTags
  }

  get initialVisuals() {
    return this.selectedPodcastSetting.includeVisuals
  }

  get initialChapters() {
    return this.selectedPodcastSetting.includeChapters
  }

  get shortsCriteria() {
    return this.selectedPodcastSetting.shortsCriteria
  }
  get isYTShorts() {
    return this.selectedPodcastSetting.isYTShorts
  }

  addPlaceHolderTitle(tag: string) {
    this.title += tag
  }
  addPlaceHolderDescription(tag: string) {
    this.plainDescription += tag
  }

  userSummaryInsert(value: string) {
    this.customDescription = true
    this.description = value
  }

  summary() {
    if (this.customDescription && this.includeVisuals && this.includeChapters) {
      this.plainDescription = this.description + this.chapterMarkers
    } else if (this.customDescription) {
      if (this.description.includes(this.chapterMarkers)) {
        this.description = this.description.replace(this.chapterMarkers, '')
      }
      this.plainDescription = this.description
    } else if (this.includeVisuals && this.includeChapters) {
      this.plainDescription = this.selectedPodcastSetting.description.replace(/<[^>]*>?/gm, '') + this.chapterMarkers
    } else {
      this.plainDescription = this.selectedPodcastSetting.description.replace(/<[^>]*>?/gm, '')
    }

    if (this.episodeKeywords.length > 0) {
      this.plainDescription =
        this.plainDescription + '\n' + this.episodeKeywords.join(' ').replace(/[^\s]+/g, (m: string) => `#${m}`)
    }

    if (this.includeShortsValue) {
      this.plainDescription += `\n #Shorts`
    }
    return this.plainDescription
  }

  selectDate(value: string) {
    if (value && this.scheduleTime) {
      this.dateSelected = true
    }
  }

  selectTime(value: string) {
    if (value && this.scheduleDate) {
      this.timesSelected = true
    }
  }

  imageClicked() {
    if (this.networkId) {
      this.$store.dispatch('showFileUploader', {
        accept: 'image/*',
        onChange: this.loadImage,
      })
    } else {
      this.$store.commit('setTriggerLogin')
    }
  }

  async loadImage() {
    const file = this.$store.getters.selectedFile
    if (file) {
      this.uploadingImage = true
      const img: any = await AdoriService.uploadImage(this.networkId, file)
      this.file = img
      this.imageId = img.id
      this.posterImageId = img.id
      Vue.set(img, 'thumbnailSrc', img.url)
      this.uploadingImage = false
    }
  }
  handleSave() {
    if (this.isSaveInValid) return
    this.ytPodcastModalSetting({
      title: this.title,
      description: this.description,
      thumbnailSrc: this.thumbnailSrc,
      includeVisuals: this.includeVisuals,
      includeChapters: this.includeChapters,
      keywords: this.keywords,
      privacy: this.privacy,
      category: this.categoryId,
      publish: this.publish,
      scheduledFor: this.scheduledFor,
      isScheduled: this.isScheduled,
      scheduleDate: this.scheduleDate,
      scheduleTime: this.scheduleTime,
      timezone: this.timezone,
      customTitle: this.title,
      customDescription: this.customDescription,
      newDescription: this.plainDescription,
      posterImageId: this.posterImageId,
      playlistId: this.playlistId,
      isYTShorts: this.includeShortsValue,
      isMadeForKids: this.selfDeclareMadeForKids,
    })
    this.closeModal()
  }

  async created() {
    if (this.youtubeSignInStatus && this.getPlaylist.length === 0) {
      const payload: any = { networkId: this.networkId }
      this.signinStatus[this.ytFeedUid]?.youtube && (payload['showId'] = this.ytFeedUid)
      await this.youtubePlaylist(payload)
    }
  }

  mounted() {
    const podcast = this.selectedPodcastSetting
    this.title = podcast.customTitle || this.findTagTitle('EPISODE_TITLE')
    this.description = podcast.newDescription || this.findTagDesc('EPISODE_DESCRIPTION')
    this.posterImageId = podcast.posterImageId
    this.keywords = podcast.keywords
    this.plainDescription = podcast.newDescription || this.findTagDesc('EPISODE_DESCRIPTION')
    this.privacy = podcast.privacy
    this.localPublish = podcast.publish
    this.categoryId = podcast.category
    this.width = podcast.width
    this.height = podcast.height
    this.startTimeSec = podcast.startTimeSec
    this.endTimeSec = podcast.endTimeSec
    this.scheduledFor = podcast.scheduledFor
    this.toggleValue = podcast.isScheduled
    this.scheduleDate = podcast.scheduleDate
    this.scheduleTime = podcast.scheduleTime
    this.includeShortsValue = podcast.isYTShorts
    podcast.playlistId && (this.playlistId = podcast.playlistId)
    this.chapterMarkers = podcast.chapterMarkers
    this.customDescription = podcast.customDescription
    this.includeVisuals = this.initialVisuals
    this.includeChapters = this.initialChapters
    this.selfDeclareMadeForKids = podcast.isMadeForKids
  }
}
