






import { Component, Prop, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { SUBSCRIPTION_STATUS } from '@/constants/subscription'
import { addPaymentMethod, updateSubscription } from '@/services/subscription'
import AdoriService from '@/services/adori'

import * as Sentry from '@sentry/vue'

@Component
export default class BaseButtonAddPaymentMethod extends Vue {
  @Prop(String) text!: string
  @Prop(String) icon!: string
  @Prop(Boolean) textButton!: boolean
  @Prop(String) planId!: string
  @Prop(Boolean) isYoutube!: boolean

  @Getter networkId!: string
  @Getter currentNetworkSubscriptions!: any
  @Getter currentNetworkPaymentCards!: any
  @Getter networkType!: any

  @Action showConfirm!: any
  @Action setShowLoader!: any
  @Action closeModal!: any
  @Action pushNotification!: any
  @Action getCurrentNetworkPaymentCards!: any

  isLoading: boolean = false

  get styleClass() {
    if (this.textButton) return `f5 fw5 adori-red bn pointer pa0 0-90 glow`
    return (
      'f6 br2 bn pv2 light-gray ph3 pv1 bg-adori-red btn-shadow ' +
      (!this.disabled ? 'o-90 glow pointer' : 'o-40 not-allowed')
    )
  }

  get disabled() {
    // return !this.selectedPlanId || !this.isLoading
    return false
  }

  get subscription() {
    return this.currentNetworkSubscriptions && this.currentNetworkSubscriptions.subscription
  }

  get subscriptionName() {
    return this.subscription && this.subscription.plan && this.subscription.plan.name
  }

  async planDescription() {
    if (this.subscription && this.planId) {
      const plan: any = await AdoriService.fetchPaymentPlan(this.planId)
      const interval = plan.interval.toLowerCase() + 'ly'
      const amount = plan.amount
      const name = plan.name

      const trialData = this.subscription.studio.trial
      const currentData = this.subscription.studio.current
      const readableDate = trialData ? trialData.readableDate : currentData.readableDate

      const description = `
      <div class="f4 white fw5">${name}</div>
      <div>
        <span class="f5">Billing ${interval} &middot; Next invoice on 
          <span class="light-gray">${readableDate}</span> for 
          <span class="light-gray"> USD ${amount} </span>
        </span>
      </div>
      `
      return description
    }
    return null
  }

  async handleUpdateSubscription(confirm = false) {
    this.setShowLoader(true)
    if (this.subscription) {
    }
    try {
      let callBackUrl = '/overview'
      if (this.isYoutube) {
        callBackUrl = '/home/all'
      }
      await updateSubscription(this.networkId, this.planId, confirm, callBackUrl)

      this.$router.push(callBackUrl)
    } catch (error) {
      Sentry.captureException(error)
    }
    this.setShowLoader(false)
    this.closeModal()
  }

  showChooseDifferentPlanErr() {
    return (
      this.planId &&
      this.subscription &&
      this.currentNetworkPaymentCards &&
      this.currentNetworkPaymentCards.length &&
      this.subscription.plan.id === this.planId
    )
  }

  async buttonAction() {
    if (!this.subscription) this.handleUpdateSubscription(false)
    else if (this.showChooseDifferentPlanErr()) {
      this.pushNotification({
        text: `Selected plan is same as your current plan. \
Choose different plan to update subscription.`,
        type: 'ERROR',
      })
    } else {
      const description = await this.planDescription()
      this.showConfirm({
        title: 'Selected plan details',
        description: description,
        yesButtonText: 'Confirm',
        // noButtonText: 'Choose other plan',
        onConfirm: async () => {
          this.setShowLoader(true)
          if (this.currentNetworkPaymentCards && this.currentNetworkPaymentCards.length) {
            await this.handleUpdateSubscription(true)
          } else if (this.subscription.plan.id === this.planId) {
            await this.handleUpdateSubscription(false)
          } else {
            // Update subscription
            await this.handleUpdateSubscription(true)
            // Checkout to stripe
            if (this.subscription.status !== SUBSCRIPTION_STATUS.trial && !this.isYoutube) {
              await this.handleUpdateSubscription(false)
            }
          }
        },
        onCancel: async () => {
          this.$router.push('/plans')
          this.closeModal()
        },
      })
    }
  }
}
