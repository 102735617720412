var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    { attrs: { hideCloseButton: _vm.uploadLoader } },
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "light-gray f3 fw5" }, [
          _vm._v(_vm._s(_vm.title)),
        ]),
      ]),
      _c("template", { slot: "body" }, [
        _c(
          "div",
          { staticClass: "min-w7" },
          [
            !_vm.hasCompletedUpload
              ? _c("ImportAndUpload", {
                  attrs: {
                    hideUploadAudio: _vm.hideUploadAudio,
                    hideImportPodcast:
                      _vm.$permissions.isCreatePodcastAllowed() &&
                      _vm.isYoutube,
                    "is-studio-parent": "",
                  },
                  on: {
                    changePane: _vm.changePane,
                    "file-selected": _vm.handleFileSelected,
                    "file-setting": _vm.handleFileSetting,
                  },
                })
              : _c("div", [
                  !_vm.enableImageGallery
                    ? _c("div", { staticClass: "flex justify-between" }, [
                        _c("div", { staticClass: "mr3" }, [
                          _c(
                            "div",
                            { staticClass: "pb3 relative" },
                            [
                              _c(
                                "div",
                                {
                                  class: _vm.uploadLoader
                                    ? "pb2 gray f6 disabled"
                                    : "pb2 gray f6",
                                },
                                [_vm._v("Episode image")]
                              ),
                              _c("BaseThumbnailEpisode", {
                                attrs: {
                                  loading: _vm.imgLoader,
                                  onClick: _vm.showImageGallery,
                                  src: _vm.selectedImageSrc,
                                  big: "",
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "mv2",
                                  class:
                                    (_vm.hasClickedImage &&
                                      !_vm.hasPreviewImage &&
                                      !_vm.imgLoader) ||
                                    (_vm.hasClickedNext &&
                                      !_vm.hasPreviewImage &&
                                      !_vm.imgLoader)
                                      ? "ba bw1 b--adori-red br2"
                                      : "ba bw1 b--adori-transparent",
                                },
                                [
                                  _c("BaseButtonBorder", {
                                    staticClass: "w-100",
                                    attrs: {
                                      text: "Change episode image",
                                      onClick: _vm.showImageGallery,
                                      disabled: _vm.uploadLoader,
                                    },
                                  }),
                                ],
                                1
                              ),
                              (_vm.hasClickedImage &&
                                !_vm.hasPreviewImage &&
                                !_vm.imgLoader) ||
                              (_vm.hasClickedNext &&
                                !_vm.hasPreviewImage &&
                                !_vm.imgLoader)
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "f6 absolute adori-red",
                                      staticStyle: { bottom: "5px" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                Podcast image is required.\n              "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex justify-start items-center pb3",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "f6 link pointer dim silver flex items-center",
                                  on: {
                                    click: function ($event) {
                                      _vm.showAdvancedOptions =
                                        !_vm.showAdvancedOptions
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "i",
                                    { staticClass: "material-icons f6 mr1" },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.showAdvancedOptions
                                              ? "remove_circle"
                                              : "add_circle"
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                  _c("span", { staticClass: "mt1" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.showAdvancedOptions
                                          ? "Hide"
                                          : "Show"
                                      ) + " advanced options"
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _vm.showAdvancedOptions
                            ? _c("div", { staticClass: "pb3" }, [
                                _c(
                                  "div",
                                  { staticClass: "flex justify-between" },
                                  [
                                    _c("div", { staticClass: "pb2 gray f6" }, [
                                      _vm._v("Keywords (optional)"),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "pb2 f6",
                                        class: !_vm.$v.selectedEpisode.keywords
                                          .maxLength
                                          ? "adori-red"
                                          : "gray",
                                      },
                                      [
                                        _vm._v(
                                          "\n                  (" +
                                            _vm._s(
                                              _vm.$v.selectedEpisode.keywords
                                                .$model.length
                                            ) +
                                            " / 255)\n                "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value:
                                        _vm.$v.selectedEpisode.keywords.$model,
                                      expression:
                                        "$v.selectedEpisode.keywords.$model",
                                      modifiers: { trim: true },
                                    },
                                  ],
                                  staticClass:
                                    "db bg-adori-very-light-gray br2 ph3 pv3 gray f6 mb2 white w-100",
                                  class: _vm.$v.selectedEpisode.keywords.$error
                                    ? "ba bw1 b--adori-red"
                                    : "bw0",
                                  attrs: {
                                    type: "text",
                                    placeholder:
                                      "Enter keywords separated by comma",
                                  },
                                  domProps: {
                                    value:
                                      _vm.$v.selectedEpisode.keywords.$model,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.$v.selectedEpisode.keywords,
                                        "$model",
                                        $event.target.value.trim()
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                                _vm.$v.selectedEpisode.keywords.$dirty
                                  ? _c("div", [
                                      !_vm.$v.selectedEpisode.keywords.maxLength
                                        ? _c(
                                            "div",
                                            { staticClass: "f6 adori-red" },
                                            [
                                              _vm._v(
                                                "\n                  Must be less than 255 characters.\n                "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ])
                                  : _vm._e(),
                                _vm.showAdvancedOptions
                                  ? _c("div", { staticClass: "pb3" }, [
                                      _c(
                                        "div",
                                        { staticClass: "flex justify-between" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "pb2 gray f6" },
                                            [
                                              _vm._v(
                                                "\n                    Does this episode contain explicit content?\n                    "
                                              ),
                                              _c("BaseTooltip", {
                                                attrs: {
                                                  info: "This indicates whether the episode contain explicit language or adult content.",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pv1 ba br4 b--transparent bg-black-20 tc",
                                          staticStyle: { width: "85px" },
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "f6 ttu pv1 ph2 silver link pointer",
                                              class: _vm.$v.selectedEpisode
                                                .explicitContent.$model
                                                ? "br4 bg-black-80 light-gray white"
                                                : "",
                                            },
                                            [
                                              _c("input", {
                                                staticClass: "input-reset",
                                                attrs: { type: "radio" },
                                                domProps: {
                                                  checked:
                                                    _vm.$v.selectedEpisode
                                                      .explicitContent.$model,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.$v.selectedEpisode.explicitContent.$model = true
                                                  },
                                                },
                                              }),
                                              _vm._v("Yes"),
                                            ]
                                          ),
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "f6 ttu pv1 ph2 silver link pointer",
                                              class: !_vm.$v.selectedEpisode
                                                .explicitContent.$model
                                                ? "br4 bg-black-80 light-gray white"
                                                : "",
                                            },
                                            [
                                              _c("input", {
                                                staticClass: "input-reset",
                                                attrs: { type: "radio" },
                                                domProps: {
                                                  checked:
                                                    !_vm.$v.selectedEpisode
                                                      .explicitContent.$model,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.$v.selectedEpisode.explicitContent.$model = false
                                                  },
                                                },
                                              }),
                                              _vm._v("No"),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm.$v.selectedEpisode.explicitContent
                                        .$dirty
                                        ? _c("div", [
                                            !_vm.$v.selectedEpisode
                                              .explicitContent.required
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "f6 adori-red",
                                                  },
                                                  [_vm._v("Cannot be empty.")]
                                                )
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                    ])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "w-50 pl3" }, [
                          _c("div", { staticClass: "pb3 relative" }, [
                            _c("div", { staticClass: "flex justify-between" }, [
                              _c(
                                "div",
                                { staticClass: "pb2 gray f6" },
                                [
                                  _vm._v(
                                    "\n                  Episode name\n                  "
                                  ),
                                  _c("BaseTooltip", {
                                    attrs: {
                                      info: "Episode name is a string containing a clear, concise name for your episode.",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "pb2 f6",
                                  class: !_vm.$v.selectedEpisode.name.maxLength
                                    ? "adori-red"
                                    : "gray",
                                },
                                [
                                  _vm._v(
                                    "\n                  (" +
                                      _vm._s(
                                        _vm.$v.selectedEpisode.name.$model
                                          .length
                                      ) +
                                      " / 255)\n                "
                                  ),
                                ]
                              ),
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.trim",
                                  value: _vm.$v.selectedEpisode.name.$model,
                                  expression: "$v.selectedEpisode.name.$model",
                                  modifiers: { trim: true },
                                },
                              ],
                              staticClass:
                                "db bg-adori-very-light-gray br2 ph3 pv3 gray f6 mb2 white w-100",
                              class:
                                _vm.$v.selectedEpisode.name.$error ||
                                (_vm.$v.selectedEpisode.name.$invalid &&
                                  _vm.hasClickedNext)
                                  ? "ba bw1 b--adori-red"
                                  : "ba bw1 b--adori-transparent",
                              attrs: {
                                type: "text",
                                placeholder: "Enter episode name",
                              },
                              domProps: {
                                value: _vm.$v.selectedEpisode.name.$model,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.$v.selectedEpisode.name,
                                    "$model",
                                    $event.target.value.trim()
                                  )
                                },
                                blur: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                            }),
                            _vm.$v.selectedEpisode.name.$dirty &&
                            !_vm.hasClickedNext
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "absolute",
                                    staticStyle: { bottom: "5px" },
                                  },
                                  [
                                    !_vm.$v.selectedEpisode.name.required
                                      ? _c(
                                          "div",
                                          { staticClass: "f6 adori-red" },
                                          [_vm._v("Cannot be empty.")]
                                        )
                                      : _vm._e(),
                                    !_vm.$v.selectedEpisode.name.maxLength
                                      ? _c(
                                          "div",
                                          { staticClass: "f6 adori-red" },
                                          [
                                            _vm._v(
                                              "\n                  Must be less than 255 characters.\n                "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              : _vm.hasClickedNext
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "absolute",
                                    staticStyle: { bottom: "5px" },
                                  },
                                  [
                                    _vm.$v.selectedEpisode.name.$invalid
                                      ? _c("div", [
                                          !_vm.$v.selectedEpisode.name.required
                                            ? _c(
                                                "div",
                                                { staticClass: "f6 adori-red" },
                                                [_vm._v("Cannot be empty.")]
                                              )
                                            : _vm._e(),
                                          !_vm.$v.selectedEpisode.name.maxLength
                                            ? _c(
                                                "div",
                                                { staticClass: "f6 adori-red" },
                                                [
                                                  _vm._v(
                                                    "\n                    Must be less than 255 characters.\n                  "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ])
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _c(
                            "div",
                            { staticClass: "pb3 relative" },
                            [
                              _c(
                                "div",
                                { staticClass: "flex justify-between" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "pb2 gray f6" },
                                    [
                                      _vm._v(
                                        "\n                  Episode description\n                  "
                                      ),
                                      _c("BaseTooltip", {
                                        attrs: {
                                          info: "Episode description is text containing one or more sentences describing your episode to potential listeners.",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c("HTMLEditor", {
                                staticClass:
                                  "bg-adori-very-light-gray bw0 br2 ph3 pv1 white mb2 w-100 overflow-y-auto scrollbar",
                                class:
                                  _vm.$v.selectedEpisode.description.$error ||
                                  (_vm.$v.selectedEpisode.description
                                    .$invalid &&
                                    _vm.hasClickedNext)
                                    ? "ba bw1 b--adori-red"
                                    : "ba bw1 b--adori-transparent",
                                staticStyle: { height: "180px" },
                                attrs: {
                                  placeholder: "Enter episode description",
                                  initialContent:
                                    _vm.$v.selectedEpisode.description.$model,
                                },
                                on: { update: _vm.updateDescription },
                              }),
                              _vm.$v.selectedEpisode.description.$invalid &&
                              _vm.$v.selectedEpisode.description.$dirty &&
                              !_vm.hasClickedNext
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "absolute",
                                      staticStyle: { bottom: "5px" },
                                    },
                                    [
                                      !_vm.$v.selectedEpisode.description
                                        .required ||
                                      !_vm.$v.selectedEpisode.description
                                        .notEmptyHTML
                                        ? _c(
                                            "div",
                                            { staticClass: "f6 adori-red" },
                                            [
                                              _vm._v(
                                                "\n                  Cannot be empty\n                "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      !_vm.$v.selectedEpisode.description
                                        .maxLength
                                        ? _c(
                                            "div",
                                            { staticClass: "f6 adori-red" },
                                            [
                                              _vm._v(
                                                "\n                  Your episode description is too long.\n                "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.hasClickedNext
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "absolute",
                                      staticStyle: { bottom: "5px" },
                                    },
                                    [
                                      !_vm.$v.selectedEpisode.description
                                        .required ||
                                      !_vm.$v.selectedEpisode.description
                                        .notEmptyHTML
                                        ? _c(
                                            "div",
                                            { staticClass: "f6 adori-red" },
                                            [
                                              _vm._v(
                                                "\n                  Cannot be empty\n                "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      !_vm.$v.selectedEpisode.description
                                        .maxLength
                                        ? _c(
                                            "div",
                                            { staticClass: "f6 adori-red" },
                                            [
                                              _vm._v(
                                                "\n                  Your episode description is too long.\n                "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "pb3 scrollbar" }, [
                            _c("div", { staticClass: "flex justify-between" }, [
                              _c(
                                "div",
                                { staticClass: "pb2 gray f6" },
                                [
                                  _vm._v(
                                    "\n                  Episode language\n                  "
                                  ),
                                  _c("BaseTooltip", {
                                    attrs: { info: "Language used in audio." },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "w-100 h3 scrollbar" }, [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.selectedLanguage,
                                      expression: "selectedLanguage",
                                    },
                                  ],
                                  staticClass:
                                    "input-reset light-gray bn br2 pa3 pr5 shadow-2 db arrow w-100 mb2 w2 scrollbar",
                                  attrs: { disabled: _vm.languageDisabled },
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.selectedLanguage = $event.target
                                        .multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                  },
                                },
                                _vm._l(_vm.languages || [], function (lang) {
                                  return _c(
                                    "option",
                                    {
                                      key: lang.code,
                                      staticClass: "scrollbar",
                                      staticStyle: { width: "30px" },
                                      domProps: { value: lang.code },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(lang.name) +
                                          "\n                  "
                                      ),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ]),
                          ]),
                        ]),
                      ])
                    : _c(
                        "div",
                        [
                          _c("ImageGallery", {
                            attrs: { galleryParent: "PODCAST_EPISODE" },
                            on: {
                              showTag: _vm.showImageGallery,
                              handleSelectedImage: _vm.handleSelectedImage,
                            },
                          }),
                        ],
                        1
                      ),
                ]),
          ],
          1
        ),
      ]),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          { staticClass: "flex justify-between w-100 items-center" },
          [
            !_vm.enableImageGallery && _vm.hasCompletedUpload
              ? _c("div", [
                  _vm.uploadLoader
                    ? _c("div", { staticClass: "white flex items-center" }, [
                        _c("div", {
                          class:
                            _vm.finalProgress == 0
                              ? "a-loader mr3"
                              : "b-loader mr3",
                        }),
                        _vm.finalProgress == 0
                          ? _c("div", [
                              _vm._v("Transcoding media files. Please wait..."),
                            ])
                          : _c("div", [
                              _vm._v(
                                "Final uploading progress: " +
                                  _vm._s(_vm.finalProgress) +
                                  "%"
                              ),
                            ]),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            !_vm.enableImageGallery && _vm.hasCompletedUpload
              ? _c("BaseButtonRed", {
                  attrs: {
                    disabled: _vm.imgLoader || _vm.uploadLoader,
                    text: "Save",
                    loading: _vm.imgLoader || _vm.uploadLoader,
                    onClick: _vm.handleSaveDetails,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }