export const queryStaleTime = 1800000

export const twentyFourHoursInMs = 1000 * 60 * 60 * 24

export const tenMinsInMs = 1000 * 60 * 10

export const queryDefaults = {
  staleTime: queryStaleTime,
  cacheTime: queryStaleTime,
  retry: false,
}
