var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "f4 light-gray fw5" }, [
          _vm._v("Change Youtube Setting"),
        ]),
      ]),
      _c("template", { slot: "body" }, [
        _c(
          "div",
          { staticClass: "flex w-100 white mw-900" },
          [
            _c("div", { staticClass: "w-60 pr2" }, [
              _c("div", { staticClass: "flex flex-column" }, [
                _c("div", { staticClass: "title" }, [
                  _c("div", { staticClass: "flex justify-end items-center" }, [
                    _c(
                      "span",
                      {
                        staticClass: "f5 mr1 pt1 pointer",
                        staticStyle: { color: "#6563ff" },
                        on: {
                          click: function ($event) {
                            return _vm.toggleAIPanel("AI_TITLE")
                          },
                        },
                      },
                      [_vm._v("AI")]
                    ),
                    _c("img", {
                      staticClass: "pointer",
                      staticStyle: { width: "auto", height: "25px" },
                      attrs: { src: require("@/assets/ai.svg"), alt: "" },
                      on: {
                        click: function ($event) {
                          return _vm.toggleAIPanel("AI_TITLE")
                        },
                      },
                    }),
                  ]),
                  _c(
                    "fieldset",
                    {
                      staticClass: "br2",
                      class:
                        _vm.isTitleOverMax || _vm.$v.title.$error
                          ? "b--adori-red"
                          : "b--adori-light-gray",
                    },
                    [
                      _c(
                        "legend",
                        {
                          staticClass: "f6",
                          class: _vm.$v.title.$error
                            ? "adori-red"
                            : "light-gray",
                        },
                        [_vm._v("Title")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.$v.title.$model,
                            expression: "$v.title.$model",
                          },
                        ],
                        staticClass:
                          "bg-adori-gray bn br2 gray f6 mb2 white w-100",
                        attrs: {
                          type: "text",
                          name: "title",
                          maxlength: "99",
                          placeholder: "Episode Title",
                        },
                        domProps: { value: _vm.$v.title.$model },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.$v.title,
                              "$model",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                  _vm.$v.title.$error
                    ? _c("span", { staticClass: "red f7 ml1" }, [
                        _vm._v("Title cannot be empty"),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "mb3 the-count f6" }, [
                    _c("span", { class: { overMax: _vm.isTitleOverMax } }, [
                      _vm._v(_vm._s(_vm.titleCharacterRemaining)),
                    ]),
                    _c("span", [_vm._v("/ 99")]),
                  ]),
                ]),
                _c("div", { staticClass: "flex justify-end items-center" }, [
                  _c(
                    "span",
                    {
                      staticClass: "f5 mr1 pt1 pointer",
                      staticStyle: { color: "#6563ff" },
                      on: {
                        click: function ($event) {
                          return _vm.toggleAIPanel("AI_DESCRIPTION")
                        },
                      },
                    },
                    [_vm._v("AI")]
                  ),
                  _c("img", {
                    staticClass: "pointer",
                    staticStyle: { width: "auto", height: "25px" },
                    attrs: { src: require("@/assets/ai.svg"), alt: "" },
                    on: {
                      click: function ($event) {
                        return _vm.toggleAIPanel("AI_DESCRIPTION")
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "description" }, [
                  _c(
                    "fieldset",
                    {
                      staticClass: "br2",
                      class: _vm.isDescriptionOverMax
                        ? "b--adori-red"
                        : "b--adori-light-gray",
                    },
                    [
                      _c("legend", { staticClass: "f6" }, [
                        _vm._v("Description"),
                      ]),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.plainDescription,
                            expression: "plainDescription",
                          },
                        ],
                        staticClass:
                          "bg-adori-gray bn br2 gray f6 mb2 white w-100 scrollbar overflow-y-auto",
                        attrs: {
                          maxlength: "4999",
                          type: "text",
                          rows: "8",
                          name: "description",
                          placeholder: "Episode Description",
                        },
                        domProps: { value: _vm.plainDescription },
                        on: {
                          input: [
                            function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.plainDescription = $event.target.value
                            },
                            function ($event) {
                              return _vm.userSummaryInsert($event.target.value)
                            },
                          ],
                        },
                      }),
                    ]
                  ),
                  _c("div", { staticClass: "the-count f6" }, [
                    _c(
                      "span",
                      { class: { overMax: _vm.isDescriptionOverMax } },
                      [_vm._v(_vm._s(_vm.descriptionCharacterRemaining))]
                    ),
                    _c("span", [_vm._v("/ 4999")]),
                  ]),
                ]),
                _vm.networkId && !_vm.isAudioUploading && _vm.hasTags
                  ? _c(
                      "div",
                      { staticClass: "flex mb1" },
                      [
                        _c("BaseCheckbox", {
                          staticClass: "mr2",
                          attrs: {
                            text: "Include visuals",
                            initialState: _vm.initialVisuals,
                          },
                          on: {
                            toggle: function ($event) {
                              _vm.includeVisuals = $event
                              _vm.summary()
                            },
                          },
                        }),
                        _vm.includeVisuals
                          ? _c("BaseCheckbox", {
                              attrs: {
                                text: "Add tags to chapters",
                                initialState: _vm.initialChapters,
                              },
                              on: {
                                toggle: function ($event) {
                                  _vm.includeChapters = $event
                                  _vm.summary()
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "flex items-center mb1" },
                  [
                    _c("BaseCheckbox", {
                      ref: "shorts",
                      staticClass: "mr2",
                      attrs: {
                        text: "Publish As Youtube Shorts",
                        initialState: _vm.isYTShorts,
                        disabled: !_vm.shortsCriteria,
                      },
                      on: {
                        toggle: function ($event) {
                          _vm.includeShortsValue = $event
                          _vm.summary()
                        },
                      },
                    }),
                    _c("BaseTooltip", {
                      attrs: {
                        info: "If video is square or portrait and less than a 1min, it will be uploaded as shorts",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "category mt2" }, [
                  _c("label", { staticClass: "f6 flex items-center" }, [
                    _vm._v("Playlist "),
                    _c(
                      "i",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.youtubeSignInStatus,
                            expression: "!youtubeSignInStatus",
                          },
                        ],
                        staticClass: "material-icons light-gray f6 pl1 pb1",
                      },
                      [_vm._v("lock")]
                    ),
                  ]),
                  _c("div", { staticClass: "flex w-100" }, [
                    _c(
                      "div",
                      {
                        staticClass: "mr2",
                        class:
                          _vm.youtubeShowSignInStatus || !_vm.isNotALlowedLogin
                            ? "w-100"
                            : "w-60",
                      },
                      [
                        (
                          _vm.currentYoutubeLogin === "Network Login"
                            ? !_vm.youtubeNetworkSignInStatus
                            : !_vm.youtubeShowSignInStatus
                        )
                          ? _c("div", { staticClass: "mt1" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "w-100 ba b--adori-gray bw1 br2 flex justify-between items-center pl2 h2 adori-gray pointer flex f6",
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "dim",
                                      on: {
                                        click: function ($event) {
                                          _vm.currentYoutubeLogin ===
                                          "Network Login"
                                            ? _vm.triggerYoutubeLogin()
                                            : _vm.triggerShowYoutubeLogin()
                                        },
                                      },
                                    },
                                    [_vm._v("Connect your youtube account")]
                                  ),
                                  _c("BaseTooltip", {
                                    attrs: { info: _vm.loginTooltip },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.playlistId,
                                    expression: "playlistId",
                                  },
                                ],
                                staticClass:
                                  "bg-adori-gray white f6 w-100 br2 h2 ba b--gray mt1 scrollbar",
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.playlistId = $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                    function ($event) {},
                                  ],
                                },
                              },
                              [
                                _c("option", { domProps: { value: 0 } }, [
                                  _vm._v("Select playlist"),
                                ]),
                                _vm._l(_vm.getPlaylist, function (item, i) {
                                  return _c(
                                    "option",
                                    { key: i, domProps: { value: item.id } },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(item.title) +
                                          "\n                  "
                                      ),
                                    ]
                                  )
                                }),
                              ],
                              2
                            ),
                      ]
                    ),
                    !_vm.youtubeShowSignInStatus && _vm.isNotALlowedLogin
                      ? _c("div", { staticClass: "w-40" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.currentYoutubeLogin,
                                  expression: "currentYoutubeLogin",
                                },
                              ],
                              staticClass:
                                "bg-adori-gray white w-100 f6 br2 h2 ba b--gray mt1 scrollbar",
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.currentYoutubeLogin = $event.target
                                      .multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                  function ($event) {},
                                ],
                              },
                            },
                            _vm._l(
                              ["Network Login", "Show Login"],
                              function (item, i) {
                                return _c(
                                  "option",
                                  { key: i, domProps: { value: item } },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(item) +
                                        "\n                  "
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _c("div", { staticClass: "keywords mt2" }, [
                  _c(
                    "div",
                    { staticClass: "flex justify-between items-center" },
                    [
                      _c("label", { staticClass: "f6 light-gray" }, [
                        _vm._v("Press enter after each keywords"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "flex justify-end items-center" },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "f5 mr1 pt1 pointer",
                              staticStyle: { color: "#6563ff" },
                              on: {
                                click: function ($event) {
                                  return _vm.toggleAIPanel("AI_TITLE")
                                },
                              },
                            },
                            [_vm._v("AI")]
                          ),
                          _c("img", {
                            staticClass: "pointer",
                            staticStyle: { width: "auto", height: "25px" },
                            attrs: { src: require("@/assets/ai.svg"), alt: "" },
                            on: {
                              click: function ($event) {
                                return _vm.toggleAIPanel("AI_KEYWORDS")
                              },
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "keyword-container mt1" },
                    [
                      _vm._l(_vm.keywords, function (keyword, i) {
                        return _c("div", { key: i, staticClass: "keyword" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(keyword) +
                              "\n                "
                          ),
                          _c(
                            "i",
                            {
                              staticClass: "material-icons",
                              on: {
                                click: function ($event) {
                                  return _vm.deleteKeyword(i)
                                },
                              },
                            },
                            [_vm._v("clear")]
                          ),
                        ])
                      }),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.currentInput,
                            expression: "currentInput",
                          },
                        ],
                        domProps: { value: _vm.currentInput },
                        on: {
                          keypress: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.saveKeyword.apply(null, arguments)
                          },
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "delete",
                                [8, 46],
                                $event.key,
                                ["Backspace", "Delete", "Del"]
                              )
                            ) {
                              return null
                            }
                            return _vm.backspaceDelete.apply(null, arguments)
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.currentInput = $event.target.value
                          },
                        },
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "w-40 pl2" }, [
              _c("div", { staticClass: "flex flex-column" }, [
                _c("div", { staticClass: "video-thumbnail" }, [
                  _c("div", { staticClass: "flex justify-between" }, [
                    _c("label", { staticClass: "f6" }, [
                      _vm._v("Video Thumbnail "),
                      _c("span", { staticClass: "gray" }, [
                        _vm._v("Max Size: 2MB"),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "flex justify-center items-center" },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "f5 mr1 pointer pt1",
                            staticStyle: { color: "#6563ff" },
                            on: {
                              click: function ($event) {
                                return _vm.toggleAIPanel("AI_THUMBNAIL")
                              },
                            },
                          },
                          [_vm._v("AI")]
                        ),
                        _c("img", {
                          staticClass: "pointer",
                          staticStyle: { width: "auto", height: "25px" },
                          attrs: { src: require("@/assets/ai.svg"), alt: "" },
                          on: {
                            click: function ($event) {
                              return _vm.toggleAIPanel("AI_THUMBNAIL")
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "thumbnail-bg relative" }, [
                    _vm.uploadingImage
                      ? _c("div", { staticClass: "loading" }, [
                          _c("img", {
                            staticClass: "h150",
                            attrs: { src: require("@/assets/spinner.svg") },
                          }),
                        ])
                      : _c("img", {
                          staticClass: "thumbnail-img",
                          attrs: { src: _vm.thumbnailSrc, alt: "" },
                        }),
                    _c(
                      "i",
                      {
                        staticClass:
                          "material-icons cam light-gray pointer dim f1 absolute",
                        on: { click: _vm.imageClicked },
                      },
                      [_vm._v("\n                photo_camera\n              ")]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "privacy mt2" }, [
                  _c("label", { staticClass: "f6" }, [_vm._v("Privacy")]),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.privacy,
                          expression: "privacy",
                        },
                      ],
                      staticClass:
                        "bg-adori-gray white f6 w-100 br2 h2 ba b--gray mt1",
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.privacy = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          function ($event) {},
                        ],
                      },
                    },
                    _vm._l(_vm.privacyListData, function (item, i) {
                      return _c(
                        "option",
                        { key: i, domProps: { value: item.value } },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(item.title) +
                              "\n              "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
                _c("div", { staticClass: "category mt2" }, [
                  _c("label", { staticClass: "f6" }, [_vm._v("Category")]),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.categoryId,
                          expression: "categoryId",
                        },
                      ],
                      staticClass:
                        "bg-adori-gray white f6 w-100 br2 h2 ba b--gray mt1 scrollbar",
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.categoryId = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          function ($event) {},
                        ],
                      },
                    },
                    _vm._l(_vm.categoryListData, function (item, i) {
                      return _c(
                        "option",
                        { key: i, domProps: { value: item.id } },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(item.title) +
                              "\n              "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
                _c("div", { staticClass: "flex mt3" }, [
                  _c("div", { staticClass: "mr3" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selfDeclareMadeForKids,
                          expression: "selfDeclareMadeForKids",
                        },
                      ],
                      staticClass: "mr1",
                      attrs: {
                        type: "radio",
                        value: "true",
                        id: "MADE_FOR_KIDS",
                      },
                      domProps: {
                        checked: _vm._q(_vm.selfDeclareMadeForKids, "true"),
                      },
                      on: {
                        change: function ($event) {
                          _vm.selfDeclareMadeForKids = "true"
                        },
                      },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "f6 light-gray pointer",
                        attrs: { for: "MADE_FOR_KIDS" },
                      },
                      [_vm._v("Yes, it's made for kids")]
                    ),
                  ]),
                  _c("div", {}, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selfDeclareMadeForKids,
                          expression: "selfDeclareMadeForKids",
                        },
                      ],
                      staticClass: "mr1",
                      attrs: {
                        type: "radio",
                        value: "false",
                        id: "NOT_MADE_FOR_KIDS",
                      },
                      domProps: {
                        checked: _vm._q(_vm.selfDeclareMadeForKids, "false"),
                      },
                      on: {
                        change: function ($event) {
                          _vm.selfDeclareMadeForKids = "false"
                        },
                      },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "f6 light-gray pointer",
                        attrs: { for: "NOT_MADE_FOR_KIDS" },
                      },
                      [_vm._v("No, it's not made for kids")]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "schedule mt4" }, [
                  _c(
                    "div",
                    { staticClass: "flex mb1" },
                    [
                      _c("label", { staticClass: "f6 mr2" }, [
                        _vm._v("Schedule"),
                      ]),
                      _c("TheToggleButton", {
                        attrs: {
                          value: _vm.toggleValue,
                          "checked-bg": "#de1a23",
                          height: "16",
                          width: "30",
                        },
                        on: {
                          click: function ($event) {
                            _vm.toggleValue = !_vm.toggleValue
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("date-picker", {
                        staticClass:
                          "adori-date-picker adori-custom-date w-38 pr1",
                        attrs: {
                          lang: "en",
                          disabled: _vm.isdisabled,
                          type: "date",
                          format: "YYYY-MM-DD",
                          placeholder: "Select Date",
                          "not-before": _vm.today,
                          clearable: false,
                        },
                        on: { change: _vm.selectDate },
                        model: {
                          value: _vm.scheduleDate,
                          callback: function ($$v) {
                            _vm.scheduleDate = $$v
                          },
                          expression: "scheduleDate",
                        },
                      }),
                      _c("date-picker", {
                        staticClass:
                          "adori-date-picker adori-custom-date w-38 pl1",
                        attrs: {
                          type: "time",
                          lang: "en",
                          disabled: _vm.isdisabled,
                          format: "hh:mm a",
                          "time-picker-options": {
                            start: "00:00",
                            step: "00:15",
                            end: "23:30",
                          },
                          placeholder: "Select Time",
                          clearable: false,
                        },
                        on: { change: _vm.selectTime },
                        model: {
                          value: _vm.scheduleTime,
                          callback: function ($$v) {
                            _vm.scheduleTime = $$v
                          },
                          expression: "scheduleTime",
                        },
                      }),
                      _c("span", { staticClass: "timezone-div dim" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.timezone,
                                expression: "timezone",
                              },
                            ],
                            staticClass:
                              "drop-selector bg-adori-gray white f6 br2 h2 ba b--gray scrollbar mw-75 pointer",
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.timezone = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                                function ($event) {},
                              ],
                            },
                          },
                          _vm._l(_vm.timezoneListData, function (item, i) {
                            return _c(
                              "option",
                              { key: i, domProps: { value: item.value } },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(item.title) +
                                    "\n                  "
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "f7 adori-gray bg-adori-gray bn mw-75",
                          },
                          [_vm._v("TIME ZONE")]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm.timezone
                    ? _c("div", { staticClass: "silver f6 mt1" }, [
                        _vm._v("Time zone is set to " + _vm._s(_vm.timezone)),
                      ])
                    : _vm._e(),
                  _vm.timeError && !_vm.isdisabled
                    ? _c("div", { staticClass: "adori-red f6 mt1" }, [
                        _vm._v("Select a time in the future"),
                      ])
                    : _vm._e(),
                ]),
                _c(
                  "div",
                  { staticClass: "save flex mt4" },
                  [
                    _c("BaseButtonRed", {
                      attrs: {
                        text: "Save",
                        disabled: _vm.hasErrors,
                        onClick: function () {
                          _vm.handleSave()
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c("AIPanel", {
              ref: "_searchAndSelectTray",
              attrs: {
                data: _vm.aiData,
                onSelectItem: _vm.selectItem,
                context: _vm.selectedContext,
              },
            }),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }