var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _c(
      "i",
      {
        staticClass: "material-icons gray mr1 v-mid",
        class: _vm.disabled ? "not-allowed" : "pointer ",
        on: {
          click: function ($event) {
            return _vm.onToggle()
          },
        },
      },
      [_vm._v("\n    " + _vm._s(_vm.icon) + "\n  ")]
    ),
    _c(
      "p",
      {
        staticClass: "dib f6",
        class: _vm.disabled ? "gray not-allowed" : "light-gray",
      },
      [_vm._v("\n    " + _vm._s(_vm.text) + "\n  ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }