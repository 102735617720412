

































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import QrCode from '@/components/Common/QrCode.vue'

@Component({
  components: { QrCode },
})
export default class Share extends Vue {
  @Prop() audio!: any
  @Prop() youtubeURL!: string

  showEmbedCode: boolean = false
  showQr = false

  showBrandedNotification(info: string) {
    this.$store.dispatch('pushNotification', { text: info, type: 'SUCCESS' })
  }

  copyEmbed(id: string) {
    const ele: any = document.getElementById(id)
    const windorRef: any = window
    ele.select()
    document.execCommand('copy')
    this.showBrandedNotification('Copied to clipboard')
    windorRef.getSelection().removeAllRanges()
  }
  get shareURL() {
    return this.youtubeURL
  }
  get shareEmbedCode() {
    let urlId = this.youtubeURL.split('youtu.be/')[1]
    let src = `https://www.youtube.com/embed/${urlId}?autoplay=1&mute=1`
    let iframe = `<iframe width="420" height="345" src=${src}></iframe>`
    return iframe
  }

  postToTwitter() {
    let text = this.audio.title
    let twitterUrl = `https://twitter.com/intent/tweet?text=${text}\
&url=${this.shareURL}`
    window.open(twitterUrl, '_blank')
  }
  postToLinkedIn() {
    let linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/\
?url=${this.shareURL}`
    window.open(linkedinUrl, '_blank')
  }
  postToFacebook() {
    let title = this.audio.title
    let facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${this.shareURL}\
&title=${title}`
    window.open(facebookUrl, '_blank')
  }
  postToWhatsapp() {
    let title = this.audio.title
    let whatsappUrl = `https://api.whatsapp.com/send?text=${title}${this.shareURL}`
    window.open(whatsappUrl, '_blank')
  }
  postToReddit() {
    let title = this.audio.title
    let redditUrl = `https://reddit.com/submit?url=${this.shareURL}&title=${title}`
    window.open(redditUrl, '_blank')
  }
}
