

























































































































import { Vue, Component } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import AdoriService from '@/services/adori'

import get from 'lodash.get'

// utils
import { msToSeconds } from '@/utils/time'

@Component({
  components: {},
})
export default class ShowsTag extends Vue {
  @Action getRssFeedsBasedOnUids!: any
  @Action getRssFeedItemsBasedOnUids!: any
  @Action getAudioTracksForTag!: any
  @Action getAudioUidsByTag!: any
  @Action clearRssFeedsBasedOnTags!: any
  @Getter tag!: any
  @Getter networkId!: any
  @Getter rssFeedsBasedOnUids!: []
  @Getter rssFeedItemsBasedOnUids!: any
  @Getter audioTracksForTag!: any
  @Getter audioUidsByTag!: []
  selectedType: string = 'SHOW'

  loadingShows: boolean = false
  loadingEpisodes: boolean = false
  showSection: boolean = false

  get tagId() {
    return this.$store.state.modal.showTagModal.tagId
  }

  get tagData() {
    return this.tag(this.tagId)
  }

  get hasVideoOrImage() {
    if (get(this.tagData, 'video.id')) {
      return true
    }
    return false
  }

  get videoUrl() {
    return get(this.tagData, 'video.originalUrl', '')
  }

  get tagImage() {
    if (get(this.tagData, 'image.url')) {
      return this.tagData.image.url
    } else {
      return ''
    }
  }
  async mounted() {
    this.loadingShows = true
    this.loadingEpisodes = true
    const tag: any = await AdoriService.fetchTag(this.networkId, this.tagId)
    const feedUids = tag && tag.feedUids

    // we need below:
    await this.getShows(feedUids)
    await this.getEpisodes(feedUids)
    await this.getAudioForTags()
    await this.getAudioUidsByTag({
      tagId: this.tagId,
    })
    this.loadingShows = false
    this.loadingEpisodes = false
  }

  async getShows(feedUids: []) {
    await this.getRssFeedsBasedOnUids({
      networkId: this.networkId,
      feedUids,
    })
  }

  toggle(show: any) {
    this.$set(show, 'showDetails', !show.showDetails)
  }

  formatMillis(duration: number) {
    return msToSeconds(duration)
  }

  episodesForShow(feedUid: string) {
    const obj = this.rssFeedItemsBasedOnUids.find((itm: { [key: string]: any }) => feedUid.indexOf(itm.feedUid) > -1)
    if (obj) {
      return obj.data
    } else {
      return []
    }
  }

  audioTrackUIdData(audioTrackUid: string) {
    const audioTrack = this.audioTracksForTag.find((x: { [key: string]: any }) => x.audioTrackUid === audioTrackUid)
    if (audioTrack) {
      return audioTrack
    } else {
      return {}
    }
  }

  async getEpisodes(feedUids: []) {
    await this.getRssFeedItemsBasedOnUids({
      networkId: this.networkId,
      feedUids,
      tagId: this.tagId,
    })
  }

  async getAudioForTags() {
    await this.getAudioTracksForTag({
      tagId: this.tagId,
    })
  }

  setSelectedType(value: string) {
    this.selectedType = value
  }

  beforeDestroy() {
    this.clearRssFeedsBasedOnTags()
  }
}
