




































import { Vue, Component, Prop, Mixins } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import { mixin as clickaway } from 'vue-clickaway'

@Component
export default class BasePane extends Mixins(clickaway) {
  @Prop(Boolean) locked!: boolean
  @Prop(Boolean) hideCloseButton!: boolean
  @Prop(Boolean) isScreenPane!: boolean
  @Prop(Boolean) showArrow!: boolean
  @Prop(Boolean) bottomAnimation!: boolean
  @Prop(Boolean) fitWidth!: boolean
  @Prop(Boolean) fitHeight!: boolean
  @Prop(Boolean) disableClosePane!: boolean
  @Prop({ default: '0rem' })
  leftOffset!: string
  @Prop({ default: '0rem' })
  rightOffset!: string
  @Prop({ default: '0rem' })
  topOffset!: string
  @Prop({ default: '0rem' })
  bottomOffset!: string
  @Prop({ default: 'rgba(17, 17, 17, 1)' })
  background!: string
  @Prop({ default: 'RIGHT' })
  paneType!: string // RIGHT, LEFT, BOTTOM, TOP

  @Getter showPane!: any
  @Action setShowPane!: any

  handleClickedOutside() {
    if (!this.locked) {
      this.$store.dispatch('closeModal')
    }
  }

  get screenPaneStyles() {
    if (!this.isScreenPane) return 'position: absolute;'
    return `position: fixed;
      top: ${this.paneType === 'BOTTOM' ? 'auto' : this.headerHeight || 0}px;
      height: calc(100vh - ${this.headerHeight}px)`
  }

  get headerHeight() {
    if (!this.isScreenPane) return ''
    const header = document.getElementById('theAdoriHeader') as HTMLElement
    return header && header.getBoundingClientRect().height
  }

  get basicStyles() {
    return `background: ${this.background};
      backdrop-filter: blur(13px);
      padding: 1.25rem;
      height: 100%;
      ${this.fitWidth ? 'width: 100%;' : ''}
      ${this.screenPaneStyles}`
  }

  get rightPaneStyles() {
    return `right: 0;
      ${this.showArrow ? 'max-width: 90vw' : ''};
      ${this.basicStyles}`
  }

  get leftPaneStyles() {
    return `left: 0;
      ${this.showArrow ? 'max-width: 90vw' : ''};
      ${this.basicStyles}`
  }

  get topPaneStyles() {
    return `right: 0;
      top: 0;
      ${this.showArrow ? 'max-height: 90%' : ''};
      ${this.basicStyles}`
  }

  get bottomPaneStyles() {
    return `right: 0;
      bottom: 0;
      ${this.showArrow ? 'max-height: 90%' : ''};
      ${this.basicStyles}`
  }

  get transitionName() {
    switch (this.paneType) {
      case 'RIGHT':
        return `slide-right-fade`
      case 'LEFT':
        return `slide-left-fade`
      case 'TOP':
        return `slide-top-fade`
      case 'BOTTOM':
        return `slide-bottom-fade`
    }
  }

  get styles() {
    switch (this.paneType) {
      case 'RIGHT':
        return this.rightPaneStyles
      case 'LEFT':
        return this.leftPaneStyles
      case 'TOP':
        return this.topPaneStyles
      case 'BOTTOM':
        return this.bottomPaneStyles
    }
  }

  get arrowStyles() {
    switch (this.paneType) {
      case 'RIGHT':
        return `left: -2.5rem; 
        top: 45%;
        background: ${this.background};`
      case 'LEFT':
        return `right: -2.5rem;
        top: 45%;
        transform: rotate(180deg);
        background: ${this.background};`
      case 'TOP':
        return `bottom: -3.9rem;
        left: 45%;
        transform: rotate(-90deg);
        background: ${this.background};`
      case 'BOTTOM':
        return `top: -3.9rem;
        left: 45%;
        transform: rotate(90deg);
        background: ${this.background};`
    }
  }

  handleCloseButtonClicked() {
    this.$store.dispatch('closeModal')
  }

  closePane() {
    // if (!this.showArrow)
    if (!this.disableClosePane) {
      this.setShowPane(false)
    }
  }
}
