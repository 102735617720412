










































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import AudiogramImagesList from '@/components/Audiogram/AudiogramImagesList.vue'
import debounce from 'lodash.debounce'
import GifLibraries from '@/mixins/gifLibraries'
import InfiniteImageList from './InfiniteImageList.vue'
import ModalPreviewImages from '@/components/Modals/ModalPreviewImages.vue'

@Component({
  components: {
    AudiogramImagesList,
    InfiniteImageList,
    ModalPreviewImages,
  },
})
export default class GifGallery extends Mixins(GifLibraries) {
  @Prop() source!: string
  searchQuery: string = ''
  showImagePreview = false
  image = ''

  get searchLibraries() {
    return [
      {
        LibraryName: 'TRENDING',
        name: 'Trending',
      },
      {
        LibraryName: 'GIPHY',
        name: 'Giphy',
      },
      {
        LibraryName: 'TENOR',
        name: 'Tenor',
      },
    ]
  }

  viewImages(image: string) {
    if (this.source === 'AI-PANEL') {
      this.$emit('viewImages', image)
    } else {
      this.showImagePreview = true
      this.image = image
    }
  }

  clearSearchQuery() {
    this.searchQuery = ''
    this.gifSearchText = ''
    this.resetOffset()
  }

  debouncedSearch = debounce(() => {
    this.searchImages()
  }, 600)

  searchImages() {
    this.gifSearchText = this.searchQuery
  }
}
