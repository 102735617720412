




























































import { Component, Prop, Mixins } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import { getImageSrc } from '@/services/adori'
import AudiogramVideosList from '@/components/Audiogram/AudiogramVideosList.vue'
import debounce from 'lodash.debounce'
import VideoLibraries from '@/mixins/VideoLibraries'

@Component({
  components: {
    AudiogramVideosList,
  },
})
export default class AudiogramSearchVideos extends Mixins(VideoLibraries) {
  @Getter networkId!: string
  @Getter networkType!: any

  @Action showTagSuggestion!: Function
  @Action getImages!: Function
  @Action setIsLoadingUserImages!: Function
  @Action getSearchImages!: Function
  @Action setShowLoader!: any

  getImageSrc = getImageSrc

  selectedVideoTab: string = 'PEXELS' // or 'MY_LIBRARY'

  previewImageSrc: string = ''
  userImageStartIndex: number = 0
  userImageCountPerPage: number = 15
  selectedImage: any = {}

  searchQuery: string = ''
  isFetchingImages: boolean = false
  hasSearched: boolean = false

  get searchLibraries() {
    return [
      {
        LibraryName: 'PEXELS',
        name: 'Pexels',
      },
      {
        LibraryName: 'PIXABAY',
        name: 'Pixabay',
      },
    ]
  }

  searchVideos() {
    this.videoSearchText = this.searchQuery
  }

  clearSearchQuery() {
    this.searchQuery = ''
  }

  async resetAndSearchUnsplash() {
    this.searchVideos()
  }

  debouncedSearch = debounce(() => {
    this.searchVideos()
  }, 600)

  loadVideo(video: any) {
    this.$emit('load-video', video)
  }
}
