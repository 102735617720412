






































































































































































import { Component, Vue, Mixins, Watch } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import get from 'lodash.get'
import { getImageSrc } from '@/services/adori'
import { NETWORKROLES } from '@/constants/roles'
import AdoriService from '@/services/adori'

@Component
export default class ModalProfileEdit extends Vue {
  @Getter hasLoadedUser!: any
  @Getter networkUsers!: any
  @Getter isFirstTimeUser!: boolean
  @Getter currentNetwork!: any
  @Getter selectedFile!: any
  @Getter networkId!: string

  @Action getNetworkUsers!: any
  @Action setFirstTimeUser!: any
  @Action uploadCurrentNetworkInfo!: any

  imageFile = 'PROFIlE'
  isSubmitting: boolean = false
  isNetworkOwner: boolean = false

  profileCroppa: any = {}

  isProfileImageSelected = false
  profileImageId = ''

  networkCroppa: any = {}
  isNetworkImageSelected = false
  networkImageId = ''

  async mounted() {
    if (!this.networkUsers && this.currentNetwork.role.name === NETWORKROLES.owner) {
      await this.getNetworkUsers()
    }
    if (this.hasLoadedUser && this.networkUsers) {
      this.isNetworkOwner = this.currentNetwork.role.name === NETWORKROLES.owner
    }
  }

  @Watch('hasLoadedUser')
  onHasLoadedUser() {
    this.isNetworkOwner = this.currentNetwork.role.name === NETWORKROLES.owner
  }

  // get hasNetworkMetaManagePermission () {
  //   return this.$permissions.isManageNetworkMetaAllowed()
  // }

  get name() {
    return this.$store.state.modal.profileEditor.name
  }

  get networkName() {
    return this.$store.state.modal.profileEditor.networkName
  }

  set name(newName: string) {
    this.$store.dispatch('changeProfileName', newName)
  }

  set networkName(newName: string) {
    this.$store.dispatch('changeProfileNetworkName', newName)
  }

  get bio() {
    return this.$store.state.modal.profileEditor.bio
  }

  set bio(newBio: string) {
    this.$store.dispatch('changeProfileBio', newBio)
  }

  get hasFilledAllDetails() {
    return this.name && this.networkName && this.bio && this.bio.length <= 512
  }

  get profileImage() {
    if (this.$store.state.modal.profileEditor.profileImageId) {
      this.profileImageId = this.$store.state.modal.profileEditor.profileImageId
      this.isProfileImageSelected = true
      return getImageSrc(this.profileImageId)
    }
    this.profileImageId = ''
    this.isProfileImageSelected = false
    return null
  }
  get networkImage() {
    if (get(this.currentNetwork.network, 'logoImageId', null)) {
      this.networkImageId = get(this.currentNetwork.network, 'logoImageId')
      this.isNetworkImageSelected = true
      return getImageSrc(this.networkImageId)
    }
    this.networkImageId = ''
    this.isNetworkImageSelected = false
    return null
  }

  handleProfileChoose() {
    // @ts-ignore
    this.profileCroppa.chooseFile()
  }
  handleNetworkChoose() {
    // @ts-ignore
    this.networkCroppa.chooseFile()
  }

  profileFileChoosen() {
    this.$store.commit('setProfileImageId', null)
    this.isProfileImageSelected = true
  }
  networkFileChoosen() {
    const networks = this.currentNetwork
    networks.network.logoImageId = ''
    this.$store.commit('setCurrentNetwork', networks)
    this.isNetworkImageSelected = true
  }

  async loadProfileImage() {
    // @ts-ignore
    const file = await this.profileCroppa.promisedBlob()
    if (file) {
      const img: any = await AdoriService.uploadImage(this.networkId, file)
      this.profileImageId = img.id
      Vue.set(img, 'profileImage', img.url)
    }
  }

  async loadNetworkImage() {
    const file = await this.networkCroppa.promisedBlob()
    if (file) {
      const img: any = await AdoriService.uploadImage(this.networkId, file)
      this.networkImageId = img.id
      Vue.set(img, 'networkImage', img.url)
    }
  }

  async uploadProfileDetails() {
    if (this.hasFilledAllDetails) {
      this.isSubmitting = true
      try {
        if (!this.profileImageId) {
          await this.loadProfileImage()
        }
        if (this.profileImageId) {
          await this.$store.commit('setProfileImageId', this.profileImageId)
        }
        if (this.isNetworkOwner) {
          const payload: any = {}
          payload['name'] = this.$store.state.modal.profileEditor.networkName
          if (!this.networkImageId) {
            await this.loadNetworkImage()
          }
          if (this.networkImageId) {
            payload['logoImageId'] = this.networkImageId
          }
          await this.uploadCurrentNetworkInfo(payload)
        }
        await this.$store.dispatch('uploadProfileDetails', this.$store.state.modal.profileEditor)
        await this.setFirstTimeUser(false)
        this.$store.dispatch('closeModal')
      } catch (error) {
        this.isSubmitting = false
      }
      this.isSubmitting = false
    }
  }

  async onCancel() {
    this.$store.dispatch('closeModal')
    this.isSubmitting = false
  }
}
