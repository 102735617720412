






















































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import { toReadableDate } from '@/utils/time'
import ProgressBar from 'vuejs-progress-bar'
import { pluralize } from '@/utils/misc'
import { uploadStatus } from '@/components/Publish/publish'
import { secondsToHMS } from '@/components/Youtube/time'
import isEmpty from 'lodash.isempty'

import YoutubeShare from '@/components/Youtube/Modal/ModalYoutubeShare.vue'
// service
import AdoriService from '@/services/adori'
import moment from 'moment'

@Component({
  components: {
    ProgressBar,
    YoutubeShare,
  },
})
export default class YoutubeAudioComboBox extends Vue {
  @Prop(String) audioUid!: string
  @Prop(Object) episode!: any
  @Prop(Boolean) isFirstClip!: boolean
  @Prop(String) ytFeedUid!: string

  @Getter uploadState!: boolean
  @Getter('audio') audioTrack!: any
  @Getter networkId!: string
  @Getter networkType!: string
  @Getter publicFeedEpisodes!: any
  @Getter signinStatus!: any
  @Getter selectedRssFeedUid!: any
  @Getter rssFeed!: any
  @Getter isYoutube!: boolean

  @Action youtubeUploadStatus!: any
  @Action pushNotification!: any
  @Action getPublicFeedEpisodes!: any
  @Action closeModal!: any
  @Action clearAllRssFeedItemsByFeedUid!: any
  @Action updateRssFeedItems!: any

  pluralize = pluralize

  shareAudio = false
  progressBarvalue = Math.ceil(this.audio.progress)
  interval!: any
  uploadError = null
  audioStatus = ''
  videoId = this.audio.videoId
  newLoader = false
  loading = false

  youtubeError = false
  youtubeProcessing = false
  youtubeText = 'View'
  showConfetti = false
  currentAudioDetails = null

  get youtubeSignInStatus() {
    return this.ytFeedUid && this.signinStatus[this.ytFeedUid]?.youtube
      ? this.signinStatus[this.ytFeedUid]?.youtube
      : this.signinStatus[this.networkId]?.youtube
      ? this.signinStatus[this.networkId]?.youtube
      : false
  }

  async created() {
    if (!isEmpty(this.audio)) {
      this.loading = true
      const statusPayload: any = {
        network_id: this.networkId,
        task_id: this.audio.id,
        get_youtube_status: this.youtubeSignInStatus,
      }

      const audio = await this.youtubeUploadStatus(statusPayload)
      this.loading = false

      this.currentAudioDetails = audio

      if (audio.status === uploadStatus.FINISHED && audio.youtubeProcessingDetails === null) {
        this.youtubeError = this.youtubeSignInStatus ? true : false
      }

      if (audio.status === uploadStatus.QUEUED || audio.status === uploadStatus.STARTED) {
        this.$store.commit('addUploadToCounter', audio.id)
        this.interval = setInterval(
          async () => {
            const statusPayload: any = {
              network_id: this.networkId,
              task_id: this.audio.id,
              get_youtube_status: this.youtubeSignInStatus,
            }

            const getRes = await this.youtubeUploadStatus(statusPayload)
            this.currentAudioDetails = getRes
            if (getRes.status === uploadStatus.QUEUED || getRes.status === uploadStatus.STARTED) {
              this.audioStatus = getRes.status
              this.progressBarvalue = Math.ceil(getRes.progress)
            } else if (getRes.status === uploadStatus.FINISHED) {
              this.audioStatus = getRes.status
              this.progressBarvalue = 100
              this.videoId = getRes.videoId
              if (!this.youtubeSignInStatus) clearInterval(this.interval)
              else if (getRes.youtubeProcessingDetails === null && getRes.publishToYoutube) {
                this.youtubeError = true
                this.pushNotification({
                  text: `There was a problem uploading to youtube <a href="https://help.adorilabs.com/en/collections/3509519-adori-video-and-studio">Learn More</a>`,
                  type: 'ERROR',
                })
                this.$store.commit('removeUploadFromCounter', getRes.id)
                clearInterval(this.interval)
              } else if (getRes.youtubeProcessingDetails === null && !getRes.publishToYoutube) {
                clearInterval(this.interval)
              } else {
                if (getRes.youtubeProcessingDetails.processingStatus === 'processing') {
                  this.youtubeText = 'Processing'
                  this.youtubeProcessing = true
                }
                if (getRes.youtubeProcessingDetails.processingStatus === 'succeeded') {
                  this.$store.commit('removeUploadFromCounter', getRes.id)
                  this.youtubeText = 'View'
                  this.youtubeProcessing = false
                  clearInterval(this.interval)
                }
              }
            } else {
              this.uploadError = getRes.errorCode
              clearInterval(this.interval)
            }
          },
          audio && audio.duration <= 300 ? 6000 : 11000
        )
      }
    }
  }

  destroyed() {
    clearInterval(this.interval)
  }

  youtubeLink() {
    window.open('//youtu.be/' + this.videoId, '_blank')
  }

  get youtubeLinkShare() {
    return `https://youtu.be/${this.videoId}`
  }

  get showYoutubeLink() {
    return this.videoId && this.audio.youtubeProcessingDetails ? true : false
  }

  get isScheduled() {
    return this.audio.status === uploadStatus.SCHEDULED ? true : false
  }
  get scheduledFor() {
    if (this.audio.scheduledFor) {
      return (
        'Scheduled For:' + toReadableDate(this.audio.scheduledFor) + ' , ' + this.toLocalTime(this.audio.scheduledFor)
      )
    }
  }

  toLocalTime(timeString: string) {
    let date = new Date(timeString).toString()
    let time = date.split(' ')[4].substring(0, 5)
    let offset = date.split(' ')[5]
    return time + ' ' + offset
  }

  get audio() {
    return this.currentAudioDetails || this.episode
  }

  get showProgressBar() {
    if (this.audioStatus === uploadStatus.FINISHED) return false
    else if (this.audio.status == uploadStatus.QUEUED || this.audio.status == uploadStatus.STARTED) return true

    return false
  }

  get showPopupAction() {
    return this.showProgressBar || this.isScheduled ? false : true
  }

  get showDeleteButton() {
    return (
      moment().diff(moment(this.audio.createdOn), 'hour') >= 2 &&
      [uploadStatus.STARTED, uploadStatus.QUEUED].includes(this.audio.status)
    )
  }

  get audioPreviewImage() {
    return this.audio && this.audio.posterImage ? this.audio.posterImage.thumbnailUrl : null
  }

  get audioDuration() {
    if (this.audio.duration) {
      return secondsToHMS(this.audio.duration)
    }
    return 'Calculating...'
  }

  get audioDate() {
    if (this.audio.publishedOn) {
      return toReadableDate(this.audio.publishedOn)
    }

    if (this.audio.publishTime) {
      return toReadableDate(this.audio.publishTime)
    }
    return toReadableDate(this.audio.createdOn)
  }

  get track() {
    return this.audioTrack(this.episode.trackUid)
  }

  get audioIsPublic() {
    return this.audio.privacy
  }

  get audioTags() {
    return this.audio ? this.audio.tagCount : 0
  }

  get audioTagsText() {
    if (this.audioTags === 0) return 'No tags added'
    return `${this.audioTags} ${pluralize(this.audioTags, 'tag', 'tags')} added`
  }

  get audioDescription() {
    return this.audio.description
  }

  get podcastsWithAudio() {
    return (this.audio && this.audio.audioCollections) || []
  }

  get audioTrackMenu() {
    const menu: any = []
    this.$permissions.isYoutubeConvertVideoShowEpisodeAllowed(this.selectedRssFeedUid) &&
      menu.push({
        name: 'New Video',
        icon: 'ondemand_video',
        onClick: () => this.goToOnboarding(),
      })

    menu.push({
      name: 'Download Video',
      icon: 'download',
      onClick: () => this.downloadVideo(),
    })

    this.$permissions.isDeleteEpisodeShowAllowed(this.selectedRssFeedUid) &&
      menu.push({
        name: 'Remove Clip',
        icon: 'delete',
        onClick: () => this.removeVideo(),
      })
    this.showYoutubeLink &&
      menu.push({
        name: 'Copy YouTube Link',
        icon: 'content_copy',
        onClick: () => this.copyYoutubeLink(),
      })

    return menu
  }

  get cYoutubeLink() {
    return 'https://youtu.be/' + this.videoId
  }

  copyYoutubeLink() {
    // @ts-ignore
    this.$clipboard(this.cYoutubeLink)
    this.pushNotification({ text: 'Copied to clipboard', type: 'SUCCESS' })
  }

  openShare() {
    this.shareAudio = true
  }

  closeShare() {
    this.shareAudio = false
  }

  async downloadVideo() {
    const params = {
      network_id: this.networkId,
      task_id: this.audio.id,
    }

    const data: any = await AdoriService.youtube_download(params)
    if (!data.url) {
      this.pushNotification({ text: 'No URL available', type: 'ERROR' })
      return
    }
    if (this.$refs['videoDownloadRef']) {
      //@ts-ignore
      this.$refs['videoDownloadRef'].href = data.url
      const audioDownloadLink = this.$refs.videoDownloadRef as HTMLElement
      audioDownloadLink.click()
    }
  }

  async removeVideo() {
    const params = {
      network_id: this.networkId,
      task_id: this.audio.id,
    }

    this.$store.dispatch('showConfirm', {
      title: 'Remove video clip?',
      description: 'Are you sure you want to remove the video?',
      yesButtonText: 'Yes',
      onConfirm: async () => {
        try {
          this.closeModal()
          await AdoriService.youtube_upload_delete(params)
          this.pushNotification({ text: 'Video removed', type: 'SUCCESS' })
          this.$emit('reloadFeedItems')
        } catch (err) {}
        this.closeModal()
      },
    })
  }

  get feed() {
    return this.rssFeed(this.ytFeedUid)
  }

  async goToOnboarding() {
    const uid = this.$route.params.id

    if ((this.isYoutube && this.track && this.track.ytFeed === null) || (!this.isYoutube && !uid)) {
      this.newLoader = true
      const selectedEpisode: any = {
        audioUrl: this.track.audioUrl,
        name: this.track.name,
        description: this.track.description,
        explicitContent: this.track.explicitContent,
        guid: this.track.guid,
        imageId: this.track.imageId,
        imageUrl: this.track.imageUrl,
        isRss: false,
        keywords: this.track.keywords,
        durationSeconds: this.track.durationMillis / 1000,
        isReUpload: true,
        startTimeSec: this.episode.startTimeSec,
        endTimeSec: this.episode.endTimeSec,
        width: this.episode.width,
        height: this.episode.height,
      }

      this.$store.commit('unselectAllEpisodeForUpload')
      this.$store.commit('clearYoutubeState')
      this.$store.commit('resetYoutubeSetting')
      this.$store.commit('selectEpisodeForUpload', selectedEpisode)
      this.$store.commit('setYoutubeStep', 2)
      // @ts-ignore
      sessionStorage.setItem('upload', 'YES')
      sessionStorage.setItem('onboardingTrack', JSON.stringify(selectedEpisode))
      this.$router.push('/onboarding')
      this.newLoader = false
    } else {
      this.newLoader = true
      this.$store.commit('setCreatedYoutubeFeed', this.isYoutube ? this.track.ytFeed : { uid: uid })
      sessionStorage.setItem('feedUid', this.isYoutube ? this.track.ytFeed.uid : uid)
      await this.getPublicFeedEpisodes({
        url: this.isYoutube ? this.track.ytFeed.rss : this.feed.rss,
      })
      sessionStorage.setItem('feedUrl', this.isYoutube ? this.track.ytFeed.rss : this.feed.rss)
      const selectedEpisode = this.publicFeedEpisodes.filter((obj: any) => obj.guid === this.track.guid)[0]
      selectedEpisode['width'] = this.episode.width
      selectedEpisode['height'] = this.episode.height
      selectedEpisode['startTimeSec'] = this.episode.startTimeSec
      selectedEpisode['endTimeSec'] = this.episode.endTimeSec
      selectedEpisode['isRss'] = true
      selectedEpisode['youtubeKeywords'] = this.episode.keywords
      selectedEpisode['name'] = this.episode.title
      selectedEpisode['description'] = this.episode.description
      selectedEpisode['playlistId'] = this.episode.playlistId

      this.$store.commit('unselectAllEpisodeForUpload')
      this.$store.commit('clearYoutubeState')
      this.$store.commit('resetYoutubeSetting')
      this.$store.commit('selectEpisodeForUpload', selectedEpisode)
      this.$store.commit('setYoutubeStep', 2)
      // @ts-ignore
      sessionStorage.setItem('upload', 'YES')
      sessionStorage.removeItem('onboardingTrack')
      sessionStorage.removeItem('ytModalSetting')
      this.$router.push('/onboarding')
      this.newLoader = false
    }
  }

  truncate(str: string, count: number) {
    if (str.length < count) return str
    return str.substring(0, count) + '...'
  }

  options = {
    text: {
      color: '#FFFFFF',
      shadowEnable: true,
      shadowColor: '#000000',
      fontSize: 8,
      fontFamily: 'Helvetica',
      dynamicPosition: false,
      hideText: true,
    },
    progress: {
      color: '#de1a23',
      backgroundColor: 'grey',
      inverted: false,
    },
    layout: {
      height: 10,
      verticalTextAlign: 61,
      horizontalTextAlign: 43,
      zeroOffset: 0,
      strokeWidth: 30,
      progressPadding: 0,
      type: 'line',
    },
  }
}
