

























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class BaseMenuItem extends Vue {
  @Prop(Boolean) active!: boolean
  @Prop(Boolean) disableUnderline!: boolean
  @Prop(Boolean) disable!: boolean
  @Prop(Boolean) beta!: boolean
  @Prop(String) text!: string
  @Prop(String) icon!: string
  @Prop({ default: 'pv3' })
  customStyle!: string
  @Prop(Function) onClick!: () => void
  @Prop(String) type!: string
  @Prop(String) svgActive!: string
  @Prop(String) svgInactive!: string

  get activeSrc() {
    return this.svgActive
  }
  get inactiveSrc() {
    return this.svgInactive
  }

  get styleClass() {
    return (
      'f7 fw1 dib link light-gray glow pointer no-outline pv3 ' +
      this.customStyle +
      (this.active ? ' white o-100 bg-adori-light-gray' : ' o-50')
    )
  }

  get isBeta() {
    return this.beta
  }

  tabAction() {
    if (this.onClick) {
      this.onClick()
    }
  }
  get isDisabled() {
    return this.disable
  }
}
