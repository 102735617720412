var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "light-gray fw5 f3" }, [_vm._v("Preview")]),
      ]),
      _c("template", { slot: "body" }, [
        _c(
          "div",
          { staticClass: "flex flex-column justify-center items-center" },
          [
            _c("div", { staticClass: "flex justify-center items-center mb3" }, [
              _c(
                "div",
                { staticClass: "pv1 ba br4 b--transparent bg-black-20" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "pv1 ph2 silver link pointer",
                      class:
                        _vm.orientation === "PORTRAIT"
                          ? "br4 bg-adori-red light-gray"
                          : "",
                    },
                    [
                      _c("input", {
                        staticClass: "input-reset",
                        attrs: { type: "radio" },
                        domProps: { checked: _vm.orientation === "PORTRAIT" },
                        on: {
                          click: function ($event) {
                            _vm.orientation = "PORTRAIT"
                          },
                        },
                      }),
                      _vm._v("Portrait"),
                    ]
                  ),
                  _c(
                    "label",
                    {
                      staticClass: "pv1 ph2 silver link pointer",
                      class:
                        _vm.orientation === "LANDSCAPE"
                          ? "br4 bg-adori-red light-gray"
                          : "",
                    },
                    [
                      _c("input", {
                        staticClass: "input-reset",
                        attrs: { type: "radio" },
                        domProps: { checked: _vm.orientation === "LANDSCAPE" },
                        on: {
                          click: function ($event) {
                            _vm.orientation = "LANDSCAPE"
                          },
                        },
                      }),
                      _vm._v("Landscape"),
                    ]
                  ),
                ]
              ),
            ]),
            _vm.orientation === "PORTRAIT"
              ? _c(
                  "div",
                  {
                    staticClass: "relative",
                    on: {
                      mouseover: function ($event) {
                        _vm.hoverOnIframe = true
                      },
                      mouseleave: function ($event) {
                        _vm.hoverOnIframe = false
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        height: "500",
                        src: require("@/assets/phone-portrait.svg"),
                      },
                    }),
                    _c("div", { staticClass: "absolute iphonex-portrait" }, [
                      _vm.isLoading
                        ? _c("div", {
                            staticClass:
                              "absolute bg-adori-light-gray skeleton",
                            staticStyle: { width: "350px", height: "700px" },
                          })
                        : _vm._e(),
                      _c("iframe", {
                        attrs: {
                          frameBorder: "0",
                          scrolling: "no",
                          title: "Adori web Player",
                          width: "350",
                          height: "700",
                          src: _vm.iframePortraitSrc,
                        },
                      }),
                    ]),
                    _vm.showEditButton
                      ? _c("BaseButtonRed", {
                          staticClass: "absolute edit-btn-portrait",
                          attrs: {
                            text: "Edit tag",
                            onClick: _vm.openTagEditor,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.orientation === "LANDSCAPE"
              ? _c(
                  "div",
                  {
                    staticClass: "relative",
                    on: {
                      mouseover: function ($event) {
                        _vm.hoverOnIframe = true
                      },
                      mouseleave: function ($event) {
                        _vm.hoverOnIframe = false
                      },
                    },
                  },
                  [
                    _c("iframe", {
                      attrs: {
                        frameBorder: "0",
                        scrolling: "no",
                        title: "Adori web Player",
                        width: "640",
                        height: "400",
                        src: _vm.iframeLandscapeSrc,
                      },
                    }),
                    _vm.showEditButton
                      ? _c("BaseButtonRed", {
                          staticClass: "absolute edit-btn-landscape",
                          attrs: {
                            text: "Edit tag",
                            onClick: _vm.openTagEditor,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isYoutube
              ? _c("div", { staticClass: "flex flex-column mt3" }, [
                  _c("div", { staticClass: "pv1 silver" }, [
                    _vm._v(
                      "\n          1. Choose Portrait to check video preview for Youtube short, Instagram reels, TikTok etc.\n        "
                    ),
                  ]),
                  _c("div", { staticClass: "pv1 silver" }, [
                    _vm._v(
                      "2. Choose Landscape to check video preview for Youtube, website etc."
                    ),
                  ]),
                  _c("div", { staticClass: "pv1 silver" }, [
                    _vm._v(
                      "\n          3. Final video may vary because effects and transitions are not part of video preview\n        "
                    ),
                  ]),
                ])
              : _vm._e(),
          ]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }