var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tooltip-container" }, [
    _c("div", { staticClass: "tooltip-data" }, [_vm._t("default")], 2),
    _c(
      "div",
      {
        staticClass: "tooltip",
        on: {
          click: function ($event) {
            $event.stopPropagation()
          },
        },
      },
      [_c("span", { staticClass: "text" }, [_vm._v(_vm._s(_vm.text))])]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }