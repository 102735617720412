

































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { ADORI_API_BASE_URL } from '@/constants'
import { toClockLike, secondsToMmss } from '@/utils/time'
import { Getter } from 'vuex-class'

@Component
export default class AudioPlayer extends Vue {
  @Prop(String) audioUid!: String
  @Prop(Number) currentTime!: number
  @Prop(Boolean) isPlaying!: boolean

  @Getter ytModalId!: any
  @Getter selectedEpisodeSettings!: any
  startTimeSec = 0
  endTimeSec = this.audio.durationMillis / 1000

  mounted() {
    if (this.ytModalId && this.selectedEpisodeSettings[this.ytModalId]) {
      this.startTimeSec = this.selectedEpisodeSettings[this.ytModalId].startTimeSec
      this.endTimeSec = this.selectedEpisodeSettings[this.ytModalId].endTimeSec
    }
  }

  togglePlay() {
    this.$emit('toggle-play', !this.isPlaying)
  }

  get audio() {
    return this.$store.getters.audio(this.audioUid)
  }

  get audioSrc() {
    const token = this.$store.getters.token
    const networkId = this.$store.getters.networkId
    return `${ADORI_API_BASE_URL}/networks/${networkId}/audiotracks/${this.audioUid}/audio?api_token=${token}`
  }

  get playButtonIcon() {
    return (this.isPlaying ? 'pause' : 'play') + '_circle_outline'
  }

  get formattedCurrentTime() {
    return secondsToMmss(this.currentTime)
  }

  get formattedTotalTime() {
    return secondsToMmss(
      Math.ceil(this.ytModalId && this.endTimeSec ? this.endTimeSec : this.audio.durationMillis / 1000)
    )
  }

  onInputChange(evt: any) {
    this.$emit('scroll-timeline', parseInt(evt.target.value))
  }

  seek(forward: boolean) {
    const seekedBy = forward ? 10 : -10
    this.$emit('scroll-timeline', this.currentTime + seekedBy)
  }

  updateTime() {
    this.$emit('scroll-timeline', (this.$refs.audio as any).currentTime)
  }

  @Watch('isPlaying')
  onIsPlayingChange(newIsPlaying: boolean) {
    const audio: any = this.$refs.audio
    if (!newIsPlaying) audio.pause()
    else {
      if (this.ytModalId && this.endTimeSec && audio.currentTime === this.endTimeSec) {
        audio.currentTime = this.startTimeSec
        audio.play()
      } else {
        audio.play()
      }
    }
  }

  @Watch('currentTime')
  onTimeChanged(newTime: number) {
    const audio: any = this.$refs.audio
    if (this.ytModalId && this.endTimeSec && newTime >= this.endTimeSec) {
      audio.pause()
      this.isPlaying && this.$emit('toggle-play', true)
      audio.currentTime = this.endTimeSec
      return
    }

    if (Math.abs(newTime - audio.currentTime) > 0.5) {
      audio.currentTime = newTime
    }
  }
}
