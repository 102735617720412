var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-wrap flex-around" },
    _vm._l(Object.keys(_vm.suggestionsByTopic), function (topic) {
      return _c(
        "div",
        {
          key: topic,
          staticClass: "mh3 darker-hover pointer",
          on: {
            click: function ($event) {
              return _vm.$emit("tag-topic", topic)
            },
          },
        },
        [
          _c("p", { staticClass: "light-gray f4 dib mr3" }, [
            _vm._v(_vm._s(topic)),
          ]),
          _c("p", { staticClass: "gray dib" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.suggestionsByTopic[topic].length) +
                "\n      possible " +
                _vm._s(_vm.pluralizeTag(_vm.suggestionsByTopic[topic].length)) +
                "\n    "
            ),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }