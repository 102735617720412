












import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class BaseAdoriTagCaption extends Vue {
  @Prop(String) caption!: string
  @Prop(Number) topMargin!: number
  @Prop(String) fontClass!: string
  @Prop(Boolean) centered!: boolean

  get currentTag() {
    return this.$store.state.modal.tagEdit.tagType
  }

  get captionPosition() {
    if (this.centered) {
      return { top: '50%', transform: 'translateY(-50%)' }
    }

    // If top margin is 1, align to the bottom
    if (this.topMargin === 1) return { bottom: 0 }

    // For any other top margin values, align to the top
    return { top: this.topMargin * 100 + '%' }
  }
}
