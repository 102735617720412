var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "absolute flex items-center tag-acn-btns",
      class:
        _vm.orientation === "LANDSCAPE"
          ? "justify-around bigger bottom-150 w-60 mrlf9"
          : "justify-center bottom-20 w-100 ",
    },
    [
      _c("img", {
        staticClass: "tag-btn nodrag",
        class: _vm.saveable ? "o-100" : "o-0",
        style: _vm.orientation === "LANDSCAPE" ? "" : "margin-right: 20px;",
        attrs: { src: require("@/assets/previewplayer/save.svg") },
      }),
      _c("img", {
        staticClass: "tag-btn nodrag",
        style: _vm.orientation === "LANDSCAPE" ? "" : "margin-right: 20px;",
        attrs: { src: _vm.tagTypeSrc },
      }),
      _c("img", {
        staticClass: "tag-btn nodrag",
        class: _vm.shareable ? "o-100" : "o-0",
        attrs: { src: require("@/assets/previewplayer/share.svg") },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }