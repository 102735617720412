var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    { attrs: { hasCustomEvent: "" }, on: { closeModal: _vm.handleCLose } },
    [
      _c("template", { slot: "header" }, [
        _c("p", { staticClass: "white f3" }, [_vm._v("Upgrade Plan")]),
      ]),
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "plan-container" }, [_c("PlansV2")], 1),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }