import Vue from 'vue'
import { StoreOptions, GetterTree, MutationTree, ActionTree } from 'vuex'
import get from 'lodash.get'

const state: any = {
  ref: '',
  accept: 'image/*',
  onChange: () => {},
  selectedFile: null,
  multiple: false,
}

const initialState: any = {
  ...JSON.parse(JSON.stringify(state)),
}

const getters: GetterTree<any, any> = {
  selectedFile: (state) => state.selectedFile,
}

const mutations: MutationTree<any> = {
  resetState(state) {
    Object.keys(state).forEach((key: any) => {
      if (key !== 'ref') {
        Vue.set(state, key, initialState[key])
      }
    })
  },

  setRef(state, ref: any) {
    Vue.set(state, 'ref', ref)
  },

  setAccept(state, accept: string) {
    Vue.set(state, 'accept', accept)
  },

  setOnChange(state, onChange: () => void) {
    Vue.set(state, 'onChange', onChange)
  },

  setSelectedFile(state, file) {
    Vue.set(state, 'selectedFile', file)
  },

  setMultiple(state, multiple) {
    Vue.set(state, 'multiple', multiple)
  },

  clearSelectedFile(state) {
    if (get(state, 'ref.value')) {
      Vue.set(state.ref, 'value', '')
    }

    if (get(state, 'ref.selectedFile')) {
      Vue.set(state, 'selectedFile', null)
    }
  },
}

const actions: ActionTree<any, any> = {
  resetFileSelectorState(context) {
    context.commit('resetState')
  },

  async showFileUploader(context, data) {
    await context.dispatch('setFileUploaderPreferences', data)
    context.commit('clearSelectedFile')
    if (!data.imageFile) setTimeout(() => context.state.ref.click(), 20)
    else await context.dispatch('onFileUploaded', { imageFile: data.imageFile })
  },

  async setFileUploaderPreferences(context, data) {
    Object.keys(context.state).forEach((k) => {
      if (data[k]) {
        context.commit('set' + k.charAt(0).toUpperCase() + k.slice(1), data[k])
      }
    })
  },

  async onFileUploaded(context, data) {
    if (get(data, 'imageFile')) {
      context.commit('setSelectedFile', data.imageFile)
    } else if (get(data, 'multiple')) {
      context.commit('setSelectedFile', context.state.ref.files)
    } else {
      context.commit('setSelectedFile', context.state.ref.files[0])
    }

    context.state.onChange()
  },

  async clearSelectedFile(context) {
    context.commit('clearSelectedFile')
  },
}

const fileSelector: StoreOptions<any> = {
  state,
  getters,
  mutations,
  actions,
}

export default fileSelector
