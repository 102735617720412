import Vue from 'vue'
import { StoreOptions, GetterTree, MutationTree, ActionTree } from 'vuex'
import get from 'lodash.get'

import {
  AdvertiserFetchParams,
  AdvertiserPayload,
  CampaignFetchParams,
  CampaignPayload,
  CampaignUpdate,
} from '@/Interfaces/StoreInterFaces/campaignInterfaces/Campaign'
import {
  FlightFetchParams,
  FlightPayload,
  ShowsFetch,
  updateShows,
} from '@/Interfaces/StoreInterFaces/campaignInterfaces/Flight'

import { AnalyticsByTimeParams, AnalyticsParams } from '@/Interfaces/StoreInterFaces/campaignInterfaces/Report'

import AdoriService from '@/services/adori_v6'

const state: any = {
  campaigns: {},
  campaignsCount: 0,
  flights: {},
  flightsCount: 0,

  advertisers: [],
  sponsors: [],
  allAdvertisers: [],

  analyticsByTime: {},
  analyticsByPosition: {},
  analyticsByFlight: {},
  analyticsByShow: {},
  analyticsSummary: {},
  analyticsReload: {},
  campaignShows: {},
  targetEpisodeList: {},
  section: 'SHOW',
}

const getters: GetterTree<any, any> = {
  campaigns: (state) => state.campaigns,
  campaignShows: (state) => state.campaignShows,
  campaignsCount: (state) => state.campaignsCount,
  flights: (state) => state.flights,
  flightsCount: (state) => state.flightsCount,
  advertisers: (state) => state.advertisers,
  allAdvertisers: (state) => state.allAdvertisers,
  sponsors: (state) => state.sponsors,
  advertiserById: (state) => (adId: string) => state.allAdvertisers.filter((obj: any) => obj.id === adId)[0],
  analyticsByTime: (state) => state.analyticsByTime,
  analyticsByPosition: (state) => state.analyticsByPosition,
  analyticsByFlight: (state) => state.analyticsByFlight,
  analyticsByShow: (state) => state.analyticsByShow,
  analyticsSummary: (state) => state.analyticsSummary,
  analyticsReload: (state) => state.analyticsReload,
  targetEpisodeList: (state) => state.targetEpisodeList,
  section: (state) => state.section,
}

const mutations: MutationTree<any> = {
  selectEpisodes(state, data) {
    Vue.set(state.targetEpisodeList, `${data.feedUid}`, data)
  },

  setTargetEpisodeList(state, list) {
    Vue.set(state, 'targetEpisodeList', list)
  },

  unselectEpisodes(state, id: string) {
    const { [id]: any, ...newObj } = state.targetEpisodeList
    Vue.set(state, 'targetEpisodeList', newObj)
  },

  unselectAllEpisodes() {
    Vue.set(state, 'targetEpisodeList', {})
  },

  setSection(state, current) {
    Vue.set(state, 'section', current)
  },

  setAdvertisers(state, data) {
    Vue.set(state, 'advertisers', data)
  },
  setAllAdvertisers(state, data) {
    Vue.set(state, 'allAdvertisers', data)
  },
  setSponsors(state, data) {
    Vue.set(state, 'sponsors', data)
  },

  setCampaignShows(state, shows) {
    let data = []
    if (get(state, 'shows.data', 0).length === 0 || shows.offset === 0) {
      data = [...shows.data]
    } else data = [...state.shows.data, ...shows.data]
    Vue.set(state, 'campaignShows', {
      data,
      offset: shows.offset,
      count: shows.count,
    })
  },
  setCampaigns(state, campaigns) {
    let data = []
    if ((state.campaigns.data && state.campaigns.data.length === 0) || campaigns.offset === 0) {
      data = [...campaigns.data]
    } else data = [...state.campaigns.data, ...campaigns.data]
    Vue.set(state, 'campaigns', {
      data,
      offset: campaigns.offset,
      count: campaigns.count,
    })
  },
  setCampaignsCount(state, count) {
    Vue.set(state, 'campaignsCount', count)
  },
  clearCampaigns(state) {
    Vue.set(state, 'campaigns', {})
    Vue.set(state, 'campaignsCount', 0)
  },
  clearCampaignShows(state) {
    Vue.set(state, 'campaignShows', {})
  },
  setFlights(state, flights) {
    let data = []
    if ((state.flights.data && state, flights.data.length === 0) || flights.offset === 0) {
      data = [...flights.data]
    } else data = [...state.flights.data, ...flights.data]
    Vue.set(state, 'flights', {
      data,
      offset: flights.offset,
      count: flights.count,
    })
  },
  setFlightsCount(state, count) {
    Vue.set(state, 'flightsCount', count)
  },
  clearFlights(state) {
    Vue.set(state, 'flights', {})
    Vue.set(state, 'flightsCount', 0)
  },

  setAnalyticsByTime(state, { data, id, resolution }) {
    let obj = state.analyticsByTime
    obj[id] = {
      DAILY: obj[id] ? obj[id]['DAILY'] : [],
      WEEKLY: obj[id] ? obj[id]['WEEKLY'] : [],
      MONTHLY: obj[id] ? obj[id]['MONTHLY'] : [],
    }
    obj[id][resolution] = data

    Vue.set(state, 'analyticsByTime', obj)
  },

  setAnalyticsByPosition(state, { data, id }) {
    let obj = state.analyticsByPosition
    obj[id] = data

    Vue.set(state, 'analyticsByPosition', obj)
  },

  setAnalyticsByFlight(state, { data, id }) {
    let obj = state.analyticsByFlight
    obj[id] = data

    Vue.set(state, 'analyticsByFlight', obj)
  },
  setAnalyticsByShow(state, { data, id }) {
    let obj = state.analyticsByShow
    obj[id] = data

    Vue.set(state, 'analyticsByShow', obj)
  },
  setAnalyticsSummary(state, { data, id }) {
    let obj = state.analyticsSummary
    obj[id] = data

    Vue.set(state, 'analyticsSummary', obj)
  },
  setAnalyticsReload(state, { data, id }) {
    let obj = state.analyticsReload
    obj[id] = data
    Vue.set(state, 'analyticsReload', obj)
  },
  clearAnalytics(state) {
    Vue.set(state, 'analyticsByTime', {})
    Vue.set(state, 'analyticsByPosition', {})
    Vue.set(state, 'analyticsByFlight', {})
    Vue.set(state, 'analyticsByShow', {})
    Vue.set(state, 'analyticsSummary', {})
  },
}

const actions: ActionTree<any, any> = {
  async getAdvertiserList(context, payload: { params: AdvertiserFetchParams; networkId: string }) {
    const res = await AdoriService.fetchAdvertisers(payload.params, payload.networkId)
    if (payload.params.kind === 'PAID') context.commit('setAdvertisers', res.data)
    else if (payload.params.kind === 'UNPAID') context.commit('setSponsors', res.data)
    else context.commit('setAllAdvertisers', res.data)
  },

  async getOneAdvertiser(context, payload: { networkId: string; advertiserId: string }) {
    const res = await AdoriService.fetchOneAdvertiser(payload.networkId, payload.advertiserId)
    return res
  },

  async createAdvertiser(context, payload: { data: AdvertiserPayload; networkId: string }) {
    await AdoriService.createAdvertiser(payload.data, payload.networkId)
  },

  async getCampaigns(context, payload: { params: CampaignFetchParams; networkId: string; clear: boolean }) {
    if (payload.clear) {
      context.commit('clearCampaigns')
    }
    const res: any = await AdoriService.fetchCampaigns(payload.params, payload.networkId)
    let campaigns: any = {
      data: res.data,
      count: res.count,
      offset: res.offset,
    }

    context.commit('setCampaignsCount', res.count)
    context.commit('setCampaigns', campaigns)
  },

  async getCampaignShows(context, payload: { campaignId: string; networkId: string; clear: boolean }) {
    if (payload.clear) {
      context.commit('clearCampaignShows')
    }
    const res: any = await AdoriService.getCampaignShows(payload.campaignId, payload.networkId)
    let shows: any = {
      data: res.data,
      count: res.count,
      offset: res.offset,
    }

    context.commit('setCampaignShows', shows)
  },

  async getOneCampaign(context, payload: { networkId: string; campaignId: string }) {
    const res = await AdoriService.fetchOneCampaign(payload.networkId, payload.campaignId)
    return res
  },

  async createCampaign(context, payload: { data: CampaignPayload; networkId: string }) {
    await AdoriService.createCampaign(payload.data, payload.networkId)
  },

  async updateCampaign(context, payload: { data: CampaignUpdate; networkId: string; campaignId: string }) {
    await AdoriService.updateCampaign(payload.data, payload.networkId, payload.campaignId)
  },

  async deleteCampaign(context, payload: { networkId: string; campaignId: string }) {
    await AdoriService.deleteCampaign(payload.networkId, payload.campaignId)
  },

  async exportCampaign(context, payload: { networkId: string; campaignId: string; params: any }) {
    try {
      await AdoriService.exportCampaign(payload.networkId, payload.campaignId, payload.params)
      context.dispatch('pushNotification', {
        text: 'The file will be sent to your email address. Please wait!',
        type: 'SUCCESS',
      })
    } catch (e) {
      console.log(e)
    }
  },

  async getFlights(
    context,
    payload: {
      params: FlightFetchParams
      networkId: string
      campaignId: string
      clear: boolean
    }
  ) {
    if (payload.clear) {
      context.commit('clearFlights')
    }
    const res: any = await AdoriService.fetchFlights(payload.params, payload.networkId, payload.campaignId)
    let flights: any = {
      data: res.data,
      count: res.count,
      offset: res.offset,
    }

    context.commit('setFlightsCount', res.count)
    context.commit('setFlights', flights)
  },

  async createFlight(context, payload: { data: FlightPayload; networkId: string; campaignId: string }) {
    const res: any = await AdoriService.createFlight(payload.data, payload.networkId, payload.campaignId)
    return res.id
  },

  async updateFlight(
    context,
    payload: {
      data: FlightPayload
      networkId: string
      campaignId: string
      flightId: string
    }
  ) {
    const res: any = await AdoriService.updateFlight(
      payload.data,
      payload.networkId,
      payload.campaignId,
      payload.flightId
    )
    return res.id
  },
  async deleteFlight(context, payload: { networkId: string; campaignId: string; flightId: string }) {
    await AdoriService.deleteFlight(payload.networkId, payload.campaignId, payload.flightId)
  },

  async updateShows(
    context,
    payload: {
      data: updateShows
      networkId: string
      campaignId: string
      flightId: string
    }
  ) {
    await AdoriService.updateShows(payload.data, payload.networkId, payload.campaignId, payload.flightId)
  },

  async getShows(
    context,
    payload: {
      params: ShowsFetch
      networkId: string
      campaignId: string
      flightId: string
    }
  ) {
    const res = await AdoriService.fetchShows(payload.params, payload.networkId, payload.campaignId, payload.flightId)
    return res
  },

  async getAnalyticsByTime(
    context,
    payload: {
      params: AnalyticsByTimeParams
      networkId: string
      campaignId: string
    }
  ) {
    const res = await AdoriService.analyticsByTime(payload.params, payload.networkId, payload.campaignId)
    context.commit('setAnalyticsByTime', {
      data: res.data,
      id: payload.campaignId,
      resolution: payload.params.resolution,
    })
  },
  async getAnalyticsByPosition(context, payload: { params: AnalyticsParams; networkId: string; campaignId: string }) {
    const res = await AdoriService.analyticsByPosition(payload.params, payload.networkId, payload.campaignId)
    context.commit('setAnalyticsByPosition', {
      data: res.data,
      id: payload.campaignId,
    })
  },
  async getAnalyticsByFlight(context, payload: { params: AnalyticsParams; networkId: string; campaignId: string }) {
    const res = await AdoriService.analyticsByFlight(payload.params, payload.networkId, payload.campaignId)
    context.commit('setAnalyticsByFlight', {
      data: res.data,
      id: payload.campaignId,
    })
  },
  async getAnalyticsByShow(context, payload: { params: AnalyticsParams; networkId: string; campaignId: string }) {
    const res = await AdoriService.analyticsByShow(payload.params, payload.networkId, payload.campaignId)
    context.commit('setAnalyticsByShow', {
      data: res.data,
      id: payload.campaignId,
    })
  },
  async getAnalyticsSummary(context, payload: { params: AnalyticsParams; networkId: string; campaignId: string }) {
    const res = await AdoriService.analyticsSummary(payload.params, payload.networkId, payload.campaignId)
    context.commit('setAnalyticsSummary', {
      data: res,
      id: payload.campaignId,
    })
  },
  async setAnalyticsReload(context, payload) {
    context.commit('setAnalyticsReload', {
      data: payload.value,
      id: payload.campaignId,
    })
  },
}

const campaigns: StoreOptions<any> = {
  state,
  getters,
  mutations,
  actions,
}

export default campaigns
