var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    { ref: "_baseModal" },
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "light-gray f4 fw5" }, [
          _vm._v("Style your subtitles"),
        ]),
      ]),
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "flex" }, [
          _c("div", { staticClass: "controls" }, [
            _c("div", { staticClass: "controls-sidebar" }, [
              _c("div", { staticClass: "controls-grid controls-tabs" }, [
                _c("ul", { staticClass: "controls-menu noselect" }, [
                  _c(
                    "li",
                    {
                      class: { active: _vm.activeTab == "templates" },
                      on: {
                        click: function ($event) {
                          return _vm.setActiveTab("templates")
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/audiogram/side-menu/templates.svg"),
                        },
                      }),
                      _vm._v("\n                Templates\n              "),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      class: { active: _vm.activeTab == "custom" },
                      on: {
                        click: function ($event) {
                          return _vm.setActiveTab("custom")
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: { src: require("@/assets/subtitle/custom.svg") },
                      }),
                      _vm._v("\n                Custom\n              "),
                    ]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "controls-content scrollbar" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeTab === "templates",
                        expression: "activeTab === 'templates'",
                      },
                    ],
                  },
                  [
                    _vm.subtitleTemplates
                      ? _c(
                          "div",
                          { staticClass: "templates-grid" },
                          _vm._l(_vm.subtitleTemplates, function (template) {
                            return _c(
                              "div",
                              { staticClass: "relative flex hide-child" },
                              [
                                _c("img", {
                                  staticClass: "nodrag tinted template-item",
                                  attrs: { src: template.imageUrl },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "absolute noselect top-0 left-0",
                                  },
                                  [
                                    _c(
                                      "i",
                                      {
                                        staticClass:
                                          "material-icons f3 white mt1 ml1 relative text-shadow",
                                        class:
                                          _vm.selectionStyle(template.enum) +
                                          " pointer",
                                        on: {
                                          mousedown: function ($event) {
                                            return _vm.selectSubtitleStyle(
                                              template.enum
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.selectionIcon(template.enum)
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "silver" }, [
                      _vm._v("My templates"),
                    ]),
                    _c("CustomTemplatesGrid", {
                      attrs: { subtitleStyleIds: _vm.subtitleStyleIds },
                      on: {
                        "select-style": function ($event) {
                          return _vm.handleSave(false)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeTab === "custom",
                        expression: "activeTab === 'custom'",
                      },
                    ],
                  },
                  [
                    _c("div", { staticClass: "templates-container mb2" }, [
                      _c(
                        "div",
                        {
                          staticClass: "template-wrapper",
                          on: {
                            click: function ($event) {
                              return _vm.newTextbox(
                                "This is the Basic\n subtitle style",
                                "BASIC"
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "template-card",
                              class:
                                _vm.activeSubtitleStyle === "BASIC"
                                  ? "card-active"
                                  : "card-inactive",
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/subtitle/templates/basic.svg"),
                                },
                              }),
                            ]
                          ),
                          _c("span", { staticClass: "template-label" }, [
                            _vm._v("Basic"),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "template-wrapper",
                          on: {
                            click: function ($event) {
                              return _vm.newTextbox(
                                "This is the Boxed\n subtitle style",
                                "BOXED"
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "template-card",
                              class:
                                _vm.activeSubtitleStyle === "BOXED"
                                  ? "card-active"
                                  : "card-inactive",
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/subtitle/templates/boxed.svg"),
                                },
                              }),
                            ]
                          ),
                          _c("span", { staticClass: "template-label" }, [
                            _vm._v("Boxed"),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "template-wrapper",
                          on: {
                            click: function ($event) {
                              return _vm.newTextbox(
                                "This is the Wrap\n subtitle style",
                                "WRAP"
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "template-card",
                              class:
                                _vm.activeSubtitleStyle === "WRAP"
                                  ? "card-active"
                                  : "card-inactive",
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/subtitle/templates/wrap.svg"),
                                },
                              }),
                            ]
                          ),
                          _c("span", { staticClass: "template-label" }, [
                            _vm._v("Wrap"),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "template-wrapper",
                          on: {
                            click: function ($event) {
                              return _vm.newTextbox(
                                "This is the Shadow\n subtitle style",
                                "SHADOW"
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "template-card",
                              class:
                                _vm.activeSubtitleStyle === "SHADOW"
                                  ? "card-active"
                                  : "card-inactive",
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/subtitle/templates/shadow.svg"),
                                },
                              }),
                            ]
                          ),
                          _c("span", { staticClass: "template-label" }, [
                            _vm._v("Shadow"),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "template-wrapper",
                          on: {
                            click: function ($event) {
                              return _vm.newTextbox(
                                "This is the Outlined\n subtitle style",
                                "OUTLINED"
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "template-card",
                              class:
                                _vm.activeSubtitleStyle === "OUTLINED"
                                  ? "card-active"
                                  : "card-inactive",
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/subtitle/templates/outlined.svg"),
                                },
                              }),
                            ]
                          ),
                          _c("span", { staticClass: "template-label" }, [
                            _vm._v("Outlined"),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "template-wrapper",
                          on: {
                            click: function ($event) {
                              return _vm.newTextbox(
                                "This is the Filled\n subtitle style",
                                "FILLED"
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "template-card",
                              class:
                                _vm.activeSubtitleStyle === "FILLED"
                                  ? "card-active"
                                  : "card-inactive",
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/subtitle/templates/filled.svg"),
                                },
                              }),
                            ]
                          ),
                          _c("span", { staticClass: "template-label" }, [
                            _vm._v("Filled"),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "template-wrapper",
                          on: {
                            click: function ($event) {
                              return _vm.newTextbox(
                                "This is the Neon\n subtitle style",
                                "NEON"
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "template-card",
                              class:
                                _vm.activeSubtitleStyle === "NEON"
                                  ? "card-active"
                                  : "card-inactive",
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/subtitle/templates/neon.svg"),
                                },
                              }),
                            ]
                          ),
                          _c("span", { staticClass: "template-label" }, [
                            _vm._v("Neon"),
                          ]),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "section-divider mb1" }),
                    _c("div", { staticClass: "customize-wrapper" }, [
                      _c("div", { staticClass: "customize-item" }, [
                        _c("div", { staticClass: "half-item" }, [
                          _c("span", { staticClass: "customize-label" }, [
                            _vm._v("Text Color"),
                          ]),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "on-clickaway",
                                  rawName: "v-on-clickaway",
                                  value: _vm.closeTextColorPicker,
                                  expression: "closeTextColorPicker",
                                },
                              ],
                              attrs: { id: "color-choose" },
                              on: { click: _vm.toggleTextColorPicker },
                            },
                            [
                              _c("div", { staticClass: "relative" }, [
                                _c("div", { staticClass: "font-color" }, [
                                  _c("div", {
                                    staticClass: "swatch",
                                    style:
                                      "background-color: " + _vm.textColorValue,
                                  }),
                                ]),
                                _c(
                                  "div",
                                  {
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                      },
                                    },
                                  },
                                  [
                                    _vm.displayTextColorPicker
                                      ? _c("Sketch", {
                                          staticClass: "sketch-picker",
                                          attrs: { value: _vm.textColorValue },
                                          on: {
                                            input:
                                              _vm.updateTextColorFromPicker,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                        ]),
                        _vm.showSecondaryColor
                          ? _c("div", { staticClass: "half-item" }, [
                              _vm.activeSubtitleStyle === "BOXED"
                                ? _c(
                                    "span",
                                    { staticClass: "customize-label" },
                                    [_vm._v("Box Color")]
                                  )
                                : _vm._e(),
                              _vm.activeSubtitleStyle === "WRAP"
                                ? _c(
                                    "span",
                                    { staticClass: "customize-label" },
                                    [_vm._v("Wrap Color")]
                                  )
                                : _vm._e(),
                              _vm.activeSubtitleStyle === "FILLED"
                                ? _c(
                                    "span",
                                    { staticClass: "customize-label" },
                                    [_vm._v("Border Color")]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "on-clickaway",
                                      rawName: "v-on-clickaway",
                                      value: _vm.closeEffectColorPicker,
                                      expression: "closeEffectColorPicker",
                                    },
                                  ],
                                  attrs: { id: "color-choose" },
                                  on: { click: _vm.toggleEffectColorPicker },
                                },
                                [
                                  _c("div", { staticClass: "relative" }, [
                                    _c("div", { staticClass: "font-color" }, [
                                      _c("div", {
                                        staticClass: "swatch",
                                        style:
                                          "background-color: " +
                                          _vm.effectColorValue,
                                      }),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                          },
                                        },
                                      },
                                      [
                                        _vm.displayEffectColorPicker
                                          ? _c("Sketch", {
                                              staticClass: "sketch-picker",
                                              attrs: {
                                                value: _vm.effectColorValue,
                                              },
                                              on: {
                                                input:
                                                  _vm.updateEffectColorFromPicker,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "customize-item" }, [
                        _c("span", { staticClass: "customize-label" }, [
                          _vm._v("Text Position"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "options" },
                          [
                            _c(
                              "BaseNewTooltip",
                              { attrs: { text: "BOTTOM" } },
                              [
                                _c(
                                  "div",
                                  {
                                    class:
                                      _vm.textPosition === "BOTTOM"
                                        ? "position-item-active"
                                        : "position-item",
                                    on: {
                                      click: function ($event) {
                                        return _vm.updateTextPosition("BOTTOM")
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/subtitle/positions/bottom.svg"),
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "BaseNewTooltip",
                              { attrs: { text: "MIDDLE" } },
                              [
                                _c(
                                  "div",
                                  {
                                    class:
                                      _vm.textPosition === "MIDDLE"
                                        ? "position-item-active"
                                        : "position-item",
                                    on: {
                                      click: function ($event) {
                                        return _vm.updateTextPosition("MIDDLE")
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/subtitle/positions/middle.svg"),
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                            _c("BaseNewTooltip", { attrs: { text: "TOP" } }, [
                              _c(
                                "div",
                                {
                                  class:
                                    _vm.textPosition === "TOP"
                                      ? "position-item-active"
                                      : "position-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.updateTextPosition("TOP")
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/subtitle/positions/top.svg"),
                                    },
                                  }),
                                ]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "customize-wrapper" }, [
                      _c("div", { staticClass: "customize-item" }, [
                        _c("span", { staticClass: "customize-label" }, [
                          _vm._v("Text Font"),
                        ]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.font,
                                expression: "font",
                              },
                            ],
                            staticClass:
                              "font-picker bg-adori-gray white f6 br2 h2 ba b--gray",
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.font = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                                function ($event) {
                                  return _vm.changeFont($event.target.value)
                                },
                              ],
                            },
                          },
                          _vm._l(_vm.fontsList, function (item, i) {
                            return _c(
                              "option",
                              { key: i, domProps: { value: item.family } },
                              [
                                _vm._v(
                                  "\n                      " +
                                    _vm._s(item.family) +
                                    "\n                    "
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                      _c("div", { staticClass: "customize-item" }, [
                        _c(
                          "div",
                          { staticClass: "text-format" },
                          [
                            _c("img", {
                              staticClass: "text-size",
                              attrs: {
                                src: require("@/assets/audiogram/text-format/text-size.svg"),
                              },
                            }),
                            _c(
                              "BaseNewTooltip",
                              { attrs: { text: "Text size" } },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.textSize,
                                      expression: "textSize",
                                    },
                                  ],
                                  staticClass: "font-width",
                                  attrs: {
                                    type: "number",
                                    placeholder: "30",
                                    min: "18",
                                    max: "48",
                                  },
                                  domProps: { value: _vm.textSize },
                                  on: {
                                    input: [
                                      function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.textSize = $event.target.value
                                      },
                                      _vm.changeTextSize,
                                    ],
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "text-format",
                            on: {
                              click: function ($event) {
                                return _vm.changeTextFormat("bold")
                              },
                            },
                          },
                          [
                            _c("BaseNewTooltip", { attrs: { text: "Bold" } }, [
                              _vm.formatBold
                                ? _c("img", {
                                    attrs: {
                                      src: require("@/assets/audiogram/text-format/text-bold-active.svg"),
                                    },
                                  })
                                : _c("img", {
                                    attrs: {
                                      src: require("@/assets/audiogram/text-format/text-bold.svg"),
                                    },
                                  }),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "text-format",
                            on: {
                              click: function ($event) {
                                return _vm.changeTextFormat("italic")
                              },
                            },
                          },
                          [
                            _c(
                              "BaseNewTooltip",
                              { attrs: { text: "Italics" } },
                              [
                                _vm.formatItalic
                                  ? _c("img", {
                                      attrs: {
                                        src: require("@/assets/audiogram/text-format/text-italic-active.svg"),
                                      },
                                    })
                                  : _c("img", {
                                      attrs: {
                                        src: require("@/assets/audiogram/text-format/text-italic.svg"),
                                      },
                                    }),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "text-format",
                            on: {
                              click: function ($event) {
                                return _vm.changeTextFormat("underline")
                              },
                            },
                          },
                          [
                            _c(
                              "BaseNewTooltip",
                              { attrs: { text: "Underline" } },
                              [
                                _vm.formatUnderline
                                  ? _c("img", {
                                      attrs: {
                                        src: require("@/assets/audiogram/text-format/text-underline-active.svg"),
                                      },
                                    })
                                  : _c("img", {
                                      attrs: {
                                        src: require("@/assets/audiogram/text-format/text-underline.svg"),
                                      },
                                    }),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "text-format",
                            on: {
                              click: function ($event) {
                                return _vm.changeTextFormat("strikethrough")
                              },
                            },
                          },
                          [
                            _c(
                              "BaseNewTooltip",
                              { attrs: { text: "Cross out" } },
                              [
                                _vm.formatStrikethrough
                                  ? _c("img", {
                                      attrs: {
                                        src: require("@/assets/audiogram/text-format/text-strikethrough-active.svg"),
                                      },
                                    })
                                  : _c("img", {
                                      attrs: {
                                        src: require("@/assets/audiogram/text-format/text-strikethrough.svg"),
                                      },
                                    }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "flex justify-center mt2",
                        attrs: { tabIndex: "1000" },
                      },
                      [
                        _c("canvas", { ref: "can" }),
                        _c("a", {
                          ref: "imageDownloadRef",
                          staticClass: "dn",
                          attrs: { download: "" },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "flex justify-center mt2" },
                      [
                        _c("BaseButtonRed", {
                          staticClass: "youtube-link",
                          attrs: {
                            text: _vm.uploadText,
                            loading: _vm.loader,
                            disabled: _vm.loader,
                            onClick: _vm.uploadTemplate,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeTab === "animations",
                        expression: "activeTab === 'animations'",
                      },
                    ],
                  },
                  [
                    _c("div", { staticClass: "templates-container" }, [
                      _c("div", { staticClass: "template-wrapper" }, [
                        _c(
                          "div",
                          { staticClass: "template-card card-active" },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/subtitle/templates/none.svg"),
                              },
                            }),
                          ]
                        ),
                        _c("span", { staticClass: "template-label" }, [
                          _vm._v("None"),
                        ]),
                      ]),
                      _c("div", { staticClass: "template-wrapper" }, [
                        _c("div", { staticClass: "template-card" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/subtitle/templates/neon.svg"),
                            },
                          }),
                        ]),
                        _c("span", { staticClass: "template-label" }, [
                          _vm._v("Karaoke"),
                        ]),
                      ]),
                      _c("div", { staticClass: "template-wrapper" }, [
                        _c("div", { staticClass: "template-card" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/subtitle/templates/neon.svg"),
                            },
                          }),
                        ]),
                        _c("span", { staticClass: "template-label" }, [
                          _vm._v("Scroll up"),
                        ]),
                      ]),
                      _c("div", { staticClass: "template-wrapper" }, [
                        _c("div", { staticClass: "template-card" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/subtitle/templates/neon.svg"),
                            },
                          }),
                        ]),
                        _c("span", { staticClass: "template-label" }, [
                          _vm._v("Scroll down"),
                        ]),
                      ]),
                      _c("div", { staticClass: "template-wrapper" }, [
                        _c("div", { staticClass: "template-card" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/subtitle/templates/neon.svg"),
                            },
                          }),
                        ]),
                        _c("span", { staticClass: "template-label" }, [
                          _vm._v("Reveal"),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]),
            _vm.activeTab !== "custom"
              ? _c(
                  "div",
                  { staticClass: "save-btn" },
                  [
                    _c("BaseButtonRed", {
                      attrs: {
                        text: _vm.saveText,
                        onClick: _vm.handleSave,
                        loading: _vm.loader,
                        disabled: _vm.loader,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }