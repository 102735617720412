var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "f4 light-gray fw5" }, [
          _vm._v("Episode transcript"),
        ]),
      ]),
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "f5 light-gray" }, [
          _vm._v(_vm._s(_vm.audioName)),
        ]),
        _vm.loading
          ? _c("div", { staticClass: "pa4 f5 silver flex items-center" }, [
              _c("div", { staticClass: "mr2" }, [
                _vm._v("Loading transcript..."),
              ]),
              _c("div", { staticClass: "loader loader-inline dib" }),
            ])
          : _c(
              "div",
              { staticClass: "mw7 mt3 max-vh-75 overflow-y-auto scrollbar" },
              [
                _vm.transcript && _vm.transcript.sentences.length !== 0
                  ? _c(
                      "div",
                      { staticClass: "white" },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.transcript.length) +
                            "\n        "
                        ),
                        _vm._l(
                          _vm.transcript.sentences,
                          function (sentence, i) {
                            return _c(
                              "div",
                              {
                                key: i + "-" + sentence.text,
                                staticClass: "pb3",
                              },
                              [
                                _c("p", { staticClass: "mb1 gray f6" }, [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.toReadableTime(sentence.start_time)
                                      ) +
                                      " -\n            " +
                                      _vm._s(
                                        _vm.toReadableTime(sentence.end_time)
                                      ) +
                                      "\n          "
                                  ),
                                ]),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "mt0 light-gray f5 lh-copy mr4",
                                  },
                                  [_vm._v(_vm._s(sentence.text))]
                                ),
                              ]
                            )
                          }
                        ),
                      ],
                      2
                    )
                  : _c("div", { staticClass: "adori-red mt2" }, [
                      _vm._v("No speech was detected in the audiotrack!"),
                    ]),
              ]
            ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }