// Todo: Edit this component later when more video libraires integrated

import { useGetLibVideos, useGetPublicVideos } from './../hooks/videos'
import { computed, reactive, ref } from '@vue/composition-api'
import { Component, Vue } from 'vue-property-decorator'
import get from 'lodash.get'
import { QueryClient, useQueryClient } from 'vue-query'

@Component({
  setup() {
    const queryClient = useQueryClient()
    const selectedVideoTab = ref('MY_LIBRARY')
    const videoSearchText = ref('')
    const libVideoParams = reactive({
      limit: 10,
      offset: 0,
    })
    const { data: libVideoData, isFetching: isLibVideoLoading } = useGetLibVideos(libVideoParams, {
      enabled: true,
    })

    const videoSearchParams = reactive({
      query: videoSearchText,
      limit: 10,
      offset: 0,
      searchLibrary: selectedVideoTab,
    })

    const isVideoSearchActive = computed(
      () => !['MY_LIBRARY'].includes(selectedVideoTab.value) && !!videoSearchText.value
    )
    const { data: publicVideoData, isFetching: isPublicVideoLoading } = useGetPublicVideos(videoSearchParams, {
      enabled: isVideoSearchActive,
    })

    return {
      queryClient,
      selectedVideoTab,
      libVideoParams,
      libVideoData,
      isLibVideoLoading,
      videoSearchParams,
      publicVideoData,
      isPublicVideoLoading,
      videoSearchText,
    }
  },
})
export default class VideoLibraries extends Vue {
  queryClient!: QueryClient
  selectedVideoTab!: string
  libVideoParams!: any
  libVideoData!: any
  isLibVideoLoading!: boolean
  publicVideoData!: any
  isPublicVideoLoading!: boolean
  videoSearchText!: string
  videoSearchParams!: any

  get selectedVideoRangeStart() {
    return this.selectedVideoTab === 'MY_LIBRARY' ? this.libVideoParams.offset + 1 : this.videoSearchParams.offset + 1
  }

  get selectedVideoRangeEnd() {
    return this.selectedVideoTab === 'MY_LIBRARY'
      ? this.libVideoParams.offset + this.videoList.length
      : this.videoSearchParams.offset + this.videoList.length
  }

  get videoList() {
    return this.selectedVideoTab === 'MY_LIBRARY'
      ? get(this.libVideoData, 'data', []).map((video: any) => ({
          originalUrl: video.originalUrl,
          previewUrl: get(video, 'mp4Urls.thumb.url', ''),
          source: 'adori',
          id: video.id,
          thumbNail: video.thumbnailUrl,
        }))
      : get(this.publicVideoData, 'data', []).map((video: any) => ({
          originalUrl: get(video, 'urls.large.url', ''),
          previewUrl: get(video, 'urls.tiny.url', ''),
          thumbNail: get(video, 'urls.tiny.thumbnail', ''),
          source: 'adori',
          id: video.id,
        }))
  }

  get totalVideoCount() {
    return this.selectedVideoTab === 'MY_LIBRARY'
      ? get(this.libVideoData, 'count', 0)
      : get(this.publicVideoData, 'count', 0)
  }

  handleVideoNavigation(nav: string) {
    if (this.selectedVideoTab === 'MY_LIBRARY') {
      nav === 'next' && this.libVideoParams.offset <= this.totalVideoCount && (this.libVideoParams.offset += 10)
      nav === 'prev' && this.libVideoParams.offset >= 10 && (this.libVideoParams.offset -= 10)
    } else {
      nav === 'next' && this.videoSearchParams.offset <= this.totalVideoCount && (this.videoSearchParams.offset += 10)
      nav === 'prev' && this.videoSearchParams.offset >= 10 && (this.videoSearchParams.offset -= 10)
    }
  }
}
