var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    { ref: "_baseModal" },
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "light-gray f4 fw5" }, [
          _vm._v(_vm._s(_vm.modalTitle)),
        ]),
      ]),
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "flex" }, [
          _c("div", { staticClass: "controls" }, [
            _c("div", { staticClass: "controls-sidebar" }, [
              _c("div", { staticClass: "controls-grid controls-tabs" }, [
                _c("ul", { staticClass: "controls-menu noselect" }, [
                  _c(
                    "li",
                    {
                      class: { active: _vm.activeTab == "templates" },
                      on: {
                        click: function ($event) {
                          return _vm.setActiveTab("templates")
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/audiogram/side-menu/templates.svg"),
                        },
                      }),
                      _vm._v("\n                Templates\n              "),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      class: { active: _vm.activeTab == "background" },
                      on: {
                        click: function ($event) {
                          return _vm.setActiveTab("background")
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/audiogram/side-menu/background.svg"),
                        },
                      }),
                      _vm._v("\n                Background\n              "),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      class: { active: _vm.activeTab == "text" },
                      on: {
                        click: function ($event) {
                          return _vm.setActiveTab("text")
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/audiogram/side-menu/text.svg"),
                        },
                      }),
                      _vm._v("\n                Text\n              "),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      class: { active: _vm.activeTab == "waveform" },
                      on: {
                        click: function ($event) {
                          return _vm.setActiveTab("waveform")
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/audiogram/side-menu/waveform.svg"),
                        },
                      }),
                      _vm._v("\n                Waveforms\n              "),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      class: { active: _vm.activeTab == "image" },
                      on: {
                        click: function ($event) {
                          return _vm.setActiveTab("image")
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/audiogram/side-menu/image.svg"),
                        },
                      }),
                      _vm._v("\n                Images\n              "),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      class: { active: _vm.activeTab == "video" },
                      on: {
                        click: function ($event) {
                          return _vm.setActiveTab("video")
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/audiogram/side-menu/videos.svg"),
                        },
                      }),
                      _vm._v("\n                Videos\n              "),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      class: { active: _vm.activeTab == "elements" },
                      on: {
                        click: function ($event) {
                          return _vm.setActiveTab("elements")
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/audiogram/side-menu/elements.svg"),
                        },
                      }),
                      _vm._v("\n                Misc\n              "),
                    ]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "controls-content scrollbar" }, [
                _c("div", [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.activeTab === "templates",
                          expression: "activeTab === 'templates'",
                        },
                      ],
                    },
                    [
                      _vm.isTemplatesLoading
                        ? _c(
                            "div",
                            { staticClass: "flex justify-center mt5" },
                            [
                              _c(
                                "div",
                                { staticClass: "building-blocks mt5" },
                                [
                                  _c("div"),
                                  _c("div"),
                                  _c("div"),
                                  _c("div"),
                                  _c("div"),
                                  _c("div"),
                                  _c("div"),
                                ]
                              ),
                            ]
                          )
                        : _vm.adminTemplates.length
                        ? _c(
                            "div",
                            { staticClass: "templates-grid" },
                            [
                              _vm._l(
                                _vm.adminTemplates,
                                function (template, i) {
                                  return [
                                    _c("AudiogramTemplate", {
                                      attrs: {
                                        templateId: template.id,
                                        orientation: _vm.orientation,
                                        thumbnail: template.image.urls.regular,
                                      },
                                      on: {
                                        "load-template": function ($event) {
                                          return _vm.onChangeTemplate($event)
                                        },
                                      },
                                    }),
                                  ]
                                }
                              ),
                            ],
                            2
                          )
                        : _vm.hasTemplatesNextPage
                        ? _c(
                            "div",
                            { staticClass: "tc pb3 ph4 mt3" },
                            [
                              _c("BaseButtonLoadMore", {
                                attrs: {
                                  text: "Load more",
                                  onClick: _vm.nextTemplatesPage,
                                  rootBottomMargin: "800",
                                  onVisible: function () {},
                                  loading: _vm.isNextTemplatesFetching,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.activeTab === "text",
                          expression: "activeTab === 'text'",
                        },
                      ],
                    },
                    [
                      _c("div", { staticClass: "element-grid" }, [
                        _c("div", { staticClass: "element-head" }, [
                          _c("div", { staticClass: "element-title" }, [
                            _vm._v("Basic text"),
                          ]),
                        ]),
                        _c("div", { staticClass: "text-basic mb40" }, [
                          _c(
                            "div",
                            {
                              staticClass: "text title",
                              on: {
                                click: function ($event) {
                                  return _vm.newTextbox(
                                    "Add a header",
                                    "header"
                                  )
                                },
                              },
                            },
                            [_c("span", [_vm._v("Add a header")])]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "text subtitle",
                              on: {
                                click: function ($event) {
                                  return _vm.newTextbox(
                                    "Add a subheader",
                                    "subheader"
                                  )
                                },
                              },
                            },
                            [_c("span", [_vm._v("Add a subheader")])]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "text paragraph",
                              on: {
                                click: function ($event) {
                                  return _vm.newTextbox(
                                    "Add a paragraph",
                                    "paragraph"
                                  )
                                },
                              },
                            },
                            [_c("span", [_vm._v("Add a paragraph")])]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "text paragraph",
                              on: {
                                click: function ($event) {
                                  return _vm.newTextbox(
                                    "Add a caption",
                                    "caption"
                                  )
                                },
                              },
                            },
                            [_c("span", [_vm._v("Add a caption")])]
                          ),
                          _c("div", { staticClass: "autopublish-title mt3" }, [
                            _c(
                              "div",
                              { staticClass: "flex mb2" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "element-title mr3" },
                                  [_vm._v("Add episode title automatically")]
                                ),
                                _c("TheToggleButton", {
                                  attrs: {
                                    value: _vm.autoPublishTitle,
                                    "checked-bg": "#de1a23",
                                    height: "16",
                                    width: "30",
                                  },
                                  on: { click: _vm.handleAutoPublishTitle },
                                }),
                              ],
                              1
                            ),
                            _vm.autoPublishTitle
                              ? _c("div", [
                                  _c("div", { staticClass: "flex" }, [
                                    _c(
                                      "label",
                                      { staticClass: "white pa2 w210" },
                                      [
                                        _vm._v(
                                          "How many characters to split title at?"
                                        ),
                                      ]
                                    ),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.autoTitleLineLength,
                                          expression: "autoTitleLineLength",
                                        },
                                      ],
                                      staticClass:
                                        "input bg-adori-very-light-gray",
                                      attrs: {
                                        type: "number",
                                        placeholder: "20",
                                        max: "40",
                                        min: "10",
                                        autocomplete: "off",
                                      },
                                      domProps: {
                                        value: _vm.autoTitleLineLength,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.autoTitleLineLength =
                                            $event.target.value
                                        },
                                      },
                                    }),
                                  ]),
                                  _c("div", { staticClass: "flex mb2" }, [
                                    _c(
                                      "label",
                                      { staticClass: "white pa2 w210" },
                                      [_vm._v("Line height")]
                                    ),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.autoTitleLineHeight,
                                          expression: "autoTitleLineHeight",
                                        },
                                      ],
                                      staticClass:
                                        "input bg-adori-very-light-gray",
                                      attrs: {
                                        type: "number",
                                        placeholder: "20",
                                        max: "40",
                                        min: "10",
                                        autocomplete: "off",
                                      },
                                      domProps: {
                                        value: _vm.autoTitleLineHeight,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.autoTitleLineHeight =
                                            $event.target.value
                                        },
                                      },
                                    }),
                                  ]),
                                  _c("div", { staticClass: "flex" }, [
                                    _c(
                                      "label",
                                      { staticClass: "white pa2 w210" },
                                      [_vm._v("Font")]
                                    ),
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.font,
                                            expression: "font",
                                          },
                                        ],
                                        staticClass:
                                          "bg-adori-gray white f6 w-30 br2 h2 ba b--gray",
                                        on: {
                                          change: [
                                            function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.font = $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            },
                                            function ($event) {
                                              return _vm.changeFont(
                                                $event.target.value
                                              )
                                            },
                                          ],
                                        },
                                      },
                                      _vm._l(
                                        _vm.autoPublishFonts,
                                        function (item, i) {
                                          return _c(
                                            "option",
                                            {
                                              key: i,
                                              domProps: { value: item },
                                            },
                                            [
                                              _vm._v(
                                                "\n                              " +
                                                  _vm._s(item) +
                                                  "\n                            "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.activeTab === "background",
                          expression: "activeTab === 'background'",
                        },
                      ],
                    },
                    [
                      _c("div", { staticClass: "element-grid" }, [
                        _c("div", { staticClass: "element-head" }, [
                          _c("div", { staticClass: "element-title" }, [
                            _vm._v("Background"),
                          ]),
                        ]),
                        _c("tbody", [
                          _c(
                            "tr",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.switchBackgroundFill(
                                    $event.target.attributes
                                  )
                                },
                              },
                            },
                            [
                              _c("th", [
                                _c("div", {
                                  staticClass: "color",
                                  class: {
                                    "color-active": _vm.activeColor == "color1",
                                  },
                                  attrs: { id: "color1" },
                                }),
                              ]),
                              _c("th", [
                                _c("div", {
                                  staticClass: "color",
                                  class: {
                                    "color-active": _vm.activeColor == "color2",
                                  },
                                  attrs: { id: "color2" },
                                }),
                              ]),
                              _c("th", [
                                _c("div", {
                                  staticClass: "color",
                                  class: {
                                    "color-active": _vm.activeColor == "color3",
                                  },
                                  attrs: { id: "color3" },
                                }),
                              ]),
                              _c("th", [
                                _c("div", {
                                  staticClass: "color",
                                  class: {
                                    "color-active": _vm.activeColor == "color4",
                                  },
                                  attrs: { id: "color4" },
                                }),
                              ]),
                              _c("th", [
                                _c("div", {
                                  staticClass: "color",
                                  class: {
                                    "color-active": _vm.activeColor == "color5",
                                  },
                                  attrs: { id: "color5" },
                                }),
                              ]),
                              _c("th", [
                                _c("div", {
                                  staticClass: "color",
                                  class: {
                                    "color-active": _vm.activeColor == "color6",
                                  },
                                  attrs: { id: "color6" },
                                }),
                              ]),
                              _c("th", [
                                _c("div", {
                                  staticClass: "color",
                                  class: {
                                    "color-active": _vm.activeColor == "color7",
                                  },
                                  attrs: { id: "color7" },
                                }),
                              ]),
                            ]
                          ),
                          _c(
                            "tr",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.switchBackgroundFill(
                                    $event.target.attributes
                                  )
                                },
                              },
                            },
                            [
                              _c("th", [
                                _c("div", {
                                  staticClass: "color",
                                  class: {
                                    "color-active": _vm.activeColor == "color8",
                                  },
                                  attrs: { id: "color8" },
                                }),
                              ]),
                              _c("th", [
                                _c("div", {
                                  staticClass: "color",
                                  class: {
                                    "color-active": _vm.activeColor == "color9",
                                  },
                                  attrs: { id: "color9" },
                                }),
                              ]),
                              _c("th", [
                                _c("div", {
                                  staticClass: "color",
                                  class: {
                                    "color-active":
                                      _vm.activeColor == "color10",
                                  },
                                  attrs: { id: "color10" },
                                }),
                              ]),
                              _c("th", [
                                _c("div", {
                                  staticClass: "color",
                                  class: {
                                    "color-active":
                                      _vm.activeColor == "color11",
                                  },
                                  attrs: { id: "color11" },
                                }),
                              ]),
                              _c("th", [
                                _c("div", {
                                  staticClass: "color",
                                  class: {
                                    "color-active":
                                      _vm.activeColor == "color12",
                                  },
                                  attrs: { id: "color12" },
                                }),
                              ]),
                              _c("th", [
                                _c("div", {
                                  staticClass: "color",
                                  class: {
                                    "color-active":
                                      _vm.activeColor == "color13",
                                  },
                                  attrs: { id: "color13" },
                                }),
                              ]),
                              _c("th", [
                                _c("div", {
                                  staticClass: "color",
                                  class: {
                                    "color-active":
                                      _vm.activeColor == "color14",
                                  },
                                  attrs: { id: "color14" },
                                }),
                              ]),
                            ]
                          ),
                          _c(
                            "tr",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.switchBackgroundFill(
                                    $event.target.attributes
                                  )
                                },
                              },
                            },
                            [
                              _c("th", [
                                _c("div", {
                                  staticClass: "color",
                                  class: {
                                    "color-active":
                                      _vm.activeColor == "color15",
                                  },
                                  attrs: { id: "color15" },
                                }),
                              ]),
                              _c("th", [
                                _c("div", {
                                  staticClass: "color",
                                  class: {
                                    "color-active":
                                      _vm.activeColor == "color16",
                                  },
                                  attrs: { id: "color16" },
                                }),
                              ]),
                              _c("th", [
                                _c("div", {
                                  staticClass: "color",
                                  class: {
                                    "color-active":
                                      _vm.activeColor == "color17",
                                  },
                                  attrs: { id: "color17" },
                                }),
                              ]),
                              _c("th", [
                                _c("div", {
                                  staticClass: "color",
                                  class: {
                                    "color-active":
                                      _vm.activeColor == "color18",
                                  },
                                  attrs: { id: "color18" },
                                }),
                              ]),
                              _c("th", [
                                _c("div", {
                                  staticClass: "color",
                                  class: {
                                    "color-active":
                                      _vm.activeColor == "color19",
                                  },
                                  attrs: { id: "color19" },
                                }),
                              ]),
                              _c("th", [
                                _c("div", {
                                  staticClass: "color",
                                  class: {
                                    "color-active":
                                      _vm.activeColor == "color20",
                                  },
                                  attrs: { id: "color20" },
                                }),
                              ]),
                              _c("th", [
                                _c("div", {
                                  staticClass: "color",
                                  class: {
                                    "color-active":
                                      _vm.activeColor == "color21",
                                  },
                                  attrs: { id: "color21" },
                                }),
                              ]),
                            ]
                          ),
                          _c(
                            "tr",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.switchBackgroundFill(
                                    $event.target.attributes
                                  )
                                },
                              },
                            },
                            [
                              _c("th", [
                                _c("div", {
                                  staticClass: "color",
                                  class: {
                                    "color-active":
                                      _vm.activeColor == "color22",
                                  },
                                  attrs: { id: "color22" },
                                }),
                              ]),
                              _c("th", [
                                _c("div", {
                                  staticClass: "color",
                                  class: {
                                    "color-active":
                                      _vm.activeColor == "color23",
                                  },
                                  attrs: { id: "color23" },
                                }),
                              ]),
                              _c("th", [
                                _c("div", {
                                  staticClass: "color",
                                  class: {
                                    "color-active":
                                      _vm.activeColor == "color24",
                                  },
                                  attrs: { id: "color24" },
                                }),
                              ]),
                              _c("th", [
                                _c("div", {
                                  staticClass: "color",
                                  class: {
                                    "color-active":
                                      _vm.activeColor == "color25",
                                  },
                                  attrs: { id: "color25" },
                                }),
                              ]),
                              _c("th", [
                                _c("div", {
                                  staticClass: "color",
                                  class: {
                                    "color-active":
                                      _vm.activeColor == "color26",
                                  },
                                  attrs: { id: "color26" },
                                }),
                              ]),
                              _c("th", [
                                _c("div", {
                                  staticClass: "color",
                                  class: {
                                    "color-active":
                                      _vm.activeColor == "color27",
                                  },
                                  attrs: { id: "color27" },
                                }),
                              ]),
                              _c("th", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "color",
                                    attrs: { id: "color28" },
                                  },
                                  [
                                    _c("div", [
                                      _c("img", {
                                        staticClass: "absolute",
                                        attrs: {
                                          src: require("@/assets/audiogram/plus.svg"),
                                        },
                                      }),
                                      _c("input", {
                                        staticClass: "bg-color-input",
                                        attrs: {
                                          type: "color",
                                          value: "#FFFFFF",
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.onBgColorChange(
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.activeTab === "waveform",
                          expression: "activeTab === 'waveform'",
                        },
                      ],
                    },
                    [
                      _c("div", { staticClass: "relative" }, [
                        _c("div", { staticClass: "grid-1" }, [
                          _c("div", { staticClass: "element-grid" }, [
                            _c("div", { staticClass: "element-head" }, [
                              _c("div", { staticClass: "element-title" }, [
                                _vm._v("Waveforms"),
                              ]),
                            ]),
                            _c(
                              "div",
                              [
                                _vm._l(
                                  _vm.allAudiogramWaveforms,
                                  function (waveform) {
                                    return [
                                      _c("AudiogramWaveform", {
                                        attrs: { waveform: waveform },
                                        on: {
                                          "load-waveform": function ($event) {
                                            return _vm.loadWaveforms($event)
                                          },
                                        },
                                      }),
                                    ]
                                  }
                                ),
                              ],
                              2
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.activeTab === "image",
                          expression: "activeTab === 'image'",
                        },
                      ],
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex items-center silver w-100 justify-center mb2",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "w-50 ph3 pv2 pointer img-tab",
                              class: {
                                "bb b--red white":
                                  _vm.imageTab === "image_upload",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.setImageTab("image_upload")
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "ma4" }, [
                                _vm._v("Upload"),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "w-50 ph3 pv2 pointer img-tab",
                              class: {
                                "bb b--red white":
                                  _vm.imageTab === "image_search",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.setImageTab("image_search")
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "ma4" }, [
                                _vm._v(" Search"),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.imageTab === "image_search",
                              expression: "imageTab === 'image_search'",
                            },
                          ],
                          staticClass: "relative",
                        },
                        [
                          _c("AudiogramSearchImages", {
                            on: {
                              "load-image": function ($event) {
                                return _vm.loadAsset($event, "uploads")
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.imageTab === "image_upload",
                              expression: "imageTab === 'image_upload'",
                            },
                          ],
                          on: {
                            dragover: function ($event) {
                              $event.preventDefault()
                            },
                            drop: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "relative" }, [
                            _c(
                              "div",
                              {
                                staticClass: "form-group",
                                class: { "dark-fill": _vm.bgDragColor },
                                on: {
                                  dragover: function () {
                                    return (_vm.bgDragColor = true)
                                  },
                                  dragleave: function () {
                                    return (_vm.bgDragColor = false)
                                  },
                                  click: _vm.imageUploader,
                                  drop: function ($event) {
                                    return _vm.dropImageFile($event)
                                  },
                                },
                              },
                              [
                                _c("label", { staticClass: "upload" }, [
                                  _vm.uploadingImage
                                    ? _c("div", [
                                        _c("img", {
                                          staticClass: "h70",
                                          attrs: {
                                            src: require("@/assets/spinner.svg"),
                                          },
                                        }),
                                        _c("div", [
                                          _c("span", [
                                            _vm._v("Uploading Image"),
                                          ]),
                                        ]),
                                      ])
                                    : _c("div", [
                                        _c("img", {
                                          staticClass: "gallery",
                                          attrs: {
                                            width: "24px",
                                            height: "24px",
                                            alt: "placeholder",
                                            src: require("@/assets/audiogram/gallery.png"),
                                          },
                                        }),
                                        _c("div", [
                                          _c("strong", [
                                            _vm._v("Choose Image"),
                                          ]),
                                          _vm._v(" or drag it here"),
                                        ]),
                                        _c("span", [
                                          _vm._v("Supports: JPG, PNG"),
                                        ]),
                                      ]),
                                ]),
                              ]
                            ),
                            _vm.isLibImageLoading
                              ? _c(
                                  "div",
                                  { staticClass: "flex justify-center mt5" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "building-blocks mt5" },
                                      [
                                        _c("div"),
                                        _c("div"),
                                        _c("div"),
                                        _c("div"),
                                        _c("div"),
                                        _c("div"),
                                        _c("div"),
                                      ]
                                    ),
                                  ]
                                )
                              : _c(
                                  "div",
                                  [
                                    _vm.imageList.length === 0
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "w-100 flex flex-column justify-center items-center",
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: require("@/assets/imgae-search.svg"),
                                                alt: "",
                                                width: "250",
                                                height: "250",
                                              },
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "f5 silver" },
                                              [
                                                _vm._v(
                                                  "Oops! No images found.. Upload images"
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _c("AudiogramImagesList", {
                                          attrs: {
                                            selectedRangeStart:
                                              _vm.selectedRangeStart,
                                            selectedRangeEnd:
                                              _vm.selectedRangeEnd,
                                            totalImages: _vm.totalImageCount,
                                            imageSources: _vm.imageList,
                                            selectedTab: "UPLOADS",
                                          },
                                          on: {
                                            handleNavigation:
                                              _vm.handleNavigation,
                                            "load-image": function ($event) {
                                              return _vm.loadAsset(
                                                $event,
                                                "uploads"
                                              )
                                            },
                                          },
                                        }),
                                  ],
                                  1
                                ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.activeTab === "video",
                          expression: "activeTab === 'video'",
                        },
                      ],
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex items-center silver w-100 justify-center mb2",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "w-50 ph3 pv2 pointer img-tab",
                              class: {
                                "bb b--red white":
                                  _vm.videoTab === "video_upload",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.setVideoTab("video_upload")
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "ma4" }, [
                                _vm._v("Upload"),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "w-50 ph3 pv2 pointer img-tab",
                              class: {
                                "bb b--red white":
                                  _vm.videoTab === "video_search",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.setVideoTab("video_search")
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "ma4" }, [
                                _vm._v(" Search"),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.videoTab === "video_search",
                              expression: "videoTab === 'video_search'",
                            },
                          ],
                          staticClass: "relative",
                        },
                        [
                          _c("AudiogramSearchVideos", {
                            on: {
                              "load-image": function ($event) {
                                return _vm.loadAsset($event, "uploads")
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.videoTab === "video_upload",
                              expression: "videoTab === 'video_upload'",
                            },
                          ],
                          on: {
                            dragover: function ($event) {
                              $event.preventDefault()
                            },
                            drop: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "relative" }, [
                            _c(
                              "div",
                              {
                                staticClass: "form-group",
                                class: { "dark-fill": _vm.bgDragColor },
                                on: {
                                  dragover: function () {
                                    return (_vm.bgDragColor = true)
                                  },
                                  dragleave: function () {
                                    return (_vm.bgDragColor = false)
                                  },
                                  click: _vm.videoUploader,
                                  drop: function ($event) {
                                    return _vm.dropVideoFile($event)
                                  },
                                },
                              },
                              [
                                _c("label", { staticClass: "upload" }, [
                                  _vm.uploadingImage
                                    ? _c("div", [
                                        _c("img", {
                                          staticClass: "h70",
                                          attrs: {
                                            src: require("@/assets/spinner.svg"),
                                          },
                                        }),
                                        _c("div", [
                                          _c("span", [
                                            _vm._v("Uploading Video"),
                                          ]),
                                        ]),
                                      ])
                                    : _c("div", [
                                        _c("img", {
                                          staticClass: "gallery",
                                          attrs: {
                                            width: "24px",
                                            height: "24px",
                                            alt: "placeholder",
                                            src: require("@/assets/audiogram/video_gallery.png"),
                                          },
                                        }),
                                        _c("div", [
                                          _c("strong", [
                                            _vm._v("Choose Video"),
                                          ]),
                                          _vm._v(" or drag it here"),
                                        ]),
                                        _c("span", [
                                          _vm._v("Supports: mp4, mov, m4v"),
                                        ]),
                                      ]),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "silver" },
                              [
                                _c("BaseCheckbox", {
                                  staticClass: "silver",
                                  attrs: {
                                    text: "Auto Scale Video on Canvas",
                                    initialState: _vm.autoScaleVideo,
                                  },
                                  on: { toggle: _vm.toggleAutoScaleVideo },
                                }),
                              ],
                              1
                            ),
                            _vm.isLibVideoLoading
                              ? _c(
                                  "div",
                                  { staticClass: "flex justify-center mt5" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "building-blocks mt5" },
                                      [
                                        _c("div"),
                                        _c("div"),
                                        _c("div"),
                                        _c("div"),
                                        _c("div"),
                                        _c("div"),
                                        _c("div"),
                                      ]
                                    ),
                                  ]
                                )
                              : _c(
                                  "div",
                                  [
                                    _vm.videoList.length === 0
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "w-100 flex flex-column justify-center items-center",
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: require("@/assets/imgae-search.svg"),
                                                alt: "",
                                                width: "250",
                                                height: "250",
                                              },
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "f5 silver" },
                                              [
                                                _vm._v(
                                                  "Oops! No Videos found.. Upload Videos"
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _c("AudiogramVideosList", {
                                          attrs: {
                                            selectedRangeStart:
                                              _vm.selectedVideoRangeStart,
                                            selectedRangeEnd:
                                              _vm.selectedVideoRangeEnd,
                                            totalVideos: _vm.totalVideoCount,
                                            videoSources: _vm.videoList,
                                            selectedTab: "UPLOADS",
                                          },
                                          on: {
                                            handleNavigation:
                                              _vm.handleVideoNavigation,
                                            "load-video": _vm.loadVideo,
                                          },
                                        }),
                                  ],
                                  1
                                ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.activeTab === "elements",
                          expression: "activeTab === 'elements'",
                        },
                      ],
                    },
                    _vm._l(_vm.allElementAssets, function (category) {
                      return _c(
                        "div",
                        [
                          _c("AudiogramElement", {
                            attrs: { category: category },
                            on: {
                              "load-element": function ($event) {
                                return _vm.loadAsset($event, "elements")
                              },
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "canvas-area tc ml3" }, [
            _c("div", { staticClass: "top-controls mb3" }, [
              _c("div", { staticClass: "controls-items-left flex" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showTextToolbar,
                        expression: "showTextToolbar",
                      },
                    ],
                    staticClass: "flex justify-center",
                  },
                  [
                    _c("div", [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.isAutoTitle,
                              expression: "!isAutoTitle",
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.font,
                              expression: "font",
                            },
                          ],
                          staticClass:
                            "font-picker bg-adori-gray white f6 w-100 br2 h2 ba b--gray",
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.font = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                              function ($event) {
                                return _vm.changeFont($event.target.value)
                              },
                            ],
                          },
                        },
                        _vm._l(_vm.fontsList, function (item, i) {
                          return _c(
                            "option",
                            { key: i, domProps: { value: item.family } },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(item.family) +
                                  "\n                  "
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "on-clickaway",
                              rawName: "v-on-clickaway",
                              value: _vm.closeTextColorPicker,
                              expression: "closeTextColorPicker",
                            },
                          ],
                          attrs: { id: "color-choose" },
                          on: { click: _vm.toggleTextColorPicker },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "relative" },
                            [
                              _c(
                                "BaseNewTooltip",
                                { attrs: { text: "Text color" } },
                                [
                                  _c("div", { staticClass: "font-color" }, [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/audiogram/text-color.svg"),
                                      },
                                    }),
                                    _c("div", {
                                      staticClass: "mini-swatch",
                                      style:
                                        "background-color: " +
                                        _vm.textColorValue,
                                    }),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                },
                                [
                                  _vm.displayTextColorPicker
                                    ? _c("Sketch", {
                                        staticClass: "sketch-picker",
                                        attrs: { value: _vm.textColorValue },
                                        on: {
                                          input: _vm.updateTextColorFromPicker,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c("div", { attrs: { id: "text-format" } }, [
                        _c(
                          "div",
                          { staticClass: "text-format" },
                          [
                            _c("img", {
                              staticClass: "text-size",
                              attrs: {
                                src: require("@/assets/audiogram/text-format/text-size.svg"),
                              },
                            }),
                            _c(
                              "BaseNewTooltip",
                              { attrs: { text: "Text size" } },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.textSize,
                                      expression: "textSize",
                                    },
                                  ],
                                  staticClass: "font-width",
                                  attrs: {
                                    type: "number",
                                    placeholder: "30",
                                    min: "10",
                                    max: "800",
                                  },
                                  domProps: { value: _vm.textSize },
                                  on: {
                                    input: [
                                      function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.textSize = $event.target.value
                                      },
                                      _vm.changeTextSize,
                                    ],
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "text-format",
                            on: {
                              click: function ($event) {
                                return _vm.changeTextFormat("bold")
                              },
                            },
                          },
                          [
                            _c("BaseNewTooltip", { attrs: { text: "Bold" } }, [
                              _vm.formatBold
                                ? _c("img", {
                                    attrs: {
                                      src: require("@/assets/audiogram/text-format/text-bold-active.svg"),
                                    },
                                  })
                                : _c("img", {
                                    attrs: {
                                      src: require("@/assets/audiogram/text-format/text-bold.svg"),
                                    },
                                  }),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "text-format",
                            on: {
                              click: function ($event) {
                                return _vm.changeTextFormat("italic")
                              },
                            },
                          },
                          [
                            _c(
                              "BaseNewTooltip",
                              { attrs: { text: "Italics" } },
                              [
                                _vm.formatItalic
                                  ? _c("img", {
                                      attrs: {
                                        src: require("@/assets/audiogram/text-format/text-italic-active.svg"),
                                      },
                                    })
                                  : _c("img", {
                                      attrs: {
                                        src: require("@/assets/audiogram/text-format/text-italic.svg"),
                                      },
                                    }),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "text-format",
                            on: {
                              click: function ($event) {
                                return _vm.changeTextFormat("underline")
                              },
                            },
                          },
                          [
                            _c(
                              "BaseNewTooltip",
                              { attrs: { text: "Underline" } },
                              [
                                _vm.formatUnderline
                                  ? _c("img", {
                                      attrs: {
                                        src: require("@/assets/audiogram/text-format/text-underline-active.svg"),
                                      },
                                    })
                                  : _c("img", {
                                      attrs: {
                                        src: require("@/assets/audiogram/text-format/text-underline.svg"),
                                      },
                                    }),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "text-format",
                            on: {
                              click: function ($event) {
                                return _vm.changeTextFormat("strikethrough")
                              },
                            },
                          },
                          [
                            _c(
                              "BaseNewTooltip",
                              { attrs: { text: "Cross out" } },
                              [
                                _vm.formatStrikethrough
                                  ? _c("img", {
                                      attrs: {
                                        src: require("@/assets/audiogram/text-format/text-strikethrough-active.svg"),
                                      },
                                    })
                                  : _c("img", {
                                      attrs: {
                                        src: require("@/assets/audiogram/text-format/text-strikethrough.svg"),
                                      },
                                    }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { attrs: { id: "text-align" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "text-align",
                            class: { "align-text-active": _vm.alignLeftActive },
                            on: {
                              click: function ($event) {
                                return _vm.alignText("left")
                              },
                            },
                          },
                          [
                            _c(
                              "BaseNewTooltip",
                              { attrs: { text: "Align text left" } },
                              [
                                _vm.alignLeftActive
                                  ? _c("img", {
                                      attrs: {
                                        src: require("@/assets/audiogram/text-alignment/align-left-active.svg"),
                                      },
                                    })
                                  : _c("img", {
                                      attrs: {
                                        src: require("@/assets/audiogram/text-alignment/align-left.svg"),
                                      },
                                    }),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "text-align align-text-active",
                            class: {
                              "align-text-active": _vm.alignCenterActive,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.alignText("center")
                              },
                            },
                          },
                          [
                            _c(
                              "BaseNewTooltip",
                              { attrs: { text: "Align text center" } },
                              [
                                _vm.alignCenterActive
                                  ? _c("img", {
                                      attrs: {
                                        src: require("@/assets/audiogram/text-alignment/align-center-active.svg"),
                                      },
                                    })
                                  : _c("img", {
                                      attrs: {
                                        src: require("@/assets/audiogram/text-alignment/align-center.svg"),
                                      },
                                    }),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "text-align",
                            class: {
                              "align-text-active": _vm.alignRightActive,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.alignText("right")
                              },
                            },
                          },
                          [
                            _c(
                              "BaseNewTooltip",
                              { attrs: { text: "Align text right" } },
                              [
                                _vm.alignRightActive
                                  ? _c("img", {
                                      attrs: {
                                        src: require("@/assets/audiogram/text-alignment/align-right-active.svg"),
                                      },
                                    })
                                  : _c("img", {
                                      attrs: {
                                        src: require("@/assets/audiogram/text-alignment/align-right.svg"),
                                      },
                                    }),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "text-align",
                            class: {
                              "align-text-active": _vm.alignJustifyActive,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.alignText("justify")
                              },
                            },
                          },
                          [
                            _c(
                              "BaseNewTooltip",
                              { attrs: { text: "Align text normal" } },
                              [
                                _vm.alignJustifyActive
                                  ? _c("img", {
                                      attrs: {
                                        src: require("@/assets/audiogram/text-alignment/justify-active.svg"),
                                      },
                                    })
                                  : _c("img", {
                                      attrs: {
                                        src: require("@/assets/audiogram/text-alignment/justify.svg"),
                                      },
                                    }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.showTextToolbar && !_vm.isOpenInEditMode,
                        expression: "!showTextToolbar && !isOpenInEditMode",
                      },
                    ],
                    staticClass: "flex",
                  },
                  [
                    _c("div", { staticClass: "mr2" }, [
                      _c(
                        "i",
                        { staticClass: "f6 material-icons white v-mid fw6" },
                        [_vm._v("crop")]
                      ),
                      _c("span", [_vm._v(" Resize: ")]),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.orientation,
                              expression: "orientation",
                            },
                          ],
                          staticClass: "resize bg-adori-gray white f6 ba br2",
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.orientation = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                              function ($event) {
                                return _vm.resizeCanvas($event.target.value)
                              },
                            ],
                          },
                        },
                        _vm._l(_vm.OrientationList, function (item, i) {
                          return _c(
                            "option",
                            { key: i, domProps: { value: item.id } },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(item.value) +
                                  "\n                  "
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]
                ),
                !_vm.showTextToolbar && _vm.showWaveColor
                  ? _c("div", { staticClass: "flex" }, [
                      !_vm.isOpenInEditMode
                        ? _c("div", { staticClass: "partition" })
                        : _vm._e(),
                      _c("div", { attrs: { id: "color-choose" } }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "on-clickaway",
                                rawName: "v-on-clickaway",
                                value: _vm.closeWaveformColorPicker,
                                expression: "closeWaveformColorPicker",
                              },
                            ],
                            attrs: { id: "color-choose" },
                            on: { click: _vm.toggleWaveformColorPicker },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "relative" },
                              [
                                _c(
                                  "BaseNewTooltip",
                                  { attrs: { text: "Waveform color" } },
                                  [
                                    _c("div", {
                                      staticClass: "square-swatch",
                                      style:
                                        "background-color: " +
                                        _vm.waveformColorValue,
                                    }),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                      },
                                    },
                                  },
                                  [
                                    _vm.displayWaveformColorPicker
                                      ? _c("Sketch", {
                                          staticClass: "sketch-picker",
                                          attrs: {
                                            value: _vm.waveformSketchColors,
                                          },
                                          on: {
                                            input:
                                              _vm.updateWaveformColorFromPicker,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                !_vm.showTextToolbar && _vm.showElementColor
                  ? _c("div", { staticClass: "flex" }, [
                      !_vm.isOpenInEditMode
                        ? _c("div", { staticClass: "partition" })
                        : _vm._e(),
                      _c("div", { attrs: { id: "color-choose" } }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "on-clickaway",
                                rawName: "v-on-clickaway",
                                value: _vm.closeElementColorPicker,
                                expression: "closeElementColorPicker",
                              },
                            ],
                            attrs: { id: "color-choose" },
                            on: { click: _vm.toggleElementColorPicker },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "relative" },
                              [
                                _c(
                                  "BaseNewTooltip",
                                  { attrs: { text: "Element color" } },
                                  [
                                    _c("div", {
                                      staticClass: "square-swatch",
                                      style:
                                        "background-color: " +
                                        _vm.elementColorValue,
                                    }),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                      },
                                    },
                                  },
                                  [
                                    _vm.displayElementColorPicker
                                      ? _c("Sketch", {
                                          staticClass: "sketch-picker",
                                          attrs: {
                                            value: _vm.elementSketchColors,
                                          },
                                          on: {
                                            input:
                                              _vm.updateElementColorFromPicker,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "div",
                { staticClass: "controls-items-right flex ml2" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "obj-pos",
                      on: { click: _vm.moveObjectToFront },
                    },
                    [
                      _c(
                        "BaseNewTooltip",
                        { attrs: { text: "Bring forward" } },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/audiogram/move-forward.svg"),
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "obj-pos mr1",
                      on: { click: _vm.moveObjectToBack },
                    },
                    [
                      _c(
                        "BaseNewTooltip",
                        { attrs: { text: "Bring backward" } },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/audiogram/move-backward.svg"),
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("BaseNewTooltip", { attrs: { text: "Download image" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "item mr3",
                        on: { click: _vm.downloadImage },
                      },
                      [
                        _c(
                          "i",
                          { staticClass: "f4 material-icons white v-mid fw6" },
                          [_vm._v("download")]
                        ),
                      ]
                    ),
                  ]),
                  _c("BaseNewTooltip", { attrs: { text: "Delete selected" } }, [
                    _c(
                      "div",
                      { staticClass: "item", on: { click: _vm.deleteObject } },
                      [
                        _c(
                          "i",
                          { staticClass: "f4 material-icons white v-mid fw6" },
                          [_vm._v("delete_outline")]
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ]),
            _c(
              "div",
              {
                staticClass: "flex justify-center mb2",
                attrs: { tabIndex: "1000" },
                on: {
                  keyup: function ($event) {
                    return _vm.checkDelete($event)
                  },
                },
              },
              [
                _c("canvas", { ref: "can" }),
                _c("a", {
                  ref: "imageDownloadRef",
                  staticClass: "dn",
                  attrs: { download: "" },
                }),
              ]
            ),
            _c(
              "div",
              { staticClass: "flex justify-between mr2 mt3" },
              [
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c("BaseButtonRed", {
                      staticClass: "clear-btn ml2 mr2",
                      attrs: {
                        text: "Clear",
                        icon: "clear",
                        onClick: _vm.clearCanvas,
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "mr2 undo",
                        class: _vm.isUndoDisabled
                          ? "o-40 not-allowed"
                          : "pointer",
                        on: {
                          click: function ($event) {
                            return _vm.buttonAction("undo")
                          },
                        },
                      },
                      [
                        _c("BaseNewTooltip", { attrs: { text: "Undo" } }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/audiogram/arrow-up-left.svg"),
                            },
                          }),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "undo",
                        class: _vm.isRedoDisabled
                          ? "o-40 not-allowed"
                          : "pointer",
                        on: {
                          click: function ($event) {
                            return _vm.buttonAction("redo")
                          },
                        },
                      },
                      [
                        _c("BaseNewTooltip", { attrs: { text: "Redo" } }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/audiogram/arrow-up-right.svg"),
                            },
                          }),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", [
                  _vm.loadingCanvas
                    ? _c("div", { staticClass: "lds-ellipsis" }, [
                        _c("div"),
                        _c("div"),
                        _c("div"),
                        _c("div"),
                      ])
                    : _vm._e(),
                ]),
                _c("BaseButtonRed", {
                  attrs: {
                    text: _vm.saveText,
                    onClick: _vm.handleSave,
                    loading: _vm.audiogramLoader,
                    disabled: _vm.audiogramLoader,
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }