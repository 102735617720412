

































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ components: {} })
export default class AudiogramWaveform extends Vue {
  @Prop() category!: any

  hideLeftPaddle = true
  hideRightPaddle = false

  loadAsset(ele: any) {
    this.$emit('load-element', ele)
  }

  get itemsLength() {
    return this.category.length
  }

  get title() {
    return this.category[0].category
  }

  handleScroll() {
    const menu = this.$refs.elementMenu as HTMLElement
    const wrapper = this.$refs.elementWrapper as HTMLElement
    // get how much have we scrolled so far
    let menuPosition = menu.scrollLeft
    // triggering point for the paddle
    let paddleMargin = 20
    // size of the visible part of the menu
    let menuWrapperSize = wrapper.offsetWidth
    // get how much of menu is invisible
    let menuInvisibleSize = this.itemsLength * 100 - menuWrapperSize
    let menuEndOffset = menuInvisibleSize - paddleMargin

    // show & hide the paddles depending on scroll position
    if (menuPosition <= paddleMargin) {
      this.hideLeftPaddle = true
      this.hideRightPaddle = false
    } else if (menuPosition < menuEndOffset) {
      this.hideLeftPaddle = false
      this.hideRightPaddle = false
    } else if (menuPosition >= menuEndOffset) {
      this.hideLeftPaddle = false
      this.hideRightPaddle = true
    }
  }
  scrollToLeft() {
    const menu = this.$refs.elementMenu as HTMLElement
    menu.scrollLeft += 200
  }

  scrollToRight() {
    const menu = this.$refs.elementMenu as HTMLElement
    menu.scrollLeft -= 200
  }
}
