var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "fixed flex justify-center items-center left-0 right-0 top-0 bottom-0 bg-black-80 no-select custom-index",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "flex flex-column bg-adori-gray ph2 pv3 mh-95 min-w5 br3 shadow-5",
        },
        [
          _c(
            "header",
            { staticClass: "flex flex-row justify-between ph4 pv3" },
            [
              _c("div", { staticClass: "light-gray fw5 f3" }, [
                _vm._v("Preview"),
              ]),
              _c(
                "button",
                {
                  staticClass: "bw0 bg-adori-gray light-gray link pointer dim",
                  on: {
                    click: function ($event) {
                      return _vm.handleCloseButtonClicked()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "material-icons f3" }, [
                    _vm._v("close"),
                  ]),
                ]
              ),
            ]
          ),
          _c(
            "section",
            {
              staticClass:
                "flex flex-wrap bg-adori-gray justify-center ph4 mb3 overflow-y-auto scrollbar",
            },
            [
              _c("div", { staticClass: "flex flex-column" }, [
                _c(
                  "div",
                  { staticClass: "flex justify-center items-center mb3" },
                  [
                    _c(
                      "div",
                      { staticClass: "pv1 ba br4 b--transparent bg-black-20" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "pv1 ph2 silver link pointer",
                            class:
                              _vm.orientation === "PORTRAIT"
                                ? "br4 bg-adori-red light-gray"
                                : "",
                          },
                          [
                            _c("input", {
                              staticClass: "input-reset",
                              attrs: { type: "radio" },
                              domProps: {
                                checked: _vm.orientation === "PORTRAIT",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.orientation = "PORTRAIT"
                                },
                              },
                            }),
                            _vm._v("Portrait"),
                          ]
                        ),
                        _c(
                          "label",
                          {
                            staticClass: "pv1 ph2 silver link pointer",
                            class:
                              _vm.orientation === "LANDSCAPE"
                                ? "br4 bg-adori-red light-gray"
                                : "",
                          },
                          [
                            _c("input", {
                              staticClass: "input-reset",
                              attrs: { type: "radio" },
                              domProps: {
                                checked: _vm.orientation === "LANDSCAPE",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.orientation = "LANDSCAPE"
                                },
                              },
                            }),
                            _vm._v("Landscape"),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm.orientation === "PORTRAIT"
                  ? _c(
                      "div",
                      { staticClass: "relative" },
                      [
                        _c("img", {
                          attrs: {
                            height: "500",
                            src: require("@/assets/phone-portrait.svg"),
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "absolute",
                            class:
                              _vm.canvasOrientation === "LANDSCAPE"
                                ? "pos-middle"
                                : "iphonex-portrait",
                          },
                          [_c("img", { attrs: { src: _vm.imgSrc, alt: "" } })]
                        ),
                        _c("BaseTagButtons", {
                          staticClass: "tag-actions",
                          attrs: {
                            tagType: _vm.tagType,
                            orientation: _vm.orientation,
                            saveable: _vm.saveable,
                            shareable: _vm.shareable,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.orientation === "LANDSCAPE"
                  ? _c(
                      "div",
                      { staticClass: "relative w710" },
                      [
                        _c("img", {
                          staticClass: "rotate",
                          attrs: {
                            height: "500",
                            src: require("@/assets/phone-portrait.svg"),
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "absolute",
                            class: {
                              "iphonex-landscape-1":
                                _vm.canvasOrientation === "PORTRAIT",
                              "iphonex-landscape-2":
                                _vm.canvasOrientation === "LANDSCAPE",
                              "iphonex-landscape-3":
                                _vm.canvasOrientation === "SQUARE",
                            },
                          },
                          [_c("img", { attrs: { src: _vm.imgSrc, alt: "" } })]
                        ),
                        _c("BaseTagButtons", {
                          staticClass: "tag-actions",
                          attrs: {
                            tagType: _vm.tagType,
                            orientation: _vm.orientation,
                            saveable: _vm.saveable,
                            shareable: _vm.shareable,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]
          ),
          _c(
            "footer",
            { staticClass: "flex justify-end ph4 mb2" },
            [_vm._t("footer")],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }