var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "element-head" }, [
      _c("div", { staticClass: "element-title ttc" }, [
        _vm._v("\n      " + _vm._s(_vm.title) + "\n    "),
      ]),
    ]),
    _c("div", { ref: "elementWrapper", staticClass: "element-wrapper" }, [
      _c(
        "ul",
        {
          ref: "elementMenu",
          staticClass: "element-menu",
          on: {
            "&scroll": function ($event) {
              return _vm.handleScroll.apply(null, arguments)
            },
          },
        },
        [
          _vm._l(_vm.category, function (ele) {
            return [
              _c(
                "li",
                {
                  staticClass: "element-item",
                  on: {
                    click: function ($event) {
                      return _vm.loadAsset(ele)
                    },
                  },
                },
                [
                  _c("img", {
                    class: {
                      "mt-20": ele.category === "recommended",
                    },
                    attrs: { src: ele.fileUrl },
                  }),
                ]
              ),
            ]
          }),
        ],
        2
      ),
      _c("div", { staticClass: "paddles" }, [
        _c(
          "div",
          {
            staticClass: "left-paddle paddle fade-left",
            class: { hidden: _vm.hideLeftPaddle },
            on: { click: _vm.scrollToRight },
          },
          [
            _c("img", {
              attrs: { src: require("@/assets/audiogram/left-paddle.svg") },
            }),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "right-paddle paddle fade-right",
            class: { hidden: _vm.hideRightPaddle },
            on: { click: _vm.scrollToLeft },
          },
          [
            _c("img", {
              attrs: { src: require("@/assets/audiogram/right-paddle.svg") },
            }),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }