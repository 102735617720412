var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: { fill: _vm.fillParent } }, [
    !_vm.isDownloading
      ? _c("div", {
          staticClass: "overflow-x-scroll relative scrollbar",
          staticStyle: { width: "1200px" },
          attrs: { id: _vm.name },
        })
      : _c(
          "div",
          {
            staticClass: "flex justify-center items-center mv4",
            staticStyle: { width: "1200px" },
          },
          [
            _c("div", { staticClass: "mr3 silver dib" }, [
              _vm._v("Downloading Audio. Please wait..."),
            ]),
            _c("div", { staticClass: "loader loader-inline" }),
          ]
        ),
    !_vm.isDownloading
      ? _c(
          "div",
          {
            staticClass: "flex flex-column mb5 mt3",
            staticStyle: { width: "1200px" },
          },
          [
            _c("div", { staticClass: "Controlsflex-container" }, [
              _c(
                "div",
                { staticClass: "volume", staticStyle: { width: "35%" } },
                [
                  _c("span", { staticClass: "volume-span" }, [
                    _c("span", { staticStyle: { "padding-right": "10px" } }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.currentTime) +
                          "/" +
                          _vm._s(_vm.getDuration) +
                          " "
                      ),
                    ]),
                    _c("span", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.volume,
                            expression: "volume",
                          },
                        ],
                        attrs: { type: "range", min: "1", max: "100" },
                        domProps: { value: _vm.volume },
                        on: {
                          input: _vm.setVolume,
                          __r: function ($event) {
                            _vm.volume = $event.target.value
                          },
                        },
                      }),
                    ]),
                    _c(
                      "button",
                      { staticClass: "bttn mute", on: { click: _vm.mute } },
                      [
                        _vm.muted
                          ? _c(
                              "span",
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: _vm.muteIcon },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        !_vm.muted
                          ? _c(
                              "span",
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: _vm.volumeUpIcon },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                { staticClass: "play-pause", staticStyle: { width: "30%" } },
                [
                  _c("span", { staticClass: "media-controls" }, [
                    _c(
                      "button",
                      { staticClass: "bttn", on: { click: _vm.playPause } },
                      [
                        _vm.playing
                          ? _c(
                              "span",
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: _vm.pauseIcon, size: "2x" },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        !_vm.playing
                          ? _c(
                              "span",
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: _vm.playIcon, size: "2x" },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                { staticClass: "zoom", staticStyle: { width: "35%" } },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.zoomValue,
                        expression: "zoomValue",
                      },
                    ],
                    staticStyle: { width: "70%" },
                    attrs: { type: "range", min: "1", max: "50", value: "0" },
                    domProps: { value: _vm.zoomValue },
                    on: {
                      change: _vm.zoomChange,
                      __r: function ($event) {
                        _vm.zoomValue = $event.target.value
                      },
                    },
                  }),
                ]
              ),
            ]),
          ]
        )
      : _vm._e(),
    _vm.initialTrackPlaylist.length !== 0 && !_vm.isDownloading
      ? _c("div", { staticClass: "flex flex-column mb3 mt3" }, [
          _vm._m(0),
          _c(
            "div",
            {
              staticClass:
                "w-100 max-h5 overflow-y-auto scrollbar bg-adori-light-gray light-gray bb b--adori-gray",
            },
            _vm._l(_vm.initialTrackPlaylist, function (ad, index) {
              return _c("div", { key: ad.id }, [
                ad.type !== "MAIN"
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "cf ph4 pv3 link darker-hover bt b--adori-gray bw1",
                      },
                      [
                        _c("div", { staticClass: "fl w-30 truncate pr2" }, [
                          _c("span", [
                            _vm._v(_vm._s(index) + " : " + _vm._s(ad.type)),
                          ]),
                        ]),
                        _c("div", { staticClass: "fl w-10 tc" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.timeDisplay(ad.abs_start)) +
                              "\n          "
                          ),
                        ]),
                        _c("div", { staticClass: "fl w-10 tc" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.timeDisplay(ad.abs_start + ad.duration)
                              ) +
                              "\n          "
                          ),
                        ]),
                        _c("div", { staticClass: "fl w-30 truncate" }, [
                          _c("span", { staticClass: "w-100 dib" }, [
                            _vm._v(_vm._s(ad.label)),
                          ]),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ])
            }),
            0
          ),
        ])
      : _vm._e(),
    _c(
      "div",
      {
        staticClass: "flex flex-column mb5 mt3",
        staticStyle: { width: "1200px" },
      },
      [
        _c("div", [
          _c("div", { staticClass: "bb b--dark-gray bw1" }, [
            _c(
              "div",
              { staticClass: "mb-neg" },
              [
                _vm.isSaveRequired
                  ? _c("BaseButtonRed", {
                      staticClass: "fr",
                      attrs: { text: "Save", onClick: _vm.finalizeAudio },
                    })
                  : _vm._e(),
                _vm.hasAdPosChanged
                  ? _c("BaseButtonRed", {
                      staticClass: "fl",
                      attrs: {
                        text: "Reprocess Audio",
                        onClick: _vm.createAudioPlaylist,
                      },
                    })
                  : _vm._e(),
                _c("BaseButtonBorder", {
                  staticClass: "bw0 bg-adori-gray fr",
                  attrs: { text: "Close", onClick: _vm.cancelPreview },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bg-adori-light-gray ph4 pv3" }, [
      _c("div", { staticClass: "cf silver" }, [
        _c("div", { staticClass: "fl w-30" }, [_vm._v("Ad Type")]),
        _c("div", { staticClass: "fl w-10 tc" }, [_vm._v("Start Time")]),
        _c("div", { staticClass: "fl w-10 tc" }, [_vm._v("End Time")]),
        _c("div", { staticClass: "fl w-30" }, [_vm._v("Name")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }