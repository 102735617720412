import Vue from 'vue'
import { StoreOptions, GetterTree, MutationTree, ActionTree } from 'vuex'
import AdoriService from '@/services/adori'
import axios from 'axios'

const state: any = {
  audioUploadProgress: 0,
  audioUploadURL: null,
  audioUploadId: null,
  audioUploadName: null,
  audioUploadDuration: null,
  audioUploadCancellationTokenSource: null,
  audioPlayUrl: null,
  audioUploadStatus: false,
}

const initialState: any = {
  ...JSON.parse(JSON.stringify(state)),
}

const getters: GetterTree<any, any> = {
  audioUploadProgress: (state) => state.audioUploadProgress,
  audioUploadURL: (state) => state.audioUploadURL,
  audioUploadId: (state) => state.audioUploadId,
  audioUploadName: (state) => state.audioUploadName,
  audioUploadDuration: (state) => state.audioUploadDuration,
  audioUploadCancellationTokenSource: (state) => state.audioUploadCancellationTokenSource,
  audioPlayUrl: (state) => state.audioPlayUrl,
  audioUploadStatus: (state) => state.audioUploadStatus,
}

const mutations: MutationTree<any> = {
  resetState(state) {
    Object.keys(state).forEach((key: any) => {
      Vue.set(state, key, initialState[key])
    })
  },

  setAudioUploadProgress(state, value: number) {
    Vue.set(state, 'audioUploadProgress', value)
  },

  setAudioUploadURL(state, url: string) {
    Vue.set(state, 'audioUploadURL', url)
  },

  setAudioUploadId(state, id: string) {
    Vue.set(state, 'audioUploadId', id)
  },

  setAudioUploadName(state, name: string) {
    Vue.set(state, 'audioUploadName', name)
  },

  setAudioUploadDuration(state, duration: any) {
    Vue.set(state, 'audioUploadDuration', duration)
  },

  setAudioUploadCancellationTokenSource(state, tokenSource: any) {
    Vue.set(state, 'audioUploadCancellationTokenSource', tokenSource)
  },
  setAudioPlayUrl(state, url: string) {
    Vue.set(state, 'audioPlayUrl', url)
  },
  setAudioUploadStatus(state, status: boolean) {
    Vue.set(state, 'audioUploadStatus', status)
  },
}

const actions: ActionTree<any, any> = {
  resetAudioUploaderState(context) {
    context.commit('resetState')
  },

  async uploadAudio(context, category = 'TRACK') {
    const networkId = context.getters.networkId
    const file = context.getters.selectedFile

    if (category === 'AD') {
      let aud = document.createElement('audio')
      const fileURL = URL.createObjectURL(file)

      aud.src = fileURL
      context.commit('setAudioPlayUrl', fileURL)
      aud.addEventListener('loadedmetadata', function () {
        context.commit('setAudioUploadDuration', aud.duration)
        aud.remove()
      })
    }

    const { uploadId, uploadUrl }: any = await AdoriService.createAudioUploadURL(networkId, {
      contentType: file.type,
      contentLength: file.size,
      category: category === 'VIDEO' ? 'TRACK' : category,
      preserveVideo: category === 'VIDEO',
    })

    const sessionURL = await AdoriService.createAudioUploadSessionURL(uploadUrl, file.type)

    context.commit('setAudioUploadId', uploadId)
    context.commit('setAudioUploadName', file.name)
    context.commit('setAudioUploadURL', sessionURL)

    const cancellationTokenSource = await axios.CancelToken.source()

    context.commit('setAudioUploadCancellationTokenSource', cancellationTokenSource)
    context.commit('setAudioUploadProgress', 0)

    await AdoriService.uploadAudio(context.state.audioUploadURL, file, cancellationTokenSource.token, (p: any) => {
      context.commit('setAudioUploadProgress', p.loaded / p.total)
    })
    context.commit('setAudioUploadStatus', true)
  },

  async clearAudioUploader(context) {
    const cancellationTokenSource = context.getters.audioUploadCancellationTokenSource
    if (cancellationTokenSource) {
      cancellationTokenSource.cancel('Audio upload cancelled by user.')
    }

    context.commit('setAudioUploadId', null)
    context.commit('setAudioUploadName', null)
    context.commit('setAudioUploadDuration', null)
    context.commit('setAudioUploadURL', null)
    context.commit('setAudioUploadProgress', 0)
    context.commit('setAudioUploadCancellationTokenSource', null)
    context.commit('setAudioPlayUrl', null)
    context.commit('setAudioUploadStatus', false)
  },
}

const audioUploader: StoreOptions<any> = {
  state,
  getters,
  mutations,
  actions,
}

export default audioUploader
