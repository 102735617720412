import Vue from 'vue'
import { StoreOptions, GetterTree, MutationTree, ActionTree } from 'vuex'

const MAX_NOTIFICATION_COUNT = 3
let notificationIdCount = 0

const state: any = {
  notifications: [],
}

const initialState: any = {
  ...JSON.parse(JSON.stringify(state)),
}

const getters: GetterTree<any, any> = {
  notifications: (state) => state.notifications,
}

const mutations: MutationTree<any> = {
  resetState(state) {
    state.notifications = []
    // Object.keys(state).forEach((key: any) => {
    //   Vue.set(state, key, initialState[key])
    // })
  },

  pushNotification(state, notification: any) {
    state.notifications.push(notification)
  },

  removeNotification(state, notificationId: number) {
    state.notifications = state.notifications.filter((notification: any) => notification.id !== notificationId)
  },
}

const actions: ActionTree<any, any> = {
  resetNotificationsState(context) {
    context.commit('resetState')
  },

  pushNotification(context, notification: any) {
    if (context.getters.notifications.length < MAX_NOTIFICATION_COUNT) {
      const id = notificationIdCount
      context.commit('pushNotification', {
        ...notification,
        id,
      })
      setTimeout(
        () => {
          context.dispatch('removeNotification', id)
        },
        ['SUCCESS', 'WARNING'].includes(notification.type) ? 5000 : 20000
      )

      notificationIdCount++
    }
  },

  removeNotification(context, notificationId: number) {
    context.commit('removeNotification', notificationId)
  },
}

const notifications: StoreOptions<any> = {
  state,
  getters,
  mutations,
  actions,
}

export default notifications
