



























import { Component, Prop, Vue } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import ImportRss from './ImportRss.vue'
import UploadAudio from './UploadAudio.vue'

@Component({
  components: { ImportRss, UploadAudio },
})
export default class ImportAndUpload extends Vue {
  @Prop(Boolean) hideUploadAudio!: boolean
  @Prop(Boolean) isStudioParent!: boolean

  @Getter youtubeModalPane!: string
  @Getter isYoutube!: boolean

  @Action showAudioTrackCreator!: any

  pane = this.youtubeModalPane || 'PODCAST'
  selectedTextFile = null
  textFileSetting = null

  selectedTab = 'BASIC'

  get showImportPodcast() {
    return this.isYoutube ? true : false
  }

  mounted() {
    this.pane = this.youtubeModalPane || this.pane
    this.$emit('changePane', this.pane)
  }

  changePane(pane: string) {
    this.pane = pane
    this.$emit('changePane', pane)
  }
  handleNext(file: any) {
    this.$emit('file-selected', this.selectedTextFile)
    this.$emit('file-setting', file)
  }
}
