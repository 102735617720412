import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import { getHashParam } from './utils/misc'
import AuthService from '@/services/auth'
import isEmpty from 'lodash.isempty'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    /***********
     * Default route.
     ***********/
    {
      path: '/',
      name: 'home',
      redirect: window.location.origin.includes('studio') ? '/overview' : '/home',
      meta: {
        requiresAuth: true,
      },
    },

    /***********
     * Routes available when the user is not logged in.
     ***********/
    {
      path: '/request-invite',
      name: 'request-invite',
      component: () => import('@/views/ViewRequestInvite.vue'),
    },
    {
      path: '/onboarding',
      name: 'onboarding',
      component: () => import('@/views/ViewOnboarding.vue'),
      meta: {
        requiresAuth: AuthService.isLoggedIn,
      },
    },
    {
      path: '/autopublish',
      name: 'autopublish',
      component: () => import('@/views/AutoPublish/AutoPublish.vue'),
      meta: {
        requiresAuth: AuthService.isLoggedIn,
      },
    },
    {
      path: '/video/login',
      name: 'video-login',
      redirect: '/login',
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/components/Auth/Login.vue'),
      meta: {
        requiresAuth: AuthService.isLoggedIn,
      },
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('@/views/ViewLogout.vue'),
    },
    {
      path: '/video/signup',
      name: 'video-signup',
      redirect: '/signup',
    },
    {
      path: '/signup',
      name: 'signup',
      component: () => import('@/components/Auth/Login.vue'),
      meta: {
        requiresAuth: AuthService.isLoggedIn,
      },
    },
    {
      path: '/youtubeTerms',
      name: 'youtubeTerms',
      component: () => import('@/components/Auth/Login.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/forgotpassword',
      name: 'forgotPass',
      component: () => import('@/components/Auth/Login.vue'),
    },
    {
      path: '/otp',
      name: 'otp',
      component: () => import('@/components/Auth/Login.vue'),
    },

    /***********
     * Routes available when the user is logged in.
     ***********/
    {
      path: '/settings',
      name: 'settings',
      redirect: '/settings/network',
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/settings/:tab',
      name: 'settingsTabs',
      component: () => import('@/views/ViewSettings.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/overview',
      name: 'overview',
      component: () => import('@/views/ViewOverview.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/audio',
      name: 'audio',
      redirect: '/audio/podcasts',
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/audioall',
      name: 'audio',
      redirect: '/audio/all',
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/audio/:channel/:id?',
      name: 'audio-channel',
      component: () => import('@/views/ViewAudio.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/audio/all-audios',
      name: 'audio-channel',
      component: () => import('@/views/ViewAudio.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/audio-editor',
      name: 'audio-editor',
      component: () => import('@/views/ViewAudioEditor.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/youtube/audio-editor',
      name: 'youtube-audio-editor',
      component: () => import('@/views/ViewAudioEditor.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/tags',
      name: 'tags',
      component: () => import('@/views/ViewTags.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/ai',
      name: 'ai',
      redirect: '/ai/templates',

      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/ai/:tab',
      name: 'aiTab',
      component: () => import('@/views/ViewAi.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/ai/:id',
      name: 'script-editor',
      component: () => import('@/views/ViewScriptEditor.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/ai/template/:id',
      name: 'template-editor',
      component: () => import('@/views/ViewAiTemplateEditor.vue'),
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: '/home/blog/:id',
      name: 'text-to-video',
      component: () => import('@/views/ViewAiTextToVideo.vue'),
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: '/ai/clone/new',
      name: 'voice-clone',
      component: () => import('@/views/ViewAiVoiceClone.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/ai/clone/use',
      name: 'voice-synthesis',
      component: () => import('@/views/ViewAiVoiceSynthesis.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/monetize',
      name: 'monetize',
      redirect: 'monetize/audioads',
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/monetize/:channel/:id?',
      name: 'monetize-channel',
      component: () => import('@/views/ViewMonetize.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/publish',
      name: 'publish',
      redirect: '/publish/podcasts',
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/publish/:channel/:id?',
      name: 'publish-channel',
      component: () => import('@/views/ViewPublish.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/campaign',
      name: 'campaign',
      component: () => import('@/views/campaign/ViewCampaign.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/campaign/report/:id',
      name: 'report',
      component: () => import('@/views/report/ViewReport.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/campaign/:id',
      name: 'flight',
      component: () => import('@/views/campaign/ViewCampaignFlight.vue'),
      props: true,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/youtubeAuth',
      name: 'youtube-auth',
      component: () => import('@/views/Publish/YoutubeAuth.vue'),
    },
    {
      path: '/plans',
      name: 'plans',
      component: () => import('@/views/ViewPlans.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    /***********
     * Internal pages.
     ***********/
    {
      path: '/featured',
      name: 'featured',
      component: () => import('@/views/ViewFeatured.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/generate-invite',
      name: 'generate-invite',
      component: () => import('@/views/ViewGenerateInvite.vue'),
    },
    {
      path: '/permission-error',
      name: 'permission-error',
      component: () => import('@/views/PermissionError.vue'),
    },
    {
      path: '/trial-end',
      name: 'trial-end',
      component: () => import('@/views/ViewTrialEnd.vue'),
    },
    {
      path: '/home',
      name: 'home',
      redirect: '/home/podcasts',
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/home/:channel/:id?',
      name: 'home-channel',
      component: () => import('@/views/ViewHome.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/rewards',
      name: 'rewards',
      component: () => import('@/views/ViewReward.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/badges',
      name: 'badges',
      component: () => import('@/views/ViewBadge.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/podcast/search',
      name: 'podcast-search',
      component: () => import('@/views/ViewImportAndUpload.vue'),
    },
    { path: '*', component: () => import('@/views/404.vue') },
  ],
})

const loadData = async () => {
  if (sessionStorage.getItem('redirect_uri')) {
    const redirectUri: any = sessionStorage.getItem('redirect_uri')
    sessionStorage.removeItem('redirect_uri')
    window.location.href = `${redirectUri}#id_token=${localStorage.getItem(
      'id_token'
    )}&expires_at=${localStorage.getItem('expires_at')}`
  }
  store.commit('setInitialLoader', true)
  await store.dispatch('loadUser')
  store.commit('setInitialLoader', false)
}

router.beforeResolve(async (to, from, next) => {
  if (to.query.redirect_uri) sessionStorage.setItem('redirect_uri', to.query.redirect_uri)
  // Handle the login from auth0
  if (to.hash) {
    store.commit('setInitialLoader', true)
    const token = getHashParam('id_token')
    const expiry = getHashParam('expires_at')

    // this handle login from auth0 inside studio
    if (!expiry) {
      await AuthService.setSession()
      if (sessionStorage.getItem('youtubeAuth') === 'YES') next({ path: '/youtubeTerms' })
      else if (window.parent.name === 'youtubeAuthHandle') {
        window.close()
      } else {
        if (localStorage.getItem('AUTH')) {
          try {
            await store.dispatch('getPermissions')
            localStorage.removeItem('AUTH')
          } catch (e) {}
        }
        await loadData()
        next({ ...to, hash: '', replace: true })
        return
      }
    } else {
      // This handles login from Adori sso
      store.dispatch('setSession', { token, expiry })
      await loadData()
      next({ ...to, hash: '', replace: true })
      return
    }
  }

  //   this handles all the routes which requires auth
  if (AuthService.isLoggedIn && !!to.meta?.requiresAuth) {
    const token: any = localStorage.getItem('id_token')
    const expiry: any = localStorage.getItem('expires_at')
    store.dispatch('setSession', {
      token: token,
      expiry: expiry,
    })

    if (!!store.getters.networkId === false) {
      await loadData()
      if (to.path === '/onboarding' && !isEmpty(to.query) && to.query.feedUrl) {
        sessionStorage.setItem('feedUrl', to.query['feedUrl'])
        next({ path: '/onboarding' })
      } else if (['/login', '/signup', '/forgotpassword', '/otp', '/callback'].includes(to.path)) {
        next('/')
      } else next()
    } else next()
  } else if (!AuthService.isLoggedIn && !!to.meta?.requiresAuth) {
    next('/login')
  } else {
    next()
  }
})

export default router
