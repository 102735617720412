var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "flex justify-between items-center" }, [
      _c(
        "button",
        {
          staticClass: "pagination-btn br-100 pa0",
          class:
            _vm.selectedRangeStart === 1
              ? "bn bg-black-40"
              : "ba b--adori-gray bg-dark-gray point",
          attrs: { disabled: _vm.selectedRangeStart === 1 },
          on: {
            click: function ($event) {
              return _vm.handleNavigation("prev")
            },
          },
        },
        [
          _c("i", { staticClass: "material-icons light-gray f3 v-mid" }, [
            _vm._v(" arrow_left "),
          ]),
        ]
      ),
      _c("p", { staticClass: "silver" }, [
        _vm._v("\n      Showing images\n      "),
        _c("span", { staticClass: "light-gray" }, [
          _vm._v(
            _vm._s(_vm.selectedRangeStart) +
              " - " +
              _vm._s(_vm.selectedRangeEnd)
          ),
        ]),
        _vm._v("\n      of total\n      "),
        _c("span", { staticClass: "light-gray" }, [
          _vm._v(_vm._s(_vm.totalImages.toLocaleString("en-US"))),
        ]),
        _vm._v("\n      images\n    "),
      ]),
      _c(
        "button",
        {
          staticClass: "pagination-btn br-100 pa0",
          class:
            _vm.selectedRangeEnd >= _vm.totalImages
              ? "bn bg-black-40"
              : "ba b--adori-gray bg-dark-gray point",
          attrs: { disabled: _vm.selectedRangeEnd >= _vm.totalImages },
          on: {
            click: function ($event) {
              return _vm.handleNavigation("next")
            },
          },
        },
        [
          _c("i", { staticClass: "material-icons light-gray f3 v-mid" }, [
            _vm._v(" arrow_right "),
          ]),
        ]
      ),
    ]),
    _c(
      "div",
      {
        staticClass: "w-100 overflow-y-auto scrollbar",
        style: !_vm.showCreateTagButton
          ? "height:420px;"
          : _vm.isMaximized
          ? "height: 225px"
          : "height: 500px",
      },
      [
        _c(
          "div",
          { staticClass: "masonry-grid ph2" },
          _vm._l(_vm.imageSources, function (img) {
            return _c("div", { key: img.id, staticClass: "relative" }, [
              _c("img", {
                staticClass: "masonry-content ba bw1 b--adori-transparent",
                class:
                  _vm.previewImageSrc === img.url && !_vm.showCreateTagButton
                    ? "ba b--adori-red-imp "
                    : !_vm.showCreateTagButton
                    ? "masonry-content-scale"
                    : "",
                attrs: {
                  href: img.previewUrl,
                  src: img.previewUrl,
                  download: "",
                },
                on: {
                  click: function ($event) {
                    !_vm.showCreateTagButton &&
                      _vm.$emit("handleSelectImage", img)
                  },
                },
              }),
              _c(
                "i",
                {
                  staticClass:
                    "absolute right-1 bg-black-50 material-icons light-gray f4 v-top pa1 child br3 pointer",
                  staticStyle: { top: "10px", right: "1px" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("viewImages", img.url)
                    },
                  },
                },
                [_vm._v("\n          visibility\n        ")]
              ),
              _vm.showCreateTagButton
                ? _c(
                    "i",
                    {
                      staticClass:
                        "material-icons bg-dark-gray br-100 pa1 light-gray f4 absolute pointer",
                      staticStyle: { top: "15px", right: "10px" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("handleSelectImage", img)
                        },
                      },
                    },
                    [_vm._v("\n          add\n        ")]
                  )
                : _vm._e(),
            ])
          }),
          0
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }