


















































































































































































































































































import { Component, Mixins, Watch } from 'vue-property-decorator'
import ImportAndUpload from '../Youtube/ImportAndUpload.vue'
import ImageGallery from '../Common/Images/ImageGallery.vue'
import { validationMixin } from 'vuelidate'
import ImagePreviewer from '@/mixins/ImagePreviewer'
import LazyLoader from '@/mixins/LazyLoader'
import { required, maxLength } from 'vuelidate/lib/validators'
import HTMLEditor from '@/components/Common/HTMLEditor.vue'
import { Action, Getter } from 'vuex-class'
import AdoriService from '@/services/adori'
import { uploadStatus } from '../Publish/publish'
import get from 'lodash.get'
import * as Sentry from '@sentry/vue'
import Invalidate from '@/mixins/Invalidate'
import { audioQueryKeys } from '@/hooks/audio'

const validations = {
  selectedEpisode: {
    name: {
      required,
      maxLength: maxLength(255),
    },
    description: {
      required,
      maxLength: maxLength(65535),
      notEmptyHTML: (value: string) => value !== '<p><br></p>',
    },
    explicitContent: {
      required,
    },
    keywords: {
      maxLength: maxLength(255),
    },
  },
}

@Component({
  // @ts-ignore
  validations,
  components: { ImportAndUpload, ImageGallery, HTMLEditor },
})
export default class ModalPodcastImportAndUpload extends Mixins(
  validationMixin,
  ImagePreviewer,
  LazyLoader,
  Invalidate
) {
  @Getter networkId!: any
  @Getter networkType!: any
  @Getter selectedFile!: any
  @Getter languages!: any
  @Getter isYoutube!: Boolean
  @Getter audio!: any

  @Action getAudioUids!: any
  @Action addAudioUid!: any
  @Action getLanguages!: any
  @Action createAudioTrack!: any

  $v: any
  selectedImageSrc = ''
  selectedImageId: string = ''
  hasClickedImage: boolean = false
  selectedEpisode: any = null
  hasClickedNext: boolean = false
  enableImageGallery = false
  showAdvancedOptions: boolean = false
  languageDisabled: boolean = false
  selectedLanguage: string = 'en-US'
  imgLoader = false
  audioUrl = ''
  imageUrl = ''
  durationSeconds!: any
  finalProgress = 0
  uploadLoader = false

  pane = 'PODCAST'
  selectedTextFile = null
  textFileSetting: any = {
    voiceType: 'Standard',
    voiceGender: 'FEMALE',
    language: 'en-US',
    voiceName: 'en-US-Standard-C',
  }

  @Watch('selectedFile')
  handleSelectedFile() {
    if (this.selectedFile.type.includes('audio')) {
      let aud = document.createElement('audio')
      const fileURL = URL.createObjectURL(this.selectedFile)
      aud.src = fileURL
      aud.addEventListener('loadedmetadata', () => {
        this.durationSeconds = aud.duration
        aud.remove()
      })
      this.audioUrl = fileURL
    } else if (this.selectedFile.type.includes('video')) {
      let aud = document.createElement('video')
      const fileURL = URL.createObjectURL(this.selectedFile)
      aud.src = fileURL
      aud.addEventListener('loadedmetadata', () => {
        this.durationSeconds = aud.duration
        aud.remove()
      })
      this.audioUrl = fileURL
    } else if (this.selectedFile.type.includes('image')) {
      const fileURL = URL.createObjectURL(this.selectedFile)
      this.imageUrl = fileURL
    }
  }

  @Watch('imageUrl')
  handleImageUrl() {
    this.selectedEpisode['imageUrl'] = this.imageUrl
  }
  @Watch('audioUrl')
  handleAudioUrl() {
    this.selectedEpisode['audioUrl'] = this.audioUrl
  }

  async created() {
    if (!this.languages) await this.getLanguages()
  }
  get uploadProgress() {
    return Math.round(this.$store.getters.audioUploadProgress * 100)
  }

  get hasCompletedUpload() {
    if (this.pane === 'AUDIO' && this.uploadProgress === 100) return true
    else if (this.pane === 'TTS' && this.selectedTextFile) return true
  }

  get title() {
    return this.isYoutube ? 'Import a podcast or upload' : 'Upload a new audio track'
  }
  get hasPreviewImage() {
    return this.selectedImageSrc
  }

  get canSubmit() {
    return this.hasPreviewImage && !this.$v.$invalid
  }
  get hideUploadAudio() {
    return this.networkId ? false : true
  }

  @Watch('selectedImageId')
  handleSelectedImageId() {
    this.selectedEpisode['imageId'] = this.selectedImageId
  }

  @Watch('hasCompletedUpload')
  handleHasCompletedUpload() {
    if (this.hasCompletedUpload) {
      this.selectedEpisode = {
        name: '',
        description: '',
        imageId: this.selectedImageId,
        keywords: '',
        explicitContent: false,
        isRss: false,
        audioUrl: this.audioUrl,
        imageUrl: this.imageUrl,
        uploadId: this.$store.getters.audioUploadId,
        durationSeconds: this.durationSeconds,
        language: this.selectedLanguage,
      }
      if (this.pane === 'TTS') {
        let fileName = get(this.selectedTextFile, 'name', '')
        fileName = fileName.replace(/\.[^/.]+$/, '')
        this.selectedEpisode = {
          ...this.selectedEpisode,
          description: fileName,
          name: fileName,
        }
      }
      this.pane === 'AUDIO' && (this.selectedEpisode['guid'] = 'adori_1Ep')
      this.languageDisabled = false
      this.selectedImageSrc = ''
    }
  }

  updateDescription(newDescription: string) {
    this.$v.selectedEpisode.description.$model = newDescription
  }

  changePane(pane: string) {
    this.pane = pane
  }
  handleFileSelected(file: any) {
    this.selectedTextFile = file
  }
  handleFileSetting(setting: any) {
    if (setting) {
      this.textFileSetting = setting
    }
  }

  async handleSaveDetails() {
    if (this.isYoutube) {
      this.handleYoutubeAudioTrackSubmit()
    } else {
      this.handleStudioAudioTrackSubmit()
    }
  }

  async handleStudioAudioTrackSubmit() {
    if (this.pane === 'TTS' && this.selectedTextFile) {
      if (this.canSubmit) {
        this.uploadLoader = true
        try {
          if (!this.textFileSetting.voiceId) {
            this.selectedEpisode.keywords = this.selectedEpisode.keywords.split(',')
            this.selectedEpisode.voiceType = this.textFileSetting.voiceType
            this.selectedEpisode.voiceGender = this.textFileSetting.voiceGender
            this.selectedEpisode.language = this.textFileSetting.language
            this.selectedEpisode.voiceName = this.textFileSetting.voiceName
          } else {
            this.selectedEpisode = { ...this.selectedEpisode, ...this.textFileSetting }
          }

          const payload = {
            file: this.selectedTextFile,
            track_info: this.selectedEpisode,
          }

          const taskId = await this.$store.dispatch('createAudioTrackFromFile', payload)

          const poll = setInterval(async () => {
            const res: any = await AdoriService.getTTSUploadStatus(this.networkId, taskId)

            if (res.status === uploadStatus.FINISHED) {
              clearInterval(poll)
              this.queryClient.invalidateQueries(audioQueryKeys.ALLTRACKS)
              await this.$store.dispatch('clearAudioUploader')
              this.addAudioUid(res.audioUid)
              this.uploadLoader = false
              this.$store.dispatch('closeModal')
            }
            if (res.status === uploadStatus.FAILED) {
              clearInterval(poll)
              this.$store.dispatch('pushNotification', {
                text: res.errorMessage || 'Upload Failed',
                type: 'ERROR',
              })
              this.uploadLoader = false
              this.$store.dispatch('closeModal')
            }
          }, 2000)
        } catch (error) {
          this.uploadLoader = false
          Sentry.captureException(error)
          this.$store.dispatch('pushNotification', {
            text: 'Upload Failed',
            type: 'ERROR',
          })
          this.$store.dispatch('closeModal')
        }
      } else {
        this.hasClickedNext = true
      }
    } else if (this.pane === 'AUDIO' && this.uploadProgress === 100) {
      if (this.canSubmit) {
        this.uploadLoader = true
        try {
          await AdoriService.updateUpload(this.networkId, this.$store.getters.audioUploadId)
          const poll = setInterval(async () => {
            const res: any = await AdoriService.getUploadStatus(this.networkId, this.$store.getters.audioUploadId)

            if (res.processingStatus === uploadStatus.FINISHED) {
              clearInterval(poll)
              this.selectedEpisode.keywords = this.selectedEpisode.keywords.split(',')
              const payload = {
                category: 'TRACK',
                ytFeedUid: null,
                name: this.selectedEpisode.name,
                description: this.selectedEpisode.description,
                language: this.selectedEpisode.language,
                public: true,
                uploadId: this.selectedEpisode.uploadId,
                imageId: this.selectedEpisode.imageId,
                keywords: this.selectedEpisode.keywords,
              }
              const audioTrack = await this.createAudioTrack(payload)
              this.queryClient.invalidateQueries(audioQueryKeys.ALLTRACKS)
              await this.$store.dispatch('clearAudioUploader')
              this.addAudioUid(audioTrack.uid)
              this.uploadLoader = false
              this.$store.dispatch('closeModal')
            }
            if (res.processingStatus === uploadStatus.FAILED) {
              clearInterval(poll)
              this.$store.dispatch('pushNotification', {
                text: 'Upload Failed',
                type: 'ERROR',
              })
              this.uploadLoader = false
              this.$store.dispatch('closeModal')
            }
          }, 2000)
        } catch (error) {
          this.uploadLoader = false
        }
      } else {
        this.hasClickedNext = true
      }
    }
  }

  async handleYoutubeAudioTrackSubmit() {
    if (this.pane === 'TTS' && this.selectedTextFile) {
      if (this.canSubmit) {
        this.uploadLoader = true
        try {
          this.selectedEpisode.keywords = this.selectedEpisode.keywords.split(',')
          if (!this.textFileSetting.voiceId) {
            this.selectedEpisode.voiceType = this.textFileSetting.voiceType
            this.selectedEpisode.voiceGender = this.textFileSetting.voiceGender
            this.selectedEpisode.language = this.textFileSetting.language
            this.selectedEpisode.voiceName = this.textFileSetting.voiceName
          } else {
            this.selectedEpisode = { ...this.selectedEpisode, ...this.textFileSetting }
          }

          const payload = {
            file: this.selectedTextFile,
            track_info: this.selectedEpisode,
          }

          const taskId = await this.$store.dispatch('createAudioTrackFromFile', payload)

          const poll = setInterval(async () => {
            const res: any = await AdoriService.getTTSUploadStatus(this.networkId, taskId)

            if (res.status === uploadStatus.FINISHED) {
              clearInterval(poll)

              await this.getAudioUids({
                clear: true,
              })

              const audioTrack = this.audio(res.audioUid)
              this.addAudioUid(res.audioUid)
              await this.$store.dispatch('clearAudioUploader')
              this.uploadLoader = false
              const selectedEpisode: any = {
                audioUrl: audioTrack.audioUrl,
                name: audioTrack.name,
                description: audioTrack.description,
                explicitContent: audioTrack.explicitContent,
                guid: audioTrack.guid,
                imageId: audioTrack.imageId,
                imageUrl: audioTrack.imageUrl,
                isRss: false,
                keywords: audioTrack.keywords,
                durationSeconds: audioTrack.durationMillis / 1000,
                isReUpload: true,
                videoUrl: audioTrack.videoUrl,
              }
              this.$store.commit('unselectAllEpisodeForUpload')
              this.$store.commit('clearYoutubeState')
              this.$store.commit('resetYoutubeSetting')
              this.$store.commit('selectEpisodeForUpload', selectedEpisode)
              this.$store.commit('setYoutubeStep', 2)
              sessionStorage.setItem('upload', 'YES')
              this.$router.push('/onboarding')
              this.queryClient.invalidateQueries(audioQueryKeys.ALLTRACKS)
              this.$store.dispatch('closeModal')
            }
            if (res.status === uploadStatus.FAILED) {
              clearInterval(poll)
              this.$store.dispatch('pushNotification', {
                text: res.errorMessage || 'Upload Failed',
                type: 'ERROR',
              })
              this.uploadLoader = false
              this.$store.dispatch('closeModal')
            }
          }, 5000)
        } catch (e) {
          Sentry.captureException(e)
          this.$store.dispatch('pushNotification', {
            text: 'Upload Failed',
            type: 'ERROR',
          })
          this.$store.dispatch('closeModal')
        }
      } else {
        this.hasClickedNext = true
      }
    } else if (this.pane === 'AUDIO' && this.uploadProgress === 100) {
      if (this.canSubmit) {
        this.uploadLoader = true
        try {
          await AdoriService.updateUpload(this.networkId, this.$store.getters.audioUploadId)

          const poll = setInterval(async () => {
            const res: any = await AdoriService.getUploadStatus(this.networkId, this.$store.getters.audioUploadId)
            this.finalProgress = res.uploadProgress

            if (res.processingStatus === uploadStatus.FINISHED) {
              clearInterval(poll)

              this.selectedEpisode.keywords = this.selectedEpisode.keywords.split(',')
              this.selectedEpisode.language = this.selectedLanguage

              const payload = {
                category: 'TRACK',
                ytFeedUid: null,
                name: this.selectedEpisode.name,
                description: this.selectedEpisode.description,
                language: this.selectedEpisode.language,
                public: true,
                uploadId: this.selectedEpisode.uploadId,
                imageId: this.selectedEpisode.imageId,
                keywords: this.selectedEpisode.keywords,
              }

              const audioTrack = await this.createAudioTrack(payload)
              this.queryClient.invalidateQueries(audioQueryKeys.ALLTRACKS)

              await this.$store.dispatch('clearAudioUploader')
              this.uploadLoader = false
              const selectedEpisode: any = {
                audioUrl: audioTrack.audioUrl,
                name: audioTrack.name,
                description: audioTrack.description,
                explicitContent: audioTrack.explicitContent,
                guid: audioTrack.guid,
                imageId: audioTrack.imageId,
                imageUrl: audioTrack.imageUrl,
                isRss: false,
                keywords: audioTrack.keywords,
                durationSeconds: audioTrack.durationMillis / 1000,
                isReUpload: true,
                videoUrl: audioTrack.videoUrl,
              }

              this.$store.commit('unselectAllEpisodeForUpload')
              this.$store.commit('clearYoutubeState')
              this.$store.commit('setPublicFeedEpisodes', [])
              this.$store.commit('resetYoutubeSetting')
              sessionStorage.removeItem('feedUrl')

              this.$store.commit('selectEpisodeForUpload', selectedEpisode)
              this.$store.commit('setYoutubeStep', 2)
              // @ts-ignore
              sessionStorage.setItem('upload', 'YES')
              this.$router.push('/onboarding')
              this.$store.commit('setCurrentModal', null)
            }
            if (res.processingStatus === uploadStatus.FAILED) {
              clearInterval(poll)
              this.$store.dispatch('pushNotification', {
                text: 'Upload Failed',
                type: 'ERROR',
              })
              this.uploadLoader = false
              this.$store.dispatch('closeModal')
            }
          }, 5000)
        } catch (e) {
          this.$store.dispatch('pushNotification', {
            text: 'Upload Failed',
            type: 'ERROR',
          })
          this.$store.dispatch('closeModal')
        }
      } else {
        this.hasClickedNext = true
      }
    }
  }

  destroyed() {
    this.$store.dispatch('clearAudioUploader')
  }

  showImageGallery() {
    if (this.uploadLoader) return
    if (this.enableImageGallery && !this.selectedImageSrc) {
      this.selectedImageSrc = ''
    }
    this.hasClickedImage = true
    this.enableImageGallery = !this.enableImageGallery
  }

  async handleSelectedImage(imgData: any) {
    this.hasClickedImage = true
    this.enableImageGallery = !this.enableImageGallery
    this.imgLoader = true
    if (imgData.urls.full && imgData.urls.full.includes('adorilabs')) {
      this.selectedImageSrc = imgData.urls.full
      this.imageUrl = imgData.urls.full
      this.selectedImageId = imgData.id
    } else if (imgData.urls.full && imgData.urls.full.startsWith('https://')) {
      this.selectedImageSrc = imgData.urls.full
      const img: any = await AdoriService.uploadImage(this.networkId, {
        url: this.selectedImageSrc,
      })
      this.selectedImageId = img.id
      this.imageUrl = img.thumbnailUrl
    } else if (this.previewImage !== '/img/Web link.c650ed21.jpg') {
      const img: any = await AdoriService.uploadImage(this.networkId, this.selectedFile)
      this.selectedImageId = img.id
      this.imageUrl = img.thumbnailUrl

      this.selectedImageSrc = this.previewImage
    } else {
      this.selectedImageSrc = '/img/Web link.c650ed21.jpg'
    }
    this.imgLoader = false
  }
}
