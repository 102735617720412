var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    { attrs: { locked: _vm.isSubmitting } },
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "light-gray f3 fw5" }, [
          _vm._v("Replace audio"),
        ]),
      ]),
      _c("template", { slot: "body" }, [
        _vm.currentStep === 1
          ? _c(
              "div",
              {
                staticClass: "flex flex-column justify-between",
                staticStyle: { width: "650px", "min-height": "360px" },
              },
              [
                _c(
                  "div",
                  [
                    _c("div", { staticClass: "silver" }, [
                      _vm._v(
                        "\n          This action will replace this track with another track that you an upload in the next step.\n        "
                      ),
                    ]),
                    _c("div", { staticClass: "light-gray mt4 mb2 f6" }, [
                      _vm._v(
                        "Please select the audio language that the replacement track is in:"
                      ),
                    ]),
                    _c("LanguageSelector", {
                      staticClass: "w-50 mb3",
                      on: {
                        "on-lang-change": function (lang) {
                          return (_vm.selectedLang = lang)
                        },
                      },
                    }),
                    _vm.trackSource === "UPLOADED"
                      ? _c("BaseCheckbox", {
                          staticClass: "mr2",
                          attrs: {
                            text: "Keep existing interactive tags",
                            initialState: _vm.keepInteractiveTags,
                          },
                          on: {
                            toggle: function ($event) {
                              _vm.keepInteractiveTags = $event
                            },
                          },
                        })
                      : _vm._e(),
                    _vm.trackSource === "UPLOADED"
                      ? _c(
                          "div",
                          {
                            staticClass: "silver f6 lh-copy mb3",
                            staticStyle: { "padding-left": "28px" },
                          },
                          [
                            _vm._v(
                              "\n          Select this to reinsert interactive tags that were part of the audio being replaced into the new track on a\n          ‘best effort basis’. All previous tags will continue to be available in All Tags and any tag collections\n          they were part of.\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.trackSource === "IMPORTED"
                      ? _c("div", { staticClass: "silver f6 lh-copy" }, [
                          _vm._v(
                            "\n          Please note that a transcript and Adori’s interactive tag suggestions (available in 10 major global\n          languages) will be generated for the new audio track replacing the current one. Once the upload and\n          processing of the replacement audio track is completed, you will be able to use the automatically generated\n          tag suggestions or create custom tags to enhance your audio with interactive visuals and calls to action to\n          enhance the listening experience.\n        "
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            )
          : _c(
              "div",
              { staticStyle: { width: "650px", "min-height": "360px" } },
              [
                _c(
                  "div",
                  { staticClass: "flex flex-column items-center pa4" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "w-100 h5 bg-adori-light-gray ba b--light-gray b--dashed br2 pa5 flex flex-column items-center justify-center",
                        class: _vm.isUploadingAudio
                          ? "not-allowed o-20"
                          : "link pointer dim",
                        on: { click: _vm.handleUploadAudio },
                      },
                      [
                        _c(
                          "i",
                          {
                            staticClass: "material-icons f-headline silver mb2",
                          },
                          [_vm._v("publish")]
                        ),
                        _c("div", { staticClass: "f4 silver" }, [
                          _vm._v("Upload a new audio"),
                        ]),
                      ]
                    ),
                    _vm.isUploadingAudio || _vm.uploadProgress === 100
                      ? _c(
                          "div",
                          { staticClass: "pt4 flex flex-column items-center" },
                          [
                            _c("progress", {
                              attrs: { max: "100" },
                              domProps: { value: _vm.uploadProgress },
                            }),
                            _c("div", { staticClass: "f6 silver pt2" }, [
                              _vm._v(
                                "Uploading audio: " +
                                  _vm._s(_vm.uploadProgress + "%")
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            ),
      ]),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          { staticClass: "flex justify-between w-100 items-center" },
          [
            _vm.currentStep === 2
              ? _c("BaseButtonBorder", {
                  attrs: {
                    text: "Back",
                    onClick: function () {
                      return (_vm.currentStep = 1)
                    },
                  },
                })
              : _c("div", { staticClass: "w3" }),
            _c("div", { staticClass: "silver" }, [
              _vm._v("Step " + _vm._s(_vm.currentStep) + " of 2"),
            ]),
            _c(
              "div",
              { staticClass: "flex items-center relative" },
              [
                _vm.isSubmitting
                  ? _c("div", {
                      staticClass: "loader loader-inline mh3 absolute",
                      staticStyle: { left: "-3rem" },
                    })
                  : _vm._e(),
                _vm.currentStep === 1
                  ? _c("BaseButtonBorder", {
                      attrs: {
                        text: "Continue",
                        onClick: function () {
                          return (_vm.currentStep = 2)
                        },
                      },
                    })
                  : _c("BaseButtonBorder", {
                      attrs: {
                        disabled: _vm.uploadProgress !== 100,
                        text: "Replace audio",
                        onClick: _vm.handleReplaceAudio,
                      },
                    }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }