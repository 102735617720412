var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "f3 light-gray fw5 di" }, [
          _vm._v("Estimates"),
        ]),
      ]),
      _c("template", { slot: "body" }, [
        _c(
          "div",
          { staticClass: "h5 mt3 w-100", staticStyle: { height: "300px" } },
          [
            _vm.isLoading
              ? _c(
                  "div",
                  {
                    staticClass: "flex w-100 h-100 items-center justify-center",
                  },
                  [
                    _c("div", { staticClass: "loader loader-inline mr3" }),
                    _c("div", { staticClass: "silver" }, [
                      _vm._v("Loading estimates..."),
                    ]),
                  ]
                )
              : _vm.sampePlanId
              ? _c(
                  "div",
                  {
                    staticClass:
                      "flex flex-column w-100 h-100 justify-center items-center lh-copy",
                  },
                  [
                    _c("div", { staticClass: "silver" }, [
                      _vm._v("Selected plan is same as your current plan."),
                    ]),
                    _c("div", { staticClass: "silver" }, [
                      _vm._v("Choose different plan for estimates."),
                    ]),
                  ]
                )
              : _c(
                  "div",
                  { staticClass: "light-gray w-100" },
                  [
                    _vm._l(_vm.estimates.data.items, function (item, i) {
                      return _c("div", { key: i }, [
                        _c(
                          "div",
                          { staticClass: "flex justify-between items-end mb4" },
                          [
                            _c("div", {}, [
                              _c("div", { staticClass: "mb2" }, [
                                _vm._v(_vm._s(item.plan.metadata.description)),
                              ]),
                              _c("div", { staticClass: "gray f6" }, [
                                _vm._v(_vm._s(item.description)),
                              ]),
                            ]),
                            _c("div", { staticClass: "ml5" }, [
                              _vm._v(_vm._s(item.amount)),
                            ]),
                          ]
                        ),
                      ])
                    }),
                    _c(
                      "div",
                      { staticClass: "flex justify-between items-center mb4" },
                      [
                        _c("div", {}, [_vm._v("Amount due")]),
                        _c("div", { staticClass: "ml5" }, [
                          _vm._v(_vm._s(_vm.estimates.amountDue)),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "flex justify-between items-center mb4" },
                      [
                        _c("div", {}, [_vm._v("Balance")]),
                        _c("div", { staticClass: "ml5" }, [
                          _vm._v(_vm._s(_vm.estimates.balance)),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "flex justify-between items-center" },
                      [
                        _c("div", {}, [_vm._v("Payable")]),
                        _c("div", { staticClass: "ml5" }, [
                          _c("span", { staticClass: "silver mr1" }, [
                            _vm._v("$"),
                          ]),
                          _vm._v(_vm._s(_vm.estimates.payable)),
                        ]),
                      ]
                    ),
                  ],
                  2
                ),
          ]
        ),
      ]),
      _c("template", { slot: "footer" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }