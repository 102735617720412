var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      staticClass: "bg-near-black",
      attrs: { locked: "", hideCloseButton: "" },
    },
    [
      _c("template", { slot: "header" }),
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "flex flex-column light-gray lh-copy" }, [
          _c("div", [
            _vm._v("You are no longer part of any network or show on Adori!"),
          ]),
          _c("div", [
            _c(
              "span",
              {
                staticClass: "mr1 pointer underline",
                on: { click: _vm.goToRequestInvite },
              },
              [_vm._v("Click here")]
            ),
            _vm._v(
              "to start a new podcast or\n        import your existing podcasts.\n      "
            ),
          ]),
          _c("div", [
            _c(
              "span",
              {
                staticClass: "mr1 pointer underline",
                on: { click: _vm.handleSignOut },
              },
              [_vm._v("Click here")]
            ),
            _vm._v(
              "\n        to sign out and login as different user.\n      "
            ),
          ]),
        ]),
      ]),
      _c("template", { slot: "footer" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }