























































































































































































































































































import { Component, Vue, Mixins, Watch } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import ImagePreviewer from '@/mixins/ImagePreviewer'
import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'
import AdoriService, { getImageSrc } from '@/services/adori'
import HTMLEditor from '@/components/Common/HTMLEditor.vue'
import ImageGallery from '@/components/Common/Images/ImageGallery.vue'
import { uploadStatus } from '../Publish/publish'

const validations = {
  name: {
    required,
    maxLength: maxLength(255),
  },
  description: {
    required,
    maxLength: maxLength(65535),
    // Custom validation for empty HTML.
    // This comes from our use of medium-editor in <HTMLEditor />.
    notEmptyHTML: (value: string) => value !== '<p><br></p>',
  },
  summary: {
    maxLength: maxLength(255),
  },
  explicitContent: {
    required,
  },
  keywords: {
    maxLength: maxLength(255),
  },
}

@Component({
  // @ts-ignore
  validations,
  components: { HTMLEditor, ImageGallery },
})
export default class ModalAudioTrackCreate extends Mixins(validationMixin, ImagePreviewer) {
  @Getter userId!: any
  @Getter networkId!: any
  @Getter selectedFile!: any
  @Getter audioCollectionSearchValue!: any
  @Getter defaultAudioCollection!: any
  @Getter languages!: any

  @Action getAudioCollectionTracks!: any
  @Action getAudioUids!: any
  @Action addAudioUid!: any
  @Action getLanguages!: any

  $v: any
  hasClickedNext: boolean = false
  hasClickedImage: boolean = false

  uid: string = ''
  name: string = ''
  description: string = ''
  imageId: string = ''
  imageData: string = ''
  summary: string = ''
  explicitContent: boolean = false
  keywords: string = ''

  shouldAutoTagAudio: boolean = false

  selectedPodcastUid: string = this.$store.state.modal.podcastEditor.podcastUid
  selectedEpisode: any = null
  selectedEpisodeImageData: string = ''
  enableImageGallery: boolean = false
  selectedImageSrc: string = ''
  selectedImageId: string = ''

  showAdvancedOptions: boolean = false
  isSubmitting: boolean = false

  selectedLanguage: string = 'en-US'
  languageDisabled: boolean = false

  async created() {
    const audioTrack = this.$store.state.modal.audioTrackEditor
    this.uid = audioTrack.uid
    this.name = audioTrack.name
    this.description = audioTrack.description
    this.imageId = audioTrack.imageId
    this.summary = audioTrack.summary || ''
    this.explicitContent = audioTrack.explicitContent || false
    this.keywords = audioTrack.keywords.join(', ') || ''
    if (!this.languages) await this.getLanguages()
  }

  get hasPreviewImage() {
    return this.selectedImageSrc
  }

  updateDescription(newDescription: string) {
    this.$v.description.$model = newDescription
  }

  get hasAudioUploadId() {
    return !!this.$store.getters.audioUploadId
  }

  get isUploadingAudio() {
    return this.$store.getters.audioUploadProgress !== 1
  }

  get uploadProgress() {
    return Math.round(this.$store.getters.audioUploadProgress * 100) + '%'
  }

  get canSubmit() {
    return !(this.hasAudioUploadId && this.isUploadingAudio) && this.hasPreviewImage && !this.$v.$invalid
  }

  showImageGallery() {
    if (this.enableImageGallery && !this.selectedImageSrc) {
      this.selectedImageSrc = ''
    }
    this.hasClickedImage = true
    this.enableImageGallery = !this.enableImageGallery
  }

  async handleSelectedImage(imgData: any) {
    this.hasClickedImage = true
    if (imgData.urls.full && imgData.urls.full.includes('adorilabs')) {
      this.selectedImageSrc = imgData.urls.full
      this.selectedImageId = imgData.id
    } else if (imgData.urls.full && imgData.urls.full.startsWith('https://')) {
      this.selectedImageSrc = imgData.urls.full
    } else if (this.previewImage !== '/img/Web link.c650ed21.jpg') {
      this.selectedImageSrc = this.previewImage
    } else {
      this.selectedImageSrc = '/img/Web link.c650ed21.jpg'
    }
    this.enableImageGallery = !this.enableImageGallery
  }

  // async updateAudioUids () {
  //   await this.getAudioCollectionTracks({ audioCollectionId: this.defaultAudioCollection.id, query: this.audioCollectionSearchValue, clear: true })
  //   await this.getAudioUids({
  //     paginate: true
  //   })
  // }

  async handleEditAudioTrackSubmit() {
    if (this.canSubmit) {
      this.isSubmitting = true

      try {
        let payload: any = {
          name: this.name,
          description: this.description,
          summary: this.summary,
          language: this.selectedLanguage,
        }

        if (this.showAdvancedOptions) {
          payload = {
            ...payload,
            explicitContent: this.explicitContent,
            keywords: this.keywordStringToArray(this.keywords),
          }
        }

        let imageId
        if (this.selectedImageSrc && this.selectedImageSrc.includes('adorilabs')) {
          if (this.selectedImageId) {
            payload.imageId = this.selectedImageId
          }
        } else if (this.selectedImageSrc && this.selectedImageSrc.startsWith('https://')) {
          const img: any = await AdoriService.uploadImage(this.networkId, {
            url: this.selectedImageSrc,
          })
          payload.imageId = img.id
        } else {
          const img: any = await AdoriService.uploadImage(this.networkId, this.selectedFile)
          payload.imageId = img.id
        }

        // if (this.imageData) {
        //   const image = this.$store.getters.selectedFile
        //   payload.imageId = await AdoriService.uploadImage(this.networkId, image)
        //     .then((response: any) => response.id)
        // }

        await AdoriService.updateUpload(this.networkId, this.$store.getters.audioUploadId)
        const poll = setInterval(async () => {
          const res: any = await AdoriService.getUploadStatus(this.networkId, this.$store.getters.audioUploadId)

          if (res.processingStatus === uploadStatus.FINISHED) {
            clearInterval(poll)
            const audioTrack = await this.$store.dispatch('createAudioTrack', payload)
            if (this.shouldAutoTagAudio) {
              await this.$store.dispatch('addAudioToAutoProcessList', {
                audioUid: audioTrack.uid,
                addTags: 'ADD',
              })
            }
            await this.getAudioUids({
              clear: true,
            })
            await this.$store.dispatch('clearAudioUploader')
            // await this.updateAudioUids()
            this.addAudioUid(audioTrack.uid)
            this.isSubmitting = false
            this.$store.dispatch('closeModal')
          }
          if (res.processingStatus === uploadStatus.FAILED) {
            clearInterval(poll)
            this.$store.dispatch('pushNotification', {
              text: 'Upload Failed',
              type: 'ERROR',
            })
            this.isSubmitting = false
          }
        }, 5000)
      } catch (error) {
        this.isSubmitting = false
      }
    } else {
      this.hasClickedNext = true
    }
  }

  keywordStringToArray(keywordString: string) {
    if (keywordString === '') return []
    return keywordString.split(',').map((keyword: string) => keyword.trim())
  }
}
