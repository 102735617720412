import store from '@/store'
import Vue from 'vue'
import { StoreOptions, GetterTree, MutationTree, ActionTree } from 'vuex'
import AdoriService from '@/services/adori'

const state: any = {
  tagCollections: {},
}

const initialState: any = {
  ...JSON.parse(JSON.stringify(state)),
}

const getters: GetterTree<any, any> = {
  tagCollections: (state) => state.tagCollections,
}

const mutations: MutationTree<any> = {
  resetState(state) {
    Object.keys(state).forEach((key: any) => {
      Vue.set(state, key, initialState[key])
    })
  },

  setTagCollections(state, tagCollections) {
    Vue.set(state, 'tagCollections', tagCollections)
  },

  addTagCollection(state, { collectionName, tagIds }) {
    Vue.set(state.tagCollections, collectionName, tagIds)
  },

  removeTagCollection(state, { collectionName }) {
    Vue.delete(state.tagCollections, collectionName)
  },

  renameTagCollection(state, { oldCollectionName, newCollectionName }) {
    Vue.set(state.tagCollections, newCollectionName, state.tagCollections[oldCollectionName])
    Vue.delete(state.tagCollections, oldCollectionName)
  },

  addTagsToTagCollection(state, { collectionName, tagIds }) {
    const tagIdsInCollection = state.tagCollections[collectionName]
    Vue.set(state.tagCollections, collectionName, [
      ...tagIds.filter((id: string) => tagIdsInCollection.indexOf(id) === -1),
      ...tagIdsInCollection,
    ])
  },

  removeTagsFromTagCollection(state, { collectionName, tagIds }) {
    const tagIdsInCollection = state.tagCollections[collectionName]
    if (tagIds && tagIdsInCollection) {
      tagIds.forEach((id: string) => {
        const index = tagIdsInCollection.indexOf(id)
        if (index !== -1) {
          Vue.delete(tagIdsInCollection, index)
        }
      })
    }
  },

  deleteTagFromTagCollections(state, tagId: string) {
    Object.keys(state.tagCollections).forEach(async (collectionName) => {
      const tagIdsInCollection = state.tagCollections[collectionName]
      const index = tagIdsInCollection.indexOf(tagId)
      if (index !== -1) {
        Vue.delete(tagIdsInCollection, index)
        await store.dispatch('uploadNetworkState')
      }
    })
  },
}

const actions: ActionTree<any, any> = {
  resetNetworkStateState(context) {
    context.commit('resetState')
  },

  async getNetworkState(context) {
    const networkId = context.getters.networkId
    const networkState: any = await AdoriService.fetchNetworkState(networkId)
    const { tagCollections } = networkState
    context.commit('setTagCollections', tagCollections || {})
  },

  async uploadNetworkState(context) {
    const networkId = context.getters.networkId
    const tagCollections = context.getters.tagCollections
    await AdoriService.updateNetworkState(networkId, {
      tagCollections,
    })
  },

  async addTagCollection(context, data) {
    context.commit('addTagCollection', data)
    await context.dispatch('uploadNetworkState')
  },

  async removeTagCollection(context, data) {
    context.commit('removeTagCollection', data)
    await context.dispatch('uploadNetworkState')
  },

  async renameTagCollection(context, data) {
    context.commit('renameTagCollection', data)
    await context.dispatch('uploadNetworkState')
  },

  async addTagsToTagCollection(context, data) {
    context.commit('addTagsToTagCollection', data)
    await context.dispatch('uploadNetworkState')
  },

  async removeTagsFromTagCollection(context, data) {
    context.commit('removeTagsFromTagCollection', data)
    await context.dispatch('uploadNetworkState')
  },

  async moveTagsBetweenTagCollections(context, data) {
    const { tagIds, fromCollectionName, toCollectionName } = data
    context.commit('removeTagsFromTagCollection', {
      collectionName: fromCollectionName,
      tagIds,
    })
    context.commit('addTagsToTagCollection', {
      collectionName: toCollectionName,
      tagIds,
    })
    await context.dispatch('uploadNetworkState')
  },

  async deleteTagFromTagCollections(context, tagId: string) {
    context.commit('deleteTagFromTagCollections', tagId)
  },
}

const tagCollections: StoreOptions<any> = {
  state,
  getters,
  mutations,
  actions,
}

export default tagCollections
