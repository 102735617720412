import store from '@/store'
import { RESOURCES, ACTIONS } from '@/constants/permissions'

// Function to check network level permissions for resource and action
function validateNetworkPermissions(resource: String, action: String) {
  const currentNetworkPermissions = store.getters.currentNetworkPermissions || []
  let allowed = false
  for (const perm of currentNetworkPermissions) {
    const permTokens = perm.split('.')
    if (
      resource.toUpperCase() === permTokens[1].toUpperCase() &&
      (action.toUpperCase() === permTokens[2].toUpperCase() || permTokens[2].toUpperCase() === 'MANAGE')
    ) {
      allowed = true
      break
    }
  }
  return allowed
}

// Function to check show level permissions for a feedUid, resource and action
// If network level permissions for resource and action exist we don't have to check show level
// If feedUid is '' check if at least one show has permissions for that resource and action
// Else look for permissions of resource and action in that feedUid

function validateNetworkShowPermissions(feedUid: String, resource: String, action: String) {
  if (validateNetworkPermissions(resource, action)) {
    return true
  }
  if (feedUid === '') {
    return validateNetworkShowPermissionsForAllShows(resource, action)
  } else {
    const currentNetworkShowPermissions = store.getters.currentNetworkShowPermissionsForShow(feedUid) || []
    return validateNetworkShowPermissionsForShow(currentNetworkShowPermissions, resource, action)
  }
}

// Function to check if at least one of the shows have permissions for that resource and action
function validateNetworkShowPermissionsForAllShows(resource: String, action: String) {
  const currentNetworkShowPerms = store.getters.currentNetworkShowPermissions
  for (const feedUid in currentNetworkShowPerms) {
    const currentNetworkShowPermissions = currentNetworkShowPerms[feedUid]
    if (validateNetworkShowPermissionsForShow(currentNetworkShowPermissions, resource, action)) {
      return true
    }
  }
  return false
}

// Function to check permissions for a resource and action for a particular feedUid show permissions
function validateNetworkShowPermissionsForShow(currentNetworkShowPermissions: any, resource: String, action: String) {
  for (const perm of currentNetworkShowPermissions) {
    const permTokens = perm.split('.')
    if (
      resource.toUpperCase() === permTokens[1].toUpperCase() &&
      (action.toUpperCase() === permTokens[2].toUpperCase() || permTokens[2].toUpperCase() === 'MANAGE')
    ) {
      return true
    }
  }
  return false
}

const permissions = {
  validateNetworkShowPermissionsForShows: (feedUids: any, resource: String, action: String) => {
    if (validateNetworkPermissions(resource, action)) {
      return true
    }
    for (const feedUid of feedUids) {
      const currentNetworkShowPermissions = store.getters.currentNetworkShowPermissionsForShow(feedUid) || []
      if (currentNetworkShowPermissions.length === 0) {
        return false
      }
      if (!validateNetworkShowPermissionsForShow(currentNetworkShowPermissions, resource, action)) {
        return false
      }
    }
    return true
  },
  // network level permissions

  // image
  isUploadImageAllowed: () => validateNetworkPermissions(RESOURCES.image, ACTIONS.manage),

  // episode
  isCreateEpisodeAllowed: () => validateNetworkPermissions(RESOURCES.episode, ACTIONS.create),

  isViewEpisodeAllowed: () => validateNetworkPermissions(RESOURCES.episode, ACTIONS.view),

  isEditEpisodeAllowed: () => validateNetworkPermissions(RESOURCES.episode, ACTIONS.edit),

  isDeleteEpisodeAllowed: () => validateNetworkPermissions(RESOURCES.episode, ACTIONS.delete),

  isInsertTagEpisodeAllowed: () => validateNetworkPermissions(RESOURCES.episode, ACTIONS.insertTag),

  isInsertAdsEpisodeAllowed: () => validateNetworkPermissions(RESOURCES.episode, ACTIONS.insertAds),

  // tags
  isViewTagAllowed: () => validateNetworkPermissions(RESOURCES.tag, ACTIONS.view),

  isCreateTagAllowed: () => validateNetworkPermissions(RESOURCES.tag, ACTIONS.create),

  isEditTagAllowed: () => validateNetworkPermissions(RESOURCES.tag, ACTIONS.edit),

  isDeleteTagAllowed: () => validateNetworkPermissions(RESOURCES.tag, ACTIONS.delete),

  // insights
  isViewInsightsAllowed: () => validateNetworkPermissions(RESOURCES.insights, ACTIONS.view),

  isExportInsightsAllowed: () => validateNetworkPermissions(RESOURCES.insights, ACTIONS.export),

  // podcast
  isCreatePodcastAllowed: () => validateNetworkPermissions(RESOURCES.show, ACTIONS.create),

  isViewPodcastAllowed: () => validateNetworkPermissions(RESOURCES.show, ACTIONS.view),

  isPublishPodcastAllowed: () => validateNetworkPermissions(RESOURCES.show, ACTIONS.publish),

  isEditPodcastAllowed: () => validateNetworkPermissions(RESOURCES.show, ACTIONS.edit),

  isDeletePodcastAllowed: () => validateNetworkPermissions(RESOURCES.show, ACTIONS.delete),

  // users
  isAddUserAllowed: () => validateNetworkPermissions(RESOURCES.users, ACTIONS.manage),

  // network meta
  isManageNetworkMetaAllowed: () => validateNetworkPermissions(RESOURCES.networkmeta, ACTIONS.manage),

  isManageNetworkBillingAllowed: () => validateNetworkPermissions(RESOURCES.billing, ACTIONS.manage),

  //   Youtube Permission

  isYoutubeLoginAllowed: () => validateNetworkPermissions(RESOURCES.integrations, ACTIONS.create),

  isYoutubeLogoutAllowed: () => validateNetworkPermissions(RESOURCES.integrations, ACTIONS.delete),

  isYoutubeShowLoginAllowed: (feedUid: string) =>
    validateNetworkShowPermissions(feedUid, RESOURCES.integrations, ACTIONS.create),

  isYoutubeShowLogoutAllowed: (feedUid: string) =>
    validateNetworkShowPermissions(feedUid, RESOURCES.integrations, ACTIONS.delete),

  // show level permissions

  // episode
  isCreateEpisodeShowAllowed: (feedUid: any) =>
    validateNetworkShowPermissions(feedUid, RESOURCES.episode, ACTIONS.create),

  isViewEpisodeShowAllowed: (feedUid: any) => validateNetworkShowPermissions(feedUid, RESOURCES.episode, ACTIONS.view),

  isEditEpisodeShowAllowed: (feedUid: any) => validateNetworkShowPermissions(feedUid, RESOURCES.episode, ACTIONS.edit),

  isDeleteEpisodeShowAllowed: (feedUid: any) =>
    validateNetworkShowPermissions(feedUid, RESOURCES.episode, ACTIONS.delete),

  isInsertTagEpisodeShowAllowed: (feedUid: any) =>
    validateNetworkShowPermissions(feedUid, RESOURCES.episode, ACTIONS.insertTag),

  isInsertAdsEpisodeShowAllowed: (feedUid: any) =>
    validateNetworkShowPermissions(feedUid, RESOURCES.episode, ACTIONS.insertAds),

  // tags
  isViewTagShowAllowed: (feedUid: any) => validateNetworkShowPermissions(feedUid, RESOURCES.tag, ACTIONS.view),

  isCreateTagShowAllowed: (feedUid: any) => validateNetworkShowPermissions(feedUid, RESOURCES.tag, ACTIONS.create),

  isEditTagShowAllowed: (feedUid: any) => validateNetworkShowPermissions(feedUid, RESOURCES.tag, ACTIONS.edit),

  isDeleteTagShowAllowed: (feedUid: any) => validateNetworkShowPermissions(feedUid, RESOURCES.tag, ACTIONS.delete),

  // insights
  isViewInsightsShowAllowed: (feedUid: any) =>
    validateNetworkShowPermissions(feedUid, RESOURCES.insights, ACTIONS.view),

  isExportInsightsShowAllowed: (feedUid: any) =>
    validateNetworkShowPermissions(feedUid, RESOURCES.insights, ACTIONS.export),

  // podcast
  isCreatePodcastShowAllowed: (feedUid: any) => validateNetworkShowPermissions(feedUid, RESOURCES.show, ACTIONS.create),

  isViewPodcastShowAllowed: (feedUid: any) => validateNetworkShowPermissions(feedUid, RESOURCES.show, ACTIONS.view),

  isPublishPodcastShowAllowed: (feedUid: any) =>
    validateNetworkShowPermissions(feedUid, RESOURCES.show, ACTIONS.publish),

  isEditPodcastShowAllowed: (feedUid: any) => validateNetworkShowPermissions(feedUid, RESOURCES.show, ACTIONS.edit),

  isDeletePodcastShowAllowed: (feedUid: any) => validateNetworkShowPermissions(feedUid, RESOURCES.show, ACTIONS.delete),

  isShowMangeAllowed: (feedUid: any) => validateNetworkShowPermissions(feedUid, RESOURCES.show, ACTIONS.manage),

  //   Campaigns
  isCampaignViewAllowed: () => validateNetworkPermissions(RESOURCES.campaigns, ACTIONS.view),

  isCampaignShowManageAllowed: (feedUid: any) =>
    validateNetworkShowPermissions(feedUid, RESOURCES.campaigns, ACTIONS.manage),

  //   Youtube
  isYoutubeConvertVideoShowEpisodeAllowed: (feedUid: any) =>
    validateNetworkShowPermissions(feedUid, RESOURCES.episode, ACTIONS.convertVideo),

  // To show publish/schedule section in episode add/edit
  // is-action-resource-{level}-allowed
  isScheduleEpisodeShowAllowed: (feedUid: any) =>
    validateNetworkShowPermissions(feedUid, RESOURCES.episode, ACTIONS.schedule),
}

export default {
  install(Vue: any, options: any) {
    Vue.prototype.$permissions = permissions
  },
}
