var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BasePane",
    {
      attrs: {
        showArrow: "",
        isScreenPane: "",
        disableClosePane: _vm.disableClosePane,
      },
    },
    [
      _c("template", { slot: "header" }, [
        _c(
          "div",
          {
            staticClass: "flex justify-between items-center flex-column mt3",
            staticStyle: { width: "25rem" },
          },
          [
            _c("div", { staticClass: "w-100 relative" }, [
              _c(
                "div",
                {
                  staticClass:
                    "w-100 pv2 mb3 br2 white fw5 f5 flex items-center",
                },
                [
                  _c("img", {
                    staticClass: "mb1",
                    staticStyle: { width: "auto", height: "30px" },
                    attrs: { src: require("@/assets/ai.svg"), alt: "" },
                  }),
                  _vm.context == "TOPIC_RECOMMENDATION"
                    ? _c("span", { staticClass: "ml2" }, [
                        _vm._v("AI Topic Recommendations"),
                      ])
                    : _vm._e(),
                  _vm.context == "AI_TITLE"
                    ? _c("span", { staticClass: "ml2" }, [
                        _vm._v("AI Generated Title"),
                      ])
                    : _vm._e(),
                  _vm.context == "AI_DESCRIPTION"
                    ? _c("span", { staticClass: "ml2" }, [
                        _vm._v("AI Generated Description"),
                      ])
                    : _vm._e(),
                  _vm.context == "AI_THUMBNAIL"
                    ? _c("span", { staticClass: "ml2" }, [
                        _vm._v("AI Generated Thumbnail"),
                      ])
                    : _vm._e(),
                  _vm.context == "AI_KEYWORDS"
                    ? _c("span", { staticClass: "ml2" }, [
                        _vm._v("AI Generated Keywords"),
                      ])
                    : _vm._e(),
                  _vm.context == "AI_MUSIC"
                    ? _c("span", { staticClass: "ml2" }, [
                        _vm._v("AI Suggested Music"),
                      ])
                    : _vm._e(),
                  ["MAIN_BLOG_IMAGE", "SCENE_BLOG_IMAGE"].includes(_vm.context)
                    ? _c("span", { staticClass: "ml2" }, [
                        _vm._v("Blog Images"),
                      ])
                    : _vm._e(),
                  _vm.context == "BLOG_TEXT"
                    ? _c("span", { staticClass: "ml2" }, [
                        _vm._v("Modify Scene's Text Using AI"),
                      ])
                    : _vm._e(),
                ]
              ),
            ]),
          ]
        ),
      ]),
      _c("template", { slot: "body" }, [
        _vm.context == "TOPIC_RECOMMENDATION"
          ? _c(
              "div",
              {
                staticClass: "light-gray mv2",
                staticStyle: { width: "25rem" },
              },
              [
                _vm._l(_vm.data, function (value, key) {
                  return _c(
                    "span",
                    {
                      key: key,
                      class: key == _vm.category ? "chip-active" : "chip",
                      on: {
                        click: function ($event) {
                          return _vm.selectCategory(key)
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "chip-text" }, [
                        _vm._v(_vm._s(key)),
                      ]),
                    ]
                  )
                }),
                _vm.category
                  ? _c(
                      "div",
                      { staticClass: "mt3" },
                      _vm._l(_vm.topic, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass:
                              "card db bg-adori-gray b--adori-gray pointer ba br2 ph3 pv3 gray f6 white w-100 mt2",
                            on: {
                              click: function ($event) {
                                return _vm.onSelectItem(item)
                              },
                            },
                          },
                          [_vm._v("\n          " + _vm._s(item) + "\n        ")]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ],
              2
            )
          : _vm._e(),
        _vm.context == "AI_TITLE"
          ? _c(
              "div",
              {
                staticClass: "light-gray mv2",
                staticStyle: { width: "25rem" },
              },
              [
                _c("div", { staticClass: "flex items-center" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedTitle,
                          expression: "selectedTitle",
                        },
                      ],
                      staticClass:
                        "bg-adori-gray white f6 w-90 br2 h2 ba b--adori-gray mt1",
                      on: {
                        keypress: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.getAiTitle.apply(null, arguments)
                        },
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.selectedTitle = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    _vm._l(_vm.titleListData, function (item, i) {
                      return _c(
                        "option",
                        { key: i, domProps: { value: item.value } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.title) +
                              "\n          "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                  _vm.isOpenAiFetching
                    ? _c("div", {
                        staticClass: "loader loader-inline v-mid pa2 ml2",
                      })
                    : _c(
                        "i",
                        {
                          staticClass:
                            "material-icons light-gray f4 v-mid pa1 pointer ml2 pa1 bg-adori-red br2",
                          on: { click: _vm.getAiTitle },
                        },
                        [_vm._v("send")]
                      ),
                ]),
                _vm.isOpenAiFetching
                  ? _c(
                      "div",
                      { staticClass: "flex justify-center mt5 relative" },
                      [
                        _c("div", { staticClass: "building-blocks mt5" }, [
                          _c("div"),
                          _c("div"),
                          _c("div"),
                          _c("div"),
                          _c("div"),
                          _c("div"),
                          _c("div"),
                        ]),
                        _c("div", { staticClass: "absolute pt5 mt5" }, [
                          _vm._v("Thinking ..."),
                        ]),
                      ]
                    )
                  : _c(
                      "div",
                      _vm._l(_vm.aiTitle, function (title, i) {
                        return _c("div", { key: i, staticClass: "relative" }, [
                          _c("textarea", {
                            staticClass:
                              "db bw0 bg-adori-light-gray br2 ph3 pv2 gray f6 white w-90 mt3 scrollbar mb2",
                            attrs: {
                              type: "text",
                              placeholder: "Generated title will show here",
                            },
                            domProps: { value: title },
                          }),
                          _c("div", { staticClass: "absolute top-0 right-1" }, [
                            _c(
                              "i",
                              {
                                staticClass:
                                  "material-icons light-gray f4 v-mid mt3 pointer",
                                attrs: { title: "Replace title" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onSelectItem({
                                      context: _vm.context,
                                      title: title,
                                    })
                                  },
                                },
                              },
                              [_vm._v("compare_arrows")]
                            ),
                          ]),
                        ])
                      }),
                      0
                    ),
              ]
            )
          : _vm._e(),
        _vm.context == "AI_DESCRIPTION"
          ? _c(
              "div",
              {
                staticClass: "light-gray mv2",
                staticStyle: { width: "25rem" },
              },
              [
                _c("div", { staticClass: "flex items-center" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedDesc,
                          expression: "selectedDesc",
                        },
                      ],
                      staticClass:
                        "bg-adori-gray white f6 w-90 br2 h2 ba b--adori-gray mt1",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.selectedDesc = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    _vm._l(_vm.descriptionListData, function (item, i) {
                      return _c(
                        "option",
                        {
                          key: i,
                          domProps: { value: item.value },
                          on: {
                            keypress: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.getAiDescription.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.title) +
                              "\n          "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                  _vm.isOpenAiFetching
                    ? _c("div", {
                        staticClass: "loader loader-inline v-mid pa2 ml3",
                      })
                    : _c(
                        "i",
                        {
                          staticClass:
                            "material-icons light-gray f4 v-mid pointer ml3 pa1 bg-adori-red br2",
                          on: { click: _vm.getAiDescription },
                        },
                        [_vm._v("send")]
                      ),
                ]),
                _vm.isOpenAiFetching
                  ? _c(
                      "div",
                      { staticClass: "flex justify-center mt5 relative" },
                      [
                        _c("div", { staticClass: "building-blocks mt5" }, [
                          _c("div"),
                          _c("div"),
                          _c("div"),
                          _c("div"),
                          _c("div"),
                          _c("div"),
                          _c("div"),
                        ]),
                        _c("div", { staticClass: "absolute pt5 mt5" }, [
                          _vm._v("Thinking ..."),
                        ]),
                      ]
                    )
                  : _c("div", { staticClass: "relative" }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.aiDescription,
                            expression: "aiDescription",
                          },
                        ],
                        staticClass:
                          "db bw0 bg-adori-light-gray br2 ph3 pv3 gray f6 white w-90 mt3 scrollbar",
                        staticStyle: { height: "67vh" },
                        attrs: {
                          type: "text",
                          placeholder: "Generated Description will show here",
                        },
                        domProps: { value: _vm.aiDescription },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.aiDescription = $event.target.value
                          },
                        },
                      }),
                      _c("div", { staticClass: "absolute top-0 right-0" }, [
                        _c(
                          "i",
                          {
                            staticClass:
                              "material-icons light-gray f4 v-mid mt3 pointer",
                            attrs: { title: "Replace Description" },
                            on: {
                              click: function ($event) {
                                return _vm.onSelectItem({
                                  context: _vm.context,
                                  description: _vm.aiDescription,
                                })
                              },
                            },
                          },
                          [_vm._v("compare_arrows")]
                        ),
                      ]),
                    ]),
              ]
            )
          : _vm._e(),
        _vm.context == "AI_THUMBNAIL"
          ? _c(
              "div",
              {
                staticClass: "light-gray mv2",
                staticStyle: { width: "25rem" },
              },
              [
                _c("div", { staticClass: "flex items-center" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedThumbnail,
                          expression: "selectedThumbnail",
                        },
                      ],
                      staticClass:
                        "bg-adori-gray white f6 w-90 br2 h2 ba b--adori-gray mt1",
                      on: {
                        keypress: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.getAiPrompt.apply(null, arguments)
                        },
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.selectedThumbnail = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    _vm._l(_vm.thumbNailList, function (item, i) {
                      return _c(
                        "option",
                        { key: i, domProps: { value: item.value } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.title) +
                              "\n          "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                  _vm.isOpenAiFetching
                    ? _c("div", {
                        staticClass: "loader loader-inline v-mid pa2 ml2",
                      })
                    : _c(
                        "i",
                        {
                          staticClass:
                            "material-icons light-gray f4 v-mid pointer ml2 pa1 bg-adori-red br2",
                          on: { click: _vm.getAiPrompt },
                        },
                        [_vm._v("send")]
                      ),
                ]),
                _vm.isOpenAiFetching || _vm.isStabilityLoading
                  ? _c(
                      "div",
                      { staticClass: "flex justify-center mt5 relative" },
                      [
                        _c("div", { staticClass: "building-blocks mt5" }, [
                          _c("div"),
                          _c("div"),
                          _c("div"),
                          _c("div"),
                          _c("div"),
                          _c("div"),
                          _c("div"),
                        ]),
                        _c("div", { staticClass: "absolute pt5 mt5" }, [
                          _vm._v("Generating Ai images ..."),
                        ]),
                      ]
                    )
                  : _c("div", { staticClass: "flex flex-wrap relative mt4" }, [
                      _vm.aiImgList
                        ? _c("img", {
                            staticClass: "pa2 pointer grow",
                            attrs: {
                              title: "select image",
                              src: _vm.aiImgList,
                              height: "150",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onSelectItem({
                                  context: _vm.context,
                                  imageUrl: _vm.aiImgList,
                                })
                              },
                            },
                          })
                        : _vm._e(),
                    ]),
              ]
            )
          : _vm._e(),
        _vm.context == "AI_KEYWORDS"
          ? _c(
              "div",
              {
                staticClass: "light-gray mv2",
                staticStyle: { width: "25rem" },
              },
              [
                _c("div", { staticClass: "flex items-center" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedKeywords,
                          expression: "selectedKeywords",
                        },
                      ],
                      staticClass:
                        "bg-adori-gray white f6 w-90 br2 h2 ba b--adori-gray mt1",
                      on: {
                        keypress: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.getAiPrompt.apply(null, arguments)
                        },
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.selectedKeywords = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    _vm._l(_vm.keyWordsList, function (item, i) {
                      return _c(
                        "option",
                        { key: i, domProps: { value: item.value } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.title) +
                              "\n          "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                  _vm.isOpenAiFetching
                    ? _c("div", {
                        staticClass: "loader loader-inline v-mid pa2 ml2",
                      })
                    : _c(
                        "i",
                        {
                          staticClass:
                            "material-icons light-gray f4 v-mid pointer ml2 pa1 bg-adori-red br2",
                          on: { click: _vm.getAiKeywords },
                        },
                        [_vm._v("send")]
                      ),
                ]),
                _vm.isOpenAiFetching
                  ? _c(
                      "div",
                      { staticClass: "flex justify-center mt5 relative" },
                      [
                        _c("div", { staticClass: "building-blocks mt5" }, [
                          _c("div"),
                          _c("div"),
                          _c("div"),
                          _c("div"),
                          _c("div"),
                          _c("div"),
                          _c("div"),
                        ]),
                        _c("div", { staticClass: "absolute pt5 mt5" }, [
                          _vm._v("Generating Ai keywords ..."),
                        ]),
                      ]
                    )
                  : _c("div", { staticClass: "mt4" }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.aiKeywords,
                            expression: "aiKeywords",
                          },
                        ],
                        staticClass:
                          "db bw0 bg-adori-light-gray br2 ph3 pv3 gray f6 white w-90 mt3 scrollbar",
                        staticStyle: { "min-height": "200px" },
                        attrs: {
                          type: "text",
                          placeholder: "Generated Description will show here",
                        },
                        domProps: { value: _vm.aiKeywords },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.aiKeywords = $event.target.value
                          },
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "keywords mt3 w-100" },
                        [
                          _c("BaseButtonRed", {
                            staticClass: "w-90",
                            attrs: {
                              text: "Replace these keywords",
                              disabled: _vm.aiKeywords.length === 0,
                              onClick: function () {
                                _vm.onSelectItem({
                                  context: _vm.context,
                                  keywords: _vm.aiKeywords,
                                })
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
              ]
            )
          : _vm._e(),
        ["MAIN_BLOG_IMAGE", "SCENE_BLOG_IMAGE"].includes(_vm.context)
          ? _c(
              "div",
              {
                staticClass: "light-gray mv2",
                staticStyle: { width: "25rem" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "ba pv1 br4 b--transparent bg-adori-light-gray mt2 flex justify-center items-center mw-fit",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "pv1 ph2 silver link pointer",
                        class:
                          _vm.selectedBlogTab === "BLOG"
                            ? "ba br4 b--transparent bg-black-80 light-gray"
                            : "",
                      },
                      [
                        _c("input", {
                          staticClass: "input-reset",
                          attrs: { type: "radio" },
                          domProps: { checked: _vm.selectedBlogTab === "BLOG" },
                          on: {
                            click: function ($event) {
                              _vm.selectedBlogTab = "BLOG"
                            },
                          },
                        }),
                        _vm._v("Blog Images"),
                      ]
                    ),
                    _c(
                      "label",
                      {
                        staticClass: "pv1 ph2 silver link pointer",
                        class:
                          _vm.selectedBlogTab === "IMAGES"
                            ? "ba br4 b--transparent bg-black-80 light-gray"
                            : "",
                      },
                      [
                        _c("input", {
                          staticClass: "input-reset",
                          attrs: { type: "radio" },
                          domProps: {
                            checked: _vm.selectedBlogTab === "IMAGES",
                          },
                          on: {
                            click: function ($event) {
                              _vm.selectedBlogTab = "IMAGES"
                            },
                          },
                        }),
                        _vm._v("Search Images"),
                      ]
                    ),
                    _c(
                      "label",
                      {
                        staticClass: "pv1 ph2 silver link pointer",
                        class:
                          _vm.selectedBlogTab === "GIFS"
                            ? "ba br4 b--transparent bg-black-80 light-gray"
                            : "",
                      },
                      [
                        _c("input", {
                          staticClass: "input-reset",
                          attrs: { type: "radio" },
                          domProps: { checked: _vm.selectedBlogTab === "GIFS" },
                          on: {
                            click: function ($event) {
                              _vm.selectedBlogTab = "GIFS"
                            },
                          },
                        }),
                        _vm._v("Gifs"),
                      ]
                    ),
                  ]
                ),
                _vm.selectedBlogTab === "IMAGES"
                  ? _c("div", { staticClass: "mt3" }, [
                      _c("div", { staticClass: "flex items-center W-100" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "ba bw1 br4 b--transparent bg-black-20 flex items-center",
                          },
                          _vm._l(_vm.searchLibraries, function (item) {
                            return _c(
                              "label",
                              {
                                staticClass:
                                  "f6 pv1 ph2 silver link pointer mr1",
                                class:
                                  _vm.selectedImageTab === item.LibraryName
                                    ? "ba br4 b--transparent bg-black-80 light-gray"
                                    : "",
                              },
                              [
                                _c("input", {
                                  staticClass: "input-reset",
                                  attrs: { type: "radio" },
                                  domProps: {
                                    checked:
                                      _vm.selectedImageTab === item.LibraryName,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.selectedImageTab = item.LibraryName
                                    },
                                  },
                                }),
                                _vm._v(_vm._s(item.name)),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "sect flex justify-between w-100",
                          staticStyle: { "min-height": "470px" },
                        },
                        [
                          _c("div", { staticClass: "w-90" }, [
                            _vm.selectedImageTab !== "MY_LIBRARY"
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex justify-left items-center pt3",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "ba b--adori-gray br2 ml2 mt2 bg-dark-gray relative",
                                        class: _vm.searchQuery
                                          ? "w-100"
                                          : "w-75",
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.searchQuery,
                                              expression: "searchQuery",
                                            },
                                          ],
                                          staticClass:
                                            "input-reset bg-dark-gray light-gray bn br2 pa2 pr4 dib w-100 mr2",
                                          attrs: {
                                            type: "text",
                                            placeholder: "Search free images",
                                          },
                                          domProps: { value: _vm.searchQuery },
                                          on: {
                                            input: [
                                              function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.searchQuery =
                                                  $event.target.value
                                              },
                                              function ($event) {
                                                return _vm.debouncedSearch()
                                              },
                                            ],
                                          },
                                        }),
                                        !_vm.searchQuery
                                          ? _c(
                                              "i",
                                              {
                                                staticClass:
                                                  "material-icons silver absolute",
                                                staticStyle: {
                                                  top: "5px",
                                                  right: "8px",
                                                },
                                              },
                                              [_vm._v("search")]
                                            )
                                          : _c(
                                              "i",
                                              {
                                                staticClass:
                                                  "material-icons silver absolute pointer dim",
                                                staticStyle: {
                                                  top: "6px",
                                                  right: "2px",
                                                },
                                                on: {
                                                  click: _vm.clearSearchQuery,
                                                },
                                              },
                                              [_vm._v("close")]
                                            ),
                                      ]
                                    ),
                                    !_vm.searchQuery
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "bg-adori-red pa2 mt2 ml2 br2 pointer",
                                            on: {
                                              click: _vm.getAiImageSearchText,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                Use AI\n              "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                            _vm.isLibImageLoading ||
                            _vm.isPublicImageLoading ||
                            _vm.isOpenAiFetching
                              ? _c(
                                  "div",
                                  { staticClass: "flex justify-center mt5" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "building-blocks mt5" },
                                      [
                                        _c("div"),
                                        _c("div"),
                                        _c("div"),
                                        _c("div"),
                                        _c("div"),
                                        _c("div"),
                                        _c("div"),
                                      ]
                                    ),
                                  ]
                                )
                              : _c("div", [
                                  _vm.selectedImageTab !== "MY_LIBRARY" &&
                                  !_vm.imageSearchText
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-100 flex flex-column justify-center items-center",
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: require("@/assets/imgae-search.svg"),
                                              alt: "",
                                              width: "250",
                                              height: "250",
                                            },
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "f5 silver" },
                                            [
                                              _vm._v(
                                                "Oops! No Images found.. Upload Images"
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        [
                                          _vm.selectedImageTab == "MY_LIBRARY"
                                            ? _c(
                                                "div",
                                                {
                                                  on: {
                                                    dragover: function (
                                                      $event
                                                    ) {
                                                      $event.preventDefault()
                                                    },
                                                    drop: function ($event) {
                                                      $event.preventDefault()
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      class: {
                                                        "dark-fill":
                                                          _vm.bgDragColor,
                                                      },
                                                      on: {
                                                        dragover: function () {
                                                          return (_vm.bgDragColor = true)
                                                        },
                                                        dragleave: function () {
                                                          return (_vm.bgDragColor = false)
                                                        },
                                                        click:
                                                          _vm.imageUploader,
                                                        drop: function (
                                                          $event
                                                        ) {
                                                          return _vm.dropImageFiles(
                                                            $event
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass: "upload",
                                                        },
                                                        [
                                                          _vm.uploadingImage
                                                            ? _c("div", [
                                                                _c("img", {
                                                                  staticClass:
                                                                    "h70",
                                                                  attrs: {
                                                                    src: require("@/assets/spinner.svg"),
                                                                  },
                                                                }),
                                                                _c("div", [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Uploading Image"
                                                                    ),
                                                                  ]),
                                                                ]),
                                                              ])
                                                            : _c("div", [
                                                                _c("img", {
                                                                  staticClass:
                                                                    "gallery",
                                                                  attrs: {
                                                                    width:
                                                                      "24px",
                                                                    height:
                                                                      "24px",
                                                                    alt: "placeholder",
                                                                    src: require("@/assets/audiogram/gallery.png"),
                                                                  },
                                                                }),
                                                                _c("div", [
                                                                  _c("strong", [
                                                                    _vm._v(
                                                                      "Choose Image"
                                                                    ),
                                                                  ]),
                                                                  _vm._v(
                                                                    " or drag it here"
                                                                  ),
                                                                ]),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Supports: JPG, PNG"
                                                                  ),
                                                                ]),
                                                              ]),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.imageList.length > 0
                                            ? _c("ImagesList", {
                                                attrs: {
                                                  selectedRangeStart:
                                                    _vm.selectedRangeStart,
                                                  selectedRangeEnd:
                                                    _vm.selectedRangeEnd,
                                                  totalImages:
                                                    _vm.totalImageCount,
                                                  previewImageSrc:
                                                    _vm.selectedSceneImage,
                                                  imageSources: _vm.imageList,
                                                },
                                                on: {
                                                  handleNavigation:
                                                    _vm.handleNavigation,
                                                  handleSelectImage:
                                                    _vm.handleSelectImage,
                                                  viewImages: _vm.viewImages,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                ]),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm.selectedBlogTab === "BLOG"
                  ? _c(
                      "div",
                      {
                        staticClass: "mt3 ph2",
                        class: _vm.data.length && "masonry-grid",
                      },
                      _vm._l(_vm.data, function (img) {
                        return _c(
                          "div",
                          { staticClass: "relative overflow-x-hidden" },
                          [
                            _c(
                              "div",
                              { staticClass: "img-opt relative hide-child" },
                              [
                                _c("img", {
                                  staticClass: "masonry-content ba pointer br2",
                                  class:
                                    _vm.selectedSceneImage === img
                                      ? "ba b--adori-red"
                                      : "",
                                  attrs: { src: img, download: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onSelectItem({
                                        context: _vm.context,
                                        image: img,
                                        scene: _vm.scene,
                                      })
                                    },
                                  },
                                }),
                                _c(
                                  "i",
                                  {
                                    staticClass:
                                      "absolute right-1 bg-black-50 material-icons light-gray f4 v-top pa1 child br3 pointer",
                                    staticStyle: { top: "1px", right: "1px" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$emit("viewImages", img)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              visibility\n            "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _vm.selectedBlogTab === "GIFS"
                  ? _c(
                      "div",
                      { staticClass: "mt3 ph2" },
                      [
                        _c("GifGallery", {
                          attrs: { source: "AI-PANEL" },
                          on: {
                            "load-image": _vm.handleSelectImage,
                            viewImages: _vm.viewImages,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            )
          : _vm._e(),
        _vm.context == "BLOG_TEXT"
          ? _c(
              "div",
              {
                staticClass: "light-gray mv2",
                staticStyle: { width: "25rem" },
              },
              [
                _vm._l(_vm.SceneTextModification, function (item) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.selectedSceneCategory === "AI_TEXT",
                          expression: "selectedSceneCategory === 'AI_TEXT'",
                        },
                      ],
                      staticClass:
                        "card db2 bg-adori-gray b--adori-gray pointer ba br2 ph3 pv3 gray f6 white w-100 mt2",
                      on: {
                        click: function ($event) {
                          return _vm.ModifySceneText(item)
                        },
                      },
                    },
                    [
                      _c(
                        "i",
                        {
                          staticClass: "material-icons light-gray f4 v-mid mr2",
                        },
                        [_vm._v(_vm._s(item.icon))]
                      ),
                      _vm._v("\n        " + _vm._s(item.text) + "\n      "),
                    ]
                  )
                }),
                _vm._l(
                  [
                    {
                      text: "Generate Image for this Scene",
                      icon: "auto_awesome",
                      isAiImage: true,
                    },
                  ],
                  function (item, index) {
                    return _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.selectedSceneCategory === "AI_IMAGE",
                            expression: "selectedSceneCategory === 'AI_IMAGE'",
                          },
                        ],
                        key: index,
                        staticClass:
                          "card db2 bg-adori-gray b--adori-gray pointer ba br2 ph3 pv3 gray f6 white w-100 mt2",
                        on: {
                          click: function ($event) {
                            return _vm.ModifySceneText(item)
                          },
                        },
                      },
                      [
                        _c(
                          "i",
                          {
                            staticClass:
                              "material-icons light-gray f4 v-mid mr2",
                          },
                          [_vm._v(_vm._s(item.icon))]
                        ),
                        _vm._v("\n        " + _vm._s(item.text) + "\n      "),
                      ]
                    )
                  }
                ),
                _vm.isOpenAiFetching || _vm.isStabilityLoading
                  ? _c(
                      "div",
                      { staticClass: "flex justify-center mt5 relative" },
                      [
                        _c("div", { staticClass: "building-blocks mt5" }, [
                          _c("div"),
                          _c("div"),
                          _c("div"),
                          _c("div"),
                          _c("div"),
                          _c("div"),
                          _c("div"),
                        ]),
                        _c("div", { staticClass: "absolute pt5 mt5" }, [
                          _vm._v("Thinking ..."),
                        ]),
                      ]
                    )
                  : _vm.showAISceneText
                  ? _c("div", { staticClass: "relative" }, [
                      !_vm.selectedModification.isAiImage
                        ? _c("div", [
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.sceneAiText,
                                  expression: "sceneAiText",
                                },
                              ],
                              staticClass:
                                "db bw0 bg-adori-light-gray br2 ph3 pv3 gray f6 white w-90 mt3 scrollbar",
                              staticStyle: { height: "67vh" },
                              attrs: {
                                type: "text",
                                placeholder: "Generated text will show here",
                              },
                              domProps: { value: _vm.sceneAiText },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.sceneAiText = $event.target.value
                                },
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "absolute top-0 right-0" },
                              [
                                _c(
                                  "i",
                                  {
                                    staticClass:
                                      "material-icons light-gray f4 v-mid mt3 pointer",
                                    attrs: { title: "Replace Scene text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onSelectItem({
                                          context: _vm.context,
                                          text: _vm.sceneAiText,
                                          scene: _vm.scene,
                                        })
                                      },
                                    },
                                  },
                                  [_vm._v("compare_arrows")]
                                ),
                              ]
                            ),
                          ])
                        : _vm.selectedSceneCategory === "AI_IMAGE"
                        ? _c(
                            "div",
                            { staticClass: "flex flex-column relative" },
                            [
                              _c(
                                "BaseNewTooltip",
                                {
                                  attrs: {
                                    text: _vm.isPremium
                                      ? ""
                                      : "Upgrade for custom Image Prompt",
                                  },
                                },
                                [
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.searchQuery,
                                        expression: "searchQuery",
                                      },
                                    ],
                                    staticClass:
                                      "input-reset bg-adori-gray light-gray h2 bn pv2 br2 pl2 pr4 dib w-100 f6 mt2 scrollbar",
                                    staticStyle: { height: "10vh" },
                                    attrs: {
                                      type: "text",
                                      placeholder: "Change Image Prompt",
                                      disabled: !_vm.isPremium,
                                    },
                                    domProps: { value: _vm.searchQuery },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.searchQuery = $event.target.value
                                      },
                                    },
                                  }),
                                ]
                              ),
                              !_vm.isPremium
                                ? _c(
                                    "i",
                                    {
                                      staticClass:
                                        "absolute material-icons light-gray f4 v-mid mr2 right-0",
                                      staticStyle: { top: "50px" },
                                    },
                                    [_vm._v("lock")]
                                  )
                                : _vm._e(),
                              _vm.isPremium
                                ? _c("BaseButtonRed", {
                                    staticClass: "mt2 z-999",
                                    attrs: {
                                      text: "Generate",
                                      onClick: _vm.searchImages,
                                    },
                                  })
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "flex flex-wrap relative mt4" },
                                _vm._l(_vm.aiImgList, function (img) {
                                  return _c("img", {
                                    staticClass: "pa2 pointer grow",
                                    attrs: {
                                      title: "select image",
                                      src: img,
                                      height: "150",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onSelectItem({
                                          context: "SCENE_BLOG_IMAGE",
                                          image: img,
                                          scene: _vm.scene,
                                        })
                                      },
                                    },
                                  })
                                }),
                                0
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ],
              2
            )
          : _vm._e(),
        _vm.context == "AI_MUSIC"
          ? _c(
              "div",
              {
                staticClass: "light-gray mv2",
                staticStyle: { width: "25rem" },
              },
              [
                _c("div", [_vm._v("Curated music for your video")]),
                _vm._l(_vm.suggestedMusic, function (music) {
                  return _c("div", [
                    _vm._v("\n        " + _vm._s(music) + "\n      "),
                  ])
                }),
              ],
              2
            )
          : _vm._e(),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }