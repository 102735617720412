















import { Component, Prop, Vue, Mixins } from 'vue-property-decorator'
import { mixin as clickaway } from 'vue-clickaway'

@Component
export default class BaseTooltip extends Mixins(clickaway) {
  @Prop(String) info!: string

  showInfo: boolean = false

  toggleInfo(): void {
    this.showInfo = !this.showInfo
  }

  closeInfo(): void {
    this.showInfo = false
  }
}
