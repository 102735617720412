import tags_photo from '@/assets/tags/photo.jpg'
import tags_poll from '@/assets/tags/poll.jpg'
import tags_Web_link from '@/assets/tags/Web link.jpg'
import tags_call from '@/assets/tags/call.jpg'
import tags_location from '@/assets/tags/location.jpg'
import tags_message from '@/assets/tags/message.jpg'
import tags_audio from '@/assets/tags/audio.jpg'
import tags_buy from '@/assets/tags/buy.jpg'
import tags_artag from '@/assets/tags/artag.jpg'
import tags_display_ad from '@/assets/tags/display_ad.jpg'
import tags_video_ad from '@/assets/tags/video_ad.jpg'

import icons_artag from '@/assets/icons/artag.svg'
import icons_artag_active from '@/assets/icons/artag_active.svg'
import icons_audio from '@/assets/icons/audio.svg'
import icons_audio_active from '@/assets/icons/audio_active.svg'
import icons_buy from '@/assets/icons/buy.svg'
import icons_buy_active from '@/assets/icons/buy_active.svg'
import icons_buy_new from '@/assets/icons/buy_new.svg'
import icons_call from '@/assets/icons/call.svg'
import icons_call_active from '@/assets/icons/call_active.svg'
import icons_campaign_icon from '@/assets/icons/campaign_icon.svg'
import icons_campaign_icon_active from '@/assets/icons/campaign_icon_active.svg'
import icons_card from '@/assets/icons/card.svg'
import icons_displayicon from '@/assets/icons/displayicon.svg'
import icons_displayicon_active from '@/assets/icons/displayicon_active.svg'
import icons_link from '@/assets/icons/link.svg'
import icons_link_active from '@/assets/icons/link_active.svg'
import icons_location_on from '@/assets/icons/location_on.svg'
import icons_location_on_active from '@/assets/icons/location_on_active.svg'
import icons_message from '@/assets/icons/message.svg'
import icons_message_active from '@/assets/icons/message_active.svg'
import icons_next_active from '@/assets/icons/next_active.svg'
import icons_next_inactive from '@/assets/icons/next_inactive.svg'
import icons_photo from '@/assets/icons/photo.svg'
import icons_photo_active from '@/assets/icons/photo_active.svg'
import icons_poll from '@/assets/icons/poll.svg'
import icons_poll_active from '@/assets/icons/poll_active.svg'
import icons_previous_active from '@/assets/icons/previous_active.svg'
import icons_previous_inactive from '@/assets/icons/previous_inactive.svg'
import icons_videoicon from '@/assets/icons/videoicon.svg'
import icons_videoicon_active from '@/assets/icons/videoicon_active.svg'

// TODO: automate some time after moving to vite
export const TagImages: any = {
  photo: tags_photo,
  poll: tags_poll,
  'Web link': tags_Web_link,
  call: tags_call,
  location: tags_location,
  message: tags_message,
  audio: tags_audio,
  buy: tags_buy,
  artag: tags_artag,
  display_ad: tags_display_ad,
  video_ad: tags_video_ad,
}

//TODO: automate some time after moving to vite
export const IconImages: any = {
  artag: icons_artag,
  artag_active: icons_artag_active,
  audio: icons_audio,
  audio_active: icons_audio_active,
  buy: icons_buy,
  buy_active: icons_buy_active,
  buy_new: icons_buy_new,
  call: icons_call,
  call_active: icons_call_active,
  campaign_icon: icons_campaign_icon,
  campaign_icon_active: icons_campaign_icon_active,
  card: icons_card,
  displayicon: icons_displayicon,
  displayicon_active: icons_displayicon_active,
  link: icons_link,
  link_active: icons_link_active,
  location_on: icons_location_on,
  location_on_active: icons_location_on_active,
  message: icons_message,
  message_active: icons_message_active,
  next_active: icons_next_active,
  next_inactive: icons_next_inactive,
  photo: icons_photo,
  photo_active: icons_photo_active,
  poll: icons_poll,
  poll_active: icons_poll_active,
  previous_active: icons_previous_active,
  previous_inactive: icons_previous_inactive,
  videoicon: icons_videoicon,
  videoicon_active: icons_videoicon_active,
}
