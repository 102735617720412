import moment from 'moment'

export function secondsToHMS(secs: number) {
  var h = Math.floor(secs / 3600)
  var m = Math.floor((secs % 3600) / 60)
  var s = Math.floor((secs % 3600) % 60)
  return (h ? h + 'h ' : '') + (m ? m + 'm ' : '') + (s ? s + 's' : '')
}

export function formatSelected(selectedDate: string) {
  let year = parseInt(selectedDate.split(' ')[3])
  let month = parseInt(moment().month(selectedDate.split(' ')[1]).format('M')) - 1
  let day = parseInt(selectedDate.split(' ')[2])
  let time = selectedDate.split(' ')[12]
  let hr = parseInt(time.split(':')[0])
  let min = parseInt(time.split(':')[1])
  let sec = parseInt(time.split(':')[2])
  return new Date(year, month, day, hr, min, sec)
}

export function formatToday(selectedDate: string, tz: string) {
  let localOffset = selectedDate.split(' ')[13]
  let localDiff = localOffset[3]
  let localHourDelta = parseInt(localOffset.substr(4, 2))
  let localMinuteDelta = parseInt(localOffset.substr(6, 2))
  let selectedOffset = tz.substr(5, 5)
  let selectedDiff = tz[4]
  if (localDiff === '+' && selectedDiff === '-') {
    return moment()
      .utc()
      .subtract({
        hours: parseInt(selectedOffset.split(':')[0]) + localHourDelta,
        minutes: parseInt(selectedOffset.split(':')[1]) + localMinuteDelta,
      })
      .toDate()
  } else if (localDiff === '-' && selectedDiff === '+') {
    return moment()
      .utc()
      .add({
        hours: parseInt(selectedOffset.split(':')[0]) + localHourDelta,
        minutes: parseInt(selectedOffset.split(':')[1]) + localMinuteDelta,
      })
      .toDate()
  } else if (localDiff === '+' && selectedDiff === '+') {
    return moment()
      .utc()
      .subtract({
        hours: localHourDelta,
        minutes: localMinuteDelta,
      })
      .add({
        hours: parseInt(selectedOffset.split(':')[0]),
        minutes: parseInt(selectedOffset.split(':')[1]),
      })
      .toDate()
  } else {
    return moment()
      .utc()
      .add({
        hours: localHourDelta,
        minutes: localMinuteDelta,
      })
      .subtract({
        hours: parseInt(selectedOffset.split(':')[0]),
        minutes: parseInt(selectedOffset.split(':')[1]),
      })
      .toDate()
  }
}

export const timezoneList = [
  { title: '(GMT-10:00) Honolulu', value: '(GMT-10:00) Honolulu' },
  { title: '(GMT-08:00) Anchorage', value: '(GMT-08:00) Anchorage' },
  { title: '(GMT-08:00) Juneau', value: '(GMT-08:00) Juneau' },
  { title: '(GMT-07:00) Hermosillo', value: '(GMT-07:00) Hermosillo' },
  { title: '(GMT-07:00) Los Angeles', value: '(GMT-07:00) Los Angeles' },
  { title: '(GMT-07:00) Phoenix', value: '(GMT-07:00) Phoenix' },
  { title: '(GMT-07:00) Tijuana', value: '(GMT-07:00) Tijuana' },
  { title: '(GMT-07:00) Vancouver', value: '(GMT-07:00) Vancouver' },
  { title: '(GMT-06:00) Denver', value: '(GMT-06:00) Denver' },
  { title: '(GMT-06:00) Edmonton', value: '(GMT-06:00) Edmonton' },
  { title: '(GMT-06:00) Mexico City', value: '(GMT-06:00) Mexico City' },
  { title: '(GMT-05:00) Bogota', value: '(GMT-05:00) Bogota' },
  { title: '(GMT-05:00) Chicago', value: '(GMT-05:00) Chicago' },
  { title: '(GMT-05:00) Easter', value: '(GMT-05:00) Easter' },
  { title: '(GMT-05:00) Rio Branco', value: '(GMT-05:00) Rio Branco' },
  { title: '(GMT-05:00) Winnipeg', value: '(GMT-05:00) Winnipeg' },
  { title: '(GMT-04:00) Detroit', value: '(GMT-04:00) Detroit' },
  { title: '(GMT-04:00) Manaus', value: '(GMT-04:00) Manaus' },
  { title: '(GMT-04:00) Montreal', value: ' (GMT-04:00) Montreal' },
  { title: '(GMT-04:00) New York', value: '(GMT-04:00) New York' },
  { title: '(GMT-04:00) Toronto', value: '(GMT-04:00) Toronto' },
  { title: '(GMT-03:00) Bahia', value: '(GMT-03:00) Bahia' },
  { title: '(GMT-03:00) Belem', value: ' (GMT-03:00) Belem' },
  { title: '(GMT-03:00) Buenos Aires', value: '(GMT-03:00) Buenos Aires' },
  { title: '(GMT-03:00) Halifax', value: '(GMT-03:00) Halifax' },
  { title: '(GMT-03:00) Recife', value: '(GMT-03:00) Recife' },
  { title: '(GMT-03:00) Santiago', value: '(GMT-03:00) Santiago' },
  { title: '(GMT-03:00) Sao Paulo', value: ' (GMT-03:00) Sao Paulo' },
  { title: '(GMT-02:30) St. John’s', value: '(GMT-02:30) St. John’s' },
  { title: '(GMT-02:00) Noronha', value: '(GMT-02:00) Noronha' },
  { title: '(GMT+00:00) Canary', value: '(GMT+00:00) Canary' },
  { title: '(GMT+00:00) London', value: '(GMT+00:00) London' },
  { title: '(GMT+01:00) Dublin', value: '(GMT+01:00) Dublin' },
  { title: '(GMT+01:00) Algiers', value: ' (GMT+01:00) Algiers' },
  { title: '(GMT+01:00) Amsterdam', value: ' (GMT+01:00) Amsterdam' },
  { title: '(GMT+01:00) Berlin', value: ' (GMT+01:00) Berlin' },
  { title: '(GMT+01:00) Brussels', value: '(GMT+01:00) Brussels' },
  { title: '(GMT+01:00) Budapest', value: '(GMT+01:00) Budapest' },
  { title: '(GMT+01:00) Casablanca', value: '(GMT+01:00) Casablanca' },
  { title: '(GMT+01:00) Lagos', value: '(GMT+01:00) Lagos' },
  { title: '(GMT+01:00) Madrid', value: '(GMT+01:00) Madrid' },
  { title: '(GMT+01:00) Paris', value: ' (GMT+01:00) Paris' },
  { title: '(GMT+01:00) Prague', value: '(GMT+01:00) Prague' },
  { title: '(GMT+01:00) Rome', value: '(GMT+01:00) Rome' },
  { title: '(GMT+01:00) Stockholm', value: '(GMT+01:00) Stockholm' },
  { title: '(GMT+01:00) Tunis', value: '(GMT+01:00) Tunis' },
  { title: '(GMT+01:00) Warsaw', value: '(GMT+01:00) Warsaw' },
  { title: '(GMT+02:00) Amman', value: '(GMT+02:00) Amman' },
  { title: '(GMT+02:00) Cairo', value: '(GMT+02:00) Cairo' },
  { title: '(GMT+02:00) Jerusalem', value: '(GMT+02:00) Jerusalem' },
  { title: '(GMT+02:00) Johannesburg', value: '(GMT+02:00) Johannesburg' },
  { title: '(GMT+02:00) Kaliningrad', value: '(GMT+02:00) Kaliningrad ' },
  { title: '(GMT+03:00) Aden', value: '(GMT+03:00) Aden ' },
  { title: '(GMT+03:00) Kampala', value: ' (GMT+03:00) Kampala' },
  { title: '(GMT+03:00) Moscow', value: ' (GMT+03:00) Moscow' },
  { title: '(GMT+03:00) Nairobi', value: '(GMT+03:00) Nairobi ' },
  { title: '(GMT+03:00) Riyadh', value: ' (GMT+03:00) Riyadh' },
  { title: '(GMT+03:00) Volgograd', value: ' (GMT+03:00) Volgograd' },
  { title: '(GMT+05:00) Yekaterinburg', value: '(GMT+05:00) Yekaterinburg' },
  { title: '(GMT+05:30) Kolkata', value: '(GMT+05:30) Kolkata' },
  { title: '(GMT+06:00) Omsk', value: '(GMT+06:00) Omsk' },
  { title: '(GMT+07:00) Krasnoyarsk', value: '(GMT+07:00) Krasnoyarsk' },
  { title: '(GMT+07:00) Novosibirsk', value: '(GMT+07:00) Novosibirsk' },
  { title: '(GMT+08:00) Hong Kong', value: '(GMT+08:00) Hong Kong' },
  { title: '(GMT+08:00) Irkutsk', value: '(GMT+08:00) Irkutsk' },
  { title: '(GMT+08:00) Manila', value: ' (GMT+08:00) Manila' },
  { title: '(GMT+08:00) Perth', value: ' (GMT+08:00) Perth' },
  { title: '(GMT+08:00) Singapore', value: ' (GMT+08:00) Singapore' },
  { title: '(GMT+08:00) Taipei', value: ' (GMT+08:00) Taipei' },
  { title: '(GMT+08:45) Eucla', value: '(GMT+08:45) Eucla' },
  { title: '(GMT+09:00) Seoul', value: '(GMT+09:00) Seoul' },
  { title: '(GMT+09:00) Tokyo', value: '(GMT+09:00) Tokyo' },
  { title: '(GMT+09:00) Yakutsk', value: ' (GMT+09:00) Yakutsk' },
  { title: '(GMT+09:30) Darwin', value: '(GMT+09:30) Darwin' },
  { title: '(GMT+10:00) Brisbane', value: '(GMT+10:00) Brisbane' },
  { title: '(GMT+10:00) Vladivostok', value: '(GMT+10:00) Vladivostok' },
  { title: '(GMT+10:30) Adelaide', value: '(GMT+10:30) Adelaide' },
  { title: '(GMT+11:00) Hobart', value: ' (GMT+11:00) Hobart' },
  { title: '(GMT+11:00) Melbourne', value: '(GMT+11:00) Melbourne' },
  { title: '(GMT+11:00) Sakhalin', value: '(GMT+11:00) Sakhalin' },
  { title: '(GMT+11:00) Sydney', value: ' (GMT+11:00) Sydney' },
  { title: '(GMT+12:00) Kamchatka', value: '(GMT+12:00) Kamchatka' },
  { title: '(GMT+13:00) Auckland', value: '(GMT+13:00) Auckland' },
  { title: '(GMT+13:45) Chatham', value: '(GMT+13:45) Chatham' },
]
