


















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ADORI_WEBPLAYER_BASE_URL, ADORI_PODCASTS_BASE_URL } from '@/constants'
import QrCode from '@/components/Common/QrCode.vue'

@Component({
  components: { QrCode },
})
export default class Share extends Vue {
  @Prop() audio!: any

  showQr = false

  get qrUrl() {
    return `${ADORI_WEBPLAYER_BASE_URL}/${this.audio.uid}`
  }

  showBrandedNotification(info: string) {
    this.$store.dispatch('pushNotification', { text: info, type: 'SUCCESS' })
  }
  copyEmbed(id: string) {
    const ele: any = document.getElementById('ep' + id)
    const windorRef: any = window
    ele.select()
    document.execCommand('copy')
    this.showBrandedNotification('Copied to clipboard')
    windorRef.getSelection().removeAllRanges()
  }
  get shareURL() {
    return `${ADORI_PODCASTS_BASE_URL}/s/e?eid=${this.audio.uid}`
  }
  get shareList() {
    return [
      {
        id: 'share-url',
        name: 'Share',
        value: this.shareURL,
      },
    ]
  }

  postToTwitter() {
    let text = this.audio.name
    let twitterUrl = `https://twitter.com/intent/tweet?text=${text}\
&url=${this.shareURL}`
    window.open(twitterUrl, '_blank')
  }
  postToLinkedIn() {
    let linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/\
?url=${this.shareURL}`
    window.open(linkedinUrl, '_blank')
  }
  postToFacebook() {
    let title = this.audio.name
    let description = this.audio.description
    let facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${this.shareURL}\
&title=${title}&description=${description}`
    window.open(facebookUrl, '_blank')
  }

  async created() {}
}
