










import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class BaseButtonTab extends Vue {
  @Prop(Boolean) active!: boolean
  @Prop(Boolean) disableUnderline!: boolean
  @Prop(Boolean) disabled!: boolean
  @Prop(String) to!: string
  @Prop(String) text!: string
  @Prop(String) icon!: string
  @Prop({ default: 'pv3' })
  customStyle!: string
  @Prop(Function) onClick!: () => void

  get styleClass() {
    const underlineStyle = !this.disableUnderline ? ' bb b--white bw1 ' : ''
    return !this.disabled
      ? 'f6 dib link light-gray glow pointer no-outline ' +
          this.customStyle +
          (this.active ? underlineStyle + 'white o-100' : ' o-50')
      : 'f6 dib link light-gray o-40 not-allowed'
  }

  tabAction() {
    if (this.onClick) this.onClick()
  }
}
