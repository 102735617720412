




































import { useUpgradePlan } from '@/hooks/billing'
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

@Component({
  setup() {
    const { mutate: upgradePlan, isLoading: isUpgrading } = useUpgradePlan()
    return {
      upgradePlan,
      isUpgrading,
    }
  },
})
export default class Plans extends Vue {
  @Getter networkType!: string
  @Action closeModal!: any

  get plans() {
    const plans = [
      {
        planTitle: 'Starter',
        planSubtitle: 'Perfect plan for starters',
        planAmount: '$0',
        planSubInfoAmount: '/month',
        planActive: this.networkType === 'VIDEO_FREE',
        networkType: 'VIDEO_FREE',
        services: [
          { icon: 'done', service: '3 videos at no cost' },
          { icon: 'done', service: 'HD resolution' },
          { icon: 'done', service: 'Publish automatically to Youtube' },
          { icon: 'done', service: 'AI Script Generator along with 10 AI Images' },
          { icon: 'done', service: 'No credit card required' },
          { icon: 'done', service: 'Access to GPT-3.5' },
          { icon: 'close', service: 'No Pro Voices included' },
          { icon: 'close', service: 'Watermark on videos' },
          { icon: 'close', service: 'No access to Video libraries' },
          { icon: 'close', service: 'No Custom AI Image prompts' },
        ],
      },
      {
        planTitle: 'Premium',
        planSubtitle: 'For single users',
        planAmount: '$9.99',
        planSubInfoAmount: '/month',
        planActive: this.networkType === 'VIDEO_BASIC',
        networkType: 'VIDEO_BASIC',
        services: [
          { icon: 'done', service: '10 videos/month included' },
          { icon: 'done', service: 'HD resolution' },
          { icon: 'done', service: 'Publish automatically to Youtube' },
          { icon: 'done', service: 'AI Script Generator along with 100 AI Images' },
          { icon: 'done', service: 'No watermark on videos' },
          { icon: 'done', service: 'Custom AI Image prompts' },
          { icon: 'done', service: 'Access to GPT-4' },
          { icon: 'done', service: 'Access to Video libraries' },
          { icon: 'close', service: 'No Pro Voices included' },
        ],
      },
      {
        planTitle: 'Pro',
        planSubtitle: 'For your entire team',
        planAmount: '$29.99',
        planSubInfoAmount: '/month',
        planActive: this.networkType === 'VIDEO_PRO',
        networkType: 'VIDEO_PRO',
        services: [
          { icon: 'done', service: '30 videos/month included' },
          { icon: 'done', service: 'Full HD resolution' },
          { icon: 'done', service: 'Pro Voices included' },
          { icon: 'done', service: 'Publish automatically to Youtube' },
          { icon: 'done', service: 'AI Script Generator along with 300 AI Images' },
          { icon: 'done', service: 'Custom AI Image prompts' },
          { icon: 'done', service: 'No watermark on videos' },
          { icon: 'done', service: 'Access to GPT-4' },
          { icon: 'done', service: 'Access to Video libraries' },
        ],
      },
    ]
    return plans
  }

  upgradePlan!: any
  isUpgrading!: boolean

  btnText(planNetworkType: string) {
    switch (this.networkType) {
      case 'VIDEO_FREE':
        return planNetworkType === 'VIDEO_FREE' ? 'CURRENT PLAN' : 'UPGRADE PLAN'
      case 'VIDEO_BASIC':
        return planNetworkType === 'VIDEO_BASIC'
          ? 'CURRENT PLAN'
          : planNetworkType === 'VIDEO_FREE'
          ? 'DOWNGRADE PLAN'
          : 'UPGRADE PLAN'
      case 'VIDEO_PRO':
        return planNetworkType === 'VIDEO_PRO' ? 'CURRENT PLAN' : 'DOWNGRADE PLAN'
      default:
        return 'UPGRADE PLAN'
    }
  }

  choosePlan(toType: string) {
    if (toType === this.networkType) return
    this.$store.dispatch('showConfirm', {
      title: 'Upgrade',
      description: 'Are you sure you want to upgrade the plan?',
      yesButtonText: 'Yes',
      noButtonText: 'No, stay on current plan',
      onConfirm: async () => {
        try {
          this.upgradePlan({
            toType,
            fromType: this.networkType,
          })
        } catch (err) {}
        this.closeModal()
      },
    })
  }
}
