






































































































































































































































































































































































































































































































































































import { Component, Vue, Mixins } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import { validationMixin } from 'vuelidate'
import { required, maxLength, url, email, numeric } from 'vuelidate/lib/validators'
import ImagePreviewer from '@/mixins/ImagePreviewer'
import AdoriService from '@/services/adori'
import HTMLEditor from '@/components/Common/HTMLEditor.vue'
import ImageGallery from '@/components/Common/Images/ImageGallery.vue'
import Invalidate from '@/mixins/Invalidate'
import { feedQueryKeys } from '@/hooks/rssFeeds'

const validations = {
  name: {
    required,
    maxLength: maxLength(255),
  },
  description: {
    required,
    maxLength: maxLength(65535),
    notEmptyHTML: (value: string) => value !== '<p><br></p>',
  },
  summary: {
    required,
    maxLength: maxLength(255),
  },
  publisher: {
    required,
    maxLength: maxLength(255),
  },
  copyright: {
    required,
    maxLength: maxLength(255),
  },
  website: {
    required,
    urlVal: (value: string) => {
      var pattern = new RegExp(
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$',
        'i'
      ) // fragment locator
      return !!pattern.test(value)
    },
    maxLength: maxLength(2083),
  },
  anltPrefix: {
    urlVal: (value: string) => {
      if (value) {
        var pattern = new RegExp(
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
          'i'
        ) // fragment locator
        return !!pattern.test(value)
      } else {
        return true
      }
    },
    maxLength: maxLength(255),
  },
  ownerName: {
    required,
    maxLength: maxLength(255),
  },
  ownerEmail: {
    required,
    email,
  },
  language: {
    required,
  },
  episodeListingType: {
    required,
  },
  genreIds: {
    required,
  },
  explicitContent: {
    required,
  },
  keywords: {
    maxLength: maxLength(255),
  },
  episodeFootNote: {
    maxLength: maxLength(255),
  },
}

@Component({
  // @ts-ignore
  validations,
  components: { HTMLEditor, ImageGallery },
})
export default class ModalPodcastCreate extends Mixins(validationMixin, ImagePreviewer, Invalidate) {
  @Getter userId!: any
  @Getter networkId!: any
  @Getter profile!: any
  @Getter rssGenres!: any
  @Getter verifiedEmails!: any
  @Getter rssFeedUids!: any
  @Getter selectedFile!: any

  @Action createRssFeed!: any
  @Action updateRssFeed!: any
  @Action publishRssFeed!: any
  @Action closeModal!: any
  @Action setSelectedRssFeedUid!: any
  @Action getRssGenres!: any
  @Action getRssFeeds!: any
  @Action incrementRssFeedsCount!: any
  @Action getVerifiedEmails!: any

  currentStep: number = 1

  name: string = ''
  description: string = ''
  summary: string = ''
  publisher: string = ''
  copyright: string = ''
  website: string = ''
  language: string = 'en'
  episodeListingType: string = 'EPISODE'
  explicitContent: boolean = false
  ownerName: string = ''
  ownerEmail: string = ''
  genreIds: string[] = []
  anltPrefix: string = ''
  keywords = ''
  episodeFootNote = ''

  // Related to validation
  $v: any
  hasClickedNext: boolean = false
  hasClickedImage: boolean = false
  hasClearedCategories: boolean = false

  podcastImageData: string = ''

  isSubmitting: boolean = false

  enableImageGallery: boolean = false
  selectedImageSrc: string = ''
  selectedImageId: string = ''

  languages = [
    { name: 'English', code: 'en' },
    { name: 'English (Australia)', code: 'en-au' },
    { name: 'English (Belize)', code: 'en-bz' },
    { name: 'English (Canada)', code: 'en-ca' },
    { name: 'English (Ireland)', code: 'en-ie' },
    { name: 'English (Jamaica)', code: 'en-jm' },
    { name: 'English (New Zealand)', code: 'en-nz' },
    { name: 'English (Phillipines)', code: 'en-ph' },
    { name: 'English (South Africa)', code: 'en-za' },
    { name: 'English (Trinidad)', code: 'en-tt' },
    { name: 'English (United Kingdom)', code: 'en-gb' },
    { name: 'English (United States)', code: 'en-us' },
    { name: 'English (Zimbabwe)', code: 'en-zw' },
    { name: 'Spanish', code: 'es' },
    { name: 'French', code: 'fr' },
    { name: 'Italian', code: 'it' },
    { name: 'Chinese', code: 'zh' },
    { name: 'Japanese', code: 'ja' },
    { name: 'Korean', code: 'ko' },
    { name: 'Hindi', code: 'hi' },
    { name: 'Tamil', code: 'ta' },
    { name: 'Marathi', code: 'mr' },
    { name: 'Kannada', code: 'kn' },
    { name: 'Urdu', code: 'ur' },
    { name: 'Telugu', code: 'te' },
    { name: 'Gujarati', code: 'gu' },
    { name: 'Malayalam', code: 'ml' },
  ]

  episodeListingTypes = [
    { text: 'Episodic', value: 'EPISODE' },
    { text: 'Serial', value: 'SERIAL' },
  ]

  async created() {
    await this.getVerifiedEmails()
    this.publisher = this.profile.name
    this.copyright = `© ${new Date().getFullYear()} ${this.profile.name}, All rights reserved.`
    this.ownerName = this.profile.name
    this.ownerEmail = this.profile.email
    if (this.rssGenres.length === 0) {
      await this.getRssGenres()
    }
  }

  get hasPreviewImage() {
    return this.selectedImageSrc
  }

  showImageGallery() {
    if (this.enableImageGallery && !this.selectedImageSrc) {
      this.selectedImageSrc = ''
    }
    this.hasClickedImage = true
    this.enableImageGallery = !this.enableImageGallery
  }

  handleSelectedImage(imgData: any) {
    this.hasClickedImage = true
    if (imgData.urls.full && imgData.urls.full.includes('adorilabs')) {
      this.selectedImageSrc = imgData.urls.full
      this.selectedImageId = imgData.id
    } else if (imgData.urls.full && imgData.urls.full.startsWith('https://')) {
      this.selectedImageSrc = imgData.urls.full
    } else if (this.previewImage !== '/img/Web link.c650ed21.jpg') {
      this.selectedImageSrc = this.previewImage
    } else {
      this.selectedImageSrc = '/img/Web link.c650ed21.jpg'
    }
    this.enableImageGallery = !this.enableImageGallery
  }

  get hasCompletedStep1() {
    return !this.$v.name.$invalid && !this.$v.description.$invalid && !this.$v.summary.$invalid && this.hasPreviewImage
  }

  get hasCompletedStep2() {
    return (
      !this.$v.publisher.$invalid &&
      !this.$v.copyright.$invalid &&
      !this.$v.website.$invalid &&
      !this.$v.ownerName.$invalid &&
      !this.$v.ownerEmail.$invalid
    )
  }

  get hasCompletedStep3() {
    return !this.$v.$invalid && this.genreIds.length !== 0
  }

  genreFromId(id: string) {
    return this.flattendRssGenres.find((genre: any) => genre.id === id)
  }

  get flattendRssGenres() {
    const allRssGenres: any = []
    const rssGenres = this.rssGenres
    rssGenres.forEach((genre: any) => {
      allRssGenres.push({ id: genre.id, name: genre.name })
      if (genre.subGenres.length !== 0) {
        genre.subGenres.forEach((subGenre: any) => {
          allRssGenres.push({ id: subGenre.id, name: subGenre.name })
        })
      }
    })
    return allRssGenres
  }

  updateDescription(newDescription: string) {
    this.$v.description.$model = newDescription
  }
  updateFootNote(footNote: string) {
    this.$v.episodeFootNote.$model = footNote
  }

  handleAddGenre(e: any) {
    this.hasClearedCategories = false
    this.genreIds.push(e.target.value)
  }

  handleRemoveGenre(id: any) {
    this.genreIds = this.genreIds.filter((genreId: any) => genreId !== id)
    if (this.genreIds.length === 0) {
      this.hasClearedCategories = true
    }
  }

  handlePrevTab() {
    this.isSubmitting = false
    this.currentStep -= 1
  }

  handleNextTab() {
    if (this.currentStep === 1 && this.hasCompletedStep1) {
      this.hasClickedNext = false
      this.currentStep += 1
    } else if (this.currentStep === 2 && this.hasCompletedStep2) {
      this.hasClickedNext = false
      this.currentStep += 1
    } else if (this.currentStep === 3 && this.hasCompletedStep3) {
      this.hasClickedNext = false
      this.currentStep += 1
    } else {
      this.hasClickedNext = true
    }
  }

  keywordStringToArray(keywordString: string) {
    if (keywordString === '') return []
    return keywordString.split(',').map((keyword: string) => keyword.trim())
  }

  async handleCreatePodcastSubmit() {
    if (this.currentStep === 3 && this.hasCompletedStep3) {
      this.isSubmitting = true
      this.hasClickedNext = false

      let imageId
      if (this.selectedImageSrc && this.selectedImageSrc.includes('adorilabs')) {
        imageId = this.selectedImageId
      } else if (this.selectedImageSrc && this.selectedImageSrc.startsWith('https://')) {
        const img: any = await AdoriService.uploadImage(this.networkId, {
          url: this.selectedImageSrc,
        })
        imageId = img.id
      } else {
        const img: any = await AdoriService.uploadImage(this.networkId, this.selectedFile)
        imageId = img.id
      }
      let createdRssFeedUid = ''
      try {
        createdRssFeedUid = await this.createRssFeed({
          name: this.name,
          description: this.description,
          summary: this.summary,
          squareImageId: imageId,
        })

        const updatePayload: any = {
          publisher: this.publisher,
          copyright: this.copyright,
          website: !/^https?:\/\//i.test(this.website) ? `https://${this.website}` : this.website,
          language: this.language,
          episodeListingType: this.episodeListingType,
          explicitContent: this.explicitContent,
          ownerName: this.ownerName,
          ownerEmail: this.ownerEmail,
          genreIds: this.genreIds,
        }

        if (this.anltPrefix) {
          updatePayload.anltPrefix = !/^https?:\/\//i.test(this.anltPrefix)
            ? `https://${this.anltPrefix}`
            : this.anltPrefix
        }

        this.keywords && (updatePayload.episodeKeywords = this.keywordStringToArray(this.keywords))

        this.episodeFootNote && (updatePayload.episodeFootNote = this.episodeFootNote)

        await this.updateRssFeed({
          rssFeedUid: createdRssFeedUid,
          payload: updatePayload,
        })

        await this.publishRssFeed({
          rssFeedUid: createdRssFeedUid,
        })

        this.queryClient.invalidateQueries(feedQueryKeys.RSSFEED)
        this.$router.push(`/audio/podcasts/${createdRssFeedUid}`)

        this.closeModal()
      } catch (err) {
        this.isSubmitting = false
      }

      this.isSubmitting = false
    } else {
      this.hasClickedNext = true
    }
  }

  async handleCreatePodcastCancel() {
    this.closeModal()
  }
}
