












import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component
export default class TheToast extends Vue {
  @Prop({ default: false }) active!: boolean
  @Prop({ default: 5000 }) timeout!: number
  @Prop({ default: false }) infinity!: boolean
  @Prop({ default: 'bottom-right' }) position!: string

  timer: any = null

  get classes() {
    return {
      [`toast--${this.position}`]: true,
    }
  }

  setTimer() {
    if (this.timer) clearTimeout(this.timer)
    if (this.infinity) return
    this.timer = setTimeout(() => {
      this.active = false
      this.$emit('update:active', false)
    }, this.timeout)
  }

  @Watch('active')
  onActive() {
    this.setTimer()
  }
}
