export enum AudioAdTab {
  Promo = 'PROMO',
  Program = 'PROGRAM',
}

export enum AudioAdType {
  Promo = 'PROMO',
  Program = 'PROGRAMMATIC',
  AdSlot = 'AD_SLOT',
}

export enum TagProvider {
  PROMO = 'ADORI',
  PROGRAMMATIC = 'TRITON',
}

export enum Title {
  titleProm = 'Promo Ad',
  titleProg = 'Programmatic Ad',
}
