var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      attrs: { hasCustomEvent: true },
      on: {
        closeModal: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "f3 light-gray fw5 di" }, [
          _vm._v("Create New Sponsor"),
        ]),
      ]),
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "flex flex-column w-100" }, [
          _c("div", { staticClass: "flex justify-between w-100 white mt4" }, [
            _c("div", { staticClass: "light-gray w-40" }, [
              _c(
                "div",
                { staticClass: "flex" },
                [
                  _c("BaseLabel", {
                    staticClass: "mb2 w-30",
                    attrs: { for: "sponsorName", text: "Name*" },
                  }),
                ],
                1
              ),
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.sponsorName,
                      expression: "sponsorName",
                    },
                  ],
                  staticClass:
                    "bg-adori-very-light-gray br2 ph3 bw0 pv2 f6 white w-80",
                  class: _vm.validationStatus ? "ba bw1 b--adori-red" : "",
                  attrs: { placeholder: "Sponsor Name" },
                  domProps: { value: _vm.sponsorName },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.sponsorName = $event.target.value
                      },
                      _vm.validation,
                    ],
                  },
                }),
              ]),
              _vm.validationStatus
                ? _c("div", { staticClass: "f6 adori-red" }, [
                    _vm._v("Sponsor Name Required"),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "flex justify-end items-center w-100 white mt6" },
          [
            _c(
              "div",
              { staticClass: "mt3 mb2 flex justify-between mr4" },
              [
                _c("BaseButtonBorder", {
                  staticClass: "bw0 bg-adori-light-gray fr",
                  attrs: {
                    text: "Close",
                    onClick: function () {
                      _vm.$emit("close")
                    },
                  },
                }),
                _c("BaseButtonRed", {
                  staticClass: "fl ml2",
                  attrs: {
                    text: "Create Sponsor",
                    onClick: _vm.handleCreateSponsor,
                  },
                }),
                _vm.isUploadingTag
                  ? _c("div", { staticClass: "fl loader loader-inline mt2" })
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }