var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      attrs: { hasCustomEvent: _vm.modalEdit },
      on: {
        closeModal: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "f3 light-gray fw5 di" }, [
          _vm._v("Update Audio Ad"),
        ]),
      ]),
      _c("template", { slot: "body" }, [
        _c(
          "div",
          { staticClass: "flex justify-between w-100 white mt4 min-w7" },
          [
            _c("div", { staticClass: "light-gray w-40" }, [
              _c(
                "div",
                { staticClass: "flex" },
                [
                  _c("BaseLabel", {
                    staticClass: "mb2 w-30",
                    attrs: { for: "nameOfAd", text: "Name of Ad*" },
                  }),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.adName,
                        expression: "adName",
                      },
                    ],
                    staticClass:
                      "bg-adori-very-light-gray br2 ph3 bw0 pv2 f6 white w-70 ml3",
                    class: _vm.validationStatus ? "ba bw1 b--adori-red" : "",
                    attrs: { placeholder: "Name of the Ad*" },
                    domProps: { value: _vm.adName },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.adName = $event.target.value
                        },
                        _vm.validation,
                      ],
                    },
                  }),
                ],
                1
              ),
              _vm.validationStatus
                ? _c(
                    "span",
                    {
                      staticClass: "f6 adori-red",
                      staticStyle: { "margin-left": "110px" },
                    },
                    [_vm._v("Name of Ad Required")]
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "flex mt3" },
                [
                  _c("BaseLabel", {
                    staticClass: "w-30",
                    attrs: { for: "type", text: "Type of Ad*" },
                  }),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.audioType,
                          expression: "audioType",
                        },
                      ],
                      staticClass:
                        "mt2 bg-adori-gray gray ba b--gray pa1 br2 f6 mb2 w-70 h2",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.audioType = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    _vm._l(_vm.audioTags, function (type) {
                      return _c("option", { key: type }, [_vm._v(_vm._s(type))])
                    }),
                    0
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "w-60 ml4 flex flex-column justify-end" },
              [
                _vm.audioPlayUrl === null
                  ? _c(
                      "div",
                      { staticClass: "flex flex-column items-center w-100" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "w-100 h5 bg-adori-light-gray ba b--light-gray b--dashed br2 pa5 flex flex-column items-center justify-center pointer",
                            on: { click: _vm.handleUploadAudio },
                          },
                          [
                            _c(
                              "i",
                              {
                                staticClass:
                                  "material-icons f-headline silver mb2",
                              },
                              [_vm._v("publish")]
                            ),
                            _c("div", { staticClass: "f4 silver" }, [
                              _vm._v("Upload"),
                            ]),
                          ]
                        ),
                      ]
                    )
                  : _c(
                      "div",
                      { staticClass: "w-100 flex flex-column items-center" },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/monetize/audioPlaceHolder.jpg"),
                            alt: "",
                            height: "250",
                            width: "550",
                          },
                        }),
                        _c(
                          "audio",
                          {
                            staticClass: "w-100",
                            attrs: {
                              controls: "",
                              src: _vm.audioPlayUrl,
                              type: "audio/mpeg",
                            },
                          },
                          [
                            _vm._v(
                              "\n            Your browser does not support the\n            "
                            ),
                            _c("code", [_vm._v("audio")]),
                            _vm._v(" element.\n          "),
                          ]
                        ),
                      ]
                    ),
                _vm.audioPlayUrl
                  ? _c(
                      "div",
                      { staticClass: "mt3 mb2 flex justify-between" },
                      [
                        _c(
                          "div",
                          { staticClass: "flex" },
                          [
                            _c("BaseButtonRed", {
                              staticClass: "fl",
                              attrs: {
                                text: "Update Ad",
                                onClick: _vm.updateAd,
                              },
                            }),
                            _vm.isUploadingTag
                              ? _c("div", {
                                  staticClass: "loader loader-inline mt2 ml1",
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("BaseButtonBorder", {
                          staticClass: "bw0 bg-adori-light-gray fr",
                          attrs: {
                            text: "Close",
                            onClick: function () {
                              _vm.modalEdit
                                ? _vm.$emit("close")
                                : _vm.$store.dispatch("closeModalChange")
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }