
































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { ADORI_API_BASE_URL } from '@/constants'
import AudioPlayer from '@/components/Audio/AudioPlayer.vue'
import { toClockLike, secondsToMmss } from '@/utils/time'

@Component({ components: { AudioPlayer } })
export default class ModalAudioTrackPlay extends Vue {
  currentTime = 0
  isPlaying = false

  beforeDestroy() {
    this.$store.state.modal.audioTrackPlayer.source = ''
  }

  get audioUid() {
    return this.$store.state.modal.audioTrackPlayer.uid
  }

  get isAd() {
    return this.$store.state.modal.audioTrackPlayer.source
  }

  get audioSrc() {
    const token = this.$store.getters.token
    const networkId = this.$store.getters.networkId
    if (this.isAd) {
      return `${ADORI_API_BASE_URL}/networks/${networkId}/audioads/${this.audioUid}/audio?api_token=${token}`
    }
    return `${ADORI_API_BASE_URL}/networks/${networkId}/audiotracks/${this.audioUid}/audio?api_token=${token}`
  }

  get audioPreviewImage() {
    return this.audio ? (this.audio.imageInfo ? this.audio.imageInfo.url : '') : null
  }

  get audio() {
    if (this.isAd) return this.$store.getters.audioAds.filter((ad: any) => ad.id)[0]
    return this.$store.getters.audio(this.audioUid)
  }

  get playButtonIcon() {
    return (this.isPlaying ? 'pause' : 'play') + '_circle_outline'
  }

  get formattedCurrentTime() {
    return secondsToMmss(this.currentTime)
  }

  get formattedTotalTime() {
    return secondsToMmss(Math.ceil(this.audio.durationMillis / 1000))
  }

  togglePlay() {
    this.isPlaying = !this.isPlaying

    const audioRef: any = this.$refs.audio
    if (!this.isPlaying) audioRef.pause()
    else audioRef.play()
  }

  updateTime() {
    const audioRef: any = this.$refs.audio
    this.currentTime = audioRef && audioRef.currentTime
  }

  handleSeekBackward() {
    const audioRef: any = this.$refs.audio
    audioRef.currentTime = audioRef.currentTime - 10
  }

  handleSeekForward() {
    const audioRef: any = this.$refs.audio
    audioRef.currentTime = audioRef.currentTime + 10
  }
}
