import Vue from 'vue'
import { StoreOptions, GetterTree, MutationTree, ActionTree } from 'vuex'
import AdoriService from '@/services/adori_v6'

const state: any = {
  userVideos: [],
  isLoading: false,
  count: 0,
  offset: 0,
}

const initialState: any = {
  ...JSON.parse(JSON.stringify(state)),
}

const getters: GetterTree<any, any> = {
  getUserVideos: (state) => state.userVideos,
  isLoadingUserVideos: (state) => state.isLoading,
}

const mutations: MutationTree<any> = {
  resetState(state) {
    Object.keys(state).forEach((key: any) => {
      Vue.set(state, key, initialState[key])
    })
  },

  setUserVideos(state, videos) {
    if (state.userVideos.data && state.userVideos.data.length !== 0 && videos.offset !== 0) {
      const data = [...state.userVideos.data, ...videos.data]
      const imagesObj = {
        count: videos.count,
        offset: videos.offset,
        data: data,
        limit: videos.limit,
      }
      Vue.set(state, 'userVideos', imagesObj)
    } else {
      Vue.set(state, 'userVideos', videos)
    }
  },

  setIsLoading(state, isLoading: boolean) {
    Vue.set(state, 'isLoading', isLoading)
  },

  setVideosCount(state, count: number) {
    Vue.set(state, 'count', count)
  },

  setVideosOffset(state, offset: number) {
    Vue.set(state, 'offset', offset)
  },
}

const actions: ActionTree<any, any> = {
  resetVideosState(context) {
    context.commit('resetState')
  },

  async getVideos(context, { limit = 10, offset = 0 }) {
    const networkId = context.getters.networkId
    context.commit('setIsLoading', true)
    const videos: any = await AdoriService.fetchVideos(networkId, limit || 10, offset || 0)
    context.commit('setIsLoading', false)
    context.commit('setUserVideos', videos)
    context.commit('setVideosCount', videos.count)
    context.commit('setVideosOffset', videos.offset)
  },

  async setIsLoadingUserVideos(context, value: boolean) {
    context.commit('setIsLoading', value)
  },

  async uploadVideo(context, setItem: string) {
    const networkId = context.getters.networkId
    let payload = context.getters.selectedFile
    if (setItem === 'setTagImageId' && context.getters.tagEditImageBlob !== '') {
      payload = context.getters.tagEditImageBlob
    }
    if (context.getters.tagEditImage.startsWith('https://')) {
      payload = {
        url: context.getters.tagEditImage,
      }
    }
    const imageId = await AdoriService.createVideo(networkId, payload).then((res: any) => res.id)
    context.commit(setItem, imageId)
  },
}

const videos: StoreOptions<any> = {
  state,
  getters,
  mutations,
  actions,
}

export default videos
