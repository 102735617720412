































































import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

@Component
export default class UploadAudio extends Vue {
  @Getter selectedFile!: any
  bgDragColor = false
  dropped = false

  audioPodcast = true

  selectedTextFile: any = null

  get selectedFileName() {
    return this.selectedFile && this.selectedFile.name
  }

  get isVideoPodcast() {
    if (this.selectedFile) {
      return this.selectedFile.type.includes('video')
    }
    return false
  }

  get selectedFileSize() {
    if (this.selectedFile) {
      let size = this.selectedFile.size
      let fSExt = ['Bytes', 'KB', 'MB', 'GB']
      let i = 0
      while (size > 900) {
        size /= 1024
        i++
      }
      return Math.round(size * 100) / 100 + ' ' + fSExt[i]
    }
  }

  get hasAudioUploadId() {
    return !!this.$store.getters.audioUploadId
  }
  get isUploadingAudio() {
    return this.hasAudioUploadId && this.$store.getters.audioUploadProgress !== 1
  }

  get uploadProgress() {
    return Math.round(this.$store.getters.audioUploadProgress * 100)
  }

  fileType(file: any) {
    const filename = file.name
    return filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename
  }

  dropFile(event: any) {
    const file = event.dataTransfer.files[0]
    if (
      !['mp3', 'mp4', 'm4a', 'wav', 'ogg', 'x-wav', 'x-m4a', 'mpeg', 'webm', 'avi', 'x-ms-wmv', 'x-ms-wma'].includes(
        this.fileType(file)
      )
    ) {
      this.$store.dispatch('pushNotification', {
        text: 'File format is not supported! Please choose different file format',
        type: 'WARNING',
      })
      return
    }
    if (this.bytesToMegaBytes(file.size) > 500) {
      this.$store.dispatch('pushNotification', {
        text: 'Please upload video lesser than 500MB',
        type: 'WARNING',
      })
      return
    }
    this.dropped = true
    this.$store.commit('setSelectedFile', event.dataTransfer.files[0])
    this.dropped = false
  }

  bytesToMegaBytes(bytes: any) {
    return bytes / (1024 * 1024)
  }

  handleUploadAudio() {
    this.$store.dispatch('uploadEpisode')
  }

  destroyed() {
    this.$store.commit('setSelectedFile', null)
  }

  uploadEpisode() {
    this.audioPodcast ? this.$store.dispatch('uploadAudio') : this.$store.dispatch('uploadAudio', 'VIDEO')
  }
}
