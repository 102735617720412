

















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class BaseTagLocationPicker extends Vue {
  @Prop(String) text!: string
  @Prop(Number) offsetMillis!: number

  get formattedTime() {
    const date = new Date(0)

    date.setMilliseconds(this.offsetMillis)

    return `${date.toISOString().substr(11, 8)}.${date.getMilliseconds()}`
  }

  onIncrement(sign: number) {
    this.$emit('increment', sign)
  }
}
