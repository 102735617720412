var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      on: {
        click: function ($event) {
          !_vm.disabled ? _vm.tabAction() : null
        },
      },
    },
    [
      _vm.icon
        ? _c(
            "i",
            {
              staticClass: "material-icons white f4 mr1 v-mid mb1",
              class: _vm.active ? "" : "o-50",
            },
            [_vm._v("\n    " + _vm._s(_vm.icon) + "\n  ")]
          )
        : _vm._e(),
      _vm.to
        ? _c("router-link", { class: _vm.styleClass, attrs: { to: _vm.to } }, [
            _vm._v(_vm._s(_vm.text)),
          ])
        : _c("a", { class: _vm.styleClass }, [
            _vm._v(" " + _vm._s(_vm.text) + " "),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }