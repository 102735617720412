

















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class BaseTagSuggestionTopics extends Vue {
  @Prop(Object) suggestionsByTopic!: object

  pluralize(singular: string, plural: string, quantity: number) {
    if (quantity === 1) return singular
    else return plural
  }

  pluralizeTag(quantity: number) {
    return this.pluralize('tag', 'tags', quantity)
  }
}
