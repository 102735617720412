


























































































import WaveSurfer from 'wavesurfer.js/dist/wavesurfer.min.js'
import Cursor from 'wavesurfer.js/dist/plugin/wavesurfer.cursor.min.js'
import Regions from 'wavesurfer.js/dist/plugin/wavesurfer.regions.min.js'
import Markers from 'wavesurfer.js/dist/plugin/wavesurfer.markers.min.js'
import TimeLine from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faPlay, faPause, faStop, faVolumeMute, faVolumeUp, faVolumeDown } from '@fortawesome/free-solid-svg-icons'
import {
  processFinalAudio,
  initAudioPreview,
  // @ts-ignore
  audioBufferList,
  // @ts-ignore
} from '@/utils/audiopreview.js'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { stringify } from 'vega-lite/build/src/util'

// enum and interfaces
import { AudioAdTab } from '@/components/Tags/tags'

@Component({
  components: {
    FontAwesomeIcon,
    WaveSurfer,
    Cursor,
    Regions,
    TimeLine,
  },
})
export default class AudioAdPreview extends Vue {
  getValue(key: string) {
    return this.$store.state.modal.confirm[key]
  }
  onAction(action: 'onConfirm' | 'onCancel') {
    const onAction = this.getValue(action)
    if (onAction) onAction()
    if (action === 'onCancel' && !onAction) {
      this.$store.dispatch('closeModal')
    }
  }
  @Action closeModal!: any
  @Prop({ default: 'AudioAdPreview' })
  name!: String
  @Prop({ default: '#999' })
  progressColor!: String
  @Prop({ default: '#FFF' })
  waveColor!: String
  @Prop({ default: '#333' })
  cursorColor!: String
  @Prop({ default: 1 })
  cursorWidth!: Number
  @Prop({ default: 128 })
  height!: Number
  @Prop({ default: true })
  fillParent!: Boolean
  @Prop({ default: true })
  loopSelection!: Boolean
  @Prop({ default: true })
  interact!: Boolean
  @Prop({ default: true })
  removeMediaElementOnDestroy!: Boolean
  @Prop({ default: true })
  combinedPlay!: Boolean
  @Prop({
    default: () => {
      return []
    },
    required: true,
  })
  tracks!: Array<any>
  @Prop() callbackFunction!: Function
  wavesurfer: any = null
  currentTime: String = '0:00'
  timeInterval: any = null
  volume: any = 100
  playing: boolean = false
  muted: boolean = false
  playIcon: any = faPlay
  pauseIcon: any = faPause
  stopIcon: any = faStop
  muteIcon: any = faVolumeMute
  volumeUpIcon: any = faVolumeUp
  volumeDownIconv = faVolumeDown
  zoomValue: Number = 0 // record value of zoom
  initialTrackPlaylist: any = [] // Episode's inital platlist (passed)
  audioUrl: String = '' // audio url after processing
  hasAdPosChanged: boolean = false // flag if playlist position changes
  isSaveRequired: boolean = false // To control save button
  hasClickedSaveAdPreview: boolean = false // flag to save preview list
  isDownloading: boolean = false // flag that audio is being downloaded
  padDigits(number: any, digits: any) {
    return Array(Math.max(digits - String(number).length + 1, 0)).join(String(0)) + number
  }
  timeDisplay(time: any) {
    let hrs: any = Math.floor(time / 3600)
    let mins: any = Math.floor((time % 3600) / 60)
    let secs: any = Math.floor(time % 60)
    return this.padDigits(hrs, 2) + ':' + this.padDigits(mins, 2) + ':' + this.padDigits(secs, 2)
  }
  playPause() {
    this.playing = this.wavesurfer.isPlaying()
    if (this.playing) {
      this.pause()
    } else {
      this.play()
    }
    this.playing = this.wavesurfer.isPlaying()
  }
  play() {
    this.timeInterval = setInterval(() => {
      this.currentTime = this.timeDisplay(this.wavesurfer.getCurrentTime())
    }, 50)
    this.wavesurfer.play()
  }
  pause() {
    this.wavesurfer.pause()
  }
  stop() {
    this.wavesurfer.stop()
    this.timeInterval = null
    this.currentTime = this.timeDisplay(0)
  }
  mute() {
    this.muted = this.getMute
    this.wavesurfer.setMute(!this.muted)
    this.muted = this.getMute
  }
  setVolume() {
    let floatValue = this.volume / 100
    this.wavesurfer.setVolume(Number.parseFloat(floatValue.toFixed(2)))
  }
  finalizeAudio() {
    this.hasClickedSaveAdPreview = true
  }
  createAudioPlaylist() {
    if (this.hasAdPosChanged) {
      // Sort the list based on start time (since ad repositioning may have changed the order)
      this.initialTrackPlaylist = this.initialTrackPlaylist.sort(function (a: any, b: any) {
        return -(b.rel_start - a.rel_start) // ascending sort on id
      })
      // Reposition the audioBufferList before sending for reprocessing
      for (let j = 0; j < audioBufferList.length; j++) {
        this.initialTrackPlaylist.find((o: any, i: number) => {
          // @ts-ignore
          if (o.id === audioBufferList[j].id) {
            // @ts-ignore
            audioBufferList[j].start = this.initialTrackPlaylist[i].rel_start
            return true
          }
        })
      }
      this.isDownloading = true
      // @ts-ignore
      processFinalAudio(audioBufferList, (response: any, err: any) => {
        this.initialTrackPlaylist = response.adPositions
        this.audioUrl = response.url
      })
      this.hasAdPosChanged = false
      this.isDownloading = false
    } else {
      let getAudioUrl = function (tracks: any) {
        return new Promise((resolve, reject) => {
          initAudioPreview(tracks, (resp: any, err: any) => {
            if (err) reject(err)
            else resolve(resp)
          })
        })
      }
      this.isDownloading = true
      getAudioUrl(this.tracks)
        .then((resp: any) => {
          let t0 = performance.now()
          this.initialTrackPlaylist = resp.adPositions
          this.audioUrl = resp.url
          this.isDownloading = false
          let t1 = performance.now()
        })
        .catch((err) => {
          console.log('Error loading stitched audio', err)
        })
    }
  }
  getAdRegion() {
    var adRegion = []
    for (let i = 0; i < this.initialTrackPlaylist.length; i++) {
      if (this.initialTrackPlaylist[i].type === 'MAIN') continue
      adRegion.push({
        id: this.initialTrackPlaylist[i].id,
        start: this.initialTrackPlaylist[i].abs_start,
        end: this.initialTrackPlaylist[i].abs_start + this.initialTrackPlaylist[i].duration,
        color: 'rgba(0, 255, 0, 0.3)',
        drag: true,
        resize: false,
      })
    }
    return adRegion
  }
  getAdMarkers() {
    var adMarkers = []
    for (var i = 0; i < this.initialTrackPlaylist.length; i++) {
      if (this.initialTrackPlaylist[i].type === 'MAIN') continue
      adMarkers.push({
        time: this.initialTrackPlaylist[i].abs_start,
        label: this.initialTrackPlaylist[i].label,
        color: '#00ffcc',
      })
    }
    return adMarkers
  }
  changeAdPos(region: any) {
    this.initialTrackPlaylist.find((o: any, i: any) => {
      if (o.id === region.id) {
        let delta = region.start - this.initialTrackPlaylist[i].abs_start
        if (this.initialTrackPlaylist[i].rel_start + delta < 0) {
          this.$store.dispatch('pushNotification', {
            text: `Ad overlapping not allowed`,
            type: 'ERROR',
          })
        } else {
          this.initialTrackPlaylist[i] = {
            id: region.id,
            type: this.initialTrackPlaylist[i].type,
            abs_start: this.initialTrackPlaylist[i].abs_start + delta,
            rel_start: this.initialTrackPlaylist[i].rel_start + delta,
            duration: region.end - region.start,
          }
          this.hasAdPosChanged = true
          this.isSaveRequired = true
        }
      }
    })
  }
  cancelPreview() {
    if (this.isSaveRequired) {
      this.$store.dispatch('showConfirm', {
        title: 'Save before cancelling?',
        description: 'You have made changes to ad positions. Save the changes first?',
        yesButtonText: 'OK',
        noButtonText: 'No, cancel the changes',
        onConfirm: async () => {
          this.onHasClickedSaveAdPreview()
          this.closeModal()
        },
        onCancel: () => {
          this.closeModal()
        },
      })
    } else {
      this.$store.dispatch('closeModal')
    }
  }
  zoomChange() {
    this.wavesurfer.zoom(this.zoomValue)
  }
  get id() {
    return `#${this.name}`
  }
  get getDuration() {
    if (this.wavesurfer) {
      return this.timeDisplay(this.wavesurfer.getDuration())
    } else {
      return null
    }
  }
  get getPlaybackRate() {
    if (this.wavesurfer) {
      return this.wavesurfer.getPlaybackRate()
    } else {
      return null
    }
  }
  get getVolume() {
    if (this.wavesurfer) {
      return this.wavesurfer.getVolume()
    } else {
      return null
    }
  }
  get getMute() {
    if (this.wavesurfer) {
      return this.wavesurfer.getMute()
    } else {
      return false
    }
  }
  @Watch('audioUrl')
  async onAudioUrl() {
    // Create regions
    this.wavesurfer.regions.clear()
    let e: any = this.getAdRegion()
    e.forEach((element: any) => {
      this.wavesurfer.regions.add(element)
    })
    // Create markers
    this.wavesurfer.markers.clear()
    e = this.getAdMarkers()
    e.forEach((element: any) => {
      this.wavesurfer.markers.add(element)
    })
    // Load the audio
    this.wavesurfer.load(this.audioUrl)
  }
  @Watch('hasClickedSaveAdPreview')
  async onHasClickedSaveAdPreview() {
    // send back the ads that changed positions
    let responseList: any[] = []
    for (let j = 0; j < this.initialTrackPlaylist.length; j++) {
      // @ts-ignore
      this.tracks.find((o: any, i: number) => {
        if (o.id === this.initialTrackPlaylist[j].id) {
          if (o.start !== this.initialTrackPlaylist[j].rel_start) {
            responseList.push(this.initialTrackPlaylist[j])
          }
        }
      })
    }
    const responseJson = JSON.stringify(responseList)
    this.callbackFunction(responseJson)
    this.$store.dispatch('closeModal')
  }
  mounted() {
    this.$nextTick(() => {
      this.wavesurfer = WaveSurfer.create({
        container: this.id,
        backend: 'MediaElement',
        waveColor: this.waveColor,
        progressColor: this.progressColor,
        cursorColor: this.cursorColor,
        cursorWidth: this.cursorWidth,
        height: this.height,
        fillParent: this.fillParent,
        loopSelection: this.loopSelection,
        interact: this.interact,
        removeMediaElementOnDestroy: this.removeMediaElementOnDestroy,
        scrollParent: true,
        plugins: [
          Cursor.create({
            showTime: true,
            opacity: 1,
            customShowTimeStyle: {
              'background-color': '#000',
              color: '#fff',
              padding: '2px',
              'font-size': '11px',
            },
          }),
          Regions.create({
            regions: [],
          }),
          Markers.create({
            markers: [],
          }),
        ],
      })
      this.createAudioPlaylist()
      this.wavesurfer.zoom(this.zoomValue)
      this.wavesurfer.on('error', function (e: any) {
        console.warn(e)
      })
      // listener to change in regions (audio ad position)
      this.wavesurfer.on('region-update-end', (region: any) => {
        this.changeAdPos(region)
      })
      // listener to wavesurfer being ready
      this.wavesurfer.on('waveform-ready', () => {
        this.isDownloading = false
      })
    })
  }
  beforeDestroy() {
    this.wavesurfer.destroy()
  }
}
