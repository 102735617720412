










import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class BaseButtonLoadMore extends Vue {
  @Prop(String) text!: string
  @Prop(String) icon!: string
  @Prop({ default: '500' })
  rootBottomMargin!: string
  @Prop(Boolean) disabled!: boolean
  @Prop(Function) onClick!: () => void
  @Prop(Function) onVisible!: () => void
  @Prop(Boolean) loading!: boolean

  observer!: any

  get styleClass() {
    return (
      'f6 br2 bn pv2 light-gray ph3 pv1 bg-adori-red btn-shadow ' +
      (!this.disabled && !this.loading ? 'o-90 glow pointer' : 'o-40 not-allowed')
    )
  }

  async buttonAction() {
    if (!this.disabled && !this.loading) {
      await this.onClick()
    }
  }

  async callOnVisible() {
    if (!this.disabled && !this.loading) {
      await this.onVisible()
    }
  }

  mounted() {
    this.observer = new IntersectionObserver(
      (entry) => {
        if (entry[0].intersectionRatio !== 0) {
          this.callOnVisible()
        }
      },
      { rootMargin: `0px 0px ${this.rootBottomMargin}px 0px` }
    )
    const ele = this.$refs.loadMore as HTMLElement
    this.observer.observe(ele)
  }

  beforeDestroy() {
    this.observer.disconnect()
  }
}
