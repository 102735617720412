










































































































































































































































































































































import { Vue, Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import { ADORI_API_BASE_URL } from '@/constants'
import AudioPlayer from '@/components/Audio/AudioPlayer.vue'
import { toClockLike, secondsToMmss } from '@/utils/time'
import { insertIntoSortedArray } from '@/utils/misc'
import AdoriService from '@/services/adori'
import { validationMixin } from 'vuelidate'
import { required, maxLength, url, email, numeric } from 'vuelidate/lib/validators'

const validations = {
  selectedAd: {
    name: {
      required,
      maxLength: maxLength(255),
    },
    description: {
      maxLength: maxLength(255),
    },
  },
}

@Component({
  // @ts-ignore
  validations,
  components: { AudioPlayer },
})
export default class ModalAudioTrackPlay extends Mixins(validationMixin) {
  @Action clearAudioUploader!: any
  @Action setShowLoader!: any
  @Action closeModal!: any
  @Action getAudioAds!: any

  @Getter networkId!: any
  @Getter audioAds!: any
  @Getter audioAdsCount!: any

  currentTime = 0
  isPlaying = false
  uploadedAd: any = null
  finalAdsArray: any = []
  currentStep: any = 0
  audioSource: string = 'UPLOAD_NEW'
  selectedAd: any = null

  $v: any
  hasClickedNext: boolean = false

  showAdInfo: boolean = false
  currentAd: any = null

  toggleAdInfo(index: any) {
    if (this.currentAd === index) {
      this.showAdInfo = !this.showAdInfo
    } else {
      this.currentAd = index
      this.showAdInfo = true
    }
  }

  closeAdInfo() {
    this.currentAd = null
    this.showAdInfo = false
  }

  created() {
    this.clearAudioUploader()
  }

  async mounted() {
    // if (this.audioAdsCount === -1) {
    //   await this.getAudioAds()
    // }
  }

  beforeDestroy() {
    this.clearAudioUploader()
  }

  get sortedAvailableAdsArray() {
    return this.audioAds
      .filter((ad: any) => !ad.isDeleted)
      .sort((a: any, b: any) => (a.durationMillis > b.durationMillis ? 1 : -1))
  }

  get audioUid() {
    return this.$store.state.modal.audioTrackAdsEditor.uid
  }

  get duration() {
    return Math.ceil(this.$store.state.modal.audioTrackAdsEditor.durationMillis / 1000)
  }

  get audioSrc() {
    const token = this.$store.getters.token
    const networkId = this.$store.getters.networkId
    return `${ADORI_API_BASE_URL}/networks/${networkId}/audiotracks/${this.audioUid}/audio?api_token=${token}`
  }

  get audioPreviewImage() {
    return this.audio ? this.audio.imageInfo.url : null
  }

  get audio() {
    return this.$store.getters.audio(this.audioUid)
  }

  get playButtonIcon() {
    return (this.isPlaying ? 'pause' : 'play') + '_circle_outline'
  }

  get formattedCurrentTime() {
    return secondsToMmss(this.currentTime)
  }

  get formattedTotalTime() {
    return secondsToMmss(Math.ceil(this.audio.durationMillis / 1000))
  }

  formattedTime(time: any) {
    return secondsToMmss(Math.ceil(time / 1000))
  }

  get audioUploadId() {
    return this.$store.getters.audioUploadId
  }

  get audioUploadName() {
    return this.$store.getters.audioUploadName
  }

  get audioUploadDuration() {
    return this.$store.getters.audioUploadDuration
  }

  get hasAudioUploadId() {
    return !!this.$store.getters.audioUploadId
  }

  get isUploadingAudio() {
    return this.hasAudioUploadId && this.$store.getters.audioUploadProgress !== 1
  }

  get uploadProgress() {
    return Math.round(this.$store.getters.audioUploadProgress * 100)
  }

  get finalAdGroups() {
    return this.finalAdsArray.reduce((acc: any, obj: any, index: any) => {
      const key = obj['currentTime']
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(index)
      return acc
    }, {})
  }

  togglePlay() {
    if (!this.isUploadingAudio) {
      this.isPlaying = !this.isPlaying

      const audioRef: any = this.$refs.audio
      if (!this.isPlaying) audioRef.pause()
      else {
        audioRef.currentTime = this.currentTime
        audioRef.play()
      }
    }
  }

  updateTime() {
    const audioRef: any = this.$refs.audio
    this.currentTime = audioRef.currentTime
    if (Math.ceil(this.currentTime) > Math.ceil(this.audio.durationMillis / 1000)) {
      this.isPlaying = false
    }
  }

  handleSeekBackward() {
    if (!this.isUploadingAudio) {
      const audioRef: any = this.$refs.audio
      audioRef.currentTime = audioRef.currentTime - 10
    }
  }

  handleSeekForward() {
    if (!this.isUploadingAudio) {
      const audioRef: any = this.$refs.audio
      audioRef.currentTime = audioRef.currentTime + 10
    }
  }

  handleUploadAudio() {
    this.$store.dispatch('uploadNewAd')
  }

  handleInsertAd() {
    this.currentStep = 1
  }

  handlePrevTab() {
    if (this.currentStep === 1) {
      this.clearAudioUploader()
      this.uploadedAd = null
    } else if (this.currentStep === 2) {
      this.selectedAd = null
      this.uploadedAd = null
    }
    this.currentStep = this.currentStep - 1
  }

  handleNextTab() {
    if (this.currentStep === 1) {
      this.clearAudioUploader()
      this.selectedAd = {
        name: this.uploadedAd.name || '',
        description: this.uploadedAd.description || '',
      }
      this.currentStep = this.currentStep + 1
    } else if (this.currentStep === 2) {
      if (this.$v.selectedAd.name.$invalid || this.$v.selectedAd.description.$invalid) {
        this.hasClickedNext = true
      } else {
        this.finalAdsArray = insertIntoSortedArray(
          this.finalAdsArray,
          {
            ...this.uploadedAd,
            name: this.selectedAd.name,
            description: this.selectedAd.description,
          },
          'currentTime'
        )
        this.currentStep = 0
        this.uploadedAd = null
      }
    }
  }

  @Watch('currentStep')
  onCurrenStepChanged() {
    if (this.currentStep !== 2) {
      this.hasClickedNext = false
    }
  }

  @Watch('audioSource')
  onAudioSourceChanged() {
    this.uploadedAd = null
  }

  async handleSaveChanges() {
    this.setShowLoader(true)
    for (let ad of this.finalAdsArray.filter((ad: any) => ad.type === 'UPLOADED')) {
      ad.id = await this.$store.dispatch('createAudioAd', {
        name: ad.name,
        description: ad.description,
        id: ad.uploadId,
      })
    }
    await AdoriService.insertAudioAds(
      this.networkId,
      this.audioUid,
      this.finalAdsArray.map((ad: any) => ({
        adId: ad.id,
        markInMillis: Math.floor(ad.currentTime * 1000),
      }))
    )
    await this.$store.dispatch('getAudio', this.audioUid)
    this.closeModal()
    this.setShowLoader(false)
  }

  handleDeleteAd(index: any) {
    // TODO: figure out backend repurcussions of this
    this.finalAdsArray.splice(index, 1)
  }

  onInputChange(evt: any) {
    const audioRef: any = this.$refs.audio
    audioRef.currentTime = evt.target.value
  }

  @Watch('uploadProgress')
  onUploadProgress(progress: any) {
    if (progress === 100) {
      this.uploadedAd = {
        id: this.audioUploadId,
        name: this.audioUploadName,
        duration: secondsToMmss(this.audioUploadDuration),
        currentTime: this.currentTime,
        position: secondsToMmss(this.currentTime),
        type: 'UPLOADED',
      }
      this.clearAudioUploader()
      this.selectedAd = {
        name: this.uploadedAd.name || '',
        description: this.uploadedAd.description || '',
      }
      this.currentStep = this.currentStep + 1
    }
  }

  handleAudioAdClick(audioId: any) {
    const audioAd = this.audioAds.filter((ad: any) => ad.id === audioId)
    if (audioAd.length > 0) {
      this.finalAdsArray = insertIntoSortedArray(
        this.finalAdsArray,
        {
          name: audioAd[0].name || '',
          description: audioAd[0].description || '',
          id: audioAd[0].id,
          duration: secondsToMmss(Math.ceil(audioAd[0].durationMillis / 1000)),
          currentTime: this.currentTime,
          position: secondsToMmss(this.currentTime),
          type: 'EXISTING',
        },
        'currentTime'
      )
      this.selectedAd = null
      this.uploadedAd = null
      this.currentStep = 0
    }
  }
}
