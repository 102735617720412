









































































import { Component, Prop, Mixins } from 'vue-property-decorator'
import { mixin as clickaway } from 'vue-clickaway'
import debounce from 'lodash.debounce'
import { SUPPORT_VIDEO_MAIL } from '@/constants/env'

@Component
export default class BasePopupMenu extends Mixins(clickaway) {
  @Prop(String) icon!: string
  @Prop(Number) iconSize!: number
  @Prop(Array) items!: any[]
  @Prop(Array) additionalItems!: any[]
  @Prop(String) cursor!: string
  @Prop(String) position!: string
  @Prop(Boolean) isNetworksMenu!: boolean

  networkSearchValue: string = ''
  open = false
  showAdditional: boolean = false

  get isSupport() {
    return [SUPPORT_VIDEO_MAIL, 'support+youtube@adorilabs.com'].includes(this.$store.getters?.profile.email)
  }
  toggleMenu() {
    this.showAdditional = false
    this.open = true
  }

  setAdditionalItems() {
    this.showAdditional = true
  }

  resetAdditionalItems() {
    this.showAdditional = false
  }

  closeMenu() {
    this.open = false
  }

  handleItemClick(item: any) {
    item.onClick()
    this.open = false
  }

  debouncedSearch = debounce(() => {
    this.onNetworkSearchValueChanged()
  }, 600)

  async onNetworkSearchValueChanged() {
    await this.$store.dispatch('getMembersNetworks', { search: this.networkSearchValue })
  }
}
