var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vue-tel-input", {
    staticClass: "adoriPhoneInput scrollbar",
    style: _vm.isValidNumber
      ? "border: 1px solid transparent !important"
      : "border 1px solid red !important",
    attrs: {
      validCharactersOnly: "",
      enabledCountryCode: "",
      disabledFormatting: "",
      defaultCountry: "us",
      mode: "international",
    },
    on: { validate: _vm.validate, input: _vm.phoneNumberCurrentInput },
    model: {
      value: _vm.phoneNumber,
      callback: function ($$v) {
        _vm.phoneNumber = $$v
      },
      expression: "phoneNumber",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }