var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: _vm.transitionName } }, [
    _vm.showPane
      ? _c(
          "div",
          {
            directives: [
              {
                name: "on-clickaway",
                rawName: "v-on-clickaway",
                value: _vm.closePane,
                expression: "closePane",
              },
            ],
            staticClass: "bg-near-black items-center z-14",
            style: _vm.styles,
            on: {
              click: function ($event) {
                if ($event.target !== $event.currentTarget) {
                  return null
                }
                return _vm.handleClickedOutside()
              },
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "h-100 flex flex-column relative",
                class: _vm.bottomAnimation ? "bottom-animation" : "",
              },
              [
                _c("header", {}, [_vm._t("header")], 2),
                _c(
                  "section",
                  {
                    staticClass: "scrollbar pv1 overflow-y-scroll",
                    staticStyle: { flex: "1", "overscroll-behavior": "none" },
                  },
                  [_vm._t("body"), _vm._t("default")],
                  2
                ),
                _c("footer", {}, [_vm._t("footer")], 2),
                _vm.showArrow
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "absolute bg-near-black flex items-center justify-center pointer",
                        staticStyle: {
                          height: "4rem",
                          width: "1.3rem",
                          "border-radius": "1rem",
                          "border-top-right-radius": "0",
                          "border-bottom-right-radius": "0",
                        },
                        style: _vm.arrowStyles,
                        on: {
                          click: function ($event) {
                            return _vm.setShowPane(false)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "material-icons silver f5" }, [
                          _vm._v("chevron_right"),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }