var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "custom-h" }, [
    _c("div", { staticClass: "mh4" }, [
      _c(
        "div",
        {
          staticClass:
            "w-100 br2 flex flex-column items-center justify-center custom-h-drop b--light-gray b--dashed pointer",
          class:
            _vm.isUploadingAudio || _vm.dropped
              ? "not-allowed o-20"
              : _vm.bgDragColor
              ? "dark-fill"
              : "link",
          on: {
            dragover: [
              function ($event) {
                $event.preventDefault()
              },
              function ($event) {
                _vm.bgDragColor = true
              },
            ],
            dragleave: function ($event) {
              _vm.bgDragColor = false
            },
            drop: function ($event) {
              $event.preventDefault()
              return _vm.dropFile($event)
            },
            click: function ($event) {
              _vm.isUploadingAudio || _vm.dropped ? "" : _vm.handleUploadAudio()
            },
          },
        },
        [
          _c("i", { staticClass: "material-icons f-headline silver mb2" }, [
            _vm._v("file_upload"),
          ]),
          _c("div", { staticClass: "f4 mb2 silver" }, [
            _vm._v("Click to select file or drag it here"),
          ]),
          _vm.selectedFile
            ? _c("span", { staticClass: "mt2 gray" }, [
                _vm._v(_vm._s(_vm.selectedFileName)),
              ])
            : _vm._e(),
          _vm.selectedFile
            ? _c("span", { staticClass: "f6 gray" }, [
                _vm._v("(" + _vm._s(_vm.selectedFileSize) + ")"),
              ])
            : !_vm.selectedFile
            ? _c(
                "div",
                { staticClass: "flex flex-column items-center justify-center" },
                [
                  _c("div", { staticClass: "f6 silver mt1" }, [
                    _vm._v("Supports: mp3, mp4, m4a, wav, ogg, mpeg, webm"),
                  ]),
                  _c("div", { staticClass: "f6 silver mt1" }, [
                    _vm._v("Max Size: 500MB"),
                  ]),
                ]
              )
            : _vm._e(),
        ]
      ),
    ]),
    _vm.isUploadingAudio || _vm.uploadProgress === 100
      ? _c("div", { staticClass: "pt3 flex flex-column items-center" }, [
          _c("progress", {
            attrs: { max: "100" },
            domProps: { value: _vm.uploadProgress },
          }),
          _c("div", { staticClass: "f6 silver pt2" }, [
            _vm._v("Uploading media: " + _vm._s(_vm.uploadProgress + "%")),
          ]),
        ])
      : _vm._e(),
    !_vm.isUploadingAudio && _vm.isVideoPodcast
      ? _c(
          "div",
          {
            staticClass:
              "w-100 flex flex-column items-center justify-center mt3",
          },
          [
            _c("div", { staticClass: "flex justify-between" }, [
              _c(
                "div",
                { staticClass: "pb2 gray f6" },
                [
                  _vm._v("\n        Upload as Audio podcast\n        "),
                  _c("BaseTooltip", {
                    attrs: {
                      info: "Select 'Yes' if you want to convert your video to audio. Select 'No' if you want to publish your video as a background. ",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "div",
              {
                staticClass: "pv1 ba br4 b--transparent bg-black-20 tc",
                staticStyle: { width: "85px" },
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "f6 ttu pv1 ph2 silver link pointer",
                    class: _vm.audioPodcast
                      ? "br4 bg-black-80 light-gray white"
                      : "",
                  },
                  [
                    _c("input", {
                      staticClass: "input-reset",
                      attrs: { type: "radio" },
                      domProps: { checked: _vm.audioPodcast },
                      on: {
                        click: function ($event) {
                          _vm.audioPodcast = true
                        },
                      },
                    }),
                    _vm._v("Yes"),
                  ]
                ),
                _c(
                  "label",
                  {
                    staticClass: "f6 ttu pv1 ph2 silver link pointer",
                    class: !_vm.audioPodcast
                      ? "br4 bg-black-80 light-gray white"
                      : "",
                  },
                  [
                    _c("input", {
                      staticClass: "input-reset",
                      attrs: { type: "radio" },
                      domProps: { checked: !_vm.audioPodcast },
                      on: {
                        click: function ($event) {
                          _vm.audioPodcast = false
                        },
                      },
                    }),
                    _vm._v("No"),
                  ]
                ),
              ]
            ),
          ]
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "flex mt3 justify-center" },
      [
        _c("BaseButtonRed", {
          staticClass: "f4",
          attrs: {
            text: "NEXT",
            onClick: _vm.uploadEpisode,
            loading: _vm.isUploadingAudio,
            disabled: !_vm.selectedFile || _vm.isUploadingAudio,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }