var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-100 flex flex-column justify-around items-center" },
    [
      _c("div", { staticClass: "flex items-center" }, [
        _c("div", { staticClass: "mt2 mr2 flex w-50 flex-column" }, [
          _c("label", { staticClass: "f6 white" }, [_vm._v("Language")]),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.voiceLanguage,
                  expression: "voiceLanguage",
                },
              ],
              staticClass:
                "bg-adori-gray white f6 w-100 br2 h2 ba b--gray mt2 scrollbar",
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.voiceLanguage = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.pauseAudio,
                ],
              },
            },
            _vm._l(_vm.languages, function (item, i) {
              return _c(
                "option",
                { key: i, domProps: { value: item.languageCode } },
                [_vm._v("\n          " + _vm._s(item.language) + "\n        ")]
              )
            }),
            0
          ),
        ]),
        _c("div", { staticClass: "mt2 mr2 white" }, [
          _c("label", { staticClass: "f6" }, [_vm._v("Voice Type")]),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.voiceType,
                  expression: "voiceType",
                },
              ],
              staticClass:
                "bg-adori-gray white f6 w-100 br2 h2 ba b--gray mt2 scrollbar",
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.voiceType = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.pauseAudio,
                ],
              },
            },
            _vm._l(_vm.voiceTypeList, function (item, i) {
              return _c("option", { key: i, domProps: { value: item.value } }, [
                _vm._v("\n          " + _vm._s(item.title) + "\n        "),
              ])
            }),
            0
          ),
        ]),
        _c("div", { staticClass: "mt2" }, [
          _c("label", { staticClass: "f6 white" }, [_vm._v("Voice gender")]),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.voiceGender,
                  expression: "voiceGender",
                },
              ],
              staticClass:
                "bg-adori-gray white f6 w-100 br2 h2 ba b--gray mt2 scrollbar",
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.voiceGender = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.pauseAudio,
                ],
              },
            },
            _vm._l(_vm.voiceGenderList, function (item, i) {
              return _c("option", { key: i, domProps: { value: item.value } }, [
                _vm._v("\n          " + _vm._s(item.title) + "\n        "),
              ])
            }),
            0
          ),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass:
            "flex justify-center silver mt3 items-center w-80 flex-wrap overflow-scroll overflow-x-hidden scrollbar ba b--gray pa2 br3",
          staticStyle: { height: "130px" },
        },
        [
          _vm.isVoiceFetching
            ? _c("div", { staticClass: "loader" })
            : _vm.voicesList.length === 0
            ? _c("div", [
                _vm._v("No Voices found!! select different voice type"),
              ])
            : _c(
                "div",
                { staticClass: "flex justify-center flex-wrap" },
                _vm._l(_vm.voicesList, function (voice) {
                  return _c(
                    "div",
                    {
                      staticClass: "mr2 mb2 pa2 ba br3 flex items-center",
                      class:
                        _vm.selectedVoice.name === voice.name
                          ? "b--adori-red bg-adori-red white	"
                          : "b--grey",
                      on: {
                        click: function ($event) {
                          return _vm.selectVoice(voice)
                        },
                      },
                    },
                    [
                      _c("span", [_vm._v(_vm._s(voice.name))]),
                      _vm.selectedVoice.name === voice.name &&
                      _vm.isAudioPlaying
                        ? _c(
                            "i",
                            {
                              staticClass:
                                "material-icons light-gray f4 v-mid pa1 ml1",
                              on: { click: _vm.pauseAudio },
                            },
                            [_vm._v("pause_circle_outline")]
                          )
                        : _c(
                            "i",
                            {
                              staticClass:
                                "material-icons light-gray f4 v-mid pa1 ml1",
                              on: {
                                click: function ($event) {
                                  return _vm.playAudio(voice)
                                },
                              },
                            },
                            [_vm._v("volume_up")]
                          ),
                    ]
                  )
                }),
                0
              ),
        ]
      ),
      _c(
        "div",
        [
          _c("BaseButtonRed", {
            staticClass: "mt3",
            attrs: {
              text: _vm.buttonTitle,
              onClick: _vm.submitVoiceDetails,
              disabled: _vm.disabled,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }