var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    { attrs: { locked: _vm.isSubmitting } },
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "flex items-center" }, [
          _c("div", { staticClass: "light-gray f3 fw5" }, [
            _vm._v("Add an episode"),
          ]),
          _vm.selectedAudioName
            ? _c(
                "div",
                {
                  staticClass: "silver f5 ml3 truncate",
                  staticStyle: { "max-width": "380px" },
                },
                [
                  _vm._v(
                    "\n        (Selected audio: " +
                      _vm._s(_vm.selectedAudioName) +
                      "\n      "
                  ),
                ]
              )
            : _vm._e(),
          _vm.selectedAudioName
            ? _c("span", { staticClass: "silver f5" }, [_vm._v(")")])
            : _vm._e(),
        ]),
      ]),
      _c("template", { slot: "body" }, [
        !_vm.enableImageGallery
          ? _c("div", { staticClass: "flex flex-wrap" }, [
              _c(
                "div",
                {
                  staticClass: "flex flex-column",
                  staticStyle: { width: "760px", height: "600px" },
                },
                [
                  _vm.currentStep === 1
                    ? _c("div", { staticClass: "mb3 flex flex-column" }, [
                        _c(
                          "div",
                          { staticClass: "flex flex-column f6 white" },
                          [
                            _c("div", { staticClass: "mb2 input" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.fileType,
                                    expression: "fileType",
                                  },
                                ],
                                staticClass: "mr2",
                                attrs: {
                                  type: "radio",
                                  value: "USING_AUDIO",
                                  id: "USING_AUDIO",
                                },
                                domProps: {
                                  checked: _vm._q(_vm.fileType, "USING_AUDIO"),
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.fileType = "USING_AUDIO"
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "pointer",
                                  attrs: { for: "USING_AUDIO" },
                                },
                                [_vm._v("I have the audio ready")]
                              ),
                            ]),
                            _c("div", { staticClass: "mb4 input" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.fileType,
                                    expression: "fileType",
                                  },
                                ],
                                staticClass: "mr2",
                                attrs: {
                                  type: "radio",
                                  value: "USING_TEXT",
                                  id: "USING_TEXT",
                                },
                                domProps: {
                                  checked: _vm._q(_vm.fileType, "USING_TEXT"),
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.fileType = "USING_TEXT"
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "pointer",
                                  attrs: { for: "USING_TEXT" },
                                },
                                [
                                  _vm._v(
                                    "I have the text file ready. Use text-to-speech converter to create audio"
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                        _vm.fileType === "USING_AUDIO"
                          ? _c("div", { staticClass: "mb3 flex flex-column" }, [
                              _c(
                                "div",
                                { staticClass: "pb4 ml-auto mr-auto" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pv1 ba bw2 br4 b--transparent bg-black-20",
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "pv1 ph2 silver link pointer mr1",
                                          class:
                                            _vm.audioSource === "UPLOAD_NEW"
                                              ? "ba br4 b--transparent bg-black-80 light-gray"
                                              : "",
                                        },
                                        [
                                          _c("input", {
                                            staticClass: "input-reset",
                                            attrs: { type: "radio" },
                                            domProps: {
                                              checked:
                                                _vm.audioSource ===
                                                "UPLOAD_NEW",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.audioSource = "UPLOAD_NEW"
                                              },
                                            },
                                          }),
                                          _vm._v("Upload new audio"),
                                        ]
                                      ),
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "pv1 ph2 silver link pointer",
                                          class:
                                            _vm.audioSource ===
                                            "SELECT_EXISTING"
                                              ? "ba br4 b--transparent bg-black-80 light-gray"
                                              : "",
                                        },
                                        [
                                          _c("input", {
                                            staticClass: "input-reset",
                                            attrs: { type: "radio" },
                                            domProps: {
                                              checked:
                                                _vm.audioSource ===
                                                "SELECT_EXISTING",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.audioSource =
                                                  "SELECT_EXISTING"
                                              },
                                            },
                                          }),
                                          _vm._v("Select existing audio"),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm.audioSource === "UPLOAD_NEW"
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex flex-column items-center pa4",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-100 h5 bg-adori-light-gray ba b--light-gray b--dashed br2 pa5 flex flex-column items-center justify-center",
                                          class: _vm.isUploadingAudio
                                            ? "not-allowed o-20"
                                            : "link pointer dim",
                                          on: { click: _vm.handleUploadAudio },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "material-icons f-headline silver mb2",
                                            },
                                            [_vm._v("publish")]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "f4 silver" },
                                            [_vm._v("Upload a new audio")]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "f6 silver mt3" },
                                            [
                                              _vm._v(
                                                "Supports: mp3, mp4, m4a, wav, ogg, mpeg, webm"
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm.isUploadingAudio ||
                                      _vm.uploadProgress === 100
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "pt4 flex flex-column items-center",
                                            },
                                            [
                                              _c("progress", {
                                                attrs: { max: "100" },
                                                domProps: {
                                                  value: _vm.uploadProgress,
                                                },
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "f6 silver pt2",
                                                },
                                                [
                                                  _vm._v(
                                                    "Uploading audio: " +
                                                      _vm._s(
                                                        _vm.uploadProgress + "%"
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.audioSource === "SELECT_EXISTING"
                                ? _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex relative sticky z-999",
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.audioSearchValue,
                                              expression: "audioSearchValue",
                                            },
                                          ],
                                          ref: "_searchAudios",
                                          staticClass:
                                            "bg-adori-gray bw0 br2 pl3 pr5 pv2 f6 white",
                                          staticStyle: {
                                            "margin-bottom": "10px",
                                            border: "1px solid #3f3f3f",
                                            width: "100%",
                                          },
                                          attrs: {
                                            autofocus: "",
                                            placeholder: "Search audio tracks",
                                          },
                                          domProps: {
                                            value: _vm.audioSearchValue,
                                          },
                                          on: {
                                            keyup: function ($event) {
                                              $event.preventDefault()
                                              return _vm.debouncedSearch()
                                            },
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.audioSearchValue =
                                                $event.target.value
                                            },
                                          },
                                        }),
                                        _vm.audioSearchValue.length === 0
                                          ? _c(
                                              "i",
                                              {
                                                staticClass:
                                                  "material-icons silver absolute",
                                                staticStyle: {
                                                  top: "5px",
                                                  right: "10px",
                                                },
                                              },
                                              [_vm._v("search")]
                                            )
                                          : _c(
                                              "i",
                                              {
                                                staticClass:
                                                  "material-icons silver absolute pointer dim",
                                                staticStyle: {
                                                  top: "5px",
                                                  right: "10px",
                                                },
                                                on: { click: _vm.clearSearch },
                                              },
                                              [_vm._v("close")]
                                            ),
                                      ]
                                    ),
                                    _vm.isSearchingAudio
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "pa4 tc silver flex justify-center",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "dib mr2" },
                                              [_vm._v("Searching audio tracks")]
                                            ),
                                            _c("div", {
                                              staticClass:
                                                "loader loader-inline mh1",
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                    !_vm.isSearchingAudio && !_vm.isGettingData
                                      ? _c("div", [
                                          _vm.allAudioUids.length !== 0 &&
                                          _vm.paginatedAudioUids.length !== 0
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "bg-adori-light-gray br2 ba b--adori-gray white max-h overflow-y-auto scrollbar",
                                                },
                                                [
                                                  _vm._l(
                                                    _vm.paginatedAudioUids,
                                                    function (audioUid) {
                                                      return _c("AudioTrack", {
                                                        key: audioUid,
                                                        attrs: {
                                                          audioUid: audioUid,
                                                          sourceComponent:
                                                            "EPISODE-CREATE",
                                                          selectable: "",
                                                          selectionType:
                                                            "SINGLE",
                                                          videoClips: [],
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  _vm.hasItemsLeft
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "tc pv3 ph4 bt b--adori-gray bw1",
                                                        },
                                                        [
                                                          _c(
                                                            "BaseButtonLoadMore",
                                                            {
                                                              attrs: {
                                                                text: "Load more",
                                                                rootBottomMargin:
                                                                  "800",
                                                                onClick:
                                                                  _vm.hasItemsLeftLoad,
                                                                onVisible:
                                                                  _vm.hasItemsLeftLoad,
                                                                loading:
                                                                  _vm.isLoading(),
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                2
                                              )
                                            : !_vm.audioSearchValue
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "pa4 tc light-gray",
                                                },
                                                [
                                                  _vm._v(
                                                    "You don't have any audio tracks!"
                                                  ),
                                                ]
                                              )
                                            : _vm.allAudioUids.length === 0 &&
                                              _vm.audioSearchValue
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "pa4 tc light-gray",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  No audio results found! Try searching with a complete word.\n                "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ])
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _vm.fileType === "USING_TEXT"
                          ? _c(
                              "div",
                              { staticClass: "flex flex-column items-center" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "w-100 bg-adori-light-gray ba b--light-gray b--dashed br2 pa3 flex flex-column items-center justify-center link pointer dim",
                                    on: { click: _vm.handleUploadFile },
                                  },
                                  [
                                    _c(
                                      "i",
                                      {
                                        staticClass:
                                          "material-icons f-headline silver mb2 f1",
                                      },
                                      [_vm._v("upload_file")]
                                    ),
                                    _c("div", { staticClass: "f4 silver" }, [
                                      _vm._v("Upload .pdf or .txt"),
                                    ]),
                                    _vm.selectedTextFile
                                      ? _c(
                                          "span",
                                          { staticClass: "mt2 gray" },
                                          [_vm._v(_vm._s(_vm.selectedFileName))]
                                        )
                                      : _vm._e(),
                                    _vm.selectedTextFile
                                      ? _c("span", { staticClass: "f6 gray" }, [
                                          _vm._v(
                                            "(" +
                                              _vm._s(_vm.selectedFileSize) +
                                              ")"
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex justify-between items-center mt3",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "ba bw1 br4 b--transparent bg-black-20 flex items-center",
                                      },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "f6 pv1 ph2 silver link pointer mr1",
                                            class:
                                              _vm.selectedTab === "BASIC"
                                                ? "ba br4 b--transparent bg-black-80 light-gray"
                                                : "",
                                          },
                                          [
                                            _c("input", {
                                              staticClass: "input-reset",
                                              attrs: { type: "radio" },
                                              domProps: {
                                                checked:
                                                  _vm.selectedTab === "BASIC",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.selectedTab = "BASIC"
                                                },
                                              },
                                            }),
                                            _vm._v(
                                              "Basic Voices\n                "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "f6 pv1 ph2 silver link pointer mr1",
                                            class:
                                              _vm.selectedTab === "PREMIUM"
                                                ? "ba br4 b--transparent bg-black-80 light-gray"
                                                : "",
                                          },
                                          [
                                            _c("input", {
                                              staticClass: "input-reset",
                                              attrs: { type: "radio" },
                                              domProps: {
                                                checked:
                                                  _vm.selectedTab === "PREMIUM",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.selectedTab = "PREMIUM"
                                                },
                                              },
                                            }),
                                            _vm._v(
                                              "Pro voices 🌟\n                "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "f6 pv1 ph2 silver link pointer mr1",
                                            class:
                                              _vm.selectedTab === "CLONING"
                                                ? "ba br4 b--transparent bg-black-80 light-gray"
                                                : "",
                                          },
                                          [
                                            _c("input", {
                                              staticClass: "input-reset",
                                              attrs: { type: "radio" },
                                              domProps: {
                                                checked:
                                                  _vm.selectedTab === "CLONING",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.selectedTab = "CLONING"
                                                },
                                              },
                                            }),
                                            _vm._v(
                                              "Build your voice\n                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("VoiceSelection", {
                                  staticClass: "mt3",
                                  attrs: {
                                    buttonTitle: "Next",
                                    disabled: !_vm.hasCompletedStep1,
                                    quality: _vm.selectedTab,
                                  },
                                  on: { "voice-details": _vm.handleNextTab },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm.currentStep !== 1
                    ? _c("div", [
                        _c(
                          "form",
                          {
                            staticClass: "w-100 mr3 flex flex-column",
                            on: {
                              submit: function ($event) {
                                $event.preventDefault()
                              },
                            },
                          },
                          [
                            _vm.currentStep === 2
                              ? _c(
                                  "div",
                                  { staticClass: "flex justify-between" },
                                  [
                                    _c("div", { staticClass: "mr3" }, [
                                      _c(
                                        "div",
                                        { staticClass: "pb3 relative" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "pb2 gray f6" },
                                            [_vm._v("Episode image")]
                                          ),
                                          _c("BaseThumbnailEpisode", {
                                            attrs: {
                                              onClick: _vm.showImageGallery,
                                              src: _vm.selectedImageSrc,
                                              big: "",
                                            },
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "mv2",
                                              class:
                                                (_vm.hasClickedImage &&
                                                  !_vm.hasPreviewImage) ||
                                                (_vm.hasClickedNext &&
                                                  !_vm.hasPreviewImage)
                                                  ? "ba bw1 b--adori-red br2"
                                                  : "ba bw1 b--adori-transparent",
                                            },
                                            [
                                              _c("BaseButtonBorder", {
                                                staticClass: "w-100",
                                                attrs: {
                                                  text: "Change episode image",
                                                  onClick: _vm.showImageGallery,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          (_vm.hasClickedImage &&
                                            !_vm.hasPreviewImage) ||
                                          (_vm.hasClickedNext &&
                                            !_vm.hasPreviewImage)
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "f6 absolute adori-red",
                                                  staticStyle: {
                                                    bottom: "5px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    Podcast image is required.\n                  "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex justify-start items-center pb3",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "f6 link pointer dim silver flex items-center",
                                              on: {
                                                click: function ($event) {
                                                  _vm.showAdvancedOptions =
                                                    !_vm.showAdvancedOptions
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "i",
                                                {
                                                  staticClass:
                                                    "material-icons f6 mr1",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.showAdvancedOptions
                                                          ? "remove_circle"
                                                          : "add_circle"
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "mt1" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.showAdvancedOptions
                                                        ? "Hide"
                                                        : "Show"
                                                    ) + " advanced options"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm.showAdvancedOptions
                                        ? _c("div", { staticClass: "pb3" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "flex justify-between",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "pb2 gray f6",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Keywords (optional)"
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "pb2 f6",
                                                    class: !_vm.$v
                                                      .selectedEpisode.keywords
                                                      .maxLength
                                                      ? "adori-red"
                                                      : "gray",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      (" +
                                                        _vm._s(
                                                          _vm.$v.selectedEpisode
                                                            .keywords.$model
                                                            .length
                                                        ) +
                                                        " / 255)\n                    "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model.trim",
                                                  value:
                                                    _vm.$v.selectedEpisode
                                                      .keywords.$model,
                                                  expression:
                                                    "$v.selectedEpisode.keywords.$model",
                                                  modifiers: { trim: true },
                                                },
                                              ],
                                              staticClass:
                                                "db bg-adori-very-light-gray br2 ph3 pv3 gray f6 mb2 white w-100",
                                              class: _vm.$v.selectedEpisode
                                                .keywords.$error
                                                ? "ba bw1 b--adori-red"
                                                : "bw0",
                                              attrs: {
                                                type: "text",
                                                placeholder:
                                                  "Enter keywords separated by comma",
                                              },
                                              domProps: {
                                                value:
                                                  _vm.$v.selectedEpisode
                                                    .keywords.$model,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.$v.selectedEpisode
                                                      .keywords,
                                                    "$model",
                                                    $event.target.value.trim()
                                                  )
                                                },
                                                blur: function ($event) {
                                                  return _vm.$forceUpdate()
                                                },
                                              },
                                            }),
                                            _vm.$v.selectedEpisode.keywords
                                              .$dirty
                                              ? _c("div", [
                                                  !_vm.$v.selectedEpisode
                                                    .keywords.maxLength
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "f6 adori-red",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      Must be less than 255 characters.\n                    "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ])
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                      _vm.showAdvancedOptions
                                        ? _c("div", { staticClass: "pb3" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "flex justify-between",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "pb2 gray f6",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      Does this episode contain explicit content?\n                      "
                                                    ),
                                                    _c("BaseTooltip", {
                                                      attrs: {
                                                        info: "This indicates whether the episode contain explicit language or adult content.",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "pv1 ba br4 b--transparent bg-black-20 tc",
                                                staticStyle: { width: "85px" },
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "f6 ttu pv1 ph2 silver link pointer",
                                                    class: _vm.$v
                                                      .selectedEpisode
                                                      .explicitContent.$model
                                                      ? "br4 bg-black-80 light-gray white"
                                                      : "",
                                                  },
                                                  [
                                                    _c("input", {
                                                      staticClass:
                                                        "input-reset",
                                                      attrs: { type: "radio" },
                                                      domProps: {
                                                        checked:
                                                          _vm.$v.selectedEpisode
                                                            .explicitContent
                                                            .$model,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.$v.selectedEpisode.explicitContent.$model = true
                                                        },
                                                      },
                                                    }),
                                                    _vm._v("Yes"),
                                                  ]
                                                ),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "f6 ttu pv1 ph2 silver link pointer",
                                                    class: !_vm.$v
                                                      .selectedEpisode
                                                      .explicitContent.$model
                                                      ? "br4 bg-black-80 light-gray white"
                                                      : "",
                                                  },
                                                  [
                                                    _c("input", {
                                                      staticClass:
                                                        "input-reset",
                                                      attrs: { type: "radio" },
                                                      domProps: {
                                                        checked:
                                                          !_vm.$v
                                                            .selectedEpisode
                                                            .explicitContent
                                                            .$model,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.$v.selectedEpisode.explicitContent.$model = false
                                                        },
                                                      },
                                                    }),
                                                    _vm._v("No"),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm.$v.selectedEpisode
                                              .explicitContent.$dirty
                                              ? _c("div", [
                                                  !_vm.$v.selectedEpisode
                                                    .explicitContent.required
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "f6 adori-red",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      Cannot be empty.\n                    "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ])
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                    ]),
                                    _c("div", { staticClass: "pl3" }, [
                                      _c(
                                        "div",
                                        { staticClass: "pb3 relative" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex justify-between",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "pb2 gray f6" },
                                                [
                                                  _vm._v(
                                                    "\n                      Episode name\n                      "
                                                  ),
                                                  _c("BaseTooltip", {
                                                    attrs: {
                                                      info: "Episode name is a string containing a clear, concise name for your episode.",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "pb2 f6",
                                                  class: !_vm.$v.selectedEpisode
                                                    .name.maxLength
                                                    ? "adori-red"
                                                    : "gray",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      (" +
                                                      _vm._s(
                                                        _vm.$v.selectedEpisode
                                                          .name.$model.length
                                                      ) +
                                                      " / 255)\n                    "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.trim",
                                                value:
                                                  _vm.$v.selectedEpisode.name
                                                    .$model,
                                                expression:
                                                  "$v.selectedEpisode.name.$model",
                                                modifiers: { trim: true },
                                              },
                                            ],
                                            staticClass:
                                              "db bg-adori-very-light-gray br2 ph3 pv3 gray f6 mb2 white w-100",
                                            class:
                                              _vm.$v.selectedEpisode.name
                                                .$error ||
                                              (_vm.$v.selectedEpisode.name
                                                .$invalid &&
                                                _vm.hasClickedNext)
                                                ? "ba bw1 b--adori-red"
                                                : "ba bw1 b--adori-transparent",
                                            attrs: {
                                              type: "text",
                                              placeholder: "Enter episode name",
                                            },
                                            domProps: {
                                              value:
                                                _vm.$v.selectedEpisode.name
                                                  .$model,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.$v.selectedEpisode.name,
                                                  "$model",
                                                  $event.target.value.trim()
                                                )
                                              },
                                              blur: function ($event) {
                                                return _vm.$forceUpdate()
                                              },
                                            },
                                          }),
                                          _vm.$v.selectedEpisode.name.$dirty &&
                                          !_vm.hasClickedNext
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "absolute",
                                                  staticStyle: {
                                                    bottom: "5px",
                                                  },
                                                },
                                                [
                                                  !_vm.$v.selectedEpisode.name
                                                    .required
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "f6 adori-red",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Cannot be empty."
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  !_vm.$v.selectedEpisode.name
                                                    .maxLength
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "f6 adori-red",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      Must be less than 255 characters.\n                    "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              )
                                            : _vm.hasClickedNext
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "absolute",
                                                  staticStyle: {
                                                    bottom: "5px",
                                                  },
                                                },
                                                [
                                                  _vm.$v.selectedEpisode.name
                                                    .$invalid
                                                    ? _c("div", [
                                                        !_vm.$v.selectedEpisode
                                                          .name.required
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "f6 adori-red",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Cannot be empty."
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        !_vm.$v.selectedEpisode
                                                          .name.maxLength
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "f6 adori-red",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                        Must be less than 255 characters.\n                      "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "pb3 relative" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex justify-between",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "pb2 gray f6" },
                                                [
                                                  _vm._v(
                                                    "\n                      Episode description\n                      "
                                                  ),
                                                  _c("BaseTooltip", {
                                                    attrs: {
                                                      info: "Episode description is text containing one or more sentences describing your episode to potential listeners.",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c("HTMLEditor", {
                                            staticClass:
                                              "bg-adori-very-light-gray bw0 br2 ph3 pv1 white mb2 overflow-y-auto scrollbar",
                                            class:
                                              _vm.$v.selectedEpisode.description
                                                .$error ||
                                              (_vm.$v.selectedEpisode
                                                .description.$invalid &&
                                                _vm.hasClickedNext)
                                                ? "ba bw1 b--adori-red"
                                                : "ba bw1 b--adori-transparent",
                                            staticStyle: {
                                              width: "394px",
                                              height: "180px",
                                            },
                                            attrs: {
                                              placeholder:
                                                "Enter episode description",
                                              initialContent:
                                                _vm.$v.selectedEpisode
                                                  .description.$model,
                                            },
                                            on: {
                                              update: _vm.updateDescription,
                                            },
                                          }),
                                          _vm.$v.selectedEpisode.description
                                            .$invalid &&
                                          _vm.$v.selectedEpisode.description
                                            .$dirty &&
                                          !_vm.hasClickedNext
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "absolute",
                                                  staticStyle: {
                                                    bottom: "5px",
                                                  },
                                                },
                                                [
                                                  !_vm.$v.selectedEpisode
                                                    .description.required ||
                                                  !_vm.$v.selectedEpisode
                                                    .description.notEmptyHTML
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "f6 adori-red",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      Cannot be empty\n                    "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  !_vm.$v.selectedEpisode
                                                    .description.maxLength
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "f6 adori-red",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      Your episode description is too long.\n                    "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.hasClickedNext
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "absolute",
                                                  staticStyle: {
                                                    bottom: "5px",
                                                  },
                                                },
                                                [
                                                  !_vm.$v.selectedEpisode
                                                    .description.required ||
                                                  !_vm.$v.selectedEpisode
                                                    .description.notEmptyHTML
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "f6 adori-red",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      Cannot be empty\n                    "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  !_vm.$v.selectedEpisode
                                                    .description.maxLength
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "f6 adori-red",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      Your episode description is too long.\n                    "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "pb3 relative" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex justify-between",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "pb2 gray f6" },
                                                [
                                                  _vm._v(
                                                    "\n                      Episode summary\n                      "
                                                  ),
                                                  _c("BaseTooltip", {
                                                    attrs: {
                                                      info: "Episode summary is best for a short description of your episode. It will display above the full show notes.",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "pb2 f6",
                                                  class: !_vm.$v.selectedEpisode
                                                    .summary.maxLength
                                                    ? "adori-red"
                                                    : "gray",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      (" +
                                                      _vm._s(
                                                        _vm.$v.selectedEpisode
                                                          .summary.$model.length
                                                      ) +
                                                      " / 255)\n                    "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c("textarea", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.trim",
                                                value:
                                                  _vm.$v.selectedEpisode.summary
                                                    .$model,
                                                expression:
                                                  "$v.selectedEpisode.summary.$model",
                                                modifiers: { trim: true },
                                              },
                                            ],
                                            staticClass:
                                              "db bg-adori-very-light-gray bw0 br2 ph3 pv3 gray f6 white mb2 overflow-y-auto scrollbar w-100",
                                            class:
                                              _vm.$v.selectedEpisode.summary
                                                .$error ||
                                              (_vm.$v.selectedEpisode.summary
                                                .$invalid &&
                                                _vm.hasClickedNext)
                                                ? "ba bw1 b--adori-red"
                                                : "ba bw1 b--adori-transparent",
                                            attrs: {
                                              rows: "1",
                                              cols: "40",
                                              placeholder:
                                                "Enter episode summary",
                                            },
                                            domProps: {
                                              value:
                                                _vm.$v.selectedEpisode.summary
                                                  .$model,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.$v.selectedEpisode
                                                    .summary,
                                                  "$model",
                                                  $event.target.value.trim()
                                                )
                                              },
                                              blur: function ($event) {
                                                return _vm.$forceUpdate()
                                              },
                                            },
                                          }),
                                          _vm.$v.selectedEpisode.summary
                                            .$dirty && !_vm.hasClickedNext
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "absolute",
                                                  staticStyle: {
                                                    bottom: "5px",
                                                  },
                                                },
                                                [
                                                  !_vm.$v.selectedEpisode
                                                    .summary.required
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "f6 adori-red",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Cannot be empty."
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  !_vm.$v.selectedEpisode
                                                    .summary.maxLength
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "f6 adori-red",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      Must be less than 255 characters.\n                    "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              )
                                            : _vm.hasClickedNext
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "absolute",
                                                  staticStyle: {
                                                    bottom: "5px",
                                                  },
                                                },
                                                [
                                                  _vm.$v.selectedEpisode.summary
                                                    .$invalid
                                                    ? _c("div", [
                                                        !_vm.$v.selectedEpisode
                                                          .summary.required
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "f6 adori-red",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Cannot be empty."
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        !_vm.$v.selectedEpisode
                                                          .summary.maxLength
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "f6 adori-red",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                        Must be less than 255 characters.\n                      "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "pb3 scrollbar" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex justify-between",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "pb2 gray f6" },
                                                [
                                                  _vm._v(
                                                    "\n                      Episode language\n                      "
                                                  ),
                                                  _c("BaseTooltip", {
                                                    attrs: {
                                                      info: "Language used in audio.",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "h3 scrollbar" },
                                            [
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.selectedLanguage,
                                                      expression:
                                                        "selectedLanguage",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "input-reset light-gray bn br2 pa3 pr5 shadow-2 db arrow w-100 mb2 w2 scrollbar",
                                                  attrs: {
                                                    disabled:
                                                      _vm.languageDisabled,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$selectedVal =
                                                        Array.prototype.filter
                                                          .call(
                                                            $event.target
                                                              .options,
                                                            function (o) {
                                                              return o.selected
                                                            }
                                                          )
                                                          .map(function (o) {
                                                            var val =
                                                              "_value" in o
                                                                ? o._value
                                                                : o.value
                                                            return val
                                                          })
                                                      _vm.selectedLanguage =
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                    },
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.languages || [],
                                                  function (lang) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        key: lang.code,
                                                        staticClass:
                                                          "scrollbar",
                                                        staticStyle: {
                                                          width: "30px",
                                                        },
                                                        domProps: {
                                                          value: lang.code,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(lang.name) +
                                                            "\n                      "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm.audioSource === "UPLOAD_NEW"
                                        ? _c("div", { staticClass: "pb3" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "flex justify-between",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "pb2 gray f6",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Add suggested interactive tags to the audio?"
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "flex justify-start items-center",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "ba bw1 br4 b--transparent bg-black-20 flex items-center tv",
                                                    staticStyle: {
                                                      "flex-shrink": "0",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "f6 pv1 ph2 silver link pointer",
                                                        class:
                                                          _vm.shouldAutoTagAudio
                                                            ? "ba br4 b--transparent bg-black-80 light-gray"
                                                            : "",
                                                      },
                                                      [
                                                        _c("input", {
                                                          staticClass:
                                                            "input-reset",
                                                          attrs: {
                                                            type: "radio",
                                                          },
                                                          domProps: {
                                                            checked:
                                                              _vm.shouldAutoTagAudio,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.shouldAutoTagAudio = true
                                                            },
                                                          },
                                                        }),
                                                        _vm._v("Yes"),
                                                      ]
                                                    ),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "f6 pv1 ph2 silver link pointer",
                                                        class:
                                                          !_vm.shouldAutoTagAudio
                                                            ? "ba br4 b--transparent bg-black-80 light-gray"
                                                            : "",
                                                      },
                                                      [
                                                        _c("input", {
                                                          staticClass:
                                                            "input-reset",
                                                          attrs: {
                                                            type: "radio",
                                                          },
                                                          domProps: {
                                                            checked:
                                                              !_vm.shouldAutoTagAudio,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.shouldAutoTagAudio = false
                                                            },
                                                          },
                                                        }),
                                                        _vm._v("No"),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm.currentStep === 3
                              ? _c(
                                  "div",
                                  { staticClass: "flex justify-between" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex flex-column mr3 w-47",
                                      },
                                      [
                                        _c("div", { staticClass: "pb3" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex justify-between",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "pb2 gray f6" },
                                                [
                                                  _vm._v(
                                                    "\n                      Type of episode\n                      "
                                                  ),
                                                  _c("BaseTooltip", {
                                                    attrs: {
                                                      info: "“Regular” for normal episodes; “Trailer” to promote an upcoming show, season, or episode; or “Bonus” for extra content related to a show, season, or episode.",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.$v.selectedEpisode
                                                      .episodeType.$model,
                                                  expression:
                                                    "$v.selectedEpisode.episodeType.$model",
                                                },
                                              ],
                                              staticClass:
                                                "input-reset light-gray bn br2 pa3 pr5 shadow-2 db arrow w-100 mb2",
                                              on: {
                                                change: function ($event) {
                                                  var $$selectedVal =
                                                    Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function (o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function (o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                  _vm.$set(
                                                    _vm.$v.selectedEpisode
                                                      .episodeType,
                                                    "$model",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                },
                                              },
                                            },
                                            _vm._l(
                                              _vm.episodeTypes,
                                              function (type) {
                                                return _c(
                                                  "option",
                                                  {
                                                    key: type.value,
                                                    domProps: {
                                                      value: type.value,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(type.text) +
                                                        "\n                    "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                          _vm.$v.selectedEpisode.episodeType
                                            .$dirty
                                            ? _c("div", [
                                                !_vm.$v.selectedEpisode
                                                  .episodeType.required
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "f6 adori-red",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Cannot be empty."
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ])
                                            : _vm._e(),
                                        ]),
                                        _c("div", { staticClass: "pb2" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex justify-between",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "pb2 gray f6" },
                                                [
                                                  _vm._v(
                                                    "\n                      Does this episode belong to a season?\n                      "
                                                  ),
                                                  _c("BaseTooltip", {
                                                    attrs: {
                                                      info: "Any number to indicate the season in which this episode belongs. This can be used with any show and episode type.",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "pv1 ba br4 b--transparent bg-black-20 tc",
                                              staticStyle: { width: "85px" },
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "f6 ttu pv1 ph2 silver link pointer",
                                                  class: _vm.belongsToSeason
                                                    ? "br4 bg-black-80 light-gray"
                                                    : "",
                                                },
                                                [
                                                  _c("input", {
                                                    staticClass: "input-reset",
                                                    attrs: { type: "radio" },
                                                    domProps: {
                                                      checked:
                                                        _vm.belongsToSeason,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.belongsToSeason = true
                                                      },
                                                    },
                                                  }),
                                                  _vm._v("Yes"),
                                                ]
                                              ),
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "f6 ttu pv1 ph2 silver link pointer",
                                                  class: !_vm.belongsToSeason
                                                    ? "br4 bg-black-80 light-gray"
                                                    : "",
                                                },
                                                [
                                                  _c("input", {
                                                    staticClass: "input-reset",
                                                    attrs: { type: "radio" },
                                                    domProps: {
                                                      checked:
                                                        !_vm.belongsToSeason,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.belongsToSeason = false
                                                      },
                                                    },
                                                  }),
                                                  _vm._v("No"),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "pb3",
                                            class: !_vm.belongsToSeason
                                              ? "o-50"
                                              : "o-100",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "flex justify-between",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "pb2 gray f6",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Season number (optional)"
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName:
                                                    "v-model.trim.number",
                                                  value:
                                                    _vm.$v.selectedEpisode
                                                      .season.$model,
                                                  expression:
                                                    "$v.selectedEpisode.season.$model",
                                                  modifiers: {
                                                    trim: true,
                                                    number: true,
                                                  },
                                                },
                                              ],
                                              staticClass:
                                                "db bg-adori-very-light-gray br2 ph3 pv3 gray f6 mb2 white w-100",
                                              class: _vm.$v.selectedEpisode
                                                .season.$error
                                                ? "ba bw1 b--adori-red"
                                                : "bw0",
                                              attrs: {
                                                type: "number",
                                                step: "1",
                                                min: "1",
                                                disabled: !_vm.belongsToSeason,
                                                required: "",
                                              },
                                              domProps: {
                                                value:
                                                  _vm.$v.selectedEpisode.season
                                                    .$model,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.$v.selectedEpisode
                                                      .season,
                                                    "$model",
                                                    _vm._n(
                                                      $event.target.value.trim()
                                                    )
                                                  )
                                                },
                                                blur: function ($event) {
                                                  return _vm.$forceUpdate()
                                                },
                                              },
                                            }),
                                            _vm.$v.selectedEpisode.season.$dirty
                                              ? _c("div", [
                                                  !_vm.$v.selectedEpisode.season
                                                    .numeric
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "f6 adori-red",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Must be a valid number."
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ])
                                              : _vm._e(),
                                          ]
                                        ),
                                        _c("div", { staticClass: "pb2" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex justify-between",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "pb2 gray f6" },
                                                [
                                                  _vm._v(
                                                    "\n                      Do you want to include a episode number?\n                      "
                                                  ),
                                                  _c("BaseTooltip", {
                                                    attrs: {
                                                      info: "Any number to indicate the current episode number, which can be relative to the entire show (like “316”), or to the current season (like “5”).",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "pv1 ba br4 b--transparent bg-black-20 tc",
                                              staticStyle: { width: "85px" },
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "f6 ttu pv1 ph2 silver link pointer",
                                                  class: _vm.hasEpisodeNumber
                                                    ? "br4 bg-black-80 light-gray"
                                                    : "",
                                                },
                                                [
                                                  _c("input", {
                                                    staticClass: "input-reset",
                                                    attrs: { type: "radio" },
                                                    domProps: {
                                                      checked:
                                                        _vm.hasEpisodeNumber,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.hasEpisodeNumber = true
                                                      },
                                                    },
                                                  }),
                                                  _vm._v("Yes"),
                                                ]
                                              ),
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "f6 ttu pv1 ph2 silver link pointer",
                                                  class: !_vm.hasEpisodeNumber
                                                    ? "br4 bg-black-80 light-gray"
                                                    : "",
                                                },
                                                [
                                                  _c("input", {
                                                    staticClass: "input-reset",
                                                    attrs: { type: "radio" },
                                                    domProps: {
                                                      checked:
                                                        !_vm.hasEpisodeNumber,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.hasEpisodeNumber = false
                                                      },
                                                    },
                                                  }),
                                                  _vm._v("No"),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "pb3",
                                            class: !_vm.hasEpisodeNumber
                                              ? "o-50"
                                              : "o-100",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "flex justify-between",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "pb2 gray f6",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Episode number (optional)"
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName:
                                                    "v-model.trim.number",
                                                  value:
                                                    _vm.$v.selectedEpisode
                                                      .episodeNumber.$model,
                                                  expression:
                                                    "$v.selectedEpisode.episodeNumber.$model",
                                                  modifiers: {
                                                    trim: true,
                                                    number: true,
                                                  },
                                                },
                                              ],
                                              staticClass:
                                                "db bg-adori-very-light-gray br2 ph3 pv3 gray f6 mb2 white w-100",
                                              class: _vm.$v.selectedEpisode
                                                .episodeNumber.$error
                                                ? "ba bw1 b--adori-red"
                                                : "bw0",
                                              attrs: {
                                                type: "number",
                                                step: "1",
                                                min: "1",
                                                disabled: !_vm.hasEpisodeNumber,
                                                required: "",
                                              },
                                              domProps: {
                                                value:
                                                  _vm.$v.selectedEpisode
                                                    .episodeNumber.$model,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.$v.selectedEpisode
                                                      .episodeNumber,
                                                    "$model",
                                                    _vm._n(
                                                      $event.target.value.trim()
                                                    )
                                                  )
                                                },
                                                blur: function ($event) {
                                                  return _vm.$forceUpdate()
                                                },
                                              },
                                            }),
                                            _vm.$v.selectedEpisode.episodeNumber
                                              .$dirty
                                              ? _c("div", [
                                                  !_vm.$v.selectedEpisode
                                                    .episodeNumber.numeric
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "f6 adori-red",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      Must be a valid number.\n                    "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ])
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "flex flex-column w-47" },
                                      [
                                        _c("div", { staticClass: "pb3" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex justify-between",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "pb2 gray f6" },
                                                [
                                                  _vm._v(
                                                    "\n                      Schedule for publishing\n                      "
                                                  ),
                                                  _c("BaseTooltip", {
                                                    attrs: {
                                                      info: "Publishing of episode can be scheduled, if it is not published yet.",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "flex flex-column" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "flex items-center mv1",
                                                },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.episodePublishType,
                                                        expression:
                                                          "episodePublishType",
                                                      },
                                                    ],
                                                    staticClass: "mr2 mb1",
                                                    attrs: {
                                                      type: "radio",
                                                      id: "publish-later",
                                                      value: "PUBLISH_LATER",
                                                    },
                                                    domProps: {
                                                      checked: _vm._q(
                                                        _vm.episodePublishType,
                                                        "PUBLISH_LATER"
                                                      ),
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        _vm.episodePublishType =
                                                          "PUBLISH_LATER"
                                                      },
                                                    },
                                                  }),
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "light-gray f6",
                                                      attrs: {
                                                        for: "publish-later",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Choose date and time"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "ml3 f7 tj o-70 white mr3",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      Schedule your episode publishing at a custom date and time. This would give you some time to\n                      review the audio file and add/remove interactive tags.\n                    "
                                                  ),
                                                ]
                                              ),
                                              _vm.formatedSuggestedDate
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "ml3 f7 silver",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "---------------------"
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.formatedSuggestedDate
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "ml3 f7 tj white mr3 o-70",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      Based on your previous schedule and nature of content, the best time to publish this episode\n                      would be:\n                      " +
                                                          _vm._s(
                                                            _vm.formatedSuggestedDate
                                                          ) +
                                                          ".\n                    "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.episodePublishType ===
                                              "PUBLISH_LATER"
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "pb3 flex items-center justify-between",
                                                    },
                                                    [
                                                      _c("BaseButtonBorder", {
                                                        staticClass: "pv3",
                                                        attrs: {
                                                          onClick:
                                                            _vm.handleSuggestedDate,
                                                          text: "Use suggested date",
                                                        },
                                                      }),
                                                      _c("date-picker", {
                                                        staticClass:
                                                          "adori-date-picker",
                                                        attrs: {
                                                          lang: "en",
                                                          type: "datetime",
                                                          format:
                                                            "YYYY-MM-DD hh:mm a",
                                                          "time-picker-options":
                                                            {
                                                              start: "00:00",
                                                              step: "00:30",
                                                              end: "23:30",
                                                            },
                                                          "not-before":
                                                            _vm.today,
                                                          width: "176",
                                                          clearable: false,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.$v
                                                              .selectedEpisode
                                                              .scheduleTime
                                                              .$model,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.$v
                                                                .selectedEpisode
                                                                .scheduleTime,
                                                              "$model",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "$v.selectedEpisode.scheduleTime.$model",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "flex items-center mv1",
                                                },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.episodePublishType,
                                                        expression:
                                                          "episodePublishType",
                                                      },
                                                    ],
                                                    staticClass: "mr2 mb1",
                                                    attrs: {
                                                      type: "radio",
                                                      id: "publish-now",
                                                      value: "PUBLISH_NOW",
                                                    },
                                                    domProps: {
                                                      checked: _vm._q(
                                                        _vm.episodePublishType,
                                                        "PUBLISH_NOW"
                                                      ),
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        _vm.episodePublishType =
                                                          "PUBLISH_NOW"
                                                      },
                                                    },
                                                  }),
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "light-gray f6",
                                                      attrs: {
                                                        for: "publish-now",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Publish immediately"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "ml3 f7 white tj mr3 o-70",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      All set? Choose this option if your episode is fully reviewed (and tagged). Proceed with\n                      caution, this action cannot be undone.\n                    "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ])
          : _c(
              "div",
              [
                _c("ImageGallery", {
                  attrs: { galleryParent: "PODCAST_EPISODE" },
                  on: {
                    showTag: _vm.showImageGallery,
                    handleSelectedImage: _vm.handleSelectedImage,
                  },
                }),
              ],
              1
            ),
      ]),
      _c("template", { slot: "footer" }, [
        _c("div", { staticClass: "flex justify-between w-100" }, [
          _vm.currentStep === 1
            ? _c("div", { staticClass: "flex flex-wrap w-100" }, [
                _c(
                  "div",
                  { staticClass: "w-100 flex justify-center items-center" },
                  [
                    _c("p", { staticClass: "silver mv0" }, [
                      _vm._v("Step 1 of 3"),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
          _vm.currentStep === 2 && !_vm.enableImageGallery
            ? _c("div", { staticClass: "flex flex-wrap w-100" }, [
                _c(
                  "div",
                  { staticClass: "w-third flex justify-start items-center" },
                  [
                    _c("BaseButtonBorder", {
                      attrs: { text: "Back", onClick: _vm.handlePrevTab },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "w-third flex flex-column justify-center items-center",
                  },
                  [
                    _c("p", { staticClass: "silver mv0" }, [
                      _vm._v("Step 2 of 3"),
                    ]),
                    _c(
                      "p",
                      {
                        staticClass: "f6 silver",
                        staticStyle: { "margin-top": "5px" },
                      },
                      [_vm._v("All fields are required.")]
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "w-third flex justify-end items-center" },
                  [
                    _c("BaseButtonBorder", {
                      attrs: { text: "Next", onClick: _vm.handleNextTab },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.currentStep === 3
            ? _c("div", { staticClass: "flex flex-wrap w-100" }, [
                _c(
                  "div",
                  { staticClass: "w-third flex justify-start items-center" },
                  [
                    _c("BaseButtonBorder", {
                      attrs: { text: "Back", onClick: _vm.handlePrevTab },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "w-third flex justify-center items-center" },
                  [
                    _c("p", { staticClass: "silver mv0" }, [
                      _vm._v("Step 3 of 3"),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "w-third flex justify-end items-center" },
                  [
                    _vm.errorMessage
                      ? _c("div", { staticClass: "f6 adori-red mh3" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.errorMessage) +
                              "\n          "
                          ),
                        ])
                      : _vm._e(),
                    _vm.isSubmitting
                      ? _c("div", { staticClass: "loader loader-inline mh3" })
                      : _vm._e(),
                    _c("BaseButtonRed", {
                      attrs: {
                        text: "Add episode",
                        onClick: _vm.handleAddEpisodeSubmit || _vm.isSubmitting,
                        disabled: _vm.disableSubmit,
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }