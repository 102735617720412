










import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class BaseButtonRed extends Vue {
  @Prop(String) text!: string
  @Prop(String) icon!: string
  @Prop(Boolean) disabled!: boolean
  @Prop(Boolean) loading!: boolean
  @Prop(Function) onClick!: () => void

  get styleClass() {
    return (
      'flex justify-center items-center f6 br2 bn pv2 light-gray ph3 pv1 bg-adori-red btn-shadow ' +
      (!this.disabled ? 'o-90 glow pointer' : 'o-40 not-allowed')
    )
  }

  buttonAction() {
    if (!this.disabled) this.onClick()
  }
}
