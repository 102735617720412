export const LEVELS = {
  network: 'Network',
  show: 'Show',
}

export const RESOURCES = {
  ads: 'Ads',
  billing: 'Billing',
  contact: 'Contact',
  episode: 'Episode',
  image: 'Image',
  insights: 'Insights',
  location: 'Location',
  networkmeta: 'Meta',
  poll: 'Poll',
  show: 'Show',
  tag: 'Tag',
  users: 'Users',
  integrations: 'Integrations',
  campaigns: 'Campaigns',
}

export const ACTIONS = {
  view: 'View',
  create: 'Create',
  manage: 'Manage',
  delete: 'Delete',
  edit: 'Edit',
  publish: 'Publish',
  insertTag: 'Insert_tag',
  insertAds: 'Insert_ads',
  export: 'Export',
  schedule: 'Schedule',
  convertVideo: 'convert_to_video',
}
