var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "fixed flex justify-center items-center left-0 right-0 top-0 bottom-0 bg-black-80 no-select z-5",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "flex flex-column bg-adori-gray ph2 pv3 mh-95 min-w5 br2 shadow-5",
        },
        [
          _c(
            "header",
            { staticClass: "flex flex-row justify-end ph2 pv2" },
            [
              _vm._t("header"),
              _c(
                "button",
                {
                  staticClass:
                    "bw0 bg-adori-gray light-gray link pointer dim right",
                  on: {
                    click: function ($event) {
                      return _vm.handleCloseButtonClicked()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "material-icons f3" }, [
                    _vm._v("close"),
                  ]),
                ]
              ),
            ],
            2
          ),
          _c(
            "section",
            {
              staticClass:
                "flex flex-wrap bg-adori-gray justify-center ph4 mb3 overflow-y-auto scrollbar",
            },
            [
              _vm._t("body", function () {
                return [
                  _c("v-select", {
                    staticClass: "style-chooser",
                    attrs: {
                      options: _vm.options,
                      name: "countryDrop",
                      label: "name",
                      placeholder: "Country",
                    },
                    on: { input: _vm.setSelected },
                  }),
                ]
              }),
            ],
            2
          ),
          _c(
            "footer",
            { staticClass: "flex justify-end ph4 mb2" },
            [
              _c("BaseButtonRed", {
                staticClass: "ph2 w-20 fr mt4",
                attrs: { text: "Select", onClick: _vm.chooseLocation },
              }),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }