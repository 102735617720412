export enum filter {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  COMPLETE = 'COMPLETE',
  DISABLED = 'DISABLED',
  ARCHIVED = 'ARCHIVED',
}

export enum filterPromoType {
  PAID_PROMO = 'PAID_PROMO',
  UNPAID_PROMO = 'UNPAID_PROMO',
}

export enum modalType {
  PAID = 'Paid',
  UNPAID = 'Unpaid',
  PROGRAMMATIC = 'Programmatic',
}

export enum promoType {
  PROGRAMMATIC = 'PROGRAMMATIC',
}
