



























































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import moment from 'moment'
import { ADORI_API_BASE_URL } from '@/constants'

@Component({})
export default class ModalExportInsights extends Vue {
  @Getter token!: any
  @Getter rssFeed!: any
  @Getter currentNetwork!: any
  @Getter exportedFileURL!: any
  @Action exportNetworkAnalytics!: any
  @Action exportRssFeedAnalytics!: any

  isSubmitting: boolean = false
  level: string = ''
  id: string = ''
  name: string = ''

  defaultSelectedDateTimeFilter: any = 'LAST_WEEK'
  selectedDateTimeFilter: any = this.defaultSelectedDateTimeFilter
  timeFormat: string = 'YYYYMMDD'
  displayFormat: string = 'DD MMM YY'
  today: any = moment().utc().subtract(2, 'days')
  notBeforeDate: any = moment().utc().subtract(6, 'months').subtract(2, 'days')
  range: any = [null, null]
  episodeBreakdown: boolean = false

  created() {
    this.level = this.$store.state.modal.exportInsights.level
    this.id = this.$store.state.modal.exportInsights.id
    this.name = this.$store.state.modal.exportInsights.name
    if (this.level === 'PODCASTS') {
      this.notBeforeDate = moment.max(
        moment.min(this.rssFeed(this.id).createdOn, moment().utc().subtract(2, 'days')),
        moment().utc().subtract(6, 'months').subtract(2, 'days')
      )
    } else {
      this.notBeforeDate = moment.max(
        moment.min(this.currentNetwork.network.createdOn, moment().utc().subtract(2, 'days')),
        moment().utc().subtract(6, 'months').subtract(2, 'days')
      )
    }
  }

  get createdOnDate() {
    const createdOn =
      this.level === 'PODCASTS'
        ? this.rssFeed(this.id)
          ? this.rssFeed(this.id).createdOn
          : ''
        : this.currentNetwork.network.createdOn
    return moment(createdOn).utc()
  }

  get analyticsTimeFilter() {
    const endDate = moment.max(this.createdOnDate, moment().utc().subtract(2, 'days'))
    switch (this.selectedDateTimeFilter) {
      case 'ALL_TIME': {
        return {
          start_date: this.createdOnDate,
          end_date: endDate,
        }
      }
      case 'LAST_WEEK': {
        return {
          start_date: moment.max(this.createdOnDate, moment().utc().subtract(8, 'days')),
          end_date: endDate,
        }
      }
      case 'LAST_MONTH': {
        return {
          start_date: moment.max(this.createdOnDate, moment().utc().subtract(1, 'months').subtract(2, 'days')),
          end_date: endDate,
        }
      }
      case 'LAST_3_MONTHS': {
        return {
          start_date: moment.max(this.createdOnDate, moment().utc().subtract(3, 'months').subtract(2, 'days')),
          end_date: endDate,
        }
      }
      default: {
        if (this.selectedDateTimeFilter && this.selectedDateTimeFilter.length === 2) {
          return {
            start_date: moment(this.selectedDateTimeFilter[0]),
            end_date: moment(this.selectedDateTimeFilter[1]),
          }
        } else {
          return {
            start_date: this.createdOnDate,
            end_date: endDate,
          }
        }
      }
    }
  }

  selectDateTimeFilter(dateTimeFilter: any) {
    this.selectedDateTimeFilter = dateTimeFilter
    this.range = [null, null]
  }

  @Watch('range')
  async onRangeChanged() {
    if (this.range.length === 2) {
      const { 0: startDate, 1: endDate } = this.range
      if (!startDate && !endDate && this.selectedDateTimeFilter instanceof Object) {
        this.selectedDateTimeFilter = this.defaultSelectedDateTimeFilter
      } else if (startDate && endDate) {
        this.selectedDateTimeFilter = this.range
      }
    }
  }

  get exportInsightsURL() {
    const networkId = this.$store.getters.networkId
    if (this.level === 'NETWORKS') {
      return `${ADORI_API_BASE_URL}/networks/${networkId}/insights/export?start-date=${this.analyticsTimeFilter.start_date.format(
        this.timeFormat
      )}&end-date=${this.analyticsTimeFilter.end_date.format(this.timeFormat)}&api_token=${this.token}`
    } else {
      return `${ADORI_API_BASE_URL}/networks/${networkId}/rssfeeds/${
        this.id
      }/insights/export?start-date=${this.analyticsTimeFilter.start_date.format(
        this.timeFormat
      )}&end-date=${this.analyticsTimeFilter.end_date.format(this.timeFormat)}&api_token=${this.token}`
    }
  }

  handleExportInsights() {
    const audioDownloadLink = this.$refs.exportInsightsRef as HTMLElement
    audioDownloadLink.click()
  }
}
