import { filter } from './../components/Campaigns/configs'
import Vue from 'vue'
import { StoreOptions, GetterTree, MutationTree, ActionTree } from 'vuex'
import get from 'lodash.get'
import AdoriService from '@/services/adori'
import AdoriServicev6 from '@/services/adori_v6'
import { uploadStatus } from '@/components/Publish/publish'

const state: any = {
  currentPodcast: {},
  publicFeed: {},
  publicFeedEpisodes: [],
  selectedEpisodeList: [],
  ytModalId: null,
  selectedEpisodeSettings: {},
  youtubeStep: 1,
  allYoutubeUploads: [],
  uploadListStatus: {},
  triggerLogin: false,
  uploadTrackTrigger: false,
  selectedRssEpisodes: [],
  callUpload: false,
  callPublish: false,
  callTags: false,
  createdYoutubeFeed: {},
  youtubeModalPane: 'PODCAST',
  multiUploadCounter: [],
}

const getters: GetterTree<any, any> = {
  publicFeed: (state) => state.publicFeed,
  publicFeedEpisodes: (state) => state.publicFeedEpisodes,
  currentPodcast: (state) => state.currentPodcast,
  selectedEpisodeList: (state) => state.selectedEpisodeList,
  ytModalId: (state) => state.ytModalId,
  selectedEpisodeSettings: (state) => state.selectedEpisodeSettings,
  isEpisodeSelectedForUpload: (state) => (guid: any) => {
    const ids = state.selectedEpisodeList.map((obj: any) => obj.guid)
    return ids.indexOf(guid) !== -1
  },
  youtubeStep: (state) => state.youtubeStep,
  allYoutubeUploads: (state) => state.allYoutubeUploads,
  uploadListStatus: (state) => state.uploadListStatus,
  triggerLogin: (state) => state.triggerLogin,
  uploadTrackTrigger: (state) => state.uploadTrackTrigger,
  selectedRssEpisodes: (state) => state.selectedRssEpisodes,
  callUpload: (state) => state.callUpload,
  callPublish: (state) => state.callPublish,
  callTags: (state) => state.callTags,
  getRssEpisodeByGuid: (state) => (guid: any) => {
    const episode = state.selectedRssEpisodes.filter((obj: any) => obj.guid === guid)[0]
    return episode
  },
  createdYoutubeFeed: (state) => state.createdYoutubeFeed,
  youtubeModalPane: (state) => state.youtubeModalPane,
  multiUploadCounter: (state) => state.multiUploadCounter,
}

const mutations: MutationTree<any> = {
  selectEpisodeForUpload(state, data) {
    Vue.set(state, 'selectedEpisodeList', [...state.selectedEpisodeList, data])
  },

  unselectEpisodesForUpload(state, audio: any) {
    const newList = state.selectedEpisodeList.filter((obj: any) => audio.guid !== obj.guid)
    Vue.set(state, 'selectedEpisodeList', newList)
  },

  unselectAllEpisodeForUpload(state) {
    Vue.set(state, 'selectedEpisodeList', [])
  },

  clearUploadListStatus() {
    Vue.set(state, 'uploadListStatus', {})
  },

  clearYoutubeState(state) {
    Vue.set(state, 'youtubeStep', 1)
    Vue.set(state, 'uploadListStatus', {})
    Vue.set(state, 'selectedRssEpisodes', [])
  },

  resetYoutubeSetting(state) {
    Vue.set(state, 'selectedEpisodeSettings', {})
  },

  clearYoutubeSetting(state, guid) {
    if (state.selectedEpisodeSettings[guid]) {
      Vue.delete(state.selectedEpisodeSettings, guid)
    }
  },
  setPublicFeed(state, data: any) {
    Vue.set(state, 'publicFeed', data)
  },

  setCurrentPodcast(state, data) {
    Vue.set(state, 'currentPodcast', data)
  },

  setPublicFeedEpisodes(state, data: any) {
    Vue.set(state, 'publicFeedEpisodes', data)
  },
  setYTModalId(state, data) {
    Vue.set(state, 'ytModalId', data)
  },
  setYoutubeModal(state, pane?: string) {
    Vue.set(state, 'youtubeModalPane', pane)
  },

  setSelectedEpisodeSettings(state, data) {
    const guid = data.guid
    if (state.selectedEpisodeSettings[guid]) {
      Vue.set(state.selectedEpisodeSettings, guid, {
        ...state.selectedEpisodeSettings[guid],
        ...data,
      })
      sessionStorage.setItem(
        'ytModalSetting',
        JSON.stringify({
          ...state.selectedEpisodeSettings[guid],
          ...data,
        })
      )
    } else Vue.set(state.selectedEpisodeSettings, guid, data)
  },

  setYoutubeStep(state, step) {
    Vue.set(state, 'youtubeStep', step)
  },
  setAllYoutubeUploads(state, data) {
    Vue.set(state, 'allYoutubeUploads', data)
  },
  setUploadListStatus(state, audioGuid) {
    Vue.set(state.uploadListStatus, audioGuid, true)
  },

  unsetUploadListStatus(state, audioGuid) {
    Vue.set(state.uploadListStatus, audioGuid, false)
  },

  setTriggerLogin(state) {
    Vue.set(state, 'triggerLogin', true)
    setTimeout(() => {
      Vue.set(state, 'triggerLogin', false)
    }, 1000)
  },
  setUploadTrackTrigger(state) {
    Vue.set(state, 'uploadTrackTrigger', true)
    setTimeout(() => {
      Vue.set(state, 'uploadTrackTrigger', false)
    }, 1000)
  },

  setSelectedRssEpisodes(state, audio) {
    const newList = state.selectedRssEpisodes
    newList.push(audio)
    Vue.set(state, 'selectedRssEpisodes', newList)
  },

  setMultipleRssEpisodes(state, audioArr) {
    Vue.set(state, 'selectedRssEpisodes', audioArr)
  },

  setCallUpload(state, value) {
    Vue.set(state, 'callUpload', value)
  },

  setCallPublish(state, value) {
    Vue.set(state, 'callPublish', value)
  },
  setCallTags(state, value) {
    Vue.set(state, 'callTags', value)
  },

  setCreatedYoutubeFeed(state, data) {
    Vue.set(state, 'createdYoutubeFeed', data)
  },

  addUploadToCounter(state, id) {
    Vue.set(state, 'multiUploadCounter', [...state.multiUploadCounter, id])
  },

  removeUploadFromCounter(state, id) {
    const newArr = state.multiUploadCounter.filter((element: string) => id !== element)
    Vue.set(state, 'multiUploadCounter', newArr)
  },

  clearUploadCounter() {
    Vue.set(state, 'multiUploadCounter', [])
  },
}

const actions: ActionTree<any, any> = {
  async toggleSelectEpisodeForUpload(context, audio) {
    const selectedEpisodeList = context.getters.selectedEpisodeList.filter((obj: any) => obj.guid === audio.guid)

    if (selectedEpisodeList.length === 0) {
      context.commit('selectEpisodeForUpload', audio)
    } else {
      context.commit('unselectEpisodesForUpload', audio)
      context.commit('clearYoutubeSetting', audio.guid)
    }
  },

  async getPublicFeedEpisodes(context, payload) {
    const data: any = await AdoriServicev6.youtubeRssFeedEpisodes(payload)
    context.commit('setCurrentPodcast', data)
    context.commit('setPublicFeedEpisodes', data.episodes)
  },

  ytModalId(context, payload) {
    context.commit('setYTModalId', payload)
  },

  ytModalSetting(context, payload) {
    context.commit('setSelectedEpisodeSettings', payload)
  },

  async getAllYoutubeUploads(context, payload) {
    const res: any = await AdoriService.youtube_upload_all(payload)
    let youtubeData: any = {
      data: res.data,
      count: res.count,
      offset: res.offset,
    }
    context.commit('setAllYoutubeUploads', youtubeData)
  },
  async checkAudioUpload(context, payload) {
    const res: any = await AdoriService.fetchAudioUids(payload.networkId, {
      params: payload.params,
    })
    res.data.map((audio: any) => {
      context.commit('addAudio', audio)
    })
    return res.data
  },

  async createYoutubeFeed(context, payload: { networkId: string; data: { rss: string } }) {
    const res = await AdoriService.youtube_create_feed(payload.networkId, payload.data)

    context.commit('setCreatedYoutubeFeed', res)
  },

  async removeYoutubeFeed(context, payload: { rssFeedUid: string }) {
    const networkId = context.getters.networkId
    const res = await AdoriService.youtube_remove_feed({
      networkId: networkId,
      rssFeedUid: payload.rssFeedUid,
    })

    context.dispatch('pushNotification', {
      text: 'Your show was successfully removed',
      type: 'SUCCESS',
    })
  },
  clearUploadListStatus(context) {
    context.commit('clearUploadListStatus')
  },
}

const youtube: StoreOptions<any> = {
  state,
  getters,
  mutations,
  actions,
}

export default youtube
