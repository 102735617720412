


































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class BaseVideoPlayer extends Vue {
  @Prop(String) src!: string
  @Prop(Boolean) bigger!: boolean
  @Prop(Boolean) noControls!: boolean
  @Prop(String) filter!: string
  @Prop(String) tagId!: string

  get videoSrc() {
    return this.src
  }

  handleOver(e: any) {
    const video: any = this.$refs[`video-${this.tagId}`]
    video.play()
  }
  handleOut(e: any) {
    const video: any = this.$refs[`video-${this.tagId}`]
    video.pause()
  }
}
