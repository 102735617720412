var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isPromtVisible && !_vm.isSummaryLoading && !_vm.isCardLoading
    ? _c(
        "div",
        {
          staticClass:
            "sticky flex justify-center items-center bg-light-gray no-select ph3 pv2 z-max",
        },
        [
          _c("div", { staticClass: "dark-gray lh-copy pv2 mr2" }, [
            _c("span", {
              attrs: { name: "message" },
              domProps: { innerHTML: _vm._s(_vm.message) },
            }),
          ]),
          _c(
            "i",
            {
              staticClass: "material-icons absolute close-btn f4 pointer",
              on: { click: _vm.handleClose },
            },
            [_vm._v(" close ")]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }