// @ts-ignore
export function isValidWebLink(param) {
  const pattern = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/)
  return pattern.test(param)
}

export function isValidTime(param) {
  const pattern = new RegExp(/^(?:1[0-2]|0?[0-9]):[0-5][0-9]:[0-5][0-9](.[0-9][0-9][0-9])$/)
  return pattern.test(param)
}
