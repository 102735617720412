var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "form-group explore" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.searchQuery,
              expression: "searchQuery",
            },
          ],
          staticClass: "form-control search-icon",
          attrs: { type: "text", placeholder: "Search images" },
          domProps: { value: _vm.searchQuery },
          on: {
            keyup: function ($event) {
              return _vm.debouncedSearch()
            },
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.searchQuery = $event.target.value
            },
          },
        }),
        _c("div", { staticClass: "btn-close" }, [
          _c(
            "i",
            {
              staticClass: "material-icons v-mid pointer dim",
              on: { click: _vm.clearSearchQuery },
            },
            [_vm._v(" clear ")]
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "form-group flex justify-center items-center" },
        [
          _c(
            "div",
            {
              staticClass:
                "ba bw1 br4 b--transparent bg-black-20 flex items-center",
            },
            _vm._l(_vm.searchLibraries, function (item) {
              return _c(
                "label",
                {
                  staticClass: "f6 pv1 ph2 silver link pointer mr1",
                  class:
                    _vm.selectedImageTab === item.LibraryName
                      ? "ba br4 b--transparent bg-black-80 light-gray"
                      : "",
                },
                [
                  _c("input", {
                    staticClass: "input-reset",
                    attrs: { type: "radio" },
                    domProps: {
                      checked: _vm.selectedImageTab === item.LibraryName,
                    },
                    on: {
                      click: function ($event) {
                        _vm.selectedImageTab = item.LibraryName
                      },
                    },
                  }),
                  _vm._v(_vm._s(item.name) + "\n      "),
                ]
              )
            }),
            0
          ),
        ]
      ),
      _vm.isPublicImageLoading || _vm.isStabilityLoading
        ? _c("div", { staticClass: "flex justify-center mt5" }, [_vm._m(0)])
        : _vm.imageList.length === 0 || !_vm.imageSearchText
        ? _c(
            "div",
            {
              staticClass: "w-100 flex flex-column justify-center items-center",
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/imgae-search.svg"),
                  alt: "",
                  width: "250",
                  height: "250",
                },
              }),
              _c("div", { staticClass: "f5 silver" }, [
                _vm._v("Oops! No images found.. try different query"),
              ]),
            ]
          )
        : _c("AudiogramImagesList", {
            attrs: {
              selectedRangeStart: _vm.selectedRangeStart,
              selectedRangeEnd: _vm.selectedRangeEnd,
              totalImages: _vm.totalImageCount,
              imageSources: _vm.imageList,
              selectedTab: "IMAGE_SEARCH",
            },
            on: {
              handleNavigation: _vm.handleNavigation,
              viewImages: _vm.viewImages,
            },
          }),
      _vm.showImagePreview
        ? _c("ModalPreviewImages", {
            attrs: { image: _vm.image },
            on: {
              close: function ($event) {
                _vm.showImagePreview = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "building-blocks mt5" }, [
      _c("div"),
      _c("div"),
      _c("div"),
      _c("div"),
      _c("div"),
      _c("div"),
      _c("div"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }