var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    ref: "file",
    staticClass: "dn",
    attrs: {
      type: "file",
      accept: _vm.$store.state.fileSelector.accept,
      multiple: _vm.$store.state.fileSelector.multiple,
    },
    on: {
      change: function ($event) {
        return _vm.$store.dispatch("onFileUploaded", {
          multiple: _vm.$store.state.fileSelector.multiple,
        })
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }