import Vue from 'vue'
import { StoreOptions, GetterTree, MutationTree, ActionTree } from 'vuex'

const state: any = {
  token: null,
  expiry: null,
}

const getters: GetterTree<any, any> = {
  token: (state) => state.token,
  expiry: (state) => state.expiry,
  isLoggedIn: (state) => state.token && state.expiry && parseInt(state.expiry) > new Date().getTime(),
}

const mutations: MutationTree<any> = {
  setToken(state, token: string) {
    Vue.set(state, 'token', token)
  },

  setExpiry(state, expiry: string) {
    Vue.set(state, 'expiry', expiry)
  },
}

const actions: ActionTree<any, any> = {
  async setSession(context, { token, expiry }) {
    context.commit('setToken', token)
    context.commit('setExpiry', expiry)
  },

  async clearSession(context) {
    context.commit('setToken', null)
    context.commit('setExpiry', null)
    localStorage.clear()
    sessionStorage.clear()
  },
}

const session: StoreOptions<any> = {
  state,
  getters,
  mutations,
  actions,
}

export default session
