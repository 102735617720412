














import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class ModalDialog extends Vue {
  get title() {
    return this.$store.state.modal.dialog.title
  }

  get description() {
    return this.$store.state.modal.dialog.description
  }
}
