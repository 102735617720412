var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    { ref: "_baseModal" },
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "light-gray f4 fw5" }, [
          _vm._v("Preview Audio Ad"),
        ]),
      ]),
      _c("template", { slot: "body" }, [
        _c(
          "div",
          { staticClass: "flex flex-wrap" },
          [
            _c("AudioAdPreview", {
              attrs: {
                tracks: _vm.tracks,
                callbackFunction: _vm.onSaveAdPreview,
              },
            }),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }