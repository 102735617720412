










import { Vue, Component } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import AdoriService from '@/services/adori'
import GmapAutocomplete from 'vue2-google-maps/src/components/autocomplete.vue'

@Component({ components: { GmapAutocomplete } })
export default class MapLocationPicker extends Vue {
  @Getter networkId!: string

  value: string = ''

  async mounted() {
    const locationId = this.$store.state.modal.tagEdit.data.locationId
    if (locationId) {
      const response: any = await AdoriService.fetchLocation(this.networkId, locationId)
      this.value = response.name || ''
    } else if (this.$store.state.modal.tagEdit.data.place) {
      this.value = this.$store.state.modal.tagEdit.data.place.name
    }
  }

  get place() {
    const place: any = this.$store.state.modal.tagEdit.data.location.place
    if (place && place.formatted_address.startsWith(place.name)) {
      return place.formatted_address
    }
    return (place && place.name + ', ' + place.formatted_address) || ''
  }

  setPlace(place: any) {
    this.$store.dispatch('changeMapPlace', place)
  }

  get markers() {
    return this.$store.state.modal.tagEdit.data.location.markers
  }

  inputValue(data: any) {
    this.$emit('locationInput', data.target.value)
  }
}
