var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.closeMenu,
          expression: "closeMenu",
        },
      ],
      staticClass: "dib pa2 relative",
      class: _vm.cursor ? _vm.cursor : "pointer",
      on: {
        mousedown: function ($event) {
          $event.stopPropagation()
          return _vm.toggleMenu.apply(null, arguments)
        },
      },
    },
    [
      _vm._t("default"),
      _c(
        "i",
        {
          staticClass: "material-icons v-mid white text-shadow",
          class: _vm.iconSize ? "f" + _vm.iconSize : "",
        },
        [_vm._v("\n    " + _vm._s(_vm.icon) + "\n  ")]
      ),
      _vm.open
        ? _c(
            "span",
            {
              staticClass: "absolute mt4 mr2 tl br2 custom-index",
              class: _vm.position ? _vm.position : "right-0 top-0",
              style: !_vm.additionalItems ? "max-width: 260px" : "width: 200px",
            },
            [
              _c(
                "div",
                {
                  staticClass: "bg-white br2 mt3 overflow-y-auto scrollbar",
                  staticStyle: { "max-height": "400px" },
                },
                [
                  _vm.isNetworksMenu && _vm.isSupport
                    ? _c("div", { staticClass: "flex relative" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.networkSearchValue,
                              expression: "networkSearchValue",
                            },
                          ],
                          ref: "_searchNetwork",
                          staticClass: "bw0 br2 pl3 pr5 pv2 ml2 mr2 mt2 f6",
                          staticStyle: {
                            "margin-bottom": "10px",
                            border: "1px solid #3f3f3f",
                            width: "67%",
                          },
                          attrs: {
                            autofocus: "",
                            placeholder: "Search networks",
                          },
                          domProps: { value: _vm.networkSearchValue },
                          on: {
                            keyup: function ($event) {
                              return _vm.debouncedSearch()
                            },
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.networkSearchValue = $event.target.value
                            },
                          },
                        }),
                      ])
                    : _vm._e(),
                  _vm._l(_vm.items, function (item) {
                    return _c("span", { key: item.name }, [
                      _c(
                        "div",
                        {
                          staticClass: "dark-gray link flex items-center w-100",
                          class:
                            item.type && item.type === "dropDown"
                              ? "bg-silver br2 br--bottom black"
                              : "dim",
                          staticStyle: { "font-size": "13px" },
                          on: {
                            mouseenter: function ($event) {
                              item.type && item.type === "dropDown"
                                ? _vm.setAdditionalItems()
                                : function () {}
                            },
                            mouseleave: function ($event) {
                              item.type && item.type === "dropDown"
                                ? _vm.resetAdditionalItems()
                                : function () {}
                            },
                            mousedown: function ($event) {
                              $event.stopPropagation()
                              !item.disabled
                                ? item.type && item.type === "dropDown"
                                  ? function () {}
                                  : _vm.handleItemClick(item)
                                : function () {}
                            },
                          },
                        },
                        [
                          !item.type || !item.type
                            ? _c(
                                "i",
                                {
                                  staticClass:
                                    "material-icons f5 dib v-mid ml2",
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(item.icon) +
                                      "\n          "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c("p", {
                            staticClass: "ml2 dib w-100 mr2",
                            class: item.disabled ? "o-40 not-allowed" : "black",
                            domProps: { innerHTML: _vm._s(item.name) },
                          }),
                        ]
                      ),
                    ])
                  }),
                ],
                2
              ),
              _c(
                "div",
                {
                  staticClass: "br2 o-100 z-9999 flex",
                  staticStyle: {
                    "margin-left": "-11rem",
                    "margin-right": "11rem",
                    "margin-top": "-4.9rem",
                    width: "220px",
                  },
                  on: {
                    mouseenter: function ($event) {
                      $event.preventDefault()
                      return _vm.setAdditionalItems()
                    },
                    mouseleave: function ($event) {
                      return _vm.resetAdditionalItems()
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "w-80 bg-white pr2 br2" },
                    _vm._l(_vm.additionalItems, function (addItem) {
                      return _c("span", { key: addItem.name }, [
                        _vm.showAdditional
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "f7 dark-gray ml2 link dim flex items-center",
                                on: {
                                  mousedown: function ($event) {
                                    return addItem.onClick()
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "mr1",
                                  staticStyle: {
                                    width: "20px",
                                    height: "20px",
                                  },
                                  attrs: { src: addItem.img, alt: "" },
                                }),
                                _c("p", {
                                  staticClass: "dib truncate",
                                  class: addItem.disabled
                                    ? "o-40 not-allowed"
                                    : "",
                                  domProps: { innerHTML: _vm._s(addItem.name) },
                                }),
                              ]
                            )
                          : _vm._e(),
                      ])
                    }),
                    0
                  ),
                  _c("div", {
                    staticClass: "w-20",
                    staticStyle: { background: "transparent" },
                  }),
                ]
              ),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }