


















import { Vue, Component, Prop } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import moment from 'moment'
import { SUBSCRIPTION_STATUS } from '@/constants/subscription'
import { addPaymentMethod } from '@/services/subscription'

@Component
export default class TheBillPayPrompt extends Vue {
  @Getter hasLoadedUser!: boolean
  @Getter hasAcceptedCookies!: boolean
  @Getter networkId!: string
  @Getter currentNetworkSubscriptions!: any
  @Getter currentNetworkPaymentCards!: any

  @Action uploadCookieStatus!: any
  @Action getCurrentNetworkPaymentCards!: any
  @Action showConfirm!: any
  @Action setShowLoader!: any
  @Action closeModal!: any

  daysOffsetToShowPromt: number = 35
  isCloseBtnVisible: boolean = true
  isPromtVisible: boolean = true

  get showPromt() {
    return (
      this.hasLoadedUser &&
      this.$route.path !== '/login' &&
      this.networkId &&
      this.subscription &&
      this.currentNetworkSubscriptions.hasUpgraded &&
      this.subscription.status === SUBSCRIPTION_STATUS.trial &&
      this.subsDays.daysRemaining <= this.daysOffsetToShowPromt &&
      this.isPromtVisible &&
      (!this.currentNetworkPaymentCards || !this.currentNetworkPaymentCards.length)
    )
  }

  get subscription() {
    return this.currentNetworkSubscriptions && this.currentNetworkSubscriptions.subscription
  }

  get subscriptionName() {
    return this.subscription && this.subscription.plan && this.subscription.plan.name
  }

  get subsDays() {
    return (
      this.subscription &&
      this.subscription.studio &&
      (this.subscription.studio.trial || this.subscription.studio.current)
    )
  }

  handleClose() {
    this.isPromtVisible = false
  }
}
