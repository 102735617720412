

















































































import { Vue, Component } from 'vue-property-decorator'
import { ADORI_WEBPLAYER_BASE_URL } from '@/constants'
import { Action } from 'vuex-class'

@Component
export default class ModalAudioTrackEmbed extends Vue {
  orientation: string = 'PORTRAIT' // One of 'PORTRAIT' or 'LANDSCAPE'

  @Action pushNotification!: any
  get episodeUid() {
    return this.$store.state.modal.iframeEmbed.episodeUid
  }

  iframePortraitSrc(isPreviewMode: boolean = false) {
    return `${ADORI_WEBPLAYER_BASE_URL}/${this.episodeUid}` + `${isPreviewMode ? '?mode=preview' : ''}`
  }

  iframeLandscapeSrc(isPreviewMode: boolean = false) {
    return `${ADORI_WEBPLAYER_BASE_URL}/${this.episodeUid}` + `${isPreviewMode ? '?mode=preview' : ''}`
  }

  iframeClassicLandscapeSrc(isPreviewMode: boolean = false) {
    return `${ADORI_WEBPLAYER_BASE_URL}/l/${this.episodeUid}` + `${isPreviewMode ? '?mode=preview' : ''}`
  }

  iframeEmbed(isPreviewMode: boolean = false) {
    if (this.orientation === 'PORTRAIT') {
      return `<iframe src='${this.iframePortraitSrc(
        isPreviewMode
      )}' style="min-height: 650px; max-width: 380px" width='100%' height='100%' title="Adori web Player" frameborder='0' scrolling='no' allow='autoplay' allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe>`
    } else if (this.orientation === 'LANDSCAPE') {
      return `<iframe src='${this.iframeLandscapeSrc(
        isPreviewMode
      )}' style="min-width: 640px; max-height: 400px" width='100%' height='400' title="Adori web Player" frameborder='0' scrolling='no' allow='autoplay' allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe>`
    } else if (this.orientation === 'CLASSIC_LANDSCAPE') {
      return `<iframe src='${this.iframeClassicLandscapeSrc(
        isPreviewMode
      )}' style="min-width: 640px; max-height: 300px" width='100%' height='300' title="Adori web Player" frameborder='0' scrolling='no' allow='autoplay' allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe>`
    }
  }

  get iframePreviewEmbed() {
    return this.iframeEmbed(true)
  }

  get iframePublicEmbed() {
    return this.iframeEmbed(false)
  }

  copyToClipboard() {
    // @ts-ignore
    this.$clipboard(this.iframePublicEmbed)
    this.pushNotification({ text: 'Copied to clipboard', type: 'SUCCESS' })
  }
}
