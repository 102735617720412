import { queryStaleTime, queryDefaults } from '@/utils/query.config'
import AdoriService from '@/services/adori'
import store from '@/store'
import { useQuery, useMutation } from 'vue-query'
import { Ref } from '@vue/composition-api'

export enum trackType {
  UNPUBLISHED = 'UNPUBLISHED',
  PUBLISHED = 'PUBLISHED',
  ALL = 'ALL',
}

interface allTracksParams {
  paginate?: boolean
  limit?: number
  offset?: number
  category?: string
  query?: null | string
  fromYoutube?: boolean
}

// Query Keys
export enum audioQueryKeys {
  ALLTRACKS = 'ALLTRACKS',
  AD = 'AD',
  YTPUBLISHED = 'YTPUBLISHED',
  YTUNPUBLISHED = 'YTUNPUBLISHED',
  YTALL = 'YTALL',
  TRACKVIDEOCLIPS = 'TRACKVIDEOCLIPS',
  VIDEOCLIP = 'VIDEOCLIP',
  AUDIO_TRACK = 'AUDIO_TRACK',
}

// Queries
export function getYTFeedTracks(
  tracksType: string,
  {
    networkId,
    rssFeedUid,
    limit = 10,
    offset = 0,
    order = 'recent_first',
    filter,
    publishedAfter,
    publishedBefore,
    fromYoutube,
  }: any
) {
  return tracksType === trackType.PUBLISHED
    ? AdoriService.fetchRssFeedItems(
        networkId,
        rssFeedUid,
        limit,
        offset,
        filter,
        order,
        publishedAfter,
        publishedBefore,
        fromYoutube
      )
    : tracksType === trackType.UNPUBLISHED
    ? AdoriService.fetchYTfeedUnpublishedEpisodes(networkId, rssFeedUid)
    : AdoriService.fetchYTfeedAllEpisodes(networkId, rssFeedUid)
}
export function getAllAudioTracks(
  networkId: string,
  { paginate = true, limit = 10, offset = 0, category = 'TRACK', query = null, fromYoutube = false }: allTracksParams
) {
  if (query) {
    return AdoriService.fetchAudioUidsSearch(networkId, {
      params: {
        paginate,
        limit,
        offset,
        category,
        query,
      },
    })
  } else if (fromYoutube) {
    let params = new URLSearchParams()
    params.append('paginate', `${paginate}`)
    params.append('limit', `${limit}`)
    params.append('offset', `${offset}`)
    params.append('track_source', 'UPLOADED')
    params.append('track_source', 'UPLOADED_FILE')
    params.append('track_source', 'AI_GENERATED')
    return AdoriService.fetchAudioUids(networkId, {
      params: params,
    })
  } else {
    return AdoriService.fetchAudioUids(networkId, {
      params: {
        paginate,
        limit,
        offset,
        category,
      },
    })
  }
}
export function getVideoClipsByTrackId(networkId: string, trackUid: string, limit = 50, offset = 0) {
  return AdoriService.youtube_upload_status_by_uid({
    network_id: networkId,
    trackUid,
    limit,
    offset,
  })
}
export function getVideoClipsById(networkId: string, task_id: number, get_youtube_status = false) {
  return AdoriService.youtube_upload_status({
    network_id: networkId,
    task_id,
    get_youtube_status,
  })
}
const getAudioUploadStatus = (networkId: string, uploadId: string) => {
  return AdoriService.getAudioUpload(networkId, uploadId)
}
const getAudioTrack = (networkId: string, trackUid: string) => {
  return AdoriService.fetchAudio(networkId, trackUid)
}

const createAudioUploadURL = (networkId: string, params: { url: string; category: string }) => {
  return AdoriService.createAudioUploadURL(networkId, params)
}
const createAudioTrack = (networkId: string, data: any) => {
  return AdoriService.createAudio(networkId, data)
}

// Hooks
export const useGetFeedTracks = (
  tracksType: string,
  rssFeedUid: string,
  { enabled }: any,
  params: {
    rssFeedUid: string
    limit: number
    offset: number
    order: string
    filter: string
    publishedAfter: any
    publishedBefore: any
    fromYoutube: boolean
  },
  onSuccess?: any
) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useQuery(
    [`YT${tracksType}`, rssFeedUid, networkId, params],
    () => getYTFeedTracks(tracksType, { networkId, ...params }),
    {
      keepPreviousData: true,
      enabled: enabled,
      staleTime: queryStaleTime,
      cacheTime: queryStaleTime,
      onSuccess,
      select: (data: any) => {
        return tracksType === trackType.PUBLISHED
          ? {
              rssFeedUid: params.rssFeedUid,
              data: data.data,
              count: data.count,
              offset: data.offset,
            }
          : data
      },
    }
  )
}
export const useGetAllTracks = (params: allTracksParams) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useQuery(
    [params.category === 'TRACK' ? audioQueryKeys.ALLTRACKS : audioQueryKeys.AD, networkId, params],
    () => getAllAudioTracks(networkId, params),
    {
      keepPreviousData: true,
      staleTime: queryStaleTime,
      cacheTime: queryStaleTime,
    }
  )
}
export const useGetVideoClipsByTrackId = (trackUid: any) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useQuery(['TRACKVIDEOCLIPS', trackUid.value], () => getVideoClipsByTrackId(networkId, trackUid.value), {
    keepPreviousData: true,
    staleTime: queryStaleTime,
    cacheTime: queryStaleTime,
  })
}
export const useGetVideoClipsById = (task_id: any, get_youtube_status = false, interval: any = false) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useQuery(['VIDEOCLIP', task_id], () => getVideoClipsById(networkId, task_id, get_youtube_status), {
    staleTime: queryStaleTime,
    cacheTime: queryStaleTime,
    refetchInterval: interval,
    enabled: !!task_id,
    refetchIntervalInBackground: false,
  })
}
export const useGetAudioUploadStatus = (uploadId: Ref, { enabled }: any, interval: any = false, onSuccess?: any) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useQuery([uploadId], () => getAudioUploadStatus(networkId, uploadId.value), {
    enabled,
    onSuccess,
    refetchInterval: interval,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: true,
  })
}
export const useGetAudioTrack = (trackUid: any) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useQuery([audioQueryKeys.AUDIO_TRACK, networkId, trackUid], () => getAudioTrack(networkId, trackUid), {
    enabled: !!trackUid,
    onSuccess: (data: any) => {
      store.commit('addAudio', data)
    },
    ...queryDefaults,
  })
}

// Mutations
export const useCreateAudioUploadUrl = () => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useMutation((params: { url: string; category: string }) => createAudioUploadURL(networkId, params))
}
export const useCreateAudioTrack = (onSuccess?: any) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useMutation((data: any) => createAudioTrack(networkId, data), {
    onSuccess,
  })
}
