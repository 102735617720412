




























import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class BaseModal extends Vue {
  @Prop(Boolean) locked!: boolean
  @Prop(Boolean) hideCloseButton!: boolean
  @Prop(Boolean) hasCustomEvent!: boolean

  handleClickedOutside() {
    // don't close modal on click outside
  }

  handleCloseButtonClicked() {
    this.$store.dispatch('closeModalChange')
  }
}
