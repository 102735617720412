// Globally register all base components.
// Based on https://github.com/chrisvfritz/vue-enterprise-boilerplate/blob/master/src/components/_globals.js
import Vue from 'vue'

// @ts-ignore
const requireComponent = require.context(
  // Search in the components folder
  './components/Base',
  // Ignore subdirectories
  false,
  // Include "Base" prefixed .vue files
  /Base[\w-]+\.vue$/
)

requireComponent.keys().forEach((filePath: string) => {
  const componentConfig = requireComponent(filePath)
  const componentName = filePath
    // Remove the "./"
    .replace(/^\.\//, '')
    // Remove the file extension
    .replace(/\.\w+$/, '')
  // Globally register the component
  Vue.component(componentName, componentConfig.default || componentConfig)
})
