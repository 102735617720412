































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

@Component
export default class ModalAdvertiser extends Vue {
  @Getter iabDaast1Categories!: any
  @Getter iabDaast2Categories!: any
  @Getter daastValues!: string[]
  @Getter networkId!: string

  @Action getDaast1IABCategories!: any
  @Action getDaast2IABCategories!: any
  @Action createAdvertiser!: any

  sponsorName = ''
  advertiserName = ''
  isUploadingTag = false

  cur = 'USD'

  validationStatus = {
    advertiserName: false,
    categories: false,
  }

  currency = ['USD', 'INR', 'EUR']

  daast1Value: string = ''
  daast2Value: string = ''

  async changeDaast() {
    await this.getDaast2IABCategories(this.daast1Value)
    let daastArray = []
    daastArray.push(this.daast1Value)
    this.$store.dispatch('changeAudioAdDaast', daastArray)
    this.validation()
  }

  get iabDasst1() {
    return this.iabDaast1Categories.map(function (iab: { name: string; uniqueId: string }) {
      return {
        text: iab.name,
        value: iab.uniqueId,
      }
    })
  }

  get iabDasst2() {
    if (!this.iabDaast2Categories) return []
    return this.iabDaast2Categories.map(function (iab: { name: string; uniqueId: string }) {
      return {
        text: iab.name,
        value: iab.uniqueId,
      }
    })
  }

  @Watch('daast2Value')
  async changeDaastSecond() {
    let daastArray = []
    if (this.daast1Value) {
      // daastArray.push(this.daast1Value)
      daastArray.push(this.daast2Value)
      this.$store.dispatch('changeAudioAdDaast', daastArray)
    } else {
      daastArray.push(this.daast1Value)
      this.$store.dispatch('changeAudioAdDaast', daastArray)
    }
    this.validation()
  }

  async created() {
    await this.getDaast1IABCategories()
    this.$store.dispatch('changeAudioAdDaast', [])
  }

  changeAdvertiser() {}

  validation() {
    if (this.advertiserName === '') this.validationStatus.advertiserName = true
    else this.validationStatus.advertiserName = false

    if (this.daastValues.length === 0) this.validationStatus.categories = true
    else this.validationStatus.categories = false

    if (this.validationStatus.categories === true || this.validationStatus.advertiserName === true) return false
    else return true
  }

  async handleCreateAdvertiser() {
    if (!this.validation()) return
    this.isUploadingTag = true
    const payload = {
      data: {
        name: this.advertiserName,
        kind: 'PAID',
        currency: this.cur,
        iabDaastCategories: this.daastValues,
      },
      networkId: this.networkId,
    }
    await this.createAdvertiser(payload)
    this.isUploadingTag = false
    this.$emit('close')
  }
}
