var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "f4 light-gray fw5" }, [
          _vm._v("Change Setting"),
        ]),
      ]),
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "flex flex-column" }, [
          _c("div", { staticClass: "flex flex-wrap items-center mb5 mt2" }, [
            _c("div", { staticClass: "w5" }, [
              _c("i", { staticClass: "material-icons light-gray mr3 v-mid" }, [
                _vm._v(" aspect_ratio "),
              ]),
              _c("p", { staticClass: "dib f5 b light-gray" }, [
                _vm._v("Set Size"),
              ]),
            ]),
            _c("div", [
              _c("div", { staticClass: "mb3" }, [
                _c("div", { staticClass: "w4 dib mr2" }, [
                  _c("label", { staticClass: "f5 fw1 light-gray" }, [
                    _vm._v("Width (px)"),
                  ]),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.width,
                      expression: "width",
                    },
                  ],
                  staticClass: "w4",
                  attrs: {
                    type: "number",
                    placeholder: "1280",
                    autofocus: "autofocus",
                    max: "3840",
                    min: "426",
                    required: "",
                  },
                  domProps: { value: _vm.width },
                  on: {
                    keydown: function ($event) {
                      return _vm.formatSizeDuration($event, "width")
                    },
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.width = $event.target.value
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "mb3" }, [
                _c("div", { staticClass: "w4 dib mr2" }, [
                  _c("span", { staticClass: "f5 fw1 light-gray" }, [
                    _vm._v("Height (px)"),
                  ]),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.height,
                      expression: "height",
                    },
                  ],
                  staticClass: "w4",
                  attrs: {
                    type: "number",
                    placeholder: "720",
                    max: "2160",
                    min: "240",
                    required: "",
                  },
                  domProps: { value: _vm.height },
                  on: {
                    keydown: function ($event) {
                      return _vm.formatSizeDuration($event, "height")
                    },
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.height = $event.target.value
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "mb2" }, [
                _c("button", {
                  staticClass:
                    "bn w3 h3 mr3 bg-transparent landscape bg-center pointer",
                  class: { isActive: _vm.active === "landscape" },
                  on: {
                    click: function ($event) {
                      return _vm.setActive("landscape")
                    },
                  },
                }),
                _c("button", {
                  staticClass:
                    "bn w3 h3 mr3 bg-transparent square bg-center pointer",
                  class: { isActive: _vm.active === "square" },
                  on: {
                    click: function ($event) {
                      return _vm.setActive("square")
                    },
                  },
                }),
                _c("button", {
                  staticClass:
                    "bn w3 h3 bg-transparent portrait bg-center pointer",
                  class: { isActive: _vm.active === "portrait" },
                  on: {
                    click: function ($event) {
                      return _vm.setActive("portrait")
                    },
                  },
                }),
              ]),
              _vm.active === "square"
                ? _c("div", { staticClass: "silver f6" }, [
                    _vm._v("Best for Instagram, Facebook & Twitter"),
                  ])
                : _vm.active === "landscape"
                ? _c("div", { staticClass: "silver f6" }, [
                    _vm._v("Best for Youtube and websites"),
                  ])
                : _vm.active === "portrait"
                ? _c("div", { staticClass: "silver f6" }, [
                    _vm._v("Best for Instagram reels, Youtube shorts"),
                  ])
                : _c("div", { staticClass: "silver f6" }, [
                    _vm._v("Select above recommended sizes"),
                  ]),
            ]),
          ]),
          _c("div", { staticClass: "flex flex-wrap items-center mb5" }, [
            _c("div", { staticClass: "w5" }, [
              _c("i", { staticClass: "material-icons light-gray mr3 v-mid" }, [
                _vm._v(" hourglass_bottom "),
              ]),
              _c("p", { staticClass: "dib f5 b light-gray" }, [
                _vm._v("Set Duration"),
              ]),
            ]),
            _c("div", [
              _c("div", { staticClass: "flex mb3" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "flex flex-column w4 dib mr2 f5 fw1 light-gray",
                  },
                  [
                    _c("span", [_vm._v("Begin ")]),
                    _c("span", { staticClass: "silver" }, [
                      _vm._v("(HH:MM:SS)"),
                    ]),
                  ]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.beginTime,
                      expression: "beginTime",
                    },
                  ],
                  ref: "beginRef",
                  staticClass: "w4 content-height",
                  attrs: { type: "text", placeholder: "00:00:00" },
                  domProps: { value: _vm.beginTime },
                  on: {
                    keydown: function ($event) {
                      return _vm.formatSizeDuration($event, "begin")
                    },
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.beginTime = $event.target.value
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "flex" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "flex flex-column w4 dib mr2 f5 fw1 light-gray",
                  },
                  [
                    _c("span", [_vm._v("End ")]),
                    _c("span", { staticClass: "silver" }, [
                      _vm._v("(HH:MM:SS)"),
                    ]),
                  ]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.endTime,
                      expression: "endTime",
                    },
                  ],
                  ref: "endRef",
                  staticClass: "w4 content-height",
                  attrs: { type: "text", placeholder: "00:00:00" },
                  domProps: { value: _vm.endTime },
                  on: {
                    keydown: function ($event) {
                      return _vm.formatSizeDuration($event, "end")
                    },
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.endTime = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "tr" }, [
            _c(
              "button",
              {
                staticClass:
                  "bn bg-adori-red glow ph3 pv2 light-gray o-90 br2 btn-shadow pointer",
                on: { click: _vm.handleSave },
              },
              [_vm._v("\n          Save\n        ")]
            ),
          ]),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }