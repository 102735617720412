




























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class BaseMultiTagInput extends Vue {
  @Prop(Array) initialValue!: Object[]
  values = this.initialValue || [{}]

  addValues() {
    this.values.push({})
    this.updateValues()
  }
  deleteValues(pos: Number) {
    if (this.values.length > 1) this.values = this.values.filter((obj: Object, index: Number) => index !== pos)
    this.updateValues()
  }

  updateValues() {
    let keyword: string[] = []
    for (const obj of this.values) {
      keyword.push(`${Object.values(obj)[0] || ''}:${Object.values(obj)[1] || ''}`)
    }
    this.$emit('multInput', keyword)
  }
}
