










































import { Component, Vue, Mixins } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import AdoriService from '@/services/adori'

@Component({
  // @ts-ignore
  components: {},
})
export default class ModalSetMembers extends Vue {
  @Getter rssFeedsByUid!: any
  @Getter showUsers!: any
  @Getter networkId!: any

  @Action getShowUsers!: any
  @Action showConfirm!: any
  @Action closeModal!: any

  selectedPodcastUid: string = ''
  isLoading: boolean = false

  async created() {
    this.selectedPodcastUid = this.$store.state.modal.setMembers.podcastUid
    if (!this.showUsers(this.selectedPodcastUid)) {
      this.isLoading = true
      await this.getShowUsers(this.selectedPodcastUid)
      this.isLoading = false
    }
  }

  get rssShowUsers() {
    return this.showUsers(this.selectedPodcastUid)
  }

  async handleRemoveUser(member: any) {
    this.showConfirm({
      title: `Remove ${member.role.name} rights for this user?`,
      description: `You are about to remove ${member.role.name} rights from this user. To restore you will have to add this user again.`,
      yesButtonText: 'Remove user',
      noButtonText: 'Cancel',
      onConfirm: async () => {
        await AdoriService.deleteShowUser(this.networkId, member.user.id, this.selectedPodcastUid)
        await this.getShowUsers(this.selectedPodcastUid)
        this.closeModal()
      },
      onCancel: async () => {
        this.closeModal()
      },
    })
  }
}
