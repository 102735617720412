var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDisabled
    ? _c(
        "span",
        {
          staticClass:
            "flex flex-wrap justify-center pv3 not-allowed f7 fw1 link medium-gray no-outline o-50 relative",
        },
        [
          _vm.isBeta
            ? _c(
                "div",
                {
                  staticClass: "absolute top-0 left-0 bg-adori-red ph2 radius",
                },
                [_c("span", [_vm._v("beta")])]
              )
            : _vm._e(),
          _vm.icon
            ? _c(
                "i",
                { staticClass: "material-icons white f3 v-mid mb1 o-50" },
                [_vm._v(_vm._s(_vm.icon))]
              )
            : _vm._e(),
          _vm.type === "img" && _vm.active
            ? _c("img", {
                staticClass: "mb1",
                attrs: {
                  src: _vm.activeSrc,
                  alt: "",
                  width: "25",
                  height: "25",
                },
              })
            : _vm._e(),
          _vm.type === "img" && !_vm.active
            ? _c("img", {
                staticClass: "mb1",
                attrs: {
                  src: _vm.inactiveSrc,
                  alt: "",
                  width: "25",
                  height: "25",
                },
              })
            : _vm._e(),
          _c("a", { staticClass: "w-100 tc" }, [_vm._v(_vm._s(_vm.text))]),
        ]
      )
    : _c(
        "span",
        {
          staticClass: "flex flex-wrap justify-center relative",
          class: _vm.styleClass,
          on: {
            click: function ($event) {
              return _vm.tabAction()
            },
          },
        },
        [
          _vm.isBeta
            ? _c(
                "div",
                {
                  staticClass: "absolute top-0 left-0 bg-adori-red ph2 radius",
                },
                [_c("span", [_vm._v("beta")])]
              )
            : _vm._e(),
          _vm.icon
            ? _c(
                "i",
                {
                  staticClass: "material-icons white f3 v-mid mb1",
                  class: _vm.active ? "" : "o-50",
                },
                [_vm._v(_vm._s(_vm.icon))]
              )
            : _vm._e(),
          _vm.type === "img" && _vm.active
            ? _c("img", {
                staticClass: "mb1",
                attrs: {
                  src: _vm.activeSrc,
                  alt: "",
                  width: "25",
                  height: "25",
                },
              })
            : _vm._e(),
          _vm.type === "img" && !_vm.active
            ? _c("img", {
                staticClass: "mb1",
                attrs: {
                  src: _vm.inactiveSrc,
                  alt: "",
                  width: "25",
                  height: "25",
                },
              })
            : _vm._e(),
          _c("a", { staticClass: "w-100 tc" }, [_vm._v(_vm._s(_vm.text))]),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }