import Vue from 'vue'
import { StoreOptions, GetterTree, MutationTree, ActionTree } from 'vuex'
import AdoriService from '@/services/adori'

const state: any = {
  audioCollectionIds: [],
  selectedAudioCollectionId: null,
  audioCollectionsById: {},
  audiosByAudioCollectionId: {},
  audiosByAudioCollectionIdCount: -1,
  audiosByAudioCollectionIdOffset: -1,
  audioCollectionSearchValue: null,
  isAudioCollectionSearching: false,
  audioCollectionSearchingCount: 0,
}

const initialState: any = {
  ...JSON.parse(JSON.stringify(state)),
}

const getters: GetterTree<any, any> = {
  audioCollectionIds: (state) => state.audioCollectionIds,
  defaultAudioCollection: (state) => Object.values(state.audioCollectionsById).find((ac: any) => ac.default),
  createdAudioCollections: (state) => Object.values(state.audioCollectionsById).filter((ac: any) => !ac.default),
  audioCollectionsById: (state) => state.audioCollectionsById,
  audioCollection: (state) => (id: string) => state.audioCollectionsById[id],
  selectedAudioCollectionId: (state) => state.selectedAudioCollectionId,
  hasSelectedAudioCollectionId: (state) => state.selectedAudioCollectionId !== null,
  audiosInAudioCollection: (state) => (collectionId: string) => state.audiosByAudioCollectionId[collectionId],
  audiosByAudioCollectionIdCount: (state) => state.audiosByAudioCollectionIdCount,
  audiosByAudioCollectionIdOffset: (state) => state.audiosByAudioCollectionIdOffset,
  audiosInAudioCollectionLazyLoader: (state) => (collectionId: string) => ({
    data: state.audiosByAudioCollectionId[collectionId],
    count: state.audiosByAudioCollectionIdCount,
    offset: state.audiosByAudioCollectionIdOffset,
  }),
  audioCollectionSearchValue: (state) => state.audioCollectionSearchValue,
  isAudioCollectionSearching: (state) => state.isAudioCollectionSearching,
}

const mutations: MutationTree<any> = {
  resetState(state) {
    Object.keys(state).forEach((key: any) => {
      Vue.set(state, key, initialState[key])
    })
  },

  setAudioCollectionIds(state, audioCollectionIds) {
    Vue.set(state, 'audioCollectionIds', audioCollectionIds)
  },

  setAudioCollection(state, audioCollection) {
    Vue.set(state.audioCollectionsById, audioCollection.id, audioCollection)
  },

  addAudioCollection(state, audioCollection) {
    Vue.set(state, 'audioCollectionIds', [audioCollection.id, ...state.audioCollectionIds])
    Vue.set(state.audioCollectionsById, audioCollection.id, audioCollection)
    Vue.set(state.audiosByAudioCollectionId, audioCollection.id, [])
  },

  removeAudioCollection(state, audioCollectionId) {
    const index = state.audioCollectionIds.indexOf(audioCollectionId)
    Vue.delete(state.audioCollectionIds, index)
    Vue.delete(state.audioCollectionsById, audioCollectionId)
    Vue.delete(state.audiosByAudioCollectionId, audioCollectionId)
  },

  renameAudioCollection(state, { collectionId, oldCollectionName, newCollectionName }) {
    Vue.set(state.audioCollectionsById[collectionId], 'name', newCollectionName)
  },

  setAudioCollectionTracks(state, { audioCollectionId, audioTrackUids }) {
    if (
      state.audiosByAudioCollectionId[audioCollectionId] &&
      state.audiosByAudioCollectionId[audioCollectionId].length !== 0
    ) {
      Vue.set(state.audiosByAudioCollectionId, audioCollectionId, [
        ...new Set([...audioTrackUids, ...state.audiosByAudioCollectionId[audioCollectionId]]),
      ])
    } else {
      Vue.set(state.audiosByAudioCollectionId, audioCollectionId, audioTrackUids)
    }
  },

  setAudioTrackUidsCount(state, count) {
    Vue.set(state, 'audiosByAudioCollectionIdCount', count)
  },

  setAudioTrackUidsOffset(state, offset) {
    Vue.set(state, 'audiosByAudioCollectionIdOffset', offset)
  },

  setAudioCollectionSearchValue(state, audioCollectionSearchValue) {
    Vue.set(state, 'audioCollectionSearchValue', audioCollectionSearchValue)
  },

  setIsAudioCollectionSearching(state, isAudioCollectionSearching) {
    Vue.set(state, 'isAudioCollectionSearching', isAudioCollectionSearching)
    if (isAudioCollectionSearching) {
      Vue.set(state, 'audioCollectionSearchingCount', state.audioCollectionSearchingCount + 1)
    } else {
      if (state.audioCollectionSearchingCount === 1) {
        Vue.set(state, 'audioCollectionSearchingCount', state.audioCollectionSearchingCount - 1)
      } else {
        Vue.set(state, 'audioCollectionSearchingCount', state.audioCollectionSearchingCount - 1)
      }
    }
  },

  clearAudioCollections(state, audioCollectionId) {
    // Vue.set(state, 'audioCollectionIds', [])
    // Vue.set(state, 'selectedAudioCollectionId', null)
    // Vue.set(state, 'audioCollectionsById', {})
    Vue.set(state, 'audiosByAudioCollectionId', { audioCollectionId: [] })
    Vue.set(state, 'audiosByAudioCollectionIdCount', -1)
    Vue.set(state, 'audiosByAudioCollectionIdOffset', -1)
    // Vue.set(state, 'audioCollectionSearchValue', null)
    // Vue.set(state, 'isAudioCollectionSearching', false)
  },

  addAudiosToAudioCollection(state, { collectionId, audioUids }) {
    const audioUidsInCollection = state.audiosByAudioCollectionId[collectionId]
    Vue.set(state.audiosByAudioCollectionId, collectionId, [
      ...audioUids.filter((uid: string) => audioUidsInCollection.indexOf(uid) === -1),
      ...audioUidsInCollection,
    ])
  },

  removeAudiosFromAudioCollection(state, { collectionId, audioUids }) {
    const audioUidsInCollection = state.audiosByAudioCollectionId[collectionId]
    audioUids.forEach((uid: string) => {
      const index = audioUidsInCollection.indexOf(uid)
      if (index !== -1) {
        Vue.delete(audioUidsInCollection, index)
      }
    })
  },

  setSelectedAudioCollectionId(state, audioCollectionId) {
    Vue.set(state, 'selectedAudioCollectionId', audioCollectionId)
  },
}

const actions: ActionTree<any, any> = {
  resetAudioCollectionsState(context) {
    context.commit('resetState')
  },

  async getAudioCollections(context) {
    const networkId = context.getters.networkId
    const audioCollections: any = await AdoriService.fetchAudioCollectionIds(networkId)
    const audioCollectionIds: any = audioCollections.data.map((collection: any) => collection.id)
    context.commit('setAudioCollectionIds', audioCollectionIds)
    for (const collectionId of audioCollectionIds) {
      await context.dispatch('getAudioCollection', collectionId)
      await context.dispatch('getAudioCollectionTracks', {
        audioCollectionId: collectionId,
      })
    }
  },

  async getAudioCollection(context, audioCollectionId: string) {
    const networkId = context.getters.networkId
    const audioCollection = await AdoriService.fetchAudioCollection(networkId, audioCollectionId)
    context.commit('setAudioCollection', audioCollection)
  },

  async getAudioCollectionTracks(
    context,
    { audioCollectionId, paginate = true, limit = 50, offset = 0, query = null, clear = false, search = true }
  ) {
    const networkId = context.getters.networkId
    let audioTrackUids: any = []
    if (search) {
      context.commit('setIsAudioCollectionSearching', true)
    }
    if (clear) {
      context.commit('clearAudioCollections', audioCollectionId)
    }
    if (query) {
      audioTrackUids = await AdoriService.fetchAudioUidsSearch(networkId, {
        params: {
          paginate,
          limit,
          offset,
          query,
        },
      })
    } else {
      audioTrackUids = await AdoriService.fetchAudioUids(networkId, {
        params: {
          paginate,
          limit,
          offset,
        },
      })
    }
    // let audioTrackUids: any = await AdoriService.fetchAudioUidsInAudioCollection(networkId, audioCollectionId, {
    //   params: {
    //     paginate,
    //     limit,
    //     offset
    //   }
    // })
    if (paginate) {
      const audioTrackUidsData = audioTrackUids.data.map((audioTrack: any) => audioTrack.uid)
      context.commit('setAudioTrackUidsCount', audioTrackUids.count)
      context.commit('setAudioTrackUidsOffset', audioTrackUids.offset)
      context.commit('setAudioCollectionTracks', {
        audioCollectionId,
        audioTrackUids: audioTrackUidsData,
      })
    } else {
      context.commit('setAudioTrackUidsCount', audioTrackUids.length)
      context.commit('setAudioTrackUidsOffset', audioTrackUids.length)
      context.commit('setAudioCollectionTracks', {
        audioCollectionId,
        audioTrackUids,
      })
    }
    context.commit('setIsAudioCollectionSearching', false)
  },

  async addAudioCollection(context, { collectionName }) {
    const networkId = context.getters.networkId
    const newAudioCollection = await AdoriService.createAudioCollection(networkId, {
      name: collectionName,
    })
    context.commit('addAudioCollection', newAudioCollection)
  },

  async removeAudioCollection(context, { collectionId }) {
    const networkId = context.getters.networkId
    await AdoriService.deleteAudioCollection(networkId, collectionId)
    context.commit('removeAudioCollection', collectionId)
  },

  async renameAudioCollection(context, { collectionId, oldCollectionName, newCollectionName }) {
    const networkId = context.getters.networkId
    await AdoriService.updateAudioCollection(networkId, collectionId, {
      name: newCollectionName,
    })
    context.commit('renameAudioCollection', {
      collectionId,
      oldCollectionName,
      newCollectionName,
    })
  },

  async addAudiosToAudioCollection(context, { collectionId, audioUids }) {
    const defaultAudioCollectionId = context.getters.defaultAudioCollection.id
    const networkId = context.getters.networkId
    if (collectionId !== defaultAudioCollectionId) {
      await AdoriService.updateAudioUidsInAudioCollection(networkId, collectionId, audioUids)
    }
    context.dispatch('getAudioCollectionTracks', {
      audioCollectionId: collectionId,
    })
  },

  async removeAudiosFromAudioCollection(context, { collectionId, audioUids }) {
    const networkId = context.getters.networkId
    await AdoriService.deleteAudioUidsInAudioCollection(networkId, collectionId, audioUids)
    context.dispatch('getAudioCollectionTracks', {
      audioCollectionId: collectionId,
    })
  },

  async moveAudiosBetweenAudioCollections(context, { audioUids, fromCollectionId, toCollectionId }) {
    context.dispatch('removeAudiosFromAudioCollection', {
      collectionId: fromCollectionId,
      audioUids,
    })
    context.commit('addAudiosToAudioCollection', {
      collectionId: toCollectionId,
      audioUids,
    })
  },

  async deleteAudioFromAudioCollections(context, audioUid) {
    const audioCollectionIds = context.getters.createdAudioCollections.map((ac: any) => ac.id)
    for (const audioCollectionId of audioCollectionIds) {
      const audioUidsInAudioCollection = context.getters.audiosInAudioCollection(audioCollectionId)
      if (audioUidsInAudioCollection.indexOf(audioUid) !== -1) {
        context.commit('removeAudiosFromAudioCollection', {
          collectionId: audioCollectionId,
          audioUids: [audioUid],
        })
      }
    }
    await context.dispatch('getAudioCollectionTracks', {
      audioCollectionId: context.getters.defaultAudioCollection.id,
      search: false,
    })
  },

  async setSelectedAudioCollectionId(context, audioCollectionId) {
    context.commit('setSelectedAudioCollectionId', audioCollectionId)
  },

  async clearSelectedAudioCollectionId(context) {
    context.commit('setSelectedAudioCollectionId', null)
  },

  async setAudioCollectionSearchValue(context, { audioCollectionSearchValue, audioCollectionId }) {
    context.commit('setIsAudioCollectionSearching', true)
    if (!audioCollectionSearchValue) {
      const networkId = context.getters.networkId
      const audioTrackUids: any = await AdoriService.fetchAudioUids(networkId, {
        params: {
          paginate: true,
          limit: 50,
          offset: 0,
        },
      })
      context.commit('clearAudioCollections', audioCollectionId)
      const audioTrackUidsData = audioTrackUids.data.map((audioTrack: any) => audioTrack.uid)
      context.commit('setAudioTrackUidsCount', audioTrackUids.count)
      context.commit('setAudioTrackUidsOffset', audioTrackUids.offset)
      context.commit('setAudioCollectionTracks', {
        audioCollectionId,
        audioTrackUids: audioTrackUidsData,
      })
    }
    context.commit('setAudioCollectionSearchValue', audioCollectionSearchValue)
    context.commit('setIsAudioCollectionSearching', false)
  },

  setIsAudioCollectionSearching(context, isAudioCollectionSearching) {
    context.commit('setIsAudioCollectionSearching', isAudioCollectionSearching)
  },
}

const audioCollections: StoreOptions<any> = {
  state,
  getters,
  mutations,
  actions,
}

export default audioCollections
