
















import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { VueTelInput } from 'vue-tel-input'

@Component({ components: { VueTelInput } })
export default class AdoriPhone extends Vue {
  isValidNumber: boolean = true
  phoneNumber: string = ''

  mounted() {
    this.phoneNumber = this.$store.state.modal.tagEdit.data.inputValues[1]
  }

  phoneNumberCurrentInput(data: any) {
    this.$emit('phoneNumberCurrentInput', data)
  }

  validate(data: any) {
    this.isValidNumber = data.isValid
    this.$emit('validPhoneNumber', data)
  }
}
