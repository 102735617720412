import { twentyFourHoursInMs, tenMinsInMs, queryDefaults } from '@/utils/query.config'
import AdoriServiceV6 from '@/services/adori_v6'
import store from '@/store'
import { useMutation, useQuery } from 'vue-query'
import Tap from '@tapfiliate/tapfiliate-js'

// QueryKeys
export enum billingQueryKeys {
  BILLING_SUMMARY = 'BILLING_SUMMARY',
  BILLING_INVOICES = 'BILLING_INVOICES',
  BILLING_CARD = 'BILLING_CARD',
  BILLING_USAGE = 'BILLING_USAGE',
}

// Queries
function fetchBillingSummary(networkId: string) {
  return AdoriServiceV6.fetchBillingSummary(networkId)
}
function fetchBillingInvoices(networkId: string) {
  return AdoriServiceV6.fetchBillingInvoicesUpcoming(networkId)
}
function fetchBillingCard(networkId: string) {
  return AdoriServiceV6.getDefaultBillingCard(networkId)
}
function fetchBillingUsage(networkId: string) {
  return AdoriServiceV6.getBillingUsage(networkId)
}

function addBillingCard(networkId: string) {
  const successUrl = window.location.origin + '/settings/billing?card_added=true'
  const cancelUrl = window.location.origin + '/settings/billing?card_added=false'
  return AdoriServiceV6.addBillingCard(networkId, successUrl, cancelUrl)
}
function updateBillingCard(networkId: string) {
  const successUrl = window.location.origin + '/settings/subscription?card_added=true'
  const cancelUrl = window.location.origin + '/settings/subscription?card_added=false'
  return AdoriServiceV6.addBillingCard(networkId, successUrl, cancelUrl)
}
function upgradePlan(networkId: string, fromType: string, toType: string) {
  const successUrl = window.location.origin + '/settings/plans?card_added=true'
  const cancelUrl = window.location.origin + '/settings/plans?card_added=false'
  return AdoriServiceV6.upgradePlan(networkId, {
    success_url: successUrl,
    cancel_url: cancelUrl,
    from_type: fromType,
    to_type: toType,
  })
}

//Query Hooks
export const useGetBillingSummary = ({ enabled }: any) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useQuery([billingQueryKeys.BILLING_SUMMARY, networkId], () => fetchBillingSummary(networkId), {
    ...queryDefaults,
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled,
    onSuccess: (data: any) => {
      // Tap affiliate code enable when required
      //   Boolean(localStorage.getItem('isNewUser')) && Tap.init('34017-509c6d')
      //   if (Boolean(localStorage.getItem('isNewUser'))) {
      //     Tap.trial(data?.customer)
      //     localStorage.removeItem('isNewUser')
      //   }
    },
  })
}
export const useGetBillingInvoices = ({ enabled }: any) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useQuery([billingQueryKeys.BILLING_INVOICES, networkId], () => fetchBillingInvoices(networkId), {
    ...queryDefaults,
    staleTime: tenMinsInMs,
    cacheTime: tenMinsInMs,
    enabled,
    refetchOnWindowFocus: false,
  })
}
export const useGetBillingCard = ({ enabled }: any) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useQuery([billingQueryKeys.BILLING_CARD, networkId], () => fetchBillingCard(networkId), {
    ...queryDefaults,
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled,
  })
}
export const useGetBillingUsage = ({ enabled }: any) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useQuery([billingQueryKeys.BILLING_USAGE, networkId], () => fetchBillingUsage(networkId), {
    ...queryDefaults,
    staleTime: tenMinsInMs,
    cacheTime: tenMinsInMs,
    enabled,
  })
}

// mutations

export const useAddBillingCard = () => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useMutation(() => addBillingCard(networkId), {
    onSuccess: (data: any) => {
      window.location.href = data.url
    },
  })
}
export const useUpdateBillingCard = () => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useMutation(() => updateBillingCard(networkId), {
    onSuccess: (data: any) => {
      window.location.href = data.url
    },
  })
}
export const useUpgradePlan = () => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useMutation((data: any) => upgradePlan(networkId, data.fromType, data.toType), {
    onSuccess: (data: any) => {
      window.location.href = data.url
    },
  })
}
