export interface invitesPayload {
  email: string
  grantType: 'OTP' | 'CODE'
  networkType: 'SAAS' | 'YOUTUBE' | 'CUSTOM' | 'YOUTUBE_METERED' | 'YOUTUBE_PRO' | 'YOUTUBE_ENTERPRISE'
  resend: boolean
}

export enum plansMapping {
  YOUTUBE_METERED = 'YOUTUBE STARTUP',
  YOUTUBE_PRO = 'YOUTUBE PARTNER PROGRAM',
  YOUTUBE_ENTERPRISE = 'YOUTUBE: NETWORK/ENTERPRISE',
}

export interface OtpVerifyAndsSignUp {
  email: string
  password: string
  confirmPassword: string
  name: string
  otp?: string
  affiliate?: string
}

export interface SocialSignUp {
  accessToken: string
  networkType: string
  affiliate?: string
}

export enum downloadNumber {
  YOUTUBE_METERED = 60,
  YOUTUBE_PRO = 60,
  YOUTUBE_ENTERPRISE = 60,
}
