var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-100" }, [
    _vm.isLoadingStyles
      ? _c(
          "div",
          { staticClass: "templates-grid" },
          _vm._l(_vm.loaders, function (_, index) {
            return _c("div", {
              key: index,
              staticClass:
                "w-100 bg-adori-light-gray bb bw1 b--adori-gray b-img skeleton",
            })
          }),
          0
        )
      : _vm.subtitleStyles.length
      ? _c("div", { staticClass: "w-100 br2 white pb2" }, [
          _c(
            "div",
            { staticClass: "templates-grid" },
            _vm._l(_vm.subtitleStyles, function (id) {
              return _c("div", { staticClass: "relative flex hide-child" }, [
                _c("img", {
                  staticClass: "b-img nodrag w-100",
                  class: _vm.selectionBorder(id),
                  attrs: { src: _vm.subtitleStyleById[id].image.originalUrl },
                }),
                _c(
                  "span",
                  {
                    staticClass:
                      "smaller flex flex-column justify-around absolute child",
                    staticStyle: { top: "0px", right: "5px" },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "link pointer ba b--adori-gray br-100 bg-dark-gray w2 h2 flex justify-center items-center btn-shadow mb2",
                        attrs: { title: "Delete Style" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteSubStyle(id)
                          },
                        },
                      },
                      [
                        _c(
                          "i",
                          {
                            staticClass:
                              "material-icons light-gray f4 v-top pa1",
                          },
                          [_vm._v(" delete ")]
                        ),
                      ]
                    ),
                  ]
                ),
                _c("div", { staticClass: "absolute noselect top-0 left-0" }, [
                  _c(
                    "i",
                    {
                      staticClass:
                        "material-icons f3 white mt1 ml1 relative text-shadow",
                      class: _vm.selectionStyle(id) + " pointer",
                      on: {
                        mousedown: function ($event) {
                          return _vm.selectSubtitleStyle(id)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.selectionIcon(id)))]
                  ),
                ]),
              ])
            }),
            0
          ),
        ])
      : _vm._e(),
    _vm.subtitleStyles.length === 0 ? _c("div", [_vm._m(0)]) : _vm._e(),
    _vm.hasNextTemplate && _vm.subtitleStyles.length !== 0
      ? _c(
          "div",
          { staticClass: "tc pb3 mb3" },
          [
            _c("BaseButtonLoadMore", {
              attrs: {
                text: "Load more",
                onClick: _vm.nextTemplatePage,
                onVisible: function () {},
                loading: _vm.isNextTemplateFetching,
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty" }, [
      _c("img", {
        attrs: {
          width: "150px",
          alt: "placeholder",
          src: require("@/assets/audiogram/no-data.svg"),
        },
      }),
      _c("div", { staticClass: "text white" }, [
        _vm._v("You've not created any custom templates yet"),
      ]),
      _c("span", [_vm._v("Go to Custom tab to create one")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }