








































import { Vue, Component, Prop } from 'vue-property-decorator'
import OptionSelector from '@/components/Common/OptionSelector.vue'

@Component({ components: { OptionSelector } })
export default class ModalConfirm extends Vue {
  getValue(key: string) {
    return this.$store.state.modal.confirm[key]
  }

  hasValue(key: string) {
    return this.$store.state.modal.confirm[key] !== undefined
  }

  get inputValue() {
    return this.$store.state.modal.confirm.inputValue
  }

  set inputValue(value: string) {
    this.$store.dispatch('setConfirmInputValue', value)
  }

  onAction(action: 'onConfirm' | 'onCancel') {
    const onAction = this.getValue(action)
    if (onAction) onAction()
    if (action === 'onCancel' && !onAction) {
      this.$store.dispatch('closeModal')
    }
  }
}
