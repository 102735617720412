











import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class ModalPreviewImages extends Vue {
  @Prop() image!: string
}
