import { formatOpenAiCaption } from '@/utils/misc'
import AdoriServiceV6 from '@/services/adori_v6'
import AdoriService from '@/services/adori'
import Vue from 'vue'
import { StoreOptions, GetterTree, MutationTree, ActionTree } from 'vuex'
import get from 'lodash.get'
import store from '.'

const state: any = {
  tempScriptContent: '',
  tempScriptTitle: '',
  blogSceneList: [],
  parsedBlogResponse: {},
  blogHistory: [],
  currentHistoryIndex: 0,
}

const getters: GetterTree<any, any> = {
  tempScriptContent: (state) => state.tempScriptContent,
  tempScriptTitle: (state) => state.tempScriptTitle,
  blogSceneList: (state) => state.blogSceneList,
  parsedBlogResponse: (state) => state.parsedBlogResponse,
  blogHistory: (state) => state.blogHistory,
  currentHistoryIndex: (state) => state.currentHistoryIndex,
}

const mutations: MutationTree<any> = {
  setScriptContent(state, content) {
    Vue.set(state, 'tempScriptContent', content)
  },
  setScriptTitle(state, title) {
    Vue.set(state, 'tempScriptTitle', title)
  },
  updateBlogSceneList(state, data) {
    Vue.set(state, 'blogSceneList', data)
  },
  setParsedBlog(state, data) {
    Vue.set(state, 'parsedBlogResponse', data)
  },
  clearParsedBlog(state) {
    Vue.set(state, 'parsedBlogResponse', {})
    Vue.set(state, 'blogSceneList', [])
  },
  setBlogHistory(state, data) {
    Vue.set(state, 'blogHistory', data)
  },
  setHistoryIndex(state, data) {
    Vue.set(state, 'currentHistoryIndex', data)
  },
}

const actions: ActionTree<any, any> = {
  async aiToAudio(context, content) {
    const temp = content.length > 2000 ? content.replace(/^(.{2000}[^\s]*).*/, '$1') : content
    const networkId = context.getters.networkId

    let description: any = AdoriServiceV6.openAi(networkId, {
      topic: `Give me summarized description for this text:\n ${temp}`,
      context: 'description',
    })

    description = await description

    return formatOpenAiCaption(description.result)
  },

  async generateAiImages(context, { audioTrack, sentences }) {
    try {
      context.commit('setTimelineLoader', true)
      sessionStorage.setItem('timelineLoader', audioTrack.uid)
      context.commit('setShowLoaderText', 'Generating AI images from OpenAI....')
      const networkId = context.getters.networkId
      const openAiPayload = context.getters.openAiPayload['image']
      const finalArray: any = []
      sentences.map(async (sentence: any) => {
        const openAi: any = await AdoriServiceV6.openAi(networkId, {
          //   ...openAiPayload,
          topic: `write an image prompt for this description:${sentence.text}. Give only prompt as response.`,
          context: 'image',
        })
        let stabilityRes: any = await AdoriServiceV6.stabilityResults(networkId, {
          prompt: formatOpenAiCaption(openAi.result),
        })

        if (stabilityRes.status === 'processing' || stabilityRes.status === null) {
          stabilityRes = await handleStabilityPolling(stabilityRes.id)
        }

        if (stabilityRes.output.length) {
          finalArray.push({
            time: sentence.start_time,
            url: stabilityRes.output[0],
          })
        } else {
          const unsplashRes: any = await AdoriService.searchImages(networkId, 'unsplash', {
            limit: 10,
            offset: 0,
            query: formatOpenAiCaption(openAi.result),
          })
          if (unsplashRes.data.length) {
            finalArray.push({
              time: sentence.start_time,
              url: unsplashRes.data[0].urls.full,
            })
          } else {
            const googleRes: any = await AdoriService.searchImages(networkId, 'google', {
              limit: 10,
              offset: 0,
              query: formatOpenAiCaption(openAi.result),
            })
            if (googleRes.data.length) {
              finalArray.push({
                time: sentence.start_time,
                url: googleRes.data[0].urls.full,
              })
            } else {
              finalArray.push(false)
            }
          }
        }

        finalArray.length === sentences.length && context.dispatch('createTags', { finalArray, audioTrack })
      })
    } catch (error) {
      context.dispatch('pushNotification', {
        text: 'Generating tags Failed!!! try again later',
        type: 'WARNING',
      })
      context.commit('setTimelineLoader', false)
      sessionStorage.removeItem('timelineLoader')
      context.commit('setShowLoaderText', '')
    }
  },

  async createTags(context, { finalArray: imagesArray, audioTrack }) {
    const getImgMeta = (src: string) => {
      return new Promise((resolve, reject) => {
        let img = new Image()
        img.onload = () => resolve({ originalImageWidth: img.width, originalImageHeight: img.height })
        img.onerror = reject
        img.src = src
      })
    }
    try {
      const temArray = []
      context.commit('setShowLoaderText', 'Creating visuals ...')
      const networkId = context.getters.networkId
      imagesArray.map(async (imageObj: any, index: number) => {
        if (imageObj) {
          const image: any = await AdoriService.uploadImage(networkId, {
            url: imageObj.url,
          })

          const imageMeta: any = await getImgMeta(image.originalUrl)
          const originalImageWidth = imageMeta.originalImageWidth
          const originalImageHeight = imageMeta.originalImageHeight
          let imageWidth, imageHeight
          if (originalImageWidth >= originalImageHeight) {
            // Landscape or square image
            imageWidth = 1080
            imageHeight = 1080 / (originalImageWidth / originalImageHeight)
          } else {
            // Portrait image
            imageHeight = 1080
            imageWidth = 1080 * (originalImageWidth / originalImageHeight)
          }
          // Calculate scaleX and scaleY based on original image dimensions
          const scaleX = imageWidth / originalImageWidth
          const scaleY = imageHeight / originalImageHeight
          // Draw the image in the center of the canvas
          const x = (1080 - imageWidth) / 2
          const y = (1080 - imageHeight) / 2
          context.rootState.modal.tagEdit.data.tagType = 'photo'
          context.rootState.modal.tagEdit.data.canvasData = {
            version: '4.6.0',
            objects: [
              {
                type: 'rect',
                version: '4.6.0',
                originX: 'left',
                originY: 'top',
                left: 0,
                top: 0,
                width: 1080,
                height: 1080,
                fill: {
                  type: 'linear',
                  coords: {
                    x1: 0,
                    y1: 540,
                    x2: 1920,
                    y2: 0,
                  },
                  colorStops: [
                    {
                      offset: 0,
                      color: '#21D4FD',
                    },
                    {
                      offset: 1,
                      color: '#B721FF',
                    },
                  ],
                  offsetX: 0,
                  offsetY: 0,
                  gradientUnits: 'pixels',
                  gradientTransform: null,
                },
                stroke: null,
                strokeWidth: 1,
                strokeDashArray: null,
                strokeLineCap: 'butt',
                strokeDashOffset: 0,
                strokeLineJoin: 'miter',
                strokeUniform: false,
                strokeMiterLimit: 4,
                scaleX: 1,
                scaleY: 1,
                angle: 0,
                flipX: false,
                flipY: false,
                opacity: 1,
                shadow: null,
                visible: true,
                backgroundColor: '',
                fillRule: 'nonzero',
                paintFirst: 'fill',
                globalCompositeOperation: 'source-over',
                skewX: 0,
                skewY: 0,
                rx: 0,
                ry: 0,
                id: 'artboard',
              },
              {
                type: 'image',
                version: '4.6.0',
                originX: 'left',
                originY: 'top',
                left: x,
                top: y,
                width: originalImageWidth,
                height: originalImageHeight,
                fill: 'rgb(0,0,0)',
                stroke: null,
                strokeWidth: 0,
                strokeDashArray: null,
                strokeLineCap: 'butt',
                strokeDashOffset: 0,
                strokeLineJoin: 'miter',
                strokeUniform: false,
                strokeMiterLimit: 4,
                scaleX: scaleX,
                scaleY: scaleY,
                angle: 0,
                flipX: false,
                flipY: false,
                opacity: 1,
                shadow: null,
                visible: true,
                backgroundColor: '',
                fillRule: 'nonzero',
                paintFirst: 'fill',
                globalCompositeOperation: 'source-over',
                skewX: 0,
                skewY: 0,
                cropX: 0,
                cropY: 0,
                src: image.originalUrl,
                crossOrigin: 'anonymous',
                filters: [],
              },
            ],
          }
          context.rootState.modal.tagEdit.tagTitle = 'AI TAG'
          context.rootState.modal.tagEdit.data.orientation = 'SQUARE'
          context.rootState.modal.tagEdit.data.imageId = image.id
          context.rootState.modal.tagEdit.data.imageData = ''
          const timelinePosition = parseInt(imageObj.time.replace('s', '')) * 1000

          const tagId = await context.dispatch('uploadTag', false)
          const tagItem = {
            tagId,
            offsetMillis: timelinePosition,
          }
          temArray.push(tagId)
          context.dispatch('addAudioTag', {
            audioUid: audioTrack.uid,
            tagPosition: tagItem,
          })
        } else {
          temArray.push(false)
        }
        if (temArray.length === imagesArray.length) {
          context.commit('setTimelineLoader', false)
          sessionStorage.removeItem('timelineLoader')
          context.dispatch('uploadAudioTags', audioTrack.uid)
        }
      })
    } catch (error) {
      context.dispatch('pushNotification', {
        text: 'Generating tags Failed!!! try again later',
        type: 'WARNING',
      })
      context.commit('setTimelineLoader', false)
      sessionStorage.removeItem('timelineLoader')
      context.commit('setShowLoaderText', '')
    }
  },

  async createParseBlogTask(context, payload) {
    const networkId = context.getters.networkId
    const task: any = await AdoriServiceV6.postBlogParse(networkId, payload)
    return task.id
  },

  async createParseFileTask(context, payload) {
    const networkId = context.getters.networkId
    const task: any = await AdoriServiceV6.postFileParse(networkId, payload)
    return task.id
  },
}

const blockingDelay = (milliseconds: number) => {
  const startTime = Date.now()
  while (Date.now() - startTime < milliseconds) {
    // Block the execution
  }
}

const handleStabilityPolling = async (id: string) => {
  const networkId = store.getters.networkId
  return new Promise(async (resolve, reject) => {
    let loop = true
    while (loop) {
      const res: any = await AdoriServiceV6.stabilityStatus(networkId, id)
      if (res.status === 'success') {
        loop = false
        resolve(res)
      } else if (['failure', 'error'].includes(res.status)) {
        reject(new Error(res))
      }
      blockingDelay(2000)
    }
  })
}

const ai: StoreOptions<any> = {
  state,
  getters,
  mutations,
  actions,
}

export default ai
