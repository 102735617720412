var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    { attrs: { locked: _vm.isSubmitting } },
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "light-gray f3 fw5" }, [
          _vm._v("Add member to your show"),
        ]),
      ]),
      _c("template", { slot: "body" }, [
        _c(
          "div",
          {
            staticClass: "flex flex-column justify-between",
            staticStyle: { width: "650px", "min-height": "360px" },
          },
          [
            _c("div", [
              _c("div", { staticClass: "relative" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.emailId,
                      expression: "emailId",
                    },
                  ],
                  staticClass:
                    "db bg-adori-very-light-gray bw0 br2 ph2 pv2 f6 white br mr3 dib",
                  staticStyle: { width: "53%" },
                  attrs: { placeholder: "Enter email" },
                  domProps: { value: _vm.emailId },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.emailId = $event.target.value
                      },
                      _vm.emailChange,
                    ],
                  },
                }),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedRole,
                        expression: "selectedRole",
                      },
                    ],
                    staticClass:
                      "input-reset light-gray bn br2 ph3 pv2 shadow-2 db w-40 mt3 arrow dib",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectedRole = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  _vm._l(_vm.filteredRoles, function (role) {
                    return _c(
                      "option",
                      {
                        key: role.name,
                        attrs: { disabled: role.name === "Select role" },
                        domProps: { value: role.name },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(role.name) +
                            "\n            "
                        ),
                      ]
                    )
                  }),
                  0
                ),
                !_vm.isValidEmail
                  ? _c(
                      "p",
                      {
                        staticClass: "adori-red f7 w-100 absolute",
                        staticStyle: { bottom: "-32px" },
                      },
                      [
                        _vm._v(
                          "\n            Please enter a valid email ID !\n          "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.isEmptyEmail
                  ? _c(
                      "p",
                      {
                        staticClass: "adori-red f7 w-100 absolute",
                        staticStyle: { bottom: "-32px" },
                      },
                      [
                        _vm._v(
                          "\n            Email ID cannot be empty !\n          "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
              _vm.descriptions
                ? _c("div", [
                    _c("div", { staticClass: "silver f4 mt4" }, [
                      _vm._v("The user you are adding can:"),
                    ]),
                    _c(
                      "div",
                      {},
                      _vm._l(_vm.descriptions, function (des) {
                        return _c("ul", { key: des, staticClass: "silver" }, [
                          _c("li", { staticClass: "f5 mb2" }, [
                            _vm._v(_vm._s(des)),
                          ]),
                        ])
                      }),
                      0
                    ),
                  ])
                : _vm.selectedRole === "Select role"
                ? _c("div", { staticClass: "silver f4 mt4" }, [
                    _vm._v(
                      "\n          Choose a role to see available permissions.\n        "
                    ),
                  ])
                : _vm._e(),
            ]),
            _c(
              "div",
              { staticClass: "flex w-100 justify-end" },
              [
                _c("BaseButtonRed", {
                  staticClass: "ph4 w-30 mt3 mr1 fr",
                  staticStyle: { "margin-right": "12px" },
                  attrs: {
                    disabled: _vm.disableAddMember,
                    text: "Add member",
                    onClick: _vm.addShowMember,
                  },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }