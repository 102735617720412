var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      attrs: { hasCustomEvent: true },
      on: {
        closeModal: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "plan-container flex justify-center" }, [
          _c("img", {
            staticClass: "cus-size",
            attrs: { src: _vm.image, alt: "" },
          }),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }