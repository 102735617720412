var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group" }, [
    _c("div", [
      _c(
        "div",
        { staticClass: "masonry-wall" },
        _vm._l(_vm.masonryColumns, function (column) {
          return _c(
            "div",
            { staticClass: "masonry-column" },
            _vm._l(column, function (item) {
              return _c("div", { key: item.id, staticClass: "masonry-item" }, [
                _c(
                  "div",
                  {
                    staticClass: "img-opt relative hide-child",
                    on: {
                      click: function ($event) {
                        return _vm.loadAsset(item)
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "img fade",
                      attrs: {
                        "data-src": item.previewUrl,
                        src: item.previewUrl,
                        lazy: "loaded",
                      },
                    }),
                    !!item.user
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "absolute white bg-black-50 author-name w-100 no-underline f6 child",
                            attrs: {
                              href:
                                item.user.url +
                                "?utm_source=adori&utm_medium=referral",
                              target: "_blank",
                            },
                          },
                          [_vm._v(_vm._s(item.user.name))]
                        )
                      : _vm._e(),
                  ]
                ),
              ])
            }),
            0
          )
        }),
        0
      ),
    ]),
    _c("a", {
      ref: "imageDownloadRef",
      staticClass: "dn",
      attrs: { download: "img", target: "_blank" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }