var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      class: _vm.styleClass,
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.buttonAction()
        },
      },
    },
    [
      _vm.icon
        ? _c(
            "i",
            {
              staticClass: "f6 material-icons light-gray v-top",
              class: _vm.text ? "mr1" : "",
            },
            [_vm._v(_vm._s(_vm.icon))]
          )
        : _vm._e(),
      _c("div", { staticClass: "flex justify-center" }, [
        _c("p", { staticClass: "ma0 dib" }, [_vm._v(_vm._s(_vm.text))]),
        _vm.loading
          ? _c("div", { staticClass: "ml2 loader loader-inline" })
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }