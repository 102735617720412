import get from 'lodash.get'
import axios, { CancelToken } from 'axios'
import { ADORI_SSO_BASE_URL, ADORI_API_BASE_URL, ADORI_STUDIO_VERSION, TOKEN_STORAGE_KEY } from '@/constants'
import store from '@/store'
import router from '@/router'
import * as Sentry from '@sentry/vue'
import {
  invitesPayload,
  OtpVerifyAndsSignUp,
  SocialSignUp,
} from '@/Interfaces/StoreInterFaces/accountInterfaces/SignupInterface'

export default {
  // ---------------------------------------------------------
  // YoutubeSignUp/SignIn
  // ---------------------------------------------------------
  youtubeSignUpOtp(payload: invitesPayload) {
    return AdoriAPI({
      method: 'post',
      url: '/invites',
      data: payload,
    })
  },

  youtubeVerifyOtpAndSignUp(payload: OtpVerifyAndsSignUp) {
    return AdoriAPI({
      method: 'post',
      url: `/invites/${payload.otp}`,
      data: payload,
    })
  },

  youtubeSocialSignUp(payload: SocialSignUp) {
    return AdoriAPI({
      method: 'post',
      url: `/invites/social`,
      data: payload,
    })
  },

  // ---------------------------------------------------------
  // Profile
  // ---------------------------------------------------------
  // Test passed
  fetchProfile() {
    return AdoriAPI.get('/users/me')
  },
  fetchProfileNetworks(payload: any) {
    return AdoriAPI.get('/users/me/member-networks', payload)
  },
  // Test passed
  updateProfile(payload: any) {
    return AdoriAPI.put(`/users/me`, payload)
  },
  // ---------------------------------------------------------
  // Networks
  // ---------------------------------------------------------
  updateProfileNetwork(networkId: string, payload: any) {
    return AdoriAPI.patch(`/networks/${networkId}`, payload)
  },
  // TODO: Not tested
  fetchNetworks(userId: string) {
    return AdoriAPI.get(`/users/${userId}/networks`)
  },
  fetchRoles(networkId: string) {
    return AdoriAPI.get(`/networks/${networkId}/roles`)
  },
  fetchNetworkUsers(networkId: string) {
    return AdoriAPI.get(`/networks/${networkId}/users`)
  },
  addNetworkUser(networkId: string, payload: any) {
    return AdoriAPI.post(`/networks/${networkId}/users`, payload)
  },
  deleteNetworkUser(networkId: string, userId: any) {
    return AdoriAPI.delete(`/networks/${networkId}/users/${userId}`)
  },
  // ---------------------------------------------------------
  // Shows
  // ---------------------------------------------------------
  fetchShowUsers(networkId: string, feedUid: any) {
    return AdoriAPI.get(`/networks/${networkId}/rssfeeds/${feedUid}/users`)
  },
  addShowUser(networkId: string, feedUid: any, payload: any) {
    return AdoriAPI.post(`/networks/${networkId}/rssfeeds/${feedUid}/users`, payload)
  },
  deleteShowUser(networkId: string, userId: any, feedUid: any) {
    return AdoriAPI.delete(`/networks/${networkId}/rssfeeds/${feedUid}/users/${userId}`)
  },
  deleteShowYoutubeUser(networkId: string, userId: any, feedUid: any) {
    return AdoriAPI.delete(`/networks/${networkId}/integrations/youtube/rssfeeds/${feedUid}/users/${userId}`)
  },
  fetchShowYoutubeUsers(networkId: string, feedUid: any) {
    return AdoriAPI.get(`/networks/${networkId}/integrations/youtube/rssfeeds/${feedUid}/users`)
  },
  addShowYoutubeUser(networkId: string, feedUid: any, payload: any) {
    return AdoriAPI.post(`/networks/${networkId}/integrations/youtube/rssfeeds/${feedUid}/users`, payload)
  },
  // ---------------------------------------------------------
  // Permissions
  // ---------------------------------------------------------
  fetchPermissions() {
    return AdoriAPI.get(`/permissions`)
  },
  // ---------------------------------------------------------
  // Network state
  // ---------------------------------------------------------
  // Test passed
  fetchNetworkState(networkId: string) {
    return AdoriAPI.get(`/networks/${networkId}/state`)
  },
  // Test passed
  updateNetworkState(networkId: string, payload: any) {
    return AdoriAPI.put(`/networks/${networkId}/state`, payload)
  },
  // ---------------------------------------------------------
  // Client state
  // ---------------------------------------------------------
  // Test passed
  fetchClientState() {
    return AdoriAPI.get(`/users/me/state`)
  },
  // Test passed
  updateClientState(payload: any) {
    return AdoriAPI.put(`/users/me/state`, payload)
  },
  // ---------------------------------------------------------
  // Images
  // ---------------------------------------------------------
  // Test passed
  fetchImages(networkId: string, limit: number = 10, offset: number = 0) {
    return AdoriAPI.get(`/networks/${networkId}/images?limit=${limit}&offset=${offset}`)
  },
  // Test passed
  uploadImage(networkId: string, payload: any) {
    const contentType = payload.url ? 'application/json' : 'multipart/form-data'
    return AdoriAPI.post(`/networks/${networkId}/images`, payload, {
      headers: {
        'Content-Type': contentType,
      },
    })
  },

  getImageInfo(networkId: string, path: string) {
    return AdoriAPI.get(`/networks/${networkId}/images/info?path=${path}`)
  },
  // ---------------------------------------------------------
  // Audios
  // ---------------------------------------------------------
  // Test passed
  fetchAudioUids(networkId: string, params: any, published?: boolean) {
    const publishedQueryParam = published ? '?public=true' : ''
    return AdoriAPI.get(`/networks/${networkId}/audiotracks${publishedQueryParam}`, params)
  },
  // Test passed
  fetchAudioUidsSearch(networkId: string, payload: any, published?: boolean) {
    const publishedQueryParam = published ? '?public=true' : ''
    return AdoriAPI.get(`/networks/${networkId}/audiotracks/search${publishedQueryParam}`, payload)
  },
  // Test passed
  fetchAudio(networkId: string, audioUid: string) {
    return AdoriAPI.get(`/networks/${networkId}/audiotracks/${audioUid}`, {
      params: {
        ignoreError: true,
      },
    })
  },
  // Test passed
  getAudioUpload(networkId: string, uploadId: string) {
    return AdoriAPI({
      method: 'get',
      url: `/networks/${networkId}/audiotracks/upload/${uploadId}`,
    })
  },

  createAudioUploadURL(networkId: string, payload: any) {
    return AdoriAPI.post(`/networks/${networkId}/audiotracks/upload`, payload)
  },
  createAudioUploadSessionURL(audioUploadUrl: string, fileType: string) {
    // Mea culpa: It's easier to create a temporary axios instance
    // here than removing the response interceptor from AdoriAPI.
    const token = store.getters.token
    return axios
      .post(
        audioUploadUrl,
        {},
        {
          baseURL: ADORI_API_BASE_URL,
          headers: {
            'Content-Type': fileType,
            'x-goog-resumable': 'start',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => response.headers.location)
  },
  // Test passed
  // TODO: Not sure on handling cancelToken
  uploadAudio(sessionURL: string, payload: any, cancelToken: CancelToken, onUploadProgress: any) {
    return AdoriAPI.put(sessionURL, payload, {
      headers: {
        'Content-Type': payload.type,
      },
      cancelToken,
      onUploadProgress,
    })
  },
  // Test passed
  updateUpload(networkId: string, uploadId: string) {
    return AdoriAPI({
      method: 'PATCH',
      url: `/networks/${networkId}/audiotracks/upload/${uploadId}`,
    })
  },
  getUploadStatus(networkId: string, uploadId: string) {
    return AdoriAPI({
      method: 'GET',
      url: `/networks/${networkId}/audiotracks/upload/${uploadId}`,
    })
  },

  getTTSUploadStatus(networkId: string, taskId: string) {
    return AdoriAPI({
      method: 'GET',
      url: `/networks/${networkId}/audiotrack_from_file/${taskId}`,
    })
  },

  createAudio(networkId: string, payload: any) {
    return AdoriAPI.post(`/networks/${networkId}/audiotracks`, payload)
  },

  createAudioFromFile(networkId: string, payload: any) {
    let data = new FormData()
    data.append('file', payload.file)
    data.append('track_info', JSON.stringify(payload.track_info))
    return AdoriAPI.post(`/networks/${networkId}/audiotrack_from_file`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },

  // Test passed
  updateAudio(networkId: string, audioUid: string, payload: any) {
    return AdoriAPI.put(`/networks/${networkId}/audiotracks/${audioUid}`, payload)
  },
  // Test passed
  deleteAudio(networkId: string, audioUid: string, yt_feed_uid?: string) {
    if (yt_feed_uid) {
      return AdoriAPI.delete(`/networks/${networkId}/audiotracks/${audioUid}?yt_feed_uid=${yt_feed_uid}`)
    }
    return AdoriAPI.delete(`/networks/${networkId}/audiotracks/${audioUid}`)
  },
  // Test passed
  adorifyAudio(networkId: string, audioUid: string) {
    return AdoriAPI.get(`/networks/${networkId}/audiotracks/${audioUid}/adorify`)
  },
  replaceAudio(networkId: string, audioUid: string, payload: string) {
    return AdoriAPI.post(`/networks/${networkId}/audiotracks/${audioUid}/replace`, payload)
  },
  // ---------------------------------------------------------
  // Audio's ads positions
  // ---------------------------------------------------------
  // Test passed
  fetchAudioAds(networkId: string, payload: any) {
    return AdoriAPI.get(`/networks/${networkId}/audioads`, payload)
  },
  fetchAudioAd(networkId: string, adId: string) {
    return AdoriAPI.get(`/networks/${networkId}/audioads/${adId}`)
  },
  // Test passed
  createAudioAd(networkId: string, payload: any) {
    return AdoriAPI.post(`/networks/${networkId}/audioads`, payload)
  },
  // Test passed
  insertAudioAds(networkId: string, audioUid: string, payload: any) {
    return AdoriAPI.post(`/networks/${networkId}/audiotracks/${audioUid}/audioads`, payload)
  },
  fetchAudioAdsByAudio(networkId: string, audioUid: string, payload: any) {
    return AdoriAPI.get(`/networks/${networkId}/audiotracks/${audioUid}/audioads`, payload)
  },
  // ---------------------------------------------------------
  // Audio's tags positions
  // ---------------------------------------------------------
  // Test passed
  fetchAudioTags(networkId: string, audioUid: string) {
    return AdoriAPI.get(`/networks/${networkId}/audiotracks/${audioUid}/tags`)
  },
  // Test passed
  updateAudioTags(networkId: string, audioUid: string, payload: any) {
    return AdoriAPI.put(`/networks/${networkId}/audiotracks/${audioUid}/tags`, payload)
  },
  // ---------------------------------------------------------
  // Audiocollections
  // ---------------------------------------------------------
  // Test passed
  fetchAudioCollectionIds(networkId: string) {
    return AdoriAPI.get(`/networks/${networkId}/audiocollections`)
  },
  fetchLanguages() {
    return AdoriAPI.get(`/languages`)
  },

  fetchVoiceLanguages(provider: any) {
    return AdoriAPI.get(`/tts/languages?service=${provider}`)
  },

  fetchVoiceDetails(params: any, provider: any) {
    params = { ...params, service: provider }
    return AdoriAPI({
      method: 'GET',
      url: `tts/voices`,
      params,
    })
  },
  // Test passed
  fetchAudioCollection(networkId: string, audioCollectionId: string) {
    return AdoriAPI.get(`/networks/${networkId}/audiocollections/${audioCollectionId}`)
  },
  // TODO: Not used anymore?
  createAudioCollection(networkId: string, payload: any) {
    return AdoriAPI.post(`/networks/${networkId}/audiocollections`, payload)
  },
  // TODO: Not used anymore?
  updateAudioCollection(networkId: string, audioCollectionId: string, payload: any) {
    return AdoriAPI.put(`/networks/${networkId}/audiocollections/${audioCollectionId}`, payload)
  },
  // TODO: Not used anymore?
  deleteAudioCollection(networkId: string, audioCollectionId: string) {
    return AdoriAPI.delete(`/networks/${networkId}/audiocollections/${audioCollectionId}`)
  },
  // TODO: Not used anymore?
  fetchAudioUidsInAudioCollection(networkId: string, audioCollectionId: string, payload: any) {
    return AdoriAPI.get(`/networks/${networkId}/audiocollections/${audioCollectionId}/audiotracks`, payload)
  },
  // TODO: Not used anymore?
  updateAudioUidsInAudioCollection(networkId: string, audioCollectionId: string, payload: any) {
    return AdoriAPI.post(`/networks/${networkId}/audiocollections/${audioCollectionId}/audiotracks`, payload)
  },
  // TODO: Not used anymore?
  deleteAudioUidsInAudioCollection(networkId: string, audioCollectionId: string, payload: any) {
    return AdoriAPI.delete(`/networks/${networkId}/audiocollections/${audioCollectionId}/audiotracks`, payload)
  },
  // ---------------------------------------------------------
  // Tags
  // ---------------------------------------------------------
  // Test passed
  fetchTagIds(networkId: string, payload: any) {
    return AdoriAPI.get(`/networks/${networkId}/tags`, payload)
  },
  // Test passed
  fetchTagIdsSearch(networkId: string, payload: any) {
    return AdoriAPI.get(`/networks/${networkId}/tags/search`, payload)
  },
  // Test passed
  fetchTag(networkId: string, tagId: string) {
    return AdoriAPI.get(`/networks/${networkId}/tags/${tagId}`)
  },
  // Test passed
  createTag(networkId: string, payload: any) {
    return AdoriAPI.post(`/networks/${networkId}/tags`, payload)
  },
  // Test passed
  updateTag(networkId: string, tagId: string, payload: any) {
    return AdoriAPI.put(`/networks/${networkId}/tags/${tagId}`, payload)
  },
  // Test passed
  deleteTag(networkId: string, tagId: string) {
    return AdoriAPI.delete(`/networks/${networkId}/tags/${tagId}`)
  },
  // Test passed
  fetchAudioTracksForTag(networkId: string, tagId: string) {
    return AdoriAPI.get(`/networks/${networkId}/tags/${tagId}/audiotracks`)
  },
  // ---------------------------------------------------------
  // Location
  // ---------------------------------------------------------
  // Test passed
  fetchLocations(networkId: string) {
    return AdoriAPI.get(`/networks/${networkId}/locations`)
  },
  // Test passed
  fetchLocation(networkId: string, locationId: string) {
    return AdoriAPI.get(`/networks/${networkId}/locations/${locationId}`)
  },
  // Test passed
  createLocation(networkId: string, payload: any) {
    return AdoriAPI.post(`/networks/${networkId}/locations`, payload)
  },
  // Test passed
  updateLocation(networkId: string, locationId: string, payload: any) {
    return AdoriAPI.put(`/networks/${networkId}/locations/${locationId}`, payload)
  },
  // ---------------------------------------------------------
  // Contact
  // ---------------------------------------------------------
  // Test passed
  fetchContacts(networkId: string) {
    return AdoriAPI.get(`/networks/${networkId}/contacts`)
  },
  // Test passed
  fetchContact(networkId: string, contactId: string) {
    return AdoriAPI.get(`/networks/${networkId}/contacts/${contactId}`)
  },
  // Test passed
  createContact(networkId: string, payload: any) {
    return AdoriAPI.post(`/networks/${networkId}/contacts`, payload)
  },
  // Test passed
  updateContact(networkId: string, contactId: string, payload: any) {
    return AdoriAPI.put(`/networks/${networkId}/contacts/${contactId}`, payload)
  },
  // ---------------------------------------------------------
  // Polls
  // ---------------------------------------------------------
  // Test passed
  fetchPolls(networkId: string) {
    return AdoriAPI.get(`/networks/${networkId}/polls`)
  },
  // Test passed
  fetchPoll(networkId: string, pollId: string) {
    return AdoriAPI.get(`/networks/${networkId}/polls/${pollId}`)
  },
  // Test passed
  createPoll(networkId: string, payload: any) {
    return AdoriAPI.post(`/networks/${networkId}/polls`, payload)
  },
  // Test passed
  updatePoll(networkId: string, pollId: string, payload: any) {
    return AdoriAPI.put(`/networks/${networkId}/polls/${pollId}`, payload)
  },
  // ---------------------------------------------------------
  // Tag suggestions
  // ---------------------------------------------------------
  // Test passed
  generateTagSuggestions(networkId: string, audioUid: string, source: string) {
    return AdoriAPI.get(`/networks/${networkId}/audiotracks/${audioUid}/generate-suggestions?source=${source}`)
  },
  // Test passed
  fetchTagSuggestions(networkId: string, audioUid: string, source: string) {
    return AdoriAPI.get(`/networks/${networkId}/audiotracks/${audioUid}/suggestions?source=${source}`)
  },
  // ---------------------------------------------------------
  // Audio transcripts
  // ---------------------------------------------------------
  generateAudioTranscript(networkId: string, audioUid: string, language: string) {
    return AdoriAPI.get(`/networks/${networkId}/audiotracks/${audioUid}/generate-transcript?language=${language}`)
  },
  // Test passed
  fetchAudioTranscript(networkId: string, audioUid: string) {
    return AdoriAPI.get(`/networks/${networkId}/audiotracks/${audioUid}/transcript`, {
      params: {
        ignoreError: true,
      },
    })
  },

  // Test passed

  uploadTranscript(networkId: string, audioTrackUid: string, payload: any) {
    let data = new FormData()
    data.append('file', payload)
    return AdoriAPI.post(`/networks/${networkId}/audiotracks/${audioTrackUid}/generate-transcript-srt`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },

  downloadTranscript(networkId: string, payload: any) {
    return AdoriAPI.get(`/networks/${networkId}/audiotracks/${payload.audioUid}/download_transcript?attachment=true`, {
      params: {
        format: payload.format,
      },
    })
  },
  // ---------------------------------------------------------
  // Analytics
  // ---------------------------------------------------------
  fetchAudioCollectionAnalytics(audioCollectionId: string) {
    return AdoriAPI.get(`/audiocollections/${audioCollectionId}/insights`)
  },
  fetchAudioTrackAnalytics(audioUid: string) {
    return AdoriAPI.get(`/audiotracks/${audioUid}/insights`)
  },
  // Test passed
  fetchNetworkAnalytics(networkId: string, payload: any) {
    return AdoriAPI.get(`/networks/${networkId}/insights?start-date=${payload.start_date}&end-date=${payload.end_date}`)
  },
  // Test passed
  fetchRssFeedAnalytics(networkId: string, feedUid: string, payload: any) {
    return AdoriAPI.get(
      `/networks/${networkId}/rssfeeds/${feedUid}/insights?start-date=${payload.start_date}&end-date=${payload.end_date}`
    )
  },
  // Test passed
  fetchRssFeedAudioTrackAnalytics(networkId: string, feedUid: string, audioUid: string, payload: any) {
    return AdoriAPI.get(
      `/networks/${networkId}/rssfeeds/${feedUid}/items/${audioUid}/insights?start-date=${payload.start_date}&end-date=${payload.end_date}`
    )
  },
  // ---------------------------------------------------------
  // RSS Feeds
  // ---------------------------------------------------------
  fetchYTRssFeeds(networkId: string, payload: any) {
    return AdoriAPI.get(`/networks/${networkId}/integrations/youtube/rssfeeds`, payload)
  },
  // Test passed
  fetchRssFeeds(networkId: string, payload: any) {
    return AdoriAPI.get(`/networks/${networkId}/rssfeeds`, payload)
  },
  // Test passed
  fetchRssFeedsSearch(networkId: string, payload: any) {
    return AdoriAPI.get(`/networks/${networkId}/rssfeeds/search`, payload)
  },
  // Test passed
  fetchRssFeed(networkId: string, rssFeedUid: string) {
    return AdoriAPI.get(`/networks/${networkId}/rssfeeds/${rssFeedUid}`)
  },
  // Test passed
  createRssFeed(networkId: string, payload: any) {
    return AdoriAPI.post(`/networks/${networkId}/rssfeeds`, payload)
  },
  // Test passed
  importRssFeed(networkId: string, payload: any) {
    return AdoriAPI.post(`/networks/${networkId}/rssfeeds/import`, payload)
  },
  // Test passed
  updateRssFeed(networkId: string, rssFeedUid: string, payload: any) {
    return AdoriAPI.patch(`/networks/${networkId}/rssfeeds/${rssFeedUid}`, payload)
  },
  // Test passed
  deleteRssFeed(networkId: string, rssFeedUid: string, deleteTracks: boolean) {
    return AdoriAPI.delete(`/networks/${networkId}/rssfeeds/${rssFeedUid}?delete_tracks=${deleteTracks}`)
  },
  // Test passed
  fetchRssFeedItem(networkId: string, rssFeedUid: string, rssFeedItemUid: string) {
    return AdoriAPI.get(`/networks/${networkId}/rssfeeds/${rssFeedUid}/items/${rssFeedItemUid}`)
  },
  // TODO: Take up after deleting audios (pagination)
  fetchRssFeedItems(
    networkId: string,
    rssFeedUid: string,
    limit: number,
    offset: number,
    filter?: any,
    order: string = 'recent_first',
    publishedAfter?: any,
    publishedBefore?: any,
    fromYoutube?: boolean
  ) {
    if (fromYoutube) {
      return AdoriAPI.get(
        `/networks/${networkId}/audiotracks?by_yt_feed_uid=${rssFeedUid}&with_video_clips=true&limit=${limit}&offset=${offset}&sort=LATEST_VIDEO_FIRST`
      )
    } else if (filter) {
      if (publishedAfter || publishedBefore) {
        const param1 = publishedAfter ? `&publishedAfter=${publishedAfter}` : ''
        const param2 = publishedBefore ? `&publishedBefore=${publishedBefore}` : ''

        return AdoriAPI.get(
          `/networks/${networkId}/rssfeeds/${rssFeedUid}/items?type=${filter}&limit=${limit}&offset=${offset}&order=${order}${param1}${param2}`
        )
      }
      return AdoriAPI.get(
        `/networks/${networkId}/rssfeeds/${rssFeedUid}/items?type=${filter}&limit=${limit}&offset=${offset}&order=${order}`
      )
    }
    return AdoriAPI.get(`/networks/${networkId}/rssfeeds/${rssFeedUid}/items?limit=${limit}&offset=${offset}`)
  },
  fetchYTfeedUnpublishedEpisodes(networkId: string, rssFeedUid: string) {
    return AdoriAPI.get(
      `/networks/${networkId}/integrations/youtube/rssfeeds/${rssFeedUid}/episodes?with_video_clips=false`
    )
  },
  fetchYTfeedAllEpisodes(networkId: string, rssFeedUid: string) {
    return AdoriAPI.get(`/networks/${networkId}/integrations/youtube/rssfeeds/${rssFeedUid}/episodes`)
  },
  fetchRssFeedItemsWithNextParam(networkId: string, rssFeedUid: string, limit: number, offset: number, filter?: any) {
    if (filter) {
      return AdoriAPI.get(
        `/networks/${networkId}/rssfeeds/${rssFeedUid}/items?type=${filter}&limit=${limit}&offset=${offset}`
      )
    }
    return AdoriAPI.get(`/networks/${networkId}/rssfeeds/${rssFeedUid}/items?limit=${limit}&offset=${offset}`)
  },
  fetchRssFeedItemsWithoutPagination(networkId: string, rssFeedUid: string, tagId?: string) {
    return AdoriAPI.get(`/networks/${networkId}/rssfeeds/${rssFeedUid}/items?tag_id=${tagId}`)
  },
  // Test passed
  addRssFeedItem(networkId: string, rssFeedUid: string, payload: any) {
    return AdoriAPI.put(`/networks/${networkId}/rssfeeds/${rssFeedUid}/items`, payload)
  },
  // Test passed
  updateRssFeedItem(networkId: string, rssFeedUid: string, itemUid: string, payload: any) {
    return AdoriAPI.patch(`/networks/${networkId}/rssfeeds/${rssFeedUid}/items/${itemUid}/update`, payload)
  },
  updateRssScheduledFeedItem(networkId: string, rssFeedUid: string, itemUid: string, payload: any) {
    return AdoriAPI.patch(`/networks/${networkId}/rssfeeds/${rssFeedUid}/items/${itemUid}/update-schedule`, payload)
  },
  // Test passed
  removeRssFeedItem(networkId: string, rssFeedUid: string, itemUid: string, deleteTrack: boolean) {
    if (deleteTrack) {
      return AdoriAPI.delete(
        `/networks/${networkId}/rssfeeds/${rssFeedUid}/items/${itemUid}?delete_track=${deleteTrack}`
      )
    }
    return AdoriAPI.delete(`/networks/${networkId}/rssfeeds/${rssFeedUid}/items/${itemUid}`)
  },
  // Test passed
  fetchRssGenres() {
    return AdoriAPI.get(`/genres/apple`)
  },
  // Test passed
  fetchRssFeedValidationErrors(networkId: string, rssFeedUid: string) {
    return AdoriAPI.get(`/networks/${networkId}/rssfeeds/${rssFeedUid}/validate`)
  },
  // Test passed
  publishRssFeed(networkId: string, rssFeedUid: string) {
    return AdoriAPI.post(`/networks/${networkId}/rssfeeds/${rssFeedUid}/publish`)
  },
  // ---------------------------------------------------------
  // Email verification
  // ---------------------------------------------------------
  // Test passed
  requestMoreFeedEmails(uid: string) {
    return AdoriAPI.get(`/shows/${uid}/emails`)
  },
  // Test passed
  fetchVerifiedEmails(networkId: string) {
    return AdoriAPI.get(`/networks/${networkId}/emails`)
  },
  // Test passed
  requestVerificationCode(networkId: string, payload: any) {
    return AdoriAPI.post(`/networks/${networkId}/emails`, payload)
  },
  // Test passed
  checkVerificationCode(payload: any) {
    return AdoriAPI.post(`/verify-email`, payload)
  },
  // ---------------------------------------------------------
  // Search APIs
  // ---------------------------------------------------------
  // Test passed
  searchPodcasts(searchText: string, cancelToken: CancelToken, next: number = 0) {
    return AdoriAPI.get(`/search?q=${searchText}&type=audiocollection&source=external&next=${next}`, {
      cancelToken,
    })
  },
  // Old api!
  searchPodcastByRssUrl(payload: any) {
    return AdoriAPI.post(`/search/rss`, payload)
  },
  // Test passed
  parseRssUrl(payload: any) {
    return AdoriAPI.post(`/search/rss/parse`, payload)
  },
  // Test passed
  searchImages(networkId: string, searchLibrary: string, params: any) {
    return AdoriAPI({
      method: 'get',
      url: `/networks/${networkId}/images/${searchLibrary}/search`,
      params,
    })
  },

  downloadUnsplashImage(networkId: string, params: any) {
    return AdoriAPI({
      method: 'get',
      url: `/networks/${networkId}/images/unsplash/download`,
      params,
    })
  },

  // ---------------------------------------------------------
  // (Internal) Featured content on app
  // ---------------------------------------------------------
  fetchFeaturedContent() {
    return AdoriAPI.get(`/featured`)
  },
  updateFeaturedContent(payload: any) {
    return AdoriAPI.post(`/featured`, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  // ---------------------------------------------------------
  // Invite codes
  // ---------------------------------------------------------
  signupWithInviteCode(code: string, payload: any) {
    return AdoriAPI.post(`/invites/${code}`, payload)
  },
  requestInviteCode(payload: any) {
    return AdoriAPI.post(`/request-invite`, payload)
  },
  generateInviteCode(payload: any) {
    return AdoriAPI.post(`/account-applicants`, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },

  checkInviteCode(payload: any) {
    return AdoriAPI.put(`/account-applicants`, payload)
  },
  // ---------------------------------------------------------
  // Payments and orders
  // ---------------------------------------------------------
  fetchPaymentPlans(type = '') {
    if (type) {
      return AdoriAPI.get(`/payments/plans?type=` + type)
    } else {
      return AdoriAPI.get(`/payments/plans`)
    }
  },
  fetchPaymentPlan(planId: string) {
    return AdoriAPI.get(`/payments/plans/${planId}`)
  },
  fetchPaymentSubscription(networkId: string) {
    return AdoriAPI.get(`/networks/${networkId}/payments/customers`)
  },

  createPaymentOrder(networkId: string, payload: CreatePaymentOrder) {
    return AdoriAPI.post(`/networks/${networkId}/payments/orders`, payload)
  },
  createPaymentTransaction(networkId: string, payload: PaymentTransaction) {
    return AdoriAPI.post(`/networks/${networkId}/payments/transactions`, payload)
  },
  fetchPaymentTransaction(networkId: string, transactionId: string) {
    return AdoriAPI.get(`/networks/${networkId}/payments/transactions/${transactionId}`)
  },
  fetchPaymentSubscriptionEstimates(networkId: string, planId: string) {
    return AdoriAPI.get(`/networks/${networkId}/payments/subscriptions/estimates?plan_id=${planId}`)
  },
  fetchPaymentCards(networkId: string) {
    return AdoriAPI.get(`/networks/${networkId}/payments/cards`)
  },

  // ---------------------------------------------------------
  // YouTube Integration Api
  // ---------------------------------------------------------
  youtube_create_feed(networkId: string, data: { rss: string }) {
    return AdoriAPI({
      method: 'post',
      url: `/networks/${networkId}/integrations/youtube/rssfeeds`,
      data,
    })
  },

  youtube_authorize(networkId: string, next: string, showId?: string) {
    return AdoriAPI({
      method: 'get',
      url: `/networks/${networkId}${
        showId ? `/feeds/${showId}` : ''
      }/integrations/youtube/authorize?next=${encodeURIComponent(next)}`,
    })
  },

  youtube_linked_account(networkId: string, showId?: string) {
    return AdoriAPI({
      method: 'get',
      url: `/networks/${networkId}${showId ? `/feeds/${showId}` : ''}/integrations/youtube/accounts`,
    })
  },

  youtube_revoke(networkId: string, showId?: string) {
    return AdoriAPI({
      method: 'get',
      url: `/networks/${networkId}${showId ? `/feeds/${showId}` : ''}/integrations/youtube/revoke`,
    })
  },

  youtube_playlist(networkId: string, showId?: string, limit: number = 50) {
    return AdoriAPI({
      method: 'get',
      url: `/networks/${networkId}${showId ? `/feeds/${showId}` : ''}/integrations/youtube/playlists?limit=${limit}`,
    })
  },
  youtube_playlist_sync(networkId: string, showId?: string) {
    return AdoriAPI({
      method: 'get',
      url: `/networks/${networkId}${showId ? `/feeds/${showId}` : ''}/integrations/youtube/playlists/sync`,
    })
  },
  youtube_categories(networkId: string, showId?: string) {
    return AdoriAPI({
      method: 'get',
      url: `/networks/${networkId}${showId ? `/feeds/${showId}` : ''}/integrations/youtube/categories`,
    })
  },
  youtube_upload_delete(payload: { network_id: string; task_id: string }) {
    return AdoriAPI({
      method: 'delete',
      url: `/networks/${payload.network_id}/integrations/youtube/uploads/${payload.task_id}`,
    })
  },
  youtube_upload(payload: any) {
    return AdoriAPI({
      method: 'post',
      url: `/networks/${payload.params.network_id}${
        payload.params.showUid ? `/feeds/${payload.params.showUid}` : ''
      }/integrations/youtube/uploads`,
      data: payload.data,
    })
  },
  youtube_download(params: any) {
    return AdoriAPI({
      method: 'get',
      url: `/networks/${params.network_id}/integrations/youtube/uploads/${params.task_id}/download?attachment=true`,
    })
  },
  youtube_upload_status(params: any) {
    return AdoriAPI({
      method: 'get',
      url: `/networks/${params.network_id}/integrations/youtube/uploads/${params.task_id}`,
      params: {
        get_youtube_status: params.get_youtube_status ? params.get_youtube_status : false,
      },
    })
  },
  youtube_upload_status_by_uid(params: any) {
    const networkId = params.network_id
    delete params.network_id
    return AdoriAPI({
      method: 'get',
      url: `/networks/${networkId}/integrations/youtube/uploads`,
      params,
    })
  },
  youtube_upload_task_by_uid(params: any) {
    let url = `/networks/${params.network_id}/integrations/youtube/uploads?limit=${params.limit}&offset=${params.offset}&`
    params.trackUid.forEach((element: { uid: any }) => {
      url += `trackUid=${element.uid}&`
    })
    return AdoriAPI({
      method: 'get',
      url,
    })
  },
  youtube_upload_task(params: any, networkId: string) {
    let url = `/networks/${networkId}/integrations/youtube/uploads?`
    for (const id of params.trackUid) {
      url += `trackUid=${id}&`
    }
    return AdoriAPI({
      method: 'get',
      url,
    })
  },
  youtube_update_task(payload: any) {
    return AdoriAPI({
      method: 'patch',
      url: `/networks/${payload.params.network_id}/integrations/youtube/uploads/${payload.params.task_id}`,
      data: payload.data,
    })
  },
  youtube_upload_all(payload: { network_id: string; params: any }) {
    return AdoriAPI({
      method: 'get',
      url: `/networks/${payload.network_id}/integrations/youtube/uploads`,
      params: payload.params,
    })
  },

  youtube_remove_feed(payload: any) {
    return AdoriAPI({
      method: 'delete',
      url: `/networks/${payload.networkId}/integrations/youtube/rssfeeds/${payload.rssFeedUid}`,
    })
  },

  getIntegrationsSummary(networkId: string, showId?: string) {
    return AdoriAPI({
      method: 'get',
      url: `/networks/${networkId}${showId ? `/feeds/${showId}` : ''}/integrations/summary`,
    })
  },

  youtubeAutoPublish(payload: any) {
    return AdoriAPI({
      method: 'POST',
      url: `/networks/${payload.networkId}/integrations/youtube/rssfeeds/${payload.rssFeedUid}/auto-publish`,
      data: payload.data,
    })
  },

  getAllAutomations(networkId: string, params: any = {}) {
    return AdoriAPI({
      method: 'get',
      url: `/networks/${networkId}/auto-publish`,
    })
  },

  updateAutoPublish(payload: any) {
    return AdoriAPI({
      method: 'PATCH',
      url: `/networks/${payload.networkId}/integrations/youtube/rssfeeds/${payload.rssFeedUid}/auto-publish/${payload.autoPublishId}`,
      data: payload.data,
    })
  },

  enableAutoPublish(payload: any) {
    return AdoriAPI({
      method: 'PATCH',
      url: `/networks/${payload.networkId}/integrations/youtube/rssfeeds/${payload.rssFeedUid}/auto-publish/${payload.autoPublishId}/enable`,
    })
  },

  disableAutoPublish(payload: any) {
    return AdoriAPI({
      method: 'PATCH',
      url: `/networks/${payload.networkId}/integrations/youtube/rssfeeds/${payload.rssFeedUid}/auto-publish/${payload.autoPublishId}/disable`,
    })
  },

  getAutoPublish(payload: any) {
    return AdoriAPI({
      method: 'get',
      url: `/networks/${payload.networkId}/integrations/youtube/rssfeeds/${payload.rssFeedUid}/auto-publish/${payload.autoPublishId}`,
    })
  },
}
export function getImageSrc(imageId?: string) {
  return imageId ? `${ADORI_API_BASE_URL}/images/${imageId}` : null
}
// ---------------------------------------------------------
// API Client
// ---------------------------------------------------------
export const AdoriAPI = axios.create({
  baseURL: ADORI_API_BASE_URL,
})
AdoriAPI.interceptors.request.use(function (config) {
  const token = store.getters.token || localStorage.getItem(TOKEN_STORAGE_KEY)

  if (token !== null) {
    config.headers.Authorization = `Bearer ${token}`
  }
  config.headers['X-Adori-Studio-Version'] = ADORI_STUDIO_VERSION
  return config
})
AdoriAPI.interceptors.response.use(
  function (response) {
    return response.data
  },
  async function (error) {
    const response = error.response
    Sentry.captureException(response)
    if (localStorage.getItem('AUTH') && response.status === 404) {
      localStorage.removeItem('AUTH')
      try {
        const signUpType = sessionStorage.getItem('networkType')
        let networkType: any = window.location.origin.includes('studio') ? 'VIDEO_FREE' : 'VIDEO_FREE'
        if (signUpType) {
          networkType = signUpType
        }
        await store.dispatch('videoSocialSignUp', {
          accessToken: store.getters.token || localStorage.getItem(TOKEN_STORAGE_KEY),
          networkType: networkType,
        })
      } catch (e) {}
      return
    }
    if (get(response, 'config.params') && get(response, 'config.params.ignoreError')) {
      return response.status
    }
    if (!response) {
      return Promise.reject(new Error('No response.'))
    }
    if (response.status && response.status === 401) {
      store.dispatch('clearSession')
      window.location.href = `${ADORI_SSO_BASE_URL}/logout?redirect_uri=${encodeURIComponent(
        window.location.origin + '/login'
      )}`
    }

    if (response.status && response.status === 403) {
      router.push('/permission-error')
      store.dispatch('pushNotification', {
        text: 'You do not have necessary permissions for this action. Contact network owner to know more.',
        type: 'WARNING',
      })
    } else if (response.status && response.status === 422) {
      store.dispatch('pushNotification', {
        text: response.data.title || 'Something went wrong',
        type: 'ERROR',
      })
    } else if (response.status && response.status === 400) {
      store.dispatch('pushNotification', {
        text: response.data.description || response.data.title || 'Something went wrong',
        type: 'ERROR',
      })
    } else if (response.status && response.status === 409) {
      return
    } else if (response.status && response.status === 412) {
      if (response.data.title.includes('Limit exhausted')) {
        store.commit('setCurrentModal', 'plans')
      } else {
        store.dispatch('pushNotification', {
          text: response.data.description || response.data.title || 'Something went wrong',
          type: 'ERROR',
        })
      }
    } else if (response.status && response.status > 400 && response.status !== 406) {
      store.dispatch('pushNotification', {
        text: response.data.title || 'Something went wrong',
        type: 'ERROR',
      })
    }

    return Promise.reject(new Error(response.data.title || 'Something went wrong.'))
  }
)
// ---------------------------------------------------------
// Interfaces
// ---------------------------------------------------------
interface CreatePaymentOrder {
  planId: string
  mode: string
}
interface PaymentTransaction {
  orderId: string
  coupon?: string
}
