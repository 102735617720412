var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c("div", [_c("div", { staticClass: "skeleton cus-size" })])
    : _c("div", [
        _c(
          "div",
          { staticClass: "flex mt1" },
          [
            _c(
              "div",
              {
                staticClass: "flex items-center",
                class: _vm.isFirstClip ? "" : "ml174",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "f6 overflow-y-hidden truncate light-gray",
                    staticStyle: { width: "200px" },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.audio
                            ? _vm.audio.title
                            : "Loading audio details..."
                        ) +
                        "\n      "
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "flex flex-wrap justify-left items-center",
                staticStyle: { width: "550px" },
              },
              [
                _c("div", { staticClass: "f6 light-gray flex items-center" }, [
                  (_vm.showYoutubeLink || _vm.showProgressBar) &&
                  _vm.currentAudioDetails.publishToYoutube
                    ? _c("img", {
                        staticClass: "mh2 adori-red",
                        attrs: {
                          src: require("@/assets/social-youtube-red.png"),
                          alt: "",
                          height: "20",
                        },
                      })
                    : !_vm.currentAudioDetails.publishToYoutube
                    ? _c("img", {
                        staticClass: "mh2",
                        attrs: {
                          src: require("@/assets/video-download-red.svg"),
                          alt: "",
                          height: "20",
                        },
                      })
                    : _vm._e(),
                  _vm.currentAudioDetails &&
                  _vm.currentAudioDetails.status === "SCHEDULED"
                    ? _c("img", {
                        staticClass: "mh2 adori-red",
                        attrs: {
                          src: require("@/assets/social-youtube-red.png"),
                          alt: "",
                          height: "20",
                        },
                      })
                    : _vm._e(),
                  _c("span", { staticClass: "mr1" }, [
                    _vm._v(_vm._s(_vm.audioDate)),
                  ]),
                  _vm._v("\n        · \n        "),
                  _c("span", { staticClass: "mr1" }, [
                    _vm._v(_vm._s(_vm.audioDuration)),
                  ]),
                  (_vm.showYoutubeLink || _vm.showProgressBar) &&
                  _vm.currentAudioDetails.publishToYoutube
                    ? _c("div", [
                        _vm._v("\n          · \n          "),
                        _c("span", { staticClass: "mr1 ttc" }, [
                          _vm._v(_vm._s(_vm.audioIsPublic)),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.isScheduled
                    ? _c("div", [
                        _vm._v("\n          · \n          "),
                        _c("span", { staticClass: "mr1 ttc" }, [
                          _vm._v(_vm._s(_vm.scheduledFor)),
                        ]),
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                        },
                      },
                    },
                    [
                      _vm.showYoutubeLink
                        ? _c("BaseButtonRed", {
                            staticClass: "youtube-link ml1",
                            attrs: {
                              text: _vm.youtubeText,
                              icon: "open_in_new",
                              loading: _vm.youtubeProcessing,
                              onClick: _vm.youtubeLink,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.showYoutubeLink
                    ? _c(
                        "div",
                        { staticClass: "ml2" },
                        [
                          _c("BaseButtonRed", {
                            staticClass: "youtube-link ml1",
                            attrs: {
                              alt: "Share",
                              text: "Share",
                              icon: "share",
                              onClick: _vm.openShare,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.showProgressBar
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "flex flex-column mt1 ml1 progress-width",
                        },
                        [
                          _c("ProgressBar", {
                            attrs: {
                              options: _vm.options,
                              value: _vm.progressBarvalue,
                              max: 100,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _vm.showPopupAction
                  ? _c(
                      "div",
                      { staticClass: "flex justify-end items-center" },
                      [
                        _vm.audioTrackMenu.length
                          ? _c("BasePopupMenu", {
                              attrs: {
                                icon: "more_vert",
                                items: _vm.audioTrackMenu,
                              },
                            })
                          : _vm._e(),
                        _vm.newLoader
                          ? _c("div", { staticClass: "loader loader-inline" })
                          : _vm._e(),
                        _c("a", {
                          ref: "videoDownloadRef",
                          staticClass: "dn",
                          attrs: { download: "" },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.showDeleteButton || _vm.isScheduled
                  ? _c(
                      "div",
                      {
                        staticClass: "flex justify-end items-center pointer",
                        attrs: { title: "delete" },
                        on: { click: _vm.removeVideo },
                      },
                      [
                        _c(
                          "i",
                          { staticClass: "material-icons gray f4 v-mid pa1" },
                          [_vm._v("delete")]
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
            _vm.shareAudio
              ? _c("YoutubeShare", {
                  attrs: { youtubeURL: _vm.youtubeLinkShare, audio: _vm.audio },
                  on: { close: _vm.closeShare },
                })
              : _vm._e(),
          ],
          1
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }