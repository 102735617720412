var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fixed bottom-0 right-0 mr4 mb4 z-99" },
    _vm._l(_vm.notifications, function (notification) {
      return _c(
        "div",
        {
          key: notification.id,
          staticClass:
            "flex justify-between items-start w6 lh-copy ph3 pv3 bn br2 mb2 z-max",
          class: _vm.notificationTypeClass(notification.type),
        },
        [
          _c("div", { staticClass: "flex items-start" }, [
            _c("i", { staticClass: "material-icons mr2" }, [
              _vm._v(_vm._s(_vm.notificationTypeIcon(notification.type))),
            ]),
            _c("span", { domProps: { innerHTML: _vm._s(notification.text) } }),
          ]),
          notification.type === "ERROR" || notification.type === "WARNING"
            ? _c(
                "div",
                {
                  staticClass: "flex items-center link pointer dim",
                  on: {
                    click: function () {
                      return _vm.handleCloseNotification(notification.id)
                    },
                  },
                },
                [
                  _c("i", { staticClass: "material-icons ml2" }, [
                    _vm._v("close"),
                  ]),
                ]
              )
            : _vm._e(),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }