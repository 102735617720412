import Vue from 'vue'
import { StoreOptions, GetterTree, MutationTree, ActionTree } from 'vuex'
import AdoriService from '@/services/adori'
import AdoriServiceV6 from '@/services/adori_v6'

const state: any = {
  tagIds: [],
  recentTagId: null,
  iabCategories: [],
  iabTier2Categories: [],
  iabDaast1Categories: [],
  iabDaast2Categories: [],
  audioTracksForTag: [],
  tagsById: {},
  allTagsById: {},
  selectedTags: [],
  selectedAudiograms: [],
  audiogramInAudioTrack: {},
  tagIdsCount: -1,
  tagIdsOffset: -1,
  tagSearchValue: null,
  isTagSearching: false,
  tagSearchingCount: 0,
  tagsLoading: false,
  tagEffects: {},
}

const initialState: any = {
  ...JSON.parse(JSON.stringify(state)),
}

const getters: GetterTree<any, any> = {
  tagIds: (state) => state.tagIds,
  audioTracksForTag: (state) => state.audioTracksForTag,
  iabCategories: (state) => state.iabCategories,
  iabTier2Categories: (state) => state.iabTier2Categories,
  iabDaast1Categories: (state) => state.iabDaast1Categories,
  iabDaast2Categories: (state) => state.iabDaast2Categories,
  tagIdsCount: (state) => state.tagIdsCount,
  tagIdsOffset: (state) => state.tagIdsOffset,
  tagIdsLazyLoader: (state) => ({
    data: state.tagIds,
    count: state.tagIdsCount,
    offset: state.tagIdsOffset,
  }),
  tagsById: (state) => state.tagsById,
  allTagsById: (state) => state.allTagsById,
  tag: (state) => (tagId: string) => state.tagsById[tagId],
  selectedTags: (state) => state.selectedTags,
  selectedAudiograms: (state) => state.selectedAudiograms,
  audiogramInAudioTrack: (state) => state.audiogramInAudioTrack,
  isTagSelected: (state) => (tagId: string) => state.selectedTags.indexOf(tagId) !== -1,
  isAudiogramSelected: (state) => (audiogramId: string) => state.selectedAudiograms.indexOf(audiogramId) !== -1,
  tagSearchValue: (state) => state.tagSearchValue,
  isTagSearchingg: (state) => state.isTagSearching,
  tagsLoading: (state) => state.tagsLoading,
  recentTagId: (state) => state.recentTagId,
  tagEffects: (state) => state.tagEffects,
}

const mutations: MutationTree<any> = {
  resetState(state) {
    Object.keys(state).forEach((key: any) => {
      Vue.set(state, key, initialState[key])
    })
  },

  setIabTier1Categories(state, categories) {
    Vue.set(state, 'iabCategories', categories)
  },

  setIabTier2Categories(state, categories: { children: [{ uniqueId: number; name: string }] }) {
    Vue.set(state, 'iabTier2Categories', categories.children)
  },

  setIabTier1DaastCategories(state, categories) {
    Vue.set(state, 'iabDaast1Categories', categories)
  },

  setIabTier2DaastCategories(state, categories: { children: [{ uniqueId: number; name: string }] }) {
    Vue.set(state, 'iabDaast2Categories', categories.children)
  },

  setTagIds(state, tagIds) {
    Vue.set(state, 'tagIds', [...new Set([...state.tagIds, ...tagIds])])
  },

  setTagsLoading(state, value) {
    Vue.set(state, 'tagsLoading', value)
  },

  setTagIdsCount(state, count) {
    Vue.set(state, 'tagIdsCount', count)
  },

  setTagIdsOffset(state, offset) {
    Vue.set(state, 'tagIdsOffset', offset)
  },

  addTagId(state, tagId: string) {
    Vue.set(state, 'tagIds', [tagId, ...state.tagIds])
  },
  recentTagId(state, tagId: string) {
    Vue.set(state, 'recentTagId', tagId)
  },

  deleteTagId(state, tagId: string) {
    Vue.delete(state.tagIds, state.tagIds.indexOf(tagId))
  },

  addTag(state, tag) {
    Vue.set(state.tagsById, tag.id, standardizeTagSchema(tag))
    Vue.set(state.allTagsById, tag.id, standardizeTagSchema(tag))
  },

  setAudioTracksForTag(state, audioTracks) {
    Vue.set(state, 'audioTracksForTag', audioTracks)
  },

  deleteTag(state, tagId) {
    Vue.delete(state.tagsById, tagId)
  },

  selectTag(state, tagId) {
    const selectedTags = state.selectedTags
    Vue.set(state, 'selectedTags', [...selectedTags, tagId])
  },

  unselectTag(state, tagId) {
    Vue.delete(state.selectedTags, state.selectedTags.indexOf(tagId))
  },

  selectAudiogram(state, audiogramId) {
    Vue.set(state, 'selectedAudiograms', [audiogramId])
  },

  unselectAudiogram(state) {
    Vue.set(state, 'selectedAudiograms', [])
  },

  unselectAllTags(state) {
    Vue.set(state, 'selectedTags', [])
  },

  setAudiogramInAudioTrack(state, audiogram) {
    Vue.set(state, 'audiogramInAudioTrack', audiogram)
  },

  unsetAudiogramInAudioTrack(state) {
    Vue.set(state, 'audiogramInAudioTrack', {})
  },

  setTagSearchValue(state, tagSearchValue) {
    Vue.set(state, 'tagSearchValue', tagSearchValue)
  },

  clearTags(state) {
    Vue.set(state, 'tagIds', [])
    Vue.set(state, 'tagsById', {})
    Vue.set(state, 'selectedTags', [])
    Vue.set(state, 'tagIdsCount', -1)
    Vue.set(state, 'tagIdsOffset', -1)
    // Vue.set(state, 'tagSearchingCount', 0)
    // Vue.set(state, 'isTagSearching', false)
  },

  clearSearch(state) {
    Vue.set(state, 'tagSearchValue', null)
    Vue.set(state, 'isTagSearching', false)
    Vue.set(state, 'tagSearchingCount', 0)
  },

  setIsTagSearching(state, isTagSearching) {
    Vue.set(state, 'isTagSearching', isTagSearching)
  },

  setTagEffects(state, { id, effects }) {
    Vue.set(state.tagEffects, id, effects)
  },
}

const actions: ActionTree<any, any> = {
  resetTagsState(context) {
    context.commit('resetState')
  },
  async getTier1IABCategories(context) {
    const categories = await AdoriServiceV6.fetchIABCategories()
    context.commit('setIabTier1Categories', categories.data)
  },

  async getTier2IABCategories(context, categoryId?: number) {
    const categories = await AdoriServiceV6.fetchIABCategories(categoryId)
    context.commit('setIabTier2Categories', categories)
  },

  async getDaast1IABCategories(context) {
    const categories = await AdoriServiceV6.fetchDAASTCategories()
    context.commit('setIabTier1DaastCategories', categories.data)
  },

  async getDaast2IABCategories(context, categoryId?: string) {
    const categories = await AdoriServiceV6.fetchDAASTCategories(categoryId)
    context.commit('setIabTier2DaastCategories', categories)
  },

  async getTagIds(
    context,
    { paginate = true, limit = 50, offset = 0, query = null, clear = true, orientation = 'LANDSCAPE' }
  ) {
    const networkId = context.getters.networkId
    let tagIds: any = []
    // context.commit('setIsTagSearching', true)
    if (clear) {
      context.commit('clearTags')
    }
    if (query) {
      tagIds = await AdoriService.fetchTagIdsSearch(networkId, {
        params: {
          paginate,
          limit,
          offset,
          query,
          orientation,
        },
      })
    } else {
      tagIds = await AdoriService.fetchTagIds(networkId, {
        params: {
          paginate,
          limit,
          offset,
          orientation,
        },
      })
    }
    // if (paginate) {
    tagIds.data.forEach((tag: any) => {
      context.commit('addTag', tag)
    })

    context.commit('setTagIdsCount', tagIds.count)
    context.commit('setTagIdsOffset', tagIds.offset)
    context.commit(
      'setTagIds',
      tagIds.data.map((tag: any) => tag.id)
    )
    // }
    // else {
    //   context.commit('setTagIdsCount', tagIds.length)
    //   context.commit('setTagIdsOffset', tagIds.length)
    //   context.commit('setTagIds', tagIds)
    // }
    if (query) {
      context.commit('setIsTagSearching', false)
    }
  },

  setTagsLoading(context, value: boolean) {
    context.commit('setTagsLoading', value)
  },

  async addTagId(context, tagId: string) {
    context.commit('addTagId', tagId)
  },

  clearTagSearch(context) {
    context.commit('clearSearch')
  },

  async deleteTagId(context, tagId: string) {
    context.commit('deleteTagId', tagId)
  },

  async getTag(context, tagId: string) {
    const networkId = context.getters.networkId
    const tag = await AdoriService.fetchTag(networkId, tagId)
    context.commit('addTag', tag)
  },

  async getTags(context, tagIds: string[]) {
    const networkId = context.getters.networkId
    const allPromises = tagIds.map(async (tagId) => {
      const tag = await AdoriService.fetchTag(networkId, tagId)
      context.commit('addTag', tag)
    })
    await Promise.all(allPromises)
  },

  async getAudioTracksForTag(context, { tagId }) {
    const networkId = context.getters.networkId
    const audioTracks = await AdoriService.fetchAudioTracksForTag(networkId, tagId)
    context.commit('setAudioTracksForTag', audioTracks)
  },

  async addTags(context, tags: object[]) {
    tags.forEach((tag: any) => {
      context.commit('addTag', tag)
      context.commit('addTagId', tag.id)
    })
  },
  async addTrackTags(context, tags: object[]) {
    tags.forEach((tag: any) => {
      context.commit('addTag', tag.tag)
    })
  },

  async deleteTag(context, tagId: string) {
    const networkId = context.getters.networkId
    await AdoriService.deleteTag(networkId, tagId)
    context.commit('deleteTag', tagId)
    context.dispatch('deleteTagId', tagId)
    context.dispatch('deleteTagFromTagCollections', tagId)
  },

  async toggleSelectTag(context, tagId) {
    const selectedTags = context.getters.selectedTags
    if (selectedTags.indexOf(tagId) === -1) {
      context.commit('selectTag', tagId)
    } else {
      context.commit('unselectTag', tagId)
    }
  },

  async toggleSelectAudiogram(context, { audiogramId, audiogram }) {
    const selectedAudiograms = context.getters.selectedAudiograms
    if (selectedAudiograms.indexOf(audiogramId) === -1) {
      context.commit('selectAudiogram', audiogramId)
      context.commit('setAudiogramInAudioTrack', audiogram)
    } else {
      context.commit('unsetAudiogramInAudioTrack')
    }
    return selectedAudiograms.indexOf(audiogramId) === -1
  },

  async initialSelectedAudiogram(context, audiogramId) {
    const networkId = context.getters.networkId
    context.commit('selectAudiogram', audiogramId)
    const audiogram = await AdoriServiceV6.get_one_audiograms(networkId, audiogramId)
    context.commit('setAudiogramInAudioTrack', audiogram)
  },

  async unselectAllTags(context) {
    context.commit('unselectAllTags')
  },

  async setTagSearchValue(context, tagSearchValue) {
    context.commit('setTagSearchValue', tagSearchValue)
  },

  setIsTagSearching(context, isTagSearching) {
    context.commit('setIsTagSearching', isTagSearching)
  },

  async createTagEffect(context, payload) {
    const networkId = context.getters.networkId
    const res = await AdoriServiceV6.createTagEffect(networkId, payload)
    return res
  },
  async getTagEffect(context, effectId) {
    const networkId = context.getters.networkId
    const res: any = await AdoriServiceV6.getTagEffect(networkId, effectId)
    context.commit('setTagEffects', { id: res.id, effects: res })
  },
  async updateTagEffect(context, payload) {
    const networkId = context.getters.networkId
    const res: any = await AdoriServiceV6.updateTagEffect(networkId, payload.effectId, payload.data)
    context.commit('setTagEffects', { id: res.id, effects: res })
    return res
  },
}

const tags: StoreOptions<any> = {
  state,
  getters,
  mutations,
  actions,
}

export default tags

// Private helpers

function standardizeTagSchema(tag: any) {
  // All tag styles are under tag.style object
  delete tag.fontStyle
  delete tag.imageOpacity
  delete tag.topMarginPercentage

  // Image
  if (tag.imageInfo) {
    tag.image = tag.imageInfo
    delete tag.imageInfo
  }

  // Location
  if (tag.locationInfo) {
    tag.location = tag.locationInfo
    delete tag.locationInfo
  }

  // Poll
  if (tag.choices) {
    tag.poll = tag.choices
    delete tag.choices
  }

  return tag
}
