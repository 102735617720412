let audioBufferList
// let lamejs = require('lamejs')

let AudioContext =
  window.AudioContext || // Default
  window.webkitAudioContext || // Safari and old versions of Chrome
  false

if (AudioContext) {
  // Do whatever you want using the Web Audio API
  var context = new AudioContext({
    sampleRate: 8000, //set sample rate to bare minimum for performance
  })
  // ...
} else {
  // Web Audio API is not supported
  // Alert the user
  console.error(
    'Sorry, but the Web Audio API is not supported by your browser. Please, consider upgrading to the latest version or downloading Google Chrome or Mozilla Firefox'
  )
}

function BufferLoader(context, tracks, loadCallback, stitchCallback) {
  this.context = context
  this.onload = loadCallback
  this.stitchCallback = stitchCallback
  this.bufferList = []
  this.tracks = tracks
  this.loadCount = 0

  this.load()
}

BufferLoader.prototype.loadBuffer = function (track, index) {
  var loader = this

  if (track.type === 'PROMO' || track.type === 'MAIN') {
    // Load buffer asynchronously
    var request = new XMLHttpRequest()
    request.open('GET', track.url, true)
    request.responseType = 'arraybuffer'

    request.onload = function () {
      // Asynchronously decode the audio file data in request.response
      loader.context.decodeAudioData(
        request.response,
        function (buffer) {
          if (!buffer) {
            this.$store.dispatch('pushNotification', { text: `Error decoding file data`, type: 'ERROR' })
            return
          }
          loader.bufferList.push({
            id: track.id,
            type: track.type,
            buffer: buffer,
            start: track.start,
            label: track.label,
          })
          if (++loader.loadCount === loader.tracks.length) {
            audioBufferList = loader.bufferList
            loader.onload(loader.bufferList, loader.stitchCallback)
          }
        },
        function (error) {
          console.error('decodeAudioData error', error)
        }
      )
    }

    request.onerror = function () {
      this.$store.dispatch('pushNotification', { text: `Error loading audio.`, type: 'ERROR' })
    }

    request.send()
  } else if (track.type === 'AD_SLOT') {
    // Create a mono blank buffer
    let tempBuffer = context.createBuffer(1, track.duration * context.sampleRate, context.sampleRate)
    loader.bufferList.push({
      id: track.id,
      type: track.type,
      buffer: tempBuffer,
      start: track.start,
      label: track.label,
    })
    if (++loader.loadCount === loader.tracks.length) {
      loader.onload(loader.bufferList, loader.stitchCallback)
    }
  }
}

BufferLoader.prototype.load = function () {
  for (var i = 0; i < this.tracks.length; ++i) {
    this.loadBuffer(this.tracks[i], i)
  }
}

// / ** Function not being used ** /
// function wavToMp3 (channels, sampleRate, samples) {
//   var buffer = []
//   var mp3enc = new lamejs.Mp3Encoder(channels, sampleRate, 64)
//   var remaining = samples.length
//   var samplesPerFrame = 1152
//   for (var i = 0; remaining >= samplesPerFrame; i += samplesPerFrame) {
//     var mono = samples.subarray(i, i + samplesPerFrame)
//     var mp3buf = mp3enc.encodeBuffer(mono)
//     if (mp3buf.length > 0) {
//       buffer.push(new Int8Array(mp3buf))
//     }
//     remaining -= samplesPerFrame
//   }
//   var d = mp3enc.flush()
//   if (d.length > 0) {
//     buffer.push(new Int8Array(d))
//   }

//   return buffer
// }

// Convert an AudioBuffer to a Blob using WAVE representation
function bufferToWave(abuffer, len) {
  // eslint-disable-next-line one-var
  let numOfChan = abuffer.numberOfChannels,
    length = len * numOfChan * 2 + 44,
    buffer = new ArrayBuffer(length),
    view = new DataView(buffer),
    channels = [],
    i,
    sample,
    offset = 0,
    pos = 0

  // write WAVE header
  setUint32(0x46464952) // "RIFF"
  setUint32(length - 8) // file length - 8
  setUint32(0x45564157) // "WAVE"

  setUint32(0x20746d66) // "fmt " chunk
  setUint32(16) // length = 16
  setUint16(1) // PCM (uncompressed)
  setUint16(numOfChan)
  setUint32(abuffer.sampleRate)
  setUint32(abuffer.sampleRate * 2 * numOfChan) // avg. bytes/sec
  setUint16(numOfChan * 2) // block-align
  setUint16(16) // 16-bit (hardcoded in this demo)

  setUint32(0x61746164) // "data" - chunk
  setUint32(length - pos - 4) // chunk length

  // write interleaved data
  for (i = 0; i < abuffer.numberOfChannels; i++) {
    channels.push(abuffer.getChannelData(i))
  }

  while (pos < length) {
    for (i = 0; i < numOfChan; i++) {
      // interleave channels
      sample = Math.max(-1, Math.min(1, channels[i][offset])) // clamp
      sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767) | 0 // scale to 16-bit signed int
      view.setInt16(pos, sample, true) // write 16-bit sample
      pos += 2
    }
    offset++ // next source sample
  }

  // let wavHdr = lamejs.WavHeader.readHeader(new DataView(buffer));
  // let wavSamples = new Int16Array(buffer, wavHdr.dataOffset, wavHdr.dataLen / 2);
  // let b = wavToMp3(wavHdr.channels, wavHdr.sampleRate, wavSamples);
  // return new Blob(b, { type: "audio/mp3" });

  return new Blob([buffer], { type: 'audio/wav' })

  function setUint16(data) {
    view.setUint16(pos, data, true)
    pos += 2
  }

  function setUint32(data) {
    view.setUint32(pos, data, true)
    pos += 4
  }
}

function processFinalAudio(bufferList, callback) {
  if (bufferList == null || bufferList.length === 0) {
    callback(null, null)
  }

  var destAudioBuffer = null // final lbuffer
  var destAudioLength = 0 // in byte
  var sourceMarker = 0 // in secs
  var destMarker = 0
  var adPositions = [] // default to MAIN track
  var slicedAudioBuffers = [] // array of sliced audios to stitch
  var tempBuffer = 0
  var numChannels = 1 // bufferList[0].numberOfChannels; HARD CODE TO 1 FOR PERFORMANCE
  var playlist = [] // list of sliced audio
  var adList = [] // list of ads
  var mainTrack = null // THE main track
  var mainTrackId = 0

  // sort the audio clips based on their position on timeline
  bufferList = bufferList.sort(function (a, b) {
    return -(b.start - a.start) // ascending
  })

  // Separate the main and ad traks
  for (var i = 0; i < bufferList.length; i++) {
    if (bufferList[i].type == 'MAIN') {
      mainTrack = bufferList[i]
      mainTrackId = bufferList[i].id
      adPositions.push({
        id: bufferList[i].id,
        type: 'MAIN',
        abs_start: 0,
        rel_start: 0,
        duration: bufferList[i].buffer.duration,
        label: 'main',
      })
    } else adList.push(bufferList[i])

    destAudioLength += bufferList[i].buffer.length
  }

  // prepare playlist of audio slices
  destMarker = mainTrack.start
  for (let i = 0; i <= adList.length; i++) {
    if (i === adList.length) {
      // check for main track tail
      if (mainTrack.buffer.duration - destMarker > 0.01) {
        playlist.push([mainTrackId, destMarker, mainTrack.buffer.duration - destMarker])
      }
      break
    }

    // Get the main track slice between ads
    if (adList[i].start - destMarker > 0.01) {
      // id, start, duration
      playlist.push([mainTrackId, destMarker, adList[i].start - destMarker])
    }
    playlist.push([adList[i].id, adList[i].start])
    destMarker = adList[i].start
  }

  destAudioBuffer = context.createBuffer(numChannels, destAudioLength, bufferList[0].buffer.sampleRate)

  // Based on playlist, prepare real audio track
  destMarker = 0
  for (let slice = 0; slice < playlist.length; slice++) {
    if (playlist[slice][0] === mainTrackId) {
      // main track
      tempBuffer = context.createBuffer(
        numChannels,
        (mainTrack.buffer.length * playlist[slice][2]) / mainTrack.buffer.duration,
        mainTrack.buffer.sampleRate
      )

      for (var j = 0; j < numChannels; j++) {
        tempBuffer.copyToChannel(
          mainTrack.buffer
            .getChannelData(0)
            .slice(
              (mainTrack.buffer.length * playlist[slice][1]) / mainTrack.buffer.duration,
              (mainTrack.buffer.length * (playlist[slice][1] + playlist[slice][2])) / mainTrack.buffer.duration
            ),
          j,
          sourceMarker
        )
      }
      slicedAudioBuffers.push(tempBuffer)
      sourceMarker += playlist[slice][2]
      destMarker += playlist[slice][2]
    } else {
      var buf = null
      adList.find((o, i) => {
        if (o.id === playlist[slice][0]) {
          buf = adList[i]
        }
      })

      tempBuffer = context.createBuffer(numChannels, buf.buffer.length, buf.buffer.sampleRate)

      for (j = 0; j < numChannels; j++) {
        tempBuffer.copyToChannel(buf.buffer.getChannelData(0), j, 0)
      }

      slicedAudioBuffers.push(tempBuffer)

      adPositions.push({
        id: buf.id,
        type: buf.type,
        abs_start: destMarker,
        rel_start: buf.start,
        duration: buf.buffer.duration,
        label: buf.label,
      })
      destMarker += buf.buffer.duration
    }
  }

  // Prepare the finalAudioBuffer from slicedAudioBuffers
  destAudioBuffer = context.createBuffer(numChannels, destAudioLength, mainTrack.buffer.sampleRate)

  destMarker = 0
  slicedAudioBuffers.forEach((element) => {
    for (var i = 0; i < numChannels; i++) {
      destAudioBuffer.getChannelData(i).set(element.getChannelData(i), destMarker)
    }
    destMarker += element.length
  })

  // convert audio to wave and send back blob url
  var response = {
    url: window.URL.createObjectURL(bufferToWave(destAudioBuffer, destAudioBuffer.length)),
    adPositions: adPositions,
  }

  callback(response, null)
}

function initAudioPreview(tracks, callback) {
  const AudioContextInner =
    window.AudioContext || // Default
    window.webkitAudioContext || // Safari and old versions of Chrome
    false

  if (AudioContextInner) {
    let loadTracks = function () {
      return new Promise((resolve, reject) => {
        new BufferLoader(context, tracks, processFinalAudio, (url, err) => {
          if (err) reject(err)
          else resolve(url)
        })
      })
    }
    loadTracks()
      .then((url) => {
        callback(url, null)
      })
      .catch((err) => {
        callback(null, err)
      })
    // ...
  } else {
    // Web Audio API is not supported
    // Alert the user
    console.error(
      'Sorry, but the Web Audio API is not supported by your browser. Please, consider upgrading to the latest version or downloading Google Chrome or Mozilla Firefox'
    )
  }
}

export { processFinalAudio, initAudioPreview, audioBufferList }
