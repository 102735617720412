var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.info
    ? _c("span", { staticClass: "relative" }, [
        _c(
          "i",
          {
            directives: [
              {
                name: "on-clickaway",
                rawName: "v-on-clickaway",
                value: _vm.closeInfo,
                expression: "closeInfo",
              },
            ],
            staticClass: "material-icons v-mid f4 pointer mb1",
            on: { click: _vm.toggleInfo },
          },
          [_vm._v("info")]
        ),
        _c(
          "span",
          {
            staticClass:
              "tooltip absolute top-2 z-1 ba br2 b--adori-near-black pa2 shadow-2 bg-adori-medium-gray silver br2",
            class: _vm.showInfo ? "db" : "dn",
          },
          [
            _c(
              "p",
              { staticClass: "tooltip-text lh-copy light-gray mv0 mh2 f6" },
              [_vm._v(_vm._s(_vm.info))]
            ),
            _c("span", {
              staticClass:
                "absolute top-0 left-50 nt2 w1 h1 bg-adori-black bl bt b--adori-near-black rotate-45 bg-adori-medium-gray",
            }),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }