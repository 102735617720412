import Vue from 'vue'
import { StoreOptions, GetterTree, MutationTree, ActionTree } from 'vuex'
import get from 'lodash.get'
import AdoriServiceV6 from '@/services/adori_v6'

const state: any = {
  subtitleTemplates: [],
  subtitleCustomStyles: [],
  subtitleStyleIds: [],
  subtitleStyleById: {},
  subtitlesList: [],
  selectedTemplate: null,
  hasUnsavedChanges: false,
}

const getters: GetterTree<any, any> = {
  subtitleTemplates: (state) => state.subtitleTemplates,
  subtitleCustomStyles: (state) => state.subtitleCustomStyles,
  subtitleStyleIds: (state) => state.subtitleStyleIds,
  subtitleStyleById: (state) => state.subtitleStyleById,
  subtitlesList: (state) => state.subtitlesList,
  selectedTemplate: (state) => state.selectedTemplate,
  hasUnsavedChanges: (state) => state.hasUnsavedChanges,
}

const mutations: MutationTree<any> = {
  setUnsavedChanges(state, value) {
    Vue.set(state, 'hasUnsavedChanges', value)
  },
  setSubtitleTemplates(state, templates) {
    Vue.set(state, 'subtitleTemplates', templates)
  },

  clearSubtitleStyles(state) {
    Vue.set(state, 'subtitleCustomStyles', [])
    Vue.set(state, 'subtitleStyleById', {})
    Vue.set(state, 'subtitleStyleIds', [])
  },

  setSubtitleCustomStyles(state, styles) {
    let data: any = []
    if (get(state.subtitleCustomStyles, 'data', []).length === 0 || styles.offset === 0) {
      //@ts-ignore
      data = new Set([...styles.data])
    } else data = new Set([...state.subtitleCustomStyles.data, ...styles.data])
    data = Array.from(data)

    Vue.set(state, 'subtitleCustomStyles', {
      data,
      offset: styles.offset,
      count: styles.count,
    })
    const subtitleStyleIds = data.map((style: any) => style.id)

    Vue.set(state, 'subtitleStyleIds', subtitleStyleIds)

    const subtitleStyleById: any = data.reduce((acc: any, obj: any) => {
      acc[obj.id] = obj
      return acc
    }, {})

    Vue.set(state, 'subtitleStyleById', {
      ...state.subtitleStyleById,
      ...subtitleStyleById,
    })
  },
  setSelectedTemplate(state, template) {
    Vue.set(state, 'selectedTemplate', template)
  },

  deleteSubtitleCustomStyles(state, styleId) {
    Vue.delete(state.subtitleStyleById, styleId)
  },

  updateSubtitle(state, data) {
    Vue.set(state, 'subtitlesList', data)
  },

  updateAudioTrackSubtitle(state, data) {
    Vue.set(state, 'subtitlesList', data.textBlocks)
  },
}

const actions: ActionTree<any, any> = {
  async getSubtitleTemplates(context) {
    const networkId = context.getters.networkId
    const templates: any = await AdoriServiceV6.fetchSubtitleTemplates(networkId)
    context.commit('setSubtitleTemplates', templates)
    return templates
  },

  async getSubtitleCustomStyles(context, { limit = 10, offset = 0, clear = true }) {
    if (clear) {
      context.commit('clearSubtitleStyles')
    }
    const networkId = context.getters.networkId
    let params: { [key: string]: any } = {
      limit,
      offset,
    }
    const res: any = await AdoriServiceV6.fetchSubtitleCustomTemplates(networkId, {
      params,
    })

    const styles: any = {
      data: res.data,
      count: res.count,
      offset: res.offset,
    }

    context.commit('setSubtitleCustomStyles', styles)
    return styles
  },

  async saveCustomTemplate(context, payload) {
    const data: any = await AdoriServiceV6.createSubtitleCustomTemplate(payload)
    return data
  },

  async deleteCustomTemplate(context, styleId: string) {
    const networkId = context.getters.networkId
    await AdoriServiceV6.deleteSubtitleCustomTemplate(networkId, styleId)
    context.commit('deleteSubtitleCustomStyles', styleId)
  },

  async getAudioTrackSubtitle(context, { trackUid, status }) {
    const networkId = context.getters.networkId
    const data: any = await AdoriServiceV6.fetchAudioTrackSubtitle(networkId, trackUid)
    if (!status) {
      context.commit('updateSubtitle', data.textBlocks)
      context.dispatch('filterSelectTemplate', data)
    }
    return data
  },

  async saveAudioTrackSubtitle(context, payload) {
    const data: any = await AdoriServiceV6.uploadAudioTrackSubtitle(payload)
    context.dispatch('filterSelectTemplate', data)
    return data
  },

  filterSelectTemplate(context, data) {
    const selectedTemplate = data.subtitleStyleId
      ? context.getters.subtitleCustomStyles.data.filter((obj: any) => obj.id === data.subtitleStyleId)[0]
      : context.getters.subtitleTemplates.filter((obj: any) => obj.enum === data.template)[0]
    context.commit('setSelectedTemplate', selectedTemplate)
  },

  async generateSubtitles(context, { trackUid }) {
    const networkId = context.getters.networkId
    await AdoriServiceV6.generateTrackSubtitles(networkId, trackUid)
  },

  async downloadSubtitles(context, payload) {
    const networkId = context.getters.networkId
    const data: any = await AdoriServiceV6.downloadSubtitles(networkId, payload)
    return data
  },
}

const subtitle: StoreOptions<any> = {
  state,
  getters,
  mutations,
  actions,
}

export default subtitle
