var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "light-gray fw5 f3" }, [
          _vm._v("Detailed view of the Tag"),
        ]),
      ]),
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "flex w-100 min-w7" }, [
          _c("div", { staticClass: "w-30" }, [
            _c(
              "div",
              { staticClass: "relative", staticStyle: { width: "253px" } },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/iphone-x-placeholder.svg"),
                    draggable: "false",
                  },
                }),
                _c(
                  "div",
                  [
                    _vm.hasVideoOrImage
                      ? _c("BaseVideoPlayer", {
                          staticClass: "absolute top-screen left-screen",
                          attrs: { bigger: "", src: _vm.videoUrl },
                        })
                      : _c("BaseAdoriTagImage", {
                          ref: "adoriImage",
                          staticClass: "absolute top-screen left-screen",
                          attrs: { src: _vm.tagImage, bigger: "" },
                        }),
                  ],
                  1
                ),
              ]
            ),
            _c("br"),
          ]),
          _c("div", { staticClass: "w-60 ml2" }, [
            _c("div", { staticClass: "flex items-end mr4 mb3" }, [
              _c(
                "a",
                {
                  staticClass:
                    "fw3 f4 dib link glow pt3 pb1 pointer no-outline w-40 mr4 bold",
                  class:
                    _vm.selectedType === "SHOW" ? "red bb b--red bw1" : "white",
                  on: {
                    click: function ($event) {
                      _vm.selectedType = "SHOW"
                    },
                  },
                },
                [_vm._v("Shows")]
              ),
              _c(
                "a",
                {
                  staticClass:
                    "fw3 f4 dib link glow pt3 pb1 pointer no-outline w-40 bold",
                  class:
                    _vm.selectedType === "EPISODE"
                      ? "red bb b--red bw1"
                      : "white",
                  on: {
                    click: function ($event) {
                      _vm.selectedType = "EPISODE"
                    },
                  },
                },
                [_vm._v("Episodes")]
              ),
            ]),
            _vm.selectedType === "SHOW"
              ? _c("div", [
                  _vm.loadingShows
                    ? _c(
                        "div",
                        { staticClass: "white flex flex-column items-center" },
                        [
                          _c("div", { staticClass: "mr2" }, [
                            _vm._v("Loading shows..."),
                          ]),
                          _c("div", {
                            staticClass: "loader loader-inline dib",
                          }),
                        ]
                      )
                    : _c("div", [
                        _vm.rssFeedsBasedOnUids &&
                        _vm.rssFeedsBasedOnUids.length !== 0
                          ? _c(
                              "div",
                              _vm._l(_vm.rssFeedsBasedOnUids, function (show) {
                                return _c(
                                  "div",
                                  {
                                    key: show.id,
                                    staticClass: "bottom-border",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex ph2 pv3 br2 white mb1 pointer",
                                        on: {
                                          click: function ($event) {
                                            return _vm.toggle(show)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "flex items-center mr2",
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "mr4 img",
                                              attrs: { src: show.imageUrl },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "flex flex-column item-center w-100 white showName",
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(show.name) +
                                                "\n                  "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "right flex flex-column",
                                          },
                                          [
                                            _c(
                                              "i",
                                              {
                                                staticClass:
                                                  "material-icons white",
                                              },
                                              [_vm._v(" keyboard_arrow_down ")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._l(
                                      _vm.episodesForShow(show.uid),
                                      function (epi) {
                                        return _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: show.showDetails,
                                                expression: "show.showDetails",
                                              },
                                            ],
                                            key: epi.id,
                                            staticClass:
                                              "flex ml4 ph2 pv3 collapsible",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "flex items-center",
                                              },
                                              [
                                                _c("img", {
                                                  staticClass: "mr4 img",
                                                  attrs: { src: epi.imageUrl },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "flex flex-column item-center w-100 white showName f6",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(epi.name) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "right flex flex-column white f7",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.formatMillis(
                                                        _vm.audioTrackUIdData(
                                                          epi.uid
                                                        ).offsetMillis
                                                      )
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                )
                              }),
                              0
                            )
                          : _c(
                              "div",
                              {
                                staticClass:
                                  "white flex flex-column items-center",
                              },
                              [
                                _c("img", {
                                  staticClass: "grpImage mt5",
                                  attrs: {
                                    src: require("@/assets/Group121.svg"),
                                    alt: "group",
                                  },
                                }),
                                _c("div", { staticClass: "mt4" }, [
                                  _vm._v(
                                    "Oops.. This tag isn't used in any shows"
                                  ),
                                ]),
                              ]
                            ),
                      ]),
                ])
              : _vm._e(),
            _vm.selectedType === "EPISODE"
              ? _c("div", { staticClass: "flex flex-column items-center" }, [
                  _vm.loadingEpisodes
                    ? _c("div", [
                        _c("div", { staticClass: "mr3 white" }, [
                          _vm._v("Loading..."),
                        ]),
                        _c("div", { staticClass: "loader loader-inline" }),
                      ])
                    : !_vm.loadingEpisodes && _vm.audioUidsByTag.length === 0
                    ? _c(
                        "div",
                        { staticClass: "white flex flex-column items-center" },
                        [
                          _c("img", {
                            staticClass: "grpImage mt5",
                            attrs: {
                              src: require("@/assets/Group121.svg"),
                              alt: "group",
                            },
                          }),
                          _c("div", { staticClass: "mt4" }, [
                            _vm._v(
                              "Oops.. This tag isn't used in any episodes"
                            ),
                          ]),
                        ]
                      )
                    : _c(
                        "div",
                        { staticClass: "w-100" },
                        _vm._l(_vm.audioUidsByTag, function (audio) {
                          return _c(
                            "div",
                            {
                              key: audio.id,
                              staticClass:
                                "flex justify-between ph2 pv3 br2 white mb1 bottom-border pointer",
                            },
                            [
                              _c("div", { staticClass: "flex items-center" }, [
                                _c("img", {
                                  staticClass: "mr4 img",
                                  attrs: { src: audio.imageUrl },
                                }),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex flex-column item-center w-90 white showName",
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(audio.name) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "right flex flex-column f6" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.formatMillis(
                                          _vm.audioTrackUIdData(audio.uid)
                                            .offsetMillis
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }