







































































import { Vue, Component, Prop, Mixins, Watch } from 'vue-property-decorator'

@Component({})
export default class ImagesList extends Vue {
  @Prop({ default: 1 })
  selectedRangeStart!: number
  @Prop({ default: 10 })
  selectedRangeEnd!: number
  @Prop({ default: 0 })
  totalImages!: number
  @Prop({ default: [] })
  imageSources!: any
  @Prop({ default: '' })
  previewImageSrc!: string
  @Prop({ default: false })
  showCreateTagButton!: boolean

  @Prop(Boolean) isMaximized!: boolean

  goToUserProfile(profileUrl: string) {
    window.open(profileUrl, '_blank')
  }

  handleNavigation(nav: string) {
    this.$emit('handleNavigation', nav)
  }
}
