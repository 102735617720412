var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "f4 light-gray fw5" }, [
          _vm._v("Podcast Default Youtube Setting"),
        ]),
      ]),
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "flex w-100 white mw-900" }, [
          _c("div", { staticClass: "w-60 pr2" }, [
            _c("div", { staticClass: "flex flex-column" }, [
              _c("div", { staticClass: "title" }, [
                _c(
                  "fieldset",
                  {
                    staticClass: "br2",
                    class:
                      _vm.isTitleOverMax || _vm.$v.title.$error
                        ? "b--adori-red"
                        : "b--adori-light-gray",
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "f6",
                        class: _vm.$v.title.$error ? "adori-red" : "light-gray",
                      },
                      [
                        _vm._v(
                          "\n                Title (Publishes Episode Title from Feed)\n              "
                        ),
                      ]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.$v.title.$model,
                          expression: "$v.title.$model",
                        },
                      ],
                      staticClass:
                        "bg-adori-gray bn br2 gray f6 mb2 white w-100",
                      attrs: {
                        type: "text",
                        name: "title",
                        maxlength: "99",
                        placeholder: "Episode Title",
                      },
                      domProps: { value: _vm.$v.title.$model },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.$v.title, "$model", $event.target.value)
                        },
                      },
                    }),
                  ]
                ),
                _vm.$v.title.$error
                  ? _c("span", { staticClass: "red f7 ml1" }, [
                      _vm._v("Title cannot be empty"),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "mb3 flex justify-between items-center f6 mt2",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "place-holder-tags" },
                      [
                        _vm._v(
                          "\n                Add metadata:\n                "
                        ),
                        _vm._l(_vm.titleTags, function (tag, i) {
                          return _c(
                            "span",
                            {
                              key: i,
                              staticClass: "blue pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.addPlaceHolderTitle(tag.tag)
                                },
                              },
                            },
                            [_vm._v("\n                  " + _vm._s(tag.value))]
                          )
                        }),
                      ],
                      2
                    ),
                    _c("div", [
                      _c("span", { class: { overMax: _vm.isTitleOverMax } }, [
                        _vm._v(_vm._s(_vm.titleCharacterRemaining)),
                      ]),
                      _c("span", [_vm._v("/ 99")]),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "description" }, [
                _c(
                  "fieldset",
                  {
                    staticClass: "br2",
                    class: _vm.isDescriptionOverMax
                      ? "b--adori-red"
                      : "b--adori-light-gray",
                  },
                  [
                    _c("legend", { staticClass: "f6" }, [
                      _vm._v(
                        "Description (Publishes Episode Description from Feed)"
                      ),
                    ]),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.plainDescription,
                          expression: "plainDescription",
                        },
                      ],
                      staticClass:
                        "bg-adori-gray bn br2 gray f6 mb2 white w-100 scrollbar overflow-y-auto",
                      attrs: {
                        maxlength: "4999",
                        type: "text",
                        rows: "8",
                        name: "description",
                        placeholder: "Episode Description",
                      },
                      domProps: { value: _vm.plainDescription },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.plainDescription = $event.target.value
                          },
                          function ($event) {
                            return _vm.userSummaryInsert($event.target.value)
                          },
                        ],
                      },
                    }),
                  ]
                ),
                _c("div", { staticClass: "flex justify-between f6 mt2 mb2" }, [
                  _c(
                    "div",
                    { staticClass: "place-holder-tags" },
                    [
                      _vm._v(
                        "\n                Add metadata:\n                "
                      ),
                      _vm._l(_vm.descriptionTags, function (tag, i) {
                        return _c(
                          "span",
                          {
                            key: i,
                            staticClass: "blue pointer",
                            on: {
                              click: function ($event) {
                                return _vm.addPlaceHolderDescription(tag.tag)
                              },
                            },
                          },
                          [_vm._v("\n                  " + _vm._s(tag.value))]
                        )
                      }),
                    ],
                    2
                  ),
                  _c("div", [
                    _c(
                      "span",
                      { class: { overMax: _vm.isDescriptionOverMax } },
                      [_vm._v(_vm._s(_vm.descriptionCharacterRemaining))]
                    ),
                    _c("span", [_vm._v("/ 4999")]),
                  ]),
                ]),
              ]),
              _vm.networkId && !_vm.isAudioUploading && _vm.hasTags
                ? _c(
                    "div",
                    { staticClass: "flex mb1" },
                    [
                      _c("BaseCheckbox", {
                        staticClass: "mr2",
                        attrs: {
                          text: "Include visuals",
                          initialState: _vm.initialVisuals,
                        },
                        on: {
                          toggle: function ($event) {
                            _vm.includeVisuals = $event
                            _vm.summary()
                          },
                        },
                      }),
                      _vm.includeVisuals
                        ? _c("BaseCheckbox", {
                            attrs: {
                              text: "Add tags to chapters",
                              initialState: _vm.initialChapters,
                            },
                            on: {
                              toggle: function ($event) {
                                _vm.includeChapters = $event
                                _vm.summary()
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "category mt2" }, [
                _c("label", { staticClass: "f6 flex items-center" }, [
                  _vm._v("Playlist "),
                  _c(
                    "i",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.youtubeSignInStatus,
                          expression: "!youtubeSignInStatus",
                        },
                      ],
                      staticClass: "material-icons light-gray f6 pl1 pb1",
                    },
                    [_vm._v("lock")]
                  ),
                ]),
                !_vm.youtubeSignInStatus
                  ? _c("div", { staticClass: "mt1" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-100 ba b--adori-gray bw1 br2 pa2 adori-gray pointer dim flex",
                          on: { click: _vm.triggerYoutubeLogin },
                        },
                        [
                          _vm._v(
                            "\n                Connect your youtube account to select playlist\n                "
                          ),
                          _vm.youtubeLoader
                            ? _c("div", {
                                staticClass: "ml2 loader loader-inline",
                              })
                            : _vm._e(),
                        ]
                      ),
                    ])
                  : _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.playlistId,
                            expression: "playlistId",
                          },
                        ],
                        staticClass:
                          "bg-adori-gray white f6 w-100 br2 h2 ba b--gray mt1 scrollbar",
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.playlistId = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            function ($event) {},
                          ],
                        },
                      },
                      [
                        _c("option", { domProps: { value: 0 } }, [
                          _vm._v("Select playlist"),
                        ]),
                        _vm._l(_vm.getPlaylist, function (item, i) {
                          return _c(
                            "option",
                            { key: i, domProps: { value: item.id } },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(item.title) +
                                  "\n              "
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
              ]),
              _c("div", { staticClass: "keywords mt2" }, [
                _c("label", { staticClass: "f6 light-gray" }, [
                  _vm._v("Press enter after each keywords"),
                ]),
                _c(
                  "div",
                  { staticClass: "keyword-container mt1" },
                  [
                    _vm._l(_vm.keywords, function (keyword, i) {
                      return _c("div", { key: i, staticClass: "keyword" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(keyword) +
                            "\n                "
                        ),
                        _c(
                          "i",
                          {
                            staticClass: "material-icons",
                            on: {
                              click: function ($event) {
                                return _vm.deleteKeyword(i)
                              },
                            },
                          },
                          [_vm._v("clear")]
                        ),
                      ])
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.currentInput,
                          expression: "currentInput",
                        },
                      ],
                      domProps: { value: _vm.currentInput },
                      on: {
                        keypress: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.saveKeyword.apply(null, arguments)
                        },
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "delete",
                              [8, 46],
                              $event.key,
                              ["Backspace", "Delete", "Del"]
                            )
                          ) {
                            return null
                          }
                          return _vm.backspaceDelete.apply(null, arguments)
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.currentInput = $event.target.value
                        },
                      },
                    }),
                  ],
                  2
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "w-40 pl2" }, [
            _c("div", { staticClass: "flex flex-column" }, [
              _c("div", { staticClass: "video-thumbnail" }, [
                _c("div", { staticClass: "flex" }, [
                  _c("label", { staticClass: "f6 mb1" }, [
                    _vm._v("Video Thumbnail"),
                  ]),
                  _c(
                    "i",
                    {
                      staticClass:
                        "material-icons cam light-gray pointer dim f6",
                      on: { click: _vm.imageClicked },
                    },
                    [_vm._v(" photo_camera ")]
                  ),
                ]),
                _c("div", { staticClass: "thumbnail-bg" }, [
                  _vm.uploadingImage
                    ? _c("div", { staticClass: "loading" }, [
                        _c("img", {
                          staticClass: "h150",
                          attrs: { src: require("@/assets/spinner.svg") },
                        }),
                      ])
                    : _c("img", {
                        staticClass: "thumbnail-img",
                        attrs: { src: _vm.thumbnailSrc, alt: "" },
                      }),
                ]),
              ]),
              _c("div", { staticClass: "privacy mt2" }, [
                _c("label", { staticClass: "f6" }, [_vm._v("Privacy")]),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.privacy,
                        expression: "privacy",
                      },
                    ],
                    staticClass:
                      "bg-adori-gray white f6 w-100 br2 h2 ba b--gray mt1",
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.privacy = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        function ($event) {},
                      ],
                    },
                  },
                  _vm._l(_vm.privacyListData, function (item, i) {
                    return _c(
                      "option",
                      { key: i, domProps: { value: item.value } },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(item.title) +
                            "\n              "
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "category mt2" }, [
                _c("label", { staticClass: "f6" }, [_vm._v("Category")]),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.categoryId,
                        expression: "categoryId",
                      },
                    ],
                    staticClass:
                      "bg-adori-gray white f6 w-100 br2 h2 ba b--gray mt1 scrollbar",
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.categoryId = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        function ($event) {},
                      ],
                    },
                  },
                  _vm._l(_vm.categoryListData, function (item, i) {
                    return _c(
                      "option",
                      { key: i, domProps: { value: item.id } },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(item.title) +
                            "\n              "
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "flex mt3" }, [
                _c("div", { staticClass: "mr3" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selfDeclareMadeForKids,
                        expression: "selfDeclareMadeForKids",
                      },
                    ],
                    staticClass: "mr1",
                    attrs: {
                      type: "radio",
                      value: "true",
                      id: "MADE_FOR_KIDS",
                    },
                    domProps: {
                      checked: _vm._q(_vm.selfDeclareMadeForKids, "true"),
                    },
                    on: {
                      change: function ($event) {
                        _vm.selfDeclareMadeForKids = "true"
                      },
                    },
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "f6 light-gray pointer",
                      attrs: { for: "MADE_FOR_KIDS" },
                    },
                    [_vm._v("Yes, it's made for kids")]
                  ),
                ]),
                _c("div", {}, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selfDeclareMadeForKids,
                        expression: "selfDeclareMadeForKids",
                      },
                    ],
                    staticClass: "mr1",
                    attrs: {
                      type: "radio",
                      value: "false",
                      id: "NOT_MADE_FOR_KIDS",
                    },
                    domProps: {
                      checked: _vm._q(_vm.selfDeclareMadeForKids, "false"),
                    },
                    on: {
                      change: function ($event) {
                        _vm.selfDeclareMadeForKids = "false"
                      },
                    },
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "f6 light-gray pointer",
                      attrs: { for: "NOT_MADE_FOR_KIDS" },
                    },
                    [_vm._v("No, it's not made for kids")]
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "save flex mt4" },
                [
                  _c("BaseButtonRed", {
                    attrs: {
                      text: "Save",
                      disabled: _vm.hasErrors,
                      onClick: function () {
                        _vm.handleSave()
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }