var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "light-gray fw5 f3" }, [
          _vm._v("Play audio " + _vm._s(_vm.isAd ? "ad" : "track")),
        ]),
      ]),
      _c("template", { slot: "body" }, [
        _c(
          "div",
          { staticClass: "flex justify-start" },
          [
            _c("BaseThumbnailEpisode", {
              staticClass: "mr2",
              attrs: { src: _vm.audioPreviewImage },
            }),
            _c("div", { staticClass: "flex flex-column" }, [
              _c("div", { staticClass: "f5 light-gray mb2 mw5 truncate" }, [
                _vm._v(_vm._s(_vm.audio.name)),
              ]),
              _c("div", { staticClass: "br2" }, [
                _c(
                  "audio",
                  {
                    ref: "audio",
                    staticClass: "v-mid ml4",
                    attrs: { preload: "auto" },
                    on: {
                      timeupdate: function ($event) {
                        return _vm.updateTime()
                      },
                    },
                  },
                  [_c("source", { attrs: { src: _vm.audioSrc } })]
                ),
                _c(
                  "div",
                  { staticClass: "bg-adori-medium-gray pa1 dib" },
                  [
                    _c("BaseButtonPlayer", {
                      staticClass: "mr2 pointer dim",
                      attrs: {
                        icon: "replay_10",
                        onClick: _vm.handleSeekBackward,
                      },
                    }),
                    _c("BaseButtonPlayer", {
                      staticClass: "mr2 pointer dim",
                      attrs: {
                        icon: _vm.playButtonIcon,
                        text:
                          _vm.formattedCurrentTime +
                          " / " +
                          _vm.formattedTotalTime,
                        onClick: _vm.togglePlay,
                      },
                    }),
                    _c("BaseButtonPlayer", {
                      staticClass: "pointer dim",
                      attrs: {
                        icon: "forward_10",
                        onClick: _vm.handleSeekForward,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }