var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    ref: "editor",
    staticClass: "custom-editor",
    staticStyle: {
      "-webkit-user-select": "text !important",
      "user-select": "text !important",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }