import { useQuery } from 'vue-query'
import AdoriService from '@/services/adori'
import store from '@/store'
import { queryStaleTime } from '@/utils/query.config'

interface YtFeedParams {
  paginate?: boolean
  limit?: number
  offset?: number
  query?: null | string
}

// Query Keys
export enum feedQueryKeys {
  YTFEED = 'YTFEED',
  RSSFEED = 'RSSFEED',
}

// Queries
export function fetchRssFeed(
  networkId: string,
  { paginate = true, limit = 50, offset = 0, query = null }: YtFeedParams,
  isYoutube: boolean
) {
  return AdoriService[isYoutube ? 'fetchYTRssFeeds' : 'fetchRssFeeds'](networkId, {
    params: {
      paginate,
      limit,
      offset,
    },
  })
}

// Hooks
export const useFetchRssFeed = (params: YtFeedParams, isYoutube = false, onSuccess?: any) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useQuery(
    [isYoutube ? feedQueryKeys.YTFEED : feedQueryKeys.RSSFEED, networkId],
    () => fetchRssFeed(networkId, params, isYoutube),
    {
      staleTime: queryStaleTime,
      cacheTime: queryStaleTime,
      select: (data: any) => {
        const list = [...data.data]
        const rssFeedByUid = data?.data.reduce((acc: any, obj: any) => {
          acc[obj.uid] = obj
          return acc
        }, {})
        return {
          rssFeedByUid,
          data: list.reverse(),
          count: data.count,
          offset: data.offset,
        }
      },
      onSuccess,
    }
  )
}
