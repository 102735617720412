import AdoriServiceV6 from '@/services/adori_v6'
import { queryDefaults } from '@/utils/query.config'
import AdoriService from '@/services/adori'
import store from '@/store'
import { Ref } from '@vue/composition-api'
import { useInfiniteQuery, useQuery, useMutation } from 'vue-query'
import get from 'lodash.get'

// Query Keys

export enum tagQueryKeys {
  GET_TAGS = 'GET_TAGS',
  TRACK_TAGS = 'TRACK_TAGS',
  TAG_EFFECTS = 'TAG_EFFECTS',
}

// Queries
const getTags = (
  networkId: string,
  {
    orientation,
    offset = 0,
    query = '',
  }: {
    orientation: string
    offset: number
    query: string
  }
) => {
  return !!query
    ? AdoriService.fetchTagIdsSearch(networkId, {
        params: {
          paginate: true,
          limit: 50,
          offset,
          query,
          orientation,
        },
      })
    : AdoriService.fetchTagIds(networkId, {
        params: {
          paginate: true,
          limit: 50,
          offset,
          orientation,
        },
      })
}
const getTrackTags = (networkId: string, trackUid: string) => {
  return AdoriService.fetchAudioTags(networkId, trackUid)
}

const deleteTags = (networkId: string, tagId: string) => {
  return AdoriService.deleteTag(networkId, tagId)
}

const getTagEffects = (networkId: string, effectId: string) => {
  return AdoriServiceV6.getTagEffect(networkId, effectId)
}

// Hooks
export const useGetTags = ({ orientation, query }: { orientation: Ref<string>; query: Ref<string> }) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useInfiniteQuery(
    [tagQueryKeys.GET_TAGS, networkId, orientation, query],
    ({ pageParam = 0 }) =>
      getTags(networkId, { orientation: orientation.value, offset: pageParam, query: query.value }),
    {
      getNextPageParam: (lastPage: any, pages) => {
        lastPage.data.forEach((tag: any) => {
          store.commit('addTag', tag)
        })
        store.commit('setTagIdsCount', lastPage.count)
        const totalPages = get(pages, '[0].count', 0) > 50 ? Math.ceil(get(pages, '[0].count', 0) / 50) : 0
        if (pages.length - 1 === totalPages) return false
        else return pages.length * 50
      },
      ...queryDefaults,
      select: (data: any) => {
        const tagsArray = data.pages.flatMap((obj: any) => obj.data)
        return tagsArray
      },
    }
  )
}
export const useGetTrackTags = (trackUid: any, { enabled }: any) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useQuery(
    [tagQueryKeys.TRACK_TAGS, networkId, trackUid],
    () => getTrackTags(networkId, typeof trackUid === 'object' ? trackUid.value : trackUid),
    {
      ...queryDefaults,
      onSuccess(data: any) {
        store.commit('setAudioTags', {
          audioUid: typeof trackUid === 'object' ? trackUid.value : trackUid,
          tagPositions: data,
        })
        store.dispatch('addTrackTags', data)
      },
      enabled,
    }
  )
}

export const useGetTagEffect = (effectId: Ref<string>, { enabled }: any) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useQuery([tagQueryKeys.TAG_EFFECTS, effectId], () => getTagEffects(networkId, effectId.value), {
    ...queryDefaults,
    enabled,
  })
}

// mutations
export const useDeleteTag = () => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useMutation((tagId: string) => deleteTags(networkId, tagId), {})
}
