



















































































































import { Vue, Component } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

@Component
export default class ModalYoutubePublish extends Vue {
  @Getter youtubeSettings!: any
  @Getter youtubeModalIndex!: any
  @Getter audioMaxLen!: any

  @Action closeModal!: any

  width: number = 1280
  height: number = 720
  beginTime: string = ''
  endTime: string = ''

  get active() {
    let ratio = (this.width / this.height).toFixed(4)
    return ratio == '1.7778' ? 'landscape' : ratio === '0.5625' ? 'portrait' : ratio === '1.0000' ? 'square' : 'custom'
  }
  mounted() {
    this.width = this.youtubeSettings[this.youtubeModalIndex].width
    this.height = this.youtubeSettings[this.youtubeModalIndex].height
    this.beginTime = this.youtubeSettings[this.youtubeModalIndex].beginTime
    this.endTime = this.youtubeSettings[this.youtubeModalIndex].endTime

    if (this.endTime == '00:00:00') {
      this.endTime = new Date(this.audioMaxLen * 1000).toISOString().substr(11, 8)
    }
  }

  setActive(size: string) {
    if (size === 'square') {
      this.width = 720
      this.height = 720
    } else if (size === 'landscape') {
      this.width = 1280
      this.height = 720
    } else {
      this.width = 720
      this.height = 1280
    }
  }

  formatSizeDuration(e: any, input: string) {
    let elementRef, element, maxlength

    if (input === 'begin') {
      elementRef = this.$refs.beginRef
      element = this.beginTime
      maxlength = 8
    } else if (input === 'end') {
      elementRef = this.$refs.endRef
      element = this.endTime
      maxlength = 8
    } else if (input === 'width') {
      elementRef = this.$refs
      element = this.width.toString()
      maxlength = 4
    } else {
      elementRef = this.$refs
      element = this.height.toString()
      maxlength = 4
    }

    if (
      element.length < maxlength &&
      (e.keyCode == 8 || // backspace
        e.keyCode == 46 || // delete
        (e.keyCode >= 35 && e.keyCode <= 40) || // arrow keys/home/end
        (e.keyCode >= 48 && e.keyCode <= 57) || // numbers on keyboard
        (e.keyCode >= 96 && e.keyCode <= 105) || // number on keypad
        e.keyCode == 9) //Tab for keyboard accessibility
    ) {
      if (input != 'width' && input != 'height' && e.keyCode != 8 && (element.length === 2 || element.length === 5)) {
        let colon = (element += ':')
        // @ts-ignore
        Vue.set(elementRef, 'value', colon)
      }
    } else {
      if (
        element.length == maxlength &&
        (e.keyCode == 8 || e.keyCode == 9 || e.keyCode == 46 || (e.keyCode >= 35 && e.keyCode <= 40))
      ) {
      } else {
        e.preventDefault() // Prevent character input
      }
    }
  }
  checkSizeValidation() {
    let isSizeCorrect = this.width.toString().length > 2 && this.height.toString().length > 2
    if (!isSizeCorrect) {
      return false
    } else {
      return true
    }
  }
  checkDurValidation() {
    //for 24-hour time, leading zeroes mandatory
    let pattern = /^(?:2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/
    let isDurCorrect = this.beginTime.match(pattern) && this.endTime.match(pattern)
    if (!isDurCorrect) {
      return false
    } else {
      return true
    }
  }

  handleSave() {
    let validate = false
    validate = this.checkSizeValidation()
    if (!validate) {
      this.$store.dispatch('pushNotification', {
        text: 'Please choose valid Size',
        type: 'ERROR',
      })
      return
    }
    validate = this.checkDurValidation()
    if (!validate) {
      this.$store.dispatch('pushNotification', {
        text: 'Please choose valid duration',
        type: 'ERROR',
      })
      return
    }

    if (this.endTime == '00:00:00') {
      this.$store.dispatch('pushNotification', {
        text: 'End Time cannot be set to 00:00:00',
        type: 'ERROR',
      })
      return
    }

    let maxTime = new Date(this.audioMaxLen * 1000).toISOString().substr(11, 8)
    if (this.endTime > maxTime) {
      this.$store.dispatch('pushNotification', {
        text: 'End time cannot be greater than audio time',
        type: 'ERROR',
      })
      return
    }

    if (this.beginTime > this.endTime) {
      this.$store.dispatch('pushNotification', {
        text: 'Begin time cannot be greater than end time',
        type: 'ERROR',
      })
      return
    }

    this.$store.dispatch('youtubeSetting', {
      index: this.youtubeModalIndex,
      width: this.width,
      height: this.height,
      beginTime: this.beginTime,
      endTime: this.endTime,
    })

    this.closeModal()
  }
}
