import moment from 'moment'

import { getImageSrc } from '@/services/adori'
import tags_photo from '@/assets/tags/photo.jpg'
import get from 'lodash.get'

const actions: { [key: string]: string } = {
  message: 'create_note',
  location: 'navigate',
  call: 'call',
  'Web link': 'click',
  poll: 'choose',
  photo: 'click',
  audio: 'audio',
  display_ad: 'display_ad',
  video_ad: 'video_ad',
  buy: 'buy',
  artag: 'artag',
}

export const tagTypeMeta: { [key: string]: string }[] = [
  { name: 'photo', icon: 'photo', text: 'Visual' },
  { name: 'poll', icon: 'poll', text: 'Poll' },
  { name: 'Web link', icon: 'link', text: 'Link' },
  { name: 'call', icon: 'call', text: 'Call' },
  { name: 'location', icon: 'location_on', text: 'Location' },
  //   { name: 'message', icon: 'message', text: 'Text' },
  { name: 'buy', icon: 'buy', text: 'Buy' },
  { name: 'audio', icon: 'audio', text: 'Audio Ad' },
  { name: 'display_ad', icon: 'displayicon', text: 'Display Ad' },
  { name: 'video_ad', icon: 'videoicon', text: 'Video Ad' },
  { name: 'artag', icon: 'artag', text: 'AR' },
]

function getStyle(fontIndex: number, topMarginPercentage: number) {
  return {
    fontStyle: fontIndex,
    imageOpacity: 1,
    topMarginPercentage,
  }
}

export function getTagUploadBody(tagEditData: any, includeId: boolean) {
  const tagType = tagEditData.tagType
  const style = getStyle(tagEditData.fontStyle, tagEditData.topMargin)
  const tag: any = {
    createdOn: Date.now(),
    style,
    id: includeId ? tagEditData.tagId : undefined,
    actions: actions[tagEditData.tagType],
    imageId: tagEditData.imageId,
    canvasData: tagEditData.canvasData,
    tagTitle: tagEditData.tagTitle,
    imageData: tagEditData.imageData,
    videoCoords: tagEditData.videoCoords,
    orientation: tagEditData.orientation,
    shareable: tagType === 'poll' ? false : tagEditData.shareable,
    saveable: tagType === 'poll' ? false : tagEditData.saveable,
  }

  if (tagEditData.videoId) {
    tag.videoId = tagEditData.videoId
  }

  if (tagType === 'message') {
    tag.notetext = tagEditData.caption
  } else if (tagType === 'photo' || tagType === 'Web link' || tagType === 'location' || tagType === 'audio') {
    tag.caption = tagEditData.caption
  }

  if (tagType === 'Web link') {
    tag.url = tagEditData.inputValues[0]
  }

  if (tagType === 'call') {
    tag.contactId = tagEditData.contactId
  }

  if (tagType === 'poll') {
    tag.pollId = tagEditData.pollId
  }

  if (tagType === 'location') {
    tag.locationId = tagEditData.locationId
  }

  if (tagType === 'display_ad') {
    tag.displayAdId = tagEditData.displayAdId
  }

  if (tagType === 'video_ad') {
    tag.videoAdId = tagEditData.videoAdId
  }

  if (tagType === 'buy') {
    tag.buyId = tagEditData.buyId
  }

  if (tagType === 'artag') {
    tag.artagId = tagEditData.artagId
  }

  return tag
}

export function getTagType(tag: any) {
  if (get(tag, 'actions', '') === 'click') {
    if (tag.url) return 'Web link'
    return 'photo'
  } else if (get(tag, 'actions', '') === 'audio') {
    return 'audio'
  } else if (tag.actions === 'display_ad') {
    return 'display_ad'
  } else if (tag.actions === 'video_ad') {
    return 'video_ad'
  } else {
    const index = Object.values(actions).indexOf(tag.actions)
    return Object.keys(actions)[index]
  }
}

function getInputValues(tag: any) {
  const inputValues: string[] = []
  inputValues[0] = tag.url || (tag.contact ? tag.contact.name : null) || (tag.poll ? tag.poll.topic : null)

  inputValues[1] = tag.contact ? tag.contact.phoneNumber : null

  const otherChoices = [1, 2, 3, 4]
  if (tag.poll) {
    otherChoices.forEach((n) => {
      inputValues[n] = tag.poll['choice' + n]
    })
  }
  return inputValues
}

export function getEmptyTagModalData() {
  return {
    tagTitle: '',
    tagType: 'photo',
    tagId: '',
    shareable: false,
    saveable: false,
    data: {
      orientation: '',
      canvasData: {},
      imageData: '',
      videoCoords: null,
      image: tags_photo,
      video: null,
      imageBlob: '',
      imageId: '',
      videoSrc: '',
      videoId: '',
      inputValues: [],
      caption: '',
      fontStyle: 1,
      topMargin: 1,
      link: '',
      effectId: null,
      contactId: '',

      poll: {
        question: '',
        choices: [],
      },
      pollId: '',

      location: {
        place: null,
        markers: [],
      },
      locationId: '',
      audioAdMeta: {},
      displayAdId: '',
      displayAdMeta: {
        url: '',
        adServiceSource: 'THIRD_PARTY',
        adSource: {
          name: '',
          adNetworkId: '',
          accessCode: '',
          key1: '',
          key2: '',
        },
        defaultCampaign: {
          priority: 0,
          startTime: null,
          endTime: null,
          keywords: [{}],
          sizes: [],
          geoTargets: [],
          maxServes: 1000000, // set to a high value
          frequency: 'FOREVER',
        },
      },
      videoAdId: '',
      videoAdMeta: {
        adSource: {
          name: '',
          adNetworkId: '',
          accessCode: '',
          key1: '',
          key2: '',
        },
        defaultCampaign: {
          priority: 0,
          startTime: null,
          endTime: null,
          keywords: [{}],
          sizes: [],
          geoTargets: [],
          maxServes: 1000000, // set to a high value
          frequency: 'FOREVER',
        },
      },
      buyId: '',
      buyMeta: {
        name: '',
        actionUrl: '',
        actionText: 'BUY',
      },
      artagId: '',
      artagMeta: {
        name: '',
        assetUrl: '',
      },
    },
  }
}

function getDisplayAdMeta(tag: any) {
  if (!tag.displayAdId) {
    return {
      url: '',
      adServiceSource: 'THIRD_PARTY',
      adSource: {
        name: '',
        adNetworkId: '',
        accessCode: '',
        key1: '',
        key2: '',
      },
      defaultCampaign: {
        priority: 0,
        startTime: null,
        endTime: null,
        keywords: null,
        sizes: [],
        geoTargets: [],
        maxServes: 1000000, // set to a high value
        frequency: 'FOREVER',
      },
      audioAdMeta: {},
      buyId: '',
      buyMeta: {
        name: '',
        actionUrl: '',
        actionText: 'BUY',
      },
      artagId: '',
      artagMeta: {
        name: '',
        assetUrl: '',
      },
    }
  }

  let keywords: any = [{}]
  if (tag.displayAd.defaultCampaign.keywords.length !== 0) {
    keywords = tag.displayAd.defaultCampaign.keywords
  }

  let geoTargets: { country: string }[] = []
  if (tag.displayAd.defaultCampaign.geoTargets !== null) {
    let geo: any = tag.displayAd.defaultCampaign.geoTargets
    geo.forEach((element: any) => {
      geoTargets.push({ country: element })
    })
  }

  return {
    url: tag.displayAd.url,
    adServiceSource: tag.displayAd.adServiceSource,
    adSource: {
      name: tag.displayAd.adSource.name,
      adNetworkId: tag.displayAd.adSource.adNetworkId,
      accessCode: tag.displayAd.adSource.accessCode,
      key1: tag.displayAd.adSource.key1,
      key2: tag.displayAd.adSource.key2,
    },
    defaultCampaign: {
      priority: tag.displayAd.defaultCampaign.priority,
      startTime: moment(tag.displayAd.defaultCampaign.startTime).format('YYYY-MM-DD'),
      endTime: moment(tag.displayAd.defaultCampaign.endTime).format('YYYY-MM-DD'),
      keywords: keywords,
      sizes: '',
      geoTargets: geoTargets,
      maxServes: tag.displayAd.defaultCampaign.maxServes,
      frequency: tag.displayAd.defaultCampaign.frequency,
    },
  }
}

function getVideoAdMeta(tag: any) {
  if (!tag.videoAdId) {
    return {
      adSource: {
        name: '',
        adNetworkId: '',
        accessCode: '',
        key1: '',
        key2: '',
      },
      defaultCampaign: {
        priority: 0,
        startTime: null,
        endTime: null,
        keywords: null,
        sizes: [],
        geoTargets: [],
        maxServes: 1000000, // set to a high value
        frequency: 'FOREVER',
      },
    }
  }

  let keywords: any = [{}]
  if (tag.videoAd.defaultCampaign.keywords.length !== 0) {
    keywords = tag.videoAd.defaultCampaign.keywords
  }

  let geoTargets: { country: string }[] = []
  if (tag.videoAd.defaultCampaign.geoTargets !== null) {
    let geo: any = tag.videoAd.defaultCampaign.geoTargets
    geo.forEach((element: any) => {
      geoTargets.push({ country: element })
    })
  }

  return {
    adSource: {
      name: tag.videoAd.adSource.name,
      adNetworkId: tag.videoAd.adSource.adNetworkId,
      accessCode: tag.videoAd.adSource.accessCode,
      key1: tag.videoAd.adSource.key1,
      key2: tag.videoAd.adSource.key2,
    },
    defaultCampaign: {
      priority: tag.videoAd.defaultCampaign.priority,
      startTime: moment(tag.videoAd.defaultCampaign.startTime).format('YYYY-MM-DD'),
      endTime: moment(tag.videoAd.defaultCampaign.endTime).format('YYYY-MM-DD'),
      keywords: keywords,
      sizes: '',
      geoTargets: geoTargets,
      maxServes: tag.videoAd.defaultCampaign.maxServes,
      frequency: tag.videoAd.defaultCampaign.frequency,
    },
  }
}

function getBuyMeta(tag: any) {
  if (!tag.buyId) {
    return {
      name: '',
      actionUrl: '',
      actionText: 'BUY',
    }
  }

  return {
    name: tag.buy.name,
    actionUrl: tag.buy.actionUrl,
    actionText: tag.buy.actionText,
  }
}

function getARTagMeta(tag: any) {
  if (!tag.artagId) {
    return {
      name: '',
      assetUrl: '',
    }
  }

  return {
    name: tag.artag.name,
    assetUrl: tag.artag.assetUrl,
  }
}

export function getTagModalData(tag: any) {
  return {
    tagTitle: tag.tagTitle,
    suggestedTag: tag.suggestedTag ? true : false,
    tagId: tag.id,
    tagType: getTagType(tag),
    saveable: tag.saveable !== undefined ? tag.saveable : false,
    shareable: tag.shareable !== undefined ? tag.shareable : false,
    data: {
      caption: tag.notetext || tag.caption,
      fontStyle: tag.style ? tag.style.fontStyle : 1,
      image: tag.image ? tag.image.originalUrl : getImageSrc(tag.imageId),
      imageId: tag.imageId,
      videoId: tag.videoId,
      video: tag.video,
      topMargin: tag.style ? tag.style.topMarginPercentage : 1,
      inputValues: getInputValues(tag),
      pollId: tag.pollId,
      contactId: tag.contactId,
      locationId: tag.locationId,
      displayAdId: tag.displayAdId,
      displayAdMeta: getDisplayAdMeta(tag),
      videoAdId: tag.videoAdId,
      videoAdMeta: getVideoAdMeta(tag),
      buyId: tag.buyId,
      buyMeta: getBuyMeta(tag),
      artagId: tag.artagId,
      artagMeta: getARTagMeta(tag),
      effectId: tag.effectId,
      orientation: tag.orientation,
      canvasData: tag.canvasData,
      imageData: tag.imageData,
      videoCoords: tag.videoCoords,
    },
  }
}

export async function imageUrlToBase64(url: string) {
  try {
    // Fetch the image data from the URL
    const response = await fetch(url)

    // Check if the response is successful
    if (!response.ok) {
      throw new Error('Failed to fetch image')
    }

    // Convert the image data to a Blob
    const blob = await response.blob()

    // Create a FileReader object to read the Blob as base64
    const reader = new FileReader()
    reader.readAsDataURL(blob)

    // Return a promise that resolves with the base64-encoded image (without prefix)
    return new Promise((resolve, reject) => {
      reader.onloadend = () => {
        if (reader.readyState === FileReader.DONE) {
          const base64String: any = reader.result
          // Remove the prefix from the base64 string
          const base64Image = base64String.replace(/^data:image\/(png|jpeg|jpg);base64,/, '')
          resolve(base64Image)
        } else {
          reject(new Error('Failed to read image as base64'))
        }
      }
    })
  } catch (error: any) {
    // Handle errors
    console.error('Error:', error.message)
    throw error
  }
}

export const fontOptions = ['Rubik', 'Unica One', 'Permanent Marker', 'Marck Script', 'Dosis'].map((e) => 'Font: ' + e)

export const fontStyles = fontOptions.map((e) => e.slice(6).split(' ').join('-').toLowerCase())
