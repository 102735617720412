var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.change
    ? _c(
        "select",
        {
          ref: "select",
          staticClass: "db bg-adori-gray gray ba b--gray br2 f6 w-100 h2",
          on: {
            change: function ($event) {
              return _vm.onOptionChangeNew()
            },
          },
        },
        [
          _c(
            "option",
            {
              staticClass: "dn",
              attrs: { disabled: "disabled", selected: "" },
            },
            [_vm._v("\n    " + _vm._s(_vm.title) + "\n  ")]
          ),
          _vm._l(_vm.options, function (option) {
            return _c(
              "option",
              { key: option.value, domProps: { value: option.value } },
              [_vm._v("\n    " + _vm._s(option.text) + "\n  ")]
            )
          }),
        ],
        2
      )
    : _c(
        "select",
        {
          ref: "select",
          staticClass: "db bg-adori-gray gray ba b--gray br2 f6 w-100 h2",
          on: {
            change: function ($event) {
              return _vm.onOptionChange()
            },
          },
        },
        [
          _c(
            "option",
            {
              staticClass: "dn",
              attrs: { disabled: "disabled", selected: "" },
            },
            [_vm._v("\n    " + _vm._s(_vm.title) + "\n  ")]
          ),
          _vm._l(_vm.options, function (option) {
            return _c(
              "option",
              { key: option.value, domProps: { value: option.value } },
              [_vm._v("\n    " + _vm._s(option.text) + "\n  ")]
            )
          }),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }