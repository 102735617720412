var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    { attrs: { locked: _vm.isSubmitting } },
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "light-gray f3 fw5" }, [
          _vm._v("Edit episode details"),
        ]),
      ]),
      _c("template", { slot: "body" }, [
        !_vm.enableImageGallery
          ? _c("div", { staticClass: "flex flex-wrap" }, [
              _c(
                "div",
                {
                  staticClass: "flex flex-column",
                  staticStyle: { width: "760px", height: "600px" },
                },
                [
                  _c(
                    "form",
                    {
                      staticClass: "w-100 mr3 flex flex-column",
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _vm.currentStep === 1
                        ? _c("div", { staticClass: "flex justify-between" }, [
                            _c(
                              "div",
                              {},
                              [
                                _c("div", { staticClass: "pb2 gray f6" }, [
                                  _vm._v("Episode image"),
                                ]),
                                _c("BaseThumbnailEpisode", {
                                  attrs: {
                                    onClick: _vm.showImageGallery,
                                    src: _vm.selectedImageSrc,
                                    big: "",
                                  },
                                }),
                                _c("BaseButtonBorder", {
                                  staticClass: "mt2 w-100 mb4",
                                  attrs: {
                                    text: "Change episode image",
                                    onClick: _vm.showImageGallery,
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex justify-start items-center pb3",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "f6 link pointer dim silver flex items-center",
                                        on: {
                                          click: function ($event) {
                                            _vm.showAdvancedOptions =
                                              !_vm.showAdvancedOptions
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "i",
                                          {
                                            staticClass:
                                              "material-icons f6 mr1",
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.showAdvancedOptions
                                                    ? "remove_circle"
                                                    : "add_circle"
                                                ) +
                                                "\n                  "
                                            ),
                                          ]
                                        ),
                                        _c("span", { staticClass: "mt1" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.showAdvancedOptions
                                                ? "Hide"
                                                : "Show"
                                            ) + " advanced options"
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm.showAdvancedOptions
                                  ? _c("div", { staticClass: "pb3" }, [
                                      _c(
                                        "div",
                                        { staticClass: "flex justify-between" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "pb2 gray f6" },
                                            [_vm._v("Keywords (optional)")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "pb2 f6",
                                              class: !_vm.$v.selectedEpisode
                                                .keywords.maxLength
                                                ? "adori-red"
                                                : "gray",
                                            },
                                            [
                                              _vm._v(
                                                "\n                    (" +
                                                  _vm._s(
                                                    _vm.$v.selectedEpisode
                                                      .keywords.$model.length
                                                  ) +
                                                  " / 255)\n                  "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.trim",
                                            value:
                                              _vm.$v.selectedEpisode.keywords
                                                .$model,
                                            expression:
                                              "$v.selectedEpisode.keywords.$model",
                                            modifiers: { trim: true },
                                          },
                                        ],
                                        staticClass:
                                          "db bg-adori-very-light-gray br2 ph3 pv3 gray f6 mb2 white w-100",
                                        class:
                                          _vm.$v.selectedEpisode.keywords
                                            .$error ||
                                          !_vm.$v.selectedEpisode.keywords
                                            .maxLength
                                            ? "ba bw1 b--adori-red"
                                            : "bw0",
                                        attrs: {
                                          type: "text",
                                          placeholder:
                                            "Enter keywords separated by comma",
                                        },
                                        domProps: {
                                          value:
                                            _vm.$v.selectedEpisode.keywords
                                              .$model,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.$v.selectedEpisode.keywords,
                                              "$model",
                                              $event.target.value.trim()
                                            )
                                          },
                                          blur: function ($event) {
                                            return _vm.$forceUpdate()
                                          },
                                        },
                                      }),
                                      _vm.$v.selectedEpisode.keywords.$dirty ||
                                      !_vm.$v.selectedEpisode.keywords.maxLength
                                        ? _c("div", [
                                            !_vm.$v.selectedEpisode.keywords
                                              .maxLength
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "f6 adori-red",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    Must be less than 255 characters.\n                  "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { staticClass: "flex justify-between" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "pb2 gray f6" },
                                            [
                                              _vm._v(
                                                "\n                    Does this episode contain explicit content?\n                    "
                                              ),
                                              _c("BaseTooltip", {
                                                attrs: {
                                                  info: "This indicates whether the episode contain explicit language or adult content.",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pv1 ba br4 b--transparent bg-black-20 tc",
                                          staticStyle: { width: "85px" },
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "f6 ttu pv1 ph2 silver link pointer",
                                              class: _vm.$v.selectedEpisode
                                                .explicitContent.$model
                                                ? "br4 bg-black-80 light-gray"
                                                : "",
                                            },
                                            [
                                              _c("input", {
                                                staticClass: "input-reset",
                                                attrs: { type: "radio" },
                                                domProps: {
                                                  checked:
                                                    _vm.$v.selectedEpisode
                                                      .explicitContent.$model,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.$v.selectedEpisode.explicitContent.$model = true
                                                  },
                                                },
                                              }),
                                              _vm._v("Yes"),
                                            ]
                                          ),
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "f6 ttu pv1 ph2 silver link pointer",
                                              class: !_vm.$v.selectedEpisode
                                                .explicitContent.$model
                                                ? "br4 bg-black-80 light-gray"
                                                : "",
                                            },
                                            [
                                              _c("input", {
                                                staticClass: "input-reset",
                                                attrs: { type: "radio" },
                                                domProps: {
                                                  checked:
                                                    !_vm.$v.selectedEpisode
                                                      .explicitContent.$model,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.$v.selectedEpisode.explicitContent.$model = false
                                                  },
                                                },
                                              }),
                                              _vm._v("No"),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c("div", [
                              _c(
                                "div",
                                { staticClass: "pb3 pl4 mr4 relative" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "flex justify-between" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "pb2 gray f6" },
                                        [
                                          _vm._v(
                                            "\n                    Episode name\n                    "
                                          ),
                                          _c("BaseTooltip", {
                                            attrs: {
                                              info: "Episode name is a string containing a clear, concise name for your episode.",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "pb2 f6",
                                          class: !_vm.$v.selectedEpisode.name
                                            .maxLength
                                            ? "adori-red"
                                            : "gray",
                                        },
                                        [
                                          _vm._v(
                                            "\n                    (" +
                                              _vm._s(
                                                _vm.$v.selectedEpisode.name
                                                  .$model.length
                                              ) +
                                              " / 255)\n                  "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.trim",
                                        value:
                                          _vm.$v.selectedEpisode.name.$model,
                                        expression:
                                          "$v.selectedEpisode.name.$model",
                                        modifiers: { trim: true },
                                      },
                                    ],
                                    staticClass:
                                      "db bg-adori-very-light-gray br2 ph3 pv3 gray f6 mb2 white w-100",
                                    class:
                                      _vm.$v.selectedEpisode.name.$error ||
                                      (_vm.$v.selectedEpisode.name.$invalid &&
                                        _vm.hasClickedNext)
                                        ? "ba bw1 b--adori-red"
                                        : "ba bw1 b--adori-transparent",
                                    attrs: {
                                      type: "text",
                                      placeholder: "Enter episode name",
                                    },
                                    domProps: {
                                      value: _vm.$v.selectedEpisode.name.$model,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.$v.selectedEpisode.name,
                                          "$model",
                                          $event.target.value.trim()
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                                  _vm.$v.selectedEpisode.name.$dirty &&
                                  !_vm.hasClickedNext
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "absolute",
                                          staticStyle: { bottom: "5px" },
                                        },
                                        [
                                          !_vm.$v.selectedEpisode.name.required
                                            ? _c(
                                                "div",
                                                { staticClass: "f6 adori-red" },
                                                [_vm._v("Cannot be empty.")]
                                              )
                                            : _vm._e(),
                                          !_vm.$v.selectedEpisode.name.maxLength
                                            ? _c(
                                                "div",
                                                { staticClass: "f6 adori-red" },
                                                [
                                                  _vm._v(
                                                    "\n                    Must be less than 255 characters.\n                  "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm.hasClickedNext
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "absolute",
                                          staticStyle: { bottom: "5px" },
                                        },
                                        [
                                          _vm.$v.selectedEpisode.name.$invalid
                                            ? _c("div", [
                                                !_vm.$v.selectedEpisode.name
                                                  .required
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "f6 adori-red",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Cannot be empty."
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                !_vm.$v.selectedEpisode.name
                                                  .maxLength
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "f6 adori-red",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      Must be less than 255 characters.\n                    "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ])
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "pb3 pl4 mr4 relative" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "flex justify-between" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "pb2 gray f6" },
                                        [
                                          _vm._v(
                                            "\n                    Episode description\n                    "
                                          ),
                                          _c("BaseTooltip", {
                                            attrs: {
                                              info: "Episode description is text containing one or more sentences describing your episode to potential listeners.",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c("HTMLEditor", {
                                    staticClass:
                                      "bg-adori-very-light-gray bw0 br2 ph3 pv1 white mb2 overflow-y-auto scrollbar",
                                    class:
                                      _vm.$v.selectedEpisode.description
                                        .$error ||
                                      (_vm.$v.selectedEpisode.description
                                        .$invalid &&
                                        _vm.hasClickedNext)
                                        ? "ba bw1 b--adori-red"
                                        : "ba bw1 b--adori-transparent",
                                    staticStyle: {
                                      width: "377px",
                                      height: "180px",
                                    },
                                    attrs: {
                                      placeholder: "Enter episode description",
                                      initialContent:
                                        _vm.$v.selectedEpisode.description
                                          .$model,
                                    },
                                    on: { update: _vm.updateDescription },
                                  }),
                                  _vm.$v.selectedEpisode.description.$dirty
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "absolute",
                                          staticStyle: { bottom: "5px" },
                                        },
                                        [
                                          !_vm.$v.selectedEpisode.description
                                            .required ||
                                          !_vm.$v.selectedEpisode.description
                                            .notEmptyHTML
                                            ? _c(
                                                "div",
                                                { staticClass: "f6 adori-red" },
                                                [
                                                  _vm._v(
                                                    "\n                    Cannot be empty\n                  "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          !_vm.$v.selectedEpisode.description
                                            .maxLength
                                            ? _c(
                                                "div",
                                                { staticClass: "f6 adori-red" },
                                                [
                                                  _vm._v(
                                                    "\n                    Your episode description is too long.\n                  "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.hasClickedNext
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "absolute",
                                          staticStyle: { bottom: "5px" },
                                        },
                                        [
                                          !_vm.$v.selectedEpisode.description
                                            .required ||
                                          !_vm.$v.selectedEpisode.description
                                            .notEmptyHTML
                                            ? _c(
                                                "div",
                                                { staticClass: "f6 adori-red" },
                                                [
                                                  _vm._v(
                                                    "\n                    Cannot be empty\n                  "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          !_vm.$v.selectedEpisode.description
                                            .maxLength
                                            ? _c(
                                                "div",
                                                { staticClass: "f6 adori-red" },
                                                [
                                                  _vm._v(
                                                    "\n                    Your episode description is too long.\n                  "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "pl4 mr4 relative" }, [
                                _c(
                                  "div",
                                  { staticClass: "flex justify-between" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "pb2 gray f6" },
                                      [
                                        _vm._v(
                                          "\n                    Episode summary\n                    "
                                        ),
                                        _c("BaseTooltip", {
                                          attrs: {
                                            info: "Episode summary is best for a short description of your episode. It will display above the full show notes.",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "pb2 f6",
                                        class: !_vm.$v.selectedEpisode.summary
                                          .maxLength
                                          ? "adori-red"
                                          : "gray",
                                      },
                                      [
                                        _vm._v(
                                          "\n                    (" +
                                            _vm._s(
                                              _vm.$v.selectedEpisode.summary
                                                .$model.length
                                            ) +
                                            " / 255)\n                  "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value:
                                        _vm.$v.selectedEpisode.summary.$model,
                                      expression:
                                        "$v.selectedEpisode.summary.$model",
                                      modifiers: { trim: true },
                                    },
                                  ],
                                  staticClass:
                                    "db bg-adori-very-light-gray bw0 br2 ph3 pv3 gray f6 white mb2 overflow-y-auto scrollbar w-100",
                                  class:
                                    _vm.$v.selectedEpisode.summary.$error ||
                                    (_vm.$v.selectedEpisode.summary.$invalid &&
                                      _vm.hasClickedNext)
                                      ? "ba bw1 b--adori-red"
                                      : "ba bw1 b--adori-transparent",
                                  attrs: {
                                    rows: "1",
                                    cols: "40",
                                    placeholder: "Enter episode summary",
                                  },
                                  domProps: {
                                    value:
                                      _vm.$v.selectedEpisode.summary.$model,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.$v.selectedEpisode.summary,
                                        "$model",
                                        $event.target.value.trim()
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                                _vm.$v.selectedEpisode.summary.$dirty &&
                                !_vm.hasClickedNext
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "absolute",
                                        staticStyle: { bottom: "22px" },
                                      },
                                      [
                                        !_vm.$v.selectedEpisode.summary.required
                                          ? _c(
                                              "div",
                                              { staticClass: "f6 adori-red" },
                                              [_vm._v("Cannot be empty.")]
                                            )
                                          : _vm._e(),
                                        !_vm.$v.selectedEpisode.summary
                                          .maxLength
                                          ? _c(
                                              "div",
                                              { staticClass: "f6 adori-red" },
                                              [
                                                _vm._v(
                                                  "\n                    Must be less than 255 characters.\n                  "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm.hasClickedNext
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "absolute",
                                        staticStyle: { bottom: "22px" },
                                      },
                                      [
                                        _vm.$v.selectedEpisode.summary.$invalid
                                          ? _c("div", [
                                              !_vm.$v.selectedEpisode.summary
                                                .required
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "f6 adori-red",
                                                    },
                                                    [_vm._v("Cannot be empty.")]
                                                  )
                                                : _vm._e(),
                                              !_vm.$v.selectedEpisode.summary
                                                .maxLength
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "f6 adori-red",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      Must be less than 255 characters.\n                    "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                              _c(
                                "div",
                                { staticClass: "pb4 pt3 pl4 mr4 scrollbar" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "flex justify-between" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "pb2 gray f6" },
                                        [
                                          _vm._v(
                                            "\n                    Episode language\n                    "
                                          ),
                                          _c("BaseTooltip", {
                                            attrs: {
                                              info: "Language used in audio.",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c("div", { staticClass: "h3 scrollbar" }, [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.selectedLanguage,
                                            expression: "selectedLanguage",
                                          },
                                        ],
                                        staticClass:
                                          "input-reset light-gray bn br2 pa3 pr5 shadow-2 db arrow w-100 mb2 w2 scrollbar",
                                        staticStyle: { width: "376px" },
                                        attrs: { disabled: true },
                                        on: {
                                          change: function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.selectedLanguage = $event.target
                                              .multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          },
                                        },
                                      },
                                      _vm._l(
                                        _vm.languages || [],
                                        function (lang) {
                                          return _c(
                                            "option",
                                            {
                                              key: lang.code,
                                              staticClass: "scrollbar",
                                              staticStyle: { width: "30px" },
                                              domProps: { value: lang.code },
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(lang.name) +
                                                  "\n                    "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.currentStep === 2 && !_vm.isYoutube
                        ? _c("div", { staticClass: "flex justify-between" }, [
                            _c(
                              "div",
                              { staticClass: "flex flex-column mr3 w-47" },
                              [
                                _c("div", { staticClass: "pb3" }, [
                                  _c(
                                    "div",
                                    { staticClass: "flex justify-between" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "pb2 gray f6" },
                                        [
                                          _vm._v(
                                            "\n                    Type of episode\n                    "
                                          ),
                                          _c("BaseTooltip", {
                                            attrs: {
                                              info: "“Regular” for normal episodes; “Trailer” to promote an upcoming show, season, or episode; or “Bonus” for extra content related to a show, season, or episode.",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.$v.selectedEpisode.episodeType
                                              .$model,
                                          expression:
                                            "$v.selectedEpisode.episodeType.$model",
                                        },
                                      ],
                                      staticClass:
                                        "input-reset light-gray bn br2 pa3 pr5 shadow-2 db arrow w-100 mb2",
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.$v.selectedEpisode.episodeType,
                                            "$model",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    _vm._l(_vm.episodeTypes, function (type) {
                                      return _c(
                                        "option",
                                        {
                                          key: type.value,
                                          domProps: { value: type.value },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(type.text) +
                                              "\n                  "
                                          ),
                                        ]
                                      )
                                    }),
                                    0
                                  ),
                                  _vm.$v.selectedEpisode.episodeType.$dirty
                                    ? _c("div", [
                                        !_vm.$v.selectedEpisode.episodeType
                                          .required
                                          ? _c(
                                              "div",
                                              { staticClass: "f6 adori-red" },
                                              [_vm._v("Cannot be empty.")]
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                ]),
                                _c("div", { staticClass: "pb2" }, [
                                  _c(
                                    "div",
                                    { staticClass: "flex justify-between" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "pb2 gray f6" },
                                        [
                                          _vm._v(
                                            "\n                    Does this episode belong to a season?\n                    "
                                          ),
                                          _c("BaseTooltip", {
                                            attrs: {
                                              info: "Any number to indicate the season in which this episode belongs. This can be used with any show and episode type.",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pv1 ba br4 b--transparent bg-black-20 tv",
                                      staticStyle: { width: "85px" },
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "f6 ttu pv1 ph2 silver link pointer",
                                          class: _vm.belongsToSeason
                                            ? "br4 bg-black-80 light-gray"
                                            : "",
                                        },
                                        [
                                          _c("input", {
                                            staticClass: "input-reset",
                                            attrs: { type: "radio" },
                                            domProps: {
                                              checked: _vm.belongsToSeason,
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.belongsToSeason = true
                                              },
                                            },
                                          }),
                                          _vm._v("Yes"),
                                        ]
                                      ),
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "f6 ttu pv1 ph2 silver link pointer",
                                          class: !_vm.belongsToSeason
                                            ? "br4 bg-black-80 light-gray"
                                            : "",
                                        },
                                        [
                                          _c("input", {
                                            staticClass: "input-reset",
                                            attrs: { type: "radio" },
                                            domProps: {
                                              checked: !_vm.belongsToSeason,
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.belongsToSeason = false
                                              },
                                            },
                                          }),
                                          _vm._v("No"),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "pb3",
                                    class: !_vm.belongsToSeason
                                      ? "o-50"
                                      : "o-100",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "flex justify-between" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "pb2 gray f6" },
                                          [_vm._v("Season number (optional)")]
                                        ),
                                      ]
                                    ),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.trim.number",
                                          value:
                                            _vm.$v.selectedEpisode.season
                                              .$model,
                                          expression:
                                            "$v.selectedEpisode.season.$model",
                                          modifiers: {
                                            trim: true,
                                            number: true,
                                          },
                                        },
                                      ],
                                      staticClass:
                                        "db bg-adori-very-light-gray br2 ph3 pv3 gray f6 mb2 white w-100",
                                      class: _vm.$v.selectedEpisode.season
                                        .$error
                                        ? "ba bw1 b--adori-red"
                                        : "bw0",
                                      attrs: {
                                        type: "number",
                                        step: "1",
                                        min: "1",
                                        disabled: !_vm.belongsToSeason,
                                        required: "",
                                      },
                                      domProps: {
                                        value:
                                          _vm.$v.selectedEpisode.season.$model,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.$v.selectedEpisode.season,
                                            "$model",
                                            _vm._n($event.target.value.trim())
                                          )
                                        },
                                        blur: function ($event) {
                                          return _vm.$forceUpdate()
                                        },
                                      },
                                    }),
                                    _vm.$v.selectedEpisode.season.$dirty
                                      ? _c("div", [
                                          !_vm.$v.selectedEpisode.season.numeric
                                            ? _c(
                                                "div",
                                                { staticClass: "f6 adori-red" },
                                                [
                                                  _vm._v(
                                                    "Must be a valid number."
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                                _c("div", { staticClass: "pb2" }, [
                                  _c(
                                    "div",
                                    { staticClass: "flex justify-between" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "pb2 gray f6" },
                                        [
                                          _vm._v(
                                            "\n                    Do you want to include a episode number?\n                    "
                                          ),
                                          _c("BaseTooltip", {
                                            attrs: {
                                              info: "Any number to indicate the current episode number, which can be relative to the entire show (like “316”), or to the current season (like “5”).",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pv1 ba br4 b--transparent bg-black-20 tc",
                                      staticStyle: { width: "85px" },
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "f6 ttu pv1 ph2 silver link pointer",
                                          class: _vm.hasEpisodeNumber
                                            ? "br4 bg-black-80 light-gray"
                                            : "",
                                        },
                                        [
                                          _c("input", {
                                            staticClass: "input-reset",
                                            attrs: { type: "radio" },
                                            domProps: {
                                              checked: _vm.hasEpisodeNumber,
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.hasEpisodeNumber = true
                                              },
                                            },
                                          }),
                                          _vm._v("Yes"),
                                        ]
                                      ),
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "f6 ttu pv1 ph2 silver link pointer",
                                          class: !_vm.hasEpisodeNumber
                                            ? "br4 bg-black-80 light-gray"
                                            : "",
                                        },
                                        [
                                          _c("input", {
                                            staticClass: "input-reset",
                                            attrs: { type: "radio" },
                                            domProps: {
                                              checked: !_vm.hasEpisodeNumber,
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.hasEpisodeNumber = false
                                              },
                                            },
                                          }),
                                          _vm._v("No"),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "pb3",
                                    class: !_vm.hasEpisodeNumber
                                      ? "o-50"
                                      : "o-100",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "flex justify-between" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "pb2 gray f6" },
                                          [_vm._v("Episode number (optional)")]
                                        ),
                                      ]
                                    ),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.trim.number",
                                          value:
                                            _vm.$v.selectedEpisode.episodeNumber
                                              .$model,
                                          expression:
                                            "$v.selectedEpisode.episodeNumber.$model",
                                          modifiers: {
                                            trim: true,
                                            number: true,
                                          },
                                        },
                                      ],
                                      staticClass:
                                        "db bg-adori-very-light-gray br2 ph3 pv3 gray f6 mb2 white w-100",
                                      class: _vm.$v.selectedEpisode
                                        .episodeNumber.$error
                                        ? "ba bw1 b--adori-red"
                                        : "bw1 b--adori-transparent",
                                      attrs: {
                                        type: "number",
                                        step: "1",
                                        min: "1",
                                        disabled: !_vm.hasEpisodeNumber,
                                        required: "",
                                      },
                                      domProps: {
                                        value:
                                          _vm.$v.selectedEpisode.episodeNumber
                                            .$model,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.$v.selectedEpisode
                                              .episodeNumber,
                                            "$model",
                                            _vm._n($event.target.value.trim())
                                          )
                                        },
                                        blur: function ($event) {
                                          return _vm.$forceUpdate()
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "flex flex-column w-47" },
                              [
                                _c("div", { staticClass: "pb3" }, [
                                  _c(
                                    "div",
                                    { staticClass: "flex justify-between" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "pb2 gray f6" },
                                        [
                                          _vm._v(
                                            "\n                    Schedule for publishing\n                    "
                                          ),
                                          _c("BaseTooltip", {
                                            attrs: {
                                              info: "Publishing of episode can be scheduled, if it is not published yet.",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm.formatedPublishedOrScheduled
                                    ? _c("div", [
                                        _c(
                                          "div",
                                          { staticClass: "mt2 mb2 light-gray" },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.formatedPublishedOrScheduled
                                                ) +
                                                "\n                  "
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.isEpisodeScheduled &&
                                  _vm.$permissions.isScheduleEpisodeShowAllowed(
                                    _vm.selectedRssFeedUid
                                  )
                                    ? _c(
                                        "div",
                                        { staticClass: "flex flex-column" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex items-center mv1",
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.episodePublishType,
                                                    expression:
                                                      "episodePublishType",
                                                  },
                                                ],
                                                staticClass: "mr2 mb1",
                                                attrs: {
                                                  type: "radio",
                                                  id: "publish-later",
                                                  value: "PUBLISH_LATER",
                                                },
                                                domProps: {
                                                  checked: _vm._q(
                                                    _vm.episodePublishType,
                                                    "PUBLISH_LATER"
                                                  ),
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    _vm.episodePublishType =
                                                      "PUBLISH_LATER"
                                                  },
                                                },
                                              }),
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "light-gray f6",
                                                  attrs: {
                                                    for: "publish-later",
                                                  },
                                                },
                                                [_vm._v("Choose date and time")]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "ml3 f7 tj o-70 white mr3",
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Schedule your episode publishing at a custom date and time. This would give you some time to\n                    review the audio file and add/remove interactive tags.\n                  "
                                              ),
                                            ]
                                          ),
                                          _vm.formatedSuggestedDate
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "ml3 f7 silver",
                                                },
                                                [
                                                  _vm._v(
                                                    "---------------------"
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.formatedSuggestedDate
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "ml3 f7 tj white mr3 o-70",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    Based on your previous schedule and nature of content, the best time to publish this episode would\n                    be:\n                    " +
                                                      _vm._s(
                                                        _vm.formatedPublishedOn
                                                      ) +
                                                      ".\n                  "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.episodePublishType ===
                                          "PUBLISH_LATER"
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "pb3 flex items-center justify-between",
                                                },
                                                [
                                                  _c("BaseButtonBorder", {
                                                    staticClass: "pv3",
                                                    attrs: {
                                                      onClick:
                                                        _vm.handleSuggestedDate,
                                                      text: "Use suggested date",
                                                    },
                                                  }),
                                                  _c("date-picker", {
                                                    staticClass:
                                                      "adori-date-picker",
                                                    attrs: {
                                                      lang: "en",
                                                      type: "datetime",
                                                      format:
                                                        "YYYY-MM-DD hh:mm a",
                                                      "time-picker-options": {
                                                        start: "00:00",
                                                        step: "00:30",
                                                        end: "23:30",
                                                      },
                                                      "not-before": _vm.today,
                                                      width: "176",
                                                      clearable: false,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.$v.selectedEpisode
                                                          .scheduleTime.$model,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.$v.selectedEpisode
                                                            .scheduleTime,
                                                          "$model",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "$v.selectedEpisode.scheduleTime.$model",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex items-center mv1",
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.episodePublishType,
                                                    expression:
                                                      "episodePublishType",
                                                  },
                                                ],
                                                staticClass: "mr2 mb1",
                                                attrs: {
                                                  type: "radio",
                                                  id: "publish-now",
                                                  value: "PUBLISH_NOW",
                                                },
                                                domProps: {
                                                  checked: _vm._q(
                                                    _vm.episodePublishType,
                                                    "PUBLISH_NOW"
                                                  ),
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    _vm.episodePublishType =
                                                      "PUBLISH_NOW"
                                                  },
                                                },
                                              }),
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "light-gray f6",
                                                  attrs: { for: "publish-now" },
                                                },
                                                [_vm._v("Publish immediately")]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "ml3 f7 white tj mr3 o-70",
                                            },
                                            [
                                              _vm._v(
                                                "\n                    All set? Choose this option if your episode is fully reviewed (and tagged). Proceed with caution,\n                    this action cannot be undone.\n                  "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
            ])
          : _c(
              "div",
              [
                _c("ImageGallery", {
                  attrs: { galleryParent: "PODCAST_EPISODE" },
                  on: {
                    showTag: _vm.showImageGallery,
                    handleSelectedImage: _vm.handleSelectedImage,
                  },
                }),
              ],
              1
            ),
      ]),
      _c("template", { slot: "footer" }, [
        _c("div", { staticClass: "flex justify-between w-100" }, [
          _vm.currentStep === 1 && !_vm.enableImageGallery
            ? _c("div", { staticClass: "flex flex-wrap w-100" }, [
                _c("div", {
                  staticClass: "w-third flex justify-start items-center",
                }),
                _c(
                  "div",
                  {
                    staticClass:
                      "w-third flex flex-column justify-center items-center",
                  },
                  [
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isYoutube,
                            expression: "!isYoutube",
                          },
                        ],
                        staticClass: "silver mv0",
                      },
                      [_vm._v("Step 1 of 2")]
                    ),
                    _c(
                      "p",
                      {
                        staticClass: "f6 silver",
                        staticStyle: { "margin-top": "5px" },
                      },
                      [_vm._v("All fields are required.")]
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "w-third flex justify-end items-center" },
                  [
                    _c("BaseButtonBorder", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isYoutube,
                          expression: "!isYoutube",
                        },
                      ],
                      attrs: { text: "Next", onClick: _vm.handleNextTab },
                    }),
                    _vm.isYoutube
                      ? _c("BaseButtonRed", {
                          attrs: {
                            text: "Edit episode",
                            onClick:
                              _vm.handleEditEpisodeSubmit || _vm.isSubmitting,
                            disabled: _vm.disableSubmit,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.currentStep === 2
            ? _c("div", { staticClass: "flex flex-wrap w-100" }, [
                _c(
                  "div",
                  { staticClass: "w-third flex justify-start items-center" },
                  [
                    _c("BaseButtonBorder", {
                      attrs: { text: "Back", onClick: _vm.handlePrevTab },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "w-third flex justify-center items-center" },
                  [
                    _c("p", { staticClass: "silver mv0" }, [
                      _vm._v("Step 2 of 2"),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "w-third flex justify-end items-center" },
                  [
                    _c(
                      "div",
                      { staticClass: "flex justify-end items-center" },
                      [
                        _vm.errorMessage
                          ? _c("div", { staticClass: "f6 adori-red mh3" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.errorMessage) +
                                  "\n            "
                              ),
                            ])
                          : _vm._e(),
                        _vm.isSubmitting
                          ? _c("div", {
                              staticClass: "loader loader-inline mh3",
                            })
                          : _vm._e(),
                        _c("BaseButtonRed", {
                          attrs: {
                            text: "Edit episode",
                            onClick:
                              _vm.handleEditEpisodeSubmit || _vm.isSubmitting,
                            disabled: _vm.disableSubmit,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }