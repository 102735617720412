



































































































import { Vue, Component } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { ADORI_API_BASE_URL, ADORI_WEBPLAYER_BASE_URL } from '@/constants'
import { CURRENT_ENV, ENVS } from '@/constants/env'

@Component
export default class ModalAudioTrackPreview extends Vue {
  @Getter token!: any
  @Getter networkId!: any
  @Getter networkType!: any
  @Getter selectedEpisodeSettings!: any
  @Getter ytModalId!: any
  @Getter isYoutube!: Boolean
  @Getter isTimeLineEdited!: Boolean

  hoverOnIframe = false
  currentTagPosition = null

  orientation: string = 'PORTRAIT' // One of 'PORTRAIT' or 'LANDSCAPE'
  isLoading = false

  async created() {
    this.isLoading = true
    if (this.isTimeLineEdited) {
      await this.$store.dispatch('uploadAudioTags', this.episodeUid)
    }
    this.isLoading = false
  }

  mounted() {
    // This has to be moved to state
    // This will explode someday: BOMB1
    window.addEventListener('message', this.onPostMessage)
    // set default orientation based on user selection
    if (this.isYoutube) {
      let w = this.selectedEpisodeSettings[this.ytModalId].width
      let h = this.selectedEpisodeSettings[this.ytModalId].height
      let ratio = (w / h).toFixed(4)
      this.orientation = this.calcOrientation(ratio)
    }
  }

  calcOrientation(ratio: string) {
    return ratio == '1.7778' ? 'LANDSCAPE' : 'PORTRAIT'
  }

  beforeDestroy() {
    // This will explode someday: BOMB2
    window.removeEventListener('message', this.onPostMessage)
  }

  onPostMessage(data: any) {
    const tagData = data.data
    if (
      data.origin === ADORI_WEBPLAYER_BASE_URL &&
      tagData.context === 'adori-web-player' &&
      tagData.eventName === 'currentTag'
    ) {
      this.currentTagPosition = tagData.info.tag
    }
  }

  get episodeUid() {
    return this.$store.state.modal.audioPreview.episodeUid
  }

  get authToken() {
    return this.token
  }

  get adoriWebplayerBaseUrl() {
    switch (CURRENT_ENV) {
      case ENVS.prod:
        return 'https://webplayer.adorilabs.com'
      case ENVS.beta:
        return 'https://webplayer.beta.adorilabs.com'
    }
    return 'https://webplayer.dev.adorilabs.com'
  }

  get iframePortraitSrc() {
    const isDev = ADORI_API_BASE_URL.indexOf('dev.adorilabs.com') !== -1
    return `${ADORI_WEBPLAYER_BASE_URL}/${this.episodeUid}?mode=preview&dev=${isDev}&token=${this.authToken}&network_id=${this.networkId}`
  }

  get iframeLandscapeSrc() {
    const isDev = ADORI_API_BASE_URL.indexOf('dev.adorilabs.com') !== -1
    return `${ADORI_WEBPLAYER_BASE_URL}/e/${this.episodeUid}?mode=preview&token=${this.authToken}&network_id=${this.networkId}`
  }

  get showEditButton() {
    return this.hoverOnIframe && this.currentTagPosition !== null
  }

  openTagEditor() {
    if (this.currentTagPosition !== null) {
      // @ts-ignore : Object is possibly null
      this.$store.dispatch('showTagEdit', this.currentTagPosition.id)
    }
  }
}
