











import { Vue, Component, Prop } from 'vue-property-decorator'
import PlansV2 from '@/components/Payment/PlansV2.vue'

@Component({
  components: {
    PlansV2,
  },
})
export default class ModalPlans extends Vue {
  @Prop(Boolean) customModal!: boolean
  handleCLose() {
    if (this.customModal) {
      this.$emit('closeModal')
    } else this.$store.commit('setCurrentModal', null)
  }
}
