var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "f3 light-gray fw5 di" }),
      ]),
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "flex justify-between w-80 white mt4" }, [
          _c("div", { staticClass: "w-100 flex flex-column items-center" }, [
            _c("img", {
              attrs: {
                src: require("@/assets/monetize/audioPlaceHolder.jpg"),
                alt: "",
                height: "250",
                width: "1050",
              },
            }),
            _c(
              "audio",
              {
                staticClass: "w-100",
                attrs: {
                  controls: "",
                  autoplay: "",
                  src: _vm.audioPlayUrl,
                  type: "audio/mpeg",
                },
              },
              [
                _vm._v(
                  "\n          Your browser does not support the\n          "
                ),
                _c("code", [_vm._v("audio")]),
                _vm._v(" element.\n        "),
              ]
            ),
          ]),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }