import Vue from 'vue'
import { StoreOptions, GetterTree, MutationTree, ActionTree } from 'vuex'
import AdoriService from '@/services/adori'

const state: any = {
  networkPerms: null,
  showPerms: null,
  routeMap: null,
}

const initialState: any = {
  ...JSON.parse(JSON.stringify(state)),
}

const getters: GetterTree<any, any> = {
  networkPerms: (state) => state.networkPerms,
  showPerms: (state) => state.showPerms,
  routeMap: (state) => state.routeMap,
}

const mutations: MutationTree<any> = {
  resetState(state) {
    Object.keys(state).forEach((key: any) => {
      Vue.set(state, key, initialState[key])
    })
  },

  setNetworkPerms(state, networkPerms) {
    Vue.set(state, 'networkPerms', networkPerms)
  },

  setShowPerms(state, showPerms) {
    Vue.set(state, 'showPerms', showPerms)
  },

  setRouteMap(state, routeMap) {
    Vue.set(state, 'routeMap', routeMap)
  },
}

const actions: ActionTree<any, any> = {
  resetPermissionsState(context) {
    context.commit('resetState')
  },

  async getPermissions(context) {
    const permissions: any = await AdoriService.fetchPermissions()
    context.commit('setNetworkPerms', permissions.networkPerms)
    context.commit('setShowPerms', permissions.showPerms)
    context.commit('setRouteMap', permissions.routeMap)
  },
}

const permissions: StoreOptions<any> = {
  state,
  getters,
  mutations,
  actions,
}

export default permissions
