var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-container" },
    [
      _vm._l(_vm.values, function (value, index) {
        return _c("div", { key: index, staticClass: "flex" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: value.key,
                expression: "value.key",
              },
            ],
            staticClass:
              "bg-adori-very-light-gray bw0 br2 w-100 mb2 ph3 pv3 f6 white mr1",
            attrs: { placeholder: "Enter the key", type: "text" },
            domProps: { value: value.key },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(value, "key", $event.target.value)
                },
                _vm.updateValues,
              ],
            },
          }),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: value.value,
                expression: "value.value",
              },
            ],
            staticClass:
              "bg-adori-very-light-gray bw0 br2 w-100 mb2 ph3 pv3 f6 white mr1",
            attrs: { placeholder: "Enter the values", type: "text" },
            domProps: { value: value.value },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(value, "value", $event.target.value)
                },
                _vm.updateValues,
              ],
            },
          }),
          _vm.values.length !== 1
            ? _c(
                "i",
                {
                  staticClass: "material-icons white mt2 pointer",
                  on: {
                    click: function ($event) {
                      return _vm.deleteValues(index)
                    },
                  },
                },
                [_vm._v("remove_circle_outline")]
              )
            : _vm._e(),
        ])
      }),
      _c("div", { staticClass: "flex white mb2" }, [
        _c(
          "i",
          {
            staticClass: "material-icons white mr1 f4 pointer",
            on: { click: _vm.addValues },
          },
          [_vm._v("add_circle_outline")]
        ),
        _c(
          "span",
          {
            staticClass: "mt1 f7 pointer add-more",
            on: { click: _vm.addValues },
          },
          [_vm._v("Add More")]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }