var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.caption
    ? _c(
        "div",
        {
          staticClass: "absolute bg-black-70 f7 pa1 w-100 white ph2",
          style: _vm.captionPosition,
        },
        [
          _c("p", {
            staticClass: "di",
            class: _vm.fontClass,
            style: _vm.currentTag === "message" ? "white-space: pre-wrap;" : "",
            domProps: { innerHTML: _vm._s(_vm.caption) },
          }),
          _vm._t("default"),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }