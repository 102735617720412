import Vue from 'vue'
import { StoreOptions, GetterTree, MutationTree, ActionTree } from 'vuex'
import AdoriService from '@/services/adori'

const state: any = {
  transcript: {},
  ep_info: {},
  blog_url: {},
}

const initialState: any = {
  ...JSON.parse(JSON.stringify(state)),
}

const getters: GetterTree<any, any> = {
  getSuggestions: (state) => (audioUid: string, source: string) => state[source][audioUid],
}

const mutations: MutationTree<any> = {
  resetState(state) {
    Object.keys(state).forEach((key: any) => {
      Vue.set(state, key, initialState[key])
    })
  },

  addSuggestions(state, { audioUid, suggestions, lcaseSource }) {
    Vue.set(state[lcaseSource], audioUid, suggestions)
  },
}

const actions: ActionTree<any, any> = {
  resetTagSuggestionsState(context) {
    context.commit('resetState')
  },

  async fetchSuggestions(context, { audioUid, source }) {
    const networkId = context.getters.networkId
    const sourceToStatus: any = {
      transcript: 'transSuggTaskStatus',
      ep_info: 'trackInfoSuggTaskStatus',
    }

    const suggestions = await AdoriService.fetchTagSuggestions(networkId, audioUid, source)
    const lcaseSource = source.toLowerCase()

    context.commit('addSuggestions', { audioUid, suggestions, lcaseSource })

    // If the suggestions status has changed, fetch the episode details again.
    const episode = context.getters.audio(audioUid)
    if (episode[sourceToStatus[source]] !== suggestions.status) {
      await context.dispatch('getAudio', audioUid)
    }
  },

  async generateSuggestions(context, { audioUid, source }) {
    const networkId = context.getters.networkId
    await AdoriService.generateTagSuggestions(networkId, audioUid, source)
  },
}

const tagSuggestions: StoreOptions<any> = {
  state,
  getters,
  mutations,
  actions,
}

export default tagSuggestions

// Public helpers

export function createSuggestionsPoll(store: any, { audioUid, source }: any, interval: number): number {
  return window.setInterval(() => {
    store.dispatch('fetchSuggestions', { audioUid, source })
  }, interval)
}

export function destroySuggestionsPoll(handler: number) {
  window.clearInterval(handler)
}
