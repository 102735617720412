var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "bg-adori-gray br2 b--adori-gray ba link",
      on: {
        click: function ($event) {
          return _vm.buttonAction()
        },
      },
    },
    [
      _vm.text
        ? _c("p", { staticClass: "dib light-gray f6 ph1 mv1 v-mid" }, [
            _vm._v("\n    " + _vm._s(_vm.text) + "\n  "),
          ])
        : _vm._e(),
      _c("i", { staticClass: "material-icons light-gray f3 mv1 v-mid" }, [
        _vm._v("\n    " + _vm._s(_vm.icon) + "\n  "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }