import Vue from 'vue'
import { StoreOptions, GetterTree, MutationTree, ActionTree } from 'vuex'
import AdoriService from '@/services/adori'
import axios from 'axios'

const state: any = {
  selectedVideoFile: null,
  generatedTags: [],
  trackDetails: null,
}

const initialState: any = {
  ...JSON.parse(JSON.stringify(state)),
}

const getters: GetterTree<any, any> = {
  selectedVideoFile: (state) => state.selectedVideoFile,
  generatedTags: (state) => state.generatedTags,
  trackDetails: (state) => state.trackDetails,
}

const mutations: MutationTree<any> = {
  resetState(state) {
    Object.keys(state).forEach((key: any) => {
      Vue.set(state, key, initialState[key])
    })
  },

  setVideoFile(state, data) {
    Vue.set(state, 'selectedVideoFile', data)
  },

  clearSelectedVideofile(state, data) {
    Vue.set(state, 'selectedVideoFile', null)
  },

  setTrackDetails(state, data) {
    Vue.set(state, 'trackDetails', data)
  },

  createTag(state, data) {
    const sortedTags = [...state.generatedTags, data].sort((a: any, b: any) => a.time - b.time)
    Vue.set(state, 'generatedTags', [...sortedTags])
  },

  removeGeneratedTag(state, index) {
    let generatedTags = [...state.generatedTags]
    generatedTags.splice(index, 1)
    Vue.set(state, 'generatedTags', generatedTags)
  },

  updateTagDetails(state, data) {
    let generatedTags = [...state.generatedTags]
    generatedTags[data.tagIndex].caption = data.caption
    generatedTags[data.tagIndex].link = data.link
    Vue.set(state, 'generatedTags', generatedTags)
  },
}

const actions: ActionTree<any, any> = {
  resetVideoToAdoriState(context) {
    context.commit('resetState')
  },

  async uploadVideo(context) {
    context.dispatch('showFileUploader', {
      accept: 'video',
      onChange: async () => {
        context.dispatch('setVideoFile')
      },
    })
  },

  async setVideoFile(context) {
    context.commit('setVideoFile', context.getters.selectedFile)
  },

  async clearSelectedVideofile(context) {
    context.commit('clearSelectedVideofile')
  },

  async setTrackDetails(context, data) {
    context.commit('setTrackDetails', data)
  },

  async removeGeneratedTag(context, index) {
    context.commit('removeGeneratedTag', index)
  },

  async addToGeneratedTags(context, data) {},

  async createTag(context, data) {
    context.commit('createTag', data)
  },

  async updateTagDetails(context, data) {
    context.commit('updateTagDetails', data)
  },
}

const videoToAdori: StoreOptions<any> = {
  state,
  getters,
  mutations,
  actions,
}

export default videoToAdori
