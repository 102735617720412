























import { Vue, Component } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

@Component
export default class TheNotifications extends Vue {
  @Getter notifications!: any
  @Action removeNotification!: any

  notificationTypeClass(type: string) {
    switch (type) {
      case 'SUCCESS':
        return 'bg-green light-gray'
      case 'ERROR':
        return 'bg-red light-gray'
      case 'WARNING':
        return 'bg-gold near-black'
    }
  }

  notificationTypeIcon(type: string) {
    switch (type) {
      case 'SUCCESS':
        return 'check_circle'
      case 'ERROR':
        return 'error'
      case 'WARNING':
        return 'warning'
    }
  }

  handleCloseNotification(id: number) {
    this.removeNotification(id)
  }
}
