var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { height: "550px" } }, [
    _c(
      "div",
      {
        staticClass: "flex justify-between items-center flex-column relative",
        staticStyle: { width: "750px" },
      },
      [
        _c(
          "div",
          {
            staticClass:
              "absolute top-0 left-0 flex items-center pointer glow white o-70",
            on: {
              click: function ($event) {
                return _vm.$emit("showTag")
              },
            },
          },
          [_vm._m(0), _vm._v(" Go back\n    ")]
        ),
        _c("div", { staticClass: "flex items-center W-100" }, [
          _c(
            "div",
            {
              staticClass:
                "ba bw1 br4 b--transparent bg-black-20 flex items-center",
            },
            _vm._l(_vm.searchLibraries, function (item) {
              return _c(
                "label",
                {
                  staticClass: "f6 pv1 ph2 silver link pointer mr1",
                  class:
                    _vm.selectedImageTab === item.LibraryName
                      ? "ba br4 b--transparent bg-black-80 light-gray"
                      : "",
                },
                [
                  _c("input", {
                    staticClass: "input-reset",
                    attrs: { type: "radio" },
                    domProps: {
                      checked: _vm.selectedImageTab === item.LibraryName,
                    },
                    on: {
                      click: function ($event) {
                        _vm.selectedImageTab = item.LibraryName
                      },
                    },
                  }),
                  _vm._v(_vm._s(item.name)),
                ]
              )
            }),
            0
          ),
        ]),
        _vm.selectedImageTab === "UPLOAD"
          ? _c(
              "div",
              {
                staticClass: "sect flex flex-column items-center pa4 w-100",
                staticStyle: { "min-height": "470px" },
                on: {
                  dragover: function ($event) {
                    $event.preventDefault()
                  },
                  drop: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [
                _vm.placeHolderImages.includes(_vm.previewImage)
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "w-90 bg-adori-light-gray ba b--adori-light-gray b--dashed br2 pa5 flex flex-column items-center justify-center",
                        class: { "dark-fill": _vm.bgDragColor },
                        staticStyle: { height: "350px" },
                        on: {
                          dragover: function () {
                            return (_vm.bgDragColor = true)
                          },
                          dragleave: function () {
                            return (_vm.bgDragColor = false)
                          },
                          drop: function ($event) {
                            return _vm.dropFile($event, _vm.galleryParent)
                          },
                          click: function ($event) {
                            return _vm.showImageUploader(_vm.galleryParent)
                          },
                        },
                      },
                      [
                        _c(
                          "i",
                          {
                            staticClass: "material-icons f-headline silver mb2",
                            staticStyle: { "pointer-events": "none" },
                          },
                          [_vm._v("publish")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "f4 silver mb3",
                            staticStyle: { "pointer-events": "none" },
                          },
                          [_vm._v("Drop image or click to upload")]
                        ),
                        _vm.galleryParent === "PODCAST_SHOW"
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "flex flex-column items-center justify-center",
                                staticStyle: { "pointer-events": "none" },
                              },
                              [
                                _c("div", { staticClass: "f6 silver mb1" }, [
                                  _vm._v("Image support: JPEG or PNG (square)"),
                                ]),
                                _c("div", { staticClass: "f6 silver mb1" }, [
                                  _vm._v("min: 1400x1400 px"),
                                ]),
                                _c("div", { staticClass: "f6 silver" }, [
                                  _vm._v("max: 3000x3000 px"),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "relative w-100" },
                  [
                    !_vm.placeHolderImages.includes(_vm.previewImage) &&
                    _vm.galleryParent === "TAG_CREATE"
                      ? _c("BaseAdoriTagImage", {
                          ref: "adoriImage",
                          staticClass: "absolute top-0 left-0 right-0",
                          staticStyle: { margin: "auto" },
                          attrs: {
                            src: _vm.previewImage,
                            enableCroppa: _vm.enableImageCropper,
                            bigger: "",
                          },
                        })
                      : !_vm.placeHolderImages.includes(_vm.previewImage) &&
                        _vm.galleryParent === "PODCAST_EPISODE"
                      ? _c("BaseThumbnailEpisode", {
                          staticClass: "absolute top-2 left-0 right-0",
                          staticStyle: { margin: "auto" },
                          attrs: { src: _vm.previewImage, big: "" },
                        })
                      : !_vm.placeHolderImages.includes(_vm.previewImage) &&
                        _vm.galleryParent === "PODCAST_SHOW"
                      ? _c(
                          "div",
                          { staticClass: "flex justify-center" },
                          [
                            _c("croppa", {
                              ref: "croppaInstance",
                              staticClass:
                                "br2 ba bw1 b--adori-light-gray b--dashed",
                              attrs: {
                                accept: "image/*",
                                "initial-size": "cover",
                                "initial-position": "center",
                                "prevent-white-space": true,
                                "show-remove-button": false,
                                "placeholder-font-size": 1,
                                width: 320,
                                "initial-image": _vm.previewImage,
                                height: 320,
                                quality: 5,
                                "loading-color": "red",
                                "disable-drag-and-drop": true,
                                "canvas-color": "#212121",
                              },
                              model: {
                                value: _vm.podcastCroppa,
                                callback: function ($$v) {
                                  _vm.podcastCroppa = $$v
                                },
                                expression: "podcastCroppa",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "absolute bottom-0 w-100 flex items-center justify-center",
                  },
                  [
                    !_vm.placeHolderImages.includes(_vm.previewImage)
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "ph3 pv2 ba b--adori-gray br2 light-gray bg-dark-gray mr2",
                            on: { click: _vm.selectPodcastImage },
                          },
                          [_vm._v("\n          Select\n        ")]
                        )
                      : _vm._e(),
                    !_vm.placeHolderImages.includes(_vm.previewImage)
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "ph3 pv2 ba b--adori-gray br2 light-gray bg-dark-gray",
                            on: { click: _vm.handleCancelUpload },
                          },
                          [_vm._v("\n          Cancel\n        ")]
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            )
          : _c(
              "div",
              {
                staticClass: "sect flex justify-between w-100",
                staticStyle: { "min-height": "470px" },
              },
              [
                _c("div", { staticClass: "w-70" }, [
                  _vm.selectedImageTab !== "MY_LIBRARY"
                    ? _c(
                        "div",
                        { staticClass: "flex justify-left items-center pt3" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "w-100 ba b--adori-gray br2 ml2 mt2 bg-dark-gray relative",
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.searchQuery,
                                    expression: "searchQuery",
                                  },
                                ],
                                staticClass:
                                  "input-reset bg-dark-gray light-gray bn br2 pa2 ph3 dib w-100 mr2",
                                attrs: {
                                  type: "text",
                                  placeholder: "Search free images",
                                },
                                domProps: { value: _vm.searchQuery },
                                on: {
                                  keyup: function ($event) {
                                    return _vm.debouncedSearch()
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.searchQuery = $event.target.value
                                  },
                                },
                              }),
                              !_vm.searchQuery
                                ? _c(
                                    "i",
                                    {
                                      staticClass:
                                        "material-icons silver absolute",
                                      staticStyle: {
                                        top: "5px",
                                        right: "15px",
                                      },
                                    },
                                    [_vm._v("search")]
                                  )
                                : _c(
                                    "i",
                                    {
                                      staticClass:
                                        "material-icons silver absolute pointer dim",
                                      staticStyle: {
                                        top: "5px",
                                        right: "10px",
                                      },
                                      on: { click: _vm.clearSearchQuery },
                                    },
                                    [_vm._v("close")]
                                  ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.isLibImageLoading || _vm.isPublicImageLoading
                    ? _c("div", { staticClass: "flex justify-center mt5" }, [
                        _vm._m(1),
                      ])
                    : _c(
                        "div",
                        [
                          _vm.imageList.length === 0 ||
                          (_vm.selectedImageTab !== "MY_LIBRARY" &&
                            !_vm.imageSearchText)
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "w-100 flex flex-column justify-center items-center",
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/imgae-search.svg"),
                                      alt: "",
                                      width: "250",
                                      height: "250",
                                    },
                                  }),
                                  _c("div", { staticClass: "f5 silver" }, [
                                    _vm._v(
                                      "Oops! No Images found.. Upload Images"
                                    ),
                                  ]),
                                ]
                              )
                            : _c("ImagesList", {
                                attrs: {
                                  selectedRangeStart: _vm.selectedRangeStart,
                                  selectedRangeEnd: _vm.selectedRangeEnd,
                                  totalImages: _vm.totalImageCount,
                                  previewImageSrc: _vm.previewImageSrc,
                                  imageSources: _vm.imageList,
                                },
                                on: {
                                  handleNavigation: _vm.handleNavigation,
                                  handleSelectImage: _vm.handleSelectImage,
                                },
                              }),
                        ],
                        1
                      ),
                ]),
                _vm.selectedImageTab !== "UPLOAD"
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "w-25 flex flex-column items-center bl bw1 b--adori-gray",
                      },
                      [
                        _vm.galleryParent === "TAG_CREATE"
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "flex justify-center flex w-90 ml4 mt5 overflow-y-hidden",
                                class: _vm.previewImageSrc
                                  ? "items-start bg-black br2"
                                  : "items-center ba b--dashed b--adori-gray bw1",
                                staticStyle: { height: "250px" },
                              },
                              [
                                _vm.previewImageSrc
                                  ? _c("img", {
                                      staticClass: "w-100 br2",
                                      attrs: {
                                        src: _vm.previewImageSrc,
                                        alt: "",
                                      },
                                    })
                                  : _c("div", { staticClass: "tc" }, [
                                      _c(
                                        "i",
                                        {
                                          staticClass:
                                            "material-icons silver f1",
                                        },
                                        [_vm._v("add_photo_alternate")]
                                      ),
                                      _c("div", { staticClass: "silver f7" }, [
                                        _vm._v("Select image to preview"),
                                      ]),
                                    ]),
                              ]
                            )
                          : _vm.galleryParent === "PODCAST_EPISODE"
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "flex justify-center flex ml3 mt5 overflow-y-hidden",
                                class: _vm.previewImageSrc
                                  ? "items-end br2"
                                  : "items-center ba b--dashed b--adori-gray bw1",
                                staticStyle: {
                                  height: "250px",
                                  "margin-right": "-24px",
                                  width: "95%",
                                },
                              },
                              [
                                _vm.previewImageSrc
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "w-100 bg-black flex relative justify-end bject-fit-cover",
                                        staticStyle: { height: "100px" },
                                      },
                                      [
                                        _c("img", {
                                          staticStyle: { height: "100px" },
                                          attrs: {
                                            src: _vm.previewImageSrc,
                                            alt: "",
                                          },
                                        }),
                                      ]
                                    )
                                  : _c("div", { staticClass: "tc" }, [
                                      _c(
                                        "i",
                                        {
                                          staticClass:
                                            "material-icons silver f1",
                                        },
                                        [_vm._v("add_photo_alternate")]
                                      ),
                                      _c("div", { staticClass: "silver f7" }, [
                                        _vm._v("Select image to preview"),
                                      ]),
                                    ]),
                              ]
                            )
                          : _vm.galleryParent === "PODCAST_SHOW"
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "flex justify-center flex ml3 mt5 overflow-y-hidden",
                                class: _vm.previewImageSrc
                                  ? "items-end br2"
                                  : "items-center ba b--dashed b--adori-gray bw1",
                                staticStyle: {
                                  height: "250px",
                                  "margin-right": "-24px",
                                  width: "95%",
                                },
                              },
                              [
                                _vm.previewImageSrc
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "w-100 flex relative justify-center bject-fit-cover",
                                      },
                                      [
                                        _c("croppa", {
                                          ref: "croppaInstance",
                                          staticClass:
                                            "br2 ba bw1 b--adori-light-gray b--dashed",
                                          attrs: {
                                            accept: "image/*",
                                            "initial-size": "cover",
                                            "initial-position": "center",
                                            "prevent-white-space": true,
                                            "show-remove-button": false,
                                            "placeholder-font-size": 1,
                                            width: 160,
                                            "initial-image":
                                              _vm.previewImageSrc,
                                            height: 160,
                                            quality: 10,
                                            "loading-color": "red",
                                            "disable-drag-and-drop": true,
                                            "show-loading": true,
                                            "canvas-color": "#212121",
                                          },
                                          model: {
                                            value: _vm.podcastCroppa,
                                            callback: function ($$v) {
                                              _vm.podcastCroppa = $$v
                                            },
                                            expression: "podcastCroppa",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _c("div", { staticClass: "tc" }, [
                                      _c(
                                        "i",
                                        {
                                          staticClass:
                                            "material-icons silver f1",
                                        },
                                        [_vm._v("add_photo_alternate")]
                                      ),
                                      _c("div", { staticClass: "silver f7" }, [
                                        _vm._v("Select image to preview"),
                                      ]),
                                    ]),
                              ]
                            )
                          : _vm._e(),
                        _c("div", { staticStyle: { "margin-bottom": "22px" } }),
                        _vm.previewImageSrc
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "ph3 pv2 ba b--adori-gray mt4 br2 light-gray bg-dark-gray ml4",
                                on: { click: _vm.selectPodcastImage },
                              },
                              [_vm._v("\n          Select\n        ")]
                            )
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("i", { staticClass: "material-icons mr2" }, [_vm._v("arrow_back")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "building-blocks mt5" }, [
      _c("div"),
      _c("div"),
      _c("div"),
      _c("div"),
      _c("div"),
      _c("div"),
      _c("div"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }