var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    [
      _c("template", { slot: "header" }, [
        _vm.rssFeedsByUid &&
        _vm.rssFeedsByUid.length !== 0 &&
        _vm.rssFeedsByUid[_vm.selectedPodcastUid]
          ? _c("div", { staticClass: "light-gray f3 fw5" }, [
              _vm._v(
                "\n      Members in " +
                  _vm._s(_vm.rssFeedsByUid[_vm.selectedPodcastUid].name) +
                  "\n      "
              ),
              _c("span", { staticClass: "i silver" }, [
                _vm._v("( " + _vm._s(_vm.rssShowUsers.length) + " )"),
              ]),
            ])
          : _c("div", { staticClass: "light-gray f3 fw5" }, [
              _vm._v("\n      Members in your show\n      "),
              _c("span", { staticClass: "i silver" }, [
                _vm._v("( " + _vm._s(_vm.rssShowUsers.length) + " )"),
              ]),
            ]),
      ]),
      _c("template", { slot: "body" }, [
        _c(
          "div",
          { staticStyle: { width: "500px" } },
          [
            _vm.isLoading
              ? _c("div", { staticClass: "flex justify-center items-center" }, [
                  _c("div", { staticClass: "mr3 silver" }, [
                    _vm._v("Loading users of the show..."),
                  ]),
                  _c("div", { staticClass: "loader loader-inline" }),
                ])
              : _vm.rssShowUsers.length !== 0
              ? _vm._l(_vm.rssShowUsers, function (member) {
                  return _c(
                    "div",
                    {
                      key: member.user.email,
                      staticClass: "flex justify-between mb2",
                    },
                    [
                      _c("p", { staticClass: "white" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(member.user.email) +
                            "\n          "
                        ),
                        _c("span", { staticClass: "i silver" }, [
                          _vm._v("( " + _vm._s(member.role.name) + " )"),
                        ]),
                      ]),
                      _c("BaseButtonBorder", {
                        attrs: {
                          icon: "remove_circle",
                          params: member,
                          onClick: _vm.handleRemoveUser,
                        },
                      }),
                    ],
                    1
                  )
                })
              : _c("div", [
                  _c("p", { staticClass: "silver" }, [
                    _vm._v("There are no users added to this show"),
                  ]),
                ]),
          ],
          2
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }