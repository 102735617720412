

























import { Vue, Component, Prop } from 'vue-property-decorator'
import { ADORI_SSO_BASE_URL } from '@/constants'

@Component({})
export default class ModalNoMemberNetworks extends Vue {
  mounted() {
    this.$store.dispatch('clearSession')
  }

  goToRequestInvite() {
    this.$store.dispatch('clearSession')
    window.location.href = `${ADORI_SSO_BASE_URL}/logout?redirect_uri=${encodeURIComponent(
      window.location.origin + '/signup'
    )}`
  }
  handleSignOut() {
    this.$store.dispatch('clearSession')
    window.location.href = `${ADORI_SSO_BASE_URL}/logout?redirect_uri=${encodeURIComponent(
      window.location.origin + '/login'
    )}`
  }
}
