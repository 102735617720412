






























import { Vue, Component, Prop } from 'vue-property-decorator'
import vSelect from 'vue-select'
import get from 'lodash.get'
import countries from './countryList'
import 'vue-select/dist/vue-select.css'

@Component({ components: { vSelect } })
export default class CountryModal extends Vue {
  @Prop(Boolean) locked!: boolean
  @Prop(Function) addLocation!: () => void
  @Prop(Function) saveLocation!: any

  selectedData: { name: string; code: string } = { name: '', code: '' }
  options: { name: string; code: string }[] = countries
  chooseLocation() {
    if (get(this.selectedData, 'code')) {
      this.saveLocation(this.selectedData)
    }
  }

  setSelected(selectedData: { name: string; code: string }) {
    this.selectedData = selectedData
  }

  handleCloseButtonClicked() {
    this.addLocation()
  }
}
