import Vue from 'vue'
import { StoreOptions, GetterTree, MutationTree, ActionTree } from 'vuex'
import AdoriService from '@/services/adori'
import moment from 'moment'

const state: any = {
  rssFeedUids: [],
  searchedRssFeedUids: [],
  rssFeedsCount: -1,
  rssFeedsOffset: -1,
  rssFeedsByUid: {},
  rssFeedItemsByFeedUid: {},
  selectedRssFeedUid: null,
  selectedRssFeedAudioTrackUid: null,
  validationErrors: {},
  rssGenres: [],
  allRssFeedItemsByFeedUid: [],
  selectedFilter: 'ALL_EPISODES',
  rssFeedsSortOrder: '',
  rssFeeds: [],
  rssFeedsBasedOnUids: [],
  rssFeedItemsBasedOnUids: [],
  ytFeedAllEpisodes: [],
  ytFeedUnpublishedEpisodes: [],
}

const initialState: any = {
  ...JSON.parse(JSON.stringify(state)),
}

const getters: GetterTree<any, any> = {
  isloadingFeeds: (state) => state.loadingFeeds,
  selectedFilter: (state) => state.selectedFilter,
  // rssFeedUids: state => state.rssFeedUids,
  rssFeedUids: (state) =>
    [...Object.values(state.rssFeedsByUid)]
      .sort((a: any, b: any) => moment(a.lastPublishedDate).unix() - moment(b.lastPublishedDate).unix())
      .reverse()
      .map((feed: any) => feed.uid),
  searchedRssFeedUids: (state) => state.searchedRssFeedUids,
  rssFeedsByUid: (state) => state.rssFeedsByUid,
  rssFeeds: (state) =>
    [...Object.values(state.rssFeedsByUid)]
      .sort((a: any, b: any) => moment(a.lastPublishedDate).unix() - moment(b.lastPublishedDate).unix())
      .reverse(),
  rssFeedsBasedOnUids: (state) => state.rssFeedsBasedOnUids,
  rssFeedItemsBasedOnUids: (state) => state.rssFeedItemsBasedOnUids,
  rssFeedsCount: (state) => state.rssFeedsCount,
  rssFeedsOffset: (state) => state.rssFeedsOffset,
  rssFeedsLazyLoader: (state) => ({
    data: state.rssFeedUids,
    count: state.rssFeedsCount > state.rssFeedUids.length ? state.rssFeedsCount : state.rssFeedUids.length,
    offset: state.rssFeedsOffset,
  }),
  searchedRssFeedsLazyLoader: (state) => ({
    data: state.searchedRssFeedUids,
    count: state.searchedRssFeedUids.length,
    offset: state.rssFeedsOffset,
  }),
  rssFeedItemsByFeedUid: (state) => state.rssFeedItemsByFeedUid,
  rssFeed: (state) => (feedUid: string) => state.rssFeedsByUid[feedUid],
  rssFeedItems: (state) => (feedUid: string) => state.rssFeedItemsByFeedUid[feedUid],
  rssFeedAudioTrackUids: (state) => (feedUid: string) =>
    state.rssFeedItemsByFeedUid[feedUid] && state.rssFeedItemsByFeedUid[feedUid].data
      ? state.rssFeedItemsByFeedUid[feedUid].data.map((audioTrack: any) => audioTrack.uid)
      : [],
  rssFeedAudioTrackUidsPublished: (state) => (feedUid: string) =>
    state.rssFeedItemsByFeedUid[feedUid] && state.rssFeedItemsByFeedUid[feedUid].data
      ? state.rssFeedItemsByFeedUid[feedUid].data
          .filter((t: any) => !t.isScheduled)
          .map((audioTrack: any) => audioTrack.uid)
      : [],
  rssFeedAudioTracks: (state) => (feedUid: string) => state.rssFeedItemsByFeedUid[feedUid].data,
  rssFeedAudioTrack: (state) => (feedUid: string, audioUid: string) =>
    state.rssFeedItemsByFeedUid[feedUid] && state.rssFeedItemsByFeedUid[feedUid].data
      ? state.rssFeedItemsByFeedUid[feedUid].data.find((audioTrack: any) => audioTrack.uid === audioUid)
      : null,
  rssFeedAudioTrackUidsBySeason: (state) => (feedUid: string, season: number) =>
    state.rssFeedItemsByFeedUid[feedUid].data
      .filter((audioTrack: any) => audioTrack.season === season)
      .map((audioTrack: any) => audioTrack.uid),
  rssFeedSeasons: (state) => (feedUid: string) =>
    state.rssFeedItemsByFeedUid[feedUid] && state.rssFeedItemsByFeedUid[feedUid].data
      ? [
          ...new Set(
            state.rssFeedItemsByFeedUid[feedUid].data
              .filter((audioTrack: any) => !!audioTrack.season)
              .map((audioTrack: any) => audioTrack.season)
          ),
        ]
      : {},
  selectedRssFeedUid: (state) => state.selectedRssFeedUid,
  hasSelectedRssFeedUid: (state) => state.selectedRssFeedUid !== null,
  selectedRssFeedAudioTrackUid: (state) => state.selectedRssFeedAudioTrackUid,
  hasSelectedRssFeedAudioTrackUid: (state) => state.selectedRssFeedAudioTrackUid !== null,
  validationErrors: (state) => state.validationErrors,
  rssGenres: (state) => state.rssGenres,
  allRssFeedItemsByFeedUid: (state) => state.allRssFeedItemsByFeedUid,
  ytFeedAllEpisodes: (state) => state.ytFeedAllEpisodes,
  ytFeedUnpublishedEpisodes: (state) => state.ytFeedUnpublishedEpisodes,
}

const mutations: MutationTree<any> = {
  resetState(state) {
    Object.keys(state).forEach((key: any) => {
      Vue.set(state, key, initialState[key])
    })
  },

  setRssFeedsFilter(state, publishedStatus) {
    Vue.set(state, 'selectedFilter', publishedStatus)
  },

  setRssFeedsSortOrder(state, sortOrder) {
    Vue.set(state, 'rssFeedsSortOrder', sortOrder)
  },

  addRssFeed(state, { rssFeed, rssFeedItems }) {
    if (state.rssFeedUids.indexOf(rssFeed.uid) === -1) {
      // Vue.set(state, 'rssFeedUids', [rssFeed.uid, ...state.rssFeedUids])
      Vue.set(state.rssFeedsByUid, rssFeed.uid, rssFeed)
      Vue.set(state.rssFeedItemsByFeedUid, rssFeed.uid, rssFeedItems)
    }
  },

  setRssFeedUids(state) {
    const uids = [...Object.values(state.rssFeedsByUid)]
      .sort((a: any, b: any) => moment(a.createdOn).unix() - moment(b.createdOn).unix())
      .reverse()
      .map((feed: any) => feed.uid)
    Vue.set(state, 'rssFeedUids', uids)
  },

  searchRssFeedUids(state, rssFeedUids) {
    Vue.set(state, 'searchedRssFeedUids', rssFeedUids)
  },

  addRssGenres(state, genres) {
    Vue.set(state, 'rssGenres', genres)
  },

  removeRssFeed(state, { rssFeedUid }) {
    const index = state.rssFeedUids.indexOf(rssFeedUid)
    Vue.delete(state.rssFeedUids, index)
    Vue.delete(state.rssFeedsByUid, rssFeedUid)
    Vue.delete(state.rssFeedItemsByFeedUid, rssFeedUid)
  },

  getRssFeedItem(state, { rssFeedUid, audioTrack }) {
    const existingAudioTracks = state.rssFeedItemsByFeedUid[rssFeedUid].data
    const index = existingAudioTracks.findIndex((track: any) => track.uid === audioTrack.uid)
    if (index === -1) {
      Vue.set(state.rssFeedItemsByFeedUid[rssFeedUid], 'audioTracks', [audioTrack, ...existingAudioTracks])
    } else {
      Vue.set(state.rssFeedItemsByFeedUid[rssFeedUid].data, index, audioTrack)
    }
  },

  addRssFeedItem(state, { rssFeedUid, newAudioTrack }) {
    const existingAudioTracks = state.rssFeedItemsByFeedUid[rssFeedUid].data
    if (existingAudioTracks) {
      Vue.set(state.rssFeedItemsByFeedUid[rssFeedUid], 'audioTracks', [newAudioTrack, ...existingAudioTracks])
    }
  },

  updateRssFeedItem(state, { rssFeedUid, updatedAudioTrack }) {
    const index = state.rssFeedItemsByFeedUid[rssFeedUid].data.findIndex(
      (audioTrack: any) => audioTrack.uid === updatedAudioTrack.uid
    )
    Vue.set(state.rssFeedItemsByFeedUid[rssFeedUid].data, index, updatedAudioTrack)
  },

  updateRssFeed(state, { rssFeed }) {
    Vue.set(state.rssFeedsByUid, rssFeed.uid, rssFeed)
  },

  updateRssFeedItems(state, { rssFeedUid, rssFeedItems, replace }) {
    if (
      state.rssFeedItemsByFeedUid[rssFeedUid] &&
      state.rssFeedItemsByFeedUid[rssFeedUid].length !== 0 &&
      rssFeedItems.offset !== 0
    ) {
      const data = [...state.rssFeedItemsByFeedUid[rssFeedUid].data, ...rssFeedItems.data]
      const rssFeedObj = {
        count: rssFeedItems.count,
        offset: rssFeedItems.offset,
        data: replace ? rssFeedItems.data : data,
        limit: rssFeedItems.limit,
      }
      Vue.set(state.rssFeedItemsByFeedUid, rssFeedUid, rssFeedObj)
    } else {
      Vue.set(state.rssFeedItemsByFeedUid, rssFeedUid, rssFeedItems)
    }
  },

  // Todo: Check if all these things are necessary
  setSelectedRssFeedUid(state, rssFeedUid) {
    if (state.rssFeedUids.filter((rssFeed: any) => rssFeed === rssFeedUid).length !== 0) {
      Vue.set(state, 'selectedRssFeedUid', rssFeedUid)
    } else if (state.rssFeedUids.length !== 0) {
      Vue.set(state, 'selectedRssFeedUid', state.rssFeedUids[0])
    } else {
      Vue.set(state, 'selectedRssFeedUid', undefined)
    }
  },

  setSelectedRssFeedUidForInsights(state, rssFeedUid) {
    Vue.set(state, 'selectedRssFeedUid', rssFeedUid)
  },

  setSelectedRssFeedAudioTrackUid(state, audioTrackUid) {
    Vue.set(state, 'selectedRssFeedAudioTrackUid', audioTrackUid)
  },

  setValidationErrors(state, validationErrors) {
    Vue.set(state, 'validationErrors', validationErrors)
  },

  clearValidationErrors(state) {
    Vue.set(state, 'validationErrors', null)
  },

  setRssFeedsCount(state, rssFeedsCount) {
    Vue.set(state, 'rssFeedsCount', rssFeedsCount)
  },

  setRssFeedsOffset(state, rssFeedsOffset) {
    Vue.set(state, 'rssFeedsOffset', rssFeedsOffset)
  },

  setRssFeedsBasedOnUids(state, rssFeeds) {
    Vue.set(state, 'rssFeedsBasedOnUids', rssFeeds)
  },

  setRssFeedItemsBasedOnUids(state, feeds) {
    Vue.set(state, 'rssFeedItemsBasedOnUids', feeds)
  },

  clearRssFeeds(state) {
    Vue.set(state, 'rssFeedUids', [])
    Vue.set(state, 'rssFeedsCount', -1)
    Vue.set(state, 'rssFeedsOffset', -1)
    Vue.set(state, 'rssFeedsByUid', {})
    Vue.set(state, 'rssFeedItemsByFeedUid', {})
    Vue.set(state, 'selectedRssFeedUid', null)
  },

  clearRssFeedsBasedOnTags(state) {
    Vue.set(state, 'rssFeedsBasedOnUids', [])
    Vue.set(state, 'rssFeedItemsBasedOnUids', [])
  },

  clearAllRssFeedItemsByFeedUid(state) {
    Vue.set(state, 'allRssFeedItemsByFeedUid', [])
  },

  clearRssFeedItemsByFeedUid(state, data) {
    Vue.set(state, 'rssFeedItemsByFeedUid', {
      ...state.rssFeedItemsByFeedUid,
      [data]: [],
    })
  },

  updateAllRssFeedItemsByFeedUid(state, data) {
    Vue.set(state, 'allRssFeedItemsByFeedUid', [...state.allRssFeedItemsByFeedUid, ...data])
  },

  setRssFeeds(state, data) {
    Vue.set(state, 'rssFeeds', data)
  },
  setYTFeedAllEpisodes(state, episodes) {
    Vue.set(state, 'ytFeedAllEpisodes', episodes)
  },
  setYTFeedUnpublishedEpisodes(state, episodes) {
    Vue.set(state, 'ytFeedUnpublishedEpisodes', episodes)
  },
}

const actions: ActionTree<any, any> = {
  resetRssFeedsState(context) {
    context.commit('resetState')
  },

  setRssFeedsFilter(context, publishedStatus) {
    context.commit('setRssFeedsFilter', publishedStatus)
  },

  setRssFeedsSortOrder(context, sortOrder) {
    context.commit('setRssFeedsSortOrder', sortOrder)
  },

  async updateExistingRssFeedAndItems(context, { rssFeedUid }) {
    const networkId = context.getters.networkId
    const rssFeed: any = await AdoriService.fetchRssFeed(networkId, rssFeedUid)
    const totalEpisodes = rssFeed.totalPublishedTracks + rssFeed.totalScheduledTracks
    // const limit = 10
    const offset = 0
    let rssFeedItems: any = await AdoriService.fetchRssFeedItems(networkId, rssFeedUid, totalEpisodes, offset)
    // Patch each audioTrack with the audioCollections key.
    // The audioCollections key is incorrectly named,
    // it should have been rssCollections.
    rssFeedItems.data = rssFeedItems.data.map((audioTrack: any) => {
      audioTrack.audioCollections = [rssFeed]
      return audioTrack
    })

    context.commit('updateAllRssFeedItemsByFeedUid', rssFeedItems.data)
    context.commit('updateRssFeed', { rssFeed })
    context.commit('updateRssFeedItems', { rssFeedUid, rssFeedItems })

    context.commit('updateAllRssFeedItemsByFeedUid', rssFeedItems.data)
    rssFeedItems.data.forEach((audioTrack: any) => {
      context.dispatch('createAudioTrackFromObject', audioTrack)
    })
  },

  async filterReplaceAudioProcessUids(context, data: any) {
    const replaceAudios = data.filter(
      (audio: any) =>
        audio.replaceTask && audio.replaceTask.status !== 'FINISHED' && audio.replaceTask.status !== 'FAILED'
    )
    if (replaceAudios && replaceAudios.length) {
      replaceAudios.forEach((audio: any) => {
        context.dispatch('addAudioToReplaceAudioProcessList', audio.uid)
      })
    }
  },

  async getYTRssFeeds(context, { paginate = true, limit = 50, offset = 0, query = null, clear = true }) {
    const networkId = context.getters.networkId
    let rssFeeds: any = []
    if (clear) {
      context.commit('clearRssFeeds')
    }

    let paramsWithQuery: { [key: string]: any } = {
      paginate,
      limit,
      offset,
      query,
    }
    let params: { [key: string]: any } = {
      paginate,
      limit,
      offset,
    }

    if (query) {
      rssFeeds = await AdoriService.fetchRssFeedsSearch(networkId, {
        params: paramsWithQuery,
      })
    } else {
      rssFeeds = await AdoriService.fetchYTRssFeeds(networkId, {
        params,
      })
    }

    context.commit('setRssFeeds', rssFeeds.data.reverse())
    context.commit('setRssFeedsCount', rssFeeds.count)
    context.commit('setRssFeedsOffset', rssFeeds.offset)
    for (const rssFeed of rssFeeds.data.reverse()) {
      const rssFeedItems: any = [] // await AdoriService.fetchRssFeedItems(userId, rssFeed.uid)
      context.commit('addRssFeed', {
        rssFeed,
        rssFeedItems,
      })
    }
    context.commit('setRssFeedUids')
  },

  async getRssFeeds(context, { paginate = true, limit = 200, offset = 0, query = null, clear = true }) {
    const networkId = context.getters.networkId
    let rssFeeds: any = []
    if (clear) {
      context.commit('clearRssFeeds')
    }

    let paramsWithQuery: { [key: string]: any } = {
      paginate,
      limit,
      offset,
      query,
    }
    let params: { [key: string]: any } = {
      paginate,
      limit,
      offset,
    }

    if (query) {
      rssFeeds = await AdoriService.fetchRssFeedsSearch(networkId, {
        params: paramsWithQuery,
      })
    } else {
      rssFeeds = await AdoriService.fetchRssFeeds(networkId, {
        params,
      })
    }
    // if (paginate) {

    context.commit('setRssFeeds', rssFeeds.data.reverse())
    context.commit('setRssFeedsCount', rssFeeds.count)
    context.commit('setRssFeedsOffset', rssFeeds.offset)
    for (const rssFeed of rssFeeds.data.reverse()) {
      const rssFeedItems: any = [] // await AdoriService.fetchRssFeedItems(userId, rssFeed.uid)
      context.commit('addRssFeed', {
        rssFeed,
        rssFeedItems,
      })
    }
    context.commit('setRssFeedUids')
  },

  async getRssFeedsBasedOnUids(context, { networkId, feedUids }) {
    let rssFeeds: any = []
    Promise.all(
      feedUids.map(async (feedId: string) => {
        let rssFeed = await AdoriService.fetchRssFeed(networkId, feedId)
        rssFeeds.push(rssFeed)
      })
    )
      .then(() => {
        context.commit('setRssFeedsBasedOnUids', rssFeeds)
      })
      .catch((err: any) => console.error(err))
  },

  async getRssFeedItemsBasedOnUids(context, { networkId, feedUids, tagId }) {
    let rssFeeds: any = []
    Promise.all(
      feedUids.map(async (feedId: string) => {
        let rssFeed = await AdoriService.fetchRssFeedItemsWithoutPagination(networkId, feedId, tagId)
        rssFeeds.push({ feedUid: feedId, data: [] })
        for (let i = 0; i < rssFeed.data.length; i++) {
          let feed = rssFeeds.find((feed: any) => feed.feedUid === feedId)
          feed.data.push(rssFeed.data[i])
        }
      })
    )
      .then(() => {
        context.commit('setRssFeedItemsBasedOnUids', rssFeeds)
      })
      .catch((err: any) => console.error(err))
  },

  searchRssFeedUids(context, searchText) {
    const rssFeedUids = context.state.rssFeedUids
      .map((feedUid: string) => context.getters.rssFeed(feedUid))
      .filter((rssFeed: any) => rssFeed.name.toLowerCase().includes(searchText.toLowerCase()))
      .map((rssFeed: any) => rssFeed.uid)
    context.commit('searchRssFeedUids', rssFeedUids)
  },

  async incrementRssFeedsCount(context) {
    context.commit('setRssFeedsCount', context.state.rssFeedsCount + 1)
  },

  async decrementRssFeedsCount(context) {
    context.commit('setRssFeedsCount', context.state.rssFeedsCount - 1)
  },

  setRssFeedTracks(context, payload) {
    const rssFeedItemsExisting = context.getters.rssFeedItems(payload.rssFeedUid)
    if (rssFeedItemsExisting) {
      context.commit('updateRssFeedItems', {
        rssFeedUid: payload.rssFeedUid,
        rssFeedItems: payload,
        replace: true,
      })
      context.commit('updateAllRssFeedItemsByFeedUid', payload.data)
    } else {
      context.commit(
        'updateAllRssFeedItemsByFeedUid',
        (context.state.rssFeedItemsByFeedUid[context.state.selectedRssFeedUid] &&
          context.state.rssFeedItemsByFeedUid[context.state.selectedRssFeedUid].data) ||
          []
      )
    }
  },

  async updateRssFeedItems(
    context,
    {
      rssFeedUid,
      limit = 10,
      offset = 0,
      order = 'recent_first',
      publishedAfter,
      publishedBefore,
      fromYoutube,
      replace = false,
    }
  ) {
    // TODO: What is exact API params for rssFeedItems
    const rssFeedItemsExisting = context.getters.rssFeedItems(rssFeedUid)
    if (rssFeedItemsExisting) {
      const networkId = context.getters.networkId

      let filter = ''
      if (context.state.selectedFilter === 'ALL_EPISODES') filter = 'all'
      else if (context.state.selectedFilter === 'ALL_PUBLISHED') filter = 'published'
      else if (context.state.selectedFilter === 'ALL_SCHEDULED') filter = 'scheduled'

      const rssFeedItems: any = await AdoriService.fetchRssFeedItems(
        networkId,
        rssFeedUid,
        limit,
        offset,
        filter,
        order,
        publishedAfter,
        publishedBefore,
        fromYoutube
      )

      let data: any = []

      let payload = {
        network_id: networkId,
        trackUid: rssFeedItems.data,
        offset: 0,
        limit: 50,
      }

      // recursive function call until all video clips are fetched
      let fetchVideoClips = async (value: any) => {
        const res: any = await AdoriService.youtube_upload_task_by_uid(value)
        data.push(...res.data)
        if (res.count > data.length) {
          payload = {
            network_id: networkId,
            trackUid: rssFeedItems.data,
            offset: data.length,
            limit: 50,
          }
          await fetchVideoClips(payload)
        }
      }

      await fetchVideoClips(payload)

      // group video clips by trackUid
      let result = data.reduce((map: any, obj: any) => {
        ;(map[obj.trackUid] = map[obj.trackUid] || []).push(obj)
        return map
      }, {})

      // assign video clips to episodes
      for (let i = 0; i < rssFeedItems.data.length; i++) {
        const uid = rssFeedItems.data[i].uid
        rssFeedItems.data[i].videoClips = result[uid] || []
      }

      context.commit('updateRssFeedItems', {
        rssFeedUid,
        rssFeedItems: rssFeedItems,
        replace: replace,
      })
      context.commit('updateAllRssFeedItemsByFeedUid', rssFeedItems.data)
      context.dispatch('filterReplaceAudioProcessUids', rssFeedItems.data)
    } else {
      context.commit(
        'updateAllRssFeedItemsByFeedUid',
        (context.state.rssFeedItemsByFeedUid[context.state.selectedRssFeedUid] &&
          context.state.rssFeedItemsByFeedUid[context.state.selectedRssFeedUid].data) ||
          []
      )
    }
  },
  async getYTfeedUnpublishedEpisodes(context, { rssFeedUid }) {
    if (rssFeedUid) {
      const networkId = context.getters.networkId
      const unpublishedEpisodes: any = await AdoriService.fetchYTfeedUnpublishedEpisodes(networkId, rssFeedUid)
      context.commit('setYTFeedUnpublishedEpisodes', unpublishedEpisodes)
    }
  },

  async getYTfeedAllEpisodes(context, { rssFeedUid }) {
    if (rssFeedUid) {
      const networkId = context.getters.networkId
      const allEpisodes: any = await AdoriService.fetchYTfeedAllEpisodes(networkId, rssFeedUid)
      context.commit('setYTFeedAllEpisodes', allEpisodes)
    }
  },

  async getRssGenres(context) {
    const genres: any = await AdoriService.fetchRssGenres()
    context.commit('addRssGenres', genres)
  },

  async createRssFeed(context, payload) {
    const networkId = context.getters.networkId
    const createdRssFeed: any = await AdoriService.createRssFeed(networkId, payload)
    const limit = 10
    const offset = 0
    const createdRssFeedItems: any = await AdoriService.fetchRssFeedItems(networkId, createdRssFeed.uid, limit, offset)
    context.commit('addRssFeed', {
      rssFeed: createdRssFeed,
      rssFeedItems: createdRssFeedItems,
    })
    context.commit('setRssFeedUids')
    context.commit('setSelectedRssFeedUid', createdRssFeed.uid)
    return createdRssFeed.uid
  },

  async importRssFeed(context, payload) {
    const networkId = context.getters.networkId
    const importedRssFeed: any = await AdoriService.importRssFeed(networkId, payload)
    const limit = 10
    const offset = 0
    const importedRssFeedItems: any = await AdoriService.fetchRssFeedItems(
      networkId,
      importedRssFeed.uid,
      limit,
      offset
    )
    context.commit('addRssFeed', {
      rssFeed: importedRssFeed,
      rssFeedItems: importedRssFeedItems,
    })
    context.commit('setRssFeedUids')
    context.commit('setSelectedRssFeedUid', importedRssFeed.uid)
    return importedRssFeed.uid
  },

  async deleteRssFeed(context, { rssFeedUid, deleteTracks = false }) {
    const networkId = context.getters.networkId
    // const audioTracks = context.getters.rssFeedItems(rssFeedUid).data

    await AdoriService.deleteRssFeed(networkId, rssFeedUid, deleteTracks)
    context.commit('removeRssFeed', { rssFeedUid })

    // for (var i = 0; i < audioTracks.length; i++) {
    //   await context.dispatch('getAudio', audioTracks[i].uid)
    // }
  },

  async updateRssFeed(context, { rssFeedUid, payload }) {
    const networkId = context.getters.networkId
    const updatedRssFeed = await AdoriService.updateRssFeed(networkId, rssFeedUid, payload)
    context.commit('updateRssFeed', { rssFeed: updatedRssFeed })
  },

  async getRssFeedItem(context, { rssFeedUid, rssFeedItemUid }) {
    const networkId = context.getters.networkId
    const audioTrack: any = await AdoriService.fetchRssFeedItem(networkId, rssFeedUid, rssFeedItemUid)
    context.commit('getRssFeedItem', { rssFeedUid, audioTrack })
  },

  async addRssFeedItem(context, { rssFeedUid, rssFeedItemUid, payload }) {
    const networkId = context.getters.networkId
    const newAudioTrack: any = await AdoriService.addRssFeedItem(networkId, rssFeedUid, {
      uid: rssFeedItemUid,
      ...payload,
    })
    context.commit('addRssFeedItem', {
      rssFeedUid,
      newAudioTrack,
    })
  },

  async updateRssFeedItem(context, { rssFeedUid, rssFeedItemUid, payload, itemUid }) {
    const networkId = context.getters.networkId
    const updatedAudioTrack: any = await AdoriService.updateRssFeedItem(networkId, rssFeedUid, itemUid, {
      uid: rssFeedItemUid,
      ...payload,
    })
  },

  async updateRssScheduledFeedItem(context, { rssFeedUid, rssFeedItemUid, payload, itemUid }) {
    const networkId = context.getters.networkId
    const updatedAudioTrack: any = await AdoriService.updateRssScheduledFeedItem(networkId, rssFeedUid, itemUid, {
      uid: rssFeedItemUid,
      ...payload,
    })
  },

  async removeRssFeedItem(context, { rssFeedUid, rssFeedItemUid, deleteTrack = false }) {
    const networkId = context.getters.networkId
    await AdoriService.removeRssFeedItem(networkId, rssFeedUid, rssFeedItemUid, deleteTrack)
    const limit = 10
    const offset = 0
    const updatedRssFeedItems = await AdoriService.fetchRssFeedItems(networkId, rssFeedUid, limit, offset)
    context.commit('updateRssFeedItems', {
      rssFeedUid,
      rssFeedItems: updatedRssFeedItems,
    })
  },

  async validateRssFeed(context, { rssFeedUid }) {
    const networkId = context.getters.networkId
    const validationErrors = await AdoriService.fetchRssFeedValidationErrors(networkId, rssFeedUid)
    context.commit('setValidationErrors', validationErrors)
  },

  async publishRssFeed(context, { rssFeedUid }) {
    const networkId = context.getters.networkId
    const updatedRssFeed = await AdoriService.publishRssFeed(networkId, rssFeedUid)
    context.commit('updateRssFeed', { rssFeed: updatedRssFeed })
  },

  async setSelectedRssFeedUid(context, rssFeedUid) {
    context.commit('setSelectedRssFeedUid', rssFeedUid)
  },

  async setSelectedRssFeedUidForInsights(context, rssFeedUid) {
    context.commit('setSelectedRssFeedUidForInsights', rssFeedUid)
  },

  async clearSelectedRssFeedUid(context) {
    context.commit('setSelectedRssFeedUid', null)
  },

  async setSelectedRssFeedAudioTrackUid(context, audioTrackUid) {
    context.commit('setSelectedRssFeedAudioTrackUid', audioTrackUid)
  },

  async clearSelectedRssFeedAudioTrackUid(context) {
    context.commit('setSelectedRssFeedAudioTrackUid', null)
  },

  async clearValidationErrors(context) {
    context.commit('clearValidationErrors')
  },

  async fetchRssFeedItemsWithNextParam(context, { uid, limit, offset }) {
    const networkId = context.getters.networkId
    let filter = ''
    if (context.state.selectedFilter === 'ALL_EPISODES') filter = 'all'
    else if (context.state.selectedFilter === 'ALL_PUBLISHED') filter = 'published'
    else if (context.state.selectedFilter === 'ALL_SCHEDULED') filter = 'scheduled'

    const data: any = await AdoriService.fetchRssFeedItemsWithNextParam(
      networkId,
      uid,
      limit || 10,
      offset || 0,
      filter
    )
    context.state.rssFeedItemsByFeedUid[uid] = data
    context.commit('updateAllRssFeedItemsByFeedUid', data.data)
  },

  async clearAllRssFeedItemsByFeedUid(context) {
    context.commit('clearAllRssFeedItemsByFeedUid')
  },

  async clearRssFeedItemsByFeedUid(context, selectedRssFeedUid) {
    context.commit('clearRssFeedItemsByFeedUid', selectedRssFeedUid)
  },

  clearRssFeeds(context) {
    context.commit('clearRssFeeds')
  },

  async clearRssFeedsBasedOnTags(context) {
    context.commit('clearRssFeedsBasedOnTags')
  },
}

const rssFeeds: StoreOptions<any> = {
  state,
  getters,
  mutations,
  actions,
}

export default rssFeeds

// Public helpers

export function createRssFeedPoll(store: any, { rssFeedUid }: any, interval: number): number {
  return window.setInterval(() => {
    store.dispatch('updateExistingRssFeedAndItems', { rssFeedUid })
  }, interval)
}

export function destroyRssFeedPoll(handler: number) {
  window.clearInterval(handler)
}
