












import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class BaseNewTooltip extends Vue {
  @Prop(String) text!: string
}
