














































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import axios, { CancelTokenSource, CancelToken } from 'axios'
import debounce from 'lodash.debounce'
import AdoriService from '@/services/adori'
import { isWebLink } from '@/utils/misc'
import { useCreateYoutubeFeed, useGetPublicFeed } from '@/hooks/youtube'
import { QueryClient, useQueryClient } from 'vue-query'
import { SetupContext, watch } from '@vue/composition-api'
import get from 'lodash.get'
import { feedQueryKeys } from '@/hooks/rssFeeds'

@Component({
  setup(props: any, { root }: SetupContext) {
    const queryClient = useQueryClient()
    const { mutate: getPublicFeed, data: publicFeedData, isLoading: isFeedLoading } = useGetPublicFeed()
    const { mutate: createFeed, data: createFeedData, isLoading: isCreateFeedLoading } = useCreateYoutubeFeed()

    watch(publicFeedData, (curVal: any) => {
      const payload = {
        rss: curVal.rss_url,
        imageUrl: curVal.imageUrl || get(curVal.episodes[0], 'imageUrl', null),
      }
      createFeed(payload)
    })
    watch(createFeedData, (curVal: any) => {
      queryClient.invalidateQueries(feedQueryKeys.YTFEED)
      root.$router.push(`/home/podcasts/${curVal.uid}?active=ALL_UNPUBLISHED`)
      root.$store.dispatch('closeModal')
    })

    return {
      queryClient,
      getPublicFeed,
      publicFeedData,
      isFeedLoading,
      createFeed,
      isCreateFeedLoading,
      createFeedData,
    }
  },
})
export default class ImportRss extends Vue {
  @Prop(Boolean) isStudioParent!: boolean

  @Getter networkId!: any

  podcastSearchText: string = ''
  isSearchingPodcasts: boolean = false
  hasSearchedPodcasts: boolean = false
  podcastSearchCancelTokenSource: CancelTokenSource | null = null

  searchResults: any[] = []
  selectedPodcastUid: string = ''
  selectedPodcastRssUrl: string = ''

  selectedEmailForVerification: string = ''
  isRequestingVerificationCode: boolean = false
  hasRequestedVerificationCode: boolean = false
  verificationCode: string = ''
  isRequestingCheckVerificationCode: boolean = false
  hasRequestedCheckVerificationCode: boolean = false
  isVerificationCodeCorrect: boolean = false

  isSubmitting: boolean = false
  totalPodcasts: number = 0
  nextPage: number = 0
  allSearchResults: any[] = []

  moreEmails: boolean = false
  allFeedEmails: any[] = []
  searchingForEmails: boolean = false

  getPublicFeed!: any
  isFeedLoading!: boolean
  isCreateFeedLoading!: boolean
  queryClient!: QueryClient

  async created() {}

  mounted() {
    if (this.$refs._podcastSearch) {
      // @ts-ignore
      this.$refs._podcastSearch.focus()
    }
  }

  async onPodcastSearch() {
    if (this.podcastSearchText.length !== 0) {
      if (this.podcastSearchCancelTokenSource) {
        this.podcastSearchCancelTokenSource.cancel('Search canceled by the user.')
      }
      if (this.selectedPodcastUid.length !== 0) {
        this.selectedPodcastUid = ''
        this.selectedPodcastRssUrl = ''
      }

      try {
        this.selectedPodcastRssUrl = ''
        this.selectedPodcastUid = ''
        this.isSearchingPodcasts = true
        let webLink = isWebLink(this.podcastSearchText)
        this.searchResults = []
        this.allSearchResults = []
        this.podcastSearchCancelTokenSource = axios.CancelToken.source()
        let response: any = null
        if (webLink) {
          response = await this.searchShowsByRss(this.podcastSearchText)
          if (Object.keys(response).length !== 0) {
            this.searchResults = [response]
          }
        } else {
          response = await this.searchShowsByName(this.podcastSearchText, this.podcastSearchCancelTokenSource.token)
          this.searchResults = response.audioCollections.external.results
        }
        // let response: any = await this.searchShowsByName(this.podcastSearchText, this.podcastSearchCancelTokenSource.token)
        // this.searchResults = response.audioCollections.external.results
        this.allSearchResults = [...this.searchResults]
        this.totalPodcasts = response.audioCollections.external.total
        this.nextPage = 0
        this.isSearchingPodcasts = false
        this.hasSearchedPodcasts = true
      } catch (e) {
        this.isSearchingPodcasts = false
        this.hasSearchedPodcasts = true
      }
    }
  }

  async searchShowsByName(podcastSearchText: string, cancelToken: CancelToken) {
    let response = await AdoriService.searchPodcasts(podcastSearchText, cancelToken)
    return response
  }

  async searchShowsByRss(rssSearchText: string) {
    let response = await AdoriService.parseRssUrl({
      rss: rssSearchText,
    })
    return response
  }

  async handleNextClick() {
    if (this.allSearchResults.length > this.nextPage + 10) {
      this.searchResults = this.allSearchResults.slice(this.nextPage + 10, this.nextPage + 20)
      this.nextPage += 10
    } else {
      try {
        this.isSearchingPodcasts = true
        this.podcastSearchCancelTokenSource = axios.CancelToken.source()
        let response: any = await AdoriService.searchPodcasts(
          this.podcastSearchText,
          this.podcastSearchCancelTokenSource.token,
          this.nextPage + 10
        )
        this.allSearchResults = [...this.allSearchResults, ...response.audioCollections.external.results]
        this.searchResults = this.allSearchResults.slice(this.nextPage + 10, this.nextPage + 20)
        this.isSearchingPodcasts = false
        this.nextPage += 10
        this.scrollToTop()
      } catch (e) {
        this.isSearchingPodcasts = false
        this.hasSearchedPodcasts = true
      }
    }
  }

  handlePreviousClick() {
    this.searchResults = this.allSearchResults.slice(this.nextPage - 10, this.nextPage)
    this.nextPage -= 10
    this.scrollToTop()
  }

  handleRedirect() {
    const podcastUrl = this.searchResults[0].rss || this.searchResults[0].rss_url
    if (this.$router.currentRoute.path === '/podcast/search') {
      const redirectUrl =
        window.location.href.includes('dev') || window.location.href.includes('localhost')
          ? `video.dev.adorilabs.com`
          : window.location.href.includes('beta')
          ? `video.beta.adorilabs.com`
          : `video.adorilabs.com`

      window.open(`https://${redirectUrl}/onboarding?feedUrl=${encodeURIComponent(podcastUrl)}`)
      return
    }

    if (this.$router.currentRoute.path === '/onboarding') {
      sessionStorage.setItem('feedUrl', podcastUrl)
      window.location.reload()
    } else {
      this.getPublicFeed({ url: podcastUrl })
    }
  }

  scrollToTop() {
    const searchResults = this.$refs.searchResults as HTMLElement
    searchResults.scrollTo(0, 0)
  }

  debouncedSearch = debounce(() => {
    this.onPodcastSearch()
  }, 600)

  selectPodcastWithRssUrl(selectedPodcast: any) {
    if (selectedPodcast.rss) {
      this.selectedPodcastRssUrl = selectedPodcast.rss
    } else {
      this.selectedPodcastRssUrl = selectedPodcast.rss_url
    }
    if (selectedPodcast.uid) this.selectedPodcastUid = selectedPodcast.uid
  }

  selectPodcast(uid: string) {
    this.selectedPodcastUid = uid
    this.searchResults = this.searchResults.filter((result: any) => result.uid === uid)
  }

  truncate(str: string, count: number) {
    if (str.length < count) return str
    return str.substring(0, count) + '...'
  }

  getPlainFormatText(text: any) {
    if (this.selectedPodcastUid) {
      return this.truncate(text, 300)
    }
    return this.truncate(text.replace(/(<([^>]+)>)/g, ' '), 130)
  }
}
