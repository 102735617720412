import AdoriServiceV6 from '@/services/adori_v6'
import store from '@/store'
import { queryDefaults } from '@/utils/query.config'
import { useQuery } from 'vue-query'

// Query Keys
export enum videoQueryKeys {
  LIB_VIDEOS = 'LIB_VIDEOS',
  PUBLIC_VIDEOS = 'PUBLIC_VIDEOS',
}

// Queries
const getPublicVideos = (networkId: string, params: any) => {
  const searchParams: any = {
    query: params.query,
    limit: params.limit,
    offset: params.offset,
  }
  return AdoriServiceV6.searchVideos(networkId, params.searchLibrary.toLowerCase(), searchParams)
}
const getLibVideos = (networkId: string, params: any) => {
  return AdoriServiceV6.fetchVideos(networkId, params.limit, params.offset)
}

// Hooks
export const useGetPublicVideos = (params: any, { enabled }: any) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useQuery([videoQueryKeys.PUBLIC_VIDEOS, networkId, params], () => getPublicVideos(networkId, params), {
    keepPreviousData: true,
    ...queryDefaults,
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled,
  })
}
export const useGetLibVideos = (params: any, { enabled }: any) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useQuery([videoQueryKeys.LIB_VIDEOS, networkId, params], () => getLibVideos(networkId, params), {
    keepPreviousData: true,
    ...queryDefaults,
    cacheTime: Infinity,
    staleTime: Infinity,
    enabled,
  })
}

// mutations
