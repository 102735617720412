var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      attrs: { hasCustomEvent: true },
      on: {
        closeModal: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "f3 light-gray fw5 di" }, [_vm._v("Share")]),
      ]),
      _c("template", { slot: "body" }, [
        _c(
          "div",
          { staticClass: "flex flex-column w-100 min-w7" },
          [
            _c("div", { staticClass: "share-options" }, [
              _c("div", { staticClass: "share" }, [
                _c(
                  "div",
                  {
                    staticClass: "pointer embed-icon",
                    on: {
                      click: function () {
                        _vm.showEmbedCode = !_vm.showEmbedCode
                        _vm.showQr = false
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/social-share/embed.svg"),
                      },
                    }),
                  ]
                ),
                _c("div", { staticClass: "text" }, [_vm._v("Embed")]),
              ]),
              _c("div", { staticClass: "share" }, [
                _c(
                  "div",
                  {
                    staticClass: "pointer facebook-icon",
                    on: { click: _vm.postToFacebook },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/social-share/facebook.svg"),
                      },
                    }),
                  ]
                ),
                _c("div", { staticClass: "text" }, [_vm._v("Facebook")]),
              ]),
              _c("div", { staticClass: "share" }, [
                _c(
                  "div",
                  {
                    staticClass: "pointer linkedin-icon",
                    on: { click: _vm.postToLinkedIn },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/social-share/linkedin.svg"),
                      },
                    }),
                  ]
                ),
                _c("div", { staticClass: "text" }, [_vm._v("LinkedIn")]),
              ]),
              _c("div", { staticClass: "share" }, [
                _c(
                  "div",
                  {
                    staticClass: "pointer twitter-icon",
                    on: { click: _vm.postToTwitter },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/social-share/twitter.svg"),
                      },
                    }),
                  ]
                ),
                _c("div", { staticClass: "text" }, [_vm._v("Twitter")]),
              ]),
              _c("div", { staticClass: "share" }, [
                _c(
                  "div",
                  {
                    staticClass: "pointer whatsapp-icon",
                    on: { click: _vm.postToWhatsapp },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/social-share/whatsapp.svg"),
                      },
                    }),
                  ]
                ),
                _c("div", { staticClass: "text" }, [_vm._v("WhatsApp")]),
              ]),
              _c("div", { staticClass: "share" }, [
                _c(
                  "div",
                  {
                    staticClass: "pointer reddit-icon",
                    on: { click: _vm.postToReddit },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/social-share/reddit.svg"),
                      },
                    }),
                  ]
                ),
                _c("div", { staticClass: "text" }, [_vm._v("Reddit")]),
              ]),
              _c("div", { staticClass: "share" }, [
                _c(
                  "div",
                  {
                    staticClass: "pointer qr-icon",
                    on: {
                      click: function ($event) {
                        _vm.showQr = !_vm.showQr
                      },
                    },
                  },
                  [
                    _c(
                      "i",
                      { staticClass: "material-icons black f3 v-mid pa1" },
                      [_vm._v("qr_code_2")]
                    ),
                  ]
                ),
                _c("div", { staticClass: "text" }, [
                  _vm._v(_vm._s(_vm.showQr ? "Hide" : "View") + " QR"),
                ]),
              ]),
            ]),
            !_vm.showQr
              ? _c("div", { staticClass: "w-100 embed" }, [
                  !_vm.showEmbedCode
                    ? _c(
                        "div",
                        { staticClass: "flex mb1", staticStyle: { flex: "1" } },
                        [
                          _c("input", {
                            staticClass:
                              "w-100 white bg-dark-gray bn pa2 br2 f6 br--left",
                            attrs: {
                              id: "share-url",
                              type: "text",
                              name: "url",
                              readonly: "",
                            },
                            domProps: { value: _vm.shareURL },
                          }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "bg-adori-red flex items-center pa1 ph2 br2 br--right pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.copyEmbed("share-url")
                                },
                              },
                            },
                            [
                              _c(
                                "i",
                                {
                                  staticClass: "material-icons white",
                                  staticStyle: { "font-size": "18px" },
                                },
                                [_vm._v("assignment")]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _c(
                        "div",
                        { staticClass: "flex mb1", staticStyle: { flex: "1" } },
                        [
                          _c("textarea", {
                            staticClass:
                              "w-100 white bg-dark-gray bn pa2 br2 f6 br--left",
                            attrs: {
                              id: "share-embed",
                              type: "text",
                              name: "embed",
                              rows: "4",
                              readonly: "",
                            },
                            domProps: { value: _vm.shareEmbedCode },
                          }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "bg-adori-red flex items-center pa1 ph2 br2 br--right pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.copyEmbed("share-embed")
                                },
                              },
                            },
                            [
                              _c(
                                "i",
                                {
                                  staticClass: "material-icons white",
                                  staticStyle: { "font-size": "18px" },
                                },
                                [_vm._v("assignment")]
                              ),
                            ]
                          ),
                        ]
                      ),
                ])
              : _vm._e(),
            _vm.showQr
              ? _c("QrCode", { attrs: { url: _vm.shareURL } })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }