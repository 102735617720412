var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showPromt && _vm.isPromtVisible
    ? _c(
        "div",
        {
          staticClass:
            "sticky flex justify-center items-center bg-light-gray no-select ph3 pv2 z-max",
        },
        [
          _c(
            "div",
            { staticClass: "dark-gray lh-copy pv2 mr2" },
            [
              _vm._v("\n    Free trial of "),
              _c("span", { staticClass: "fw5" }, [
                _vm._v(_vm._s(_vm.subscriptionName)),
              ]),
              _vm._v(
                " will expire\n    " +
                  _vm._s(_vm.subsDays && _vm.subsDays.daysRemainingText) +
                  ",\n\n    "
              ),
              _c("BaseButtonAddPaymentMethod", {
                attrs: {
                  planId: _vm.subscription.plan.id,
                  text: "Attach",
                  textButton: "",
                },
              }),
              _vm._v("\n    credit card to keep enjoying the\n    "),
              _c("span", { staticClass: "fw5" }, [
                _vm._v(" Adori Experience!"),
              ]),
            ],
            1
          ),
          _c(
            "i",
            {
              staticClass: "material-icons absolute close-btn f4",
              on: {
                click: function ($event) {
                  _vm.isPromtVisible = false
                },
              },
            },
            [_vm._v(" close ")]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }