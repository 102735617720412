




































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { AudioTags } from '@/components/Monetize/monetize'
import { Action, Getter } from 'vuex-class'
import Invalidate from '@/mixins/Invalidate'
import { audioQueryKeys } from '@/hooks/audio'

@Component
export default class ModalAudioAdEdit extends Mixins(Invalidate) {
  @Prop(Boolean) modalEdit!: boolean
  @Prop() audioAd!: any

  @Getter audioUploadId!: string
  @Getter audioAdEdit!: any

  @Action updateAudioAd!: any
  @Action clearAudioUploader!: any
  @Action getAudioAds!: any

  audioPlayUrl!: string
  audioType: string = 'Promo'
  isUploadingTag: boolean = false
  adName: string = ''
  btnStatus: boolean = true
  validationStatus: boolean = false

  @Watch('audioUploadId')
  setLoader() {
    if (this.audioUploadId !== null) this.btnStatus = false
  }

  get audioTags() {
    return AudioTags
  }

  validation() {
    if (this.adName === '') {
      this.validationStatus = true
      return true
    }
    this.validationStatus = false
    return false
  }

  created() {
    if (this.audioAd) {
      this.adName = this.audioAd.name
      this.audioType = this.audioAd.type
      this.audioPlayUrl = this.audioAd.audioUrl
    } else {
      this.adName = this.audioAdEdit.name
      this.audioType = this.audioAdEdit.type
      this.audioPlayUrl = this.audioAdEdit.audioUrl
    }
  }

  async updateAd() {
    if (this.validation() === true) return
    this.isUploadingTag = true
    const payload = {
      name: this.adName,
      type: this.audioType,
    }
    if (this.audioAd) await this.updateAudioAd({ audioId: this.audioAd.uid, payload })
    else await this.updateAudioAd({ audioId: this.audioAdEdit.uid, payload })
    this.isUploadingTag = false
    this.queryClient.invalidateQueries(audioQueryKeys.AD)
    this.$store.dispatch('closeModal')
  }

  handleUploadAudio() {
    this.$store.dispatch('uploadNewAd')
  }

  beforeDestroy() {
    this.clearAudioUploader()
  }
}
