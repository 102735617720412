var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "f4 light-gray fw5" }, [
          _vm._v("Youtube Batch Settings"),
        ]),
      ]),
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "white" }, [
          _c("div", { staticClass: "privacy mt2" }, [
            _c("label", { staticClass: "f6" }, [_vm._v("Privacy")]),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.privacy,
                    expression: "privacy",
                  },
                ],
                staticClass:
                  "bg-adori-gray white f6 w-100 br2 h2 ba b--gray mt1",
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.privacy = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    function ($event) {},
                  ],
                },
              },
              _vm._l(_vm.privacyListData, function (item, i) {
                return _c(
                  "option",
                  { key: i, domProps: { value: item.value } },
                  [
                    _vm._v(
                      "\n            " + _vm._s(item.title) + "\n          "
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
          _c("div", { staticClass: "category mt3" }, [
            _c("label", { staticClass: "f6" }, [_vm._v("Category")]),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.categoryId,
                    expression: "categoryId",
                  },
                ],
                staticClass:
                  "bg-adori-gray white f6 w-100 br2 h2 ba b--gray mt1 scrollbar",
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.categoryId = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    function ($event) {},
                  ],
                },
              },
              _vm._l(_vm.categoryListData, function (item, i) {
                return _c("option", { key: i, domProps: { value: item.id } }, [
                  _vm._v(
                    "\n            " + _vm._s(item.title) + "\n          "
                  ),
                ])
              }),
              0
            ),
          ]),
          _c("div", { staticClass: "category mt3" }, [
            _c("label", { staticClass: "f6 flex items-center" }, [
              _vm._v("Playlist "),
              _c(
                "i",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.youtubeSignInStatus,
                      expression: "!youtubeSignInStatus",
                    },
                  ],
                  staticClass: "material-icons light-gray f6 pl1 pb1",
                },
                [_vm._v("lock")]
              ),
            ]),
            _c("div", { staticClass: "flex w-100" }, [
              _c(
                "div",
                {
                  class:
                    _vm.youtubeShowSignInStatus || !_vm.isNotALlowedLogin
                      ? "w-100"
                      : "w-60",
                },
                [
                  (
                    _vm.currentYoutubeLogin === "Network Login"
                      ? !_vm.youtubeNetworkSignInStatus
                      : !_vm.youtubeShowSignInStatus
                  )
                    ? _c("div", { staticClass: "mt1" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "w-100 ba b--adori-gray bw1 br2 flex justify-between items-center pl2 h2 adori-gray pointer flex f6",
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "dim",
                                on: {
                                  click: function ($event) {
                                    _vm.currentYoutubeLogin === "Network Login"
                                      ? _vm.triggerYoutubeLogin()
                                      : _vm.triggerShowYoutubeLogin()
                                  },
                                },
                              },
                              [_vm._v("Connect your youtube account")]
                            ),
                            _c("BaseTooltip", {
                              attrs: { info: _vm.loginTooltip },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.playlistId,
                              expression: "playlistId",
                            },
                          ],
                          staticClass:
                            "bg-adori-gray white f6 w-100 br2 h2 ba b--gray mt1 scrollbar",
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.playlistId = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                              function ($event) {},
                            ],
                          },
                        },
                        [
                          _c("option", { domProps: { value: 0 } }, [
                            _vm._v("Select playlist"),
                          ]),
                          _vm._l(_vm.getPlaylist, function (item, i) {
                            return _c(
                              "option",
                              { key: i, domProps: { value: item.id } },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(item.title) +
                                    "\n              "
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                ]
              ),
              !_vm.youtubeShowSignInStatus && _vm.isNotALlowedLogin
                ? _c("div", { staticClass: "w-40" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.currentYoutubeLogin,
                            expression: "currentYoutubeLogin",
                          },
                        ],
                        staticClass:
                          "bg-adori-gray white w-100 f6 br2 h2 ba b--gray mt1 scrollbar",
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.currentYoutubeLogin = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            function ($event) {},
                          ],
                        },
                      },
                      _vm._l(
                        ["Network Login", "Show Login"],
                        function (item, i) {
                          return _c(
                            "option",
                            { key: i, domProps: { value: item } },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(item) +
                                  "\n              "
                              ),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _c("div", { staticClass: "flex mt3" }, [
            _c("div", { staticClass: "mr3" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selfDeclareMadeForKids,
                    expression: "selfDeclareMadeForKids",
                  },
                ],
                staticClass: "mr1",
                attrs: { type: "radio", value: "true", id: "MADE_FOR_KIDS" },
                domProps: {
                  checked: _vm._q(_vm.selfDeclareMadeForKids, "true"),
                },
                on: {
                  change: function ($event) {
                    _vm.selfDeclareMadeForKids = "true"
                  },
                },
              }),
              _c(
                "label",
                {
                  staticClass: "f6 light-gray pointer",
                  attrs: { for: "MADE_FOR_KIDS" },
                },
                [_vm._v("Yes, it's made for kids")]
              ),
            ]),
            _c("div", {}, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selfDeclareMadeForKids,
                    expression: "selfDeclareMadeForKids",
                  },
                ],
                staticClass: "mr1",
                attrs: {
                  type: "radio",
                  value: "false",
                  id: "NOT_MADE_FOR_KIDS",
                },
                domProps: {
                  checked: _vm._q(_vm.selfDeclareMadeForKids, "false"),
                },
                on: {
                  change: function ($event) {
                    _vm.selfDeclareMadeForKids = "false"
                  },
                },
              }),
              _c(
                "label",
                {
                  staticClass: "f6 light-gray pointer",
                  attrs: { for: "NOT_MADE_FOR_KIDS" },
                },
                [_vm._v("No, it's not made for kids")]
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "flex justify-center mt4" },
            [
              _c("BaseButtonRed", {
                attrs: {
                  text: "Save",
                  onClick: function () {
                    _vm.handleSave()
                  },
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }