var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    [
      _c("template", { slot: "header" }),
      _c("template", { slot: "body" }, [
        _c(
          "div",
          { staticClass: "flex flex-column", staticStyle: { width: "750px" } },
          [
            _c(
              "div",
              {
                staticClass: "light-gray lh-copy ph2 ph3-ns pv3",
                class: _vm.isSubmitting ? "o-50 not-allowed" : "",
              },
              [
                _c("div", { staticClass: "flex justify-between" }, [
                  _c("div", { staticClass: "light-gray mb2" }, [
                    _vm._v("Find your podcast"),
                  ]),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://www.listennotes.com/",
                        target: "_blank",
                      },
                    },
                    [
                      _c("img", {
                        attrs: { src: require("@/assets/listennotes.png") },
                      }),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "flex flex-wrap flex-column flex-row-l justify-between-l items-start-l mb3",
                  },
                  [
                    _c("div", { staticClass: "flex items-center mv2" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.podcastSearchText,
                            expression: "podcastSearchText",
                          },
                        ],
                        ref: "_podcastSearch",
                        staticClass:
                          "input-reset bg-adori-light-gray bn h-50 light-gray pa3 br3 br--left",
                        staticStyle: { width: "665px" },
                        attrs: {
                          type: "search",
                          placeholder:
                            "Search for your podcasts or paste rss feed URL",
                          autofocus: "",
                        },
                        domProps: { value: _vm.podcastSearchText },
                        on: {
                          keyup: function ($event) {
                            return _vm.debouncedSearch()
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.podcastSearchText = $event.target.value
                          },
                        },
                      }),
                      _c(
                        "button",
                        {
                          staticClass:
                            "h-50 ph3 bg-adori-red bn light-gray flex justify-center items-center br3 br--right link pointer dim",
                          class: _vm.isSearchingPodcasts ? "o-50" : "",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.onPodcastSearch()
                            },
                          },
                        },
                        [
                          !_vm.isSearchingPodcasts
                            ? _c("i", { staticClass: "f3 material-icons" }, [
                                _vm._v("search"),
                              ])
                            : _c("div", {
                                staticClass: "loader loader-inline",
                              }),
                        ]
                      ),
                    ]),
                  ]
                ),
                _vm.hasSearchedPodcasts
                  ? _c(
                      "div",
                      {
                        ref: "searchResults",
                        staticClass: "max-h-podcast overflow-y-auto scrollbar",
                      },
                      [
                        !_vm.isSearchingPodcasts &&
                        _vm.searchResults.length === 0
                          ? _c("div", [
                              _c(
                                "div",
                                { staticClass: "silver mb3 lh-copy fw5 mr4-l" },
                                [
                                  _vm._v(
                                    "\n              We were unable to find this podcast in our records. May we ask you to check for any possible errors and\n              try searching again?\n            "
                                  ),
                                ]
                              ),
                            ])
                          : _vm.searchResults.length === 1
                          ? _c("div", [
                              _c("div", { staticClass: "w-100 mb3" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "pa3 bg-adori-light-gray",
                                    class:
                                      _vm.searchResults[0].uid ===
                                        _vm.selectedPodcastUid ||
                                      _vm.searchResults[0].rss_url ===
                                        _vm.selectedPodcastRssUrl
                                        ? "b--adori-red ba bw1 br3"
                                        : "link pointer dim",
                                    on: {
                                      click: function ($event) {
                                        _vm.selectedPodcastUid
                                          ? _vm.selectPodcast(
                                              _vm.searchResults[0].uid
                                            )
                                          : _vm.selectPodcastWithRssUrl(
                                              _vm.searchResults[0]
                                            )
                                      },
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "f5 mb3" }, [
                                      _vm._v(_vm._s(_vm.searchResults[0].name)),
                                    ]),
                                    _c("div", { staticClass: "cf" }, [
                                      _c(
                                        "div",
                                        { staticClass: "fl w-30 mw4" },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: _vm.searchResults[0]
                                                .imageUrl,
                                            },
                                          }),
                                        ]
                                      ),
                                      _c("div", { staticClass: "fl w-70" }, [
                                        _vm.searchResults[0].publisher
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "f6 fw5 silver ml3 mb2",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      By " +
                                                    _vm._s(
                                                      _vm.searchResults[0]
                                                        .publisher
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.searchResults[0].description
                                          ? _c("div", {
                                              staticClass:
                                                "f6 light-gray ml3 mb2",
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.getPlainFormatText(
                                                    _vm.searchResults[0]
                                                      .description
                                                  )
                                                ),
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.searchResults[0].totalTracks
                                          ? _c(
                                              "div",
                                              { staticClass: "f6 silver ml3" },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.searchResults[0]
                                                        .totalTracks
                                                    ) +
                                                    " episodes\n                    "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]),
                                    ]),
                                  ]
                                ),
                              ]),
                            ])
                          : _c("div", [
                              _c(
                                "div",
                                { staticClass: "podcasts mb3" },
                                _vm._l(_vm.searchResults, function (podcast) {
                                  return _c(
                                    "div",
                                    {
                                      key: podcast.uid,
                                      staticClass: "pa3 bg-adori-light-gray h5",
                                      class:
                                        podcast.uid === _vm.selectedPodcastUid
                                          ? "b--adori-red ba bw1 br3"
                                          : "link pointer dim",
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectPodcast(podcast.uid)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "f5 mb3 mw5-l truncate-l",
                                        },
                                        [_vm._v(_vm._s(podcast.name))]
                                      ),
                                      _c("div", { staticClass: "cf" }, [
                                        _c("div", { staticClass: "fl w-30" }, [
                                          _c("img", {
                                            attrs: { src: podcast.imageUrl },
                                          }),
                                        ]),
                                        _c("div", { staticClass: "fl w-70" }, [
                                          podcast.publisher
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "f6 fw5 silver ml3 mb2 mw5-l truncate-l",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      By " +
                                                      _vm._s(
                                                        podcast.publisher
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          podcast.description
                                            ? _c("div", {
                                                staticClass:
                                                  "f6 light-gray ml3 mb2",
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.getPlainFormatText(
                                                      podcast.description
                                                    )
                                                  ),
                                                },
                                              })
                                            : _vm._e(),
                                          podcast.totalTracks
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "f6 silver ml3",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      podcast.totalTracks
                                                    ) + " episodes"
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ]),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ]),
                      ]
                    )
                  : _vm._e(),
                !(_vm.selectedPodcastUid || _vm.selectedPodcastRssUrl) &&
                _vm.searchResults.length > 1
                  ? _c(
                      "div",
                      { staticClass: "flex mt3 justify-between items-center" },
                      [
                        _c("BaseButtonBorder", {
                          attrs: {
                            text: "Prev",
                            disabled: _vm.nextPage === 0,
                            onClick: _vm.handlePreviousClick,
                          },
                        }),
                        _c("div", { staticClass: "silver f6" }, [
                          _vm._v(
                            "\n            Page\n            " +
                              _vm._s(
                                (_vm.nextPage / 10 + 1).toLocaleString(
                                  "en-US"
                                ) +
                                  " of " +
                                  Math.ceil(
                                    _vm.totalPodcasts / 10
                                  ).toLocaleString("en-US")
                              ) +
                              "\n          "
                          ),
                        ]),
                        _c("BaseButtonBorder", {
                          attrs: {
                            text: "Next",
                            disabled: _vm.nextPage >= _vm.totalPodcasts - 10,
                            onClick: _vm.handleNextClick,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.hasSearchedPodcasts &&
                (_vm.selectedPodcastUid || _vm.selectedPodcastRssUrl) &&
                _vm.needsEmailVerification &&
                _vm.allFeedEmails.length !== 0
                  ? _c("div", { staticClass: "flex flex-column" }, [
                      _c("div", [
                        _c("div", { staticClass: "light-gray mb3" }, [
                          _vm._v("Verify your ownership of this podcast"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "flex justify-left items-center mb1" },
                          [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectedEmailForVerification,
                                    expression: "selectedEmailForVerification",
                                  },
                                ],
                                staticClass:
                                  "input-reset light-gray bn br2 ph3 pv2 shadow-2 db w5 mr2 arrow",
                                attrs: {
                                  disabled: _vm.hasRequestedVerificationCode,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.selectedEmailForVerification = $event
                                      .target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                },
                              },
                              _vm._l(_vm.allFeedEmails, function (email) {
                                return _c(
                                  "option",
                                  { key: email, domProps: { value: email } },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(email) +
                                        "\n                "
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                            _c("BaseButtonBorder", {
                              attrs: {
                                text: "Request verification code",
                                disabled: _vm.hasRequestedVerificationCode,
                                onClick: _vm.handleRequestVerficationCode,
                              },
                            }),
                            _vm.isRequestingVerificationCode
                              ? _c("div", {
                                  staticClass: "loader loader-inline ml2",
                                })
                              : _vm._e(),
                            _vm.hasRequestedVerificationCode
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-center",
                                  },
                                  [
                                    _c(
                                      "i",
                                      { staticClass: "material-icons ml2" },
                                      [_vm._v("check_circle")]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                      !this.selectedPodcastUid
                        ? _c("div", { staticClass: "flex f6 silver mb3" }, [
                            _vm._v(
                              "\n            We have updated the email list! Can't find your registered email?\n            "
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "ml2 pointer underline",
                                on: { click: _vm.gotoHelp },
                              },
                              [_vm._v("Learn more")]
                            ),
                          ])
                        : !_vm.moreEmails
                        ? _c("div", { staticClass: "flex mb3" }, [
                            _c("span", { staticClass: "silver f6" }, [
                              _vm._v(
                                "Did you update the email of the feed recently?"
                              ),
                            ]),
                            _vm.searchingForEmails
                              ? _c("div", { staticClass: "f6 silver ml2" }, [
                                  _vm._v("Updating feed"),
                                ])
                              : _c(
                                  "span",
                                  {
                                    staticClass:
                                      "white o-70 underline ml2 pointer glow f6",
                                    on: { click: _vm.handleMoreFeedEmails },
                                  },
                                  [_vm._v("Click here to update")]
                                ),
                            _vm.searchingForEmails
                              ? _c("div", {
                                  staticClass: "loader loader-inline ml2",
                                })
                              : _vm._e(),
                          ])
                        : _c("div", { staticClass: "flex f6 silver mb3" }, [
                            _vm._v(
                              "\n            We have updated the email list! Can't find your registered email?\n            "
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "ml2 pointer underline",
                                on: { click: _vm.gotoHelp },
                              },
                              [_vm._v("Learn more")]
                            ),
                          ]),
                      !_vm.hasRequestedVerificationCode
                        ? _c("div", [
                            _c("div", { staticClass: "silver mb3" }, [
                              _vm._v(
                                "Already have a verification code? Enter it here to verify."
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex justify-left items-center mb3",
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value: _vm.verificationCode,
                                      expression: "verificationCode",
                                      modifiers: { trim: true },
                                    },
                                  ],
                                  staticClass:
                                    "db bg-adori-very-light-gray br2 ph3 pv2 gray f6 white w5 bw0 mr2",
                                  attrs: {
                                    type: "text",
                                    placeholder: "Enter your verfication code",
                                  },
                                  domProps: { value: _vm.verificationCode },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.verificationCode =
                                        $event.target.value.trim()
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                                _c("BaseButtonBorder", {
                                  attrs: {
                                    text: "Verify your code",
                                    disabled: _vm.verificationCode.length === 0,
                                    onClick:
                                      _vm.handleRequestCheckVerficationCode,
                                  },
                                }),
                                _vm.isRequestingCheckVerificationCode
                                  ? _c("div", {
                                      staticClass: "loader loader-inline ml2",
                                    })
                                  : _vm._e(),
                                _vm.hasRequestedCheckVerificationCode
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex items-center justify-center",
                                      },
                                      [
                                        _c(
                                          "i",
                                          { staticClass: "material-icons ml2" },
                                          [_vm._v("check_circle")]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm.hasRequestedVerificationCode
                        ? _c(
                            "div",
                            {
                              staticClass: "flex justify-left items-center mb3",
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.verificationCode,
                                    expression: "verificationCode",
                                    modifiers: { trim: true },
                                  },
                                ],
                                staticClass:
                                  "db bg-adori-very-light-gray br2 ph3 pv2 gray f6 white w5 bw0 mr2",
                                attrs: {
                                  type: "text",
                                  placeholder: "Enter your verfication code",
                                },
                                domProps: { value: _vm.verificationCode },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.verificationCode =
                                      $event.target.value.trim()
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                              _c("BaseButtonBorder", {
                                attrs: {
                                  text: "Verify your code",
                                  disabled: _vm.verificationCode.length === 0,
                                  onClick:
                                    _vm.handleRequestCheckVerficationCode,
                                },
                              }),
                              _vm.isRequestingCheckVerificationCode
                                ? _c("div", {
                                    staticClass: "loader loader-inline ml2",
                                  })
                                : _vm._e(),
                              _vm.hasRequestedCheckVerificationCode &&
                              !_vm.isRequestingCheckVerificationCode
                                ? _c("div", [
                                    _vm.isVerificationCodeCorrect
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "flex items-center justify-center",
                                          },
                                          [
                                            _c(
                                              "i",
                                              {
                                                staticClass:
                                                  "material-icons ml2",
                                              },
                                              [_vm._v("check_circle")]
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass:
                                              "flex items-center justify-center",
                                          },
                                          [
                                            _c(
                                              "i",
                                              {
                                                staticClass:
                                                  "material-icons ml2",
                                              },
                                              [_vm._v("not_interested")]
                                            ),
                                          ]
                                        ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ])
                  : _vm.allFeedEmails.length === 0 && _vm.selectedPodcastUid
                  ? _c("div", [
                      _vm._v(
                        "\n          This podcast feed does not appear to have a published email address to send a code to. Please email\n          "
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "link pointer dim silver underline",
                          attrs: { href: "mailto:hello@adorilabs.com" },
                        },
                        [_vm._v("hello@adorilabs.com")]
                      ),
                      _vm._v(
                        "\n          to complete the verification and import of this podcast.\n        "
                      ),
                    ])
                  : _vm._e(),
                _vm.hasSearchedPodcasts &&
                (_vm.selectedPodcastUid || _vm.selectedPodcastRssUrl)
                  ? _c(
                      "div",
                      { staticClass: "flex justify-end items-center mt2" },
                      [
                        _vm.hasAlreadyImportedPodcast
                          ? _c("div", { staticClass: "adori-red mr2" }, [
                              _vm._v(
                                "\n            This podcast has already been imported. Search to import another podcast.\n          "
                              ),
                            ])
                          : _c(
                              "div",
                              { staticClass: "flex justify-end items-center" },
                              [
                                _vm.isSubmitting
                                  ? _c("div", {
                                      staticClass: "loader loader-inline mh3",
                                    })
                                  : _vm._e(),
                                _c("BaseButtonRed", {
                                  staticClass:
                                    "dib f6 br2 bn pv2 ph3 light-gray bg-adori-red btn-shadow",
                                  class: _vm.isSubmitting
                                    ? "o-40 not-allowed"
                                    : "o-90 glow pointer",
                                  attrs: {
                                    text: "Import this podcast",
                                    disabled:
                                      _vm.needsEmailVerification &&
                                      !(
                                        _vm.hasRequestedCheckVerificationCode &&
                                        _vm.isVerificationCodeCorrect
                                      ),
                                    onClick: _vm.handleImportPodcast,
                                  },
                                }),
                              ],
                              1
                            ),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }