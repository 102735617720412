var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-column justify-center items-center" },
    [
      _c("div", { ref: "qrCode", attrs: { id: "qr-code" } }),
      _c(
        "div",
        { staticClass: "w-40 flex items-center justify-between mt3" },
        [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.extension,
                  expression: "extension",
                },
              ],
              staticClass: "bg-adori-gray white f6 w-50 br2 h2 ba b--gray",
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.extension = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
              },
            },
            [
              _c("option", { attrs: { value: "svg" } }, [_vm._v("SVG")]),
              _c("option", { attrs: { value: "png" } }, [_vm._v("PNG")]),
              _c("option", { attrs: { value: "jpeg" } }, [_vm._v("JPEG")]),
              _c("option", { attrs: { value: "webp" } }, [_vm._v("WEBP")]),
            ]
          ),
          _c("BaseButtonRed", {
            attrs: { text: "Download", onClick: _vm.download },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }