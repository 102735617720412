var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    { staticClass: "light-gray db", attrs: { for: _vm.forId } },
    [
      _c("i", { staticClass: "material-icons light-gray v-mid f5" }, [
        _vm._v(_vm._s(_vm.icon)),
      ]),
      _c(
        "p",
        {
          staticClass: "dib ml1 mb0 f6",
          class: _vm.color ? "" + _vm.color : "light-gray",
        },
        [_vm._v("\n    " + _vm._s(_vm.text) + "\n  ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }