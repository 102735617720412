








import { Component, Prop, Vue } from 'vue-property-decorator'
import { tagTypeMeta } from '@/utils/tag'

import previewplayer_locate from '@/assets/previewplayer/locate.svg'
import previewplayer_call from '@/assets/previewplayer/call.svg'
import previewplayer_link from '@/assets/previewplayer/link.svg'
import previewplayer_submit_poll from '@/assets/previewplayer/submit_poll.svg'
import previewplayer_like from '@/assets/previewplayer/like.svg'
import previewplayer_audio from '@/assets/previewplayer/audio.svg'
import previewplayer_displayicon from '@/assets/previewplayer/displayicon.svg'
import previewplayer_videoicon from '@/assets/previewplayer/videoicon.svg'
import previewplayer_buy_new from '@/assets/previewplayer/buy_new.svg'

@Component
export default class BaseAdoriTagButtons extends Vue {
  @Prop(String) tagType!: string
  @Prop(Boolean) saveable!: boolean
  @Prop(Boolean) shareable!: boolean
  @Prop(Boolean) bigger!: boolean

  get tagTypeMeta() {
    return tagTypeMeta.filter((t: any) => t.name === this.tagType)[0]
  }

  get tagTypeSrc() {
    const tagTypeToAsset: any = {
      message: previewplayer_like,
      location: previewplayer_locate,
      call: previewplayer_call,
      'Web link': previewplayer_link,
      poll: previewplayer_submit_poll,
      photo: previewplayer_like,
      audio: previewplayer_audio,
      display_ad: previewplayer_displayicon,
      video_ad: previewplayer_videoicon,
      buy: previewplayer_buy_new,
      artag: previewplayer_link,
    }
    return tagTypeToAsset[this.tagType]
  }
}
