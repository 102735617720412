





























































import { Component, Mixins, Vue } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import { NetworkQueryKeys } from '@/hooks/network'
import Invalidate from '@/mixins/Invalidate'

@Component
export default class ModalAddShowMember extends Mixins(Invalidate) {
  @Getter showroles!: any
  @Getter networkId!: any
  @Getter networkType!: any
  @Getter isYoutube!: Boolean

  @Action addShowUser!: any
  @Action getShowUsers!: any

  @Action addShowYoutubeUser!: any
  @Action getShowYoutubeUsers!: any

  @Action setShowLoader!: any
  @Action closeModal!: any
  @Action pushNotification!: any

  emailId = ''
  isValidEmail = true
  isEmptyEmail = false
  selectedRole = ''
  isSubmitting: boolean = false
  isNetworkOwner: boolean = false
  selectedPodcastUid: string = ''

  async created() {
    this.selectedPodcastUid = this.$store.state.modal.addShowMember.podcastUid
    this.selectedRole = this.filteredRoles[0].name
  }

  get filteredRoles() {
    if (this.isYoutube) {
      return [{ name: 'Select role' }, ...this.showroles].filter((role: any) => role.ytEnabled)
    }
    return [{ name: 'Select role' }, ...this.showroles] // .filter((role: any) => role.name !== 'Network Owner') //TODO: Check if there can be only one Show Admin?
  }

  get descriptions() {
    if (this.isYoutube) {
      return this.filteredRoles.filter((role: any) => role.name === this.selectedRole)[0].ytDescription
    }
    return this.filteredRoles.filter((role: any) => role.name === this.selectedRole)[0].description
  }

  get disableAddMember() {
    return !(this.isValidEmail && this.emailId && this.selectedRole !== 'Select role')
  }

  emailChange() {
    if (this.emailId) {
      this.isEmptyEmail = false
    } else {
      this.isEmptyEmail = true
    }
    /* eslint-disable no-useless-escape */
    const re =
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    this.isValidEmail = this.emailId ? re.test(this.emailId) : true
  }

  async addShowMember() {
    this.setShowLoader(true)
    if (!this.emailId) {
      this.isEmptyEmail = true
    }
    if (this.isYoutube) {
      await this.addShowYoutubeUser({
        email: this.emailId,
        role: this.selectedRole,
        feedUid: this.selectedPodcastUid,
      })
      this.queryClient.invalidateQueries([NetworkQueryKeys.SHOWUSERS, this.networkId, this.selectedPodcastUid])
    } else {
      await this.addShowUser({
        email: this.emailId,
        role: this.selectedRole,
        feedUid: this.selectedPodcastUid,
      })
      this.queryClient.invalidateQueries([NetworkQueryKeys.SHOWUSERS, this.networkId, this.selectedPodcastUid])
    }

    this.closeModal()
    this.setShowLoader(false)
  }
}
