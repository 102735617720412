






































































































import { Vue, Component } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import TheToggleButton from '@/components/Common/TheToggleButton.vue'

// constants
import { privacyList, categoryList } from '@/components/Publish/publish'
import get from 'lodash.get'
import { CURRENT_ENV, ENVS } from '@/constants/env'

@Component({
  components: {
    TheToggleButton,
  },
})
export default class ModalYoutubeBatchSetting extends Vue {
  @Getter categories!: any
  @Getter playlist!: any
  @Getter selectedEpisodeSettings!: any
  @Getter networkId!: string
  @Getter networkType!: string
  @Getter signinStatus!: any
  @Getter createdYoutubeFeed!: any

  @Action closeModal!: any
  @Action ytModalSetting!: any
  @Action youtubePlaylist!: any

  selfDeclareMadeForKids = null
  playlistId: number = 0
  currentYoutubeLogin = 'Network Login'
  categoryId: number = 1
  privacy: string = 'public'

  triggerYoutubeLogin() {
    sessionStorage.setItem('youtubeAuth', 'YES')
    this.$store.commit('setTriggerLogin')
  }
  triggerShowYoutubeLogin() {
    sessionStorage.setItem('showLogin', 'YES')
    this.$store.commit('setTriggerLogin')
  }

  get loginTooltip() {
    return this.currentYoutubeLogin === 'Network Login'
      ? 'Connect Youtube Channel to your Network, By default all the episode will be published to connected channel'
      : 'Connect Youtube channel to this standalone podcast, Then all the episode of this podcast will be published to connected channel '
  }

  get ytFeedUid() {
    return get(this.createdYoutubeFeed, 'uid', '') || sessionStorage.getItem('feedUid')
  }

  get getPlaylist() {
    return this.signinStatus[this.ytFeedUid]?.youtube
      ? this.playlist[this.ytFeedUid] || []
      : this.playlist[this.networkId] || []
  }

  get isNotALlowedLogin() {
    return !['YOUTUBE_METERED', 'YOUTUBE', 'YOUTUBE_PRO'].includes(this.networkType) && CURRENT_ENV !== ENVS.dev
  }

  get youtubeNetworkSignInStatus() {
    return this.signinStatus[this.networkId]?.youtube || false
  }
  get youtubeShowSignInStatus() {
    return (this.ytFeedUid && this.signinStatus[this.ytFeedUid]?.youtube) || false
  }

  get youtubeSignInStatus() {
    return this.ytFeedUid && this.signinStatus[this.ytFeedUid]?.youtube
      ? this.signinStatus[this.ytFeedUid]?.youtube
      : this.signinStatus[this.networkId]?.youtube
      ? this.signinStatus[this.networkId]?.youtube
      : false
  }

  get privacyListData() {
    return privacyList
  }

  get categoryListData() {
    return categoryList
  }

  handleSave() {
    Object.keys(this.selectedEpisodeSettings).forEach((guid) => {
      this.ytModalSetting({
        guid: guid,
        privacy: this.privacy,
        category: this.categoryId,
        playlistId: this.playlistId,
        isMadeForKids: this.selfDeclareMadeForKids,
      })
    })

    this.closeModal()
  }

  async created() {
    if (this.youtubeSignInStatus && this.getPlaylist.length === 0) {
      const payload: any = { networkId: this.networkId }
      this.signinStatus[this.ytFeedUid]?.youtube && (payload['showId'] = this.ytFeedUid)
      await this.youtubePlaylist(payload)
    }
  }

  mounted() {
    this.youtubeShowSignInStatus && (this.currentYoutubeLogin = 'Show Login')
  }
}
