





















import { Vue, Prop, Component } from 'vue-property-decorator'

@Component
export default class BaseThumbnailShow extends Vue {
  @Prop() src!: any
  @Prop(Boolean) big!: boolean
  @Prop(String) hoverText!: string
  @Prop(String) hoverIcon!: string
  @Prop(Function) onClick!: () => void

  get width() {
    if (this.big) return ' w196 '
    return ' w70 '
  }

  get height() {
    if (this.big) return ' h196 '
    return ' h70 '
  }

  get fontSize() {
    if (this.big) return ' f-superheadline '
    return ' f1 '
  }

  get dimensions() {
    return this.width + ' ' + this.height
  }
}
