var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group" }, [
    _c("div", { staticClass: "flex justify-between items-center silver" }, [
      _c(
        "button",
        {
          staticClass: "pagination-btn br-100 pa0",
          class:
            _vm.selectedRangeStart === 1
              ? "bn bg-black-40"
              : "ba b--adori-gray bg-dark-gray pointer dim",
          attrs: { disabled: _vm.selectedRangeStart === 1 },
          on: {
            click: function ($event) {
              return _vm.handleNavigation("prev")
            },
          },
        },
        [
          _c("i", { staticClass: "material-icons light-gray f3 v-mid" }, [
            _vm._v(" arrow_left "),
          ]),
        ]
      ),
      _c("p", { staticClass: "silver" }, [
        _c("span", { staticClass: "light-gray" }, [
          _vm._v(
            _vm._s(_vm.selectedRangeStart) +
              " - " +
              _vm._s(_vm.selectedRangeEnd)
          ),
        ]),
        _vm._v("\n       of total \n      "),
        _c("span", { staticClass: "light-gray" }, [
          _vm._v(_vm._s(_vm.totalImages.toLocaleString("en-US"))),
        ]),
        _vm._v("\n       images\n    "),
      ]),
      _c(
        "button",
        {
          staticClass: "pagination-btn br-100 pa0",
          class:
            _vm.selectedRangeEnd >= _vm.totalImages
              ? "bn bg-black-40"
              : "ba b--adori-gray bg-dark-gray pointer dim",
          attrs: { disabled: _vm.selectedRangeEnd >= _vm.totalImages },
          on: {
            click: function ($event) {
              return _vm.handleNavigation("next")
            },
          },
        },
        [
          _c("i", { staticClass: "material-icons light-gray f3 v-mid" }, [
            _vm._v(" arrow_right "),
          ]),
        ]
      ),
    ]),
    _c("div", [
      _c(
        "div",
        { staticClass: "masonry-wall" },
        _vm._l(_vm.masonryColumns, function (column) {
          return _c(
            "div",
            { staticClass: "masonry-column" },
            _vm._l(column, function (item) {
              return _c("div", { key: item.id, staticClass: "masonry-item" }, [
                _c("div", { staticClass: "img-opt relative hide-child" }, [
                  _c("img", {
                    staticClass: "img fade",
                    class:
                      _vm.previewImageSrc === item.url
                        ? "ba b--adori-red-imp "
                        : "",
                    attrs: {
                      "data-src": item.previewUrl,
                      src: item.previewUrl,
                      lazy: "loaded",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.loadAsset(item)
                      },
                    },
                  }),
                  !!item.user
                    ? _c(
                        "a",
                        {
                          staticClass:
                            "absolute white bg-black-50 author-name w-100 no-underline f6 child",
                          attrs: {
                            href:
                              item.user.url +
                              "?utm_source=adori&utm_medium=referral",
                            target: "_blank",
                          },
                        },
                        [_vm._v(_vm._s(item.user.name))]
                      )
                    : _vm._e(),
                  _c(
                    "i",
                    {
                      staticClass:
                        "absolute right-1 bg-black-50 material-icons light-gray f4 v-top pa1 child br3",
                      staticStyle: { top: "1px", right: "1px" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("viewImages", item.url)
                        },
                      },
                    },
                    [_vm._v("\n              visibility\n            ")]
                  ),
                ]),
              ])
            }),
            0
          )
        }),
        0
      ),
    ]),
    _c("a", {
      ref: "imageDownloadRef",
      staticClass: "dn",
      attrs: { download: "img", target: "_blank" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }