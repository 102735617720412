var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "light-gray f3 fw5" }, [
          _vm._v("Validate and publish podcast"),
        ]),
      ]),
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "flex flex-column" }, [
          !_vm.validationErrors
            ? _c("p", { staticClass: "f5 gray measure lh-copy mb4" }, [
                _vm._v(
                  "\n        This action will run a validation check and update your podcast. It may take a few hours for your changes to\n        reflect on external hosts.\n      "
                ),
              ])
            : _c(
                "div",
                { staticClass: "mb4", staticStyle: { width: "500px" } },
                [
                  _c("p", { staticClass: "f5 silver measure" }, [
                    _vm._v("Validation errors"),
                  ]),
                  _c("p", { staticClass: "f5 gray measure mb4" }, [
                    _vm._v("Please fix these errors and try publishing again."),
                  ]),
                  _c("p", { staticClass: "silver measure" }, [
                    _vm._v("Podcast details"),
                  ]),
                  _c(
                    "ul",
                    { staticClass: "pb3" },
                    _vm._l(_vm.validationErrors.feed, function (error, k) {
                      return _c(
                        "li",
                        {
                          key: "feed-error-" + k,
                          staticClass: "f5 gray measure",
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(error.message) +
                              "\n          "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                  _c("p", { staticClass: "silver measure" }, [
                    _vm._v("Episode details"),
                  ]),
                  _vm._l(
                    _vm.validationErrors.audiotracks,
                    function (audiotrack, i) {
                      return _c(
                        "div",
                        {
                          key: "audiotrack-" + i,
                          staticClass: "f5 gray measure",
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.audioTrackName(Object.keys(audiotrack)[0])
                              ) +
                              "\n          "
                          ),
                          _c(
                            "ul",
                            _vm._l(
                              Object.values(audiotrack)[0],
                              function (error, j) {
                                return _c(
                                  "li",
                                  { key: "audiotrack-error-" + j },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(error.message) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]
                      )
                    }
                  ),
                ],
                2
              ),
          _c(
            "div",
            { staticClass: "flex justify-between items-center" },
            [
              _c("BaseButtonBorder", {
                attrs: { text: "Cancel", onClick: _vm.handleCancelClicked },
              }),
              _c(
                "div",
                { staticClass: "flex justify-end items-center" },
                [
                  _vm.isSubmitting
                    ? _c("div", { staticClass: "loader loader-inline mh3" })
                    : _vm._e(),
                  _c("BaseButtonRed", {
                    staticClass: "mr3",
                    attrs: {
                      text: "Validate and publish",
                      disabled:
                        _vm.isSubmitting || _vm.validationErrors !== null,
                      onClick: _vm.handleValidateAndPublishClicked,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }