export enum AudioTags {
  PROMO = 'Promo',
  OUTRO = 'Outro',
  INTRO = 'Intro',
  LIVEREAD = 'Live Read',
  INTERNAL = 'internal',
  OTHER = 'Other',
}

export enum selectionType {
  AUDIO_ADS = 'AUDIO_ADS',
  COMING_SOON = 'COMING_SOON',
}
