var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      attrs: { hasCustomEvent: "" },
      on: {
        closeModal: function ($event) {
          return _vm.$emit("closeModal")
        },
      },
    },
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "flex items-center" }, [
          _c("img", {
            staticClass: "mr2",
            attrs: { src: require("@/assets/youtube/error.svg") },
          }),
          _c("div", { staticClass: "f4 light-gray fw5 di" }, [
            _vm._v("Too many requests"),
          ]),
        ]),
      ]),
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "f5 silver max-w5 lh-copy" }, [
          _vm._v(
            "\n      50 auto-pick images are allowed in one minute. Please wait for " +
              _vm._s(_vm.secondsLeft) +
              " seconds before making another\n      request.\n    "
          ),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }