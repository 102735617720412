

















































































































































































































































































































































































































































































































































































import { Component, Vue, Mixins } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import { validationMixin } from 'vuelidate'
import isEmpty from 'lodash.isempty'
import { required, maxLength, email } from 'vuelidate/lib/validators'
import ImagePreviewer from '@/mixins/ImagePreviewer'
import AdoriService from '@/services/adori'
import HTMLEditor from '@/components/Common/HTMLEditor.vue'
import ImageGallery from '@/components/Common/Images/ImageGallery.vue'
import Invalidate from '@/mixins/Invalidate'
import { feedQueryKeys } from '@/hooks/rssFeeds'

const validations = {
  podcast: {
    name: {
      required,
      maxLength: maxLength(255),
    },
    description: {
      required,
      maxLength: maxLength(65535),
      notEmptyHTML: (value: string) => value !== '<p><br></p>',
    },
    summary: {
      required,
      maxLength: maxLength(255),
    },
    publisher: {
      required,
      maxLength: maxLength(255),
    },
    copyright: {
      required,
      maxLength: maxLength(255),
    },
    anltPrefix: {
      maxLength: maxLength(255),
      urlVal: (value: string) => {
        if (value) {
          var pattern = new RegExp(
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
              '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
              '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
              '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
              '(\\#[-a-z\\d_]*)?$',
            'i'
          ) // fragment locator
          return !!pattern.test(value)
        } else {
          return true
        }
      },
    },
    website: {
      required,
      urlVal: (value: string) => {
        var pattern = new RegExp(
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
          'i'
        ) // fragment locator
        return !!pattern.test(value)
      },
      maxLength: maxLength(2083),
    },
    ownerName: {
      required,
      maxLength: maxLength(255),
    },
    ownerEmail: {
      required,
      email,
    },
    language: {
      required,
    },
    episodeListingType: {
      required,
    },
    genreIds: {
      required,
    },
    explicitContent: {
      required,
    },
    episodeKeywords: {
      maxLength: maxLength(255),
    },
    episodeFootNote: {
      maxLength: maxLength(255),
    },
  },
}

@Component({
  // @ts-ignore
  validations,
  components: { HTMLEditor, ImageGallery },
})
export default class ModalPodcastEdit extends Mixins(validationMixin, ImagePreviewer, Invalidate) {
  @Getter userId!: any
  @Getter networkId!: any
  @Getter rssFeed!: any
  @Getter rssGenres!: any
  @Getter name!: any
  @Getter email!: any
  @Getter verifiedEmails!: any
  @Getter selectedFile!: any
  @Getter currentNetwork!: any

  @Action updateRssFeed!: any
  @Action publishRssFeed!: any
  @Action closeModal!: any
  @Action getRssGenres!: any
  @Action getVerifiedEmails!: any

  currentStep: number = 1

  $v: any
  hasClickedNext: boolean = false
  hasClickedImage: boolean = false
  hasClearedCategories: boolean = false

  podcast: any = {}
  podcastImageData: string = ''

  isSubmitting: boolean = false

  enableImageGallery: boolean = false
  selectedImageSrc: string = ''
  selectedImageId: string = ''

  languages = [
    { name: 'English', code: 'en' },
    { name: 'English (Australia)', code: 'en-au' },
    { name: 'English (Belize)', code: 'en-bz' },
    { name: 'English (Canada)', code: 'en-ca' },
    { name: 'English (Ireland)', code: 'en-ie' },
    { name: 'English (Jamaica)', code: 'en-jm' },
    { name: 'English (New Zealand)', code: 'en-nz' },
    { name: 'English (Phillipines)', code: 'en-ph' },
    { name: 'English (South Africa)', code: 'en-za' },
    { name: 'English (Trinidad)', code: 'en-tt' },
    { name: 'English (United Kingdom)', code: 'en-gb' },
    { name: 'English (United States)', code: 'en-us' },
    { name: 'English (Zimbabwe)', code: 'en-zw' },
    { name: 'Spanish', code: 'es' },
    { name: 'French', code: 'fr' },
    { name: 'Italian', code: 'it' },
    { name: 'Chinese', code: 'zh' },
    { name: 'Japanese', code: 'ja' },
    { name: 'Korean', code: 'ko' },
    { name: 'Hindi', code: 'hi' },
    { name: 'Tamil', code: 'ta' },
    { name: 'Marathi', code: 'mr' },
    { name: 'Kannada', code: 'kn' },
    { name: 'Urdu', code: 'ur' },
    { name: 'Telugu', code: 'te' },
    { name: 'Gujarati', code: 'gu' },
    { name: 'Malayalam', code: 'ml' },
  ]

  episodeListingTypes = [
    { text: 'Episodic', value: 'EPISODE' },
    { text: 'Serial', value: 'SERIAL' },
  ]

  async created() {
    const selectedPodcastUid = this.$store.state.modal.podcastEditor.podcastUid
    this.podcast = this.setDefaults({ ...this.rssFeed(selectedPodcastUid) })
    this.selectedImageSrc = this.podcast.imageInfo.url
    if (this.rssGenres.length === 0) {
      await this.getRssGenres()
    }
  }

  get isNetworkOwner() {
    return this.currentNetwork?.role?.name === 'Network Owner'
  }

  async mounted() {
    if (this.isNetworkOwner) {
      await this.getVerifiedEmails()
    }
  }

  setDefaults(podcast: any) {
    podcast.publisher = podcast.publisher || this.name
    podcast.copyright = podcast.copyright || `© ${new Date().getFullYear()} ${this.name}, All rights reserved.`
    podcast.website = podcast.website || ''
    podcast.language = podcast.language || 'en'
    podcast.episodeListingType = podcast.episodeListingType || 'EPISODE'
    podcast.explicitContent = podcast.explicitContent || false
    podcast.ownerName = podcast.ownerName || this.name
    podcast.ownerEmail = podcast.ownerEmail || this.email
    podcast.genreIds = podcast.genreIds || []
    podcast.anltPrefix = podcast.anltPrefix || null

    return podcast
  }

  get hasPreviewImage() {
    return this.selectedImageSrc
  }

  showImageGallery() {
    if (this.enableImageGallery && !this.selectedImageSrc) {
      this.selectedImageSrc = ''
    }
    this.enableImageGallery = !this.enableImageGallery
  }

  handleSelectedImage(imgData: any) {
    if (imgData.urls.full && imgData.urls.full.includes('adorilabs')) {
      this.selectedImageSrc = imgData.urls.full
      this.selectedImageId = imgData.id
    } else if (imgData.urls.full && imgData.urls.full.startsWith('https://')) {
      this.selectedImageSrc = imgData.urls.full
    } else if (this.previewImage !== '/img/Web link.c650ed21.jpg') {
      this.selectedImageSrc = this.previewImage
    } else {
      this.selectedImageSrc = ''
    }
    this.enableImageGallery = !this.enableImageGallery
  }

  get hasCompletedStep1() {
    return (
      !this.$v.podcast.name.$invalid &&
      !this.$v.podcast.description.$invalid &&
      !this.$v.podcast.summary.$invalid &&
      this.hasPreviewImage
    )
  }

  get hasCompletedStep2() {
    return (
      !this.$v.podcast.publisher.$invalid &&
      !this.$v.podcast.copyright.$invalid &&
      !this.$v.podcast.website.$invalid &&
      !this.$v.podcast.ownerName.$invalid &&
      !this.$v.podcast.ownerEmail.$invalid
    )
  }

  get hasCompletedStep3() {
    return !this.$v.$invalid && this.podcast.genreIds.length !== 0
  }

  genreFromId(id: string) {
    return this.flattendRssGenres.find((genre: any) => genre.id === id)
  }

  get flattendRssGenres() {
    const allRssGenres: any = []
    const rssGenres = this.rssGenres
    rssGenres.forEach((genre: any) => {
      allRssGenres.push({ id: genre.id, name: genre.name })
      if (genre.subGenres.length !== 0) {
        genre.subGenres.forEach((subGenre: any) => {
          allRssGenres.push({ id: subGenre.id, name: subGenre.name })
        })
      }
    })
    return allRssGenres
  }

  updateDescription(newDescription: string) {
    this.$v.podcast.description.$model = newDescription
  }

  updateFootNote(footNote: string) {
    this.$v.podcast.episodeFootNote.$model = footNote
  }

  handleAddGenre(e: any) {
    this.hasClearedCategories = false
    this.podcast.genreIds.push(e.target.value)
  }

  handleRemoveGenre(id: any) {
    this.podcast.genreIds = this.podcast.genreIds.filter((genreId: any) => genreId !== id)
    if (this.podcast.genreIds.length === 0) {
      this.hasClearedCategories = true
    }
  }

  handlePrevTab() {
    this.currentStep -= 1
  }

  handleNextTab() {
    if (this.currentStep === 1 && this.hasCompletedStep1) {
      this.hasClickedNext = false
      this.currentStep += 1
    } else if (this.currentStep === 2 && this.hasCompletedStep2) {
      this.hasClickedNext = false
      this.currentStep += 1
    } else if (this.currentStep === 3 && this.hasCompletedStep3) {
      this.hasClickedNext = false
      this.currentStep += 1
    } else {
      this.hasClickedNext = true
    }
  }

  keywordStringToArray(keywordString: string) {
    if (Array.isArray(keywordString)) {
      return keywordString
    }
    if (isEmpty(keywordString)) {
      return []
    }
    if (keywordString === '') {
      return []
    }
    return keywordString.split(',').map((keyword: string) => keyword.trim())
  }

  async handleEditPodcastSubmit() {
    if (this.currentStep === 3 && this.hasCompletedStep3) {
      this.isSubmitting = true
      this.hasClickedNext = false

      const payload: any = {
        name: this.podcast.name,
        description: this.podcast.description,
        summary: this.podcast.summary,
        publisher: this.podcast.publisher,
        copyright: this.podcast.copyright,
        website: !/^https?:\/\//i.test(this.podcast.website) ? `https://${this.podcast.website}` : this.podcast.website,
        language: this.podcast.language,
        episodeListingType: this.podcast.episodeListingType,
        explicitContent: this.podcast.explicitContent,
        ownerName: this.podcast.ownerName,
        ownerEmail: this.podcast.ownerEmail,
        genreIds: this.podcast.genreIds,
        anltPrefix:
          this.podcast.anltPrefix && !/^https?:\/\//i.test(this.podcast.anltPrefix)
            ? `https://${this.podcast.anltPrefix}`
            : this.podcast.anltPrefix || null,
      }

      !this.isNetworkOwner && delete payload['ownerName']
      !this.isNetworkOwner && delete payload['ownerEmail']

      this.podcast.episodeKeywords
        ? (payload.episodeKeywords = this.keywordStringToArray(this.podcast.episodeKeywords))
        : (payload.episodeKeywords = [])

      this.podcast.episodeFootNote
        ? (payload.episodeFootNote = this.podcast.episodeFootNote)
        : (payload.episodeFootNote = '')

      let imageId
      if (this.selectedImageSrc && this.selectedImageSrc.includes('adorilabs')) {
        payload.squareImageId = this.selectedImageId
      } else if (this.selectedImageSrc && this.selectedImageSrc.startsWith('https://')) {
        const img: any = await AdoriService.uploadImage(this.networkId, {
          url: this.selectedImageSrc,
        })
        payload.squareImageId = img.id
      } else {
        const img: any = await AdoriService.uploadImage(this.networkId, this.selectedFile)
        payload.squareImageId = img.id
      }

      try {
        await this.updateRssFeed({
          rssFeedUid: this.podcast.uid,
          payload,
        })

        await this.publishRssFeed({
          rssFeedUid: this.podcast.uid,
        })

        this.closeModal()
      } catch (err) {
        this.isSubmitting = false
      }

      this.queryClient.invalidateQueries(feedQueryKeys.RSSFEED)

      this.isSubmitting = false
    } else {
      this.hasClickedNext = true
    }
  }
}
