












































































import { Component, Mixins, Vue } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import AdoriService from '@/services/adori'
import LanguageSelector from '@/components/Common/LanguageSelector.vue'
import { uploadStatus } from '../Publish/publish'
import { audioQueryKeys } from '@/hooks/audio'
import Invalidate from '@/mixins/Invalidate'

@Component({
  components: { LanguageSelector },
})
export default class ModalReplaceAudio extends Mixins(Invalidate) {
  @Getter networkId!: any
  @Getter audio!: any

  @Action getAudio!: Function
  @Action addAudioToReplaceAudioProcessList!: Function
  @Action closeModal!: Function
  @Action clearAudioUploader!: Function

  keepInteractiveTags: boolean = true
  regenerateTranscript: boolean = false
  generateTranscript: boolean = true
  currentStep = 1
  isSubmitting: boolean = false
  selectedLang: string = 'en-US'

  get hasAudioUploadId() {
    return !!this.$store.getters.audioUploadId
  }

  get uploadProgress() {
    return Math.round(this.$store.getters.audioUploadProgress * 100)
  }

  get isUploadingAudio() {
    return this.hasAudioUploadId && this.$store.getters.audioUploadProgress !== 1
  }

  get trackSource() {
    return this.audio(this.audioUid) && this.audio(this.audioUid).trackSource
  }

  get audioUid() {
    return this.$store.state.modal.replaceAudio.episodeUid
  }

  handleUploadAudio() {
    this.$store.dispatch('uploadNewEpisode')
  }

  async handleReplaceAudio() {
    this.isSubmitting = true
    const audioUid = this.$store.state.modal.replaceAudio.episodeUid
    const payload: any = {
      uploadId: this.$store.getters.audioUploadId,
      language: this.selectedLang,
      keepTags: this.keepInteractiveTags,
      redoTranscript: this.regenerateTranscript,
    }

    await AdoriService.updateUpload(this.networkId, this.$store.getters.audioUploadId)

    const poll = setInterval(async () => {
      const res: any = await AdoriService.getUploadStatus(this.networkId, this.$store.getters.audioUploadId)
      if (res.processingStatus === uploadStatus.FINISHED) {
        clearInterval(poll)
        const res = await AdoriService.replaceAudio(this.networkId, audioUid, payload)
        await this.getAudio(audioUid)
        this.addAudioToReplaceAudioProcessList(audioUid)
        this.queryClient.invalidateQueries([audioQueryKeys.AUDIO_TRACK, this.networkId, audioUid])
        this.queryClient.invalidateQueries(audioQueryKeys.ALLTRACKS)
        this.isSubmitting = false
        this.closeModal()
      }
      if (res.processingStatus === uploadStatus.FAILED) {
        clearInterval(poll)
        this.isSubmitting = false
      }
    }, 3000)
  }

  async mounted() {
    if (!this.audio(this.audioUid)) await this.getAudio(this.audioUid)
  }

  beforeDestroy() {
    this.clearAudioUploader()
  }
}
