var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      attrs: { hasCustomEvent: true },
      on: {
        closeModal: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "f3 light-gray fw5 di" }, [
          _vm._v("Share this track"),
        ]),
      ]),
      _c("template", { slot: "body" }, [
        _c(
          "div",
          { staticClass: "flex flex-column w-100 min-w7" },
          [
            _vm._l(_vm.shareList, function (sh, i) {
              return _c("div", { key: i, staticClass: "w-100" }, [
                _c("div", { staticClass: "w-100 embed" }, [
                  _c("div", { staticClass: "f7 tracked-05 white mb2" }, [
                    _vm._v(_vm._s(sh.name)),
                  ]),
                  _c(
                    "div",
                    { staticClass: "flex", staticStyle: { flex: "1" } },
                    [
                      _c("input", {
                        staticClass:
                          "w-100 white bg-dark-gray bn pa2 br2 f6 br--left",
                        attrs: {
                          id: "ep" + sh.id,
                          type: "text",
                          name: "embed",
                          readonly: "",
                        },
                        domProps: { value: sh.value },
                      }),
                      _c(
                        "div",
                        {
                          staticClass:
                            "bg-adori-red flex items-center pa1 ph2 br2 br--right pointer",
                          on: {
                            click: function ($event) {
                              return _vm.copyEmbed(sh.id)
                            },
                          },
                        },
                        [
                          _c(
                            "i",
                            {
                              staticClass: "material-icons white",
                              staticStyle: { "font-size": "18px" },
                            },
                            [_vm._v("assignment")]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
              ])
            }),
            _c("div", { staticClass: "share-options" }, [
              _c(
                "div",
                {
                  staticClass: "share-option",
                  attrs: { title: "Post to Facebook" },
                  on: { click: _vm.postToFacebook },
                },
                [
                  _c("div", [_vm._v("Facebook")]),
                  _c("div", { staticClass: "facebook-icon" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "share-option",
                  attrs: { title: "Share on LinkedIn" },
                  on: { click: _vm.postToLinkedIn },
                },
                [
                  _c("div", [_vm._v("Linkedin")]),
                  _c("div", { staticClass: "linkedin-icon" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "share-option",
                  attrs: { title: "Share on twitter" },
                  on: { click: _vm.postToTwitter },
                },
                [
                  _c("div", [_vm._v("Twitter")]),
                  _c("div", { staticClass: "twitter-icon" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "share-option",
                  attrs: { title: "Share on twitter" },
                  on: {
                    click: function ($event) {
                      _vm.showQr = !_vm.showQr
                    },
                  },
                },
                [
                  _c("div", [
                    _vm._v(_vm._s(_vm.showQr ? "Hide" : "View") + " QR"),
                  ]),
                  _c(
                    "i",
                    { staticClass: "material-icons black f4 v-mid pa1" },
                    [_vm._v("qr_code_2")]
                  ),
                ]
              ),
            ]),
            _vm.showQr ? _c("QrCode", { attrs: { url: _vm.qrUrl } }) : _vm._e(),
          ],
          2
        ),
      ]),
      _c("template", { slot: "footer" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }