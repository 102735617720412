var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "f3 light-gray fw5 di" }, [
          _vm._v(_vm._s(_vm.getValue("title"))),
        ]),
      ]),
      _c("template", { slot: "body" }, [
        _c(
          "div",
          { staticClass: "flex w-100" },
          [
            _vm.hasValue("description")
              ? _c("p", {
                  staticClass: "f5 gray measure lh-copy pr3",
                  domProps: { innerHTML: _vm._s(_vm.getValue("description")) },
                })
              : _vm._e(),
            _vm.getValue("inputPlaceholder")
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.inputValue,
                      expression: "inputValue",
                    },
                  ],
                  staticClass:
                    "br2 ba pa2 bg-adori-very-light-gray white mt2 mb1 w-100",
                  class:
                    _vm.hasValue("error") && _vm.getValue("error") !== ""
                      ? "b--adori-red"
                      : "b--gray",
                  attrs: { placeholder: _vm.getValue("inputPlaceholder") },
                  domProps: { value: _vm.inputValue },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.inputValue = $event.target.value
                    },
                  },
                })
              : _vm._e(),
            _vm.getValue("options")
              ? _c("OptionSelector", {
                  attrs: {
                    title: "Select a collection",
                    options: _vm.getValue("options"),
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm.hasValue("error") && _vm.getValue("error") !== ""
          ? _c("div", {
              staticClass: "f7 adori-red measure",
              domProps: { innerHTML: _vm._s(_vm.getValue("error")) },
            })
          : _vm._e(),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _vm.getValue("yesButtonText")
            ? _c("BaseButtonRed", {
                staticClass: "mr3",
                attrs: {
                  text: _vm.getValue("yesButtonText"),
                  onClick: function () {
                    return _vm.onAction("onConfirm")
                  },
                },
              })
            : _vm._e(),
          _vm.getValue("noButtonText")
            ? _c("BaseButtonBorder", {
                attrs: {
                  text: _vm.getValue("noButtonText"),
                  onClick: function () {
                    return _vm.onAction("onCancel")
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }