var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      attrs: { hasCustomEvent: "" },
      on: {
        closeModal: function ($event) {
          return _vm.$emit("closeModal")
        },
      },
    },
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "flex items-center" }, [
          _c(
            "i",
            { staticClass: "material-icons light-gray f4 v-mid pa1 mr2" },
            [_vm._v(" ondemand_video ")]
          ),
          _c("div", { staticClass: "f4 light-gray fw5 di" }, [
            _vm._v("Example Blog Video Preview"),
          ]),
        ]),
      ]),
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "f5 silver max-w5 lh-copy" }, [
          _vm._v(
            "\n      This is how your final video will look after adding transitions, effects, and subtitles. Please edit the scenes\n      here and apply transitions later using the advanced editor to create your own version.\n\n      "
          ),
          _c(
            "video",
            { attrs: { controls: "true", width: "560", height: "315" } },
            [
              _c("source", {
                attrs: {
                  src: "https://storage.googleapis.com/dev_audio_adorilabs/samples/example-blog.mp4",
                  type: "video/mp4",
                },
              }),
            ]
          ),
          _c(
            "div",
            { staticClass: "grid" },
            [
              _c(
                "a",
                {
                  staticClass: "mr-auto",
                  attrs: {
                    href: "https://edition.cnn.com/2023/05/30/world/solar-tree-electric-vehicle-charging-spc-intl/index.html",
                    target: "_blank",
                    rel: "noopener noreferrer",
                  },
                },
                [_vm._v("\n          Blog link\n        ")]
              ),
              _c("BaseButtonRed", {
                attrs: {
                  text: "Edit",
                  onClick: function () {
                    return _vm.$emit("closeModal")
                  },
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }