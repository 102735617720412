var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("GmapAutocomplete", {
    staticClass: "db bg-adori-very-light-gray bw0 br2 ph3 pv3 gray f6 white",
    attrs: {
      placeholder: "Start typing a location name...",
      value: _vm.value || _vm.place || "",
    },
    on: { input: _vm.inputValue, place_changed: _vm.setPlace },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }