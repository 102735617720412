





























import { Vue, Prop, Component } from 'vue-property-decorator'

@Component
export default class BaseThumbnailEpisode extends Vue {
  @Prop(Boolean) loading!: boolean
  @Prop() src!: any
  @Prop(Boolean) big!: boolean
  @Prop(String) hoverText!: string
  @Prop(String) hoverIcon!: string
  @Prop(Function) onClick!: () => void

  get width() {
    if (this.big) return ' w350 bg-black'
    return ' w125'
  }

  get height() {
    if (this.big) return ' h196 bg-black'
    return ' h70'
  }

  get fontSize() {
    if (this.big) return ' f-superheadline '
    return ' f1 '
  }

  get dimensions() {
    return this.width + ' ' + this.height
  }
}
