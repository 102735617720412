



















































































































































































































































import moment from 'moment'
import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import ModalSponsor from '@/components/Campaigns/Modals/ModalSponsor.vue'
import ModalAdvertiser from '@/components/Campaigns/Modals/ModalAdvertiser.vue'
import isEmpty from 'lodash.isempty'

import { modalType } from '@/components/Campaigns/configs'
import Invalidate from '@/mixins/Invalidate'
import { campaignQueryKeys } from '@/hooks/campaign'

@Component({
  components: {
    ModalSponsor,
    ModalAdvertiser,
  },
})
export default class ModalDynamicCampaign extends Mixins(Invalidate) {
  @Prop(Boolean) modalEdit!: boolean
  @Prop(String) campaignType!: string

  @Getter networkId!: string
  @Getter advertisers!: any
  @Getter sponsors!: any
  @Getter iabCategories!: any
  @Getter iabContentCategories!: any
  @Getter iabDaast1Categories!: any
  @Getter iabDaast2Categories!: any
  @Getter daastValues!: string[]

  @Action getAdvertiserList!: any
  @Action getTier1IABCategories!: any
  @Action createCampaign!: any
  @Action getCampaigns!: any
  @Action getDaast1IABCategories!: any
  @Action getDaast2IABCategories!: any
  @Action createAdvertiser!: any

  sponsorModal = false
  advertiserModal = false

  isUploadingTag: boolean = false
  validationStatus: boolean = false
  iabDivs: any = [{}]

  campaignName = ''
  campaignDescription = ''
  campaignforeverOption = false
  campaignStartDate: any = ''
  campaignEndDate: any = ''
  notBeforeStartDate: any = moment().utc().toISOString()

  notAfterStartDate: any = null
  notBeforeEndDate: any = moment().utc().toISOString()
  notAfterEndtDate: any = null

  advertiser = ''
  sponsor = ''

  validationObj = {
    campaignName: false,
    campaignDescription: false,
    date: false,
    advertiser: false,
    sponsor: false,
  }

  daast1Value: string = ''
  daast2Value: string = ''

  get modalType() {
    return modalType
  }

  async changeDaast() {
    await this.getDaast2IABCategories(this.daast1Value)
    let daastArray = []
    daastArray.push(this.daast1Value)
    this.$store.dispatch('changeAudioAdDaast', daastArray)
  }

  get iabDasst1() {
    return this.iabDaast1Categories.map(function (iab: { name: string; uniqueId: string }) {
      return {
        text: iab.name,
        value: iab.uniqueId,
      }
    })
  }

  get iabDasst2() {
    if (!this.iabDaast2Categories) return []
    return this.iabDaast2Categories.map(function (iab: { name: string; uniqueId: string }) {
      return {
        text: iab.name,
        value: iab.uniqueId,
      }
    })
  }

  @Watch('daast2Value')
  async changeDaastSecond() {
    let daastArray = []
    if (this.daast1Value) {
      // daastArray.push(this.daast1Value)
      daastArray.push(this.daast2Value)
      this.$store.dispatch('changeAudioAdDaast', daastArray)
    } else {
      daastArray.push(this.daast1Value)
      this.$store.dispatch('changeAudioAdDaast', daastArray)
    }
  }

  get advertiserList() {
    return this.advertisers
  }
  get sponsorList() {
    return this.sponsors
  }

  camapignNameValidation() {
    if (this.campaignName === '') this.validationObj.campaignName = true
    else this.validationObj.campaignName = false
  }
  campaignDescriptionValidation() {
    if (this.campaignDescription === '') this.validationObj.campaignDescription = true
    else this.validationObj.campaignDescription = false
  }
  validateDate() {
    this.notAfterStartDate = this.campaignEndDate

    if (!['', null].includes(this.campaignStartDate)) this.notBeforeEndDate = this.campaignStartDate
    else {
      this.notBeforeEndDate = moment().utc().toISOString()
    }
    if (this.campaignforeverOption === false) {
      if (['', null].includes(this.campaignStartDate) || ['', null].includes(this.campaignEndDate)) {
        this.validationObj.date = true
      } else this.validationObj.date = false
    }
  }

  validateAdvertiser() {
    const element: any = this.$refs.advertiser
    if (element.value === '') this.validationObj.advertiser = true
    else this.validationObj.advertiser = false
  }

  validateSponsor() {
    const element: any = this.$refs.sponsor
    if (element.value === '') this.validationObj.sponsor = true
    else this.validationObj.sponsor = false
  }

  toggleCheckBox() {
    this.campaignforeverOption = !this.campaignforeverOption
  }

  validation() {
    this.camapignNameValidation()
    this.campaignDescriptionValidation()
    this.campaignforeverOption === false ? this.validateDate() : (this.validationObj.date = false)
    this.campaignType === this.modalType.PAID || this.campaignType === this.modalType.PROGRAMMATIC
      ? this.validateAdvertiser()
      : (this.validationObj.advertiser = false)
    this.campaignType === this.modalType.UNPAID ? this.validateSponsor() : (this.validationObj.sponsor = false)
    if (
      this.validationObj.campaignName === true ||
      this.validationObj.campaignDescription === true ||
      this.validationObj.date === true ||
      this.validationObj.advertiser === true ||
      this.validationObj.sponsor === true
    ) {
      return true
    } else return false
  }
  openCreateSponsorModal() {
    this.sponsorModal = true
  }
  openCreateAdvertiserModal() {
    this.advertiserModal = true
  }

  async closeModal() {
    this.sponsorModal = false
    this.advertiserModal = false
    await this.fetchCategories()
  }

  get iabTier1Categories() {
    return this.iabCategories.map(function (iab: { name: string; uniqueId: number }) {
      return {
        text: iab.name,
        value: iab.uniqueId,
      }
    })
  }

  async created() {
    this.fetchCategories()
    await this.getTier1IABCategories()
    await this.getDaast1IABCategories()
    this.$store.dispatch('changeAudioAdDaast', [])
    this.$store.commit('setAudioAdCategories', [])
  }

  async fetchCategories() {
    let payload = {
      params: {
        limit: 100,
        offset: 0,
        kind: 'PAID',
      },
      networkId: this.networkId,
    }
    await this.getAdvertiserList(payload)
    payload.params.kind = 'UNPAID'
    await this.getAdvertiserList(payload)
  }

  async changeTier2() {
    const contenteArr = []
    for (const item of this.iabDivs) {
      const category1: any = this.iabCategories.filter((iab: any) => item.tier1 === iab.uniqueId)[0].children
      item.iab2Categories = category1.map((iab: any) => ({
        text: iab.name,
        value: iab.uniqueId,
      }))
      if (item.tier2) {
        contenteArr.push(item.tier2)
      } else {
        contenteArr.push(item.tier1)
      }
    }
    this.$store.commit('setAudioAdCategories', contenteArr)
  }

  updateCat() {
    let iab: string[] = []
    for (const obj of this.iabDivs) {
      iab.push(`${Object.values(obj)[0] || ''}:${Object.values(obj)[1] || ''}`)
    }
  }

  addMoreCat() {
    if (this.iabDivs.length > 1) {
      return
    }
    this.iabDivs.push({})
    this.updateCat()
  }

  deleteCat(pos: Number) {
    if (this.iabDivs.length > 1) this.iabDivs = this.iabDivs.filter((obj: Object, index: Number) => index !== pos)
    this.updateCat()
  }

  async handleCreateCampaign() {
    try {
      if (this.validation()) return
      this.isUploadingTag = true
      let payload: any = {
        data: {
          name: this.campaignName,
          description: this.campaignDescription,
          type: 'AUDIO',
          kind: `${this.campaignType.toUpperCase()}${
            this.campaignType === this.modalType.PROGRAMMATIC ? '' : '_PROMO'
          }`,
          advertiserId: `${this.advertiser || this.sponsor}`,
        },
        networkId: this.networkId,
      }
      if (this.campaignforeverOption === false) {
        payload.data['startTime'] = moment(this.campaignStartDate).startOf('day').utc().toISOString()

        payload.data['endTime'] = moment(this.campaignEndDate).endOf('day').utc().toISOString()
      } else {
        payload.data['startTime'] = moment().startOf('day').utc().toISOString()
        payload.data['endTime'] = null
      }
      if (['Paid', 'Unpaid'].includes(this.campaignType)) {
        payload.data['provider'] = {}
        payload.data.provider['kind'] = 'ADORI'
      } else {
        payload.data['provider'] = {}
        payload.data.provider['kind'] = 'TRITON'
        if (!isEmpty(this.iabContentCategories)) {
          payload.data.provider['iabContentCategories'] = this.iabContentCategories
        }
        if (!isEmpty(this.daastValues)) payload.data.provider['iabExcludeCategories'] = this.daastValues
      }

      await this.createCampaign(payload)
      await this.getAdvertiserList({
        params: { limit: 100, offset: 0 },
        networkId: this.networkId,
      })
      this.isUploadingTag = false
      this.queryClient.invalidateQueries(campaignQueryKeys.CAMPAIGN)
      this.$store.dispatch('closeModal')
    } catch (error) {
      this.isUploadingTag = false
      this.$store.dispatch('closeModalChange')
    }
  }
}
