
























import { Vue, Prop, Component } from 'vue-property-decorator'

@Component
export default class OptionSelector extends Vue {
  @Prop(Array) options!: string[]
  @Prop(String) title!: string
  @Prop(String) id!: string
  @Prop(Function) onChange!: (s: any) => void
  @Prop(Boolean) change!: boolean
  @Prop(String) elValue!: string

  onOptionChange() {
    const s = this.$refs.select as any
    const value = s.options[s.selectedIndex].value
    this.$store.dispatch('selectUniqueItem', value)
  }

  onOptionChangeNew() {
    const s = this.$refs.select as any
    this.onChange(s)
  }
}
