

















































import { Component, Vue, Mixins } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

@Component
export default class ModalPodcastPublish extends Vue {
  @Getter validationErrors!: any
  @Getter rssFeedItems!: any
  @Action closeModal!: any
  @Action validateRssFeed!: any
  @Action publishRssFeed!: any
  @Action clearValidationErrors!: any

  isSubmitting: boolean = false
  isValidated: boolean = false

  selectedPodcastUid: string = this.$store.state.modal.podcastEditor.podcastUid

  created() {
    this.clearValidationErrors()
  }
  beforeDestroy() {
    this.clearValidationErrors()
  }

  handleCancelClicked() {
    this.closeModal()
  }

  audioTrackName(uid: string) {
    const audioTracks = this.rssFeedItems(this.selectedPodcastUid).audioTracks
    const selectedAudioTrack = audioTracks.find((track: any) => track.uid === uid)
    return selectedAudioTrack.name
  }

  async handleValidateAndPublishClicked() {
    this.isSubmitting = true

    this.clearValidationErrors()
    try {
      await this.publishRssFeed({ rssFeedUid: this.selectedPodcastUid })
      this.closeModal()
    } catch (error) {
      await this.validateRssFeed({ rssFeedUid: this.selectedPodcastUid })
    }

    this.isSubmitting = false
  }
}
