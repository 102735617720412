
























































































































































































































































import { Component, Mixins, Watch, Prop } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import { getImageSrc } from '@/services/adori'
import ImagePreviewer from '@/mixins/ImagePreviewer'
import store from '@/store'
import ImagesList from '@/components/Common/Images/ImagesList.vue'
import debounce from 'lodash.debounce'
import { TagImages } from '@/imageAssets'
import ImageLibraries from '@/mixins/ImageLibraries'

@Component({
  components: {
    ImagesList,
  },
})
export default class ImageGallery extends Mixins(ImagePreviewer, ImageLibraries) {
  @Prop({ default: 'TAG_CREATE' })
  galleryParent!: string // or 'PODCAST_EPISODE' OR 'PODCAST_SHOW'

  @Getter userId!: string
  @Getter networkId!: string
  @Getter placeHolderImages!: any

  @Action getImages!: Function

  selectedImageTab: string = 'UPLOAD' // or 'UNSPLASH' or 'UPLOAD' or 'MY_LIBRARY'
  searchQuery: string = ''
  isFetchingImages: boolean = false
  hasSearched: boolean = false

  previewImageSrc: string = ''
  getImageSrc = getImageSrc
  newFileUpload: boolean = false
  uploadedImageSrc: any = this.newFileUpload
  bgDragColor: boolean = false
  selectedImage: any = {}
  reader = new FileReader()
  enableImageCropper: boolean = false

  podcastCroppa: any = {}

  unsplashUserDetails: any = null

  debouncedSearch = debounce(() => {
    this.searchImages()
  }, 600)

  async mounted() {
    this.$store.dispatch('changeTagImage', TagImages[this.$store.state.modal.tagEdit.tagType])
  }

  get searchLibraries() {
    return [
      {
        LibraryName: 'UPLOAD',
        name: 'Upload',
      },
      {
        LibraryName: 'UNSPLASH',
        name: 'Unsplash',
      },
      {
        LibraryName: 'GOOGLE',
        name: 'Google',
      },
      {
        LibraryName: 'GETTY',
        name: 'Getty',
      },
      {
        LibraryName: 'PIXABAY',
        name: 'Pixabay',
      },
      {
        LibraryName: 'MY_LIBRARY',
        name: 'My Library',
      },
    ]
  }

  clearSearchQuery() {
    this.searchQuery = ''
    this.imageSearchText = ''
    this.resetOffset()
  }

  get imgData() {
    return {
      urls: {
        full: this.selectedImage.url,
      },
      id: this.selectedImage.id,
    }
  }

  get uploadedImage() {
    return this.previewImage
  }

  async handleSelectImage(result: any) {
    this.previewImageSrc = ''
    setTimeout(() => {
      this.previewImageSrc = this.galleryParent === 'PODCAST_SHOW' ? result.url : result.previewUrl
    })
    this.selectedImage = result
    if (result.user) {
      this.unsplashUserDetails = result.user
    } else {
      this.unsplashUserDetails = null
    }
  }

  handleCancelUpload() {
    this.$store.dispatch('changeTagImage', TagImages[this.$store.state.modal.tagEdit.tagType])
  }

  goToUserProfile(profileUrl: string) {
    window.open(profileUrl, '_blank')
  }

  dropFile(e: any, galleryParent: String) {
    this.bgDragColor = false
    this.showImageUploader(galleryParent, e.dataTransfer.files[0])
  }

  async selectPodcastImage() {
    if (this.galleryParent === 'PODCAST_SHOW') {
      const file = await this.podcastCroppa.promisedBlob()
      this.$store.commit('setSelectedFile', file)
      this.previewImage = this.podcastCroppa.generateDataUrl()
      this.$emit('handleSelectedImage', {
        urls: {
          full: undefined,
        },
        id: undefined,
      })
    } else {
      this.$emit('handleSelectedImage', this.imgData)
    }
  }

  async searchImages() {
    this.imageSearchText = this.searchQuery
  }

  @Watch('selectedImageTab')
  clearPrevieImageSource() {
    this.previewImageSrc = ''
  }
}
