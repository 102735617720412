














































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

@Component
export default class ModalSponsor extends Vue {
  @Getter networkId!: string

  @Action createAdvertiser!: any
  validationStatus: boolean = false
  sponsorName = ''
  isUploadingTag = false

  validation() {
    if (this.sponsorName === '') {
      this.validationStatus = true
      return true
    }
    this.validationStatus = false
    return false
  }
  async handleCreateSponsor() {
    if (this.validation()) return
    this.isUploadingTag = true
    const payload = {
      data: {
        name: this.sponsorName,
        kind: 'UNPAID',
      },
      networkId: this.networkId,
    }

    await this.createAdvertiser(payload)
    this.isUploadingTag = true
    this.$emit('close')
  }
}
