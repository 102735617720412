import auth0, { Auth0Error, Auth0Callback } from 'auth0-js'
import { AUTH0_DOMAIN, AUTH0_CLIENT_ID, TOKEN_STORAGE_KEY, EXPIRY_STORAGE_KEY } from '@/constants'
import store from '@/store'

// Public API

export default {
  get isLoggedIn() {
    const idToken = localStorage.getItem(TOKEN_STORAGE_KEY)
    const expiresAt = localStorage.getItem(EXPIRY_STORAGE_KEY)
    if (idToken && expiresAt) {
      return parseInt(expiresAt) > new Date().getTime()
    }
    return false
  },

  async login(email: string, password: string, callback: any) {
    AuthAPI.login(
      {
        email,
        password,
        realm: 'Username-Password-Authentication',
      },
      callback
    )
  },

  async googleAuthorisation(callback: any) {
    AuthAPI.authorize(
      {
        connection: 'google-oauth2',
      },
      callback
    )
  },

  changePassword(email: string, callback: any) {
    AuthAPI.changePassword(
      {
        email,
        connection: 'Username-Password-Authentication',
      },
      callback
    )
  },

  setSession() {
    return new Promise((resolve, reject) => {
      AuthAPI.parseHash((err: any, res: any) => {
        if (res) {
          localStorage.setItem(TOKEN_STORAGE_KEY, `${res.idToken}`)
          localStorage.setItem(EXPIRY_STORAGE_KEY, `${res.idTokenPayload.exp * 1000}`)
          store.dispatch('setSession', {
            token: res.idToken,
            expiry: res.idTokenPayload.exp * 1000,
          })

          resolve(res)
        } else if (err) {
          reject(err)
        }
      })
    })
  },
}

// Private helpers

const AuthAPI = new auth0.WebAuth({
  domain: AUTH0_DOMAIN,
  clientID: AUTH0_CLIENT_ID,
  redirectUri: `${window.location.origin}`,
  responseType: 'id_token',
  scope: 'openid email',
})
