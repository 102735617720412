var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c("div", [
        _c("img", {
          staticClass:
            "tr link dim pointer hide-child relative flex justify-center",
          class: _vm.height,
          attrs: { src: require("@/assets/loading.gif") },
        }),
      ])
    : _c(
        "div",
        {
          staticClass:
            "tr link dim pointer hide-child relative flex justify-center",
          class: _vm.dimensions,
          attrs: { title: _vm.hoverText },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.onClick()
            },
          },
        },
        [
          _vm.src
            ? _c("img", { class: _vm.height, attrs: { src: _vm.src } })
            : _c(
                "i",
                {
                  staticClass:
                    "material-icons light-gray f1 br2 bg-adori-very-light-gray flex justify-center items-center",
                  class: _vm.dimensions + _vm.fontSize,
                },
                [_vm._v("\n    image\n  ")]
              ),
          _vm.hoverText
            ? _c(
                "div",
                {
                  staticClass:
                    "f6 absolute child mt1 top-0 bottom-0 left-0 right-0 pa2 b",
                },
                [
                  _c(
                    "i",
                    {
                      staticClass:
                        "material-icons light-gray br2 bg-adori-gray pa1",
                    },
                    [_vm._v(_vm._s(_vm.hoverIcon))]
                  ),
                ]
              )
            : _vm._e(),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }