
















import { Mixins, Component, Prop, Watch, Vue } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

import { orientationStatus } from '@/components/Publish/publish'

@Component({ components: {} })
export default class AudiogramTemplate extends Vue {
  @Prop(String) templateId!: string
  @Prop(String) orientation!: string
  @Prop(String) thumbnail!: string

  activeHover: boolean = false

  loadTemplate() {
    this.$emit('load-template', this.templateId)
  }

  get templateClass() {
    if (this.orientation === orientationStatus.LANDSCAPE) {
      return 'relative landscape-img '
    } else if (this.orientation === orientationStatus.SQUARE) {
      return 'relative square-img '
    } else {
      return 'relative portrait-img '
    }
  }
}
