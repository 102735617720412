var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "bg-black br2",
      class: {
        "landscape-size": _vm.filter === "LANDSCAPE",
        "square-size": _vm.filter === "SQUARE",
        "portrait-size": _vm.filter === "PORTRAIT",
        bigger: _vm.bigger,
        tag: !_vm.filter,
      },
      on: { mouseover: _vm.handleOver, mouseout: _vm.handleOut },
    },
    [
      _c(
        "div",
        { staticClass: "relative w-100 h-100 flex items-center" },
        [
          _vm.noControls
            ? _c("video", {
                ref: "video-" + _vm.tagId,
                staticClass: "tag-bg mw-none w-100 no-drag",
                attrs: { src: _vm.videoSrc, muted: "" },
                domProps: { muted: true },
              })
            : _c("video", {
                ref: "video-" + _vm.tagId,
                staticClass: "tag absolute right-0",
                class: _vm.bigger ? "bigger" : "",
                attrs: { src: _vm.videoSrc, muted: "" },
                domProps: { muted: true },
              }),
          _vm._t("default"),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }