import AdoriService from '@/services/adori'
import store from '@/store'
import { queryDefaults } from '@/utils/query.config'
import { useInfiniteQuery, useMutation, useQuery } from 'vue-query'
import get from 'lodash.get'

// Query Keys
export enum imageQueryKeys {
  PUBLIC_IMAGES = 'PUBLIC_IMAGES',
  LIB_IMAGES = 'LIB_IMAGES',
}

// Queries
const uploadImage = (params: { networkId: string; url?: string }) => {
  return AdoriService.uploadImage(params.networkId, {
    url: params.url,
  })
}
const getPublicImages = (networkId: string, params: any) => {
  const searchParams: any = {
    query: params.query,
    limit: params.limit,
    offset: params.offset,
  }
  params?.orientation === 'squarish' &&
    params.searchLibrary.toLowerCase() === 'unsplash' &&
    (searchParams['orientation'] = 'squarish')
  return AdoriService.searchImages(networkId, params.searchLibrary.toLowerCase(), searchParams)
}

const getLibImages = (networkId: string, params: any) => {
  return AdoriService.fetchImages(networkId, params.limit, params.offset)
}

// Hooks
export const useGetPublicImages = (params: any, { enabled }: any) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useQuery([imageQueryKeys.PUBLIC_IMAGES, networkId, params], () => getPublicImages(networkId, params), {
    keepPreviousData: true,
    ...queryDefaults,
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled,
  })
}
export const useGetLibImages = (params: any, { enabled }: any) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useQuery([imageQueryKeys.LIB_IMAGES, networkId, params], () => getLibImages(networkId, params), {
    keepPreviousData: true,
    ...queryDefaults,
    cacheTime: Infinity,
    staleTime: Infinity,
    enabled,
  })
}

// mutations
export const useUploadImage = () => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useMutation((params: { url: string }) => uploadImage({ networkId, url: params.url }))
}
