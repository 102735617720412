import { queryDefaults } from '@/utils/query.config'
import AdoriServiceV6 from '@/services/adori_v6'
import store from '@/store'
import { useQuery, useMutation, useQueryClient, useInfiniteQuery } from 'vue-query'
import get from 'lodash.get'

// Query Keys

export enum subtitleQueryKeys {
  SYS_SUBTITLE_TEMPLATES = 'SYS_SUBTITLE_TEMPLATES',
  SUBTITLES = 'SUBTITLES',
  CUSTOM = 'CUSTOM_TEMPLATES_STYLES',
}

// Queries
const getSubtitleTemplates = (networkId: string) => {
  return AdoriServiceV6.fetchSubtitleTemplates(networkId)
}
const getSubtitles = (networkId: string, trackUid: any) => {
  return AdoriServiceV6.fetchAudioTrackSubtitle(networkId, typeof trackUid !== 'string' ? trackUid.value : trackUid)
}
const generateSubtitles = (networkId: string, trackUid: string) => {
  return AdoriServiceV6.generateTrackSubtitles(networkId, trackUid)
}

const getCustomTemplatesStyles = (networkId: string, offset: number) => {
  return AdoriServiceV6.fetchSubtitleCustomTemplates(networkId, {
    params: {
      limit: 100,
      offset,
    },
  })
}
// Hooks
export const useGetSubtitleTemplates = () => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useQuery([subtitleQueryKeys.SYS_SUBTITLE_TEMPLATES, networkId], () => getSubtitleTemplates(networkId), {
    ...queryDefaults,
    cacheTime: Infinity,
    staleTime: Infinity,
    onSuccess(data: any) {
      store.commit('setSubtitleTemplates', data)
    },
  })
}
export const useGetSubtitles = (trackUid: any, { enabled }: any = { enabled: true }) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useQuery([subtitleQueryKeys.SUBTITLES, networkId, trackUid], () => getSubtitles(networkId, trackUid), {
    ...queryDefaults,
    cacheTime: Infinity,
    staleTime: Infinity,
    onSuccess(data: any) {
      store.commit('updateSubtitle', data.textBlocks)
      store.dispatch('filterSelectTemplate', data)
    },
    enabled,
  })
}
export const useGetCustomTemplateStyles = () => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useInfiniteQuery(
    [subtitleQueryKeys.CUSTOM, networkId],
    ({ pageParam = 0 }) => getCustomTemplatesStyles(networkId, pageParam),
    {
      ...queryDefaults,
      cacheTime: Infinity,
      staleTime: Infinity,
      getNextPageParam: (lastPage: any, pages) => {
        store.commit('setSubtitleCustomStyles', lastPage)
        const totalPages = get(pages, '[0].count', 0) > 100 ? Math.ceil(get(pages, '[0].count', 0) / 100) : 0
        if (pages.length - 1 === totalPages) return false
        else return pages.length * 100
      },
    }
  )
}

// mutations
export const useGenerateSubtitles = () => {
  const queryClient = useQueryClient()
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useMutation((trackUid: string) => generateSubtitles(networkId, trackUid), {
    onSuccess() {
      queryClient.invalidateQueries(subtitleQueryKeys.SUBTITLES)
    },
  })
}
