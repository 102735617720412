var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-wrap justify-center items-center w4" },
    [
      _c("p", { staticClass: "light-gray f6 mt0 mb2" }, [
        _vm._v(_vm._s(_vm.text)),
      ]),
      _c("p", { staticClass: "flex justify-between items-center mv0" }, [
        _c(
          "span",
          {
            staticClass: "link pointer ba b--adori-gray br-100 bg-dark-gray",
            on: {
              click: function () {
                return _vm.onIncrement(-1)
              },
            },
          },
          [
            _c("i", { staticClass: "material-icons light-gray f3 v-mid" }, [
              _vm._v(" arrow_left "),
            ]),
          ]
        ),
        _c("span", { staticClass: "light-gray code center f6 ph2" }, [
          _vm._v("\n      " + _vm._s(_vm.formattedTime) + "\n    "),
        ]),
        _c(
          "span",
          {
            staticClass: "link pointer ba b--adori-gray br-100 bg-dark-gray",
            on: {
              click: function () {
                return _vm.onIncrement(1)
              },
            },
          },
          [
            _c("i", { staticClass: "material-icons light-gray f3 v-mid" }, [
              _vm._v(" arrow_right "),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }