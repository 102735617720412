

















































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import AdoriService from '@/services/adori'

import * as Sentry from '@sentry/vue'

@Component
export default class ModalPaymentEstimates extends Vue {
  @Getter paymentEstimates!: any
  @Getter networkId!: string
  @Getter currentNetworkSubscriptions!: any

  isLoading: boolean = false
  estimates = null
  sampePlanId: boolean = false

  get subscription() {
    const subs = this.currentNetworkSubscriptions
    return subs && subs.subscription
  }

  async created() {
    if (this.subscription.plan.id === this.paymentEstimates.planId) {
      this.sampePlanId = true
    } else {
      this.isLoading = true
      try {
        const res: any = await AdoriService.fetchPaymentSubscriptionEstimates(
          this.networkId,
          this.paymentEstimates.planId
        )
        this.estimates = res
      } catch (error) {
        Sentry.captureException(error)
      }
      this.isLoading = false
    }
  }
}
