var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "loadMore", staticClass: "w-100" }, [
    _c(
      "button",
      {
        class: _vm.styleClass,
        on: {
          click: function ($event) {
            return _vm.buttonAction()
          },
        },
      },
      [
        _vm.icon
          ? _c(
              "i",
              {
                staticClass: "f6 material-icons white v-top",
                class: _vm.text ? "mr1" : "",
              },
              [_vm._v(_vm._s(_vm.icon))]
            )
          : _vm._e(),
        _vm.loading
          ? _c("div", {
              staticClass: "loader loader-inline dib mh1 relative",
              staticStyle: { top: "3px" },
            })
          : _vm._e(),
        _c("p", { staticClass: "ma0 dib" }, [
          _vm._v(_vm._s(_vm.loading ? "Loading more..." : _vm.text)),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }