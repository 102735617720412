var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "light-gray fw5 f3" }, [
          _vm._v("\n      Insert Ads into audio track\n      "),
          _vm.currentStep !== 0
            ? _c("span", [_vm._v("at " + _vm._s(_vm.formattedCurrentTime))])
            : _vm._e(),
        ]),
      ]),
      _c("template", { slot: "body" }, [
        _vm.currentStep === 1
          ? _c("div", { staticStyle: { width: "900px" } }, [
              _c(
                "div",
                {
                  staticClass:
                    "pb4 ml-auto mr-auto flex justify-center sticky bg-adori-gray z-5",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "pv1 ba bw2 br4 b--transparent bg-black-20",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "pv1 ph2 silver link pointer mr1",
                          class:
                            _vm.audioSource === "UPLOAD_NEW"
                              ? "ba br4 b--transparent bg-black-80 light-gray"
                              : "",
                        },
                        [
                          _c("input", {
                            staticClass: "input-reset",
                            attrs: { type: "radio" },
                            domProps: {
                              checked: _vm.audioSource === "UPLOAD_NEW",
                            },
                            on: {
                              click: function ($event) {
                                _vm.audioSource = "UPLOAD_NEW"
                              },
                            },
                          }),
                          _vm._v("Upload new audio Ad"),
                        ]
                      ),
                      _c(
                        "label",
                        {
                          staticClass: "pv1 ph2 silver link pointer",
                          class:
                            _vm.audioSource === "SELECT_EXISTING"
                              ? "ba br4 b--transparent bg-black-80 light-gray"
                              : "",
                        },
                        [
                          _c("input", {
                            staticClass: "input-reset",
                            attrs: { type: "radio" },
                            domProps: {
                              checked: _vm.audioSource === "SELECT_EXISTING",
                            },
                            on: {
                              click: function ($event) {
                                _vm.audioSource = "SELECT_EXISTING"
                              },
                            },
                          }),
                          _vm._v("Select from existing audio Ads"),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _vm.audioSource === "UPLOAD_NEW"
                ? _c(
                    "div",
                    { staticClass: "flex flex-column items-center pa4" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-100 h5 bg-adori-light-gray ba b--light-gray b--dashed br2 pa5 flex flex-column items-center justify-center",
                          class: _vm.isUploadingAudio
                            ? "not-allowed o-20"
                            : "link pointer dim",
                          on: { click: _vm.handleUploadAudio },
                        },
                        [
                          _c(
                            "i",
                            {
                              staticClass:
                                "material-icons f-headline silver mb2",
                            },
                            [_vm._v("publish")]
                          ),
                          _c("div", { staticClass: "f4 silver" }, [
                            _vm._v("Upload a new audio Ad"),
                          ]),
                        ]
                      ),
                      _vm.isUploadingAudio || _vm.uploadProgress === 100
                        ? _c(
                            "div",
                            {
                              staticClass: "pt4 flex flex-column items-center",
                            },
                            [
                              _c("progress", {
                                attrs: { max: "100" },
                                domProps: { value: _vm.uploadProgress },
                              }),
                              _c("div", { staticClass: "f6 silver pt2" }, [
                                _vm._v(
                                  "Uploading audio ad: " +
                                    _vm._s(_vm.uploadProgress + "%")
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _vm.audioSource === "SELECT_EXISTING"
                ? _c("div", [
                    _vm.sortedAvailableAdsArray.length !== 0
                      ? _c(
                          "div",
                          {
                            staticClass: "flex flex-column mb5 mt3",
                            staticStyle: { width: "900px" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "bg-adori-light-gray ph4 pv3" },
                              [
                                _c("div", { staticClass: "cf silver" }, [
                                  _c("div", { staticClass: "fl w-30" }, [
                                    _vm._v("Ad Title"),
                                  ]),
                                  _c("div", { staticClass: "fl w-60" }, [
                                    _vm._v("Description"),
                                  ]),
                                  _c("div", { staticClass: "fl w-10 tc" }, [
                                    _vm._v("Duration"),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "w-100 max-h5 overflow-y-auto scrollbar bg-adori-light-gray light-gray bb b--adori-gray",
                              },
                              _vm._l(
                                _vm.sortedAvailableAdsArray,
                                function (ad) {
                                  return _c("div", { key: ad.id }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "cf ph4 pv3 link darker-hover bt b--adori-gray bw1 pointer",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleAudioAdClick(ad.id)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "fl w-30 truncate pr2",
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(ad.name)),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "fl w-60 truncate" },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "w-100 dib" },
                                              [_vm._v(_vm._s(ad.description))]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "fl w-10 tc" },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.formattedTime(
                                                    ad.durationMillis
                                                  )
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ])
                                }
                              ),
                              0
                            ),
                          ]
                        )
                      : _c(
                          "div",
                          {
                            staticClass:
                              "white tc bg-adori-light-gray pv4 mb5 ba b--adori-gray br2",
                          },
                          [
                            _vm._v(
                              "\n          There are no audio Ads available. Please upload a new Ad.\n        "
                            ),
                          ]
                        ),
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "w-100 flex flex-wrap bg-adori-gray pt2",
                  staticStyle: {
                    position: "sticky",
                    bottom: "0",
                    "z-index": "100",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "w-third flex justify-start items-center" },
                    [
                      _c("BaseButtonBorder", {
                        attrs: {
                          text: "Back",
                          disabled:
                            _vm.audioSource === "UPLOAD_NEW" &&
                            _vm.isUploadingAudio,
                          onClick: _vm.handlePrevTab,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-third flex flex-column justify-center items-center",
                    },
                    [
                      _c("p", { staticClass: "silver mv0" }, [
                        _vm._v("Step 1 of 2"),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "w-third flex justify-end items-center" },
                    [
                      _c("BaseButtonBorder", {
                        attrs: {
                          text: "Next",
                          disabled:
                            (_vm.audioSource === "UPLOAD_NEW" &&
                              _vm.isUploadingAudio) ||
                            !_vm.uploadedAd,
                          onClick: _vm.handleNextTab,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ])
          : _vm.currentStep === 2
          ? _c("div", { staticStyle: { width: "900px" } }, [
              _c("div", { staticClass: "pb3 relative" }, [
                _c("div", { staticClass: "flex justify-between" }, [
                  _c(
                    "div",
                    { staticClass: "pb2 gray f6" },
                    [
                      _vm._v("\n            Audio ad name\n            "),
                      _c("BaseTooltip", {
                        attrs: {
                          info: "Audio ad name is a string containing a clear, concise name for your audio ad.",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "pb2 f6",
                      class: !_vm.$v.selectedAd.name.maxLength
                        ? "adori-red"
                        : "gray",
                    },
                    [
                      _vm._v(
                        "\n            (" +
                          _vm._s(_vm.$v.selectedAd.name.$model.length) +
                          " / 255)\n          "
                      ),
                    ]
                  ),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.$v.selectedAd.name.$model,
                      expression: "$v.selectedAd.name.$model",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass:
                    "db bg-adori-very-light-gray br2 ph3 pv3 gray f6 mb2 white w-100",
                  class:
                    _vm.$v.selectedAd.name.$error ||
                    (_vm.$v.selectedAd.name.$invalid && _vm.hasClickedNext)
                      ? "ba bw1 b--adori-red"
                      : "ba bw1 b--adori-transparent",
                  attrs: { type: "text", placeholder: "Enter audio ad name" },
                  domProps: { value: _vm.$v.selectedAd.name.$model },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.selectedAd.name,
                        "$model",
                        $event.target.value.trim()
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
                _vm.$v.selectedAd.name.$dirty && !_vm.hasClickedNext
                  ? _c(
                      "div",
                      {
                        staticClass: "absolute",
                        staticStyle: { bottom: "5px" },
                      },
                      [
                        !_vm.$v.selectedAd.name.required
                          ? _c("div", { staticClass: "f6 adori-red" }, [
                              _vm._v("Cannot be empty."),
                            ])
                          : _vm._e(),
                        !_vm.$v.selectedAd.name.maxLength
                          ? _c("div", { staticClass: "f6 adori-red" }, [
                              _vm._v("Must be less than 255 characters."),
                            ])
                          : _vm._e(),
                      ]
                    )
                  : _vm.hasClickedNext
                  ? _c(
                      "div",
                      {
                        staticClass: "absolute",
                        staticStyle: { bottom: "5px" },
                      },
                      [
                        _vm.$v.selectedAd.name.$invalid
                          ? _c("div", [
                              !_vm.$v.selectedAd.name.required
                                ? _c("div", { staticClass: "f6 adori-red" }, [
                                    _vm._v("Cannot be empty."),
                                  ])
                                : _vm._e(),
                              !_vm.$v.selectedAd.name.maxLength
                                ? _c("div", { staticClass: "f6 adori-red" }, [
                                    _vm._v("Must be less than 255 characters."),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "pb3 relative" }, [
                _c("div", { staticClass: "flex justify-between" }, [
                  _c(
                    "div",
                    { staticClass: "pb2 gray f6" },
                    [
                      _vm._v(
                        "\n            Audio ad description\n            "
                      ),
                      _c("BaseTooltip", {
                        attrs: {
                          info: "Audio ad description is a string containing a clear description for your audio ad.",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "pb2 f6",
                      class: !_vm.$v.selectedAd.description.maxLength
                        ? "adori-red"
                        : "gray",
                    },
                    [
                      _vm._v(
                        "\n            (" +
                          _vm._s(_vm.$v.selectedAd.description.$model.length) +
                          " / 255)\n          "
                      ),
                    ]
                  ),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.$v.selectedAd.description.$model,
                      expression: "$v.selectedAd.description.$model",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass:
                    "db bg-adori-very-light-gray br2 ph3 pv3 gray f6 mb2 white w-100",
                  class:
                    _vm.$v.selectedAd.description.$error ||
                    (_vm.$v.selectedAd.description.$invalid &&
                      _vm.hasClickedNext)
                      ? "ba bw1 b--adori-red"
                      : "ba bw1 b--adori-transparent",
                  attrs: {
                    type: "text",
                    placeholder: "Enter audio ad description",
                  },
                  domProps: { value: _vm.$v.selectedAd.description.$model },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.selectedAd.description,
                        "$model",
                        $event.target.value.trim()
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
                _vm.$v.selectedAd.description.$dirty && !_vm.hasClickedNext
                  ? _c(
                      "div",
                      {
                        staticClass: "absolute",
                        staticStyle: { bottom: "5px" },
                      },
                      [
                        !_vm.$v.selectedAd.description.maxLength
                          ? _c("div", { staticClass: "f6 adori-red" }, [
                              _vm._v(
                                "\n            Must be less than 255 characters.\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    )
                  : _vm.hasClickedNext
                  ? _c(
                      "div",
                      {
                        staticClass: "absolute",
                        staticStyle: { bottom: "5px" },
                      },
                      [
                        _vm.$v.selectedAd.description.$invalid
                          ? _c("div", [
                              !_vm.$v.selectedAd.description.maxLength
                                ? _c("div", { staticClass: "f6 adori-red" }, [
                                    _vm._v(
                                      "\n              Must be less than 255 characters.\n            "
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
              ]),
              _c(
                "div",
                {
                  staticClass: "w-100 flex flex-wrap bg-adori-gray pt2",
                  staticStyle: {
                    position: "sticky",
                    bottom: "0",
                    "z-index": "100",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "w-third flex justify-start items-center" },
                    [
                      _c("BaseButtonBorder", {
                        attrs: { text: "Back", onClick: _vm.handlePrevTab },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-third flex flex-column justify-center items-center",
                    },
                    [
                      _c("p", { staticClass: "silver mv0" }, [
                        _vm._v("Step 2 of 2"),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "w-third flex justify-end items-center" },
                    [
                      _c("BaseButtonBorder", {
                        attrs: { text: "Insert", onClick: _vm.handleNextTab },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ])
          : _c("div", [
              _c("div", { staticClass: "sticky bg-adori-gray pb2" }, [
                _c(
                  "div",
                  { staticClass: "flex justify-start db" },
                  [
                    _c("BaseThumbnailEpisode", {
                      staticClass: "mr3 bg-black",
                      attrs: { src: _vm.audioPreviewImage },
                    }),
                    _c("div", [
                      _c(
                        "div",
                        { staticClass: "f5 light-gray mb2 mw5 truncate" },
                        [_vm._v(_vm._s(_vm.audio.name))]
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "br2 mt4", staticStyle: { width: "900px" } },
                  [
                    _c(
                      "div",
                      { staticClass: "flex flex-wrap" },
                      _vm._l(
                        _vm.finalAdGroups,
                        function (item, curTime, index) {
                          return _c(
                            "span",
                            {
                              key: curTime,
                              staticClass: "white f7 absolute pointer",
                              style: {
                                top: "80px",
                                left:
                                  (curTime * 880) /
                                    (_vm.audio.durationMillis / 1000) -
                                  2 +
                                  "px",
                              },
                            },
                            [
                              _c(
                                "i",
                                {
                                  key: curTime + "-icon",
                                  staticClass:
                                    "material-icons v-mid f4 pointer mb1",
                                  on: {
                                    click: function ($event) {
                                      return _vm.toggleAdInfo(index)
                                    },
                                  },
                                },
                                [_vm._v("monetization_on")]
                              ),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.showAdInfo &&
                                        _vm.currentAd === index,
                                      expression:
                                        "showAdInfo && currentAd === index",
                                    },
                                  ],
                                  key: curTime + "-tooltip",
                                  staticClass:
                                    "tooltip absolute bottom-2 z-1 ba br2 b--adori-near-black pa2 shadow-2 bg-adori-medium-gray silver br2",
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      key: curTime + "-tooltip-text",
                                      staticClass:
                                        "tooltip-text lh-copy light-gray mv0 mh2 f6",
                                      staticStyle: { width: "100%" },
                                    },
                                    [_vm._v("Ad:" + _vm._s(item.join(",")))]
                                  ),
                                  _c("span", {
                                    key: curTime + "-tooltip-arrow",
                                    staticClass:
                                      "absolute bottom-5 left-38 nt2 w1 h1 bg-adori-black bl bt b--adori-near-black rotate-225 bg-adori-medium-gray",
                                  }),
                                ]
                              ),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "progressbar-wrapper mb3 mt1",
                        attrs: { title: "Seek" },
                      },
                      [
                        _c("progress", {
                          staticClass: "progressbar-progress",
                          style: !this.isUploadingAudio
                            ? " cursor: pointer;"
                            : "",
                          attrs: {
                            min: "0",
                            max: _vm.audio.durationMillis / 1000,
                          },
                          domProps: { value: _vm.currentTime },
                        }),
                        _c("input", {
                          staticClass: "progressbar-range",
                          style: !this.isUploadingAudio
                            ? " cursor: pointer;"
                            : "",
                          attrs: {
                            disabled: _vm.isUploadingAudio ? true : false,
                            type: "range",
                            min: "0",
                            max: _vm.audio.durationMillis / 1000,
                          },
                          domProps: { value: _vm.currentTime },
                          on: { input: _vm.onInputChange },
                        }),
                      ]
                    ),
                    _c(
                      "audio",
                      {
                        ref: "audio",
                        staticClass: "v-mid ml4",
                        attrs: { preload: "auto" },
                        on: {
                          timeupdate: function ($event) {
                            return _vm.updateTime()
                          },
                        },
                      },
                      [_c("source", { attrs: { src: _vm.audioSrc } })]
                    ),
                    _c("div", [
                      _c(
                        "div",
                        { staticClass: "bg-adori-medium-gray pa1 dib" },
                        [
                          _c("BaseButtonPlayer", {
                            staticClass: "mr2",
                            class: _vm.isUploadingAudio
                              ? "o-40 not-allowed"
                              : "pointer dim",
                            attrs: {
                              icon: "replay_10",
                              onClick: _vm.handleSeekBackward,
                            },
                          }),
                          _c("BaseButtonPlayer", {
                            staticClass: "mr2",
                            class: _vm.isUploadingAudio
                              ? "o-40 not-allowed"
                              : "pointer dim",
                            staticStyle: { width: "170px" },
                            attrs: {
                              icon: _vm.playButtonIcon,
                              text:
                                _vm.formattedCurrentTime +
                                " / " +
                                _vm.formattedTotalTime,
                              onClick: _vm.togglePlay,
                            },
                          }),
                          _c("BaseButtonPlayer", {
                            class: _vm.isUploadingAudio
                              ? "o-40 not-allowed"
                              : "pointer dim",
                            attrs: {
                              icon: "forward_10",
                              onClick: _vm.handleSeekForward,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "flex",
                          staticStyle: { float: "right", height: "45px" },
                        },
                        [
                          _vm.isUploadingAudio
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "flex flex-column items-center mr2",
                                },
                                [
                                  _c("div", { staticClass: "f6 silver pt3" }, [
                                    _vm._v(
                                      "Uploading Ad: " +
                                        _vm._s(_vm.uploadProgress + "%")
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _c("BaseButtonRed", {
                            staticClass: "mr2",
                            attrs: {
                              icon: "add",
                              text: _vm.isPlaying
                                ? "Pause audio to insert Ad"
                                : "Insert Ad at " + _vm.formattedCurrentTime,
                              disabled: _vm.isPlaying || _vm.isUploadingAudio,
                              onClick: _vm.handleInsertAd,
                            },
                          }),
                          _c("BaseButtonRed", {
                            attrs: {
                              text: "Save changes",
                              disabled:
                                _vm.isUploadingAudio ||
                                _vm.finalAdsArray.length === 0,
                              onClick: _vm.handleSaveChanges,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ]),
              _vm.finalAdsArray.length !== 0
                ? _c(
                    "div",
                    {
                      staticClass: "flex flex-column mb5 mt3",
                      staticStyle: { width: "900px" },
                    },
                    [
                      _c("div", { staticClass: "bg-near-black pa4 shadow-5" }, [
                        _c("span", { staticClass: "silver f5 fw3 mr3" }, [
                          _vm._v("Inserted Ads: "),
                        ]),
                        _c("span", { staticClass: "light-gray f5 fw3 mr3" }, [
                          _vm._v(_vm._s(_vm.finalAdsArray.length)),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "bg-adori-light-gray ph4 pv3" },
                        [
                          _c("div", { staticClass: "cf silver" }, [
                            _c("div", { staticClass: "fl w-30" }, [
                              _vm._v("Ad Title"),
                            ]),
                            _c("div", { staticClass: "fl w-30" }, [
                              _vm._v("Description"),
                            ]),
                            _c("div", { staticClass: "fl w-10 tc" }, [
                              _vm._v("Duration"),
                            ]),
                            _c("div", { staticClass: "fl w-10 tc" }, [
                              _vm._v("Position"),
                            ]),
                            _c("div", { staticClass: "fl w-20" }),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-100 max-h5 overflow-y-auto scrollbar bg-adori-light-gray light-gray bb b--adori-gray",
                        },
                        _vm._l(_vm.finalAdsArray, function (ad, index) {
                          return _c("div", { key: ad.audioId }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "cf ph4 pv3 link darker-hover bt b--adori-gray bw1",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "fl w-30 truncate pr2" },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(index) + " : " + _vm._s(ad.name)
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("div", { staticClass: "fl w-30 truncate" }, [
                                  _c("span", { staticClass: "w-100 dib" }, [
                                    _vm._v(_vm._s(ad.description)),
                                  ]),
                                ]),
                                _c("div", { staticClass: "fl w-10 tc" }, [
                                  _vm._v(_vm._s(ad.duration)),
                                ]),
                                _c("div", { staticClass: "fl w-10 tc" }, [
                                  _vm._v(_vm._s(ad.position)),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "fl w-20 pointer tc" },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "underline white",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleDeleteAd(index)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v("Remove Ad\n                  "),
                                        _c(
                                          "i",
                                          {
                                            staticClass:
                                              "material-icons f5 dib mr2 v-mid",
                                          },
                                          [_vm._v("delete")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ])
                        }),
                        0
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }