































import { Component, Mixins } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { mixin as clickaway } from 'vue-clickaway'
import Billing from '@/mixins/Billing'

@Component
export default class BaseCreditCardPopup extends Mixins(clickaway, Billing) {
  @Action toggleCreditCardAlert!: any

  get percentage() {
    return (this.limit / +this.lifetime_credits_given) * 100
  }

  get limit() {
    return +this.lifetime_credits_given - +this.creditBalanceAvailable
  }

  closeAlert() {
    this.toggleCreditCardAlert(false)
  }
}
