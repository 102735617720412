var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pa3 custom-h" }, [
    _vm._m(0),
    _c(
      "div",
      {
        staticClass:
          "flex flex-wrap flex-column flex-row-l justify-between-l items-start-l mb3",
      },
      [
        _c("div", { staticClass: "flex w-100 items-center mv2" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.podcastSearchText,
                expression: "podcastSearchText",
              },
            ],
            ref: "_podcastSearch",
            staticClass:
              "input-reset bn h-50 light-gray pa3 br3 br--left w-100",
            class: _vm.isStudioParent
              ? "bg-adori-light-gray"
              : "bg-adori-very-light-gray",
            attrs: {
              type: "search",
              placeholder: "Search for your podcasts or paste rss feed URL",
              autofocus: "",
            },
            domProps: { value: _vm.podcastSearchText },
            on: {
              keyup: function ($event) {
                return _vm.debouncedSearch()
              },
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.podcastSearchText = $event.target.value
              },
            },
          }),
          _c(
            "button",
            {
              staticClass:
                "h-50 ph3 bg-adori-red bn light-gray flex justify-center items-center br3 br--right link pointer dim",
              class: _vm.isSearchingPodcasts ? "o-50" : "",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.onPodcastSearch()
                },
              },
            },
            [
              !_vm.isSearchingPodcasts
                ? _c("i", { staticClass: "f3 material-icons" }, [
                    _vm._v("search"),
                  ])
                : _c("div", { staticClass: "loader loader-inline" }),
            ]
          ),
        ]),
      ]
    ),
    _vm.hasSearchedPodcasts
      ? _c(
          "div",
          {
            ref: "searchResults",
            staticClass: "max-h-podcast overflow-y-auto scrollbar",
          },
          [
            !_vm.isSearchingPodcasts && _vm.searchResults.length === 0
              ? _c("div", [
                  _c("div", { staticClass: "silver mb3 lh-copy fw5 mr4-l" }, [
                    _vm._v(
                      "\n        We were unable to find this podcast in our records. May we ask you to check for any possible errors and try\n        searching again?\n      "
                    ),
                  ]),
                ])
              : _vm.searchResults.length === 1
              ? _c("div", [
                  _c("div", { staticClass: "w-100 mb3" }, [
                    _c(
                      "div",
                      {
                        staticClass: "pa3 br3",
                        class:
                          _vm.searchResults[0].uid === _vm.selectedPodcastUid ||
                          _vm.searchResults[0].rss_url ===
                            _vm.selectedPodcastRssUrl
                            ? _vm.isStudioParent
                              ? "bg-adori-light-gray b--adori-red ba bw1 br3"
                              : "bg-adori-gray b--adori-red ba bw1 br3"
                            : _vm.isStudioParent
                            ? "g-adori-light-gray link pointer dim"
                            : "bg-adori-gray link pointer dim",
                        on: {
                          click: function ($event) {
                            _vm.selectedPodcastUid
                              ? _vm.selectPodcast(_vm.searchResults[0].uid)
                              : _vm.selectPodcastWithRssUrl(
                                  _vm.searchResults[0]
                                )
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "f5 white mb3" }, [
                          _vm._v(_vm._s(_vm.searchResults[0].name)),
                        ]),
                        _c("div", { staticClass: "cf" }, [
                          _c("div", { staticClass: "fl w-30 mw4" }, [
                            _c("img", {
                              attrs: { src: _vm.searchResults[0].imageUrl },
                            }),
                          ]),
                          _c("div", { staticClass: "fl w-70" }, [
                            _vm.searchResults[0].publisher
                              ? _c(
                                  "div",
                                  { staticClass: "f6 fw5 silver ml3 mb2" },
                                  [
                                    _vm._v(
                                      "\n                By " +
                                        _vm._s(_vm.searchResults[0].publisher) +
                                        "\n              "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.searchResults[0].description
                              ? _c("div", {
                                  staticClass: "f6 light-gray ml3 mb2",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.getPlainFormatText(
                                        _vm.searchResults[0].description
                                      )
                                    ),
                                  },
                                })
                              : _vm._e(),
                            _vm.searchResults[0].totalTracks
                              ? _c("div", { staticClass: "f6 silver ml3" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.searchResults[0].totalTracks) +
                                      " episodes\n              "
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                ])
              : _c("div", [
                  _c(
                    "div",
                    { staticClass: "podcasts mb3" },
                    _vm._l(_vm.searchResults, function (podcast) {
                      return _c(
                        "div",
                        {
                          key: podcast.uid,
                          staticClass: "pa3 bg-adori-gray br3",
                          class: {
                            "b--adori-red ba bw1 br3":
                              podcast.uid === _vm.selectedPodcastUid,
                            "link pointer dim":
                              podcast.uid !== _vm.selectedPodcastUid,
                            "bg-adori-gray": !_vm.isStudioParent,
                            "bg-adori-light-gray": _vm.isStudioParent,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.selectPodcast(podcast.uid)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "f5 white mb3 mw5-l truncate-l" },
                            [_vm._v(_vm._s(podcast.name))]
                          ),
                          _c("div", { staticClass: "cf" }, [
                            _c("div", { staticClass: "fl w-30" }, [
                              _c("img", {
                                staticStyle: { "max-height": "180px" },
                                attrs: { src: podcast.imageUrl },
                              }),
                            ]),
                            _c("div", { staticClass: "fl w-70" }, [
                              podcast.publisher
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "f6 fw5 silver ml3 mb2 mw5-l truncate-l",
                                    },
                                    [
                                      _vm._v(
                                        "\n                By " +
                                          _vm._s(podcast.publisher) +
                                          "\n              "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              podcast.description
                                ? _c("div", {
                                    staticClass: "f6 light-gray ml3 mb2",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.getPlainFormatText(
                                          podcast.description
                                        )
                                      ),
                                    },
                                  })
                                : _vm._e(),
                              podcast.totalTracks
                                ? _c("div", { staticClass: "f6 silver ml3" }, [
                                    _vm._v(
                                      _vm._s(podcast.totalTracks) + " episodes"
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
          ]
        )
      : _vm._e(),
    !(_vm.selectedPodcastUid || _vm.selectedPodcastRssUrl) &&
    _vm.searchResults.length > 1
      ? _c(
          "div",
          { staticClass: "flex mt3 justify-between items-center" },
          [
            _c("BaseButtonRed", {
              attrs: {
                text: "Prev",
                disabled: _vm.nextPage === 0,
                onClick: _vm.handlePreviousClick,
              },
            }),
            _c("div", { staticClass: "silver f6" }, [
              _vm._v(
                "\n      Page\n      " +
                  _vm._s(
                    (_vm.nextPage / 10 + 1).toLocaleString("en-US") +
                      " of " +
                      Math.ceil(_vm.totalPodcasts / 10).toLocaleString("en-US")
                  ) +
                  "\n    "
              ),
            ]),
            _c("BaseButtonRed", {
              attrs: {
                text: "Next",
                disabled: _vm.nextPage >= _vm.totalPodcasts - 10,
                onClick: _vm.handleNextClick,
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "flex w-100 justify-center mt2" },
      [
        _vm.searchResults.length === 1 &&
        (_vm.searchResults[0].uid === _vm.selectedPodcastUid ||
          _vm.searchResults[0].rss_url === _vm.selectedPodcastRssUrl)
          ? _c("BaseButtonRed", {
              attrs: {
                text: "Import a Podcast",
                loading: _vm.isFeedLoading || _vm.isCreateFeedLoading,
                disabled: _vm.isFeedLoading || _vm.isCreateFeedLoading,
                onClick: _vm.handleRedirect,
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex items-center justify-between" }, [
      _c("div", { staticClass: "light-gray mb2 mt3" }, [
        _vm._v("Find your podcast"),
      ]),
      _c(
        "a",
        { attrs: { href: "https://www.listennotes.com/", target: "_blank" } },
        [_c("img", { attrs: { src: require("@/assets/listennotes.png") } })]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }