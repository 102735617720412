import Vue from 'vue'
import { StoreOptions, GetterTree, MutationTree, ActionTree } from 'vuex'
import AdoriService from '@/services/adori'

const state: any = {
  showPane: false,
}

const getters: GetterTree<any, any> = {
  showPane: (state) => state.showPane,
}

const mutations: MutationTree<any> = {
  setShowPane(state, showPane) {
    Vue.set(state, 'showPane', showPane)
  },
}

const actions: ActionTree<any, any> = {
  setShowPane(context, showPane) {
    context.commit('setShowPane', showPane)
  },
}

const panes: StoreOptions<any> = {
  state,
  getters,
  mutations,
  actions,
}

export default panes
