



















































































import { Vue, Component } from 'vue-property-decorator'

@Component
export default class ModalTagPreview extends Vue {
  handleCloseButtonClicked() {
    this.$emit('close-preview')
  }

  orientation: string = 'PORTRAIT' // One of 'PORTRAIT' or 'LANDSCAPE'

  get imgSrc() {
    return this.$store.state.modal.tagPreview.img
  }
  get saveable() {
    return this.$store.state.modal.tagPreview.saveable
  }
  get shareable() {
    return this.$store.state.modal.tagPreview.shareable
  }
  get canvasOrientation() {
    return this.$store.state.modal.tagPreview.orientation
  }
  get tagType() {
    return this.$store.state.modal.tagPreview.tagType
  }
}
