











import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class BaseButtonSwitch extends Vue {
  // Position
  @Prop(Boolean) isLeft!: boolean
  @Prop(Boolean) disabled!: boolean
}
