var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "absolute bottom-0 w-100 flex justify-around items-center tag-acn-btns",
      class: _vm.bigger ? "bigger" : "",
    },
    [
      _c("img", {
        staticClass: "tag-btn",
        class: _vm.saveable ? "o-100" : "o-0",
        attrs: { src: require("@/assets/previewplayer/save.svg") },
      }),
      _c("img", {
        staticClass: "tag-btn",
        class: _vm.saveable ? "o-100" : "o-0",
        attrs: { src: _vm.tagTypeSrc },
      }),
      _c("img", {
        staticClass: "tag-btn",
        class: _vm.shareable ? "o-100" : "o-0",
        attrs: { src: require("@/assets/previewplayer/share.svg") },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }