import Vue from 'vue'
import { StoreOptions, GetterTree, MutationTree, ActionTree } from 'vuex'
import isEmpty from 'lodash.isempty'
import get from 'lodash.get'
import AdoriServiceV6 from '@/services/adori_v6'

const state: any = {
  fontsList: [],
  autoPublishFonts: ['Inter'],
  allAudiograms: [],
  audiogramIds: [],
  audiogramById: {},
  audiogramByIdOnTimeline: {},
  allAudiogramLandscapeTemplates: [],
  allAudiogramSquareTemplates: [],
  allAudiogramPortraitTemplates: [],
  allAudiogramTemplates: [],
  audiogramTemplatesCount: -1,
  audiogramTemplatesOffset: -1,
  allAudiogramWaveforms: [],
  allElementAssets: {},
}

const getters: GetterTree<any, any> = {
  fontsList: (state) => state.fontsList,
  autoPublishFonts: (state) => state.autoPublishFonts,
  allAudiograms: (state) => state.allAudiograms,
  audiogramIds: (state) => state.audiogramIds,
  audiogramById: (state) => state.audiogramById,
  audiogramByIdOnTimeline: (state) => state.audiogramByIdOnTimeline,
  audiogramTemplatesCount: (state) => state.audiogramTemplatesCount,
  audiogramTemplatesOffset: (state) => state.audiogramTemplatesOffset,
  allAudiogramLandscapeTemplates: (state) => state.allAudiogramLandscapeTemplates,
  allAudiogramSquareTemplates: (state) => state.allAudiogramSquareTemplates,
  allAudiogramPortraitTemplates: (state) => state.allAudiogramPortraitTemplates,
  allAudiogramWaveforms: (state) => state.allAudiogramWaveforms,
  allElementAssets: (state) => state.allElementAssets,
}

const mutations: MutationTree<any> = {
  setWebFonts(state, data) {
    Vue.set(state, 'fontsList', data.items)
  },

  setAudiogramByIdOnTimeline(state, data) {
    Vue.set(state.audiogramByIdOnTimeline, data.id, data)
  },

  clearAudioGrams(state) {
    Vue.set(state, 'allAudiograms', [])
    Vue.set(state, 'audiogramById', {})
    Vue.set(state, 'audiogramIds', [])
  },
  setAllAudiograms(state, allAudiograms) {
    let data = []
    if (get(state.allAudiograms, 'data', []).length === 0 || allAudiograms.offset === 0) {
      //@ts-ignore
      data = [...allAudiograms.data]
    } else data = [...state.allAudiograms.data, ...allAudiograms.data]

    Vue.set(state, 'allAudiograms', {
      data,
      offset: allAudiograms.offset,
      count: allAudiograms.count,
    })
    const audiogramIds = data.map((audiogram: any) => audiogram.id)

    Vue.set(state, 'audiogramIds', audiogramIds)

    const audiogramById = data.reduce((acc, obj) => {
      acc[obj.id] = obj
      return acc
    }, {})

    Vue.set(state, 'audiogramById', {
      ...state.audiogramById,
      ...audiogramById,
    })
  },

  setAudiogramTemplatesCount(state, audiogramsCount) {
    Vue.set(state, 'audiogramTemplatesCount', audiogramsCount)
  },

  setAudiogramTemplatesOffset(state, audiogramsOffset) {
    Vue.set(state, 'audiogramTemplatesOffset', audiogramsOffset)
  },
  setAllAudiogramTemplates(state, allAudiograms) {
    let landscapeTemplates = allAudiograms.filter((item: any) => item.orientation === 'LANDSCAPE')
    Vue.set(state, 'allAudiogramLandscapeTemplates', landscapeTemplates)
    let squareTemplates = allAudiograms.filter((item: any) => item.orientation === 'SQUARE')
    Vue.set(state, 'allAudiogramSquareTemplates', squareTemplates)
    let portraitTemplates = allAudiograms.filter((item: any) => item.orientation === 'PORTRAIT')
    Vue.set(state, 'allAudiogramPortraitTemplates', portraitTemplates)
  },

  deleteAudiogram(state, audiogramId) {
    Vue.delete(state.audiogramById, audiogramId)
  },
  setAllAudiogramWaveforms(state, waveforms) {
    Vue.set(state, 'allAudiogramWaveforms', waveforms)
  },

  setAllElementAssets(state, elements) {
    let groupElementsByCategory = elements.reduce((hash: any, obj: any) => {
      if (obj['category'] === undefined) return hash
      return Object.assign(hash, {
        [obj['category']]: (hash[obj['category']] || []).concat(obj),
      })
    }, {})
    Vue.set(state, 'allElementAssets', groupElementsByCategory)
  },
}

const actions: ActionTree<any, any> = {
  async getWebFonts(context) {
    const { data } = await AdoriServiceV6.fetchWebFonts()
    context.commit('setWebFonts', data)
    return data
  },

  async saveAudiogram(context, payload) {
    const data: any = await AdoriServiceV6.save_audiogram(payload)
    context.commit('setAudiogramByIdOnTimeline', data)

    return data
  },

  async getOneAudiogram(context, audiogramId) {
    const networkId = context.getters.networkId
    const audiogram = await AdoriServiceV6.get_one_audiograms(networkId, audiogramId)
    context.commit('setAudiogramByIdOnTimeline', audiogram)
  },

  async getAudiogramById(context, audiogramId) {
    const networkId = context.getters.networkId
    const audiogram = await AdoriServiceV6.get_one_audiograms(networkId, audiogramId)
    return audiogram
  },

  async getAudiograms(context, { orientation, limit = 10, offset = 0, clear = true }) {
    if (clear) {
      context.commit('clearAudioGrams')
    }
    const networkId = context.getters.networkId
    let params: { [key: string]: any } = {
      orientation,
      limit,
      offset,
    }
    const res: any = await AdoriServiceV6.get_all_audiograms(networkId, {
      params,
    })

    const audiograms: any = {
      data: res.data,
      count: res.count,
      offset: res.offset,
    }

    context.commit('setAllAudiograms', audiograms)
    return audiograms
  },

  async getAllAudiogramTemplates(context, { orientation, limit = 50, offset = 0 }) {
    const networkId = context.getters.networkId
    let params: { [key: string]: any } = {
      orientation,
      limit,
      offset,
    }
    const templates: any = await AdoriServiceV6.get_all_audiogram_templates(networkId, {
      params,
    })

    context.commit('setAllAudiogramTemplates', templates.data)
    context.commit('setAudiogramTemplatesCount', templates.count)
    context.commit('setAudiogramTemplatesOffset', templates.offset)
    return templates
  },

  async deleteAudiogram(context, audiogramId: string) {
    const networkId = context.getters.networkId
    await AdoriServiceV6.delete_audiogram(networkId, audiogramId)
    context.commit('deleteAudiogram', audiogramId)
  },
}

const audiogram: StoreOptions<any> = {
  state,
  getters,
  mutations,
  actions,
}

export default audiogram
