



























































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Mixins } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import get from 'lodash.get'

import { Sketch } from 'vue-color'
import { mixin as clickaway } from 'vue-clickaway'

import AudiogramTemplate from '@/components/Audiogram/AudiogramTemplate.vue'
import AudiogramWaveform from '@/components/Audiogram/AudiogramWaveform.vue'
import AudiogramElement from '@/components/Audiogram/AudiogramElement.vue'

import AudiogramSearchImages from '@/components/Audiogram/AudiogramSearchImages.vue'
import AudiogramImagesList from '@/components/Audiogram/AudiogramImagesList.vue'
import AudiogramSearchVideos from '@/components/Audiogram/AudiogramSearchVideos.vue'
import AudiogramVideosList from '@/components/Audiogram/AudiogramVideosList.vue'
import TheToggleButton from '@/components/Common/TheToggleButton.vue'

import Audiogram from '@/mixins/Audiogram'
import { audiogramQueryKeys } from '@/hooks/audiogram'
import ImageLibraries from '@/mixins/ImageLibraries'
import VideoLibraries from '@/mixins/VideoLibraries'

@Component({
  components: {
    AudiogramTemplate,
    AudiogramWaveform,
    AudiogramElement,
    Sketch,
    AudiogramSearchImages,
    AudiogramImagesList,
    AudiogramSearchVideos,
    AudiogramVideosList,
    TheToggleButton,
  },
})
export default class ModalAudiogramEdit extends Mixins(clickaway, Audiogram, ImageLibraries, VideoLibraries) {
  @Getter imageSearchResult!: any
  @Getter networkId!: string
  @Getter fontsList!: any
  @Getter autoPublishFonts!: any
  @Getter allAudiogramWaveforms!: any
  @Getter allElementAssets!: any
  @Getter isDuplicate!: boolean

  @Action saveAudiogram!: any
  @Action closeModal!: any
  @Action clearAudiogramEdit!: any
  @Action windowOrientation!: any

  get modalTitle() {
    if (this.$store.state.modal.audiogram.id) return 'Edit audiogram details'
    else return 'Create a new audiogram'
  }

  get shapesAssets() {
    return this.allElementAssets.shapes
  }

  get PatternsAssets() {
    return this.allElementAssets.patterns
  }

  get isOpenInEditMode() {
    return this.$store.state.modal.audiogram.id ? true : false
  }

  async mounted() {
    this.loadingCanvas = true
    this.isOpenInEditMode && this.isDuplicate && (this.saveText = 'Create New')
    if (this.$store.state.modal.audiogram.id) {
      this.initCanvasSize(this.$store.state.modal.audiogram.orientation)
      this.waveformName = this.$store.state.modal.audiogram.waveformName
    }

    if (this.$store.state.modal.audiogramOpenFrom) {
      this.initCanvasSize(this.$store.state.modal.audiogramOpenFrom)
    }

    await this.initCanvas(false)

    if (this.$store.state.modal.audiogram.id) {
      this.editAudiogram(this.$store.state.modal.audiogram.canvasData)
      this.autoTitleLineHeight = get(this.$store.state.modal.audiogram, 'autoTitle.lineHeight') || 20
      this.autoTitleLineLength = get(this.$store.state.modal.audiogram, 'autoTitle.lineLength') || 20
      if (this.$store.state.modal.audiogram.video) {
        this.videoId = this.$store.state.modal.audiogram.videoId
        this.getVideoElement(this.$store.state.modal.audiogram.video.originalUrl, true)
      }
    }

    this.loadingCanvas = false
    this.initCenteringGuidelines(this.canvasContext)
    this.initAligningGuidelines(this.canvasContext)
  }

  async handleSave() {
    const orientation = this.orientation
    this.audiogramLoader = true
    this.saveText = 'Saving...'
    this.upScaleCanvas()
    let canvas = this.canvasContext.toDatalessJSON(['id'])
    //remove waveform from image
    let top, left, width, height, waveformObj, videoCoords, autoTitle
    this.canvasContext.getObjects().forEach((obj: any) => {
      if (obj.id && obj.id === 'waveform') {
        top = obj.top
        left = obj.left
        width = obj.width * obj.scaleX
        height = obj.height * obj.scaleY
        this.waveformColorValue = obj.fill
        waveformObj = obj
        this.canvasContext.remove(obj)
      }
      //check if video is present in canvas
      if (obj.id && obj.id === 'video') {
        videoCoords = {
          x: obj.aCoords.tl.x,
          y: obj.aCoords.tl.y,
          height: obj.height * obj.scaleX,
          width: obj.width * obj.scaleY,
          rotate: Math.atan2(obj.aCoords.tr.y - obj.aCoords.tl.y, obj.aCoords.tr.x - obj.aCoords.tl.x),
        }
      }

      //hide autopublish title
      if (get(obj, 'id', '') === 'autoTitle') {
        autoTitle = {
          x: obj.left,
          y: obj.top,
          fontName: obj.fontFamily,
          fontSize: obj.fontSize,
          fontColor: obj.fill,
          lineHeight: this.autoTitleLineHeight,
          lineLength: this.autoTitleLineLength,
        }
        obj.set({ visible: false })
      }
    })

    let base64img = this.canvasContext.toDataURL({ quality: 1.0 })
    let encodeImg = base64img.split('base64,')[1]
    waveformObj && this.canvasContext.add(waveformObj)
    this.downScaleCanvas(orientation)

    try {
      const commonPayload = {
        height: this.height,
        width: this.width,
        orientation: orientation,
        canvasData: canvas,
        imageData: encodeImg,
        title: 'Untitled',
        videoId: videoCoords ? this.videoId : null,
        videoCoords: videoCoords ? videoCoords : null,
        autoTitle: this.autoPublishTitle ? autoTitle : null,
      }

      if (waveformObj) {
        const payload: any = {
          data: {
            ...commonPayload,
            waveformName: this.waveformName,
            waveformColor: this.waveformColorValue,
            waveformPosition: {
              x: left,
              y: top,
              height: height,
              width: width,
            },
          },
          params: {
            network_id: this.networkId,
          },
        }
        this.isOpenInEditMode &&
          !this.isDuplicate &&
          (payload['params']['audiogram_id'] = this.$store.state.modal.audiogram.id)

        await this.saveAudiogram(payload)
        this.$store.dispatch('audiogramOpenFrom', orientation)
        this.queryClient.invalidateQueries([audiogramQueryKeys.GET_AUDIOGRAMS, this.networkId, orientation])
      } else {
        const payload: any = {
          data: {
            ...commonPayload,
          },
          params: {
            network_id: this.networkId,
          },
        }
        this.isOpenInEditMode &&
          !this.isDuplicate &&
          (payload['params']['audiogram_id'] = this.$store.state.modal.audiogram.id)

        await this.saveAudiogram(payload)
        this.windowOrientation(orientation)
        this.queryClient.invalidateQueries([audiogramQueryKeys.GET_AUDIOGRAMS, this.networkId, orientation])
      }
      this.$store.dispatch('setIsDuplicate', false)
      this.closeModal()
    } catch (error) {
      console.log(error)
      this.audiogramLoader = false
      this.saveText = 'Save Changes'
      this.$store.dispatch('setIsDuplicate', false)
      this.closeModal()
    }
  }

  handleAutoPublishTitle() {
    this.autoPublishTitle = !this.autoPublishTitle
    if (this.autoPublishTitle) {
      this.insertTitlePlaceholder()
    } else {
      this.canvasContext.getObjects().forEach((obj: any) => {
        //check if autopublish's episode title is present in canvas
        if (get(obj, 'id', '') === 'autoTitle') {
          this.canvasContext.remove(obj)
        }
      })
    }
  }

  destroyed() {
    this.clearAudiogramEdit()
  }
}
