import Vue from 'vue'
import { StoreOptions, GetterTree, MutationTree, ActionTree } from 'vuex'
import AdoriService from '@/services/adori'

const state: any = {
  autoPublishFeed: {},
  autoPublishStep: 1,
  selectedPodcastSetting: {},
  showPodcastEnhance: false,
  allAutomations: {},
}

const getters: GetterTree<any, any> = {
  autoPublishFeed: (state) => state.autoPublishFeed,
  autoPublishStep: (state) => state.autoPublishStep,
  selectedPodcastSetting: (state) => state.selectedPodcastSetting,
  showPodcastEnhance: (state) => state.showPodcastEnhance,
  allAutomations: (state) => state.allAutomations,
}

const mutations: MutationTree<any> = {
  // This is to set the step for auto publish
  setAutoPublishStep(state, step) {
    Vue.set(state, 'autoPublishStep', step)
  },

  setSelectedPodcastSetting(state, data) {
    Vue.set(state, 'selectedPodcastSetting', { ...state.selectedPodcastSetting, ...data })
  },

  resetPodcastSetting() {
    Vue.set(state, 'selectedPodcastSetting', {})
  },

  clearAutoPublishState(state) {
    Vue.set(state, 'autoPublishStep', 1)
    Vue.set(state, 'autoPublishFeed', {})
  },

  setShowPodcastEnhance(state, status) {
    Vue.set(state, 'showPodcastEnhance', status)
  },

  setAutoPublishFeedData(state, data) {
    Vue.set(state, 'autoPublishFeed', data)
  },

  setAllAutomations(state, data) {
    Vue.set(state.allAutomations, data.type, data.data)
  },
}

const actions: ActionTree<any, any> = {
  ytPodcastModalSetting(context, data) {
    context.commit('setSelectedPodcastSetting', data)
  },
  podcastEnhance(context, status) {
    context.commit('setShowPodcastEnhance', status)
  },

  async autoPublishPodcast(_, payload) {
    await AdoriService.youtubeAutoPublish(payload)
  },

  async getAutoPublishData(context, payload) {
    const data: any = await AdoriService.getAutoPublish(payload)
    context.commit('setAutoPublishFeedData', data)
  },

  async updateAutoPublish(_, payload: any) {
    await AdoriService.updateAutoPublish(payload)
  },

  async enableDisableAutoPublish(_, payload: any) {
    if (payload.isEnabled) {
      await AdoriService.enableAutoPublish(payload)
    } else {
      await AdoriService.disableAutoPublish(payload)
    }
  },

  async getAllAutomations(context, payload) {
    const res = await AdoriService.getAllAutomations(payload.networkId, payload.params)
    context.commit('setAllAutomations', { data: res, type: payload?.params?.type || 'youtube' })
  },
}

const autoPublish: StoreOptions<any> = {
  state,
  getters,
  mutations,
  actions,
}

export default autoPublish
