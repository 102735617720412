var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasLoadedUser &&
    !_vm.hasAcceptedCookies &&
    ![
      "/login",
      "/signup",
      "/youtubeTerms",
      "/forgotpassword",
      "/otp",
      "/youtubeAuth",
    ].includes(_vm.$route.path) &&
    _vm.networkId
    ? _c(
        "div",
        {
          staticClass:
            "sticky flex justify-between items-center bg-light-gray no-select ph3 pv2 z-max",
        },
        [
          _vm._m(0),
          _c("BaseButtonRed", {
            staticClass: "no-shrink",
            attrs: { text: "Accept cookies", onClick: _vm.onAcceptCookies },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dark-gray lh-copy pa3" }, [
      _vm._v(
        "\n    We use cookies to personalize your experience and analyze site traffic. By continuing to use this website, you\n    consent to our\n    "
      ),
      _c(
        "a",
        {
          staticClass: "link dim pointer gray",
          attrs: {
            href: "https://help.adorilabs.com/terms-and-conditions/",
            target: "_blank",
          },
        },
        [_vm._v("terms of use")]
      ),
      _vm._v("\n    and\n    "),
      _c(
        "a",
        {
          staticClass: "link dim pointer gray",
          attrs: {
            href: "https://help.adorilabs.com/privacy-policy/",
            target: "_blank",
          },
        },
        [_vm._v("privacy policy")]
      ),
      _vm._v(".\n  "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }