import { Component, Vue } from 'vue-property-decorator'
import store from '@/store'
import { Getter, Action } from 'vuex-class'

@Component
export default class LazyLoader extends Vue {
  private iterations = 1

  increment = 10
  loading = false
  hasItemsLeft = true
  fetchingData = false
  storeUpdateOffset = 20
  lazyLoadDirection = 'DOWN' // UP

  @Action clearAllRssFeedItemsByFeedUid!: any

  private getItems(getterFn: Function) {
    return getterFn()
  }

  getItemsLazy(getterFn: Function) {
    return this.getItems(getterFn) ? this.getItems(getterFn).slice(0, this.iterations * this.increment) : []
  }

  loadMoreItems() {
    this.iterations += 1
  }

  resetIterations() {
    this.iterations = 1
  }

  setItemsLeft(itemsLeft: boolean) {
    this.hasItemsLeft = itemsLeft
  }

  isLoading() {
    return this.loading
  }

  async handleLoadMore(getterFn: Function, actionFn?: Function, params?: any) {
    const items = this.getItems(getterFn)

    if (items && items.data && items.count) {
      if (
        this.iterations * this.increment >= items.data.length &&
        items.count > items.data.length &&
        this.fetchingData
      ) {
        this.loading = true
      }

      if (this.lazyLoadDirection === 'UP') this.iterations -= 1
      else this.iterations += 1

      if (items.count < this.iterations * this.increment) {
        this.setItemsLeft(false)
      }
      if (items.data.length < (params.limit || params.params.limit || items.limit || 50)) {
        this.setItemsLeft(false)
      }

      if (
        items.data.length - this.storeUpdateOffset <= this.iterations * this.increment &&
        items.count > items.offset + (params.limit || params.params.limit || items.limit || 50) &&
        !this.fetchingData
      ) {
        await this.handleStoreUpdate(actionFn, params)
      }
    }
  }

  async handleStoreUpdate(actionFn?: any, params?: any) {
    try {
      this.fetchingData = true
      await actionFn({ ...params })
      this.fetchingData = false
      this.loading = false
    } catch (error) {
      this.hasItemsLeft = false
      this.fetchingData = false
    }
  }
}
