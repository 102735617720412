import Vue from 'vue'
import { StoreOptions, GetterTree, MutationTree, ActionTree } from 'vuex'
import AdoriService from '@/services/adori'
import store from '@/store'

import get from 'lodash.get'

const state: any = {
  publishList: [],
  signinStatus: {},
  categories: [],
  playlist: {},
  youtubeConf: {},
  step: 1,
  uploadState: false,
  scheduleUpload: false,
  btnLoader: false,
  schedulebtnLoader: false,
  youtubeUrl: [],
  uploadTaskData: [],
  selectedUpload: {},
  youtubeModalIndex: null,
  audioMaxLen: null,
  youtubeSettings: null,
  youtubeProfile: {},
}

const getters: GetterTree<any, any> = {
  publishList: (state) => state.publishList,
  signinStatus: (state) => state.signinStatus,
  youtubeConf: (state) => state.youtubeConf,
  categories: (state) => state.categories,
  playlist: (state) => state.playlist,
  step: (state) => state.step,
  uploadState: (state) => state.uploadState,
  scheduleUpload: (state) => state.scheduleUpload,
  btnLoader: (state) => state.btnLoader,
  schedulebtnLoader: (state) => state.schedulebtnLoader,
  youtubeUrl: (state) => state.youtubeUrl,
  uploadTaskData: (state) => state.uploadTaskData,
  selectedUpload: (state) => state.selectedUpload,
  youtubeModalIndex: (state) => state.youtubeModalIndex,
  audioMaxLen: (state) => state.audioMaxLen,
  youtubeSettings: (state) => state.youtubeSettings,
  youtubeProfile: (state) => state.youtubeProfile,
}

const mutations: MutationTree<any> = {
  setUploadTaskData(state, data) {
    const arr = [...data, ...state.uploadTaskData]
    Vue.set(state, 'uploadTaskData', arr)
  },

  setPublishList(state, data) {
    const publishList = []
    for (const item of data) {
      publishList.push(store.getters.audio(item))
    }
    Vue.set(state, 'publishList', publishList)
  },
  setSigninStatus(state, { id, data }) {
    if (state.signinStatus[id])
      Vue.set(state.signinStatus, id, {
        ...state.signinStatus[id],
        ...data,
      })
    else Vue.set(state.signinStatus, id, data)
  },

  setYoutubeProfile(state, data) {
    Vue.set(state, 'youtubeProfile', data)
  },
  setYouTubeConf(state, data) {
    Vue.set(state, 'youtubeConf', data)
  },
  setCategories(state, data) {
    Vue.set(state, 'categories', data)
  },
  setPlaylist(state, { id, data }) {
    if (state.playlist[id]) {
      Vue.set(state.playlist, id, data)
    } else Vue.set(state.playlist, id, data)
  },
  setStep(state, data) {
    Vue.set(state, 'step', data)
  },
  selectedPublishStatus(state, data) {
    Vue.set(state, 'selectedUpload', data)
  },
  setUploadState(state, data) {
    Vue.set(state, 'uploadState', true)
    setTimeout(() => {
      Vue.set(state, 'uploadState', false)
    }, 10000)
  },
  setScheduleState(state, data) {
    Vue.set(state, 'scheduleUpload', true)
    setTimeout(() => {
      Vue.set(state, 'scheduleUpload', false)
    }, 10000)
  },
  setBtnLoader(state, data) {
    Vue.set(state, 'btnLoader', data)
  },
  setScheduleBtnLoader(state, data) {
    Vue.set(state, 'schedulebtnLoader', data)
  },
  setYoutubeUrl(state, data) {
    state.youtubeUrl.push(data)
  },

  clearPublish(state) {
    Vue.set(state, 'youtubeConf', {})
    Vue.set(state, 'selectedUpload', {})
    Vue.set(state, 'youtubeUrl', [])
    Vue.set(state, 'btnLoader', false)
    Vue.set(state, 'schedulebtnLoader', false)
  },

  setYoutubeModalIndex(state, data) {
    Vue.set(state, 'youtubeModalIndex', data)
  },
  setAudioMaxLen(state, data) {
    Vue.set(state, 'audioMaxLen', data)
  },
  clearYoutubeSettings(state) {
    Vue.set(state, 'youtubeSettings', [])
  },

  updateYoutubeSetting(state, data) {
    Vue.set(state.youtubeSettings, data.index, data)
  },

  setYoutubeSettings(state, data) {
    Vue.set(state, 'youtubeSettings', data)
  },
}

const actions: ActionTree<any, any> = {
  findTaskData(context, audioId) {
    return state.uploadTaskData.find((obj: any) => obj.trackUid === audioId)
  },
  async youtubeLogin(context, payload) {
    const res = await AdoriService.youtube_authorize(payload.networkId, payload.next, payload.showId)
    return res
  },

  async youtubeSignout(context, payload) {
    const res = await AdoriService.youtube_revoke(payload.networkId, payload.showId)
    return res
  },

  async youtubeAccount(context, payload) {
    const res: any = await AdoriService.youtube_linked_account(payload.networkId, payload.showId)
    context.commit('setYoutubeProfile', res[0].gProfile)
  },

  async youtubeCategories(context, payload) {
    const data = await AdoriService.youtube_categories(payload.networkId, payload.showId)
    context.commit('setCategories', data)
  },

  async youtubePlaylist(context, payload) {
    await AdoriService.youtube_playlist_sync(payload.networkId, payload.showId)
    const data = await AdoriService.youtube_playlist(payload.networkId, payload.showId)
    context.commit('setPlaylist', { id: payload.showId ? payload.showId : payload.networkId, data: data.data })
    return data
  },

  async getIntegrationsSummary(context, payload) {
    const data: any = await AdoriService.getIntegrationsSummary(payload.networkId, payload.showId)
    context.commit('setSigninStatus', { id: payload.showId ? payload.showId : payload.networkId, data: data })
  },
  async youtubeUpload(context, payload) {
    const data: any = await AdoriService.youtube_upload(payload)
    return data
  },
  async youtubeUploadTask(context, payload) {
    const data: any = await AdoriService.youtube_upload_task(payload.params, payload.network_id)
    return data.data
  },
  async youtubeUpdateTask(context, payload) {
    const data: any = await AdoriService.youtube_update_task(payload)
    return data
  },
  async youtubeUploadStatus(context, payload) {
    if (payload.uid) {
      const { data }: any = await AdoriService.youtube_upload_status_by_uid(payload)
      return data
    } else {
      const data: any = await AdoriService.youtube_upload_status(payload)
      return data
    }
  },

  youtubeModalIndex(context, payload) {
    context.commit('setYoutubeModalIndex', payload)
  },

  audioMaxLen(context, payload) {
    context.commit('setAudioMaxLen', payload)
  },

  youtubeSetting(context, payload) {
    context.commit('updateYoutubeSetting', payload)
  },
}

const publish: StoreOptions<any> = {
  state,
  getters,
  mutations,
  actions,
}

export default publish
