













import { Vue, Component } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import get from 'lodash.get'

// components
import AudioAdPreview from '@/components/Audio/AudioAdPreview.vue'
// constants
import { ADORI_API_BASE_URL } from '@/constants'
import { daysRemaining } from '@/utils/time'
import { integer } from 'vuelidate/lib/validators'

// enum and interfaces
import { AudioAdType } from '@/components/Tags/tags'

// utils
import { getQueryParam } from '@/utils/misc'

@Component({ components: { AudioAdPreview } })
export default class ModalAudioAdPreview extends Vue {
  @Getter tag!: any
  tracks: any[] = []
  hasClickedSaveAdPreview: boolean = false
  audioUid = getQueryParam('uid')

  get audio() {
    return this.$store.getters.audio(this.audioUid)
  }

  async mounted() {
    // Get the main track first
    const tagPositions = this.$store.getters.tagPositions(this.audioUid) || []
    const audioUrl = `${ADORI_API_BASE_URL}/networks/${this.$store.getters.networkId}/audiotracks/${this.audioUid}/audio?api_token=${this.$store.getters.token}`
    this.tracks.push({
      url: audioUrl,
      start: 0,
      duration: this.audio.durationMillis / 1000,
      label: this.audio.name,
      type: 'MAIN',
      track_id: this.audio.uid,
      id: 9999, // some high number
    })

    // Prepare audio Ad list
    let i = 0
    tagPositions.forEach((tagDetails: any) => {
      if (!get(tagDetails, 'tag')) {
        tagDetails.tag = this.tag(tagDetails.tagId)
      }
      if (get(tagDetails, 'tag.actions', '') === 'audio') {
        const type = AudioAdType.AdSlot

        const duration = tagDetails.durationMillis

        this.tracks.push({
          url: tagDetails.tag.url,
          start: tagDetails.offsetMillis / 1000, // in sec
          duration: duration / 1000, // in sec
          label: tagDetails.tag.caption,
          type: type,
          track_id: tagDetails.id,
          id: i,
        })
      }
      i++
    })
  }
  onSaveAdPreview(data: any) {
    let tags = JSON.parse(data)
    Promise.all(
      tags.map((tag: { [key: string]: any }) => {
        this.$store.dispatch('moveAudioTag', {
          audioUid: this.audioUid,
          index: tag.id,
          toPosition: (tag.rel_start * 1000) | 0,
          action: 'audio',
        })
      })
    )
      .then(() => {
        this.hasClickedSaveAdPreview = true
      })
      .catch((err: any) => console.error(err))
  }
  beforeDestroy() {
    this.tracks = []
  }
}
