






































































































































































































































































































































































































































































































































































import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { titleList, descriptionList, thumbNailList, keywordDataList } from '@/components/AI/setting'
import { computed, reactive, ref, watch } from '@vue/composition-api'
import { useGetDalleResults, useGetOpenAiContext, useGetStabilityResults } from '@/hooks/openAi'
import get from 'lodash.get'
import debounce from 'lodash.debounce'
import ImageLibraries from '@/mixins/ImageLibraries'
import ImagesList from '@/components/Common/Images/ImagesList.vue'
import GifGallery from '@/components/Common/Images/GifGallery.vue'
import AdoriService from '@/services/adori'

@Component({
  components: { ImagesList, GifGallery },
  setup() {
    const openAiRes = ref('')
    const openAiParams = reactive({
      topic: '',
      max_tokens: 4000,
      temperature: 0.7,
      description: '',
      template: '',
      keywords: '',
      voiceTone: 'Default',
      writingStyle: 'Default',
      language: 'English',
    })
    const isEnabled = computed(() => !!openAiParams.template || !!openAiParams.topic)

    const { data: openAiData, isFetching: isOpenAiFetching } = useGetOpenAiContext(openAiParams, {
      enabled: isEnabled,
    })

    watch(openAiData, (curVal) => {
      openAiRes.value = curVal
    })

    const stabilityParams = reactive({
      prompt: '',
      id: null,
    })
    const interval: any = ref(false)
    const isStabilityActive = computed(() => !!stabilityParams.prompt)
    const { data: stabilityResults, isFetching: isStabilityLoading } = useGetStabilityResults(
      stabilityParams,
      {
        enabled: isStabilityActive,
      },
      interval,
      (data: any) => {
        if (data.status === 'processing' || data.status === null) {
          stabilityParams.id = data.id
          interval.value = 0.1
        } else {
          stabilityParams.id = data.id
          interval.value = false
        }
      }
    )

    return {
      openAiRes,
      openAiData,
      isOpenAiFetching,
      openAiParams,
      stabilityParams,
      stabilityResults,
      isStabilityLoading,
    }
  },
})
export default class AIPanel extends Mixins(ImageLibraries) {
  @Getter networkId!: string
  @Getter networkType!: string

  @Prop() data!: any
  @Prop() selectedItem!: string
  @Prop() scene!: any
  @Prop() context!: string
  @Prop() onSelectItem!: any
  @Prop() onClose!: () => void
  @Prop({ default: false })
  isLoading!: boolean

  @Action setRssFeedsSortOrder!: any
  @Action setShowPane!: any

  disableClosePane = false
  selectedImageTab: string = 'UNSPLASH' // or 'UNSPLASH' or 'UPLOAD' or 'MY_LIBRARY'
  selectedBlogTab: string = 'BLOG'
  previewImageSrc: string = ''
  bgDragColor = false
  uploadingImage = false

  search = ''
  podcastSortOrder: string = ''
  category: any = ''
  generatedText = ''
  selectedTitle = 'existing'
  selectedDesc = 'seo'
  selectedThumbnail = 'title'
  selectedKeywords = 'title'
  showAISceneText = false
  selectedSceneCategory = 'AI_TEXT'
  searchQuery = ''
  suggestedMusic = ['Music 1', 'Music 2']

  openAiData!: any
  openAiRes!: any
  openAiParams!: any
  isOpenAiFetching: boolean = false
  stabilityParams!: any
  stabilityResults!: any
  isStabilityLoading!: boolean
  aiTitle = []
  aiDescription = ''
  sceneAiText = ''
  aiKeywords = ''
  SceneTextModification = [
    {
      text: 'Make the text shorter',
      prompt: 'Make the text shorter in few meaningful words',
      icon: 'content_cut',
      isAiImage: false,
    },
    {
      text: 'Make Text More Engaging',
      prompt: 'Make Text More Engaging in few sentences',
      icon: 'tips_and_updates',
      isAiImage: false,
    },
    { text: 'Make Text More Professional', prompt: 'Make Text More Professional', icon: 'work', isAiImage: false },
    { text: 'Make Text Sound Happier', prompt: 'Make Text Sound Happier', icon: 'mood', isAiImage: false },
    { text: 'Re-write text ', prompt: 'Re-write text', icon: 'edit_note', isAiImage: false },
    // { text: 'Generate Image for this Scene', icon: 'auto_awesome', isAiImage: true },
  ]

  get selectedSceneImage() {
    return get(this.scene, 'image', null)
  }

  get isPremium() {
    return ['VIDEO_PRO', 'VIDEO_BASIC'].includes(this.networkType)
  }

  viewImages(image: string) {
    this.$emit('viewImages', image)
  }

  selectedModification = { text: 'Make Text Shorter', icon: 'content_cut', isAiImage: false }

  selectCategory(key: any) {
    this.category = key
  }

  get searchLibraries() {
    return [
      {
        LibraryName: 'UNSPLASH',
        name: 'Unsplash',
      },
      {
        LibraryName: 'GOOGLE',
        name: 'Google',
      },
      {
        LibraryName: 'GETTY',
        name: 'Getty',
      },
      {
        LibraryName: 'PIXABAY',
        name: 'Pixabay',
      },
      {
        LibraryName: 'MY_LIBRARY',
        name: 'My Library',
      },
    ]
  }

  get aiImgList() {
    return get(this, 'stabilityResults.url', '')
  }

  get topic() {
    return this.data[this.category]
  }
  get titleListData() {
    return titleList
  }
  get descriptionListData() {
    return descriptionList
  }
  get thumbNailList() {
    return thumbNailList
  }
  get keyWordsList() {
    return keywordDataList
  }

  get thumbnailSrc() {
    return
  }

  async dropImageFiles(e: any) {
    this.bgDragColor = false
    const files = e.dataTransfer.files

    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      const type = get(file, 'type', '')

      if (!type.includes('image')) {
        this.$store.dispatch('pushNotification', {
          text: 'Please choose valid image',
          type: 'ERROR',
        })
        continue
      }

      if (file) {
        await this.uploadImage(file)
      }
    }
    this.uploadingImage = false
  }

  imageUploader() {
    this.disableClosePane = true
    this.$store.dispatch('showFileUploader', {
      accept: 'image/*',
      onChange: this.loadImageFile,
      multiple: true,
    })
    setTimeout(() => {
      this.disableClosePane = false
    }, 500)
  }

  async loadImageFile() {
    const files = this.$store.getters.selectedFile
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      if (file) {
        await this.uploadImage(file)
      }
    }
    this.uploadingImage = false
  }

  async uploadImage(file: any) {
    this.uploadingImage = true
    try {
      await AdoriService.uploadImage(this.networkId, file)
      this.resetImageGallery()
    } catch (error) {
      this.uploadingImage = false
    }
  }

  clearSearchQuery() {
    this.searchQuery = ''
    this.imageSearchText = ''
    this.resetOffset()
  }

  handleAiTextTab() {
    this.selectedSceneCategory = 'AI_TEXT'
  }
  handleAiImageTab() {
    this.selectedSceneCategory = 'AI_IMAGE'
  }

  handleSelectImage(imageObj: any) {
    this.onSelectItem({
      context: this.context,
      image: imageObj.url,
      scene: this.scene,
    })
  }

  debouncedSearch = debounce(() => {
    this.searchImages()
  }, 600)

  async searchImages() {
    if (['SCENE_BLOG_IMAGE', 'MAIN_BLOG_IMAGE'].includes(this.context) && this.selectedBlogTab === 'IMAGES')
      this.imageSearchText = this.searchQuery
    if (this.context === 'BLOG_TEXT') {
      this.stabilityParams.id = null
      this.stabilityParams.prompt = this.searchQuery
    }
  }

  getAiTitle() {
    this.openAiParams.template = 'YOUTUBE_TITLE'
    this.openAiParams.description = this.selectedTitle === 'existing' ? this.data.title : this.data.description
    this.openAiParams.topic = ''
  }
  getAiDescription() {
    this.openAiParams.template = ''
    this.openAiParams.description = ''
    this.openAiParams.topic = `Generate youtube description from the text suitable for SEO:  ${this.data.description}`
  }
  getAiPrompt() {
    this.openAiParams.template = ''
    this.openAiParams.description = ''
    this.openAiParams.topic = `write an dall-e image prompt for this ${
      this.selectedThumbnail === 'title' ? 'title: ' + this.data.title : 'description: ' + this.data.description
    }`
  }
  getAiKeywords() {
    this.openAiParams.template = ''
    this.openAiParams.description = ''
    this.openAiParams.topic = `Extract comma seperated keywords from this text: ${
      this.selectedKeywords === 'title' ? this.data.title : this.data.description
    }`
  }

  getAiImageSearchText() {
    this.openAiParams.template = ''
    this.openAiParams.description = ''
    this.openAiParams.topic = `write an image prompt for this description: ${this.scene.text}. Give only prompt as response.`
    if (this.openAiRes) {
      this.handleOpenAiRes()
    }
  }

  @Watch('selectedBlogTab')
  @Watch('scene')
  handleBlogTab() {
    // if (this.selectedBlogTab === 'IMAGES') this.getAiImageSearchText()
  }

  @Watch('openAiRes')
  handleOpenAiRes() {
    if (this.context === 'AI_TITLE')
      this.aiTitle = this.openAiRes.split('\n').map((item: any) => item.replace(/^\d+\.\s*/, ''))
    if (this.context === 'AI_DESCRIPTION') this.aiDescription = this.openAiRes
    if (this.context === 'AI_THUMBNAIL') {
      this.stabilityParams.prompt = this.openAiRes
    }
    if (this.context === 'AI_KEYWORDS') {
      this.aiKeywords = this.openAiRes
    }
    if (this.context === 'BLOG_TEXT') {
      if (this.selectedModification.isAiImage) {
        this.stabilityParams.prompt = this.openAiRes
        this.searchQuery = this.openAiRes
        this.showAISceneText = true
      } else {
        this.sceneAiText = this.openAiRes
        this.showAISceneText = true
      }
    }
    if (['SCENE_BLOG_IMAGE', 'MAIN_BLOG_IMAGE'].includes(this.context)) {
      this.searchQuery = this.openAiRes
      this.searchImages()
    }
  }
  ModifySceneText(item: any) {
    this.selectedModification = item
    this.openAiParams.template = ''
    this.openAiParams.description = ''
    this.openAiParams.topic = `${
      item.isAiImage
        ? 'without any extra text in response, write a short stability image prompt for this description: '
        : item.prompt
    }: ${this.scene.text}`
  }
}
