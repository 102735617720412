






























































import { NetworkQueryKeys } from '@/hooks/network'
import Invalidate from '@/mixins/Invalidate'
import { Component, Mixins } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

@Component
export default class ModalAddMember extends Mixins(Invalidate) {
  @Getter roles!: any
  @Getter networkId!: any
  @Getter isYoutube!: Boolean

  @Action addNetworkUser!: any
  @Action getNetworkUsers!: any
  @Action setShowLoader!: any
  @Action closeModal!: any
  @Action pushNotification!: any

  emailId = ''
  isValidEmail = true
  isEmptyEmail = false
  selectedRole = ''
  isSubmitting: boolean = false
  isNetworkOwner: boolean = false

  async created() {
    this.selectedRole = this.filteredRoles[0].name
  }

  get filteredRoles() {
    if (this.isYoutube) {
      return [
        { name: 'Select role' },
        ...this.roles.filter((role: any) => role.ytEnabled && role.name !== 'Network Owner'),
      ]
    }
    return [{ name: 'Select role' }, ...this.roles.filter((role: any) => role.name !== 'Network Owner')]
  }

  get disableAddMember() {
    return !(this.isValidEmail && this.emailId && this.selectedRole !== 'Select role')
  }

  get descriptions() {
    if (this.isYoutube) {
      return this.filteredRoles.filter((role: any) => role.name === this.selectedRole)[0].ytDescription
    }
    return this.filteredRoles.filter((role: any) => role.name === this.selectedRole)[0].description
  }

  emailChange() {
    if (this.emailId) {
      this.isEmptyEmail = false
    } else {
      this.isEmptyEmail = true
    }
    /* eslint-disable no-useless-escape */
    const re =
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    this.isValidEmail = this.emailId ? re.test(this.emailId) : true
  }

  async addMember() {
    this.setShowLoader(true)
    if (!this.emailId) {
      this.isEmptyEmail = true
    }
    await this.addNetworkUser({
      email: this.emailId,
      role: this.selectedRole,
    })
    this.queryClient.invalidateQueries([NetworkQueryKeys.NETWORKUSERS, this.networkId])
    this.closeModal()
    this.setShowLoader(false)
  }
}
