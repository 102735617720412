export function isImage(filename: string) {
  return /\.(jpe?g|png|gif)$/i.test(filename)
}

export function isPodcastShowImage(filename: string) {
  return /\.(jpe?g|png)$/i.test(filename)
}

export function contains<T>(arr: T[], e: T) {
  return arr.indexOf(e) !== -1
}

export function removeDuplicates<T>(arr: T[]) {
  return [...new Set(arr)]
}

export function removeDuplicatesByProp(array: any, prop: any) {
  return array.filter((elt: any, idx: any, arr: any) => {
    return arr.map((elt: any) => elt[prop]).indexOf(elt[prop]) === idx
  })
}

export function convertJsonToCss(obj: any) {
  return JSON.stringify(obj)
    .replace(/,/g, ';')
    .replace(/"|'|{|}/g, '')
}

export function isWebLink(param: string) {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // fragment locator
  return pattern.test(param)
}

export function removeStyles(html: string) {
  /* eslint-disable-next-line */
  const regex = /style=("|')(?:[^\1\\]|\\.)+?\1/gi
  const subst = ``
  // The substituted value will be contained in the result variable
  const result = html.replace(regex, subst)
  return result
}

export function formatOpenAiCaption(caption: string) {
  return caption.replace(/\r?\n/gi, '')
}

export function formatOpenAiPoll(pollString: string) {
  const tempArr = pollString.split('?')
  let question = tempArr[0]
  let choices = tempArr
    .slice(1, tempArr.length)[0]
    .split('\n')
    .filter((choice: string) => choice && choice)
  question = question.replace(/(\d+\)\s)/gi, '')
  question = question.replace(/(\d+\.\s)/gi, '')
  question = question.replace(/^\s+|\s$/gi, '')

  const poll: any = {}
  poll['topic'] = question
  poll['id'] = 'pollId'
  choices.map((choice: string, index: number) => {
    poll[`choice${index + 1}`] = choice
  })
  return poll
}

export function formatOpenAiKeywords(keywords: string) {
  let tempKeywords = ''
  tempKeywords = keywords.replace(/\r?\n-/gi, '')
  tempKeywords = tempKeywords.replace(/\r?\n\n/gi, '')
  return tempKeywords
}

export function getQueryParam(param: string) {
  const query = window.location.search.substring(1)
  const params = query.split('&')

  for (let v of params) {
    const pair = v.split('=')
    if (pair[0] === param) return pair[1]
  }
  return null
}

export function getHashParam(param: string) {
  const query = window.location.hash.substring(1)
  const params = query.split('&')

  for (let v of params) {
    const pair = v.split('=')
    if (pair[0] === param) return pair[1]
  }
  return null
}

export function pluralize(quantity: number, singular: string, plural: string) {
  if (quantity === 1) return singular
  else return plural
}

// Round a number n to d decimal places
export function round(n: number, d: number) {
  return Math.round(n * d * 100) / (d * 100)
}

export function insertIntoSortedArray(arr: any, obj: any, key: any) {
  for (var i = 0, len = arr.length; i < len; i++) {
    if (obj[key] <= arr[i][key]) {
      arr.splice(i, 0, obj)
      return arr
    }
  }

  arr.push(obj)
  return arr
}

export function capitalizeFirstLetter(string: string) {
  string = string.replace(/_/g, ' ')
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function truncateStringAtWord(input: string, maxLength: number = 1024): string {
  if (input.length <= maxLength) return input
  const truncated = input.slice(0, maxLength + 1)
  const lastSpaceIndex = truncated.lastIndexOf(' ')
  return lastSpaceIndex === -1 ? truncated.slice(0, maxLength) : truncated.slice(0, lastSpaceIndex)
}
