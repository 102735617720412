
































































import { Component, Mixins } from 'vue-property-decorator'
import AudiogramImagesList from '@/components/Audiogram/AudiogramImagesList.vue'
import debounce from 'lodash.debounce'
import ImageLibraries from '@/mixins/ImageLibraries'
import ModalPreviewImages from '@/components/Modals/ModalPreviewImages.vue'

@Component({
  components: {
    AudiogramImagesList,
    ModalPreviewImages,
  },
})
export default class AudiogramSearchImages extends Mixins(ImageLibraries) {
  selectedImageTab: string = 'UNSPLASH'

  searchQuery: string = ''
  showImagePreview = false
  image = ''

  created() {
    this.selectedImageTab = 'UNSPLASH'
  }

  get searchLibraries() {
    return [
      {
        LibraryName: 'UNSPLASH',
        name: 'Unsplash',
      },
      {
        LibraryName: 'GOOGLE',
        name: 'Google',
      },
      {
        LibraryName: 'GETTY',
        name: 'Getty',
      },
      {
        LibraryName: 'PIXABAY',
        name: 'Pixabay',
      },
      {
        LibraryName: 'AI',
        name: 'AI',
      },
    ]
  }

  viewImages(image: string) {
    this.showImagePreview = true
    this.image = image
  }

  clearSearchQuery() {
    this.searchQuery = ''
    this.imageSearchText = ''
    this.resetOffset()
  }

  debouncedSearch = debounce(() => {
    this.searchImages()
  }, 600)

  searchImages() {
    this.stabilityParams.prompt = this.searchQuery
    this.imageSearchText = this.searchQuery
  }
}
