















import { Component, Vue } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import AdoriService from '@/services/adori'

@Component
export default class LanguageSelector extends Vue {
  @Getter languages!: any
  @Action getLanguages!: any

  selectedLang: string = 'en-US'
  languageDisabled: boolean = false

  handleLangChange() {
    this.$emit('on-lang-change', this.selectedLang)
  }

  async mounted() {
    await this.getLanguages()
  }
}
