var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bg-adori-gray rubik relative", attrs: { id: "app" } },
    [
      _vm.showLoader
        ? _c(
            "div",
            {
              staticClass:
                "flex justify-center items-center absolute z-max bg-black-50 fixed",
              staticStyle: {
                width: "99.5vw",
                height: "100vh",
                "pointer-events": "none",
              },
              style: "margin-top: " + _vm.scrollY() + "px",
            },
            [
              _c("div", { staticClass: "flex flex-column items-center" }, [
                _c("div", { staticClass: "loader" }),
                _c("div", { staticClass: "silver mt4" }, [
                  _vm._v(_vm._s(_vm.showLoaderText)),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm.initialLoader
        ? _c(
            "div",
            {
              staticClass: "flex justify-center items-center",
              staticStyle: { height: "100vh" },
            },
            [_c("div", { staticClass: "bar-loader" })]
          )
        : _c(
            "div",
            [
              _vm.networkId && _vm.isHomeRoute
                ? _c(
                    "div",
                    [
                      _c("TheAlertPrompt"),
                      _c("TheCookiesPrompt"),
                      _c("TheBillPayPrompt"),
                      _vm.isYoutubeNew &&
                      _vm.currentRole === _vm.networkRoles.owner
                        ? _c("TheContactPrompt")
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c("router-view", { class: _vm.isDev ? "" : "no-select" }),
            ],
            1
          ),
      _c("TheModals"),
      _c("TheNotifications"),
      _c("TheFileUploader"),
      _vm.creditCardAlert ? _c("BaseCreditCardPopup") : _vm._e(),
      _c(
        "TheToast",
        {
          attrs: {
            active: _vm.updateExists,
            infinity: _vm.infinity,
            position: _vm.position,
          },
        },
        [
          _vm._v("\n    A new version is available\n    "),
          _c(
            "button",
            { staticClass: "refresh-btn", on: { click: _vm.updateApp } },
            [_vm._v("Refresh")]
          ),
        ]
      ),
      _c(
        "TheToast",
        {
          attrs: {
            active: _vm.isMobile && !_vm.isMobileBannerShownOnce,
            infinity: _vm.infinity,
            position: "top-left",
          },
        },
        [
          _vm._v(
            "\n    Currently we only support access from Computers and Tablets. Mobile support coming soon\n    "
          ),
          _c(
            "button",
            {
              staticClass: "refresh-btn",
              on: {
                click: function () {
                  _vm.isMobileBannerShownOnce = true
                },
              },
            },
            [_vm._v("\n      Close\n    ")]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }