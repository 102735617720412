












import { Component, Watch, Mixins } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import moment from 'moment'

import isEmpty from 'lodash.isempty'
import get from 'lodash.get'
import Billing from '@/mixins/Billing'

enum billingKeys {
  canceled = 'canceled',
  charge_automatically = 'charge_automatically',
  past_due = 'past_due',
}

@Component
export default class TheContactPrompt extends Mixins(Billing) {
  @Getter hasLoadedUser!: boolean
  @Getter hasAcceptedCookies!: boolean
  @Getter networkId!: string
  @Getter networkType!: string
  @Getter currentNetwork!: any
  @Getter isYoutubeNew!: Boolean

  message: any = ''

  @Watch('hasLoadedUser')
  mounted() {
    if (this.hasLoadedUser && this.networkType === 'CUSTOM') {
      if (this.daysLeft <= 7 && this.daysLeft > 0) {
        this.message =
          `Free trial is about to end in ${this.daysLeft} days, contact` +
          `<span class="fw5"> hello@adorilabs.com </span>` +
          `to keep enjoying the` +
          `<span class="fw5"> Adori Experience!</span>`
        this.isPromtVisible = true
      }
      if (this.daysLeft <= 0 && this.currentNetwork.network.trialConsumed) {
        this.$store.commit('setTrialPeriodEnded', true)
        this.$router.push('/trial-end')
      }
    }

    if (this.hasLoadedUser && this.isYoutubeNew) {
      if (!isEmpty(this.summaryData)) {
        if (
          this.networkType === 'YOUTUBE_METERED' &&
          !localStorage.getItem('credit_prompt') &&
          get(this.summaryData, 'collection_method') === billingKeys.charge_automatically &&
          isEmpty(this.cardData)
        ) {
          this.message = `You have been awarded $5 credit. Add your credit card from the <a href='/settings/billing'>billing section</a> to get an additional $15 of credit`
          this.isPromtVisible = true
        }
        if (get(this.summaryData, 'status') === billingKeys.canceled) {
          this.$store.commit('setTrialPeriodEnded', true)
          this.$router.push('/trial-end')
        }

        if (get(this.summaryData, 'status') === billingKeys.past_due) {
          this.message = `You have not paid your invoice head to the <a href='/settings/billing'>billing section</a> and complete the payment to continue using Adori`
          this.isPromtVisible = true
        }
      }
    }
  }

  handleClose() {
    this.isPromtVisible = false

    get(this.summaryData, 'collection_method') === 'charge_automatically' &&
      isEmpty(this.cardData) &&
      localStorage.setItem('credit_prompt', 'CLOSED')
  }

  get daysLeft() {
    const trialEndDate = moment(this.currentNetwork.network.customTrialEndDate)
    return trialEndDate.diff(moment(), 'days')
  }

  daysOffsetToShowPromt: number = 35
  isCloseBtnVisible: boolean = true
  isPromtVisible: boolean = false
}
