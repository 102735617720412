import { reactive, ref, computed } from '@vue/composition-api'
import { Component, Vue } from 'vue-property-decorator'
import get from 'lodash.get'
import { QueryClient, useQueryClient } from 'vue-query'
import { gifQueryKey, useGetPublicGifs, useGetTenorGif } from '@/hooks/gif'

@Component({
  setup(props) {
    const queryClient = useQueryClient()
    const selectedGifTab = ref('TRENDING')
    const gifSearchText = ref('')
    const gifSearchParams = reactive({
      q: gifSearchText,
      limit: 10,
      offset: 0,
      searchLibrary: selectedGifTab,
    })

    const isGifSearchActive = computed(() => ['GIPHY', 'TRENDING'].includes(selectedGifTab.value))
    const { data: publicGifData, isFetching: isPublicGifLoading } = useGetPublicGifs(gifSearchParams, {
      enabled: isGifSearchActive,
    })
    const tenorSearchParams = reactive({
      q: gifSearchText,
    })

    const isTenorSearchActive = computed(() => ['TENOR'].includes(selectedGifTab.value) && !!gifSearchText.value)

    const {
      data: tenorData,
      isLoading: isTenorFetching,
      hasNextPage: hasNextTenorGifs,
      fetchNextPage: nextTenorGifs,
      isFetchingNextPage: isFetchingNextTenorGifs,
    } = useGetTenorGif(tenorSearchParams, {
      enabled: isTenorSearchActive,
    })
    return {
      queryClient,
      selectedGifTab,
      gifSearchText,
      gifSearchParams,
      publicGifData,
      isPublicGifLoading,
      tenorData,
      isTenorFetching,
      hasNextTenorGifs,
      nextTenorGifs,
      isFetchingNextTenorGifs,
    }
  },
})
export default class GifLibraries extends Vue {
  selectedGifTab!: string
  gifSearchText!: string
  gifSearchParams!: any
  publicGifData!: any
  isPublicGifLoading!: boolean
  tenorData!: any
  isTenorFetching!: boolean
  hasNextTenorGifs!: boolean
  nextTenorGifs!: any
  isFetchingNextTenorGifs!: boolean
  queryClient!: QueryClient

  get selectedGifRangeStart() {
    return this.gifSearchParams.offset + 1
  }

  get selectedGifRangeEnd() {
    return this.gifSearchParams.offset + this.gifList.length
  }

  get gifList() {
    return ['GIPHY', 'TRENDING'].includes(this.selectedGifTab)
      ? get(this.publicGifData, 'data.data', []).map((img: any) => ({
          url: img.images.original.url,
          previewUrl: img.images.preview_gif.url,
          source: 'GIPHY',
          id: img.id,
          user: img.username || null,
        }))
      : this.tenorData?.length
      ? this.tenorData.map((obj: any) => ({
          url: obj.media_formats.mediumgif.url,
          previewUrl: obj.media_formats.tinygif.url,
          source: 'TENOR',
          id: obj.id,
          user: null,
        }))
      : []
  }

  get totalGifCount() {
    return get(this.publicGifData, 'data.pagination.total_count', 0)
  }

  handleNavigation(nav: string) {
    nav === 'next' && this.gifSearchParams.offset <= this.totalGifCount && (this.gifSearchParams.offset += 10)
    nav === 'prev' && this.gifSearchParams.offset >= 10 && (this.gifSearchParams.offset -= 10)
  }

  resetOffset() {
    this.gifSearchParams.offset = 0
  }

  resetGifGallery() {
    this.queryClient.invalidateQueries(gifQueryKey.PUBLIC_GIPHY)
  }
}
