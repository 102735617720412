import { ENVS, CURRENT_ENV, PR_NUMBER } from '@/constants/env'

const env = {
  envConstDev: () => {
    return ENVS.dev
  },
  envConstBeta: () => {
    return ENVS.beta
  },
  envConstProd: () => {
    return ENVS.prod
  },
  windowOrigin: () => {
    return CURRENT_ENV
  },
  prNumber: () => {
    return PR_NUMBER
  },
}

export default {
  install(Vue: any, options: any) {
    Vue.prototype.$env = env
  },
}
