import AdoriService from '@/services/adori'
import { loadStripe } from '@stripe/stripe-js/pure'

export const stripeCheckout = async (pubKey: string, sessionId: string) => {
  // @ts-ignore
  const stripe: any = await loadStripe(pubKey)
  stripe.redirectToCheckout({ sessionId: sessionId })
}

export const addPaymentMethod = async (networkId: string, planId: string, mode: string = 'PAYMENT') => {
  const order: any = await AdoriService.createPaymentOrder(networkId, {
    planId,
    mode,
  })

  const tran: any = await AdoriService.createPaymentTransaction(networkId, {
    orderId: order.id,
  })

  await stripeCheckout(tran.stripePublishableKey, tran.sessionId)
}

export const updateSubscription = async (
  networkId: string,
  planId: string,
  confirm: boolean = false,
  callbackUrl: string = '/overview',
  mode: string = 'PAYMENT'
) => {
  const orderPayload: any = { planId, mode }
  if (confirm) orderPayload['confirm'] = 'YES'

  const order: any = await AdoriService.createPaymentOrder(networkId, orderPayload)

  const transactionPayload: any = {
    orderId: order.id,
    callbackUrl,
  }
  if (confirm) transactionPayload['confirm'] = 'YES'

  const tran: any = await AdoriService.createPaymentTransaction(networkId, transactionPayload)

  if (tran.sessionId && tran.stripePublishableKey) {
    await stripeCheckout(tran.stripePublishableKey, tran.sessionId)
  } else {
    await AdoriService.fetchPaymentTransaction(networkId, tran.id)
  }
}
