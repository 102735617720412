import Vue from 'vue'
import { StoreOptions, GetterTree, MutationTree, ActionTree } from 'vuex'
import AdoriService from '@/services/adori'

import {
  invitesPayload,
  OtpVerifyAndsSignUp,
  SocialSignUp,
} from '@/Interfaces/StoreInterFaces/accountInterfaces/SignupInterface'

const state: any = {}

const getters: GetterTree<any, any> = {}

const mutations: MutationTree<any> = {}

const actions: ActionTree<any, any> = {
  async requestSignUpOtp(context, payload: invitesPayload) {
    return await AdoriService.youtubeSignUpOtp(payload)
  },

  async videoSocialSignUp(context, payload: SocialSignUp) {
    return await AdoriService.youtubeSocialSignUp(payload)
  },

  async youtubeSignUp(context, payload: OtpVerifyAndsSignUp) {
    return await AdoriService.youtubeVerifyOtpAndSignUp(payload)
  },
}

const loginAndSignup: StoreOptions<any> = {
  state,
  getters,
  mutations,
  actions,
}

export default loginAndSignup
