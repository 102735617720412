import Vue from 'vue'
import { StoreOptions, GetterTree, MutationTree, ActionTree } from 'vuex'

const state: any = {
  selected: [],
  uniqueSelected: null,
}

const initialState: any = {
  ...JSON.parse(JSON.stringify(state)),
}

const getters: GetterTree<any, any> = {
  isItemSelected: (state) => (id: string) => state.selected.indexOf(id) !== -1,
  itemsSelected: (state) => state.selected,
  itemSelected: (state) => state.uniqueSelected,
}

const mutations: MutationTree<any> = {
  resetState(state) {
    Object.keys(state).forEach((key: any) => {
      Vue.set(state, key, initialState[key])
    })
  },

  add(state, id: string) {
    Vue.set(state, 'selected', [...state.selected, id])
  },

  remove(state, id: string) {
    Vue.delete(state.selected, state.selected.indexOf(id))
  },

  removeAll(state) {
    Vue.set(state, 'selected', [])
  },

  selectUniqueItem(state, item: string | null) {
    Vue.set(state, 'uniqueSelected', item)
  },
}

const actions: ActionTree<any, any> = {
  resetSelectionState(context) {
    context.commit('resetState')
  },

  async unselect(context, id: string) {
    context.commit('remove', id)
  },

  async select(context, id: string) {
    context.commit('add', id)
  },

  async toggleSelect(context, id: string) {
    if (!context.getters.isItemSelected(id)) {
      context.dispatch('select', id)
    } else {
      context.dispatch('unselect', id)
    }
  },

  async unselectAll(context) {
    context.commit('removeAll')
  },

  async batchAction(context, action: string) {
    context.state.selected.forEach((item: any) => context.dispatch(action, item))
  },

  async selectUniqueItem(context, item: string) {
    context.commit('selectUniqueItem', item)
  },

  async unselectUniqueItem(context) {
    context.commit('selectUniqueItem', null)
  },
}

const selection: StoreOptions<any> = {
  state,
  getters,
  mutations,
  actions,
}

export default selection
