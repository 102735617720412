




























































































































































































































































import { Component, Vue, Mixins } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import axios, { CancelTokenSource, CancelToken } from 'axios'
import debounce from 'lodash.debounce'
import AdoriService from '@/services/adori'
import { ADORI_HELP_BASE_URL } from '@/constants'
import { isWebLink } from '@/utils/misc'

@Component
export default class ModalPodcastImport extends Vue {
  @Getter userId!: any
  @Getter networkId!: any
  @Getter verifiedEmails!: any
  @Getter rssFeedUids!: any
  @Getter rssFeedsByUid!: any
  @Action createRssFeed!: any
  @Action getProfile!: any
  @Action closeModal!: any
  @Action setSelectedRssFeedUid!: any
  @Action importRssFeed!: any
  @Action incrementRssFeedsCount!: any
  @Action getVerifiedEmails!: any

  podcastSearchText: string = ''
  isSearchingPodcasts: boolean = false
  hasSearchedPodcasts: boolean = false
  podcastSearchCancelTokenSource: CancelTokenSource | null = null

  searchResults: any[] = []
  selectedPodcastUid: string = ''
  selectedPodcastRssUrl: string = ''

  selectedEmailForVerification: string = ''
  isRequestingVerificationCode: boolean = false
  hasRequestedVerificationCode: boolean = false
  verificationCode: string = ''
  isRequestingCheckVerificationCode: boolean = false
  hasRequestedCheckVerificationCode: boolean = false
  isVerificationCodeCorrect: boolean = false

  isSubmitting: boolean = false
  totalPodcasts: number = 0
  nextPage: number = 0
  allSearchResults: any[] = []

  moreEmails: boolean = false
  allFeedEmails: any[] = []
  searchingForEmails: boolean = false

  async created() {
    await this.getVerifiedEmails()
  }

  mounted() {
    if (this.$refs._podcastSearch !== undefined) {
      // @ts-ignore
      this.$refs._podcastSearch.focus()
    }
  }

  async onPodcastSearch() {
    if (this.podcastSearchText.length !== 0) {
      if (this.podcastSearchCancelTokenSource) {
        this.podcastSearchCancelTokenSource.cancel('Search canceled by the user.')
      }
      if (this.selectedPodcastUid.length !== 0) {
        this.selectedPodcastUid = ''
        this.selectedPodcastRssUrl = ''
      }

      try {
        this.selectedPodcastRssUrl = ''
        this.selectedPodcastUid = ''
        this.isSearchingPodcasts = true
        let webLink = isWebLink(this.podcastSearchText)
        this.searchResults = []
        this.allSearchResults = []
        this.podcastSearchCancelTokenSource = axios.CancelToken.source()
        let response: any = null
        if (webLink) {
          response = await this.searchShowsByRss(this.podcastSearchText)
          if (Object.keys(response).length !== 0) {
            this.searchResults = [response]
          }
        } else {
          response = await this.searchShowsByName(this.podcastSearchText, this.podcastSearchCancelTokenSource.token)
          this.searchResults = response.audioCollections.external.results
        }
        // let response: any = await this.searchShowsByName(this.podcastSearchText, this.podcastSearchCancelTokenSource.token)
        // this.searchResults = response.audioCollections.external.results
        this.allSearchResults = [...this.searchResults]
        this.totalPodcasts = response.audioCollections.external.total
        this.nextPage = 0
        this.isSearchingPodcasts = false
        this.hasSearchedPodcasts = true
      } catch (e) {
        this.isSearchingPodcasts = false
        this.hasSearchedPodcasts = true
      }
    }
  }

  async searchShowsByName(podcastSearchText: string, cancelToken: CancelToken) {
    let response = await AdoriService.searchPodcasts(podcastSearchText, cancelToken)
    return response
  }

  async searchShowsByRss(rssSearchText: string) {
    let response = await AdoriService.parseRssUrl({
      rss: rssSearchText,
    })
    return response
  }

  //  async searchShowsByRss (rssSearchText: string) {
  //   let response = await AdoriService.searchPodcastByRssUrl({
  //     rss: rssSearchText
  //   })
  //   return response
  // }

  async handleNextClick() {
    if (this.allSearchResults.length > this.nextPage + 10) {
      this.searchResults = this.allSearchResults.slice(this.nextPage + 10, this.nextPage + 20)
      this.nextPage += 10
    } else {
      try {
        this.isSearchingPodcasts = true
        this.podcastSearchCancelTokenSource = axios.CancelToken.source()
        let response: any = await AdoriService.searchPodcasts(
          this.podcastSearchText,
          this.podcastSearchCancelTokenSource.token,
          this.nextPage + 10
        )
        this.allSearchResults = [...this.allSearchResults, ...response.audioCollections.external.results]
        this.searchResults = this.allSearchResults.slice(this.nextPage + 10, this.nextPage + 20)
        this.isSearchingPodcasts = false
        this.nextPage += 10
        this.scrollToTop()
      } catch (e) {
        this.isSearchingPodcasts = false
        this.hasSearchedPodcasts = true
      }
    }
  }

  handlePreviousClick() {
    this.searchResults = this.allSearchResults.slice(this.nextPage - 10, this.nextPage)
    this.nextPage -= 10
    this.scrollToTop()
  }

  scrollToTop() {
    const searchResults = this.$refs.searchResults as HTMLElement
    searchResults.scrollTo(0, 0)
  }

  gotoHelp() {
    window.open(`${ADORI_HELP_BASE_URL}/podcasts/importing/`, '_blank')
  }

  debouncedSearch = debounce(() => {
    this.onPodcastSearch()
  }, 600)

  selectPodcastWithRssUrl(selectedPodcast: any) {
    if (selectedPodcast.rss) {
      this.selectedPodcastRssUrl = selectedPodcast.rss
    } else {
      this.selectedPodcastRssUrl = selectedPodcast.rss_url
    }
    if (selectedPodcast.uid) this.selectedPodcastUid = selectedPodcast.uid
    this.allFeedEmails = []
    this.allFeedEmails = this.searchResults[0].emails
    if (this.searchResults[0].moreFeedEmails && this.searchResults[0].moreFeedEmails.length !== 0) {
      let emailSet = new Set(this.allFeedEmails.concat(this.searchResults[0].moreFeedEmails))
      this.allFeedEmails = Array.from(emailSet)
      this.moreEmails = true
    } else {
      this.moreEmails = false
    }

    if (this.needsEmailVerification) {
      this.selectedEmailForVerification = this.allFeedEmails[0]
    }
  }

  selectPodcast(uid: string) {
    this.selectedPodcastUid = uid
    this.searchResults = this.searchResults.filter((result: any) => result.uid === uid)
    this.allFeedEmails = []
    this.allFeedEmails = this.searchResults[0].emails || []
    if (this.searchResults[0].moreFeedEmails && this.searchResults[0].moreFeedEmails.length !== 0) {
      let emailSet = new Set(this.allFeedEmails.concat(this.searchResults[0].moreFeedEmails))
      this.allFeedEmails = Array.from(emailSet)
      this.moreEmails = true
    } else {
      this.moreEmails = false
    }

    if (this.needsEmailVerification) {
      this.selectedEmailForVerification = this.allFeedEmails[0]
    }
  }

  async handleMoreFeedEmails() {
    this.searchingForEmails = true
    AdoriService.requestMoreFeedEmails(this.selectedPodcastUid).then((res: any) => {
      let emailSet = new Set(this.allFeedEmails.concat(res.moreFeedEmails))
      this.allFeedEmails = Array.from(emailSet)
      this.searchingForEmails = false
      this.moreEmails = true
    })
  }

  truncate(str: string, count: number) {
    if (str.length < count) return str
    return str.substring(0, count) + '...'
  }

  get hasAlreadyImportedPodcast() {
    if (this.selectedPodcastUid) {
      const importedPodcastUids = Object.values(this.rssFeedsByUid)
        .filter((rssFeed: any) => !!rssFeed.importedFrom)
        .map((importedRssFeed: any) => importedRssFeed.importedFrom)
      return importedPodcastUids.indexOf(this.selectedPodcastUid) !== -1
    }
    return false
  }

  get needsEmailVerification() {
    if (!(this.selectedPodcastUid || this.selectedPodcastRssUrl)) return false

    let needsEmailVerification = true
    if (this.verifiedEmails) {
      this.verifiedEmails.forEach((verifiedEmail: string) => {
        // @ts-ignore
        if (this.allFeedEmails.indexOf(verifiedEmail) !== -1) {
          needsEmailVerification = false
        }
      })
    }
    return needsEmailVerification
  }

  async handleImportPodcast() {
    this.isSubmitting = true
    let payload: any = {}

    if (this.selectedPodcastUid) {
      payload.uid = this.selectedPodcastUid
    } else {
      payload.rss_url = this.selectedPodcastRssUrl
    }

    await this.importRssFeed(payload)
      .then((response: any) => {
        // this.setSelectedRssFeedUid(this.rssFeedUids[0])
        this.isSubmitting = false
        this.incrementRssFeedsCount()
        this.closeModal()
      })
      .catch((e: any) => {
        this.isSubmitting = false
      })
  }

  async handleRequestVerficationCode() {
    this.isRequestingVerificationCode = true
    let payload: any = {
      email: this.selectedEmailForVerification,
    }
    if (this.selectedPodcastUid) {
      payload.uid = this.selectedPodcastUid
    } else {
      payload.rss_url = this.selectedPodcastRssUrl
    }
    await AdoriService.requestVerificationCode(this.networkId, payload)
    this.isRequestingVerificationCode = false
    this.hasRequestedVerificationCode = true
  }

  async handleRequestCheckVerficationCode() {
    this.isRequestingCheckVerificationCode = true
    try {
      await AdoriService.checkVerificationCode({
        email: this.selectedEmailForVerification,
        code: this.verificationCode,
      })
      this.isVerificationCodeCorrect = true

      // Re-fetch the user profile to update
      // the list of verified email addresses.
      await this.getProfile()
    } catch (error) {
      this.isVerificationCodeCorrect = false
    }
    this.isRequestingCheckVerificationCode = false
    this.hasRequestedCheckVerificationCode = true
  }

  getPlainFormatText(text: any) {
    if (this.selectedPodcastUid) {
      return this.truncate(text, 300)
    }
    return this.truncate(text.replace(/(<([^>]+)>)/g, ' '), 130)
  }
}
