export enum suggestionSource {
  UNKNOWN = 'UNKNOWN',
  CUSTOM_TEXT = 'CUSTOM_TEXT',
  EP_NOTES = 'EP_NOTES',
  EP_NAME = 'EP_NAME',
  EP_INFO = 'EP_INFO',
  TRANSCRIPT = 'TRANSCRIPT',
  BLOG_URL = 'BLOG_URL',
}

export enum imageTypes {
  UNSPLASH = 'unsplash',
  GOOGLE = 'google',
  GETTY = 'getty',
  PIXABAY = 'pixabay',
  MY_LIBRARY = 'library',
}

export const searchImageTypes = [
  {
    LibraryName: 'UNSPLASH',
    name: 'Unsplash',
  },
  {
    LibraryName: 'GOOGLE',
    name: 'Google',
  },
  {
    LibraryName: 'GETTY',
    name: 'Getty',
  },
  {
    LibraryName: 'PIXABAY',
    name: 'Pixabay',
  },
  {
    LibraryName: 'MY_LIBRARY',
    name: 'Library',
  },
]
