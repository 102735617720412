import { Component, Vue } from 'vue-property-decorator'
import { isImage, isPodcastShowImage } from '@/utils/misc'
import store from '@/store'

@Component
export default class ImagePreviewer extends Vue {
  reader = new FileReader()
  width = 0
  height = 0
  square = false

  created() {
    this.reader.onload = (event) => {
      const that = this
      let image = new Image()
      image.src = this.reader.result as string
      image.onload = function () {
        that.width = image.width
        that.height = image.height
        that.previewImage = that.reader.result as string
      }
    }
  }

  get previewImage() {
    return this.$store.state.modal.tagEdit.data.image
  }

  set previewImage(img: any) {
    this.$store.dispatch('changeTagImage', img)
  }

  loadImage() {
    const file = this.$store.getters.selectedFile
    if (this.square) {
      if (file && isPodcastShowImage(file.name)) {
        this.reader.readAsDataURL(file)
      } else {
        store.dispatch('pushNotification', {
          text: 'Image should be of JPEG or PNG format',
          type: 'ERROR',
        })
      }
    } else {
      if (file && isImage(file.name)) {
        this.reader.readAsDataURL(file)
      } else {
        store.dispatch('pushNotification', {
          text: 'Image should be of JPEG or PNG format',
          type: 'ERROR',
        })
      }
    }
  }

  showImageUploader(square: String, imageFile: Object) {
    this.$store.dispatch('showFileUploader', {
      accept: 'image/*',
      onChange: this.loadImage,
      imageFile: imageFile,
    })
  }
}
