var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "white" }, [
    _c("p", { staticClass: "f4" }, [_vm._v("Plans and Pricing")]),
    _c("p", { staticClass: "f5 silver" }, [
      _vm._v(
        "Simple pricing, No hidden charges. Advanced features for your video creation needs"
      ),
    ]),
    _c(
      "div",
      { staticClass: "flex" },
      _vm._l(_vm.plans, function (plan, key) {
        return _c(
          "div",
          { key: key, staticClass: "bg-adori-light-gray pv3 ph4 br3 mr3 w-33" },
          [
            _c("div", { staticClass: "mb2" }, [_vm._v(_vm._s(plan.planTitle))]),
            _c("div", { staticClass: "silver mb4" }, [
              _vm._v(_vm._s(plan.planSubtitle)),
            ]),
            _c("div", { staticClass: "f3 mb2" }, [
              _vm._v(_vm._s(plan.planAmount)),
            ]),
            _c("div", { staticClass: "f4 silver" }, [
              _vm._v(_vm._s(plan.planSubInfoAmount)),
            ]),
            _c(
              "button",
              {
                staticClass:
                  "w-100 pointer br2 bg-transparent ba bg-adori-red shadow-2 mt3 white pa2 f6 pointer flex justify-center",
                class: {
                  "bg-adori-gray": !plan.planActive,
                  "o-40 not-allowed": _vm.isUpgrading,
                },
                on: {
                  click: function ($event) {
                    return _vm.choosePlan(plan.networkType)
                  },
                },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.btnText(plan.networkType)) +
                    "\n        "
                ),
                _vm.isUpgrading
                  ? _c("div", { staticClass: "ml2 loader loader-inline" })
                  : _vm._e(),
              ]
            ),
            _c(
              "div",
              { staticClass: "mt3" },
              _vm._l(plan.services, function (service, key) {
                return _c(
                  "div",
                  { key: key, staticClass: "flex items-center" },
                  [
                    _c(
                      "i",
                      {
                        staticClass: "material-icons f5 v-mid pa1 mr2",
                        class: service.icon === "done" ? "green" : "adori-red",
                      },
                      [_vm._v(_vm._s(service.icon))]
                    ),
                    _c("span", { staticClass: "f6" }, [
                      _vm._v(_vm._s(service.service)),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }