





























































































































































































































































































































import { Component, Mixins } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import get from 'lodash.get'
import { fabric } from 'fabric'
import WebFontLoader from 'webfontloader'
import { Sketch } from 'vue-color'
import { mixin as clickaway } from 'vue-clickaway'

import CustomTemplatesGrid from '@/components/Subtitle/CustomTemplatesGrid.vue'
import { templateTypes } from '@/components/Subtitle/constants'
import Invalidate from '@/mixins/Invalidate'
import { subtitleQueryKeys } from '@/hooks/subtitle'
import { useGetAudiogramFonts } from '@/hooks/audiogram'

@Component({
  components: {
    Sketch,
    CustomTemplatesGrid,
  },
  setup() {
    useGetAudiogramFonts()
  },
})
export default class ModalSubtitleStyle extends Mixins(Invalidate, clickaway) {
  @Getter imageSearchResult!: any
  @Getter networkId!: string
  @Getter fontsList!: any
  @Getter subtitleTemplates!: any
  @Getter subtitleStyleIds!: any
  @Getter selectedTemplate!: any

  @Action saveCustomTemplate!: any
  @Action closeModal!: any
  @Action saveAudioTrackSubtitle!: Function

  uploadText = 'Create template'
  loader = false
  loadingCanvas = false
  uploadingImage = false
  textSize: number = 20
  width: number = 480
  height: number = 270
  canvasContext!: any

  subtitle: string = ''
  saveText: string = 'Save Changes'
  activeSubtitleStyle = 'BASIC'
  artboardCtx!: any
  activeTab: string = 'templates'
  font: string = 'Inter'
  activeColor: string = 'color1'
  displayTextColorPicker: boolean = false
  displayEffectColorPicker: boolean = false
  textColorValue = '#FFFFFF'
  effectColorValue = '#FFFFFF'
  backColour = '#FFFFFF'
  outlineColour = '#FFFFFF'
  textPosition = 'MIDDLE'

  // text-format
  formatBold: boolean = false
  formatItalic: boolean = false
  formatUnderline: boolean = false
  formatStrikethrough: boolean = false

  //gradients
  color1 = new fabric.Gradient({
    type: 'linear',
    gradientUnits: 'pixels',
    coords: { x1: 0, y1: this.height / 2, x2: this.width, y2: 0 },
    colorStops: [
      { offset: 0, color: '#21D4FD' },
      { offset: 1, color: '#B721FF' },
    ],
  })

  async mounted() {
    this.loadingCanvas = true
    const ref = this.$refs.can
    this.canvasContext = new fabric.Canvas(ref, {
      preserveObjectStacking: true,
      stateful: true,
      uniScaleKey: '',
      selection: false,
    })

    this.canvasContext.setWidth(this.width)
    this.canvasContext.setHeight(this.height)

    this.artboardCtx = new fabric.Rect({
      left: 0,
      top: 0,
      width: this.width,
      height: this.height,
      absolutePositioned: true,
      fill: '#FFF',
      hasControls: true,
      typeThing: 'none',
      transparentCorners: false,
      borderColor: '#0E98FC',
      cornerColor: '#0E98FC',
      cursorWidth: 1,
      selectable: false,
      cursorDuration: 1,
      cursorDelay: 250,
      id: 'artboard',
    })

    this.canvasContext.renderAll()

    // Clip canvas to the artboard
    this.canvasContext.clipPath = this.artboardCtx
    this.canvasContext.renderAll()
    this.artboardCtx.set('fill', this.color1)
    this.canvasContext.add(this.artboardCtx)

    // Automatically increase textbox width (do not break words)
    this.canvasContext.on('text:changed', () => {
      var linewidth =
        this.canvasContext.getActiveObject().__lineWidths[this.canvasContext.getActiveObject().__lineWidths.length - 1]
      if (!isNaN(linewidth) && linewidth + 40 > this.canvasContext.getActiveObject().width) {
        this.canvasContext.getActiveObject().set('width', linewidth + 40)
        this.canvasContext.renderAll()
      }
    })

    this.newTextbox('This is the Basic\n subtitle style', 'BASIC')

    this.loadingCanvas = false
  }

  get showSecondaryColor() {
    return ['BOXED', 'WRAP', 'FILLED'].includes(this.activeSubtitleStyle)
  }

  changeTextSize(e: any) {
    this.canvasContext.getObjects().forEach((obj: any) => {
      if (obj.id && obj.id === 'subtitle') {
        obj.set({
          fontSize: e.target.value,
        })
      }
    })
    this.canvasContext.renderAll()
  }

  changeTextFormat(id: string) {
    this.canvasContext.getObjects().forEach((obj: any) => {
      if (obj.id && obj.id === 'subtitle') {
        if (id == 'bold') {
          if (this.formatBold == true) {
            this.formatBold = false
            obj.set({ fontWeight: 'normal' })
          } else {
            this.formatBold = true
            obj.set({ fontWeight: 'bold' })
          }
        } else if (id == 'italic') {
          if (this.formatItalic == true) {
            this.formatItalic = false
            obj.set({ fontStyle: 'normal' })
          } else {
            this.formatItalic = true
            obj.set({ fontStyle: 'italic' })
          }
        } else if (id == 'underline') {
          if (this.formatUnderline == true) {
            this.formatUnderline = false
            obj.set({ underline: false })
          } else {
            this.formatUnderline = true
            obj.set({ underline: true })
          }
        } else {
          if (this.formatStrikethrough == true) {
            this.formatStrikethrough = false
            obj.set({ linethrough: false })
          } else {
            this.formatStrikethrough = true
            obj.set({ linethrough: true })
          }
        }
      }
    })

    this.canvasContext.renderAll()
  }

  async uploadTemplate() {
    this.loader = true
    this.uploadText = 'Creating...'
    this.upScaleCanvas()
    let base64img = this.canvasContext.toDataURL({ quality: 1.0 })
    let encodeImg = base64img.split('base64,')[1]
    this.downScaleCanvas()

    if (this.activeSubtitleStyle === templateTypes.BOXED || this.activeSubtitleStyle === templateTypes.WRAP) {
      this.backColour = this.effectColorValue
    }
    if (this.activeSubtitleStyle === templateTypes.OUTLINED || this.activeSubtitleStyle === templateTypes.FILLED) {
      this.outlineColour = this.effectColorValue
    }

    try {
      const payload = {
        data: {
          template: this.activeSubtitleStyle,
          imageData: encodeImg,
          fontname: this.font,
          fontsize: this.textSize,
          bold: this.formatBold,
          italic: this.formatItalic,
          underline: this.formatUnderline,
          strikeOut: this.formatStrikethrough,
          alignment: this.findAlignment(),
          primaryColour: this.textColorValue,
          backColour: this.backColour,
          outlineColour: this.outlineColour,
        },
        params: {
          network_id: this.networkId,
        },
      }
      await this.saveCustomTemplate(payload)

      this.queryClient.invalidateQueries(subtitleQueryKeys.CUSTOM)
      this.setActiveTab('templates')
      this.loader = false
      this.uploadText = 'Create Template'
    } catch (error) {
      console.log(error)
      this.loader = false
      this.uploadText = 'Create Template'
    }
  }

  findAlignment() {
    if (this.textPosition === 'MIDDLE') return 5
    if (this.textPosition === 'TOP') return 8
    if (this.textPosition === 'BOTTOM') return 2
  }

  async handleSave(close = true) {
    this.saveText = 'Saving...'
    this.loader = true

    const payload = {
      subtitleStyleId: this.selectedTemplate.id || null,
      template: this.selectedTemplate.id
        ? 'CUSTOM'
        : this.selectedTemplate.template || this.selectedTemplate.enum || 'BASIC',
      animation: 'NONE',
      isEdited: true,
      params: {
        network_id: this.networkId,
        track_uid: this.$route.query.uid ?? sessionStorage.getItem('uid'),
      },
    }
    try {
      await this.saveAudioTrackSubtitle(payload)
      this.saveText = 'Save changes'
      this.loader = false
      close && this.closeModal()
    } catch (error) {
      console.log(error)
      this.loader = false
      this.saveText = 'Save Changes'
      close && this.closeModal()
    }
  }

  // Change font
  changeFont(family: string) {
    WebFontLoader.load({
      google: {
        families: [family],
      },
      //font loading sequence events->loading, active,inactive,
      active: () => {
        this.canvasContext.getObjects().forEach((obj: any) => {
          if (obj.id && obj.id === 'subtitle') {
            obj.set({ fontFamily: family })
          }
        })
        this.canvasContext.renderAll()
      },
    })
  }

  upScaleCanvas() {
    const SCALE_FACTOR = 3
    this.canvasContext.setHeight(this.canvasContext.getHeight() * SCALE_FACTOR)
    this.canvasContext.setWidth(this.canvasContext.getWidth() * SCALE_FACTOR)

    this.canvasContext.getObjects().forEach((obj: any) => {
      let tempScaleX = obj.scaleX * SCALE_FACTOR
      let tempScaleY = obj.scaleY * SCALE_FACTOR
      let tempLeft = obj.left * SCALE_FACTOR
      let tempTop = obj.top * SCALE_FACTOR

      obj.scaleX = tempScaleX
      obj.scaleY = tempScaleY
      obj.left = tempLeft
      obj.top = tempTop

      obj.setCoords()
    })
  }

  downScaleCanvas() {
    const SCALE_FACTOR = 3
    this.canvasContext.setHeight(this.canvasContext.getHeight() * (1 / SCALE_FACTOR))
    this.canvasContext.setWidth(this.canvasContext.getWidth() * (1 / SCALE_FACTOR))

    this.canvasContext.getObjects().forEach((obj: any) => {
      let tempScaleX = obj.scaleX * (1 / SCALE_FACTOR)
      let tempScaleY = obj.scaleY * (1 / SCALE_FACTOR)
      let tempLeft = obj.left * (1 / SCALE_FACTOR)
      let tempTop = obj.top * (1 / SCALE_FACTOR)

      obj.scaleX = tempScaleX
      obj.scaleY = tempScaleY
      obj.left = tempLeft
      obj.top = tempTop

      obj.setCoords()
    })

    this.canvasContext.renderAll()
  }

  selectionIcon(id: string) {
    return this.selectedTemplate?.enum === id ? 'check_circle' : 'radio_button_unchecked'
  }

  selectionStyle(id: string) {
    return this.selectedTemplate?.enum === id ? 'adori-red' : 'o-80'
  }

  async selectSubtitleStyle(id: string) {
    const style = this.subtitleTemplates.filter((obj: any) => obj.enum === id)[0]
    this.$store.commit('setSelectedTemplate', style)
    this.handleSave(false)
  }

  closeTextColorPicker() {
    this.displayTextColorPicker = false
  }
  closeEffectColorPicker() {
    this.displayEffectColorPicker = false
  }

  toggleTextColorPicker() {
    this.displayTextColorPicker = !this.displayTextColorPicker
  }

  toggleEffectColorPicker() {
    this.displayEffectColorPicker = !this.displayEffectColorPicker
  }

  updateTextColorFromPicker(color: any) {
    if (color.rgba.a == 1) {
      this.textColorValue = color.hex
    } else {
      this.textColorValue =
        'rgba(' + color.rgba.r + ', ' + color.rgba.g + ', ' + color.rgba.b + ', ' + color.rgba.a + ')'
    }
    this.onTextColorChange(this.textColorValue)
  }

  updateEffectColorFromPicker(color: any) {
    if (color.rgba.a == 1) {
      this.effectColorValue = color.hex
    } else {
      this.effectColorValue =
        'rgba(' + color.rgba.r + ', ' + color.rgba.g + ', ' + color.rgba.b + ', ' + color.rgba.a + ')'
    }
    this.onEffectColorChange(this.effectColorValue)
  }

  onTextColorChange(color: any) {
    this.canvasContext.getObjects().forEach((obj: any) => {
      if (obj.id && obj.id === 'subtitle') {
        if (this.activeSubtitleStyle === templateTypes.OUTLINED)
          obj.set({ fill: 'rgba(0, 0, 0, 0)', stroke: color, strokeWidth: 1 })
        else if (this.activeSubtitleStyle === templateTypes.NEON)
          obj.set({
            fill: color,
            stroke: '#ffffff',
            strokeWidth: 2,
            shadow: {
              color: color,
              offsetX: 0,
              offsetY: 10,
              blur: 10,
              opacity: 1,
            },
          })
        else obj.set({ fill: color })
      }
    })
    this.canvasContext.renderAll()
  }

  onEffectColorChange(color: any) {
    this.canvasContext.getObjects().forEach((obj: any) => {
      if (obj.id && obj.id === 'subtitle') {
        if (this.activeSubtitleStyle === templateTypes.BOXED) obj.set({ backgroundColor: color })
        if (this.activeSubtitleStyle === templateTypes.WRAP) obj.set({ textBackgroundColor: color })
        if (this.activeSubtitleStyle === templateTypes.FILLED) obj.set({ stroke: color, strokeWidth: 3 })
      }
    })
    this.canvasContext.renderAll()
  }

  updateTextPosition(pos: string) {
    this.textPosition = pos
    this.canvasContext.getObjects().forEach((obj: any) => {
      if (obj.id && obj.id === 'subtitle') {
        if (pos === 'TOP') obj.set({ top: this.canvasContext.getHeight() / 5, left: this.canvasContext.getWidth() / 2 })
        if (pos === 'MIDDLE')
          obj.set({
            top: this.canvasContext.getHeight() / 2,
            left: this.canvasContext.getWidth() / 2,
          })
        if (pos === 'BOTTOM')
          obj.set({ top: this.canvasContext.getHeight() / 1.2, left: this.canvasContext.getWidth() / 2 })
      }
    })
    this.canvasContext.renderAll()
  }

  onBgColorChange(color: any) {
    this.artboardCtx.set('fill', color)
    this.canvasContext.renderAll()
  }

  newTextbox(text: string, active: string) {
    this.activeSubtitleStyle = active
    this.canvasContext.getObjects().forEach((obj: any) => {
      if (obj.id && obj.id === 'subtitle') {
        this.canvasContext.remove(obj)
      }
    })

    let specificStyle

    if (active === templateTypes.BASIC) {
      specificStyle = {
        fill: '#fff',
        shadow: {
          color: '#000',
          offsetX: 0,
          offsetY: 0,
          blur: 0,
          opacity: 0,
        },
      }

      this.textColorValue = '#ffffff'
    }
    if (active === templateTypes.BOXED) {
      specificStyle = { fill: '#fff', backgroundColor: 'rgba(0,0,0,0.7)' }
      this.textColorValue = '#ffffff'
      this.effectColorValue = 'rgba(0,0,0,0.7)'
    }
    if (active === templateTypes.WRAP) {
      specificStyle = { fill: '#fff', textBackgroundColor: 'rgba(0,0,0,0.5)', lineHeight: 1.3 }
      this.textColorValue = '#ffffff'
      this.effectColorValue = 'rgba(0,0,0,0.5)'
    }
    if (active === templateTypes.SHADOW) {
      specificStyle = { fill: '#fff', shadow: 'rgba(0,0,0,0.6) 5px 15px 10px' }
      this.textColorValue = '#ffffff'
    }
    if (active === templateTypes.OUTLINED) {
      specificStyle = { fill: 'rgba(0, 0, 0, 0)', stroke: '#fff', strokeWidth: 1 }
      this.textColorValue = '#ffffff'
    }
    if (active === templateTypes.FILLED) {
      specificStyle = { fill: '#000000', stroke: '#00FFF0', strokeWidth: 3 }
      this.textColorValue = '#000000'
      this.effectColorValue = '#00FFF0'
    }
    if (active === templateTypes.NEON) {
      specificStyle = {
        fill: '#FF0099',
        stroke: '#fff',
        strokeWidth: 2,
        shadow: {
          color: 'rgb(255, 0, 153) ',
          offsetX: 0,
          offsetY: 10,
          blur: 10,
          opacity: 1,
        },
      }
      this.textColorValue = '#FF0099'
    }

    this.textSize = 20
    this.font = 'Inter'
    this.textPosition === 'MIDDLE'

    let newtext = new fabric.Textbox(text, {
      left: this.canvasContext.getWidth() / 2,
      top: this.canvasContext.getHeight() / 2,
      ...specificStyle,
      width: 300,
      selectable: false,
      originX: 'center',
      originY: 'center',
      fontFamily: 'Inter',
      textAlign: 'center',
      fontWeight: 'normal',
      fontSize: 20,
      cursorWidth: 1,
      cursorDuration: 1,
      paintFirst: 'stroke',
      typeThing: 'none',
      objectCaching: true,
      strokeUniform: true,
      inGroup: false,
      cursorDelay: 250,
      id: 'subtitle',
      strokeDashArray: false,
      absolutePositioned: true,
    })

    this.canvasContext.add(newtext)
    this.canvasContext.bringToFront(newtext)
    this.canvasContext.renderAll()
  }

  setActiveTab(tab: string) {
    this.activeTab = tab
  }

  destroyed() {
    sessionStorage.removeItem('uid')
  }
}
