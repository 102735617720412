





















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import get from 'lodash.get'
import { subtitleQueryKeys, useGetCustomTemplateStyles } from '@/hooks/subtitle'
import { QueryClient, useQueryClient } from 'vue-query'

@Component({
  setup() {
    const queryClient = useQueryClient()
    const {
      fetchNextPage: nextTemplatePage,
      isFetchingNextPage: isNextTemplateFetching,
      isLoading: isTemplatesLoading,
      hasNextPage: hasNextTemplate,
    } = useGetCustomTemplateStyles()
    return {
      queryClient,
      nextTemplatePage,
      isNextTemplateFetching,
      isTemplatesLoading,
      hasNextTemplate,
    }
  },
})
export default class CustomTemplatesGrid extends Vue {
  @Prop(String) audioUid!: string
  @Prop(Array) subtitleStyleIds!: any[]

  @Getter networkId!: string
  @Getter hasLoadedUser!: any
  @Getter subtitleTemplates!: any
  @Getter subtitleStyleById!: any
  @Getter subtitleCustomStyles!: any
  @Getter selectedTemplate!: any

  @Action pushNotification!: any
  @Action saveAudioTrackSubtitle!: Function
  @Action deleteCustomTemplate!: Function

  isLoadingStyles = false

  queryClient!: QueryClient
  nextTemplatePage!: boolean
  isNextTemplateFetching!: boolean
  isTemplatesLoading!: boolean
  hasNextTemplate!: boolean

  selectionIcon(id: string) {
    return this.selectedTemplate?.id === id ? 'check_circle' : 'radio_button_unchecked'
  }

  selectionStyle(id: string) {
    return this.selectedTemplate?.id === id ? 'adori-red' : 'o-80'
  }
  selectionBorder(id: string) {
    return this.selectedTemplate?.id === id ? 'b--adori-red ba' : ''
  }

  get subtitleStyles() {
    return this.subtitleStyleIds
  }

  get loaders() {
    return new Array(20)
  }

  async deleteSubStyle(styleId: string) {
    if (this.selectedTemplate?.id === styleId) {
      try {
        this.isLoadingStyles = true
        await this.deleteCustomTemplate(styleId)
        this.$store.commit('setSelectedTemplate', this.subtitleTemplates.filter((obj: any) => obj.enum === 'BASIC')[0])

        const payload = {
          subtitleStyleId: null,
          template: 'BASIC',
          animation: 'NONE',
          isEdited: true,
          params: {
            network_id: this.networkId,
            track_uid: this.$route.query.uid,
          },
        }
        await this.saveAudioTrackSubtitle(payload)
        this.queryClient.invalidateQueries(subtitleQueryKeys.CUSTOM)
        this.isLoadingStyles = false
      } catch (error) {
        this.isLoadingStyles = false
      }
    } else {
      try {
        this.isLoadingStyles = true
        await this.deleteCustomTemplate(styleId)
        this.queryClient.invalidateQueries(subtitleQueryKeys.CUSTOM)
        this.isLoadingStyles = false
      } catch (error) {
        this.isLoadingStyles = false
      }
    }
  }

  async selectSubtitleStyle(id: string) {
    this.$store.commit('setSelectedTemplate', this.subtitleStyleById[id])
    this.$emit('select-style')
  }
}
