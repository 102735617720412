



















import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

@Component
export default class ModalAdPlay extends Vue {
  @Getter audioAdEdit!: any

  audioPlayUrl!: string

  created() {
    this.audioPlayUrl = this.audioAdEdit.audioUrl
  }

  handleUploadAudio() {
    this.$store.dispatch('uploadNewAd')
  }
}
