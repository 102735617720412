var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [_vm.templateClass],
      on: {
        mouseenter: function ($event) {
          _vm.activeHover = true
        },
        mouseleave: function ($event) {
          _vm.activeHover = false
        },
        click: _vm.loadTemplate,
      },
    },
    [
      _c("div", { staticClass: "template-item" }, [
        _vm.activeHover
          ? _c("div", { staticClass: "template-overlay flex-center" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/audiogram/template-active.svg"),
                },
              }),
            ])
          : _vm._e(),
        _c("img", { staticClass: "img", attrs: { src: _vm.thumbnail } }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }