




import { Vue, Component, Prop } from 'vue-property-decorator'
import MediumEditor from 'medium-editor'

@Component
export default class HTMLEditor extends Vue {
  @Prop(String) placeholder!: string
  @Prop(String) initialContent!: string

  editor: any = null

  mounted() {
    this.editor = new MediumEditor(this.$refs.editor, {
      placeholder: {
        text: this.placeholder || 'Enter text',
      },
      paste: {
        cleanPastedHTML: true,
        cleanAttrs: ['class', 'style', 'dir'],
        cleanTags: ['meta', 'script', 'img', 'style'],
      },
      extensions: {
        imageDragging: {}, // Disable file dragging
      },
      autoLink: true, // Turn typed URLs into anchor tags
      targetBlank: true, // Add target="_blank" to anchor tags
    })

    if (this.initialContent) {
      this.editor.setContent(this.initialContent, 0)
    }

    this.editor.subscribe('editableInput', () => {
      this.$emit('update', this.editor.getContent())
    })
  }

  beforeDestroy() {
    this.editor.destroy()
  }
}
