









import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class BaseLabel extends Vue {
  @Prop(String) icon!: string
  @Prop(String) text!: string
  @Prop(String) forId!: string
  @Prop(String) color!: string
}
