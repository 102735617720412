





























import { Vue, Component } from 'vue-property-decorator'
import { toHms, secondsToMmss } from '@/utils/time'
import { Getter, Action } from 'vuex-class'

import get from 'lodash.get'

@Component
export default class ModalTranscript extends Vue {
  @Getter getTranscript!: any

  @Action fetchTranscript!: any

  loading: boolean = false

  get audioUid() {
    return this.$store.state.modal.transcript.audioUid
  }

  get audio() {
    return this.$store.getters.audio(this.audioUid)
  }

  get audioName() {
    return get(this.$store.getters.audio(this.audioUid), 'name', '')
  }

  get transcript() {
    return this.getTranscript(this.audioUid)
  }

  toReadableTime(timeString: string) {
    const timeInSeconds = parseFloat(timeString.replace('s', ''))
    const timeInMillis = timeInSeconds * 1000
    if (timeInMillis === 0) return timeString
    return toHms(timeInMillis)
  }

  async mounted() {
    this.loading = true
    if (!this.transcript) await this.fetchTranscript(this.audioUid)
    this.loading = false
  }
}
