var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "fixed flex justify-center items-center left-0 right-0 top-0 bottom-0 bg-black-80 no-select custom-index",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) {
            return null
          }
          _vm.hasCustomEvent
            ? _vm.$emit("clickAway")
            : _vm.handleClickedOutside()
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "flex flex-column bg-adori-gray ph2 pv3 mh-95 min-w5 br3 shadow-5",
        },
        [
          _c(
            "header",
            { staticClass: "flex flex-row justify-between ph4 pv3" },
            [
              _vm._t("header"),
              !_vm.hideCloseButton
                ? _c(
                    "button",
                    {
                      staticClass:
                        "bw0 bg-adori-gray light-gray link pointer dim",
                      on: {
                        click: function ($event) {
                          _vm.hasCustomEvent
                            ? _vm.$emit("closeModal")
                            : _vm.handleCloseButtonClicked()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons f3" }, [
                        _vm._v("close"),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            2
          ),
          _c(
            "section",
            {
              staticClass:
                "flex flex-wrap bg-adori-gray justify-center ph4 mb3 overflow-y-auto scrollbar",
            },
            [
              _vm._t("body", function () {
                return [_vm._v("Modal body")]
              }),
            ],
            2
          ),
          _c(
            "footer",
            { staticClass: "flex justify-end ph4 mb2" },
            [_vm._t("footer")],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }