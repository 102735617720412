














































import { Vue, Component, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import ModalDialog from '@/components/Modals/ModalDialog.vue'
import ModalConfirm from '@/components/Modals/ModalConfirm.vue'
import ModalTranscript from '@/components/Modals/ModalTranscript.vue'
import ModalYoutubePublish from '@/components/Modals/ModalYoutubePublish.vue'
import ModalTagEdit from '@/components/Modals/ModalTagEdit.vue'
import ModalAudioTrackPreview from '@/components/Modals/ModalAudioTrackPreview.vue'
import ModalAudioTrackEmbed from '@/components/Modals/ModalAudioTrackEmbed.vue'
import ModalAudioTrackEdit from '@/components/Modals/ModalAudioTrackEdit.vue'
import ModalAudioTrackAdsEdit from '@/components/Modals/ModalAudioTrackAdsEdit.vue'
import ModalAudioTrackCreate from '@/components/Modals/ModalAudioTrackCreate.vue'
import ModalAudioTrackPlay from '@/components/Modals/ModalAudioTrackPlay.vue'
import ModalProfileEdit from '@/components/Modals/ModalProfileEdit.vue'
import ModalPodcastCreate from '@/components/Modals/ModalPodcastCreate.vue'
import ModalPodcastEdit from '@/components/Modals/ModalPodcastEdit.vue'
import ModalPodcastEpisodeAdd from '@/components/Modals/ModalPodcastEpisodeAdd.vue'
import ModalPodcastEpisodeEdit from '@/components/Modals/ModalPodcastEpisodeEdit.vue'
import ModalPodcastImport from '@/components/Modals/ModalPodcastImport.vue'
import ModalPodcastPublish from '@/components/Modals/ModalPodcastPublish.vue'
import ModalAddMember from '@/components/Modals/ModalAddMember.vue'
import ModalAddShowMember from '@/components/Modals/ModalAddShowMember.vue'
import ModalSetMembers from '@/components/Modals/ModalSetMembers.vue'
import ModalNoMemberNetworks from '@/components/Modals/ModalNoMemberNetworks.vue'
import ModalExportInsights from '@/components/Modals/ModalExportInsights.vue'
import ModalReplaceAudio from '@/components/Modals/ModalReplaceAudio.vue'
import ModalPaymentEstimates from '@/components/Modals/ModalPaymentEstimates.vue'
import ModalAudioAd from '@/components/Campaigns/Modals/ModalAudioAd.vue'
import ModalAudioAdEdit from '@/components/Campaigns/Modals/ModalAudioAdEdit.vue'
import ModalAdPlay from '@/components/Campaigns/Modals/ModalAdPlay.vue'
import ModalAudioAdPreview from '@/components/Campaigns/Modals/ModalAudioAdPreview.vue'
import ModalDynamicCampaign from '@/components/Campaigns/Modals/ModalDynamicCampaign.vue'
import ShowsTag from '@/components/Tags/Modals/ShowsTag.vue'
import ModalYoutubeSetting from '@/components/Youtube/Modal/ModalYoutubeSetting.vue'
import ModalYoutubeBatchSetting from '@/components/Youtube/Modal/ModalYoutubeBatchSetting.vue'
import ModalAutoPublish from '@/views/AutoPublish/components/ModalAutoPublish.vue'
import ModalPodcastImportAndUpload from '../Modals/ModalPodcastImportAndUpload.vue'
import ModalAudiogramEdit from '@/components/Modals/ModalAudiogramEdit.vue'
import ModalSubtitleStyle from '@/components/Modals/ModalSubtitleStyle.vue'
import ModalPlans from '@/components/Modals/ModalPlans.vue'

@Component({
  components: {
    ModalDialog,
    ModalConfirm,
    ModalTranscript,
    ModalYoutubePublish,
    ModalTagEdit,
    ModalAudioTrackPreview,
    ModalAudioTrackEmbed,
    ModalAudioTrackEdit,
    ModalAudioTrackAdsEdit,
    ModalAudioTrackCreate,
    ModalAudioTrackPlay,
    ModalProfileEdit,
    ModalPodcastCreate,
    ModalPodcastEdit,
    ModalPodcastEpisodeAdd,
    ModalPodcastEpisodeEdit,
    ModalPodcastImport,
    ModalPodcastPublish,
    ModalAddMember,
    ModalAddShowMember,
    ModalSetMembers,
    ModalNoMemberNetworks,
    ModalExportInsights,
    ModalReplaceAudio,
    ModalPaymentEstimates,
    ModalAudioAd,
    ModalAudioAdEdit,
    ModalAdPlay,
    ModalAudioAdPreview,
    ModalDynamicCampaign,
    ShowsTag,
    ModalYoutubeSetting,
    ModalAutoPublish,
    ModalPodcastImportAndUpload,
    ModalAudiogramEdit,
    ModalSubtitleStyle,
    ModalYoutubeBatchSetting,
    ModalPlans,
  },
})
export default class TheModals extends Vue {
  @Getter showLoader!: any

  get currentModal() {
    return this.$store.state.modal.current
  }

  @Watch('currentModal')
  onCurrentModalChanged() {
    if (this.currentModal && !this.showLoader) {
      document.body.classList.add('scroll-lock')
    }

    if (!this.currentModal && !this.showLoader) {
      document.body.classList.remove('scroll-lock')
    }
  }
}
