var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "fixed flex justify-center items-center left-0 right-0 top-0 bottom-0 bg-black-50 no-select custom-index",
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "on-clickaway",
              rawName: "v-on-clickaway",
              value: _vm.closeAlert,
              expression: "closeAlert",
            },
          ],
          staticClass:
            "flex flex-column bg-adori-gray ph2 pv3 mh-95 min-w5 br3 shadow-5 white",
        },
        [
          _c("div", { staticClass: "flex flex-column items-center pa4" }, [
            _c("span", { staticClass: "f3" }, [
              _vm._v("You've consumed your free credits"),
            ]),
            _c("span", { staticClass: "f4 silver mt3" }, [
              _vm._v("Get access to more video uploads and transcriptions"),
            ]),
            _c("div", { staticClass: "w-100 bg-adori-light-gray ph2 mt4" }, [
              _c("div", { staticClass: "silver flex justify-between mv3" }, [
                _c("span", [_vm._v("You've reached your limit")]),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.limit) +
                      " / " +
                      _vm._s(_vm.lifetime_credits_given) +
                      " $"
                  ),
                ]),
              ]),
              _c("div", { staticClass: "progress-wrapper mt2" }, [
                _c("div", { staticClass: "progress" }, [
                  _c("div", {
                    staticClass: "progress-bar",
                    style: "width:" + _vm.percentage + "%",
                  }),
                ]),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "flex flex-column items-center" },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/cc.svg"),
                  alt: "",
                  height: "200",
                },
              }),
              _c("div", { staticClass: "f5 mt3" }, [
                _vm._v("Add your card and get 5$ more free credits"),
              ]),
              _c("BaseButtonRed", {
                staticClass: "f4 mv3",
                attrs: { text: "Add Card Now", onClick: _vm.addCard },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "f7 flex flex-column items-center silver",
              attrs: { v: "" },
            },
            [
              _vm._v(
                "\n      Note: No charges for adding your credit card; pay only when you use our services.\n    "
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }