import Vue from 'vue'
import { StoreOptions, GetterTree, MutationTree, ActionTree } from 'vuex'
import isEmpty from 'lodash.isempty'

const state: any = {
  podcastPageNumber: {},
  allAudioPageNumber: 1,
}

const getters: GetterTree<any, any> = {
  podcastPageNumber: (state) => state.podcastPageNumber,
  allAudioPageNumber: (state) => state.allAudioPageNumber,
}

const mutations: MutationTree<any> = {
  setPodcastPageNumber(state, data) {
    const feedUid = Object.keys(data)[0]
    if (isEmpty(state.podcastPageNumber[feedUid])) {
      Vue.set(state, 'podcastPageNumber', {
        ...state.podcastPageNumber,
        ...data,
      })
    } else {
      Vue.set(state.podcastPageNumber, feedUid, {
        ...state.podcastPageNumber[feedUid],
        ...data[feedUid],
      })
    }
  },

  setAllAudioPageNumber(state, pageNo) {
    Vue.set(state, 'allAudioPageNumber', pageNo)
  },

  clearAllPaginationState() {
    Vue.set(state, 'podcastPageNumber', {})
    Vue.set(state, 'allAudioPageNumber', 1)
  },
}

const actions: ActionTree<any, any> = {
  setPodcastPageNumber(context, data) {
    context.commit('setPodcastPageNumber', data)
  },
  setAllAudioPageNumber(context, pageNo) {
    context.commit('setAllAudioPageNumber', pageNo)
  },
}

const pagination: StoreOptions<any> = {
  state,
  getters,
  mutations,
  actions,
}

export default pagination
