import { GIPHY_KEY, TENOR_KEY } from '@/constants'
import { queryDefaults } from '@/utils/query.config'
import Axios from 'axios'
import { useInfiniteQuery, useQuery } from 'vue-query'

// Query Keys
export enum gifQueryKey {
  PUBLIC_GIPHY = 'PUBLIC_IMAGES',
  TENOR = 'TENOR',
}

// Queries
const getPublicGifs = (params: any) => {
  const gifParams: any = {
    api_key: GIPHY_KEY,
    limit: params.limit,
    offset: params.offset,
  }

  params.q && (gifParams['q'] = params.q)
  return params.searchLibrary === 'TRENDING'
    ? Axios.get('https://api.giphy.com/v1/gifs/trending', {
        params: gifParams,
      })
    : Axios.get('https://api.giphy.com/v1/gifs/search', {
        params: gifParams,
      })
}
const getTenorGifs = async (params: any, nextPage: any) => {
  const gifParams: any = {
    key: TENOR_KEY,
    client_key: 'my_test_app',
    limit: 8,
  }

  params.q && (gifParams['q'] = params.q)
  nextPage && (gifParams['pos'] = nextPage)
  return Axios.get('https://tenor.googleapis.com/v2/search', {
    params: gifParams,
  })
}

// Hooks
export const useGetPublicGifs = (params: any, { enabled }: any) => {
  return useQuery([gifQueryKey.PUBLIC_GIPHY, params], () => getPublicGifs(params), {
    keepPreviousData: true,
    ...queryDefaults,
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled,
  })
}

export const useGetTenorGif = (params: any, { enabled }: any) => {
  return useInfiniteQuery([gifQueryKey.TENOR, params], ({ pageParam = null }) => getTenorGifs(params, pageParam), {
    getNextPageParam: (lastPage: any, pages) => {
      if (lastPage.data.next) return lastPage.data.next
      else false
    },
    ...queryDefaults,
    select: (data: any) => {
      const tagsArray = data.pages.flatMap((obj: any) => obj.data.results)
      return tagsArray
    },
    enabled,
  })
}
