var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    { attrs: { locked: _vm.isSubmitting } },
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "light-gray f3 fw5" }, [
          _vm._v("Edit podcast details"),
        ]),
      ]),
      _c("template", { slot: "body" }, [
        !_vm.enableImageGallery && _vm.podcast.name
          ? _c("div", { staticClass: "flex flex-wrap" }, [
              _c("div", { staticClass: "flex flex-column" }, [
                _c(
                  "form",
                  {
                    staticClass: "w-100",
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _vm.currentStep === 1
                      ? _c("div", { staticClass: "flex justify-between" }, [
                          _c(
                            "div",
                            { staticClass: "mr5" },
                            [
                              _c(
                                "div",
                                { staticClass: "pb2 gray f6" },
                                [
                                  _vm._v(
                                    "\n                Podcast image\n                "
                                  ),
                                  _c("BaseTooltip", {
                                    attrs: {
                                      info: "Image should be square, minimum of 1400 x 1400 pixels and maximum of 3000 x 3000 pixels, 72 dpi resolution and file type can of JPEG or PNG with RGB colorspace.",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("BaseThumbnailShow", {
                                attrs: {
                                  onClick: _vm.showImageGallery,
                                  src: _vm.selectedImageSrc,
                                  big: "",
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "mv2",
                                  class: _vm.hasPreviewImage
                                    ? "bw0"
                                    : "ba bw1 b--adori-red br2",
                                },
                                [
                                  _c("BaseButtonBorder", {
                                    staticClass: "w-100",
                                    attrs: {
                                      text: "Change podcast image",
                                      onClick: _vm.showImageGallery,
                                    },
                                  }),
                                ],
                                1
                              ),
                              !_vm.hasPreviewImage
                                ? _c("div", { staticClass: "f6 adori-red" }, [
                                    _vm._v("Podcast image is required."),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "podcast-fields" }, [
                            _c("div", { staticClass: "pb3 relative" }, [
                              _c(
                                "div",
                                { staticClass: "flex justify-between" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "pb2 gray f6" },
                                    [
                                      _vm._v(
                                        "\n                    Podcast name\n                    "
                                      ),
                                      _c("BaseTooltip", {
                                        attrs: {
                                          info: "Podcast name is a string containing a clear, concise name for your podcast.",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "pb2 f6",
                                      class: !_vm.$v.podcast.name.maxLength
                                        ? "adori-red"
                                        : "gray",
                                    },
                                    [
                                      _vm._v(
                                        "\n                    (" +
                                          _vm._s(
                                            _vm.$v.podcast.name.$model.length
                                          ) +
                                          " / 255)\n                  "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.$v.podcast.name.$model,
                                    expression: "$v.podcast.name.$model",
                                    modifiers: { trim: true },
                                  },
                                ],
                                staticClass:
                                  "db bg-adori-very-light-gray br2 ph3 pv3 gray f6 mb2 white w-100",
                                class:
                                  _vm.$v.podcast.name.$error ||
                                  (_vm.$v.podcast.name.$invalid &&
                                    _vm.hasClickedNext)
                                    ? "ba bw1 b--adori-red"
                                    : "ba bw1 b--adori-transparent",
                                attrs: {
                                  type: "text",
                                  placeholder: "Enter podcast name",
                                },
                                domProps: { value: _vm.$v.podcast.name.$model },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.$v.podcast.name,
                                      "$model",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                              _vm.$v.podcast.name.$dirty && !_vm.hasClickedNext
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "absolute",
                                      staticStyle: { bottom: "5px" },
                                    },
                                    [
                                      !_vm.$v.podcast.name.required
                                        ? _c(
                                            "div",
                                            { staticClass: "f6 adori-red" },
                                            [_vm._v("Cannot be empty.")]
                                          )
                                        : _vm._e(),
                                      !_vm.$v.podcast.name.maxLength
                                        ? _c(
                                            "div",
                                            { staticClass: "f6 adori-red" },
                                            [
                                              _vm._v(
                                                "Must be less than 255 characters."
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm.hasClickedNext
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "absolute",
                                      staticStyle: { bottom: "5px" },
                                    },
                                    [
                                      _vm.$v.podcast.name.$invalid
                                        ? _c("div", [
                                            !_vm.$v.podcast.name.required
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "f6 adori-red",
                                                  },
                                                  [_vm._v("Cannot be empty.")]
                                                )
                                              : _vm._e(),
                                            !_vm.$v.podcast.name.maxLength
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "f6 adori-red",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      Must be less than 255 characters.\n                    "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                            _c(
                              "div",
                              { staticClass: "pb3 relative" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "flex justify-between" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "pb2 gray f6" },
                                      [
                                        _vm._v(
                                          "\n                    Podcast description\n                    "
                                        ),
                                        _c("BaseTooltip", {
                                          attrs: {
                                            info: "Podcast description is text containing one or more sentences describing your podcast to potential listeners.",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c("HTMLEditor", {
                                  staticClass:
                                    "bg-adori-very-light-gray bw0 br2 ph3 pv1 white mb2 overflow-y-auto scrollbar",
                                  class:
                                    _vm.$v.podcast.description.$error ||
                                    (_vm.$v.podcast.description.$invalid &&
                                      _vm.hasClickedNext)
                                      ? "ba bw1 b--adori-red"
                                      : "ba bw1 b--adori-transparent",
                                  staticStyle: {
                                    width: "415px",
                                    height: "180px",
                                  },
                                  attrs: {
                                    placeholder: "Enter podcast description",
                                    initialContent:
                                      _vm.$v.podcast.description.$model,
                                    required: "",
                                  },
                                  on: { update: _vm.updateDescription },
                                }),
                                _vm.$v.podcast.description.$dirty &&
                                _vm.$v.podcast.description.$dirty &&
                                !_vm.hasClickedNext
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "absolute",
                                        staticStyle: { bottom: "5px" },
                                      },
                                      [
                                        !_vm.$v.podcast.description.required ||
                                        !_vm.$v.podcast.description.notEmptyHTML
                                          ? _c(
                                              "div",
                                              { staticClass: "f6 adori-red" },
                                              [
                                                _vm._v(
                                                  "\n                    Cannot be empty.\n                  "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        !_vm.$v.podcast.description.maxLength
                                          ? _c(
                                              "div",
                                              { staticClass: "f6 adori-red" },
                                              [
                                                _vm._v(
                                                  "\n                    Your description is too long.\n                  "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.hasClickedNext
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "absolute",
                                        staticStyle: { bottom: "5px" },
                                      },
                                      [
                                        _vm.$v.podcast.description.$invalid
                                          ? _c("div", [
                                              !_vm.$v.podcast.description
                                                .required ||
                                              !_vm.$v.podcast.description
                                                .notEmptyHTML
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "f6 adori-red",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      Cannot be empty.\n                    "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              !_vm.$v.podcast.description
                                                .maxLength
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "f6 adori-red",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      Your description is too long.\n                    "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "pb4 relative" }, [
                              _c(
                                "div",
                                { staticClass: "flex justify-between" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "pb2 gray f6" },
                                    [
                                      _vm._v(
                                        "\n                    Podcast summary\n                    "
                                      ),
                                      _c("BaseTooltip", {
                                        attrs: {
                                          info: "Podcast summary is best for a short description of your podcast. It will display above the full show notes.",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "pb2 f6",
                                      class: !_vm.$v.podcast.summary.maxLength
                                        ? "adori-red"
                                        : "gray",
                                    },
                                    [
                                      _vm._v(
                                        "\n                    (" +
                                          _vm._s(
                                            _vm.$v.podcast.summary.$model.length
                                          ) +
                                          " / 255)\n                  "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.$v.podcast.summary.$model,
                                    expression: "$v.podcast.summary.$model",
                                    modifiers: { trim: true },
                                  },
                                ],
                                staticClass:
                                  "db bg-adori-very-light-gray bw0 br2 ph3 pv3 gray f6 white mb2 overflow-y-auto scrollbar w-100",
                                class:
                                  _vm.$v.podcast.summary.$error ||
                                  (_vm.$v.podcast.summary.$invalid &&
                                    _vm.hasClickedNext)
                                    ? "ba bw1 b--adori-red"
                                    : "ba bw1 b--adori-transparent",
                                attrs: {
                                  rows: "4",
                                  cols: "40",
                                  placeholder: "Enter podcast summary",
                                },
                                domProps: {
                                  value: _vm.$v.podcast.summary.$model,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.$v.podcast.summary,
                                      "$model",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                              _vm.$v.podcast.summary.$dirty &&
                              !_vm.hasClickedNext
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "absolute",
                                      staticStyle: { bottom: "16px" },
                                    },
                                    [
                                      !_vm.$v.podcast.summary.required
                                        ? _c(
                                            "div",
                                            { staticClass: "f6 adori-red" },
                                            [_vm._v("Cannot be empty.")]
                                          )
                                        : _vm._e(),
                                      !_vm.$v.podcast.summary.maxLength
                                        ? _c(
                                            "div",
                                            { staticClass: "f6 adori-red" },
                                            [
                                              _vm._v(
                                                "\n                    Must be less than 255 characters.\n                  "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm.hasClickedNext
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "absolute",
                                      staticStyle: { bottom: "16px" },
                                    },
                                    [
                                      _vm.$v.podcast.summary.$invalid
                                        ? _c("div", [
                                            !_vm.$v.podcast.summary.required
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "f6 adori-red",
                                                  },
                                                  [_vm._v("Cannot be empty.")]
                                                )
                                              : _vm._e(),
                                            !_vm.$v.podcast.summary.maxLength
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "f6 adori-red",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      Must be less than 255 characters.\n                    "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.currentStep === 2
                      ? _c(
                          "div",
                          { staticClass: "flex flex-column pv3 min-w-10" },
                          [
                            _vm.isNetworkOwner
                              ? _c(
                                  "div",
                                  { staticClass: "flex justify-between" },
                                  [
                                    _c("div", { staticClass: "pb3 mr3" }, [
                                      _c(
                                        "div",
                                        { staticClass: "flex justify-between" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "pb2 gray f6" },
                                            [_vm._v("Owner name")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "pb2 f6",
                                              class: !_vm.$v.podcast.ownerName
                                                .maxLength
                                                ? "adori-red"
                                                : "gray",
                                            },
                                            [
                                              _vm._v(
                                                "\n                    (" +
                                                  _vm._s(
                                                    _vm.$v.podcast.ownerName
                                                      .$model.length
                                                  ) +
                                                  " / 255)\n                  "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.trim",
                                            value:
                                              _vm.$v.podcast.ownerName.$model,
                                            expression:
                                              "$v.podcast.ownerName.$model",
                                            modifiers: { trim: true },
                                          },
                                        ],
                                        staticClass:
                                          "db bg-adori-very-light-gray br2 ph3 pv3 gray f6 mb2 white w-3",
                                        class: _vm.$v.podcast.ownerName.$error
                                          ? "ba bw1 b--adori-red"
                                          : "ba bw1 b--adori-transparent",
                                        attrs: {
                                          type: "text",
                                          placeholder: "Enter the owner name",
                                        },
                                        domProps: {
                                          value:
                                            _vm.$v.podcast.ownerName.$model,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.$v.podcast.ownerName,
                                              "$model",
                                              $event.target.value.trim()
                                            )
                                          },
                                          blur: function ($event) {
                                            return _vm.$forceUpdate()
                                          },
                                        },
                                      }),
                                      _vm.$v.podcast.ownerName.$dirty
                                        ? _c("div", [
                                            !_vm.$v.podcast.ownerName.required
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "f6 adori-red",
                                                  },
                                                  [_vm._v("Cannot be empty.")]
                                                )
                                              : _vm._e(),
                                            !_vm.$v.podcast.ownerName.maxLength
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "f6 adori-red",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    Must be less than 255 characters.\n                  "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                    ]),
                                    _c("div", { staticClass: "pb3" }, [
                                      _c(
                                        "div",
                                        { staticClass: "flex justify-between" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "pb2 gray f6" },
                                            [_vm._v("Owner email")]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.$v.podcast.ownerEmail
                                                  .$model,
                                              expression:
                                                "$v.podcast.ownerEmail.$model",
                                            },
                                          ],
                                          staticClass:
                                            "input-reset light-gray bn br2 pa3 pr5 db arrow w5 mb2 scrollbar",
                                          on: {
                                            change: function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                _vm.$v.podcast.ownerEmail,
                                                "$model",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                          },
                                        },
                                        _vm._l(
                                          _vm.verifiedEmails,
                                          function (email) {
                                            return _c(
                                              "option",
                                              {
                                                key: email,
                                                domProps: { value: email },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(email) +
                                                    "\n                  "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                      _vm.$v.podcast.ownerEmail.$dirty
                                        ? _c("div", [
                                            !_vm.$v.podcast.ownerEmail.required
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "f6 adori-red",
                                                  },
                                                  [_vm._v("Cannot be empty.")]
                                                )
                                              : _vm._e(),
                                            !_vm.$v.podcast.ownerEmail.email
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "f6 adori-red",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Must be a valid email address."
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _c("div", { staticClass: "pb3 relative" }, [
                              _c(
                                "div",
                                { staticClass: "flex justify-between" },
                                [
                                  _c("div", { staticClass: "pb2 gray f6" }, [
                                    _vm._v("Publisher"),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "pb2 f6",
                                      class: !_vm.$v.podcast.publisher.maxLength
                                        ? "adori-red"
                                        : "gray",
                                    },
                                    [
                                      _vm._v(
                                        "\n                  (" +
                                          _vm._s(
                                            _vm.$v.podcast.publisher.$model
                                              .length
                                          ) +
                                          " / 255)\n                "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.$v.podcast.publisher.$model,
                                    expression: "$v.podcast.publisher.$model",
                                    modifiers: { trim: true },
                                  },
                                ],
                                staticClass:
                                  "db bg-adori-very-light-gray br2 ph3 pv3 gray f6 mb2 white w-100",
                                class: _vm.$v.podcast.publisher.$error
                                  ? "ba bw1 b--adori-red"
                                  : "ba bw1 b--adori-transparent",
                                attrs: {
                                  type: "text",
                                  placeholder: "Enter the podcast publisher",
                                },
                                domProps: {
                                  value: _vm.$v.podcast.publisher.$model,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.$v.podcast.publisher,
                                      "$model",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                              _vm.$v.podcast.publisher.$invalid &&
                              _vm.$v.podcast.publisher.$dirty &&
                              !_vm.hasClickedNext
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "absolute",
                                      staticStyle: { bottom: "5px" },
                                    },
                                    [
                                      !_vm.$v.podcast.publisher.required
                                        ? _c(
                                            "div",
                                            { staticClass: "f6 adori-red" },
                                            [_vm._v("Cannot be empty.")]
                                          )
                                        : _vm._e(),
                                      !_vm.$v.podcast.publisher.maxLength
                                        ? _c(
                                            "div",
                                            { staticClass: "f6 adori-red" },
                                            [
                                              _vm._v(
                                                "\n                  Must be less than 255 characters.\n                "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm.hasClickedNext
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "absolute",
                                      staticStyle: { bottom: "5px" },
                                    },
                                    [
                                      _vm.$v.podcast.publisher.$invalid
                                        ? _c("div", [
                                            !_vm.$v.podcast.publisher.required
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "f6 adori-red",
                                                  },
                                                  [_vm._v("Cannot be empty.")]
                                                )
                                              : _vm._e(),
                                            !_vm.$v.podcast.publisher.maxLength
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "f6 adori-red",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    Must be less than 255 characters.\n                  "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                            _c("div", { staticClass: "pb3 relative" }, [
                              _c(
                                "div",
                                { staticClass: "flex justify-between" },
                                [
                                  _c("div", { staticClass: "pb2 gray f6" }, [
                                    _vm._v("Copyright"),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "pb2 f6",
                                      class: !_vm.$v.podcast.copyright.maxLength
                                        ? "adori-red"
                                        : "gray",
                                    },
                                    [
                                      _vm._v(
                                        "\n                  (" +
                                          _vm._s(
                                            _vm.$v.podcast.copyright.$model
                                              .length
                                          ) +
                                          " / 255)\n                "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.$v.podcast.copyright.$model,
                                    expression: "$v.podcast.copyright.$model",
                                    modifiers: { trim: true },
                                  },
                                ],
                                staticClass:
                                  "db bg-adori-very-light-gray br2 ph3 pv3 gray f6 mb2 white w-100",
                                class:
                                  _vm.$v.podcast.copyright.$error ||
                                  (_vm.$v.podcast.copyright.$invalid &&
                                    _vm.hasClickedNext)
                                    ? "ba bw1 b--adori-red"
                                    : "ba bw1 b--adori-transparent",
                                attrs: {
                                  type: "text",
                                  placeholder: "Enter the podcast copyright",
                                },
                                domProps: {
                                  value: _vm.$v.podcast.copyright.$model,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.$v.podcast.copyright,
                                      "$model",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                              _vm.$v.podcast.copyright.$dirty
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "absolute",
                                      staticStyle: { bottom: "5px" },
                                    },
                                    [
                                      !_vm.$v.podcast.copyright.required
                                        ? _c(
                                            "div",
                                            { staticClass: "f6 adori-red" },
                                            [_vm._v("Cannot be empty.")]
                                          )
                                        : _vm._e(),
                                      !_vm.$v.podcast.copyright.maxLength
                                        ? _c(
                                            "div",
                                            { staticClass: "f6 adori-red" },
                                            [
                                              _vm._v(
                                                "\n                  Must be less than 255 characters.\n                "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm.hasClickedNext
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "absolute",
                                      staticStyle: { bottom: "5px" },
                                    },
                                    [
                                      _vm.$v.podcast.copyright.$invalid
                                        ? _c("div", [
                                            !_vm.$v.copyright.required
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "f6 adori-red",
                                                  },
                                                  [_vm._v("Cannot be empty.")]
                                                )
                                              : _vm._e(),
                                            !_vm.$v.podcast.copyright.maxLength
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "f6 adori-red",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    Must be less than 255 characters.\n                  "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                            _c("div", { staticClass: "pb3 relative" }, [
                              _c(
                                "div",
                                { staticClass: "flex justify-between" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "pb2 gray f6" },
                                    [
                                      _vm._v(
                                        "\n                  Website\n                  "
                                      ),
                                      _c("BaseTooltip", {
                                        attrs: {
                                          info: "Typically a home page for a podcast or a dedicated portion of a larger website.",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "pb2 f6",
                                      class: !_vm.$v.podcast.website.maxLength
                                        ? "adori-red"
                                        : "gray",
                                    },
                                    [
                                      _vm._v(
                                        "\n                  (" +
                                          _vm._s(
                                            _vm.$v.podcast.website.$model.length
                                          ) +
                                          " / 2083)\n                "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.$v.podcast.website.$model,
                                    expression: "$v.podcast.website.$model",
                                    modifiers: { trim: true },
                                  },
                                ],
                                staticClass:
                                  "db bg-adori-very-light-gray br2 ph3 pv3 gray f6 mb2 white w-100",
                                class:
                                  _vm.$v.podcast.website.$error ||
                                  (_vm.$v.podcast.website.$invalid &&
                                    _vm.hasClickedNext)
                                    ? "ba bw1 b--adori-red"
                                    : "ba bw1 b--adori-transparent",
                                attrs: {
                                  type: "text",
                                  placeholder: "https://example.com",
                                },
                                domProps: {
                                  value: _vm.$v.podcast.website.$model,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.$v.podcast.website,
                                      "$model",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                              _vm.$v.podcast.website.$invalid &&
                              _vm.$v.podcast.website.$dirty &&
                              !_vm.hasClickedNext
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "absolute",
                                      staticStyle: { bottom: "5px" },
                                    },
                                    [
                                      !_vm.$v.podcast.website.required
                                        ? _c(
                                            "div",
                                            { staticClass: "f6 adori-red" },
                                            [_vm._v("Cannot be empty.")]
                                          )
                                        : _vm._e(),
                                      !_vm.$v.podcast.website.urlVal
                                        ? _c(
                                            "div",
                                            { staticClass: "f6 adori-red" },
                                            [_vm._v("Must be a valid url.")]
                                          )
                                        : _vm._e(),
                                      !_vm.$v.podcast.website.maxLength
                                        ? _c(
                                            "div",
                                            { staticClass: "f6 adori-red" },
                                            [
                                              _vm._v(
                                                "Must be less than 255 characters."
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm.hasClickedNext
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "absolute",
                                      staticStyle: { bottom: "5px" },
                                    },
                                    [
                                      _vm.$v.podcast.website.$invalid
                                        ? _c("div", [
                                            !_vm.$v.podcast.website.required
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "f6 adori-red",
                                                  },
                                                  [_vm._v("Cannot be empty.")]
                                                )
                                              : !_vm.$v.podcast.website.urlVal
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "f6 adori-red",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Must be a valid url."
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            !_vm.$v.podcast.website.maxLength
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "f6 adori-red",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    Must be less than 255 characters.\n                  "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm.currentStep === 3
                      ? _c("div", { staticClass: "flex justify-between pv3" }, [
                          _c("div", { staticClass: "flex flex-column mr4" }, [
                            _c("div", { staticClass: "pb3" }, [
                              _c(
                                "div",
                                { staticClass: "flex justify-between" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "pb2 gray f6 mb2" },
                                    [_vm._v("Language")]
                                  ),
                                ]
                              ),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.$v.podcast.language.$model,
                                      expression: "$v.podcast.language.$model",
                                    },
                                  ],
                                  staticClass:
                                    "input-reset light-gray bn br2 pa3 pr5 shadow-2 db arrow w5 mb2",
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.$v.podcast.language,
                                        "$model",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                  },
                                },
                                _vm._l(_vm.languages, function (language) {
                                  return _c(
                                    "option",
                                    {
                                      key: language.name,
                                      domProps: { value: language.code },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(language.name) +
                                          "\n                  "
                                      ),
                                    ]
                                  )
                                }),
                                0
                              ),
                              _vm.$v.podcast.language.$dirty
                                ? _c("div", [
                                    !_vm.$v.podcast.language.required
                                      ? _c(
                                          "div",
                                          { staticClass: "f6 adori-red" },
                                          [_vm._v("Cannot be empty.")]
                                        )
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                            ]),
                            _c("div", { staticClass: "pb3" }, [
                              _c(
                                "div",
                                { staticClass: "flex justify-between" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "pb2 gray f6" },
                                    [
                                      _vm._v(
                                        "\n                    Type of episode listing\n                    "
                                      ),
                                      _c("BaseTooltip", {
                                        attrs: {
                                          info: "“Episodic” for non-chronological episodes that will behave as they have for years and download the latest episode, or “Serial” for chronological episodes that should be consumed oldest to newest.",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.$v.podcast.episodeListingType
                                          .$model,
                                      expression:
                                        "$v.podcast.episodeListingType.$model",
                                    },
                                  ],
                                  staticClass:
                                    "input-reset light-gray bn br2 pa3 pr5 shadow-2 db arrow w5 mb2",
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.$v.podcast.episodeListingType,
                                        "$model",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                  },
                                },
                                _vm._l(
                                  _vm.episodeListingTypes,
                                  function (type) {
                                    return _c(
                                      "option",
                                      {
                                        key: type.value,
                                        domProps: { value: type.value },
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(type.text) +
                                            "\n                  "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                              _vm.$v.podcast.episodeListingType.$dirty
                                ? _c("div", [
                                    !_vm.$v.podcast.episodeListingType.required
                                      ? _c(
                                          "div",
                                          { staticClass: "f6 adori-red" },
                                          [_vm._v("Cannot be empty.")]
                                        )
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                            ]),
                            _c("div", { staticClass: "pb3 relative" }, [
                              _c(
                                "div",
                                { staticClass: "flex justify-between" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "pb2 gray f6 mb2" },
                                    [_vm._v("Podtrac Analytics Prefix")]
                                  ),
                                ]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.$v.podcast.anltPrefix.$model,
                                    expression: "$v.podcast.anltPrefix.$model",
                                    modifiers: { trim: true },
                                  },
                                ],
                                staticClass:
                                  "db bg-adori-very-light-gray br2 ph3 pv3 gray f6 mb2 white w-100",
                                class:
                                  _vm.$v.podcast.anltPrefix.$error ||
                                  (_vm.$v.podcast.anltPrefix.$invalid &&
                                    _vm.hasClickedNext)
                                    ? "ba bw1 b--adori-red"
                                    : "ba bw1 b--adori-transparent",
                                attrs: {
                                  type: "text",
                                  placeholder: "Enter a URL",
                                },
                                domProps: {
                                  value: _vm.$v.podcast.anltPrefix.$model,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.$v.podcast.anltPrefix,
                                      "$model",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                              _vm.hasClickedNext
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "absolute",
                                      staticStyle: { bottom: "5px" },
                                    },
                                    [
                                      _vm.$v.podcast.anltPrefix.$invalid
                                        ? _c("div", [
                                            !_vm.$v.podcast.anltPrefix.urlVal
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "f6 adori-red",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Must be a valid url."
                                                    ),
                                                  ]
                                                )
                                              : !_vm.$v.podcast.anltPrefix
                                                  .maxLength
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "f6 adori-red",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      Must be less than 255 characters.\n                    "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                            _c("div", { staticClass: "pb3" }, [
                              _c(
                                "div",
                                { staticClass: "flex justify-between mb2" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "pb2 gray f6" },
                                    [
                                      _vm._v(
                                        "\n                    Does this show contain explicit content?\n                    "
                                      ),
                                      _c("BaseTooltip", {
                                        attrs: {
                                          info: "This indicates whether the episode contain explicit language or adult content.",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "pv1 ba br4 b--transparent bg-black-20 tc",
                                  staticStyle: { width: "85px" },
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "f6 ttu pv1 ph2 silver link pointer",
                                      class: _vm.$v.podcast.explicitContent
                                        .$model
                                        ? "br4 bg-black-80 light-gray"
                                        : "",
                                    },
                                    [
                                      _c("input", {
                                        staticClass: "input-reset",
                                        attrs: { type: "radio" },
                                        domProps: {
                                          checked:
                                            _vm.$v.podcast.explicitContent
                                              .$model,
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.$v.podcast.explicitContent.$model = true
                                          },
                                        },
                                      }),
                                      _vm._v("Yes"),
                                    ]
                                  ),
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "f6 ttu pv1 ph2 silver link pointer",
                                      class: !_vm.$v.podcast.explicitContent
                                        .$model
                                        ? "br4 bg-black-80 light-gray"
                                        : "",
                                    },
                                    [
                                      _c("input", {
                                        staticClass: "input-reset",
                                        attrs: { type: "radio" },
                                        domProps: {
                                          checked:
                                            !_vm.$v.podcast.explicitContent
                                              .$model,
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.$v.podcast.explicitContent.$model = false
                                          },
                                        },
                                      }),
                                      _vm._v("No"),
                                    ]
                                  ),
                                ]
                              ),
                              _vm.$v.podcast.explicitContent.$dirty
                                ? _c("div", [
                                    !_vm.$v.podcast.explicitContent.required
                                      ? _c(
                                          "div",
                                          { staticClass: "f6 adori-red" },
                                          [_vm._v("Cannot be empty.")]
                                        )
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "flex flex-column justify-between" },
                            [
                              _c("div", { staticClass: "pb3 relative" }, [
                                _c(
                                  "div",
                                  { staticClass: "flex justify-between" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "pb2 gray f6" },
                                      [
                                        _vm._v(
                                          "\n                    Categories\n                    "
                                        ),
                                        _c("BaseTooltip", {
                                          attrs: {
                                            info: "Select the categories that best reflects the content of your show.",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "select",
                                  {
                                    staticClass:
                                      "input-reset light-gray br2 pa3 pr5 shadow-2 db arrow w5 mb3",
                                    class:
                                      (_vm.$v.podcast.genreIds.$model.length ===
                                        0 &&
                                        _vm.hasClickedNext) ||
                                      (!_vm.hasClickedNext &&
                                        _vm.$v.podcast.genreIds.$model
                                          .length === 0 &&
                                        _vm.hasClearedCategories)
                                        ? "ba bw1 b--adori-red"
                                        : "ba bw1 b--adori-transparent",
                                    on: { change: _vm.handleAddGenre },
                                  },
                                  [
                                    _c(
                                      "option",
                                      { attrs: { disabled: "", selected: "" } },
                                      [_vm._v("Select categories")]
                                    ),
                                    _vm._l(_vm.rssGenres, function (genre) {
                                      return [
                                        _c(
                                          "option",
                                          {
                                            key: genre.id,
                                            staticClass: "b",
                                            domProps: { value: genre.id },
                                          },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(genre.name) +
                                                "\n                    "
                                            ),
                                          ]
                                        ),
                                        _vm._l(
                                          genre.subGenres,
                                          function (subgenre) {
                                            return _c(
                                              "option",
                                              {
                                                key: subgenre.id,
                                                domProps: {
                                                  value: subgenre.id,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                         " +
                                                    _vm._s(subgenre.name) +
                                                    "\n                    "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                                (_vm.$v.podcast.genreIds.$model.length === 0 &&
                                  _vm.hasClickedNext) ||
                                (!_vm.hasClickedNext &&
                                  _vm.$v.podcast.genreIds.$model.length === 0 &&
                                  _vm.hasClearedCategories)
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "f6 adori-red absolute",
                                        staticStyle: { bottom: "12px" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  Cannot be empty.\n                "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "mw5 max-h4 overflow-y-auto scrollbar",
                                  },
                                  _vm._l(
                                    _vm.$v.podcast.genreIds.$model,
                                    function (id) {
                                      return _c(
                                        "span",
                                        {
                                          key: id,
                                          staticClass:
                                            "dib f6 pv2 ph3 br4 bg-black-30 light-gray mr2 mb2",
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                (_vm.genreFromId(id) &&
                                                  _vm.genreFromId(id).name) ||
                                                  "Unknown category"
                                              ) +
                                              "\n                    "
                                          ),
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "ml1 material-icons f5 v-mid link pointer dim",
                                              on: {
                                                click: function () {
                                                  return _vm.handleRemoveGenre(
                                                    id
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("close")]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]),
                              _c("div", { staticClass: "pb3 relative" }, [
                                _c(
                                  "div",
                                  { staticClass: "flex justify-between" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "pb2 gray f6 mb2" },
                                      [_vm._v("Keywords")]
                                    ),
                                  ]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value:
                                        _vm.$v.podcast.episodeKeywords.$model,
                                      expression:
                                        "$v.podcast.episodeKeywords.$model",
                                      modifiers: { trim: true },
                                    },
                                  ],
                                  staticClass:
                                    "db bg-adori-very-light-gray br2 ph3 pv3 gray f6 mb2 white w-100",
                                  class: _vm.$v.podcast.episodeKeywords.$error
                                    ? "ba bw1 b--adori-red"
                                    : "ba bw1 b--adori-transparent",
                                  attrs: {
                                    type: "text",
                                    placeholder:
                                      "Enter keywords separated by comma",
                                  },
                                  domProps: {
                                    value:
                                      _vm.$v.podcast.episodeKeywords.$model,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.$v.podcast.episodeKeywords,
                                        "$model",
                                        $event.target.value.trim()
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "absolute",
                                    staticStyle: { bottom: "5px" },
                                  },
                                  [
                                    !_vm.$v.podcast.episodeKeywords.maxLength
                                      ? _c(
                                          "div",
                                          { staticClass: "f6 adori-red" },
                                          [
                                            _vm._v(
                                              "\n                    Must be less than 255 characters.\n                  "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "pb3 relative" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "flex justify-between" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "pb2 gray f6" },
                                        [_vm._v("Episode footnote")]
                                      ),
                                    ]
                                  ),
                                  _c("HTMLEditor", {
                                    staticClass:
                                      "bg-adori-very-light-gray bw0 br2 ph3 pv1 white mb2 w5 overflow-y-auto scrollbar",
                                    class: _vm.$v.podcast.episodeFootNote.$error
                                      ? "ba bw1 b--adori-red"
                                      : "ba bw1 b--adori-transparent",
                                    staticStyle: { height: "180px" },
                                    attrs: {
                                      placeholder: "Enter episode footnote",
                                      initialContent:
                                        _vm.$v.podcast.episodeFootNote.$model,
                                      required: "",
                                    },
                                    on: { update: _vm.updateFootNote },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "absolute",
                                      staticStyle: { bottom: "5px" },
                                    },
                                    [
                                      !_vm.$v.podcast.episodeFootNote.maxLength
                                        ? _c(
                                            "div",
                                            { staticClass: "f6 adori-red" },
                                            [
                                              _vm._v(
                                                "\n                    Your footNote is too long.\n                  "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
                _vm.currentStep === 1
                  ? _c("div", { staticClass: "flex flex-wrap" }, [
                      _c("div", {
                        staticClass: "w-third flex justify-start items-center",
                      }),
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-third flex flex-column justify-center items-center",
                        },
                        [
                          _c("p", { staticClass: "silver mv0" }, [
                            _vm._v("Step 1 of 3"),
                          ]),
                          _c(
                            "p",
                            {
                              staticClass: "f6 silver",
                              staticStyle: { "margin-top": "5px" },
                            },
                            [_vm._v("All fields are required.")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "w-third flex justify-end items-center",
                        },
                        [
                          _c("BaseButtonBorder", {
                            attrs: { text: "Next", onClick: _vm.handleNextTab },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm.currentStep === 2
                  ? _c("div", { staticClass: "flex flex-wrap" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-third flex justify-start items-center",
                        },
                        [
                          _c("BaseButtonBorder", {
                            attrs: { text: "Back", onClick: _vm.handlePrevTab },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-third flex flex-column justify-center items-center",
                        },
                        [
                          _c("p", { staticClass: "silver mv0" }, [
                            _vm._v("Step 2 of 3"),
                          ]),
                          _c(
                            "p",
                            {
                              staticClass: "f6 silver",
                              staticStyle: { "margin-top": "5px" },
                            },
                            [_vm._v("All fields are required.")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "w-third flex justify-end items-center",
                        },
                        [
                          _c("BaseButtonBorder", {
                            attrs: { text: "Next", onClick: _vm.handleNextTab },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm.currentStep === 3
                  ? _c("div", { staticClass: "flex flex-wrap" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-third flex justify-start items-center",
                        },
                        [
                          _c("BaseButtonBorder", {
                            attrs: { text: "Back", onClick: _vm.handlePrevTab },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-third flex flex-column justify-center items-center",
                        },
                        [
                          _c("p", { staticClass: "silver mv0" }, [
                            _vm._v("Step 3 of 3"),
                          ]),
                          _c(
                            "p",
                            {
                              staticClass: "f6 silver",
                              staticStyle: { "margin-top": "5px" },
                            },
                            [_vm._v("All fields are required.")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "w-third flex justify-end items-center",
                        },
                        [
                          _vm.isSubmitting
                            ? _c("div", {
                                staticClass: "loader loader-inline mh3",
                              })
                            : _vm._e(),
                          _c("BaseButtonRed", {
                            attrs: {
                              text: "Update podcast",
                              onClick: _vm.handleEditPodcastSubmit,
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]),
            ])
          : _c(
              "div",
              [
                _c("ImageGallery", {
                  attrs: { galleryParent: "PODCAST_SHOW" },
                  on: {
                    showTag: _vm.showImageGallery,
                    handleSelectedImage: _vm.handleSelectedImage,
                  },
                }),
              ],
              1
            ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }