var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "bg-search-gray pa1 br2 ba b--search-gray bw0" },
      [
        _c("i", { staticClass: "material-icons v-mid light-gray mh2" }, [
          _vm._v("search"),
        ]),
        _c("input", {
          staticClass: "bg-search-gray bw0 light-gray v-mid",
          attrs: { placeholder: "Search: Coming soon" },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }