





















































































































































































































































































































































































































































































































import { Component, Mixins } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import HTMLEditor from '@/components/Common/HTMLEditor.vue'
import { validationMixin } from 'vuelidate'
import { required, maxLength, numeric } from 'vuelidate/lib/validators'
import ImagePreviewer from '@/mixins/ImagePreviewer'
import AdoriService, { AdoriAPI, getImageSrc } from '@/services/adori'
import { toReadableDate } from '@/utils/time'
import moment from 'moment'
import ImageGallery from '@/components/Common/Images/ImageGallery.vue'

import { removeStyles } from '@/utils/misc'
import { suggestionSource } from '../Tags/Suggestions/Suggestions'
import { audioQueryKeys } from '@/hooks/audio'
import Invalidate from '@/mixins/Invalidate'

const validations = {
  selectedEpisode: {
    name: {
      required,
      maxLength: maxLength(255),
    },
    description: {
      required,
      maxLength: maxLength(65535),
      notEmptyHTML: (value: string) => value !== '<p><br></p>',
    },
    summary: {
      required,
      maxLength: maxLength(255),
    },
    episodeType: {
      required,
    },
    season: {
      numeric,
    },
    explicitContent: {
      required,
    },
    keywords: {
      maxLength: maxLength(255),
    },
    scheduleTime: {},
    episodeNumber: {
      numeric,
    },
    publishedOn: {},
  },
}

@Component({
  // @ts-ignore
  validations,
  components: { HTMLEditor, ImageGallery },
})
export default class ModalPodcastEpisodeEdit extends Mixins(validationMixin, ImagePreviewer, Invalidate) {
  @Getter userId!: any
  @Getter networkId!: any
  @Getter defaultAudioCollection!: any
  @Getter selectedAudios!: any
  @Getter audio!: any
  @Getter rssFeedAudioTrackUids!: any
  @Getter selectedRssFeedUid!: any
  @Getter rssFeedAudioTrack!: any
  @Getter selectedFile!: any
  @Getter languages!: any
  @Getter networkType!: any
  @Getter isYoutube!: Boolean

  @Action unselectAllAudios!: any
  @Action setSelectedAudioCollectionId!: any
  @Action updateAudioTrack!: any
  @Action updateRssFeedItem!: any
  @Action getRssFeedItem!: any
  @Action addRssFeedItem!: any
  @Action publishRssFeed!: any
  @Action closeModal!: any
  @Action updateRssScheduledFeedItem!: any
  @Action getLanguages!: any

  $v: any
  hasClickedNext: boolean = false
  hasClickedImage: boolean = false

  currentStep: number = 1
  selectedEpisode: any = null
  selectedEpisodeImageData: string = ''
  isSubmitting: boolean = false
  errorMessage: string = ''
  showAdvancedOptions: boolean = true

  episodeTypes = [
    { text: 'Regular', value: 'REGULAR' },
    { text: 'Trailer', value: 'TRAILER' },
    { text: 'Bonus', value: 'BONUS' },
  ]

  belongsToSeason: boolean = false
  hasEpisodeNumber: boolean = true
  episodePublishType: string = 'PUBLISH_LATER' // One of 'PUBLISH_NOW' or 'PUBLISH_LATER'

  today: Date = new Date()

  enableImageGallery: boolean = false
  selectedImageSrc: string = ''
  selectedImageId: string = ''

  suggestedDate: any = ''
  isEpisodeScheduled: boolean = false

  selectedLanguage: string = 'en-US'
  languageDisabled: boolean = false

  async created() {
    const selectedEpisodeUid = this.$store.state.modal.podcastEpisodeEditor.episodeUid
    let selectedPodcastEpisode = await this.rssFeedAudioTrack(this.selectedRssFeedUid, selectedEpisodeUid)

    if (!selectedPodcastEpisode) {
      selectedPodcastEpisode = this.episode
    }
    if (!this.languages) await this.getLanguages()

    this.selectedEpisode = selectedPodcastEpisode
    this.selectedLanguage = selectedPodcastEpisode.language || 'en-US'
    this.selectedEpisode = {
      ...this.selectedEpisode,
      summary: this.selectedEpisode.summary || '',
      keywords: (this.selectedEpisode.keywords && this.selectedEpisode.keywords.join(', ')) || '',
      explicitContent: this.selectedEpisode.explicitContent || false,
    }
    this.selectedEpisode = {
      ...this.selectedEpisode,
      episodeType: selectedPodcastEpisode.episodeType || 'REGULAR',
      episodeNumber: selectedPodcastEpisode.episodeNumber || 1,
      scheduleTime: selectedPodcastEpisode.scheduleTime || null,
      season: selectedPodcastEpisode.season || 1,
      description: selectedPodcastEpisode.description || '',
      summary: selectedPodcastEpisode.summary || '',
    }

    this.belongsToSeason = !!selectedPodcastEpisode.season
    this.hasEpisodeNumber = !!selectedPodcastEpisode.episodeNumber
    this.selectedEpisodeImageData = ''

    this.selectedImageSrc = this.selectedEpisode.imageInfo.url
    const days = 7
    const date = new Date()
    const last = new Date(date.getTime() + days * 24 * 60 * 60 * 1000)
    this.suggestedDate = last
  }

  get formatedSuggestedDate() {
    return moment(this.suggestedDate).format('MMMM Do, YYYY, h:mm a')
  }

  handleSuggestedDate() {
    this.$v.selectedEpisode.scheduleTime.$model = this.suggestedDate
  }

  beforeDestroy() {
    this.suggestedDate = ''
  }

  get episode() {
    return this.audio(this.$store.state.modal.podcastEpisodeEditor.episodeUid)
  }

  get hasCompletedStep1() {
    return (
      !this.$v.selectedEpisode.name.$invalid &&
      !this.$v.selectedEpisode.description.$invalid &&
      !this.$v.selectedEpisode.summary.$invalid &&
      this.hasPreviewImage
    )
  }

  get hasPreviewImage() {
    return (
      (this.previewImage !== '' && this.previewImage !== '/img/Web link.c650ed21.jpg') || this.selectedImageSrc !== ''
    )
  }

  get formatedPublishedOn() {
    return moment(this.$v.selectedEpisode.publishedOn.$model).format('MMMM Do, YYYY, h:mm a')
  }

  get formatedPublishedOrScheduled() {
    this.isEpisodeScheduled = this.selectedEpisode.isScheduled
    if (this.selectedEpisode.isScheduled) {
      return 'Scheduled for: ' + moment(this.selectedEpisode.scheduleTime).format('MMMM Do, YYYY, h:mm a')
    } else if (this.selectedEpisode && this.selectedEpisode.publishedOn) {
      return 'Published on: ' + moment(this.selectedEpisode.publishedOn).format('MMMM Do, YYYY, h:mm a')
    }
    return null
  }

  updateDescription(newDescription: string) {
    this.$v.selectedEpisode.description.$model = newDescription
  }

  get disableSubmit() {
    return this.$v.selectedEpisode.$invalid || this.isSubmitting
  }

  showImageGallery() {
    this.enableImageGallery = !this.enableImageGallery
  }

  handleSelectedImage(imgData: any) {
    if (imgData.urls.full && imgData.urls.full.includes('adorilabs')) {
      this.selectedImageSrc = imgData.urls.full
      this.selectedImageId = imgData.id
    } else if (imgData.urls.full && imgData.urls.full.startsWith('https://')) {
      this.selectedImageSrc = imgData.urls.full
    } else if (this.previewImage !== '/img/Web link.c650ed21.jpg') {
      this.selectedImageSrc = this.previewImage
    } else {
      this.selectedImageSrc = '/img/Web link.c650ed21.jpg'
    }
    this.enableImageGallery = !this.enableImageGallery
  }

  async handleEditEpisodeSubmit() {
    this.isSubmitting = true

    const audioTrackPayload: any = {
      name: this.selectedEpisode.name,
      description: removeStyles(this.selectedEpisode.description),
      summary: this.selectedEpisode.summary,
      keywords: this.keywordStringToArray(this.selectedEpisode.keywords),
      explicitContent: this.selectedEpisode.explicitContent,
    }

    let imageId

    if (this.selectedImageSrc && this.selectedImageSrc.includes('adorilabs')) {
      if (this.selectedImageId) {
        audioTrackPayload.imageId = this.selectedImageId
      }
    } else if (this.selectedImageSrc && this.selectedImageSrc.startsWith('https://')) {
      const img: any = await AdoriService.uploadImage(this.networkId, {
        url: this.selectedImageSrc,
      })
      audioTrackPayload.imageId = img.id
    } else {
      const img: any = await AdoriService.uploadImage(this.networkId, this.selectedFile)
      audioTrackPayload.imageId = img.id
    }

    // PUBLISH_NOW

    let rssFeedItemPayload: any = {
      episodeType: this.selectedEpisode.episodeType,
      episodeNumber: this.hasEpisodeNumber ? this.selectedEpisode.episodeNumber : null,
      season: this.belongsToSeason ? this.selectedEpisode.season : null,
    }

    let rssFeedItemScheduledPayload: any = {
      ...rssFeedItemPayload,
    }

    if (this.isEpisodeScheduled && this.$permissions.isScheduleEpisodeShowAllowed(this.selectedRssFeedUid)) {
      let scheduleTime: any = ''
      if (this.episodePublishType === 'PUBLISH_NOW') {
        scheduleTime = Date.now()
      } else if (this.episodePublishType === 'PUBLISH_LATER') {
        scheduleTime = this.$v.selectedEpisode.scheduleTime.$model
      }
      rssFeedItemScheduledPayload = {
        ...rssFeedItemPayload,
        scheduleTime: scheduleTime,
      }
    }

    // if (this.episodePublishType === 'PUBLISH_LATER') {
    //   rssFeedItemPayload.scheduleTime = this.selectedEpisode.scheduleTime.getTime()
    // }

    try {
      await this.updateAudioTrack({
        audioUid: this.selectedEpisode.uid,
        payload: audioTrackPayload,
      })

      const feedUid = this.$route.params?.id

      if (!this.isYoutube) {
        await this.updateRssFeedItem({
          rssFeedUid: this.selectedRssFeedUid,
          rssFeedItemUid: this.selectedEpisode.uid,
          itemUid: this.selectedEpisode.uid,
          payload: rssFeedItemPayload,
        })

        if (this.$permissions.isScheduleEpisodeShowAllowed(this.selectedRssFeedUid)) {
          await this.updateRssScheduledFeedItem({
            rssFeedUid: this.selectedRssFeedUid,
            rssFeedItemUid: this.selectedEpisode.uid,
            itemUid: this.selectedEpisode.uid,
            payload: rssFeedItemScheduledPayload,
          })
        }

        await this.publishRssFeed({
          rssFeedUid: this.selectedRssFeedUid,
        })
      }
      if (feedUid) {
        this.queryClient.invalidateQueries([audioQueryKeys.YTPUBLISHED, `${feedUid}`])
        this.queryClient.invalidateQueries(audioQueryKeys.ALLTRACKS)
      } else {
        this.queryClient.invalidateQueries(audioQueryKeys.YTPUBLISHED)
        this.queryClient.invalidateQueries(audioQueryKeys.ALLTRACKS)
      }
      this.closeModal()
    } catch (error: any) {
      this.errorMessage = error.message
      this.isSubmitting = false
    }

    this.isSubmitting = false
  }

  keywordStringToArray(keywordString: string) {
    if (keywordString === '') return []
    return keywordString.split(',').map((keyword: string) => keyword.trim())
  }

  handlePrevTab() {
    this.currentStep -= 1
  }

  handleNextTab() {
    if (this.currentStep === 1 && this.hasCompletedStep1) {
      this.hasClickedNext = false
      this.currentStep += 1
    } else {
      this.hasClickedNext = true
    }
  }

  dateFormator(date: number) {
    return toReadableDate(date)
  }
}
