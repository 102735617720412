










import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ components: {} })
export default class AudiogramWaveform extends Vue {
  @Prop() waveform!: any

  activeHover: boolean = false

  loadWaveforms() {
    let waveformData = {
      imageUrl: this.waveform.imageUrl,
      enum: this.waveform.enum,
    }
    this.$emit('load-waveform', waveformData)
  }

  get img() {
    return this.waveform.imageUrl
  }
  get video1() {
    return this.waveform.webmUrl
  }
  get video2() {
    return this.waveform.mp4Url
  }
}
