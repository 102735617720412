var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ba circle tc dib bg-black-70 b--adori-gray gray btn-shadow",
    },
    [
      _c("i", { staticClass: "material-icons f6 pt1 white db v-top" }, [
        _vm._v(_vm._s(_vm.icon)),
      ]),
      _vm.text
        ? _c("p", { staticClass: "f8 mv0 gray" }, [_vm._v(_vm._s(_vm.text))])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }