











import { Vue, Component } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

@Component
export default class TheFileUploader extends Vue {
  @Action setFileUploaderPreferences!: any

  mounted() {
    this.setFileUploaderPreferences({
      ref: this.$refs.file,
    })
  }
  updated() {
    let file = this.$refs.file
    if (!file) return
    this.setFileUploaderPreferences({
      ref: file,
    })
  }
}
