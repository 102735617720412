export const ADORI_STUDIO_VERSION = 'dev-local'

export const ADORI_SSO_BASE_URL = 'https://accounts.dev.adorilabs.com'
export const ADORI_API_BASE_URL = 'https://dev.adorilabs.com/service/v5.1'
export const ADORI_API_BASE_URL_V6 = 'https://dev.adorilabs.com/service/studio/v6'
export const ADORI_WEBPLAYER_BASE_URL = 'https://webplayer.dev.adorilabs.com'
export const ADORI_PODCASTS_BASE_URL = 'https://podcasts.dev.adorilabs.com'
export const ADORI_INSIGHTS_BASE_URL = 'https://insights.dev.adorilabs.com'

export const AUTH0_DOMAIN = 'adori-dev.auth0.com'
export const AUTH0_CLIENT_ID = 'byFG8j5SOJZSA4LaM8tVYVihPblTdMh6'
export const GROW_SURF_CAMPAIGN_ID = 'on4krf'
export const SMARTLOOK_ID = '7136eeeb6b65f3eeb041d5f3c5c923eddf2155ac'
export const INTERCOM_ID = 'ai2eb9ym'

export const TOKEN_STORAGE_KEY = 'id_token'
export const EXPIRY_STORAGE_KEY = 'expires_at'
export const GIPHY_KEY = 'bzHstrCorbcQ0siOWDjLhHQ3giUMEQcQ'
export const TENOR_KEY = 'AIzaSyC9Emjvzs5eWgmK9SYv0wLkanTeAjdQZ7k'

// This is not dependent on the deployment mode.
export const ADORI_HELP_BASE_URL = 'https://help.adorilabs.com'
