var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    { attrs: { locked: _vm.isSubmitting } },
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "light-gray f3 fw5 tc fl" }, [
          _vm._v("Edit audio track details"),
        ]),
      ]),
      _c("template", { slot: "body" }, [
        !_vm.enableImageGallery
          ? _c("div", { staticClass: "flex flex-wrap" }, [
              _c("div", { staticClass: "flex flex-column" }, [
                _c(
                  "form",
                  {
                    staticClass: "w-100",
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "flex justify-between" }, [
                      _c("div", { staticClass: "mr4" }, [
                        _c(
                          "div",
                          { staticClass: "pb3" },
                          [
                            _c("div", { staticClass: "pb2 gray f6" }, [
                              _vm._v("Audio track image"),
                            ]),
                            _c("BaseThumbnailEpisode", {
                              attrs: {
                                onClick: _vm.showImageGallery,
                                src: _vm.selectedImageSrc,
                                big: "",
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "mv2",
                                class: _vm.hasPreviewImage
                                  ? "bw0"
                                  : "ba bw1 b--adori-red br2",
                              },
                              [
                                _c("BaseButtonBorder", {
                                  staticClass: "w-100",
                                  attrs: {
                                    text: "Change audio track image",
                                    onClick: _vm.showImageGallery,
                                  },
                                }),
                              ],
                              1
                            ),
                            !_vm.hasPreviewImage
                              ? _c("div", { staticClass: "f6 adori-red" }, [
                                  _vm._v("Audio track image is required."),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "flex justify-start items-center pb3",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "f6 link pointer dim silver flex items-center",
                                on: {
                                  click: function ($event) {
                                    _vm.showAdvancedOptions =
                                      !_vm.showAdvancedOptions
                                  },
                                },
                              },
                              [
                                _c(
                                  "i",
                                  { staticClass: "material-icons f6 mr1" },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.showAdvancedOptions
                                            ? "remove_circle"
                                            : "add_circle"
                                        ) +
                                        "\n                  "
                                    ),
                                  ]
                                ),
                                _c("span", { staticClass: "mt1" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.showAdvancedOptions ? "Hide" : "Show"
                                    ) + " advanced options"
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        ),
                        _vm.showAdvancedOptions
                          ? _c("div", { staticClass: "pb3" }, [
                              _c(
                                "div",
                                { staticClass: "flex justify-between" },
                                [
                                  _c("div", { staticClass: "pb2 gray f6" }, [
                                    _vm._v("Keywords (optional)"),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "pb2 f6",
                                      class: !_vm.$v.keywords.maxLength
                                        ? "adori-red"
                                        : "gray",
                                    },
                                    [
                                      _vm._v(
                                        "\n                    (" +
                                          _vm._s(
                                            _vm.$v.keywords.$model.length
                                          ) +
                                          " / 255)\n                  "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.$v.keywords.$model,
                                    expression: "$v.keywords.$model",
                                    modifiers: { trim: true },
                                  },
                                ],
                                staticClass:
                                  "db bg-adori-very-light-gray br2 ph3 pv3 gray f6 mb2 white w-100",
                                class: _vm.$v.keywords.$error
                                  ? "ba bw1 b--adori-red"
                                  : "bw0",
                                attrs: {
                                  type: "text",
                                  placeholder:
                                    "Enter keywords separated by comma",
                                },
                                domProps: { value: _vm.$v.keywords.$model },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.$v.keywords,
                                      "$model",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                              _vm.$v.keywords.$dirty
                                ? _c("div", [
                                    !_vm.$v.keywords.maxLength
                                      ? _c(
                                          "div",
                                          { staticClass: "f6 adori-red" },
                                          [
                                            _vm._v(
                                              "Must be less than 255 characters."
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _vm.showAdvancedOptions
                          ? _c("div", { staticClass: "pb3" }, [
                              _c(
                                "div",
                                { staticClass: "flex justify-between" },
                                [
                                  _c("div", { staticClass: "pb2 gray f6" }, [
                                    _vm._v(
                                      "Does this episode contain explicit content?"
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "pv1 ba br4 b--transparent bg-black-20",
                                  staticStyle: { width: "80px" },
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "f6 ttu pv1 ph2 silver link pointer",
                                      class: _vm.$v.explicitContent.$model
                                        ? "br4 bg-black-80 light-gray"
                                        : "",
                                    },
                                    [
                                      _c("input", {
                                        staticClass: "input-reset",
                                        attrs: { type: "radio" },
                                        domProps: {
                                          checked:
                                            _vm.$v.explicitContent.$model,
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.$v.explicitContent.$model = true
                                          },
                                        },
                                      }),
                                      _vm._v("Yes"),
                                    ]
                                  ),
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "f6 ttu pv1 ph2 silver link pointer",
                                      class: !_vm.$v.explicitContent.$model
                                        ? "br4 bg-black-80 light-gray"
                                        : "",
                                    },
                                    [
                                      _c("input", {
                                        staticClass: "input-reset",
                                        attrs: { type: "radio" },
                                        domProps: {
                                          checked:
                                            !_vm.$v.explicitContent.$model,
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.$v.explicitContent.$model = false
                                          },
                                        },
                                      }),
                                      _vm._v("No"),
                                    ]
                                  ),
                                ]
                              ),
                              _vm.$v.explicitContent.$dirty
                                ? _c("div", [
                                    !_vm.$v.explicitContent.required
                                      ? _c(
                                          "div",
                                          { staticClass: "f6 adori-red" },
                                          [_vm._v("Cannot be empty.")]
                                        )
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "audiotrack-fields" }, [
                        _c("div", { staticClass: "pb3 relative" }, [
                          _c("div", { staticClass: "flex justify-between" }, [
                            _c("div", { staticClass: "pb2 gray f6" }, [
                              _vm._v("Audio name"),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "pb2 f6",
                                class: !_vm.$v.name.maxLength
                                  ? "adori-red"
                                  : "gray",
                              },
                              [
                                _vm._v(
                                  "\n                    (" +
                                    _vm._s(_vm.$v.name.$model.length) +
                                    " / 255)\n                  "
                                ),
                              ]
                            ),
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.$v.name.$model,
                                expression: "$v.name.$model",
                                modifiers: { trim: true },
                              },
                            ],
                            staticClass:
                              "db bg-adori-very-light-gray br2 ph3 pv3 white f6 mb2 w-100",
                            class:
                              _vm.$v.name.$error ||
                              (_vm.$v.name.$invalid && _vm.hasClickedNext)
                                ? "ba bw1 b--adori-red"
                                : "ba bw1 b--adori-transparent",
                            attrs: {
                              type: "text",
                              placeholder: "Enter audio name",
                            },
                            domProps: { value: _vm.$v.name.$model },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.$v.name,
                                  "$model",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                          }),
                          _vm.$v.name.$dirty && !_vm.hasClickedNext
                            ? _c(
                                "div",
                                {
                                  staticClass: "absolute",
                                  staticStyle: { bottom: "5px" },
                                },
                                [
                                  !_vm.$v.name.required
                                    ? _c(
                                        "div",
                                        { staticClass: "f6 adori-red" },
                                        [_vm._v("Cannot be empty.")]
                                      )
                                    : _vm._e(),
                                  !_vm.$v.name.maxLength
                                    ? _c(
                                        "div",
                                        { staticClass: "f6 adori-red" },
                                        [
                                          _vm._v(
                                            "Must be less than 255 characters."
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm.hasClickedNext
                            ? _c(
                                "div",
                                {
                                  staticClass: "absolute",
                                  staticStyle: { bottom: "5px" },
                                },
                                [
                                  _vm.$v.name.$invalid
                                    ? _c("div", [
                                        !_vm.$v.name.required
                                          ? _c(
                                              "div",
                                              { staticClass: "f6 adori-red" },
                                              [_vm._v("Cannot be empty.")]
                                            )
                                          : _vm._e(),
                                        !_vm.$v.name.maxLength
                                          ? _c(
                                              "div",
                                              { staticClass: "f6 adori-red" },
                                              [
                                                _vm._v(
                                                  "Must be less than 255 characters."
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                        ]),
                        _c(
                          "div",
                          { staticClass: "pb3 relative" },
                          [
                            _c("div", { staticClass: "flex justify-between" }, [
                              _c("div", { staticClass: "pb2 gray f6" }, [
                                _vm._v("Audio description"),
                              ]),
                            ]),
                            _c("HTMLEditor", {
                              staticClass:
                                "bg-adori-very-light-gray bw0 br2 ph3 pv1 white mb2 overflow-y-auto scrollbar",
                              class:
                                _vm.$v.description.$error ||
                                (_vm.$v.description.$invalid &&
                                  _vm.hasClickedNext)
                                  ? "ba bw1 b--adori-red"
                                  : "ba bw1 b--adori-transparent",
                              staticStyle: { width: "377px", height: "180px" },
                              attrs: {
                                placeholder: "Enter episode description",
                                initialContent: _vm.$v.description.$model,
                              },
                              on: { update: _vm.updateDescription },
                            }),
                            _vm.$v.description.$dirty && !_vm.hasClickedNext
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "absolute",
                                    staticStyle: { bottom: "5px" },
                                  },
                                  [
                                    !_vm.$v.description.required
                                      ? _c(
                                          "div",
                                          { staticClass: "f6 adori-red" },
                                          [_vm._v("Cannot be empty.")]
                                        )
                                      : _vm._e(),
                                    !_vm.$v.description.notEmptyHTML
                                      ? _c(
                                          "div",
                                          { staticClass: "f6 adori-red" },
                                          [_vm._v("Cannot be empty.")]
                                        )
                                      : _vm._e(),
                                    !_vm.$v.description.maxLength
                                      ? _c(
                                          "div",
                                          { staticClass: "f6 adori-red" },
                                          [
                                            _vm._v(
                                              "Your description is too long."
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                            _vm.hasClickedNext
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "absolute",
                                    staticStyle: { bottom: "5px" },
                                  },
                                  [
                                    !_vm.$v.description.required ||
                                    !_vm.$v.description.notEmptyHTML
                                      ? _c(
                                          "div",
                                          { staticClass: "f6 adori-red" },
                                          [
                                            _vm._v(
                                              "\n                    Cannot be empty\n                  "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    !_vm.$v.description.maxLength
                                      ? _c(
                                          "div",
                                          { staticClass: "f6 adori-red" },
                                          [
                                            _vm._v(
                                              "Your description is too long."
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "pb3 relative" }, [
                          _c("div", { staticClass: "flex justify-between" }, [
                            _c("div", { staticClass: "pb2 gray f6" }, [
                              _vm._v("Audio summary (optional)"),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "pb2 f6",
                                class: !_vm.$v.summary.maxLength
                                  ? "adori-red"
                                  : "gray",
                              },
                              [
                                _vm._v(
                                  "\n                    (" +
                                    _vm._s(_vm.$v.summary.$model.length) +
                                    " / 255)\n                  "
                                ),
                              ]
                            ),
                          ]),
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.$v.summary.$model,
                                expression: "$v.summary.$model",
                                modifiers: { trim: true },
                              },
                            ],
                            staticClass:
                              "db bg-adori-very-light-gray bw0 br2 ph3 pv3 gray f6 white mb2 overflow-y-auto scrollbar w-100",
                            class:
                              _vm.$v.summary.$error ||
                              (_vm.$v.summary.$invalid && _vm.hasClickedNext)
                                ? "ba bw1 b--adori-red"
                                : "ba bw1 b--adori-transparent",
                            attrs: {
                              rows: "1",
                              placeholder: "Enter audio summary",
                            },
                            domProps: { value: _vm.$v.summary.$model },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.$v.summary,
                                  "$model",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                          }),
                          _vm.$v.summary.$dirty && !_vm.hasClickedNext
                            ? _c(
                                "div",
                                {
                                  staticClass: "absolute",
                                  staticStyle: { bottom: "5px" },
                                },
                                [
                                  !_vm.$v.summary.maxLength
                                    ? _c(
                                        "div",
                                        { staticClass: "f6 adori-red" },
                                        [
                                          _vm._v(
                                            "Must be less than 255 characters."
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm.hasClickedNext
                            ? _c(
                                "div",
                                {
                                  staticClass: "absolute",
                                  staticStyle: { bottom: "5px" },
                                },
                                [
                                  _vm.$v.summary.$invalid
                                    ? _c("div", [
                                        !_vm.$v.summary.maxLength
                                          ? _c(
                                              "div",
                                              { staticClass: "f6 adori-red" },
                                              [
                                                _vm._v(
                                                  "Must be less than 255 characters."
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "pb3 scrollbar" }, [
                          _c("div", { staticClass: "flex justify-between" }, [
                            _c(
                              "div",
                              { staticClass: "pb2 gray f6" },
                              [
                                _vm._v(
                                  "\n                    Audio language\n                    "
                                ),
                                _c("BaseTooltip", {
                                  attrs: { info: "Language used in audio." },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "h3 scrollbar" }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectedLanguage,
                                    expression: "selectedLanguage",
                                  },
                                ],
                                staticClass:
                                  "input-reset bg-adori-very-light-gray light-gray bn br2 pa3 pr5 shadow-2 db arrow mb2 scrollbar",
                                staticStyle: { width: "378px" },
                                attrs: { disabled: true },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.selectedLanguage = $event.target
                                      .multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                },
                              },
                              _vm._l(_vm.languages || [], function (lang) {
                                return _c(
                                  "option",
                                  {
                                    key: lang.code,
                                    staticClass: "scrollbar",
                                    staticStyle: { width: "30px" },
                                    domProps: { value: lang.code },
                                  },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(lang.name) +
                                        "\n                    "
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "flex justify-end items-center" }, [
                  _c(
                    "div",
                    { staticClass: "flex justify-center items-center" },
                    [
                      _vm.isSubmitting
                        ? _c("div", { staticClass: "loader loader-inline mh3" })
                        : _vm._e(),
                      _c("BaseButtonRed", {
                        attrs: {
                          text: "Save track details",
                          onClick: _vm.handleEditAudioTrackSubmit,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ])
          : _c(
              "div",
              [
                _c("ImageGallery", {
                  attrs: { galleryParent: "PODCAST_EPISODE" },
                  on: {
                    showTag: _vm.showImageGallery,
                    handleSelectedImage: _vm.handleSelectedImage,
                  },
                }),
              ],
              1
            ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }