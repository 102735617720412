var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-100" }, [
    _c(
      "div",
      { staticClass: "w-100 h-100 ba b--adori-light-gray white relative" },
      [
        _c("div", { staticClass: "w-100 flex justify-around pointer" }, [
          _vm.showImportPodcast
            ? _c(
                "div",
                {
                  staticClass: "ba bl-0 bt-0 flex justify-center w-100 pa3",
                  class:
                    _vm.pane === "PODCAST"
                      ? "b--red bw2 br-0"
                      : "b--adori-light-gray br-1",
                  on: {
                    click: function ($event) {
                      return _vm.changePane("PODCAST")
                    },
                  },
                },
                [_vm._v("\n        IMPORT A PODCAST\n      ")]
              )
            : _vm._e(),
          !_vm.hideUploadAudio
            ? _c(
                "div",
                {
                  staticClass: "ba bt-0 flex justify-center w-100 pa3",
                  class:
                    _vm.pane === "AUDIO"
                      ? "b--red bw2 bl-0 br-0"
                      : "b--adori-light-gray",
                  on: {
                    click: function ($event) {
                      return _vm.changePane("AUDIO")
                    },
                  },
                },
                [_vm._v("\n        UPLOAD AUDIO/VIDEO\n      ")]
              )
            : _vm._e(),
        ]),
        _vm.pane === "PODCAST"
          ? _c("ImportRss", {
              attrs: { "is-studio-parent": _vm.isStudioParent },
            })
          : _vm._e(),
        _vm.pane === "AUDIO" ? _c("UploadAudio") : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }