























































































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import ButtonAudioAdorify from '@/components/Common/Buttons/ButtonAudioAdorify.vue'
import { toReadableDate } from '@/utils/time'
import { pluralize } from '@/utils/misc'
import { ADORI_API_BASE_URL, ADORI_WEBPLAYER_BASE_URL, ADORI_PODCASTS_BASE_URL } from '@/constants'
import moment from 'moment'
import ProgressBar from 'vuejs-progress-bar'
import YoutubeAudioComboBox from '@/components/Youtube/YoutubeAudioComboBox.vue'
import get from 'lodash.get'

// modals
import ShareModal from '@/components/Audio/Modals/Share.vue'

@Component({
  components: {
    ButtonAudioAdorify,
    ShareModal,
    ProgressBar,
    YoutubeAudioComboBox,
  },
})
export default class AudioTrack extends Vue {
  @Prop(String) audioUid!: string
  @Prop(Boolean) selectable!: boolean
  @Prop({ default: '' })
  sourceComponent!: string // 'EPISODE-CREATE'
  @Prop(String) selectionType!: string // One of 'SINGLE' or 'MULTI'
  @Prop(Boolean) editable!: boolean
  @Prop(Boolean) showEditButtons!: boolean
  @Prop(Boolean) showPreviewButton!: boolean
  @Prop(Boolean) showDownloadButton!: boolean
  @Prop(Boolean) hideUnadorified!: boolean
  @Prop(Boolean) hideUnpublished!: boolean
  @Prop(String) collectionType!: string
  @Prop(Boolean) fromYoutube!: boolean
  @Prop(String) rss!: string
  @Prop() videoClips!: any
  @Prop(String) feedUid!: string

  @Getter token!: any
  @Getter('audio') audioTrack!: any
  @Getter tagPositions!: any
  @Getter selectedRssFeedUid!: any
  @Getter rssFeedAudioTrack!: any
  @Getter rssFeedsByUid!: any
  @Getter defaultAudioCollection!: any
  @Getter audioCollectionSearchValue!: any
  @Getter audioUidsWithExcludedFeed!: any
  @Getter showLoader!: any
  @Getter publicFeedEpisodes!: any

  @Action getAudio!: any
  @Action showAudioTrackEditor!: any
  @Action showAudioTrackPlayer!: any
  @Action showEditPodcastEpisode!: any
  @Action removeRssFeedItem!: any
  @Action showIframeEmbed!: any
  @Action deleteAudio!: any
  @Action closeModal!: any
  @Action publishRssFeed!: any
  @Action setShowLoader!: any
  @Action clearAllRssFeedItemsByFeedUid!: any
  @Action updateRssFeedItems!: any
  @Action getAudioCollectionTracks!: any
  @Action getAudioUids!: any
  @Action deleteAudioUid!: any
  @Action showReplaceAudio!: any
  @Action getPublicFeedEpisodes!: any
  @Action getAudioTags!: any
  @Action pushNotification!: any

  orginal = 'ORIGINAL'

  pluralize = pluralize

  shareAudio = false
  isComboBoxActive = false

  async created() {
    if (!this.audio) {
      try {
        await this.getAudio(this.audioUid)
      } catch (error) {}
    }
  }

  get allVideoClipsExceptFirst() {
    if (this.collectionType == 'ALL_AUDIOS') {
      return get(this.audio, 'videoClips', []).length && this.audio.videoClips.slice(1)
    } else return this.videoClips.slice(1)
  }

  get isVideoPodcast() {
    return !!this.audio.videoUrl
  }

  get firstClip() {
    if (this.collectionType == 'ALL_AUDIOS') {
      return get(this.audio, 'videoClips', []).length && this.audio.videoClips[0]
    } else return this.videoClips[0]
  }
  get showComboBox() {
    if (this.collectionType == 'ALL_AUDIOS') {
      return get(this.audio, 'videoClips', []).length > 1 ? true : false
    } else {
      return this.videoClips && this.videoClips.length > 1 ? true : false
    }
  }

  get clipsCount() {
    if (this.collectionType == 'ALL_AUDIOS') {
      return get(this.audio, 'videoClips', []).length == 1 || get(this.audio, 'videoClips', []).length == 0
        ? get(this.audio, 'videoClips', []).length + ' video clip'
        : get(this.audio, 'videoClips', []).length + ' video clips'
    } else {
      return this.videoClips.length == 1 || this.videoClips.length == 0
        ? this.videoClips.length + ' video clip'
        : this.videoClips.length + ' video clips'
    }
  }

  get audio() {
    if (this.sourceComponent && this.sourceComponent === 'EPISODE-CREATE') {
      return this.audioUidsWithExcludedFeed.data.filter((data: any) => data.uid === this.audioUid)[0]
    }
    return this.audioTrack(this.audioUid)
  }

  get audioPreviewImage() {
    return this.audio && this.audio.imageInfo ? this.audio.imageInfo.thumbnailURL : null
  }

  get audioDuration() {
    if (this.audio.durationMillis) {
      const seconds = Math.ceil(this.audio.durationMillis / 1000)
      const hrs = Math.floor(seconds / 3600)
      const min = Math.floor((seconds % 3600) / 60)
      const sec = Math.floor(seconds % 60)
      return (hrs ? hrs + 'h ' : '') + (min ? min + 'm ' : '') + (sec ? sec + 's' : '')
    }
    return 'Calculating...'
  }

  get audioDate() {
    if (this.hasSelectedPodcast) {
      if (this.audio.publishedOn) {
        return toReadableDate(this.audio.publishedOn)
      }
    }

    if (this.audio.publishTime) {
      return toReadableDate(this.audio.publishTime)
    }
    return toReadableDate(this.audio.createdOn)
  }

  get audioIsPublic() {
    return this.audio.public
  }

  async mounted() {
    await this.getAudioTags(this.audioUid)
  }
  get tagLength() {
    return this.tagPositions(this.audioUid) ? this.tagPositions(this.audioUid).length : false
  }

  get audiogramLength() {
    return this.$store.getters.audiogramPositions(this.audioUid)
      ? this.$store.getters.audiogramPositions(this.audioUid).length
      : false
  }

  get totalVisualElements() {
    return this.tagLength + this.audiogramLength
  }

  get audioTags() {
    return this.audio ? this.audio.tagCount : 0
  }

  get audioTagsText() {
    if (!this.totalVisualElements) return 'No tags added'
    return `${this.totalVisualElements} ${pluralize(this.totalVisualElements, 'tag', 'tags')} added`
  }

  get audioDescription() {
    return this.audio.description
  }

  get selectionIcon() {
    return this.$store.getters.isAudioSelected(this.audioUid) ? 'check_circle' : 'radio_button_unchecked'
  }

  get selectionStyle() {
    return this.$store.getters.isAudioSelected(this.audioUid) ? 'adori-red' : 'o-20'
  }

  get podcastsWithAudio() {
    // This key is misnamed and will be changed
    // later in the backend.
    if (this.sourceComponent && this.sourceComponent === 'PODCASTS-TAB') {
      return [this.rssFeedsByUid[this.selectedRssFeedUid]]
    }
    return (this.audio && this.audio.audioCollections) || []
  }

  get audioTrackMenu() {
    const trackMenu = [
      {
        name: 'Create Video',
        icon: 'ondemand_video',
        onClick: () => this.goToOnboarding(),
      },
      {
        name: 'Delete track',
        icon: 'delete',
        onClick: () => this.handleDeleteAudio(),
      },
      {
        name: 'Embed on web',
        icon: 'stay_primary_portrait',
        onClick: () => this.handleEmbedWeb(),
      },
      {
        name: 'Replace audio',
        icon: 'swap_vert',
        onClick: () => this.handleReplaceAudio(),
      },
    ]

    if (this.$permissions.isEditEpisodeShowAllowed(this.selectedRssFeedUid)) {
      trackMenu.splice(1, 0, {
        name: 'Edit Episode',
        icon: 'edit',
        onClick: () => this.handleEditClick(),
      })
    }
    return trackMenu
  }

  get podcastEpisodeMenu() {
    const episodeMenu: any = []
    this.$permissions.isYoutubeConvertVideoShowEpisodeAllowed(this.selectedRssFeedUid) &&
      episodeMenu.push({
        name: 'Create Video',
        icon: 'ondemand_video',
        onClick: () => this.goToOnboarding(),
      })
    this.$permissions.isDeleteEpisodeShowAllowed(this.selectedRssFeedUid) &&
      episodeMenu.push(
        {
          name: this.collectionType === 'ALL_AUDIOS' ? 'Delete track' : 'Remove episode',
          icon: this.collectionType === 'ALL_AUDIOS' ? 'delete' : 'clear',
          onClick: () => this.handleRemoveEpisode(),
        },
        {
          name: 'Embed on web',
          icon: 'stay_primary_portrait',
          onClick: () => this.handleEmbedWeb(),
        },
        {
          name: 'Replace audio',
          icon: 'swap_vert',
          onClick: () => this.handleReplaceAudio(),
        },
        {
          name: 'Share Audio',
          icon: 'share',
          onClick: () => this.handleShareAudio(),
        },
        {
          name: 'Download Original Audio',
          icon: 'verified_user',
          onClick: () => (window.location.href = this.audioDownloadURL(this.orginal)),
        },
        {
          name: 'Download Audio',
          icon: 'cloud_download',
          onClick: () => (window.location.href = this.audioDownloadURL()),
        }
      )

    if (this.$permissions.isEditEpisodeShowAllowed(this.selectedRssFeedUid)) {
      episodeMenu.splice(1, 0, {
        name: 'Edit Episode',
        icon: 'edit',
        onClick: () => this.handleEditClick(),
      })
    }
    return episodeMenu
  }

  get showAudioProcessingWarning() {
    return (
      this.audio &&
      this.audio.adorificationStatus &&
      (this.audio.adorificationStatus === 'STARTED' || this.audio.adorificationStatus === 'QUEUED')
    )
  }

  async goToOnboarding() {
    const uid = this.$route.params.id
    if (!uid) {
      const selectedEpisode: any = {
        audioUrl: this.audio.audioUrl,
        name: this.audio.name,
        description: this.audio.description,
        explicitContent: this.audio.explicitContent,
        guid: this.audio.guid,
        imageId: this.audio.imageId,
        imageUrl: this.audio.imageUrl,
        isRss: false,
        keywords: this.audio.keywords,
        durationSeconds: this.audio.durationMillis / 1000,
        isReUpload: true,
      }

      this.$store.commit('unselectAllEpisodeForUpload')
      this.$store.commit('clearYoutubeState')
      this.$store.commit('resetYoutubeSetting')
      this.$store.commit('selectEpisodeForUpload', selectedEpisode)
      this.$store.commit('setYoutubeStep', 2)
      // @ts-ignore
      sessionStorage.setItem('upload', 'YES')
      this.$router.push('/onboarding')
    } else {
      this.$store.commit('setCreatedYoutubeFeed', { uid: this.feedUid })
      await this.getPublicFeedEpisodes({
        url: this.rss,
      })
      sessionStorage.setItem('feedUid', this.feedUid)
      sessionStorage.setItem('feedUrl', this.rss)
      const selectedEpisode = this.publicFeedEpisodes.filter((obj: any) => obj.guid === this.audio.guid)[0]
      if (!selectedEpisode) {
        this.pushNotification({
          text: 'Please try again later',
          type: 'WARNING',
        })
        return
      }
      this.$store.commit('unselectAllEpisodeForUpload')
      this.$store.commit('clearYoutubeState')
      this.$store.commit('resetYoutubeSetting')
      this.$store.commit('selectEpisodeForUpload', selectedEpisode)
      this.$store.commit('setYoutubeStep', 2)

      // @ts-ignore
      sessionStorage.setItem('upload', 'YES')
      this.$router.push('/onboarding')
    }
  }

  handleReplaceAudio() {
    this.showReplaceAudio(this.audioUid)
  }

  handleShareAudio() {
    this.shareAudio = true
  }

  closeShare() {
    this.shareAudio = false
  }

  handleEmbedWeb() {
    if (this.showAudioProcessingWarning) {
      this.$store.dispatch('showConfirm', {
        title: 'Warning: Unprocessed Audio',
        description:
          'This audio is being processed. The embedded player will be able to play the audio only after the processing completes.',
        yesButtonText: 'View embed code',
        onConfirm: async () => {
          this.showIframeEmbed(this.audioUid)
        },
      })
    } else {
      this.showIframeEmbed(this.audioUid)
    }
  }

  podcastEpisode(podcastUid: string) {
    return this.rssFeedAudioTrack(podcastUid, this.audioUid)
  }

  get hasSelectedPodcast() {
    for (let index = 0; index < this.podcastsWithAudio.length; index++) {
      if (this.podcastEpisode(this.podcastsWithAudio[index].uid)) {
        return true
      }
    }
    return false
  }

  truncate(str: string, count: number) {
    if (str.length < count) return str
    return str.substring(0, count) + '...'
  }

  handleAudioClicked() {
    if (this.editable) {
      this.handleEditAudio()
    } else if (this.selectable) {
      this.handleAudioSelectionIconClicked()
    } else {
      //   this.handleEditEpisodeDetails()
    }
  }

  handleEditClick() {
    if (this.collectionType === 'ALL_AUDIOS') {
      this.handleEditAudioDetails()
    } else {
      this.handleEditEpisodeDetails()
    }
    // if (this.audio.audioCollections && this.audio.audioCollections.length === 0) {
    //   this.handleEditAudioDetails()
    // } else if (this.audio.audioCollections) {
    //   this.handleEditEpisodeDetails()
    // }
  }

  handleAudioSelectionIconClicked() {
    if (this.selectable) {
      if (this.selectionType === 'SINGLE') {
        this.$store.dispatch('unselectAllAudios')
        this.$store.dispatch('toggleSelectAudio', this.audioUid)
      }
      if (this.selectionType === 'MULTI') {
        this.$store.dispatch('togleSelectAudio', this.audioUid)
      }
    }
  }

  handleAudioThumbnailClicked() {
    if (this.editable) {
      this.handleEditAudioDetails()
    }
  }

  // Buttons

  handlePlayAudio() {
    this.showAudioTrackPlayer(this.audioUid)
  }

  handleEditAudio() {
    this.$router.push('/audio-editor?uid=' + this.audioUid)
  }

  handleEditAudioDetails() {
    this.showAudioTrackEditor(this.audio)
  }

  handleDeleteAudio() {
    if (this.podcastsWithAudio.length === 0) {
      this.$store.dispatch('showConfirm', {
        title: 'Delete audio track?',
        description: 'You are about to permanently delete this audio track. Are you sure?',
        yesButtonText: 'Yes, delete this audio track',
        noButtonText: 'No, keep this audio track',
        onConfirm: async () => {
          this.setShowLoader(true)
          try {
            await this.deleteAudio(this.audioUid)
          } catch (err) {
            this.setShowLoader(false)
          }
          this.closeModal()
          this.setShowLoader(false)
        },
      })
    } else {
      this.$store.dispatch('showConfirm', {
        title: 'Cannot delete audio track',
        description:
          'You cannot delete this audiotrack as it is part of one or move active podcasts. Please remove it from any podcasts before deleting the audio.',
        yesButtonText: 'Okay, got it',
        onConfirm: () => {
          this.closeModal()
        },
      })
    }
  }

  handleEditEpisodeDetails() {
    this.showEditPodcastEpisode(this.audioUid)
  }

  handleRemoveEpisode() {
    if (this.collectionType === 'ALL_AUDIOS') {
      this.$store.dispatch('showConfirm', {
        title: 'Delete track or remove episode?',
        description: `You are about to remove this episode from following podcasts:<br /><br />${this.podcastsWithAudio
          .map((podcast: any) => podcast.name)
          .join(`<br />`)}<br /><br />Do you want to delete the audio track as well?`,
        yesButtonText: 'Yes, remove episode and delete audio',
        noButtonText: 'No, just remove episode',
        onConfirm: async () => {
          this.setShowLoader(true)
          try {
            for (let index = 0; index < this.podcastsWithAudio.length; index++) {
              await this.removeRssFeedItem({
                rssFeedUid: this.podcastsWithAudio[index].uid,
                rssFeedItemUid: this.audioUid,
                deleteTrack: true,
              })
              await this.publishRssFeed({
                rssFeedUid: this.podcastsWithAudio[index].uid,
              })
            }
            await this.deleteAudioUid(this.audioUid)
            // await this.deleteAudio(this.audioUid)
            // await this.updateAudioUids()
            // await this.getAudioUids({
            //   paginate: true
            // })
          } catch (err) {
            this.setShowLoader(false)
          }
          this.closeModal()
          this.setShowLoader(false)
        },
        onCancel: async () => {
          this.setShowLoader(true)
          try {
            for (let index = 0; index < this.podcastsWithAudio.length; index++) {
              await this.removeRssFeedItem({
                rssFeedUid: this.podcastsWithAudio[index].uid,
                rssFeedItemUid: this.audioUid,
              })
              await this.publishRssFeed({
                rssFeedUid: this.podcastsWithAudio[index].uid,
              })
            }
            await this.getAudio(this.audioUid)
          } catch (err) {
            this.setShowLoader(false)
          }
          this.closeModal()
          this.setShowLoader(false)
        },
      })
    } else {
      if (this.podcastsWithAudio && this.podcastsWithAudio.length > 1) {
        this.$store.dispatch('showConfirm', {
          title: 'Remove episode?',
          description: `Since this episode is part of multiple podcasts you can only remove the episode from this podcast.<br /><br />If you want to permanently delete the audio track please go to all audio tracks section.`,
          yesButtonText: 'Yes, remove episode',
          noButtonText: 'No, keep episode',
          onConfirm: async () => {
            this.setShowLoader(true)
            try {
              await this.removeRssFeedItem({
                rssFeedUid: this.selectedRssFeedUid,
                rssFeedItemUid: this.audioUid,
              })
              await this.publishRssFeed({
                rssFeedUid: this.selectedRssFeedUid,
              })
              // await this.getAudio(this.audioUid)
              await this.clearAllRssFeedItemsByFeedUid()
              await this.updateRssFeedItems({
                rssFeedUid: this.selectedRssFeedUid,
              })
            } catch (err) {
              this.setShowLoader(false)
            }
            this.closeModal()
            this.setShowLoader(false)
          },
          onCancel: () => {
            this.closeModal()
          },
        })
      } else if (this.podcastsWithAudio && this.podcastsWithAudio.length === 1) {
        this.$store.dispatch('showConfirm', {
          title: 'Remove episode or delete track?',
          description: `You are about to remove the episode from this podcast.<br /><br />Do you want to delete the audio track as well?`,
          yesButtonText: 'Yes, remove episode and delete audio',
          noButtonText: 'No, just remove episode',
          onConfirm: async () => {
            this.setShowLoader(true)
            try {
              await this.removeRssFeedItem({
                rssFeedUid: this.selectedRssFeedUid,
                rssFeedItemUid: this.audioUid,
                deleteTrack: true,
              })
              await this.publishRssFeed({
                rssFeedUid: this.selectedRssFeedUid,
              })
              await this.clearAllRssFeedItemsByFeedUid()
              await this.updateRssFeedItems({
                rssFeedUid: this.selectedRssFeedUid,
              })
              await this.deleteAudioUid(this.audioUid)
            } catch (err) {
              this.setShowLoader(false)
            }
            this.closeModal()
            this.setShowLoader(false)
          },
          onCancel: async () => {
            this.setShowLoader(true)
            try {
              await this.removeRssFeedItem({
                rssFeedUid: this.selectedRssFeedUid,
                rssFeedItemUid: this.audioUid,
              })
              await this.publishRssFeed({
                rssFeedUid: this.selectedRssFeedUid,
              })
              await this.getAudio(this.audioUid)
              // await this.updateAudioUids()
              await this.getAudioUids({
                paginate: true,
              })
            } catch (err) {
              this.setShowLoader(false)
            }
            this.closeModal()
            this.setShowLoader(false)
          },
        })
      }
    }
  }

  audioDownloadURL(type?: string) {
    const networkId = this.$store.getters.networkId

    return type === this.orginal
      ? `${ADORI_API_BASE_URL}/networks/${networkId}/audiotracks/${this.audioUid}/audio/download?api_token=${
          this.token
        }&original=${true}`
      : `${ADORI_API_BASE_URL}/networks/${networkId}/audiotracks/${this.audioUid}/audio/download?api_token=${this.token}`
  }

  handleIframeEmbed() {
    this.showIframeEmbed(this.audio.uid)
  }

  get isAudioVisible() {
    if (!this.audio) return true
    return (
      (!this.hideUnadorified || this.audio.adorificationStatus === 'FINISHED') &&
      (!this.hideUnpublished || this.audio.public)
    )
  }

  get audioIsAdorified() {
    if (!this.audio) return false
    return this.audio && this.audio.adorificationStatus === 'FINISHED'
  }

  get audioIsGettingReplaced() {
    if (!this.audio) return false
    return (
      this.audio.replaceTask &&
      this.audio.replaceTask.status !== 'FINISHED' &&
      this.audio.replaceTask.status !== 'FAILED'
    )
  }

  slugify(str: string) {
    return (
      str
        // Trim leading and trailing white space.
        .trim()
        // Turn everthing to lower case.
        .toLowerCase()
        // Remove non-alphanumeric or non-space characters.
        .replace(/[^a-z0-9 ]/g, '')
        // Replace spaces with hyphens.
        .replace(/ /g, '-')
    )
  }

  get portraitExperienceSrc() {
    if (!this.audio) return `#`
    return `${ADORI_PODCASTS_BASE_URL}/e/${this.slugify(this.audio.name)}?eid=${this.audio.uid}`
  }

  get landscapeExperienceSrc() {
    if (!this.audio) return `#`
    return `${ADORI_WEBPLAYER_BASE_URL}/e/${this.audio.uid}`
  }

  get formatedPublishedOrScheduled() {
    if (this.podcastEpisode(this.selectedRssFeedUid) && this.podcastEpisode(this.selectedRssFeedUid).isScheduled) {
      return (
        'Scheduled for: ' +
        moment(this.podcastEpisode(this.selectedRssFeedUid).scheduleTime).format('MMMM Do, YYYY, h:mm a')
      )
    } else if (
      this.podcastEpisode(this.selectedRssFeedUid) &&
      this.podcastEpisode(this.selectedRssFeedUid).publishedOn
    ) {
      return (
        'Published on: ' +
        moment(this.podcastEpisode(this.selectedRssFeedUid).publishedOn).format('MMMM Do, YYYY, h:mm a')
      )
    }
    return null
  }

  openEpisodeWebsite() {
    window.open(this.portraitExperienceSrc, '_blank')
  }
}
