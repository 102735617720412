import Vue from 'vue'
import Component from 'vue-class-component'
import { useQueryClient, QueryClient } from 'vue-query'

@Component({
  setup() {
    const queryClient = useQueryClient()
    return {
      queryClient,
    }
  },
})
export default class Invalidate extends Vue {
  queryClient!: QueryClient
}
