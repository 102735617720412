var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      attrs: { id: "wave-container" },
      on: {
        mouseenter: function ($event) {
          _vm.activeHover = true
        },
        mouseleave: function ($event) {
          _vm.activeHover = false
        },
      },
    },
    [
      _c("img", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.activeHover,
            expression: "!activeHover",
          },
        ],
        attrs: { src: _vm.img, width: "100%" },
        on: { click: _vm.loadWaveforms },
      }),
      _c(
        "video",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeHover,
              expression: "activeHover",
            },
          ],
          ref: "videoRef",
          attrs: { width: "100%", autoplay: "", loop: "" },
          on: { click: _vm.loadWaveforms },
        },
        [
          _c("source", { attrs: { src: _vm.video1, type: "video/webm" } }),
          _c("source", {
            attrs: { src: _vm.video2, type: 'video/mp4; codecs="hvc1"' },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }