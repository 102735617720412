import get from 'lodash.get'
import Vue from 'vue'
import { StoreOptions, GetterTree, MutationTree, ActionTree } from 'vuex'
import AdoriService from '@/services/adori'

const state: any = {
  isLoading: false,
  imageSearchResult: {},
}

const initialState: any = {
  ...JSON.parse(JSON.stringify(state)),
}

const getters: GetterTree<any, any> = {
  imageSearchResult: (state) => state.imageSearchResult,
  isLoadingUserImages: (state) => state.isLoading,
}

const mutations: MutationTree<any> = {
  resetState(state) {
    Object.keys(state).forEach((key: any) => {
      Vue.set(state, key, initialState[key])
    })
  },
  setSearchImages(state, { searchLibrary, images }) {
    const currentLibSource = get(state.imageSearchResult, searchLibrary)
    if (currentLibSource && currentLibSource.data.length !== 0 && images.offset !== 0) {
      const data = [...currentLibSource.data, ...images.data]
      const imagesObj = {
        count: images.count,
        offset: images.offset,
        data: data,
        limit: images.limit,
      }
      Vue.set(state.imageSearchResult, searchLibrary, imagesObj)
    } else {
      Vue.set(state.imageSearchResult, searchLibrary, images)
    }
  },

  clearSearchImages(state, searchLibrary) {
    Vue.delete(state.imageSearchResult, searchLibrary)
  },

  setIsLoading(state, isLoading: boolean) {
    Vue.set(state, 'isLoading', isLoading)
  },
}

const actions: ActionTree<any, any> = {
  resetImagesState(context) {
    context.commit('resetState')
  },

  async getImages(context, { limit = 10, offset = 0 }) {
    const networkId = context.getters.networkId
    context.commit('setIsLoading', true)
    const images: any = await AdoriService.fetchImages(networkId, limit, offset)
    context.commit('setIsLoading', false)
    context.commit('setSearchImages', { searchLibrary: 'my_library', images })
  },

  async getSearchImages(
    context,
    { query = '', limit = 10, offset = 0, galleryParent = '', searchLibrary = 'unsplash' }
  ) {
    const networkId = context.getters.networkId
    const params: any = {
      query,
      limit,
      offset,
    }
    galleryParent === 'PODCAST_SHOW' && searchLibrary === 'unsplash' && (params['orientation'] = 'squarish')
    const images: any = await AdoriService.searchImages(networkId, searchLibrary, params)

    context.commit('setSearchImages', { searchLibrary, images })
  },

  async downloadUnsplash(context, params: any) {
    const networkId = context.getters.networkId
    const url = await AdoriService.downloadUnsplashImage(networkId, params).then((res: any) => res.url)
    return url
  },

  async setIsLoadingUserImages(context, value: boolean) {
    context.commit('setIsLoading', value)
  },

  async uploadImage(context, setItem: string) {
    const networkId = context.getters.networkId
    let payload = context.getters.selectedFile
    if (setItem === 'setTagImageId' && context.getters.tagEditImageBlob !== '') {
      payload = context.getters.tagEditImageBlob
    }
    if (context.getters.tagEditImage.startsWith('https://')) {
      payload = {
        url: context.getters.tagEditImage,
      }
    }
    const imageId = await AdoriService.uploadImage(networkId, payload).then((res: any) => res.id)
    context.commit(setItem, imageId)
  },
}

const images: StoreOptions<any> = {
  state,
  getters,
  mutations,
  actions,
}

export default images
