export const NETWORKROLES = {
  owner: 'Network Owner',
  admin: 'Network Admin',
  tagArtist: 'Network Tag Artist',
  producer: 'Network Producer',
  monitor: 'Network Monitor',
}

export const SHOWROLES = {
  admin: 'Show Admin',
  manage: 'Show Manager',
  tagArtist: 'Show Tag Artist',
  monitor: 'Show Monitor',
}
