











































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Mixins, Watch } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import AudioTrack from '@/components/Audio/AudioTrack.vue'
import HTMLEditor from '@/components/Common/HTMLEditor.vue'
import { validationMixin } from 'vuelidate'
import { required, maxLength, url, email, numeric } from 'vuelidate/lib/validators'
import ImagePreviewer from '@/mixins/ImagePreviewer'
import LazyLoader from '@/mixins/LazyLoader'
import AdoriService, { AdoriAPI } from '@/services/adori'
import ImageGallery from '@/components/Common/Images/ImageGallery.vue'
import moment from 'moment'
import debounce from 'lodash.debounce'

import { removeStyles } from '@/utils/misc'
import { uploadStatus } from '../Publish/publish'
import Invalidate from '@/mixins/Invalidate'
import { audioQueryKeys } from '@/hooks/audio'
import VoiceSelection from '../Common/VoiceSelection.vue'

const validations = {
  selectedEpisode: {
    name: {
      required,
      maxLength: maxLength(255),
    },
    description: {
      required,
      maxLength: maxLength(65535),
      notEmptyHTML: (value: string) => value !== '<p><br></p>',
    },
    summary: {
      required,
      maxLength: maxLength(255),
    },
    episodeType: {
      required,
    },
    season: {
      numeric,
    },
    episodeNumber: {
      numeric,
    },
    explicitContent: {
      required,
    },
    keywords: {
      maxLength: maxLength(255),
    },
    scheduleTime: {},
  },
}

@Component({
  // @ts-ignore
  validations,
  components: { AudioTrack, HTMLEditor, ImageGallery, VoiceSelection },
})
export default class ModalPodcastEpisodeAdd extends Mixins(validationMixin, ImagePreviewer, LazyLoader, Invalidate) {
  @Getter userId!: any
  @Getter networkId!: any
  @Getter defaultAudioCollection!: any
  @Getter selectedAudios!: any
  @Getter selectedAudioWithExcludedFeed!: any
  @Getter audioUids!: any
  @Getter audioUidsLazyLoader!: any
  @Getter audio!: any
  @Getter rssFeedAudioTrackUids!: any
  @Getter selectedFile!: any
  @Getter audioCollectionSearchValue!: any
  @Getter audioUidsWithExcludedFeed!: any
  @Getter rssFeed!: any
  @Getter languages!: any

  @Action unselectAllAudios!: any
  @Action clearAudioUploader!: any
  @Action setSelectedAudioCollectionId!: any
  @Action updateAudioTrack!: any
  @Action getRssFeedItem!: any
  @Action addRssFeedItem!: any
  @Action closeModal!: any
  @Action publishRssFeed!: any
  @Action clearSelectedFile!: any
  @Action updateRssFeedItems!: any
  @Action getAudioUids!: any
  @Action clearAllRssFeedItemsByFeedUid!: any
  @Action getAudioCollectionTracks!: any
  @Action getAudioUidsWithExcludedFeed!: any
  @Action clearAudioUidsWithExcludedFeed!: any
  @Action getLanguages!: any

  $v: any
  hasClickedNext: boolean = false
  hasClickedImage: boolean = false

  selectedTab = 'BASIC'

  fileType = 'USING_AUDIO' // One of "USING_TEXT" OR "USING_AUDIO"
  blogURL = ''
  selectedTextFile: any = null
  voiceType: string = 'standard'
  voiceGender: string = 'FEMALE'

  voiceTypeList = [
    { title: 'Standard', value: 'standard' },
    { title: 'Wavenet', value: 'wavenet' },
  ]

  voiceGenderList = [
    { title: 'Female', value: 'FEMALE' },
    { title: 'Male', value: 'MALE' },
    { title: 'Neutral', value: 'NEUTRAL' },
  ]

  currentStep: number = 1
  audioSource = 'UPLOAD_NEW' // One of 'UPLOAD_NEW' or 'SELECT_EXISTING'
  selectedEpisode: any = null
  selectedEpisodeImageData: string = ''
  isSubmitting: boolean = false
  errorMessage: string = ''

  showAdvancedOptions: boolean = true
  shouldAutoTagAudio: boolean = false

  selectedPodcastUid: string = this.$store.state.modal.podcastEditor.podcastUid

  episodeTypes = [
    { text: 'Regular', value: 'REGULAR' },
    { text: 'Trailer', value: 'TRAILER' },
    { text: 'Bonus', value: 'BONUS' },
  ]

  selectedLanguage: string = 'en-US'
  languageDisabled: boolean = false

  today: Date = new Date()

  belongsToSeason: boolean = true
  hasEpisodeNumber: boolean = true
  episodePublishType: string = 'PUBLISH_LATER' // One of 'PUBLISH_NOW' or 'PUBLISH_LATER'

  enableImageGallery: boolean = false
  selectedImageSrc: string = ''
  selectedImageId: string = ''
  selectedAudioName: string = ''

  audioSearchValue: string = ''
  isSearchingAudio: boolean = false
  isGettingData: boolean = false

  suggestedDate: any = ''

  clearSearch() {
    this.audioSearchValue = ''
    this.onAudioSearchValueChanged()
  }

  async created() {
    await this.getAudioUidsWithExcludedFeed({
      excludeFeedUid: this.selectedPodcastUid,
    })

    if (!this.languages) await this.getLanguages()

    // if (this.rssFeedAudioTrackUids(this.selectedPodcastUid).length === 0 && this.selectedPodcastUid) {
    //   await this.updateRssFeedItems({ rssFeedUid: this.selectedPodcastUid })
    // }
    // if (this.audioUids.length === 0) {
    //   await this.getAudioUids({
    //     paginate: true
    //   })
    // }
    this.unselectAllAudios()
    this.clearAudioUploader()
    const days = 7
    const date = new Date()
    const last = new Date(date.getTime() + days * 24 * 60 * 60 * 1000)
    this.suggestedDate = last
    // this.suggestedDate = moment(last).format('MMMM Do, YYYY, h:mm a')
  }

  get formatedSuggestedDate() {
    return moment(this.suggestedDate).format('MMMM Do, YYYY, h:mm a')
  }

  // @Watch('audioSearchValue')
  async onAudioSearchValueChanged() {
    this.isSearchingAudio = true
    this.setItemsLeft(true)
    this.resetIterations()
    await this.getAudioUidsWithExcludedFeed({
      excludeFeedUid: this.selectedPodcastUid,
      paginate: true,
      limit: 70,
      query: this.audioSearchValue,
      clear: true,
    })
    this.isSearchingAudio = false
  }

  debouncedSearch = debounce(() => {
    this.onAudioSearchValueChanged()
  }, 600)

  handleSuggestedDate() {
    this.$v.selectedEpisode.scheduleTime.$model = this.suggestedDate
  }

  beforeDestroy() {
    this.unselectAllAudios()
    this.clearAudioUploader()
    this.clearSelectedFile()
    this.clearAudioUidsWithExcludedFeed()
    this.audioSearchValue = ''
    // if (this.audioSearchValue) {
    //   this.audioSearchValue = ''
    //   this.getAudioUids({
    //     paginate: true
    //   })
    // }
    this.suggestedDate = ''
  }

  get allAudioUids() {
    this.isGettingData = true
    let audioUidsFinal: any = []
    if (this.audioUidsWithExcludedFeed && this.audioUidsWithExcludedFeed.data) {
      this.audioUidsWithExcludedFeed.data.forEach((audio: any) => {
        audioUidsFinal.push(audio.uid)
      })
    }
    this.isGettingData = false
    return audioUidsFinal
  }

  get paginatedAudioUids() {
    return this.getItemsLazy(() => this.audioUidsWithExcludedFeed.data).map((audio: any) => audio.uid)
  }

  async hasItemsLeftLoad() {
    if (!this.isLoading()) {
      await this.handleLoadMore(() => this.audioUidsWithExcludedFeed, this.getAudioUidsWithExcludedFeed, {
        excludeFeedUid: this.selectedPodcastUid,
        query: this.audioSearchValue,
        limit: 70,
        offset: (this.audioUidsWithExcludedFeed && this.audioUidsWithExcludedFeed.data.length) || 0,
      })
    }
  }

  get hasAudioUploadId() {
    return !!this.$store.getters.audioUploadId
  }

  get isUploadingAudio() {
    return this.hasAudioUploadId && this.$store.getters.audioUploadProgress !== 1
  }

  get uploadProgress() {
    return Math.round(this.$store.getters.audioUploadProgress * 100)
  }

  get selectedAudio() {
    return this.selectedAudioWithExcludedFeed
  }

  get hasCompletedStep1() {
    return (
      (this.fileType === 'USING_TEXT' && this.selectedTextFile !== null) ||
      (this.audioSource === 'UPLOAD_NEW' && this.uploadProgress === 100) ||
      (this.audioSource === 'SELECT_EXISTING' && this.selectedAudio !== undefined && this.selectedAudioWithExcludedFeed)
    )
  }

  get hasCompletedStep2() {
    return (
      !this.$v.selectedEpisode.name.$invalid &&
      !this.$v.selectedEpisode.description.$invalid &&
      !this.$v.selectedEpisode.summary.$invalid &&
      this.hasPreviewImage
    )
  }

  get hasPreviewImage() {
    return this.selectedImageSrc
  }

  showImageGallery() {
    if (this.enableImageGallery && !this.selectedImageSrc) {
      this.selectedImageSrc = ''
    }
    this.hasClickedImage = true
    this.enableImageGallery = !this.enableImageGallery
  }

  async handleSelectedImage(imgData: any) {
    this.hasClickedImage = true
    if (imgData.urls.full && imgData.urls.full.includes('adorilabs')) {
      this.selectedImageSrc = imgData.urls.full
      this.selectedImageId = imgData.id
    } else if (imgData.urls.full && imgData.urls.full.startsWith('https://')) {
      this.selectedImageSrc = imgData.urls.full
    } else if (this.previewImage !== '/img/Web link.c650ed21.jpg') {
      this.selectedImageSrc = this.previewImage
    } else {
      this.selectedImageSrc = '/img/Web link.c650ed21.jpg'
    }
    this.enableImageGallery = !this.enableImageGallery
  }

  @Watch('selectedPodcastUid')
  async onSelectedPodcastUidChange() {
    if (this.selectedPodcastUid && this.rssFeedAudioTrackUids(this.selectedPodcastUid).length === 0) {
      await this.updateRssFeedItems({ rssFeedUid: this.selectedPodcastUid })
    }
  }

  get selectedFileName() {
    return this.selectedTextFile && this.selectedTextFile.name
  }

  get selectedFileSize() {
    if (this.selectedTextFile) {
      let size = this.selectedTextFile.size
      let fSExt = ['Bytes', 'KB', 'MB', 'GB']
      let i = 0
      while (size > 900) {
        size /= 1024
        i++
      }
      return Math.round(size * 100) / 100 + ' ' + fSExt[i]
    }
  }

  @Watch('hasCompletedStep1')
  handleHasCompletedStep1(e: any) {
    if (this.hasCompletedStep1) {
      if (this.fileType === 'USING_AUDIO') {
        if (this.audioSource === 'UPLOAD_NEW') {
          this.selectedEpisode = {
            name: '',
            description: '',
            imageId: '',
          }
          this.languageDisabled = false
          this.selectedImageSrc = ''
        }

        if (this.audioSource === 'SELECT_EXISTING') {
          if (this.selectedAudioWithExcludedFeed) {
            this.selectedEpisode = {
              ...this.selectedAudioWithExcludedFeed,
            }
            this.selectedLanguage = this.selectedEpisode.language || 'en-US'
            this.languageDisabled = true
            this.selectedImageSrc = this.selectedEpisode.imageInfo.url
          }
        }

        this.selectedEpisode = {
          ...this.selectedEpisode,
          summary: this.selectedEpisode.summary || '',
          episodeType: 'REGULAR',
          season: 1,
          episodeNumber: 1,
          scheduleTime: null,
          keywords: (this.selectedEpisode.keywords && this.selectedEpisode.keywords.join(', ')) || '',
          explicitContent:
            this.selectedEpisode.explicitContent || this.rssFeed(this.selectedPodcastUid).explicitContent || false,
        }
        this.selectedEpisodeImageData = ''
        this.currentStep += 1
        if (this.selectedAudio) {
          this.selectedAudioName = this.selectedAudio.name
        } else if (this.selectedFile) {
          this.selectedAudioName = this.selectedFile.name
        }
      }
      if (this.fileType === 'USING_TEXT') {
        this.selectedEpisode = {
          name: '',
          description: '',
          imageId: '',
        }
        this.languageDisabled = false
        this.selectedImageSrc = ''

        this.selectedEpisode = {
          ...this.selectedEpisode,
          summary: this.selectedEpisode.summary || '',
          episodeType: 'REGULAR',
          season: 1,
          episodeNumber: 1,
          scheduleTime: null,
          keywords: (this.selectedEpisode.keywords && this.selectedEpisode.keywords.join(', ')) || '',
          explicitContent:
            this.selectedEpisode.explicitContent || this.rssFeed(this.selectedPodcastUid).explicitContent || false,
        }
        this.selectedEpisodeImageData = ''
      }
    }
  }

  @Watch('selectedFile')
  handleHasFileSelected(e: any) {
    if (this.selectedFile.type === 'text/plain' || this.selectedFile.type === 'application/pdf') {
      this.selectedTextFile = this.selectedFile
    }
  }

  updateDescription(newDescription: string) {
    this.$v.selectedEpisode.description.$model = newDescription
  }

  handleLoadMoreAudioUids() {
    this.loadMoreItems()
  }

  handleUploadAudio() {
    this.$store.dispatch('uploadNewEpisode')
  }

  handleUploadFile() {
    this.$store.dispatch('uploadNewEpisodeUsingText')
  }

  disablePastDays(date: Date) {
    const today = new Date()
    return date.getTime() > today.getTime()
  }

  get disableSubmit() {
    return (
      this.$v.selectedEpisode.$invalid ||
      (this.episodePublishType === 'PUBLISH_LATER' && this.$v.selectedEpisode.scheduleTime.$model === null) ||
      this.isSubmitting
    )
  }

  async handleAddEpisodeSubmit() {
    if (this.fileType === 'USING_AUDIO') {
      this.isSubmitting = true

      try {
        let audioTrackPayload: any = {
          name: this.selectedEpisode.name,
          description: removeStyles(this.selectedEpisode.description),
          summary: this.selectedEpisode.summary,
          keywords: this.keywordStringToArray(this.selectedEpisode.keywords),
          explicitContent: this.selectedEpisode.explicitContent,
          language: this.selectedLanguage,
        }

        let imageId
        if (this.selectedImageSrc && this.selectedImageSrc.includes('adorilabs')) {
          if (this.selectedImageId) {
            audioTrackPayload.imageId = this.selectedImageId
          }
        } else if (this.selectedImageSrc && this.selectedImageSrc.startsWith('https://')) {
          const img: any = await AdoriService.uploadImage(this.networkId, {
            url: this.selectedImageSrc,
          })
          audioTrackPayload.imageId = img.id
        } else {
          const img: any = await AdoriService.uploadImage(this.networkId, this.selectedFile)
          audioTrackPayload.imageId = img.id
        }

        const callMethods = async () => {
          const rssFeedItemPayload: any = {
            episodeType: this.selectedEpisode.episodeType,
            episodeNumber: this.hasEpisodeNumber ? this.selectedEpisode.episodeNumber : null,
            season: this.belongsToSeason ? this.selectedEpisode.season : null,
            explicitContent: this.selectedEpisode.explicitContent,
          }

          if (this.episodePublishType === 'PUBLISH_LATER') {
            rssFeedItemPayload.scheduleTime = this.selectedEpisode.scheduleTime.getTime()
          }

          await this.addRssFeedItem({
            rssFeedUid: this.selectedPodcastUid,
            rssFeedItemUid: this.selectedEpisode.uid,
            payload: rssFeedItemPayload,
          })

          if (this.$permissions.isViewEpisodeAllowed()) {
            await this.updateAudioTrack({
              audioUid: this.selectedEpisode.uid,
              payload: audioTrackPayload,
            })
          }

          await this.publishRssFeed({
            rssFeedUid: this.selectedPodcastUid,
          })

          const feedUid = this.$route.params?.id
          if (feedUid) {
            this.queryClient.invalidateQueries([audioQueryKeys.YTPUBLISHED, `${feedUid}`])
            this.queryClient.invalidateQueries(audioQueryKeys.ALLTRACKS)
          } else {
            this.queryClient.invalidateQueries(audioQueryKeys.YTPUBLISHED)
            this.queryClient.invalidateQueries(audioQueryKeys.ALLTRACKS)
          }

          this.isSubmitting = false
          this.closeModal()
        }

        if (this.audioSource === 'UPLOAD_NEW') {
          await AdoriService.updateUpload(this.networkId, this.$store.getters.audioUploadId)

          const poll = setInterval(async () => {
            const res: any = await AdoriService.getUploadStatus(this.networkId, this.$store.getters.audioUploadId)

            if (res.processingStatus === uploadStatus.FINISHED) {
              clearInterval(poll)
              const audioTrack = await this.$store.dispatch('createAudioTrack', audioTrackPayload)
              this.selectedEpisode.uid = audioTrack.uid
              if (this.shouldAutoTagAudio) {
                await this.$store.dispatch('addAudioToAutoProcessList', {
                  audioUid: audioTrack.uid,
                  addTags: 'ADD',
                })
              }
              callMethods()
            }
            if (res.processingStatus === uploadStatus.FAILED) {
              clearInterval(poll)
              this.$store.dispatch('pushNotification', {
                text: 'Upload Failed',
                type: 'ERROR',
              })
              this.isSubmitting = false
            }
          }, 3000)
        } else callMethods()
      } catch (error: any) {
        this.isSubmitting = false
        this.errorMessage = error.message
      }
    } else if (this.fileType === 'USING_TEXT') {
      this.isSubmitting = true
      try {
        let audioTrackPayload: any = {
          name: this.selectedEpisode.name,
          description: removeStyles(this.selectedEpisode.description),
          summary: this.selectedEpisode.summary,
          keywords: this.keywordStringToArray(this.selectedEpisode.keywords),
          explicitContent: this.selectedEpisode.explicitContent,
          language: this.selectedEpisode.language,
          public: true,
          voiceType: this.selectedEpisode.voiceType,
          voiceGender: this.selectedEpisode.voiceGender,
          voiceName: this.selectedEpisode.voiceName,
        }

        let imageId
        if (this.selectedImageSrc && this.selectedImageSrc.includes('adorilabs')) {
          if (this.selectedImageId) {
            audioTrackPayload.imageId = this.selectedImageId
          }
        } else if (this.selectedImageSrc && this.selectedImageSrc.startsWith('https://')) {
          const img: any = await AdoriService.uploadImage(this.networkId, {
            url: this.selectedImageSrc,
          })
          audioTrackPayload.imageId = img.id
        } else {
          const img: any = await AdoriService.uploadImage(this.networkId, this.selectedFile)
          audioTrackPayload.imageId = img.id
        }

        const callMethods = async () => {
          const rssFeedItemPayload: any = {
            episodeType: this.selectedEpisode.episodeType,
            episodeNumber: this.hasEpisodeNumber ? this.selectedEpisode.episodeNumber : null,
            season: this.belongsToSeason ? this.selectedEpisode.season : null,
            explicitContent: this.selectedEpisode.explicitContent,
          }

          if (this.episodePublishType === 'PUBLISH_LATER') {
            rssFeedItemPayload.scheduleTime = this.selectedEpisode.scheduleTime.getTime()
          }

          await this.addRssFeedItem({
            rssFeedUid: this.selectedPodcastUid,
            rssFeedItemUid: this.selectedEpisode.uid,
            payload: rssFeedItemPayload,
          })

          if (this.$permissions.isViewEpisodeAllowed()) {
            await this.updateAudioTrack({
              audioUid: this.selectedEpisode.uid,
              payload: audioTrackPayload,
            })
          }

          await this.publishRssFeed({
            rssFeedUid: this.selectedPodcastUid,
          })

          const feedUid = this.$route.params?.id
          if (feedUid) {
            this.queryClient.invalidateQueries([audioQueryKeys.YTPUBLISHED, `${feedUid}`])
            this.queryClient.invalidateQueries(audioQueryKeys.ALLTRACKS)
          } else {
            this.queryClient.invalidateQueries(audioQueryKeys.YTPUBLISHED)
            this.queryClient.invalidateQueries(audioQueryKeys.ALLTRACKS)
          }
          this.isSubmitting = false
          this.closeModal()
        }

        const payload = {
          file: this.selectedTextFile,
          track_info: audioTrackPayload,
        }

        const taskId = await this.$store.dispatch('createAudioTrackFromFile', payload)

        const poll = setInterval(async () => {
          const res: any = await AdoriService.getTTSUploadStatus(this.networkId, taskId)

          if (res.status === uploadStatus.FINISHED) {
            clearInterval(poll)

            this.selectedEpisode.uid = res.audioUid

            callMethods()
          }
          if (res.status === uploadStatus.FAILED) {
            clearInterval(poll)
            this.$store.dispatch('pushNotification', {
              text: res.errorMessage || 'Upload Failed',
              type: 'ERROR',
            })
            this.isSubmitting = false
          }
        }, 3000)
      } catch (error: any) {
        this.isSubmitting = false
        this.errorMessage = error.message
        this.closeModal()
      }
    }
  }

  keywordStringToArray(keywordString: string) {
    if (keywordString === '') return []
    return keywordString.split(',').map((keyword: string) => keyword.trim())
  }

  handlePrevTab() {
    if (this.currentStep === 2) {
      this.selectedTextFile = null
      this.clearSelectedFile()
      this.clearAudioUploader()
      this.currentStep = 1
      this.audioSource = 'UPLOAD_NEW'
      this.unselectAllAudios()
      this.selectedAudioName = ''
    } else {
      this.currentStep -= 1
    }
  }

  handleNextTab(voices: any) {
    if (this.currentStep === 1 && this.hasCompletedStep1) {
      this.hasClickedNext = false
      if (voices) {
        if (voices.voiceId) {
          this.selectedEpisode = { ...this.selectedEpisode, voices }
        } else {
          this.selectedEpisode.voiceType = voices.voiceType
          this.selectedEpisode.voiceGender = voices.voiceGender
          this.selectedEpisode.language = voices.language
          this.selectedEpisode.voiceName = voices.voiceName
        }
      }
      this.currentStep += 1
    } else if (this.currentStep === 2 && this.hasCompletedStep2) {
      this.hasClickedNext = false
      this.currentStep += 1
    } else {
      this.hasClickedNext = true
    }
  }
}
