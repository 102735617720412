var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "snackbar" } }, [
    _vm.active
      ? _c("div", { staticClass: "toast", class: _vm.classes }, [
          _c("div", { staticClass: "toast-wrap" }, [
            _c("div", { staticClass: "toast-body" }, [_vm._t("default")], 2),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }