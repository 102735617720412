import { FlightFetchParams } from './../Interfaces/StoreInterFaces/campaignInterfaces/Flight'
import { CampaignFetchParams } from './../Interfaces/StoreInterFaces/campaignInterfaces/Campaign'
import { AdvertiserFetchParams } from '../Interfaces/StoreInterFaces/campaignInterfaces/Campaign'
import { queryDefaults } from '@/utils/query.config'
import AdoriServiceV6 from '@/services/adori_v6'
import store from '@/store'
import { useQuery } from 'vue-query'

// Query Keys
export enum campaignQueryKeys {
  ADVERTISERS = 'ADVERTISERS_LIST',
  CAMPAIGN = 'CAMPAIGN',
  ONE_CAMPAIGN = 'ONE_CAMPAIGN',
  FLIGHT = 'FLIGHT',
}

// Queries
const getAdvertiserList = (networkId: string, params: AdvertiserFetchParams) => {
  return AdoriServiceV6.fetchAdvertisers(params, networkId)
}
const getCampaigns = (networkId: string, params: CampaignFetchParams) => {
  return AdoriServiceV6.fetchCampaigns(params, networkId)
}
const getOneCampaign = (networkId: string, campaignId: string) => {
  return AdoriServiceV6.fetchOneCampaign(networkId, campaignId)
}
const getFlights = (networkId: string, campaignId: string, params: FlightFetchParams) => {
  return AdoriServiceV6.fetchFlights(params, networkId, campaignId)
}

// Hooks
export const useGetAdvertiserList = (params: AdvertiserFetchParams, onSuccess?: any) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useQuery([campaignQueryKeys.ADVERTISERS, params], () => getAdvertiserList(networkId, params), {
    ...queryDefaults,
    staleTime: Infinity,
    cacheTime: Infinity,
    onSuccess,
  })
}
export const useGetCampaigns = (params: CampaignFetchParams) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useQuery([campaignQueryKeys.CAMPAIGN, params], () => getCampaigns(networkId, params), {
    ...queryDefaults,
    staleTime: Infinity,
    cacheTime: Infinity,
    keepPreviousData: true,
  })
}
export const useGetOneCampaign = (campaignId: string) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useQuery([campaignQueryKeys.ONE_CAMPAIGN, campaignId], () => getOneCampaign(networkId, campaignId), {
    ...queryDefaults,
  })
}
export const useGeFlights = (campaignId: string, params: CampaignFetchParams) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useQuery([campaignQueryKeys.FLIGHT, params], () => getFlights(networkId, campaignId, params), {
    ...queryDefaults,
    staleTime: Infinity,
    cacheTime: Infinity,
    keepPreviousData: true,
  })
}

// Mutations
