var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isAudioVisible
    ? _c(
        "div",
        {
          staticClass: "bb b--adori-gray pb4 darker-hover relative",
          class: { waveform: _vm.audioIsAdorified },
        },
        [
          _vm.isAudioVisible
            ? _c(
                "div",
                {
                  staticClass:
                    "flex flex-wrap justify-between ph2 pt4 link darker-hover br2 relative",
                  class: _vm.editable && "pointer",
                  on: { click: _vm.handleAudioClicked },
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex flex-wrap justify-start" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "flex items-center ml2 mr3",
                          staticStyle: { cursor: "default" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                            },
                          },
                        },
                        [
                          _vm.selectable
                            ? _c(
                                "i",
                                {
                                  staticClass:
                                    "material-icons f2 white link pointer",
                                  class: _vm.selectionStyle + " pointer",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.handleAudioSelectionIconClicked.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.selectionIcon))]
                              )
                            : _vm._e(),
                        ]
                      ),
                      _c("BaseThumbnailEpisode", {
                        staticClass: "mr3",
                        attrs: {
                          src: _vm.audioPreviewImage,
                          onClick: _vm.handleAudioClicked,
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex flex-column flex-wrap justify-between",
                          staticStyle: { width: "485px" },
                        },
                        [
                          _c("div", { staticClass: "flex flex-column" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "f4 overflow-y-hidden truncate light-gray mb1",
                                staticStyle: { width: "485px" },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.audio
                                        ? _vm.audio.name
                                        : "Loading audio details..."
                                    ) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ]),
                          _vm.audio
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "flex flex-wrap justify-left items-center mt2",
                                  staticStyle: { width: "485px" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "f6 light-gray mt1" },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.audioDate)),
                                      ]),
                                      _vm._v("\n            ·\n            "),
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.audioDuration)),
                                      ]),
                                      _vm._v("\n            ·\n            "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.audioIsPublic
                                              ? "Public"
                                              : "Private"
                                          )
                                        ),
                                      ]),
                                      _vm._v("\n            ·\n            "),
                                      _c("span", { staticClass: "mr2" }, [
                                        _vm._v(_vm._s(_vm.audioTagsText)),
                                      ]),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.audio
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "flex flex-wrap justify-left items-center mt2",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex flex-wrap items-center",
                                    },
                                    _vm._l(
                                      _vm.podcastsWithAudio,
                                      function (podcast) {
                                        return _c(
                                          "div",
                                          {
                                            key: podcast.uid,
                                            staticClass:
                                              "f7 mr2 mv1 br2 flex items-center",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "bg-adori-gray light-gray ph1 pv1 br2",
                                                class:
                                                  _vm.podcastEpisode(
                                                    podcast.uid
                                                  ) &&
                                                  _vm.podcastEpisode(
                                                    podcast.uid
                                                  ).season &&
                                                  _vm.podcastEpisode(
                                                    podcast.uid
                                                  ).episodeNumber
                                                    ? "br--left"
                                                    : "",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      _vm.truncate(
                                                        podcast.name,
                                                        20
                                                      )
                                                    ) +
                                                    "\n              "
                                                ),
                                              ]
                                            ),
                                            _vm.podcastEpisode(podcast.uid) &&
                                            (_vm.podcastEpisode(podcast.uid)
                                              .season ||
                                              _vm.podcastEpisode(podcast.uid)
                                                .episodeNumber)
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "bg-adori-red light-gray ph1 pv1 br2 br--right",
                                                  },
                                                  [
                                                    _vm.podcastEpisode(
                                                      podcast.uid
                                                    ).season
                                                      ? _c("span", [
                                                          _vm._v(
                                                            "\n                  S" +
                                                              _vm._s(
                                                                _vm.podcastEpisode(
                                                                  podcast.uid
                                                                ).season + " "
                                                              ) +
                                                              "\n                "
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    _vm.podcastEpisode(
                                                      podcast.uid
                                                    ).episodeNumber
                                                      ? _c("span", [
                                                          _vm._v(
                                                            "\n                  E" +
                                                              _vm._s(
                                                                _vm.podcastEpisode(
                                                                  podcast.uid
                                                                ).episodeNumber
                                                              ) +
                                                              "\n                "
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm.showEditButtons
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "flex flex-column items-center absolute right-1 top-2",
                        },
                        [
                          _vm.hasSelectedPodcast
                            ? _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex justify-end",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                      },
                                    },
                                  },
                                  [
                                    _vm.$permissions.isEditEpisodeShowAllowed(
                                      _vm.selectedRssFeedUid
                                    )
                                      ? _c("BaseButtonBorder", {
                                          staticClass: "mr3",
                                          attrs: {
                                            icon: "edit",
                                            onClick: _vm.handleEditClick,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.$permissions.isEditEpisodeShowAllowed(
                                      _vm.selectedRssFeedUid
                                    ) && !_vm.fromYoutube
                                      ? _c("ButtonAudioAdorify", {
                                          staticClass: "mr3 mb0 w-fixed",
                                          attrs: { audioUid: _vm.audioUid },
                                        })
                                      : _vm._e(),
                                    _c("BasePopupMenu", {
                                      attrs: {
                                        icon: "more_vert",
                                        items: _vm.podcastEpisodeMenu,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm.formatedPublishedOrScheduled
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "pt4 ph2 f6 flex justify-end light-gray",
                                      },
                                      [
                                        _vm._v(
                                          "\n          " +
                                            _vm._s(
                                              _vm.formatedPublishedOrScheduled
                                            ) +
                                            "\n        "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ])
                            : _c(
                                "div",
                                {
                                  staticClass: "flex justify-end",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                },
                                [
                                  _vm.$permissions.isEditEpisodeAllowed()
                                    ? _c("BaseButtonBorder", {
                                        staticClass: "mr3",
                                        attrs: {
                                          icon: "edit",
                                          onClick: _vm.handleEditClick,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.$permissions.isEditEpisodeAllowed() &&
                                  !_vm.fromYoutube
                                    ? _c("ButtonAudioAdorify", {
                                        staticClass: "mr3 mb0 w-fixed",
                                        attrs: { audioUid: _vm.audioUid },
                                      })
                                    : _vm._e(),
                                  _vm.$permissions.isDeleteEpisodeAllowed()
                                    ? _c("BasePopupMenu", {
                                        attrs: {
                                          icon: "more_vert",
                                          items: _vm.audioTrackMenu,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                        ]
                      )
                    : _vm._e(),
                  _vm.showPreviewButton
                    ? _c("div", [
                        _vm.podcastEpisode(_vm.selectedRssFeedUid) &&
                        !_vm.podcastEpisode(_vm.selectedRssFeedUid).isScheduled
                          ? _c(
                              "div",
                              { staticClass: "flex flex-column items-end" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "flex items-center mr3 mb3" },
                                  [
                                    _c("BaseButtonBorder", {
                                      staticClass: "mr3",
                                      attrs: {
                                        text: "Episode website",
                                        onClick: _vm.openEpisodeWebsite,
                                      },
                                    }),
                                    _c("BaseButtonBorder", {
                                      attrs: {
                                        href: _vm.portraitExperienceSrc,
                                        text: "Embed interactive web-player",
                                        onClick: _vm.handleIframeEmbed,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm.formatedPublishedOrScheduled
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "ph2 f6 flex justify-end light-gray",
                              },
                              [
                                _vm._v(
                                  "\n        " +
                                    _vm._s(_vm.formatedPublishedOrScheduled) +
                                    "\n      "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm.isVideoPodcast
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "absolute top-0 left-0 white bg-adori-red f7 ph2 pv1 fw5 tracked",
                        },
                        [_vm._v("\n      Video Podcast\n    ")]
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _c("div", { staticClass: "flex justify-between pr3 mr2" }, [
            _c("div", { staticClass: "ml173" }, [
              _vm.firstClip
                ? _c(
                    "div",
                    [
                      _c("YoutubeAudioComboBox", {
                        key: _vm.firstClip.id,
                        attrs: {
                          audioUid: _vm.firstClip.id,
                          episode: _vm.firstClip,
                          isFirstClip: "",
                          ytFeedUid: _vm.feedUid,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _vm.showComboBox
              ? _c(
                  "div",
                  {
                    staticClass: "pointer",
                    on: {
                      click: function ($event) {
                        _vm.isComboBoxActive = !_vm.isComboBoxActive
                      },
                    },
                  },
                  [
                    _vm.isComboBoxActive
                      ? _c("div", [
                          _c(
                            "i",
                            {
                              staticClass:
                                "material-icons v-mid white text-shadow mt2",
                            },
                            [_vm._v(" expand_less ")]
                          ),
                        ])
                      : _c("div", [
                          _c(
                            "i",
                            {
                              staticClass:
                                "material-icons v-mid white text-shadow mt3",
                            },
                            [_vm._v(" expand_more ")]
                          ),
                        ]),
                  ]
                )
              : _vm._e(),
          ]),
          _vm.showComboBox
            ? _c("div", [
                _vm.isComboBoxActive
                  ? _c(
                      "div",
                      { staticClass: "combobox pb3 pt1" },
                      _vm._l(_vm.allVideoClipsExceptFirst, function (clip) {
                        return _c("YoutubeAudioComboBox", {
                          key: clip.id,
                          attrs: {
                            audioUid: clip.id,
                            episode: clip,
                            ytFeedUid: _vm.feedUid,
                          },
                        })
                      }),
                      1
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm.audioIsGettingReplaced
            ? _c(
                "div",
                {
                  staticClass:
                    "absolute top-0 left-0 w-100 h-100 flex items-center justify-center br2 f4",
                  staticStyle: { background: "rgb(0, 0, 0, 0.85)" },
                  on: {
                    click: [
                      function ($event) {
                        $event.preventDefault()
                      },
                      function ($event) {
                        $event.stopPropagation()
                      },
                    ],
                  },
                },
                [
                  _vm._v("\n    Audio is being replaced\n    "),
                  _c("div", { staticClass: "loader loader-inline ml3" }),
                ]
              )
            : _vm._e(),
          _vm.shareAudio
            ? _c("ShareModal", {
                attrs: { audio: _vm.audio },
                on: { close: _vm.closeShare },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }