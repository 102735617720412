










import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class BaseButtonBorder extends Vue {
  @Prop(String) text!: string
  @Prop(String) className!: string
  @Prop(String) icon!: string
  @Prop(Boolean) disabled!: boolean
  @Prop(Object) params!: object
  @Prop(Function) onClick!: Function
  @Prop(Boolean) loading!: boolean

  get styleClass() {
    return (
      (this.className
        ? this.className
        : 'flex justify-center items-center f6 ph3 pv2 br2 ba b--adori-gray light-gray bg-dark-gray btn-shadow') +
      (!this.disabled ? 'o-90 glow pointer' : 'o-40 not-allowed')
    )
  }

  buttonAction() {
    if (!this.disabled) {
      if (!this.params) this.onClick()
      else this.onClick(this.params)
    }
    // if (!this.disabled) this.onClick()
  }
}
