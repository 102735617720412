

























import { Vue, Component, Prop } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

@Component
export default class TheCookiesPrompt extends Vue {
  @Getter hasLoadedUser!: boolean
  @Getter hasAcceptedCookies!: boolean
  @Getter networkId!: string
  @Action uploadCookieStatus!: any

  onAcceptCookies() {
    this.uploadCookieStatus(true)
  }
}
