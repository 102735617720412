









import { Vue, Component } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

@Component
export default class TheCookiesPrompt extends Vue {
  @Getter alertPromptMessage!: boolean

  handleClose() {
    this.$store.commit('setAlertPromptMessage')
  }
}
