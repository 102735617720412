




















































import { Vue, Component, Watch } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import MobileDetect from 'mobile-detect'
import TheCookiesPrompt from '@/components/Common/TheCookiesPrompt.vue'
import TheModals from '@/components/Common/TheModals.vue'
import TheNotifications from '@/components/Common/TheNotifications.vue'
import TheFileUploader from '@/components/Common/TheFileUploader.vue'
import TheBillPayPrompt from '@/components/Common/TheBillPayPrompt.vue'
import TheContactPrompt from './components/Common/TheContactPrompt.vue'
import TheAlertPrompt from './components/Common/TheAlertPrompt.vue'
import TheToast from './components/Common/TheToast.vue'
import { CURRENT_ENV, ENVS } from '@/constants/env'
import { NETWORKROLES } from './constants/roles'
import BaseCreditCardPopup from './components/Base/BaseCreditCardPopup.vue'

@Component({
  components: {
    TheCookiesPrompt,
    TheModals,
    TheNotifications,
    TheFileUploader,
    TheBillPayPrompt,
    TheContactPrompt,
    TheAlertPrompt,
    TheToast,
    BaseCreditCardPopup,
  },
})
export default class App extends Vue {
  @Getter showLoader!: boolean
  @Getter initialLoader!: boolean
  @Getter networkId!: boolean
  @Getter networkType!: string
  @Getter isYoutubeNew!: boolean
  @Getter currentRole!: string
  @Getter showLoaderText!: string
  @Getter creditCardAlert!: string

  get networkRoles() {
    return NETWORKROLES
  }

  get isHomeRoute() {
    return ['home-channel', 'home', 'overview', undefined].includes(this.$route.name)
  }

  refreshing: boolean = false
  registration: any = null
  updateExists: boolean = false
  infinity: boolean = true
  position: string = 'bottom-left' // 'bottom-left', 'bottom-center', 'bottom-right', 'top-left', 'top-center', 'top-right'
  isMobileBannerShownOnce = false

  created() {
    //Dynamically set title
    var url = window.location.href
    var res = url.match(/^[a-z]+:\/\/|\.?[^/.\s]+/giy) || []
    const isVideo = res.includes('video') || res.includes('.video')
    isVideo ? (document.title = 'Adori Video') : (document.title = 'Adori Studio')

    document.addEventListener('swUpdated', this.updateAvailable, { once: true })
    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      window.location.reload()
    })
  }

  updateAvailable(event: any) {
    this.registration = event.detail
    this.updateExists = true
  }

  updateApp() {
    this.updateExists = false
    // Make sure we only send a 'skip waiting' message if the SW is waiting
    if (!this.registration || !this.registration.waiting) return
    // Send message to SW to skip the waiting and activate the new SW
    this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
  }

  get isMobile() {
    const md = new MobileDetect(window.navigator.userAgent)
    return md.mobile()
  }
  // Temporarily disabling mobile detect
  get isAllowedMobileRoute() {
    return true
  }

  get isDev() {
    return CURRENT_ENV === ENVS.dev
  }

  scrollY() {
    return window.scrollY
  }

  @Watch('showLoader')
  onShowLoaderChanged() {
    if (this.showLoader) {
      document.body.classList.add('scroll-lock', 'event-lock')
    } else {
      document.body.classList.remove('scroll-lock', 'event-lock')
    }
  }
}
